import React, { FC } from 'react';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { Grid, Typography } from '@mui/material';
import { useCatalog } from '@/hooks/CatalogHook';
import { UspdInfoResponse } from '@/dto/taskmap/task/TaskResultDto';

interface IFormUspd {
  keyStr: string;
  uspdInfo?: UspdInfoResponse;
}

export const FormUspd: FC<IFormUspd> = ({ uspdInfo = {}, keyStr }) => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  return (
    <Grid
      item
      xs={12}
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant='h5'>Информация о УСПД</Typography>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <AutocompleteValue
          title='Тип сотового оператора'
          sx={{ width: '100%' }}
          fieldName={`${keyStr}.mobileOperatorId`}
          values={getCatalogMapWithErr('mobileOperatorType')}
          defaultValue={uspdInfo?.mobileOperatorId}
        />
      </Grid>
      <Grid
        item
        xs={4}
      >
        <AutocompleteValue
          title='Уровень сигнала'
          sx={{ width: '100%' }}
          fieldName={`${keyStr}.signalLevelId`}
          defaultValue={uspdInfo?.signalLevelId}
          values={getCatalogMapWithErr('signalLevel')}
        />
      </Grid>
      <Grid
        item
        xs={4}
      >
        <AutocompleteValue
          freeSolo
          disableClearable
          title='Место установки'
          sx={{ width: '100%' }}
          fieldName={`${keyStr}.installPlace`}
          defaultValue={uspdInfo?.installPlace}
          values={getCatalogMapWithErr('uspdInstallPlace')}
        />
      </Grid>
    </Grid>
  );
};
