import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { FilterArgEnum } from '@/components/filter/MultySelectFilter';
import { DateRange } from '@/components/inputs/DateRange';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { SupplyBillFilterParam } from '@/dto/SupplyBillServiceDto';
import usePressEnterButton from '@/hooks/usePressEnterButton';
import React from 'react';
import {
  SupplyBillBrowseDateRangeWrapper,
  SupplyBillBrowseFormPapper,
  SupplyBillBrowseFormWrapper,
  SupplyBillBrowseInputWrapper,
} from '../SupplyBillBrowse/SupplyBillBrowse.styled';
import { FILTERS_LABELS, MULTIPLE_ARGS, RANGE_DATE_ARG } from '../SupplyBillBrowse/utils';
import { useSupplyStatisticFilter } from './store';
import { SUPPLY_STATISTIC_FILTER_KEY, useSupplyStatisticForm } from './utils';

export interface SupplyStatisticFormProps {
  updateData: (filt?: SupplyBillFilterParam, withReset?: boolean) => void;
}

const SupplyStatisticForm = ({ updateData }: SupplyStatisticFormProps) => {
  const {
    billDate,
    puTypes,
    billType,
    supplyId,
    responsables,
    osTypes,
    setDateValue,
    setDateValueArg,
    setDefaultValue,
    setMultiValue,
    setMultiValueArg,
  } = useSupplyStatisticFilter();
  const {
    setFilterValues,
    getFilterValues,
    billTypesOptions,
    storehousesOptions,
    puTypesOptions,
    osTypesOptions,
    responsablesOptions,
  } = useSupplyStatisticForm();

  const onSubmitSearch = () => {
    const filtParam = getFilterValues();
    updateData(filtParam, true);
  };

  usePressEnterButton(onSubmitSearch);

  const detailedInfo = [
    ...generateDetailedInfo(
      [puTypes.value, FILTERS_LABELS.puTypes],
      [osTypes.value, FILTERS_LABELS.osTypes],
      [billType.value, FILTERS_LABELS.billType],
      [supplyId.value, FILTERS_LABELS.supplyId],
      [responsables.value, FILTERS_LABELS.responsables]
    ),
    { items: billDate.value, title: FILTERS_LABELS.billDate },
  ];

  const timeToolTipLabel =
    billDate.value.length === 1 ? 'Введите вторую дату' : FILTERS_LABELS.dateRange;

  const isInRange = billDate.value.length !== 2;

  return (
    <SupplyBillBrowseFormPapper>
      <SupplyBillBrowseFormWrapper>
        <SupplyBillBrowseInputWrapper>
          <MultiSelect
            label={FILTERS_LABELS.responsables}
            options={responsablesOptions}
            {...responsables}
            onChange={setMultiValue('responsables')}
            onArgChange={setMultiValueArg('responsables')}
            argList={[FilterArgEnum.IN_LIST]}
            withoutBullAnswerReset
          />
        </SupplyBillBrowseInputWrapper>

        <SupplyBillBrowseDateRangeWrapper>
          <DateRange
            label={timeToolTipLabel}
            isInRange={isInRange}
            onChange={setDateValue('billDate')}
            onArgChange={setDateValueArg('billDate')}
            {...billDate}
            argList={RANGE_DATE_ARG}
          />
        </SupplyBillBrowseDateRangeWrapper>

        <SupplyBillBrowseInputWrapper>
          <MultiSelect
            label={FILTERS_LABELS.puTypes}
            options={puTypesOptions}
            {...puTypes}
            onChange={setMultiValue('puTypes')}
            onArgChange={setMultiValueArg('puTypes')}
            argList={MULTIPLE_ARGS}
            withoutBullAnswerReset
          />
        </SupplyBillBrowseInputWrapper>

        <SupplyBillBrowseInputWrapper>
          <MultiSelect
            label={FILTERS_LABELS.osTypes}
            options={osTypesOptions}
            {...osTypes}
            onChange={setMultiValue('osTypes')}
            onArgChange={setMultiValueArg('osTypes')}
            argList={MULTIPLE_ARGS}
            withoutBullAnswerReset
          />
        </SupplyBillBrowseInputWrapper>

        <SupplyBillBrowseInputWrapper>
          <MultiSelect
            label={FILTERS_LABELS.supplyId}
            options={storehousesOptions}
            {...supplyId}
            onChange={setMultiValue('supplyId')}
            onArgChange={setMultiValueArg('supplyId')}
            argList={MULTIPLE_ARGS}
            withoutBullAnswerReset
          />
        </SupplyBillBrowseInputWrapper>

        <SupplyBillBrowseInputWrapper>
          <MultiSelect
            label={FILTERS_LABELS.billType}
            options={billTypesOptions}
            {...billType}
            onChange={setMultiValue('billType')}
            onArgChange={setMultiValueArg('billType')}
            argList={MULTIPLE_ARGS}
            withoutBullAnswerReset
          />
        </SupplyBillBrowseInputWrapper>

        <FilterButtons
          onSearchClick={onSubmitSearch}
          onCloseClick={setDefaultValue}
          info={detailedInfo}
          saveFilter={{
            filterType: SUPPLY_STATISTIC_FILTER_KEY,
            infoToSave: {
              responsables,
              puTypes,
              billDate,
              billType,
              supplyId,
              osTypes,
            },
            selectCallback: setFilterValues,
          }}
        />
      </SupplyBillBrowseFormWrapper>
    </SupplyBillBrowseFormPapper>
  );
};

export default SupplyStatisticForm;
