import CommonModal from '@/components/common/CommonModal/CommonModal';
import { styled } from '@mui/material';

export const PnrPlanningCommonModal = styled(CommonModal)({
  minWidth: '90vw',
  height: '95%',

  '.CommonModalInner': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
});
