import { AxiosResponse } from 'axios';
import { HOST } from './YodaRestService';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from './helpers';
import {
  ConsumableEquipment,
  NetworkElementRequest,
  NetworkElementResponse,
} from '@/dto/NetworkDto';

export async function getNetworkElements(
  taskId: string
): Promise<AxiosResponse<NetworkElementResponse[], any>> {
  const url = HOST + `/rest/task/${taskId}/network-element`;
  return axiosGet(url);
}

export async function updateNetworkElement(
  id: string,
  data: NetworkElementRequest
): Promise<AxiosResponse<NetworkElementResponse, any>> {
  const url = HOST + `/rest/network-element/${id}`;
  return axiosPut(url, data);
}

export async function repeatNetworkElement(
  id: string,
  data: NetworkElementRequest
): Promise<AxiosResponse<NetworkElementResponse, any>> {
  const url = HOST + `/rest/network-element/${id}/execute`;
  return axiosPost(url, data);
}

export async function deleteEquippedSimNetworkElement(
  simId: string
): Promise<AxiosResponse<NetworkElementResponse, any>> {
  const url = HOST + `/rest/network-element-equipped-sim/${simId}`;
  return axiosDelete(url);
}
export async function cancelNetworkElement(
  id: string
): Promise<AxiosResponse<NetworkElementResponse, any>> {
  const url = HOST + `/rest/network-element/${id}/cancel`;
  return axiosPost(url, null);
}
export async function getConsumableEquipment(
  id: string
): Promise<AxiosResponse<ConsumableEquipment[], any>> {
  const url = HOST + `/rest/network-element-expendables/${id}`;
  return axiosGet(url);
}
export async function updateConsumableEquipment(
  data: { id: string; quantity: number }[]
): Promise<AxiosResponse<NetworkElementResponse, any>> {
  const url = HOST + `/rest/network-element-expendables/`;
  return axiosPut(url, data);
}
