import { TaskInfoResponse, TaskResponse } from '@/dto/taskmap/task/TasksDto';
import { createStore } from '@/utils/createStore';

interface PpoMapStore {
  isEditUSPDMode: boolean;
  tempDataUSPD: any;
  latitudeUSPD?: number;
  longitudeUSPD?: number;
  showUSPDs: boolean;

  changeUSPDMode: (flag: boolean) => void;
  setShowUSPDs: () => void;
  setTempDataUSPD: (data: TaskResponse | null | undefined) => void;

  setLatitudeUSPD: (latitude: number) => void;
  setLongitudeUSPD: (longitude: number) => void;
}

export const useUSPDStore = createStore<PpoMapStore>(
  (set: any, get: any) => ({
    isEditUSPDMode: false,
    tempDataUSPD: null,
    showUSPDs: false,

    setLatitudeUSPD: (latitude) => {
      set({ latitudeUSPD: latitude });
    },
    setLongitudeUSPD: (longitude) => {
      set({ longitudeUSPD: longitude });
    },
    setShowUSPDs: () => {
      const showUSPDsNow = get().showUSPDs;
      set({ showUSPDs: !showUSPDsNow });
    },
    setTempDataUSPD: (data) => {
      set({ tempDataUSPD: data });
    },

    changeUSPDMode: (flag) => {
      if (!flag) {
        set({
          tempDataUSPD: null,
          isEditUSPDMode: flag,
          latitudeUSPD: undefined,
          longitudeUSPD: undefined,
        });
      }
      set({
        isEditUSPDMode: flag,
        latitudeUSPD: undefined,
        longitudeUSPD: undefined,
      });
    },
  }),
  'USPD'
);
