import { createStore } from '@/utils/createStore';

interface ImageNewScreen {
  isNewScreen: boolean;
  setIsNewScreen: (isNewScreen: boolean) => void;
}

export const useImageNewScreen = createStore<ImageNewScreen>(
  (set, get) => ({
    isNewScreen: false,
    setIsNewScreen: (isNewScreen) =>
      set(() => ({
        isNewScreen,
      })),
  }),
  'Image New Screen'
);
