import { CellWithTooltip } from '@/components/features/tasksBrowse/constants';
import { color } from '@/styles/mixins';
import { GridColDef } from '@mui/x-data-grid';

export const ODPU_DATA_GRID_COLUMNS: GridColDef[] = [
  {
    field: 'taskNumber',
    headerName: '№ Заявки',
    width: 100,
    renderCell: (params) => (
      <CellWithTooltip params={params}>
        <span
          style={{
            cursor: 'pointer',
            color: color('muiBlue'),
          }}
          onClick={() => window.open(`/task/edit/${params.row.taskId}`)}
        >
          {params.value}
        </span>
      </CellWithTooltip>
    ),
    sortComparator: (v1, v2) => v1 - v2,
  },
  {
    field: 'newMeterMeterType',
    headerName: 'Тип оборудования',
    width: 170,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
  {
    field: 'newMeterNumber',
    headerName: '№ ПУ',
    width: 170,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
  {
    field: 'installPlace',
    headerName: 'Место установки',
    width: 170,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
  {
    field: 'newMeterMac',
    headerName: 'МАС',
    width: 170,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
  {
    field: 'taskId',
    headerName: 'ID заявки',
    width: 300,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
  {
    field: 'vruTaskNum',
    headerName: 'ВРУ',
    width: 115,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
];
