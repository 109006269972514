import type { ColumnsType } from 'antd/es/table';
import { DataColTypePu } from 'src/dto/taskmap/Dto';

export const pieOption = {
  responsive: true,
  plugins: {
    title: {
      display: true,
    },
  },
};
const colors: Record<string, string> = {
  лестница: '#A3A3A3',
  квартира: '#35AC4E',
  холл: '#346EBD',
};
export const aggregatePieData = (data: DataColTypePu[]) => {
  const labels = data.filter((item) => item.title !== 'итого').map((item) => item.title);
  const pieData = data.filter((item) => item.title !== 'итого').map((item) => item.fact);
  const backgroundColor = data.map((item) => colors[item.title]);

  return {
    labels,
    datasets: [{ data: pieData, backgroundColor }],
  };
};
const COLUMNS: ColumnsType<DataColTypePu> = [
  { title: 'место установки', dataIndex: 'title', align: 'center' },
  { title: 'план', dataIndex: 'plan', align: 'center' },
  { title: 'факт', dataIndex: 'fact', align: 'center' },
];

export const TABLE_OPTIONS = {
  columns: COLUMNS,
  pagination: false as const,
  showHeader: true,
  bordered: true,
  style: { flex: 1 },
};

export const TextLength = 35;

export const shortText = (item: any, fileld: string) =>
  `${
    item[fileld].name.length > TextLength
      ? `${item[fileld].name.substring(0, TextLength)}...`
      : item[fileld].name
  }`;
