import { styled } from '@mui/material/styles';
import { color } from '@/styles/mixins';

export const Border = styled('div')({
  border: `0.5px solid ${color('muiBlue')}`,
  borderRadius: 5,
  padding: '2px 6px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const Circle = styled('div')({
  width: 14,
  height: 14,
  borderRadius: 7,
});

export const Count = styled('div')({
  marginLeft: 4,
  fontWeight: 400,
  fontSize: '20px',
});
