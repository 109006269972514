import { Paper, styled, IconButton } from '@mui/material';

export const IssuedStampFilterWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
});

export const IssuedStampHeaderWrapper = styled(Paper)({
  position: 'relative',
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  padding: '10px',
  marginBottom: '20px',
});

export const IssuedStampUpdateInfoWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

export const IssuedStampUpdateInfoBold = styled('span')({
  fontWeight: 500,
});
export const IssuedStampFileInputWrapper = styled('div')({
  display: 'flex',
  gap: 2,
});

export const IssuedStampUpdateFileIconButton = styled(IconButton)({
  alignSelf: 'center',
});
