import React from 'react';
import { TaskEditor } from '@/components/sections/TaskEditor/TaskEditor';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';

const TaskEditorPage = () => {
  const hasAccess = useAccess({
    access: [AccessEnum.TasksBrowse, AccessEnum.DeliveryPointScreen],
  });

  return <>{hasAccess && <TaskEditor />}</>;
};
export default TaskEditorPage;
