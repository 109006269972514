import { mediaBreakpointDown } from '@/styles/mixins';
import { Stack, styled } from '@mui/material';
import { MainFilterCheckbox } from '../../tasksBrowse/MainFilter/MainFilter.styled';

export const ExtraFilters = styled(Stack)({
  flexDirection: 'row',
  [mediaBreakpointDown('md')]: {
    flexDirection: 'column',
    marginTop: 15,
  },
});
export const CheckBoxLabel = styled(MainFilterCheckbox)({
  [mediaBreakpointDown('md')]: {
    [`span`]: {
      margin: 2,
      fontSize: 14,
    },
  },
});
