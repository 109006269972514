import { PnrMainTableCube } from '@/components/sections/PnrMainTable/PnrMainTable.styled';
import { color } from '@/styles/mixins';
import { styled } from '@mui/material';
import { Menu } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Loading } from '@/components/ui/Loading';

export const PnrElementsInfoMenu = styled(Menu)({
  marginTop: 4,
});

export const PnrElementsInfoSmallText = styled('span')({
  fontSize: 12,
});

export const PnrElementsInfoContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  gap: 8,
  paddingLeft: 27,
});

export const PnrElementsInfoText = styled('span')({
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: 12,
  display: 'block',
  color: color('gray'),
  textAlign: 'left',
});

export const PnrElementsInfoMainText = styled(PnrElementsInfoText)({
  fontWeight: 500,
  color: color('trueBlack'),
  display: 'inline-flex',
});

export const PnrElementsInfoTaskLink = styled('span')({
  fontWeight: 500,
  cursor: 'pointer',
  color: color('muiBlue'),
  display: 'inline-flex',
  fontSize: 14,
});

export const PnrElementsInfoCopyButton = styled('div')({
  fontSize: 16,
});

export const PnrElementsInfoEndAlign = styled('span')({
  alignSelf: 'flex-end',
});

export interface PnrElementsInfoCubeProps {
  disabled?: boolean;
}

export const PnrElementsInfoCube = styled(PnrMainTableCube)<PnrElementsInfoCubeProps>(
  ({ disabled }) => ({
    width: '15%',
    padding: '4px 12px',
    transition: '0.4s',
    cursor: 'pointer',

    '&:hover': {
      border: `1px solid ${color('muiBlue')}`,
    },

    ...(disabled && { pointerEvents: 'none' }),
  })
);

export const PnrElementsInfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  minWidth: 120,
});

export const PnrElementsInfoRowsWrapper = styled(PnrElementsInfoWrapper)({
  padding: 12,
  gap: 12,
});

export const PnrElementsInfoRowWrapper = styled('div')({
  display: 'flex',
  gap: 8,
});

export interface PnrElementWrapperProps {
  backendItem?: boolean;
}
export const PnrElementWrapper = styled('div')<PnrElementWrapperProps>(({ backendItem }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  gap: 4,

  '& > div > svg:first-child': {
    ...(backendItem && { fill: color('muiBlue') }),
  },
}));

export const PnrElementsWrapper = styled('div')({
  display: 'flex',
  gap: 14,
  height: '100%',
});

export const PnrElementsAddIcon = styled(AddIcon)({
  margin: '10px 0',
});

export const PnrElementsLoading = styled(Loading)({
  height: '57px !important',
  width: '35px !important',
});
