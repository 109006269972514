import { FilterArgEnum } from '../../inputs/ArgumentSelectNew/types';
import { ICallCenterBrowseStore } from './store';

export const converter = (o: ICallCenterBrowseStore) => {
  const store = {
    onlyBashkortostan: o.onlyBashkortostan,
    address: o.address.value,
    addressArg: o.address.argValue,
    taskId: o.taskId.value,
    taskIdArg: o.taskId.argValue,
    taskNum: o.taskNum.value,
    taskNumArg: o.taskNum.argValue,
    statusList: o.statusList.value?.map((id) => id?.value),
    statusListArg: o.statusList.argValue,
    operatorIdList: o.operatorIdList.value?.map((id) => id?.value),
    operatorIdListArg: o.operatorIdList.argValue,
    nextCallDateOn: o.nextCallDateOn.value,
    nextCallDateOnArg: FilterArgEnum.EQUAL,
  };

  return Object.entries(store).reduce((acc, [key, value]) => {
    if (value || value === 0) {
      if (Array.isArray(value)) {
        if (value.length) {
          return {
            ...acc,
            [key]: value.map((item) => item),
          };
        } else {
          return {
            ...acc,
          };
        }
      } else {
        if (key === 'taskId') {
          const isList = store[`taskIdArg`] === FilterArgEnum.IN_LIST && typeof value == 'string';
          return {
            ...acc,
            taskIdList: isList ? value.split(',').map((item) => item) : [value],
          };
        }
        return {
          ...acc,
          [key]: value,
        };
      }
    }
    return acc;
  });
};
