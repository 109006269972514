import React, { FC } from 'react';
import { FormControlLabel, Radio, RadioGroup as RadioGroupMUI } from '@mui/material';
import { IRadioGroupForm } from './types';

export const RadioGroupForm: FC<IRadioGroupForm> = ({
  value,
  onChange,
  items = [],
  labelPlacement = 'top',
  columnDirection,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
      alignItems: 'end',
    }}
  >
    <RadioGroupMUI
      row={columnDirection ? undefined : true}
      name='row-radio-buttons-group'
      value={value}
      onChange={onChange}
    >
      {items.map((elem, index) => (
        <FormControlLabel
          key={elem.value}
          sx={index === 0 ? { padding: '0px' } : {}}
          value={elem.value}
          labelPlacement={labelPlacement}
          control={<Radio />}
          label={elem.label}
        />
      ))}
    </RadioGroupMUI>
  </div>
);
