import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { useCatalog } from '@/hooks/CatalogHook';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { SingleLine } from '@/components/inputs/SingleLineNew';
import { useCatalogCounterFilter } from './store';
import { CatalogTypes, getFilterLabels } from './constants';
import shallow from 'zustand/shallow';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';

const COL_7 = 12 / 7;

interface IMainFilter {
  onSubmit: () => void;
  type: CatalogTypes;
}

export const MainFilter: FC<IMainFilter> = ({ onSubmit, type }) => {
  const FILTERS_LABELS = getFilterLabels(type);

  const {
    mac,
    id,
    imei,
    phase,
    number,
    typeId,
    simNum,
    simIccid,
    ip,
    setSingleValue,
    setSingleValueArg,
    setDefaultValue,
    setMultiValue,
    setMultiValueArg,
  } = useCatalogCounterFilter(
    (state) => ({
      setSingleValue: state.setSingleValue,
      setSingleValueArg: state.setSingleValueArg,
      setDefaultValue: state.setDefaultValue,
      setMultiValue: state.setMultiValue,
      setMultiValueArg: state.setMultiValueArg,
      mac: state.mac,
      phase: state.phase,
      number: state.number,
      imei: state.imei,
      id: state.id,
      ip: state.ip,
      typeId: state.typeId,
      simNum: state.simNum,
      simIccid: state.simIccid,
    }),
    shallow
  );

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const detailedInfo = [
    ...generateDetailedInfo(
      [number.value, FILTERS_LABELS.number],
      [id.value, FILTERS_LABELS.id],
      [mac.value, FILTERS_LABELS.mac],
      [imei.value, FILTERS_LABELS.imei],
      [phase.value as unknown as string, FILTERS_LABELS.phase],
      [typeId.value as unknown as string, FILTERS_LABELS.typeId]
    ),
  ];

  return (
    <BoxShadow sx={{ position: 'relative' }}>
      <Grid
        container
        spacing={0.5}
      >
        {(type === CatalogTypes.KOO || type === CatalogTypes.PU || type === CatalogTypes.STAMP) && (
          <Grid
            item
            xs={COL_7}
          >
            <SingleLine
              onChange={setSingleValue('number')}
              label={FILTERS_LABELS.number}
              onArgChange={setSingleValueArg('number')}
              value={number.value}
              argValue={number.argValue}
            />
          </Grid>
        )}
        <Grid
          item
          xs={COL_7}
        >
          <SingleLine
            onChange={setSingleValue('id')}
            label={FILTERS_LABELS.id}
            onArgChange={setSingleValueArg('id')}
            value={id.value}
            argValue={id.argValue}
          />
        </Grid>

        {(type === CatalogTypes.PU || type === CatalogTypes.KOO) && (
          <Grid
            item
            xs={COL_7}
          >
            <SingleLine
              onChange={setSingleValue('mac')}
              label={FILTERS_LABELS.mac}
              onArgChange={setSingleValueArg('mac')}
              value={mac.value}
              argValue={mac.argValue}
            />
          </Grid>
        )}
        {(type === CatalogTypes.PU || type === CatalogTypes.KOO) && (
          <Grid
            item
            xs={COL_7}
          >
            <SingleLine
              onChange={setSingleValue('imei')}
              label={FILTERS_LABELS.imei}
              onArgChange={setSingleValueArg('imei')}
              {...imei}
            />
          </Grid>
        )}
        {type === CatalogTypes.PU && (
          <Grid
            item
            xs={COL_7}
          >
            {/*<SingleLine*/}
            {/*  onChange={setSingleValue('phase')}*/}
            {/*  label={FILTERS_LABELS.phase}*/}
            {/*  onArgChange={setSingleValueArg('phase')}*/}
            {/*  {...phase}*/}
            {/*/>*/}
            <MultiSelect
              options={taskMapToArray(getCatalogMapWithErr('phase'))}
              label={FILTERS_LABELS.phase}
              onChange={setMultiValue('phase')}
              onArgChange={setMultiValueArg('phase')}
              {...phase}
            />
          </Grid>
        )}
        {(type === CatalogTypes.PU || type === CatalogTypes.KOO) && (
          <Grid
            item
            xs={COL_7}
          >
            <MultiSelect
              options={taskMapToArray(getCatalogMapWithErr('meterType'))}
              label={FILTERS_LABELS.typeId}
              onChange={setMultiValue('typeId')}
              onArgChange={setMultiValueArg('typeId')}
              {...typeId}
            />
          </Grid>
        )}
        {(type === CatalogTypes.KOO || type === CatalogTypes.SIM) && (
          <Grid
            item
            xs={COL_7}
          >
            <SingleLine
              onChange={setSingleValue('simIccid')}
              label={FILTERS_LABELS.simIccid}
              onArgChange={setSingleValueArg('simIccid')}
              value={simIccid.value}
              argValue={simIccid.argValue}
            />
          </Grid>
        )}
        {type === CatalogTypes.SIM && (
          <Grid
            item
            xs={COL_7}
          >
            <SingleLine
              onChange={setSingleValue('number')}
              label={FILTERS_LABELS.number}
              onArgChange={setSingleValueArg('number')}
              value={number.value}
              argValue={number.argValue}
            />
          </Grid>
        )}
        {type === CatalogTypes.KOO && (
          <Grid
            item
            xs={COL_7}
          >
            <SingleLine
              onChange={setSingleValue('simNum')}
              label={FILTERS_LABELS.simNum}
              onArgChange={setSingleValueArg('simNum')}
              value={simNum.value}
              argValue={simNum.argValue}
            />
          </Grid>
        )}
        {type === CatalogTypes.SIM && (
          <Grid
            item
            xs={COL_7}
          >
            <SingleLine
              onChange={setSingleValue('ip')}
              label={FILTERS_LABELS.ip}
              onArgChange={setSingleValueArg('ip')}
              value={ip.value}
              argValue={ip.argValue}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-end' }}
        >
          <FilterButtons
            info={detailedInfo}
            onSearchClick={onSubmit}
            onCloseClick={setDefaultValue}
          />
        </Grid>
      </Grid>
    </BoxShadow>
  );
};
