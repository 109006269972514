import { TextField } from '@/components/fixUI';
import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { Grid, Typography } from '@mui/material';
import { DeliveryPointTransformatorResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';

interface IFormTT {
  keyStr: string;
  meter?: DeliveryPointTransformatorResponse;
  disabledAll?: boolean;
}

export const FormTT: FC<IFormTT> = ({ meter = {}, keyStr, disabledAll = false }) => {
  const { register, setValue, watch, reset } = useFormContext();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  setValue(`${keyStr}.id`, meter?.id);
  useEffect(() => {
    setValue(`${keyStr}.typeTT`, meter?.typeTT);
    setValue(`${keyStr}.ktt`, meter?.ktt);
    setValue(`${keyStr}.ttNumberA`, meter?.ttNumberA);
    setValue(`${keyStr}.ttNumberB`, meter?.ttNumberB);
    setValue(`${keyStr}.ttNumberC`, meter?.ttNumberC);
  }, [keyStr]);

  return (
    <Grid
      item
      xs={12}
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant={'body1'}>Информация о ТТ</Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Тип ТТ'
          sx={{ width: '100%' }}
          fieldName={`${keyStr}.typeTT`}
          values={getCatalogMapWithErr('tpType')}
          defaultValue={meter?.typeTT}
          disabled={disabledAll}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Коэффициент ТТ'
          sx={{ width: '100%' }}
          fieldName={`${keyStr}.ktt`}
          defaultValue={meter?.ktt}
          values={getCatalogMapWithErr('ktt')}
          disabled={disabledAll}
        />
      </Grid>
      <Grid
        item
        xs={4}
      >
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography
            variant={'body1'}
            sx={{ mb: 0.5, mr: 1 }}
          >
            A
          </Typography>
          <TextField
            id='ttNumberA'
            label='Номер ТТ'
            variant='standard'
            placeholder='отсутствует'
            fullWidth
            {...register(`${keyStr}.ttNumberA`, {
              value: meter?.ttNumberA,
            })}
            disabled={disabledAll}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography
            variant={'body1'}
            sx={{ mb: 0.5, mr: 1 }}
          >
            B
          </Typography>
          <TextField
            id='ttNumberB'
            label='Номер ТТ'
            variant='standard'
            placeholder='отсутствует'
            fullWidth
            {...register(`${keyStr}.ttNumberB`, {
              value: meter?.ttNumberB,
            })}
            disabled={disabledAll}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography
            variant={'body1'}
            sx={{ mb: 0.5, mr: 1 }}
          >
            C
          </Typography>
          <TextField
            id='ttNumberC'
            label='Номер ТТ'
            variant='standard'
            placeholder='отсутствует'
            fullWidth
            {...register(`${keyStr}.ttNumberC`, {
              value: meter?.ttNumberC ?? '',
            })}
            InputLabelProps={{ shrink: true }}
            disabled={disabledAll}
          />
        </div>
      </Grid>
    </Grid>
  );
};
