import { Placemark } from 'react-yandex-maps';
import { GeoTaskInBounds, GeoTask } from '@/dto/taskmap/Dto';

export const TaskScopePlacemark = (props: any) => {
  const geoTaskInBounds: GeoTaskInBounds = props.geoTaskInBounds;
  const geoTaskList: GeoTask[] = geoTaskInBounds?.geoTaskList;
  const geoCentroid = geoTaskInBounds.geoCentroid;
  return (
    geoTaskList && (
      <Placemark
        geometry={[geoCentroid.x, geoCentroid.y]}
        options={{
          preset: 'islands#blueStretchyIcon',
          iconColor: 'blue',
        }}
        properties={{
          iconContent: geoTaskInBounds?.networkResArea + ' - ' + geoTaskList.length,
        }}
      />
    )
  );
};
