import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button, Grid } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';

import { useCablePopup } from './store';
import { PpoCableResponse } from '@/dto/taskmap/task/TaskResultDto';
import { deletePpoVruCable, editPpoVruCableList, addPpoVruCable } from '@/services/TaskService';
import { AutocompleteValue } from '../../inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { useActionLog, ActionLogType } from '@/hooks/ActionLogHook';
import {
  CablePopupFormInputWrapper,
  CablePopupFormWrapper,
  CablePopupIconButton,
  CablePopupFormButtonsWrapper,
  CablePopupFormRow,
} from './CablePopup.styled';
import { TextField } from '@/components/fixUI';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { useDisabledAll } from '@/hooks/useAccess';

interface CablePopupFormData {
  [x: string]: PpoCableResponse;
}

const CablePopupForm = () => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const methods = useForm();
  const { register, handleSubmit, reset } = methods;

  const { setIsOpen, cableList, ppoVruInputId, forceReload, addCable, deleteCable } = useCablePopup(
    (state) => ({
      setIsOpen: state.setIsCablePopupOpen,
      forceReload: state.forceReload,
      cableList: state.cableList,
      ppoVruInputId: state.ppoVruInputId,
      addCable: state.addCable,
      deleteCable: state.deleteCable,
    })
  );

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { catchError, addActionLog } = useActionLog();
  const [isLoading, setIsLoading] = useState(false);

  const successActions = (successText: string, withClose = false) => {
    forceReload();
    addActionLog(ActionLogType.SUCCESS, successText);
    withClose && handleClose();
  };

  const handleClose = () => setIsOpen(false);

  const handleEdit = (data: CablePopupFormData) => {
    const newCableList = [];
    for (const cableId in data) {
      const cable = data[cableId];
      cable.type = cable.type ?? null;
      cable.id = cableId;
      newCableList.push(cable);
    }

    setIsLoading(true);
    editPpoVruCableList(newCableList)
      .then(() => successActions('Успешное редактирование списка кабелей', true))
      .catch((err) => catchError('Ошибка редактирования списка кабелей', err))
      .finally(() => setIsLoading(false));
  };

  const handleAdd = async () => {
    setIsLoading(true);
    addPpoVruCable(ppoVruInputId)
      .then(({ data }) => {
        addCable(data);
        successActions('Успешное добавление кабеля');
      })
      .catch((err) => catchError('Ошибка добавление кабеля', err))
      .finally(() => setIsLoading(false));
  };

  const handleDelete = async (cableId: string) => {
    setIsLoading(true);
    deletePpoVruCable(cableId)
      .then(() => {
        deleteCable(cableId);
        successActions('Успешное удаления кабеля');
        reset();
      })
      .catch((err) => catchError('Ошибка удаления кабеля', err))
      .finally(() => setIsLoading(false));
  };

  return (
    <FormProvider {...methods}>
      <CablePopupFormWrapper disabled={isLoading}>
        {cableList?.map((cabel, index) => (
          <CablePopupFormRow key={cabel?.id + index}>
            <CablePopupIconButton
              onClick={() => handleDelete(cabel.id)}
              disabled={disabledAll || onlyShow}
            >
              <DeleteForeverOutlinedIcon />
            </CablePopupIconButton>

            <CablePopupFormInputWrapper>
              <AutocompleteValue
                title='Тип'
                defaultValue={cabel?.cableCompositionId?.toString()}
                fieldName={`${cabel?.id}.cableCompositionId`}
                values={getCatalogMapWithErr('ppoCableComposition')}
              />
            </CablePopupFormInputWrapper>
            <CablePopupFormInputWrapper>
              <TextField
                id='cable-mark'
                label='Марка'
                variant='standard'
                defaultValue={cabel?.brand}
                {...register(`${cabel?.id}.brand`, {
                  value: cabel?.brand,
                })}
              />
            </CablePopupFormInputWrapper>
            <CablePopupFormInputWrapper>
              <TextField
                id='cable-count'
                label='Количество жил'
                variant='standard'
                defaultValue={cabel?.linesNumber}
                {...register(`${cabel?.id}.linesNumber`, {
                  value: cabel?.linesNumber,
                })}
              />
            </CablePopupFormInputWrapper>
            <CablePopupFormInputWrapper>
              <AutocompleteValue
                title='Сечение'
                defaultValue={cabel?.sectionId?.toString()}
                fieldName={`${cabel?.id}.sectionId`}
                values={getCatalogMapWithErr('cableSection')}
              />
            </CablePopupFormInputWrapper>
          </CablePopupFormRow>
        ))}

        <Grid>
          <Button
            onClick={handleAdd}
            startIcon={<AddIcon />}
            disabled={disabledAll || onlyShow}
          >
            Добавить
          </Button>
        </Grid>

        <Grid>
          <CablePopupFormButtonsWrapper>
            <LoadingButton
              loading={isLoading}
              onClick={handleSubmit(handleEdit)}
              disabled={disabledAll || onlyShow}
            >
              Сохранить
            </LoadingButton>
            <Button onClick={handleClose}>Отменить</Button>
          </CablePopupFormButtonsWrapper>
        </Grid>
      </CablePopupFormWrapper>
    </FormProvider>
  );
};

export default CablePopupForm;
