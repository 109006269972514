import { HOST } from './YodaRestService';
import { axiosGet, axiosPost, axiosPut } from './helpers';
import { AxiosResponse } from 'axios';
import { AdpuRequest, AdpuResponse, MailNotificationResponse } from '@/dto/AdpuDto';
import { ApiResponse } from '@/types/api';

export async function getAdpuById(adpuId: string): ApiResponse<any> {
  const url = HOST + `/rest/adpu/${adpuId}`;
  return axiosGet(url);
}

export async function getAdpuByTaskId(taskId: string): ApiResponse<any> {
  const url = HOST + `/rest/adpu/task/${taskId}`;
  return axiosGet(url);
}

export async function getAdpuOrder(adpuOrderId: string): ApiResponse<any> {
  const url = HOST + `/rest/adpu/adpu-order/${adpuOrderId}`;
  return axiosGet(url);
}
export async function getMailNotification(
  taskId: string
): Promise<AxiosResponse<MailNotificationResponse, any>> {
  const url = HOST + `/rest/task/${taskId}/postal-departure`;
  return axiosGet(url);
}

export async function createAdpu(data: AdpuRequest): ApiResponse<AdpuResponse> {
  const url = HOST + `/rest/adpu`;
  return axiosPost(url, data);
}

export async function updateAdpu(adpuId: string, data: AdpuRequest): ApiResponse<AdpuResponse> {
  const url = HOST + `/rest/adpu/${adpuId}`;
  return axiosPut(url, data);
}
