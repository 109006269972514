import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';

import ListItemText from '@material-ui/core/ListItemText';
import { Button } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { color } from '@/styles/mixins';

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)',
  },
});

export type DraggableListItemProps = {
  item: any;
  index: number;
  handelDelete: (id: string) => void;
};

const DraggableListItem = ({ item, index, handelDelete }: DraggableListItemProps) => {
  const classes = useStyles();
  return (
    <Draggable
      draggableId={item.id}
      index={index}
    >
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ''}
        >
          <ListItemText primary={item.label} />

          <DeleteForeverOutlinedIcon
            style={{ cursor: 'pointer', color: color('trueRed') }}
            onClick={() => handelDelete(item.id)}
          />
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
