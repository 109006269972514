import { PpoCableResponse } from '@/dto/taskmap/task/TaskResultDto';
import { createStore } from '../../../utils/createStore';

interface ICablePopup {
  isCablePopupOpen: boolean;
  cableList: PpoCableResponse[];
  ppoVruInputId: string;
  reloadCounter: number;

  setIsCablePopupOpen: (isOpen: boolean) => void;
  setCableList: (cables: PpoCableResponse[], ppoVruInputId: string) => void;
  addCable: (cable: PpoCableResponse) => void;
  deleteCable: (cableId: string) => void;
  forceReload: () => void;
}

const defaultValue: ICablePopup = {
  ppoVruInputId: '',
  isCablePopupOpen: false,
  cableList: [],
  reloadCounter: 0,
  setIsCablePopupOpen: () => null,
  setCableList: () => null,
  addCable: () => null,
  deleteCable: () => null,
  forceReload: () => null,
};

export const useCablePopup = createStore<ICablePopup>(
  (set, get) => ({
    ...defaultValue,

    setIsCablePopupOpen: (isOpen) => set({ isCablePopupOpen: isOpen }),

    setCableList: (cables, ppoVruInputId) =>
      set({
        cableList: cables,
        ppoVruInputId: ppoVruInputId,
      }),

    addCable: (cabel) => set({ cableList: [...get().cableList, cabel] }),

    deleteCable: (cableId) => {
      set({ cableList: [...get().cableList.filter((cable) => cable.id !== cableId)] });
    },

    forceReload: () => set(() => ({ reloadCounter: get().reloadCounter + 1 })),
  }),
  'Cable popup'
);
