import pdfPatch from '@/images/patch/PdfPatсh.png';
import excelPatch from '@/images/patch/ExcelPatch.png';

export const NOT_DEFINED = 'NOT_DEFINED';

export const getImageUrl = (
  imageId: string,
  getImageUrlById: (id: string) => string,
  contentType?: string
) => {
  if (!contentType || contentType === NOT_DEFINED) return getImageUrlById(imageId);
  if (contentType.includes('pdf')) {
    return pdfPatch;
  }
  if (contentType.includes('sheet') || contentType.includes('text/plain')) {
    return excelPatch;
  }
  return getImageUrlById(imageId);
};
