import React, { useState } from 'react';
import { Badge, BadgeProps, Calendar, Spin } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'dayjs/locale/ru';
import dayjs from 'dayjs'; // Добавляем импорт dayjs
import Select from '@mui/material/Select';
import { Grid, MenuItem, Stack } from '@mui/material';
import { color } from '@/styles/mixins';
import { useCallingMapState } from '../store';
import shallow from 'zustand/shallow';
import { checkName, countMapKey } from '../utils/CallingMapUtils';
import { useCatalog } from '@/hooks/CatalogHook';
import { StyledSpan } from './СallingMap.styled';
import type { Dayjs } from 'dayjs';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { getExecutorsSchedule } from '@/services/CallingMapService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';

dayjs.locale('ru');

const getCellColor = (date: Dayjs, selectedDate: Dayjs | null) => {
  const isToday = dayjs(date).isSame(dayjs(), 'date');
  const isInSelectedMonth = dayjs(date).isSame(selectedDate ? selectedDate : dayjs(), 'month');

  if (isToday) {
    return color('slightYellow');
  } else if (!isInSelectedMonth) {
    return color('slightGray');
  }
};

const EventCalendar: React.FC = (props: any) => {
  const { selectExecotorSchedule, setSelectExecotorSchedule } = useCallingMapState(
    (state) => ({
      selectExecotorSchedule: state.selectExecotorSchedule,
      setSelectExecotorSchedule: state.setSelectExecotorSchedule,
    }),
    shallow
  );
  const { catchError } = useActionLog();
  const { setIsInProgress, isInProgress } = useScreenHoldHook();

  const taskGroupMap = useCatalog((state) => state.taskGroupMap);
  const { workloadDays } = selectExecotorSchedule || {};

  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);

  const handleExcutorMonthChange = (month: number) => {
    const startOfMonth = moment().month(month).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().month(month).endOf('month').format('YYYY-MM-DD');
    setIsInProgress(true);
    getExecutorsSchedule(startOfMonth, endOfMonth, selectExecotorSchedule?.userId)
      .then(({ data: { workloadExecutors } }) => {
        if (workloadExecutors.length) {
          if (selectExecotorSchedule) {
            const mergeExcutor = {
              ...selectExecotorSchedule,
              workloadDays: workloadExecutors[0].workloadDays,
            };
            setSelectExecotorSchedule(mergeExcutor);
          }
        } else {
          setSelectExecotorSchedule(selectExecotorSchedule);
        }
      })
      .catch((err) => {
        catchError(err?.response?.data?.message ?? 'Ошибка', err);
      })
      .finally(() => setIsInProgress(false));
  };
  return (
    <Calendar
      onPanelChange={(value) => {
        const now = value.clone().month();
        handleExcutorMonthChange(now);
      }}
      onSelect={(date) => setSelectedDate(dayjs(date))}
      locale={locale}
      fullCellRender={(date) => {
        const isToday = dayjs(date).isSame(dayjs(), 'date');
        const getListData = (value: Dayjs) => {
          const currentDate = value.format('YYYY-MM-DD');
          const currentDayTasks = workloadDays?.find(
            (task: any) => dayjs(task.date).format('YYYY-MM-DD') === currentDate
          );
          return currentDayTasks ? (
            <Stack
              style={{
                display: 'flex',
                alignItems: 'self-start',
                padding: 5,
              }}
            >
              {!!currentDayTasks.countMap[countMapKey?.amCount] && (
                <>
                  <Typography
                    variant='subtitle2'
                    style={{ fontSize: 13 }}
                  >
                    <StyledSpan customColor='orange'>
                      {currentDayTasks.countMap[countMapKey?.amCount] && `1-я половина дня: `}
                    </StyledSpan>
                    {currentDayTasks.countMap[countMapKey?.amCount]}
                  </Typography>
                </>
              )}
              {!!currentDayTasks.countMap[countMapKey?.pmCount] && (
                <>
                  <Typography
                    variant='subtitle2'
                    style={{ fontSize: 13 }}
                  >
                    <StyledSpan customColor={color('blue')}>
                      {!!currentDayTasks.countMap[countMapKey?.pmCount] && `2-я половина дня: `}
                    </StyledSpan>

                    {currentDayTasks.countMap[countMapKey?.pmCount]}
                  </Typography>
                </>
              )}
              {!!currentDayTasks.countMap[countMapKey?.anyCount] && (
                <>
                  <Typography
                    variant='subtitle2'
                    style={{ fontSize: 13 }}
                  >
                    <StyledSpan customColor={color('purple')}>
                      {!!currentDayTasks.countMap[countMapKey?.anyCount] && `В любое время: `}
                    </StyledSpan>

                    {currentDayTasks.countMap[countMapKey?.anyCount]}
                  </Typography>
                </>
              )}
            </Stack>
          ) : null;
        };
        return (
          <Stack
            className='calendar-cell'
            borderRadius={1}
            style={{
              height: 120,
              width: 155,
              border: '1px solid grey',
              margin: 1,
              backgroundColor: getCellColor(date, selectedDate),
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack
              mb={4}
              pr={1}
            >
              <Typography variant='subtitle2'>{date.date()}</Typography>
            </Stack>

            {isInProgress ? <Spin /> : <Stack>{getListData(date)}</Stack>}
          </Stack>
        );
      }}
      mode='month' // Устанавливаем режим календаря только на выбор месяца
      headerRender={({ value, onChange }) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];
        let current = value.clone();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
          current = current.month(i);
          months.push(localeData.monthsShort(current));
        }

        for (let i = start; i < end; i++) {
          monthOptions.push({ value: i, label: months[i] });
        }

        const year = value.year();
        const month = value.month();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
          options.push({ value: i, label: i });
        }

        return (
          <Stack
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={2}
          >
            <Grid
              item
              xs={6}
              container
              spacing={2}
            >
              <Grid
                item
                xs={3}
              >
                <Select
                  value={monthOptions[month].value}
                  label='Месяц'
                  fullWidth
                  variant='standard'
                  onChange={(e) => {
                    const now = value.clone().month(+e.target.value);
                    handleExcutorMonthChange(+e.target.value);
                    onChange(now);
                  }}
                >
                  {monthOptions.map((m) => (
                    <MenuItem
                      value={m.value}
                      key={m.value}
                    >
                      {m.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={3}
              >
                <Select
                  value={year}
                  label='Год'
                  variant='standard'
                  fullWidth
                  onChange={(e) => {
                    const now = value.clone().year(+e.target.value);
                    onChange(now);
                  }}
                >
                  {options.map((year) => (
                    <MenuItem
                      value={year.value}
                      key={year.value}
                    >
                      {year.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid item>
              {checkName(selectExecotorSchedule)}
              <StyledSpan>
                {' '}
                {selectExecotorSchedule?.groupId &&
                  `(${taskGroupMap.get(selectExecotorSchedule.groupId)})`}
              </StyledSpan>
            </Grid>
          </Stack>
        );
      }}
    />
  );
};

export default EventCalendar;
