import { AxiosResponse } from 'axios';
import { HOST } from '@/services/YodaRestService';
import { axiosGet, axiosPost, axiosPut } from '@/services/helpers';
import { GeoTransStation, PpoFilterRequest } from './dto';
import { GeoTaskInBounds } from '@/dto/taskmap/Dto';
import { SubscriberResponse } from '@/dto/taskmap/task/TaskResultDto';

export const findAllByFilter = (
  filter: PpoFilterRequest,
  isNeedDetails?: boolean
): Promise<AxiosResponse<GeoTaskInBounds[], any>> => {
  const url = HOST + `/rest/geo/ppo`;
  return axiosPost(url, { ...filter, isNeedDetails: isNeedDetails ?? false });
};
export const getReportEnergyservice = (
  tpId: string
): Promise<AxiosResponse<GeoTaskInBounds[], any>> => {
  const url = HOST + `/rest/report/stolby/energyservice/${tpId}`;
  return axiosGet(url);
};

export const findAllDeliveryPoint = (
  taskId: string
): Promise<AxiosResponse<GeoTransStation, any>> => {
  const url = HOST + `/rest/geo/ppo/${taskId}`;
  return axiosGet(url);
};

interface EditAddressData {
  region?: string | null;
  areaName?: string | null;
  locality?: string | null;
  street?: string | null;
  house?: string | null;
  apartment?: string | null;
}
export const editeAddress = (
  subId: string,
  data: EditAddressData
): Promise<AxiosResponse<SubscriberResponse, any>> => {
  const url = HOST + `/rest/subscriber/${subId}/modified-address`;
  return axiosPut(url, data);
};
