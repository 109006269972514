import { YMaps, Map, Placemark, Clusterer, GeoObject, ObjectManager } from 'react-yandex-maps';
import { useCallingMapState } from '../store';
import { getSingleTaskfromCallMap, getTasksByIdsCallMap } from '@/services/CallingMapService';
import React, { useEffect, useRef, useState } from 'react';
import shallow from 'zustand/shallow';
import { IgeoPointCallingMap } from '../types/types';
import {
  MOSCOW_CENTER_COORDINATES,
  createGeoJSONRectangle,
  getPointsFromCluster,
  isNumberInRange,
  renderColor,
  splitGeoJSONByGrid,
} from '../utils/CallingMapUtils';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { catchError } from '@/hooks/ActionLogHook';
import { color } from '@/styles/mixins';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const turf = window?.turf;

const CallingMapInner = ({
  setMapAPI,
  setZoomState,
  setDiffGeojson,
  diffGeojson,
  singleSearch,
}: any) => {
  const {
    geoPoints,
    setSingleGeoPoint,
    setTaskId,
    urgentGeoPoints,
    toggleUrgentGeoPoins,
    taskId,
    setBounds,
    setMapZoom,
    setTKO,
    setRequestHistory,
    singleGeoPoint,
    mapZoom,
  } = useCallingMapState(
    (state) => ({
      geoPoints: state.geoPoints,
      toggleUrgentGeoPoins: state.toggleUrgentGeoPoins,
      setSingleGeoPoint: state.setSingleGeoPoint,
      setTaskId: state.setTaskId,
      taskId: state.taskId,
      urgentGeoPoints: state.urgentGeoPoints,
      setBounds: state.setBounds,
      setInitBounds: state.setInitBounds,
      bounds: state.bounds,
      setMapZoom: state.setMapZoom,
      setMapAPI: state.setMapAPI,
      isAuthorized: state.isAuthorized,
      setTKO: state.setTKO,
      setRequestHistory: state.setRequestHistory,
      singleGeoPoint: state.singleGeoPoint,

      mapZoom: state.mapZoom,
    }),
    shallow
  );
  const { setIsInProgress, isInProgress } = useScreenHoldHook();

  const [visiblePointsCount, setVisiblePointsCount] = useState(0);
  const [geoPointsInCluster, setGeoPointsInCluster] = useState<Record<string, any>>({});

  const [forceCluster, setForceUpdateCluster] = useState(1);
  const [prevZoom, setPrevZoom] = useState(null);
  const [polygons, setPolygons] = useState([]);

  const mapRef = useRef<any>(null);
  const clusterRef = useRef<any>(null);
  const objectManagerRef = useRef<any>(null);

  const handleBoundsChange = (event: any) => {
    const map = mapRef.current;
    if (!map) return;
    setMapZoom(map._zoom);
    const bounds: number[][] = map?.getBounds();
    const newZoom = event?.get('newZoom');
    const oldBounds = event?.get('oldBounds');

    const newBounds = event?.get('newBounds');
    if (oldBounds?.length && newBounds?.length) {
      if (newBounds?.length && oldBounds?.length) {
        const oldPolygon1 = createGeoJSONRectangle(oldBounds[0], oldBounds[1]);
        const newPolygon2 = createGeoJSONRectangle(newBounds[0], newBounds[1]);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const difference = turf?.difference(newPolygon2, oldPolygon1);

        setDiffGeojson(difference);
      }
    }

    if (prevZoom !== null) {
      if (newZoom > prevZoom) {
        setZoomState('IN');
      } else if (newZoom < prevZoom) {
        setZoomState('OUT');
      } else if (newZoom === prevZoom) {
        setZoomState('SAME');
      }
    }
    setPrevZoom(newZoom);

    if (map?.balloon?._balloon?._state == 'CLOSED' || !map?.balloon?._balloon?._state) {
      setBounds(bounds);
    }

    if (toggleUrgentGeoPoins && urgentGeoPoints.length) {
      const pointsInBounds = urgentGeoPoints.filter((point) => isPointInBounds(point, bounds));
      setVisiblePointsCount(pointsInBounds.length);
    } else if (!toggleUrgentGeoPoins && geoPoints.length) {
      const pointsInBounds = geoPoints.filter((point) => isPointInBounds(point, bounds));

      setVisiblePointsCount(pointsInBounds.length);
    } else {
      setVisiblePointsCount(0);
    }
  };

  const isPointInBounds = (point: IgeoPointCallingMap, bounds: number[][]) => {
    const [longitude, latitude] = [point.longitudeX, point.latitudeY];
    const [southWest, northEast] = bounds;

    const isLongitudeInRange = longitude >= southWest[0] && longitude <= northEast[0];
    const isLatitudeInRange = latitude >= southWest[1] && latitude <= northEast[1];
    return isLongitudeInRange && isLatitudeInRange;
  };

  const getInfoFromTask = (taskId: string) => {
    setIsInProgress(true);
    setTKO([]);
    setRequestHistory([]);
    getSingleTaskfromCallMap(taskId)
      .then(({ data }) => {
        setSingleGeoPoint(data);
        setTaskId(taskId);
      })
      .catch((error) => catchError('Ошибка загрузки информации о заявке', error))
      .finally(() => setIsInProgress(false));
  };

  useEffect(() => {
    handleBoundsChange(null);
    const map = mapRef.current;
    if (map) {
      map.events.add('boundschange', handleBoundsChange);
    }
    // unsubscribe on component unmount
    return () => {
      if (map) {
        map.events.remove('boundschange', handleBoundsChange);
      }
    };
  }, [geoPoints, urgentGeoPoints, toggleUrgentGeoPoins]);

  useEffect(() => {
    setForceUpdateCluster((prev) => prev + 1);
  }, [geoPoints, urgentGeoPoints]);

  const makeAssociativeArray = (data: any) => {
    const result: Record<string, any> = {};
    data.forEach((el: any) => {
      if (!result[el.taskId]) {
        result[el.taskId] = el;
      }
    });
    return result;
  };

  const getTasksInfoByIds = (ids: string[]) => {
    setIsInProgress(true);
    getTasksByIdsCallMap(ids)
      .then(({ data }) => {
        setGeoPointsInCluster(makeAssociativeArray(data));
      })
      .catch((error) => catchError('Ошибка загрузки информации о заявках', error))
      .finally(() => setIsInProgress(false));
  };

  function getHint(obj: any): string {
    if (obj) {
      return ` <div style="margin-bottom: 2px;"
             <div style="padding: 12px; border-left-width: 20px; cursor: pointer;"
                  <div style="display: flex; margin-bottom: 2px;">
                      <div style="flex: 2;">
                        <Typography style="color: ${color(
                          'typographyColorMap2'
                        )}">л/с №</Typography>
                        <Typography style="color: ${color('typographyColorMap1')}">
                        ${
                          geoPointsInCluster[obj?.taskId]?.accountingNumber ||
                          singleGeoPoint?.accountingNumber ||
                          'неизвестно'
                        }
                          </Typography>
                       </div>
                  <div style="flex: 9; display: flex; justify-content: space-between;">
               </div>
          <div style="display: flex;">
        <div style="flex: 2;">
          <Typography style="color: ${color('typographyColorMap2')}">Адрес: </Typography>
        </div>
        <div style="flex: 12;">
         
        </div>
      </div>
       <Typography> 
          ${geoPointsInCluster[obj?.taskId]?.addressTitle || singleGeoPoint?.address}
          ${
            (geoPointsInCluster[obj?.taskId]?.apartmentNumber &&
              `кв.${geoPointsInCluster[obj?.taskId]?.apartmentNumber}`) ||
            (singleGeoPoint?.apartmentNumber && `кв.${singleGeoPoint?.apartmentNumber}`) ||
            'кв. неизвестна'
          }
          </Typography>
    </div>
     <div> <p style="cursor: pointer;  color: ${color('muiBlue')}" data-info="${
        obj.taskId
      }" style="color: ${color('typographyColorMap1')}">
        Выбрать
          </p></div>
  </div`;
    }
    return '';
  }

  useEffect(() => {
    const handleClick = (event: any) => {
      const neededInfo = event.target.dataset.info;
      if (neededInfo) {
        getInfoFromTask(neededInfo);
      }
    };
    const map = document.getElementById('callingMap');
    if (map) {
      map.addEventListener('click', handleClick);
    }

    return () => {
      if (map) {
        map.removeEventListener('click', handleClick);
      }
    };
  }, [geoPointsInCluster]);

  useEffect(() => {
    const res = splitGeoJSONByGrid(diffGeojson, prevZoom || 5);
    setPolygons(res);
  }, [diffGeojson]);
  const features = geoPoints.map((point, index) => ({
    type: 'Feature',
    id: index,
    geometry: {
      type: 'Point',
      coordinates: [point.longitudeX, point.latitudeY],
    },
    properties: {
      point,
      balloonContentHeader: getHint(point),
      balloonContentBody: `ID заявки № ${point.taskId}`,
      options: {
        preset: taskId === point.taskId && 'islands#dotIcon',
        iconColor: renderColor(point.installationCallGeneralStatus),
        iconImageSize: [36, 36],
      },
    },
  }));

  const handleClickOnObjManager = (e: any) => {
    // Используем айдишник для того, чтобы далее получить инфу по метке
    if (!isNumberInRange(mapZoom)) {
      return;
    }
    const objectId = e?.get('objectId');
    const taskId = objectManagerRef?.current?.objects?.getById(objectId)?.properties.point?.taskId;
    getInfoFromTask(taskId);
    objectManagerRef?.current?.balloon?.open(objectId);
  };

  useEffect(() => {
    if (objectManagerRef.current) {
      objectManagerRef.current.objects.events.add('click', handleClickOnObjManager);
    }
    return () => {
      if (objectManagerRef.current) {
        objectManagerRef.current.objects.events.remove('click', handleClickOnObjManager);
      }
    };
  }, [objectManagerRef.current, singleSearch]);

  useEffect(() => {
    if (!isNumberInRange(mapZoom) && !toggleUrgentGeoPoins) {
      setSingleGeoPoint(null);
      setTaskId('');
    }
  }, [mapZoom, singleSearch]);

  useEffect(() => {
    if (singleSearch) {
      geoPoints.length === 1 && getInfoFromTask(geoPoints[0].taskId);
    }
  }, [singleSearch, geoPoints]);

  return (
    <YMaps
      query={{
        ns: 'use-load-option',
        load: 'Map,Placemark,control.ZoomControl,geoObject.addon.balloon,geoObject.addon.hint,layout.PieChart', // тут менять кластеры  layout.PieChart
        coordorder: 'longlat',
      }}
    >
      <Map
        id={'callingMap'}
        instanceRef={(instance) => {
          mapRef.current = instance;
          setMapAPI(instance);
        }}
        modules={['geoQuery']}
        defaultState={{
          center: MOSCOW_CENTER_COORDINATES,
          zoom: 5,
          controls: ['zoomControl'],
        }}
        onBoundsChange={handleBoundsChange}
        width={'100%'}
        height={'85vh'}
        options={{
          autoFitToViewport: 'always',
          // eslint-disable-next-line no-useless-escape, @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // restrictMapArea: [
          //   [-20, 30], // Западная граница (примерно Шотландия)
          //   [180, 80], // Восточная граница (примерно Камчатка)
          // ],
        }}
      >
        {mapZoom >= 16 || toggleUrgentGeoPoins ? (
          <>
            <Clusterer
              onClick={getPointsFromCluster(getTasksInfoByIds)}
              key={forceCluster}
              options={{
                clusterIconLayout: 'default#pieChart',
                // Радиус диаграммы в пикселях.
                clusterIconPieChartRadius: 25,
                // Радиус центральной части макета.
                clusterIconPieChartCoreRadius: 10,
                // Ширина линий-разделителей секторов и внешней обводки диаграммы.
                clusterIconPieChartStrokeWidth: 3,
                // Определяет наличие поля balloon.
                groupByCoordinates: false,
                clusterDisableClickZoom: true,
                clusterHideIconOnBalloonOpen: false,
                geoObjectHideIconOnBalloonOpen: false,
                hasBalloon: true, // Enabling balloon for clusters
                clusterBalloonContentLayout: 'cluster#balloonAccordionContent', // Defining the layout for cluster balloon content
                clusterBalloonContentLayoutWidth: 'auto',
                clusterBalloonContentLayoutHeight: 280,
                clusterBalloonMinWidth: 650,
                clusterBalloonMaxHeight: 900,
              }}
              modules={['clusterer.addon.balloon']} // эта строчка делает балун для кластера
            >
              {!toggleUrgentGeoPoins &&
                geoPoints.map((n, i: number) => (
                  <Placemark
                    onClick={() => {
                      if (!isNumberInRange(mapZoom)) {
                        return;
                      } else {
                        getInfoFromTask(n.taskId);
                      }
                    }}
                    geometry={[n.longitudeX, n.latitudeY]}
                    key={n.taskId + i}
                    options={{
                      preset: taskId === n.taskId && 'islands#dotIcon',
                      iconColor: renderColor(n.installationCallGeneralStatus),
                      iconImageSize: [36, 36],
                      balloonMinWidth: 650,
                    }}
                    properties={{
                      point: n,
                      balloonContentHeader: getHint(n),
                      balloonContentBody: `ID заявки № ${n.taskId}`,
                    }}
                  />
                ))}
              {toggleUrgentGeoPoins &&
                urgentGeoPoints.map((n, i: number) => (
                  <Placemark
                    onClick={() => {
                      getInfoFromTask(n.taskId);
                    }}
                    geometry={[n.longitudeX, n.latitudeY]}
                    key={n.taskId + i}
                    options={{
                      preset: taskId === n.taskId ? 'islands#dotIcon' : 'islands#blueAttentionIcon',
                      iconColor: renderColor(n.installationCallGeneralStatus),
                      iconImageSize: taskId === n.taskId ? [156, 156] : [36, 36],
                    }}
                    properties={{
                      point: n,
                      balloonContentHeader: getHint(n),
                      balloonContentBody: `ID заявки № ${n.taskId}`,
                    }}
                  />
                ))}
            </Clusterer>
          </>
        ) : (
          <>
            {!toggleUrgentGeoPoins && (
              <ObjectManager
                options={{
                  clusterize: false,
                  gridSize: 32,
                  openBalloonOnClick: false,
                }}
                objects={{
                  openBalloonOnClick: false,
                  preset: 'islands#blueCircleDotIcon',
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                features={features}
                instanceRef={(instance) => {
                  objectManagerRef.current = instance;
                }}
                modules={['objectManager.addon.objectsBalloon', 'objectManager.addon.objectsHint']}
              />
            )}
          </>
        )}
      </Map>
    </YMaps>
  );
};
export default CallingMapInner;
