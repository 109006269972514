import { useComplaintStore } from '../store';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import shallow from 'zustand/shallow';
import { EComplaintType } from '../types';

const ComplaintQuerySearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { fetchSingleComplaint, handleSearch, selectedCompliant } = useComplaintStore(
    (state) => ({
      fetchSingleComplaint: state.fetchSingleComplaint,
      handleSearch: state.handleSearch,
      selectedCompliant: state.selectedCompliant,
    }),
    shallow
  );

  useEffect(() => {
    const id = searchParams.get('id');
    const type = searchParams.get('type');

    if (id && (type === EComplaintType.ENERGY_SERVICE || type === EComplaintType.ODPU)) {
      fetchSingleComplaint(+id, type, true);
    } else {
      handleSearch();
    }
  }, []);

  useEffect(() => {
    const id = selectedCompliant?.complaint?.base?.id;
    const type = selectedCompliant?.complaint?.type;
    if (id && type) {
      setSearchParams({
        id: id.toString(),
        type: type,
      });
    } else {
      searchParams.delete('id');
      searchParams.delete('type');

      setSearchParams(searchParams);
    }
  }, [selectedCompliant?.complaint?.base?.id]);

  return null;
};

export default ComplaintQuerySearch;
