import { AxiosResponse } from 'axios';
import { HOST } from './YodaRestService';
import { axiosDelete, axiosGet, axiosGetExl, axiosPost } from './helpers';
import {
  Emails,
  IFindAllRegistryByOdpuIdItem,
  IFindAllRegistryByYearItem,
  IFindAllRegistryEmailById,
  IFindAllRegistryTasksByParams,
  IFindManagementCompanyById,
  IGetRegistryEnforseTable,
} from '@/dto/RegistryDto';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';

export async function findAllRegistryByYear(
  year: number
): Promise<AxiosResponse<IFindAllRegistryByYearItem[], any>> {
  const url = HOST + `/rest/odpu-schedule/filter?year=${year}`;
  return axiosGet(url);
}

export async function findRegistryById(
  id: string
): Promise<AxiosResponse<IFindAllRegistryByYearItem, any>> {
  const url = HOST + `/rest/odpu-schedule/filter?id=${id}`;
  return axiosGet(url);
}

export async function createNewRegistry(data: {
  month: number;
  year: number;
}): Promise<AxiosResponse<IFindAllRegistryByYearItem, any>> {
  const url = HOST + `/rest/odpu-schedule/`;
  return axiosPost(url, data);
}

export async function findAllRegistryByOdpuId(
  id: string
): Promise<AxiosResponse<IFindAllRegistryByOdpuIdItem[], any>> {
  const url = HOST + `/rest/odpu-schedule/${id}/items`;
  return axiosGet(url);
}

export async function findAllRegistryTasksByParams(
  type?: string,
  adress?: string,
  managementCompany?: string
): Promise<AxiosResponse<IFindAllRegistryTasksByParams[], any>> {
  const url =
    HOST +
    `/rest/task/schedule?&type=${type ? type : ''}&address=${
      adress ? adress : ''
    }&managementCompanyId=${managementCompany ? managementCompany : ''}`;
  return axiosGet(url);
}

export async function addRegistryItems(
  id: string,
  data: {
    taskId: string;
    executorId: string;
    scheduleOn: number;
  }[]
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/odpu-schedule/${id}/items`;
  return axiosPost(url, data);
}

export async function sendRegistry(
  id: string,
  data: {
    year: string | number;
    month: string | number;
  }
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/odpu-schedule/${id}/send`;
  return axiosPost(url, data);
}

export async function findAllRegistryEmailById(
  id: number | string
): Promise<AxiosResponse<IFindAllRegistryEmailById[], any>> {
  const url = HOST + `/rest/odpu-schedule/${id}/emailing`;
  return axiosGet(url);
}

export async function findManagementCompanyById(
  id: number | string
): Promise<AxiosResponse<IFindManagementCompanyById[], any>> {
  const url = HOST + `/rest/odpu-schedule/${id}/management-company`;
  return axiosGet(url);
}

export async function deleteRegistryItem(
  id: number | string,
  registryId: number | string
): Promise<AxiosResponse<string, any>> {
  const url = HOST + `/rest/odpu-schedule/${id}/item/${registryId}`;
  return axiosDelete(url);
}

export async function getEmails(): Promise<AxiosResponse<Emails[], any>> {
  const url = HOST + `/rest/emailing-address`;
  return axiosGet(url);
}

export async function getRegistryEnforseTable(
  type?: FilterArgEnum,
  dateFrom?: number,
  dateTo?: number
): Promise<AxiosResponse<IGetRegistryEnforseTable[], any>> {
  const url =
    HOST +
    `/rest/enforce-import/history?filterType=${type ? type : ''}&dateFrom=${
      dateFrom ? dateFrom : ''
    }&dateTo=${dateTo ? dateTo : ''}`;
  return axiosGet(url);
}

export async function get1RegistryEnforseTable(
  enforceImportId: string
): Promise<AxiosResponse<IGetRegistryEnforseTable[], any>> {
  const url = HOST + `enforce-import/${enforceImportId}/load`;
  return axiosGet(url);
}

export async function getContractList(): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/enforce-import-contract/list`;
  return axiosGet(url);
}
export async function getContractListForAdd(): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/enforce-import-contract/addlist`;
  return axiosGet(url);
}
export async function addToContractList(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/enforce-import-contract/${id}/add`;
  return axiosPost(url, {});
}
export async function removeFromContractList(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/enforce-import-contract/${id}/remove`;
  return axiosPost(url, {});
}
export async function getReport(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/enforce-import/${id}/report`;
  return axiosGetExl(url);
}
export async function getMissingReport(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/enforce-import/${id}/address/missing/report`;
  return axiosGetExl(url);
}
export async function createRegistry(): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/enforce-import/check`;
  return axiosPost(url, {});
}
export async function changeStatus(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/enforce-import/${id}/load`;
  return axiosPost(url, {});
}
export async function getDateManual(date: {
  dateFrom: string;
  dateTo: string;
}): Promise<AxiosResponse<string, any>> {
  const url = HOST + `/rest/enforce-import/manual?dateFrom=${date.dateFrom}&dateTo=${date.dateTo}`;
  return axiosPost(url, null);
}
