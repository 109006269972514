import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { PpoVruInputResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';

interface MeterItemProps {
  vruInput: PpoVruInputResponse;
  prevString: string;
}

export const PpoPUItem = (props: MeterItemProps) => {
  const { register, setValue } = useFormContext();
  const { vruInput, prevString } = props;
  const meter = vruInput.meterResponse;
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  setValue(`${prevString}.ppoMeterRequest.id`, meter?.id);
  useEffect(() => {
    setValue(`${prevString}.absenceExPu`, vruInput?.absenceExPu);
  }, []);

  const [absenceExPuCheck, setAbsenceExPuCheck] = useState<boolean>(vruInput?.absenceExPu);

  const onCheck = () => {
    setAbsenceExPuCheck(!absenceExPuCheck);
    setValue(`${prevString}.absenceExPu`, !absenceExPuCheck);
  };

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Typography
          variant='h6'
          mr={2}
          mt={2}
        >
          Информация о существующем ПУ:
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Checkbox
              onChange={onCheck}
              checked={absenceExPuCheck}
            />
          }
          label='Сущ. ПУ отсутствует'
        />
      </Grid>
      {!absenceExPuCheck && (
        <>
          <Grid
            item
            xs={6}
          >
            <TextField
              id='ppo-pu'
              label='Тип ПУ'
              variant='standard'
              {...register(`${prevString}.ppoMeterRequest.type`, {
                value: meter?.type,
              })}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid
            item
            xs={6}
          />

          <Grid
            item
            xs={6}
          >
            <TextField
              id='ppo-number'
              label='Номер'
              variant='standard'
              {...register(`${prevString}.ppoMeterRequest.number`, {
                value: meter?.number,
              })}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <AutocompleteValue
              title='Место установки'
              defaultValue={meter?.locationId ? `${meter.locationId}` : undefined}
              fieldName={`${prevString}.ppoMeterRequest.locationId`}
              values={getCatalogMapWithErr('ppoLocationMeter')}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              id='tf-number'
              label='Пломба'
              variant='standard'
              {...register(`${prevString}.ppoMeterRequest.stampNumber`, {
                value: meter?.stampNumber,
              })}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              id='tf-affiliation'
              label='Принадлежность пломбы'
              variant='standard'
              {...register(`${prevString}.ppoMeterRequest.affiliationStamp`, {
                value: meter?.affiliationStamp,
              })}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              id='tf-manufacture'
              label='Год выпуска'
              variant='standard'
              {...register(`${prevString}.ppoMeterRequest.manufactureYear`, {
                value: meter?.manufactureYear,
              })}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              id='tf-verification'
              label='Год проверки'
              variant='standard'
              {...register(`${prevString}.ppoMeterRequest.verificationYear`, {
                value: meter?.verificationYear,
              })}
              sx={{ width: '100%' }}
            />
          </Grid>
        </>
      )}
    </>
  );
};
