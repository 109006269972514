import { DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { FC } from 'react';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';

interface VideoPlayerProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  videoUrl: string;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ isOpen, onClose, title, videoUrl }) => {
  return (
    <>
      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 999,
          }}
        >
          <Draggable
            handle='#draggable-dialog-title'
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper>
              <DialogTitle
                style={{ cursor: 'move', display: 'flex', alignItems: 'center' }}
                id='draggable-dialog-title'
              >
                {title && <Typography style={{ alignItems: 'center' }}>{title}</Typography>}
                <IconButton
                  style={{ marginLeft: 'auto' }}
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent>
                <video
                  style={{ maxHeight: '85vh', maxWidth: '90vw' }}
                  width={'100%'}
                  autoPlay
                  src={videoUrl}
                  controls
                />
              </DialogContent>
            </Paper>
          </Draggable>
        </div>
      )}
    </>
  );
};
