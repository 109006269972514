import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { color } from '@/styles/mixins';

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch
    focusVisibleClassName='.Mui-focusVisible'
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 37,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: color('muiBlue'),
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: color('muiBlue'),
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const HousePnrBrowseMergeAddressModalFormControlLabel = styled(FormControlLabel)({
  gap: 16,
  flexShrink: 0,
});

export const HousePnrBrowseMergeAddress = styled('span')({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

interface HousePnrBrowseMergeAddressModalRowProps {
  mainAddress?: boolean;
}

export const HousePnrBrowseMergeAddressModalRow = styled(
  'div'
)<HousePnrBrowseMergeAddressModalRowProps>(({ mainAddress }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 40,
  fontSize: 20,
  color: mainAddress ? color('muiBlue') : 'gray',
  padding: '0 32px',
}));

export const HousePnrBrowseMergeAddressModalButtonsWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16,
  marginTop: 48,
});

export const HousePnrBrowseMergeAddressModalRowWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
  justifyContent: 'center',
  gap: 5,
  marginTop: 35,
});
