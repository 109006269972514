import { Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { VruTaskResponse } from '@/dto/taskmap/task/TaskResultDto';
import { VruInputItem } from './VruInputItem';
import { useGalleryData } from '@/components/Gallery/store';
import { getSMRVruListImageGroup } from '../panel/utils';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { deleteODPUVru } from '@/services/TaskService';

import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';

interface VruTaskItemProps {
  response: VruTaskResponse;
  updateRes: any;
  ids?: {
    executorId?: string;
    id?: string;
    contractorId?: string;
    vruId?: string;
    taskId?: string;
    contractId?: string;
  };
  updateReplacementResponse?: () => void;
  optionsAutomaticBreaker?: Map<string, string>;
}

export const VruTaskItem = (props: VruTaskItemProps) => {
  const onlyShow = useTaskEditorOnlyShow();
  const { catchError, addActionLog } = useActionLog();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const vruTaskResponse = props.response;
  const { updateReplacementResponse, optionsAutomaticBreaker } = props;

  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setImageGroups(getSMRVruListImageGroup(vruTaskResponse?.inputResponseList));
  };

  const handleDelete = () => {
    setIsOpen(false);
    deleteODPUVru(vruTaskResponse?.id)
      .then((res) => {
        props.updateRes(res.data);
        addActionLog(ActionLogType.SUCCESS, 'ВРУ успешно удален');
      })
      .catch((err) => {
        catchError(`Ошибка при удалении ВРУ. ${err?.response?.data?.message ?? ''}`, err);
      });
  };

  return (
    <BoxShadow onDoubleClick={handleClick}>
      <AccessPopup
        headerText={'После удаления восстановить данные будет невозможно'}
        titleText={'Продолжить?'}
        successText={'Да'}
        disabledSuccess={onlyShow}
        closeText={'Отменить'}
        onSuccess={handleDelete}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
      />
      <Grid
        container
        spacing={1}
        item
        direction={'column'}
      >
        <Typography variant='h5'>
          {`${vruTaskResponse?.number ? 'ВРУ №' + vruTaskResponse?.number : ''}: ${
            vruTaskResponse?.installationPlace ? vruTaskResponse?.installationPlace : ''
          }`}
          <IconButton onClick={() => setIsOpen(true)}>
            <DeleteOutlineRoundedIcon />
          </IconButton>
        </Typography>
        {vruTaskResponse?.inputResponseList &&
          vruTaskResponse.inputResponseList.map((t) => (
            <Grid
              key={`grid-vru-input-${t.id}`}
              item
              container
            >
              <VruInputItem
                response={t}
                updateRes={props.updateRes}
                odpuScheduledDateOn={vruTaskResponse.odpuScheduledDateOn}
                ids={{ ...props.ids, id: t.id }}
                updateReplacementResponse={updateReplacementResponse}
                optionsAutomaticBreaker={optionsAutomaticBreaker}
              />
            </Grid>
          ))}
      </Grid>
    </BoxShadow>
  );
};
