import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import DownloadAllButton from '@/components/button/DownloadAllButton/DownloadAllButton';

export const ImageGroupViewControlsWrapper = styled(Typography)({
  textAlign: 'end',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ImageGroupViewControlsButtonWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const ImageGroupViewControlsDownloadAllButton = styled(DownloadAllButton)({
  display: 'inline-block',
});
