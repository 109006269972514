import { HOST } from '@/services/YodaRestService';
import { axiosDelete, axiosGet, axiosPost } from '@/services/helpers';
import { TagsResponse } from '@/dto/TagsDto';
import { ApiResponse } from '@/types/api';

export async function getTags(taskId: string): ApiResponse<TagsResponse[]> {
  const url = HOST + `/rest/task/${taskId}/tag`;
  return axiosGet(url);
}
export async function createTag(tagName: string): ApiResponse<TagsResponse> {
  const url = HOST + `/rest/tag`;
  return axiosPost(url, { title: tagName });
}
export async function addTagToTasks(
  taskIdList: string[],
  tagId: string
): ApiResponse<TagsResponse[]> {
  const url = HOST + `/rest/task-tag`;
  return axiosPost(url, { taskIdList, tagId });
}
export async function deleteTag(taskTagId: string): ApiResponse<TagsResponse> {
  const url = HOST + `/rest/task-tag/${taskTagId}`;
  return axiosDelete(url);
}
