import { LabelDisplayedRowsArgs, TablePagination, Tooltip } from '@mui/material';
import { arrowLabel } from '../utils';
import {
  TasksBrowseNewSelectAll,
  TasksBrowseNewCloseIcon,
  TasksBrowseTotalCountWrapper,
} from './TasksBrowseNewPagination.styled';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import React from 'react';
import { DEFAULT_COUNT } from '../TasksBrowseNew';
import CircularProgress from '@mui/material/CircularProgress';

export interface TasksBrowseNewPaginationProps {
  checkedRows: string[];
  clearCheckedRows: () => void;
  totalRows: number;
  currentPage: number;
  pageSize: number;
  onRowsPerPageChange: (
    newPageSize: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isLoadingTotal: boolean;
  onPageChange: (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void;
  updateCount: () => void;
  selectAllPopupOpen: () => void;
  selectAllCounter?: number | null;
}

const TasksBrowseNewPagination = ({
  checkedRows,
  clearCheckedRows,
  totalRows,
  currentPage,
  pageSize,
  onRowsPerPageChange,
  isLoadingTotal,
  onPageChange,
  updateCount,
  selectAllPopupOpen,
  selectAllCounter,
}: TasksBrowseNewPaginationProps) => {
  const handleSelctAll = () => selectAllPopupOpen();
  const selectAllIcon =
    selectAllCounter === checkedRows.length ? (
      <CheckBoxOutlinedIcon color='primary' />
    ) : (
      <CheckBoxOutlineBlankIcon color='primary' />
    );

  const DisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => (
    <>
      {`${from} - ${to} из `}
      <TasksBrowseTotalCountWrapper>
        {isLoadingTotal ? (
          <CircularProgress size={15} />
        ) : count === DEFAULT_COUNT ? (
          <Tooltip title='Уточнить кол-во заявок'>
            <span
              style={{ cursor: 'pointer' }}
              onClick={updateCount}
            >
              ?
            </span>
          </Tooltip>
        ) : (
          count
        )}
      </TasksBrowseTotalCountWrapper>
    </>
  );

  return (
    <>
      <TasksBrowseNewSelectAll
        onClick={handleSelctAll}
        startIcon={selectAllIcon}
      >
        выделить все
      </TasksBrowseNewSelectAll>
      {checkedRows.length > 0 && <TasksBrowseNewCloseIcon onClick={clearCheckedRows} />}
      <TablePagination
        count={totalRows}
        page={currentPage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={onRowsPerPageChange}
        labelDisplayedRows={(props) => <DisplayedRows {...props} />}
        onPageChange={onPageChange}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        labelRowsPerPage={'Заявок на странице:'}
        getItemAriaLabel={arrowLabel}
      />
    </>
  );
};

export default TasksBrowseNewPagination;
