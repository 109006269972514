import { mediaBreakpointDown, font } from '@/styles/mixins';
import { styled } from '@mui/material';
import { TablePagination, Stack } from '@mui/material';

export const ComplaintBrowseTablePagination = styled(TablePagination)({
  p: {
    marginBottom: 0,
  },
});

export interface ComplaintBrowseTablePaginationWrapperProps {
  disabled?: boolean;
}

export const ComplaintBrowseTablePaginationWrapper = styled(
  Stack
)<ComplaintBrowseTablePaginationWrapperProps>(({ disabled }) => ({
  p: {
    marginBottom: 0,
  },

  pointerEvents: disabled ? 'none' : 'auto',
}));
export const ComplaintBrowseTablePaginatioCount = styled('p')`
  ${font('pagination')}
`;
export const ComplaintBrowseTablePaginatioText = styled('p')`
  ${font('pagination')}
`;
