import { color } from '@/styles/mixins';
import { Stack, styled } from '@mui/material';
import cable from '../../../../../images/icons/cable/cable.png';

export const PpoCableWrapper = styled('div')({
  height: '48px',
  width: '48px',
  position: 'relative',
  '& svg': {
    marginLeft: '9px',
    marginTop: '5px',
  },
});

export const PpoCableText = styled(Stack)({
  fontSize: '12px',
  lineHeight: 1,
  textAlign: 'center',
  padding: '9px 0',
});

export interface PpoCableImgProps {
  add?: boolean;
}
export const PpoCableImg = styled('div')<PpoCableImgProps>(({ add }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: `url(${cable}) center no-repeat`,
  backgroundSize: 'contain',
  transition: '0.4s',

  '&::after, &::before': {
    content: '""',
    position: 'absolute',
    display: add ? 'block' : 'none',
    width: '3px',
    height: '15px',
    backgroundColor: color('blue'),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  '&::before': {
    transform: 'translate(-50%, -50%) rotate(90deg)',
  },
}));
