import { LabelCheckbox } from '@/components/inputs/labelCheckbox';
import { styled } from '@mui/material';

export const StatisticDistributionOfInstallationByConctrorFiltersWrapper = styled('div')({
  padding: '0 0 0 5vw',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  gap: 16,
  paddingBottom: 30,
});

export const StatisticDistributionOfInstallationByConctrorCheckBoxWrapper = styled('div')({
  disaplay: 'flex',
  flexWrap: 'wrap',
});

export const StatisticDistributionOfInstallationByConctrorBarWrapper = styled('div')({
  display: 'grid',
  gap: 15,
  width: '95%',
  margin: 'auto',
  overflowX: 'auto',
  marginTop: 15,
  paddingBottom: 10,
  height: 600,
});

export const MonthBarWrapper = styled('div')({
  minWidth: '100%',
  marginBottom: 15,
});
export const LabelCheckboxStyled = styled(LabelCheckbox)({
  marginRight: 20,
});
