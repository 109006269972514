import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TaskStatus } from '@/dto/taskmap/Dto';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

interface CallTypeModalSendedToBillingProps {
  taskId: string;
  requestId: string;
  taskStatus: string;
  sendToAsumbStatus?: string;
  sendedToBilling: (taskId: string, requestId: string, resultJobLog: string) => any;
}

export default function CallTypeModalSendedToBilling(props: CallTypeModalSendedToBillingProps) {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSaveClick = () => {
    if (
      props.taskStatus !== getKeyByValue(TaskStatus.completed) &&
      props.taskStatus !== getKeyByValue(TaskStatus.disput)
    ) {
      handleOpen();
    } else {
      props.sendedToBilling(props.taskId, props.requestId, 'Нажал на кнопку Передана в билинг');
      setOpen(false);
    }
  };

  function getKeyByValue(value: string) {
    const indexOfS = Object.values(TaskStatus).indexOf(value as unknown as TaskStatus);

    const key = Object.keys(TaskStatus)[indexOfS];

    return key;
  }

  return (
    <div>
      <Button onClick={() => onSaveClick()}>Передана в биллинг</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            sx={{ marginBottom: 3 }}
            variant='h6'
            component='h2'
          >
            Для данной операции заявка должна быть в статусе “Закрыта”. На данный момент заявка в
            статусе "{TaskStatus[props.taskStatus as keyof typeof TaskStatus]}".
          </Typography>
          <Typography
            id='modal-modal-description'
            variant='h6'
            component='h2'
          >
            Перейдите в заявку и закройте ее пожалуйста.
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'end',
              marginTop: 10,
            }}
          >
            <Button
              onClick={(e) => {
                setOpen(false);
              }}
            >
              Отмена
            </Button>
            <Button
              sx={{ marginRight: 2 }}
              onClick={(e) => {
                navigate(`/task/edit/${props.taskId}`);
              }}
            >
              Открыть заявку
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
