import { color } from '@/styles/mixins';
import { Paper, styled } from '@mui/material';

export const Container = styled('div')({
  position: 'relative',
});

export const PnrMainTableItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const PnrMainTableCube = styled('div')({
  border: '1px solid #ccc',
  borderRadius: '6px',
  paddingLeft: '10px',
  paddingRight: '10px',
});

export const PnrMainTableEntranceWrapper = styled(PnrMainTableCube)({
  display: 'flex',
  gap: 12,
  alignItems: 'center',
  padding: '4px 12px',
});

export const PnrMainTableEntrance = styled('div')({
  fontWeight: '800',
  fontSize: '45px',
  color: color('gray'),
  lineHeight: 1,
});

export const PnrMainTableInfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  gap: 4,
});

export interface PnrPlanningsCountMainTextProps {
  secondary?: boolean;
}

export const PnrMainTableInfoText = styled('span')<PnrPlanningsCountMainTextProps>(
  ({ secondary }) => ({
    fontFamily: 'Roboto',
    fontWeight: secondary ? 400 : 500,
    fontSize: secondary ? 10 : 12,
    display: 'block',

    ...(secondary && { color: color('gray') }),
  })
);

export const PnrMainTableHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '27px',
  gap: 4,
});

export const PnrMainTableCarouselInner = styled('div')({
  width: '100%',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});
