import { Box, Grid, Typography } from '@mui/material';
import { aggregatePhases, getChartData, getChartOptions } from './utils';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface MonthChartProps {
  month: string;
  data: any;
  tko: boolean;
  xs: number;
  maxX: number;
}

const MonthChart = ({ month, data, tko, xs, maxX }: MonthChartProps) => {
  function preferredOrder(obj: any, order: any) {
    const newObject: any = {};

    for (let i = 0; i < order.length; i++) {
      if (Object.keys(obj).includes(order[i])) {
        newObject[order[i]] = obj[order[i]];
      }
    }
    return newObject;
  }

  return (
    <Grid
      item
      xs={xs}
    >
      <Typography variant='h6'>{month}</Typography>
      <BoxShadow>
        {Object.entries(data).map(([contract, phases], index) => {
          const aggregatedData = preferredOrder(aggregatePhases(phases, tko), [
            'квартира',
            'холл',
            'лестница',
          ]);

          const chartData = getChartData(aggregatedData, contract);

          return (
            <Box key={index}>
              <Typography
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 14,
                  fontWeight: 'bold',
                }}
              >
                {contract}
              </Typography>
              <Box
                style={{
                  height: '120px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Bar
                  data={chartData}
                  options={getChartOptions(aggregatedData, maxX)}
                  plugins={[ChartDataLabels]}
                />
              </Box>
            </Box>
          );
        })}
      </BoxShadow>
    </Grid>
  );
};

export default MonthChart;
