import { Grid, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { PpoVruInputResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';

interface MeterItemProps {
  vruInput: PpoVruInputResponse;
  prevString: string;
}

export const PpoPlanItem = (props: MeterItemProps) => {
  const { register, setValue } = useFormContext();
  const { vruInput, prevString } = props;
  const meter = vruInput.meterResponse;
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  setValue(`${prevString}.ppoMeterRequest.id`, meter?.id);

  const TRANSFORMER = `${prevString}.transformerRequestList.0.transformerInfo`;

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Typography
          variant='h6'
          mr={2}
          mt={2}
        >
          Информация о планируемом оборудовании:
        </Typography>
      </Grid>

      <>
        <Grid
          item
          xs={3}
        >
          <AutocompleteValue
            title='Место установки нового ПУ'
            defaultValue={vruInput?.locationNewMeterId}
            fieldName={`${prevString}.locationNewMeterId`}
            values={getCatalogMapWithErr('ppoLocationNewMeter')}
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteValue
            title='Место установки нового ТТ'
            defaultValue={
              vruInput?.ppoTransformerResponseList &&
              vruInput?.ppoTransformerResponseList[0]?.transformerInfo?.newLocationId
                ? `${vruInput?.ppoTransformerResponseList[0].transformerInfo.newLocationId}`
                : undefined
            }
            fieldName={`${TRANSFORMER}.newLocationId`}
            values={getCatalogMapWithErr('ppoLocationTt')}
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteValue
            title='Место установки нового ТТ'
            defaultValue={
              vruInput?.installPlaceTTId ? `${vruInput.installPlaceTTId.toString()}` : undefined
            }
            fieldName={`${prevString}.installPlaceTTId`}
            values={getCatalogMapWithErr('ppoInstallPlaceTt')}
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <TextField
            id='tf-tsepi'
            label='Измерителные цепи (м)'
            variant='standard'
            {...register(`${prevString}.measurementCableLength`, {
              value: vruInput?.measurementCableLength,
            })}
            sx={{ width: '100%' }}
          />
        </Grid>
      </>
    </>
  );
};
