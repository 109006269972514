import { BaseUI } from '@/types/ui';
import React from 'react';
import { HouseEntranceFlatCountMatchedWrapper } from './HouseEntranceFlatCountMatched.styled';
import { Tooltip } from '@mui/material';
import { useShemaTypeView } from './utils';

const HouseEntranceFlatCountMatched = ({ children, className }: BaseUI) => {
  const shemaTypeView = useShemaTypeView();

  return (
    <HouseEntranceFlatCountMatchedWrapper
      border={shemaTypeView.border}
      className={className}
    >
      {children}
      <sup>
        <Tooltip
          title={shemaTypeView.tooltip}
          placement='top'
        >
          <span>{shemaTypeView.icon}</span>
        </Tooltip>
      </sup>
    </HouseEntranceFlatCountMatchedWrapper>
  );
};

export default HouseEntranceFlatCountMatched;
