import { TextField } from '@/components/fixUI';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { Grid, Typography } from '@mui/material';
import { DeliveryPointResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { isChangeInput } from './utils';

interface IFormInput {
  keyStr: string;
  meter?: DeliveryPointResponse;
  disabledAll?: boolean;
}

export const FormPUInput: FC<IFormInput> = ({ meter, keyStr, disabledAll = false }) => {
  const { register, setValue } = useFormContext();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  useEffect(() => {
    setValue(`${keyStr}.isReplacementInput`, `${meter?.isReplacementInput}`);
  }, [meter]);

  return (
    <Grid
      item
      xs={12}
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant={'body1'}>Информация о Вводе</Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Необходимо заменить Ввод'
          sx={{ width: '100%' }}
          fieldName={`${keyStr}.isReplacementInput`}
          defaultValue={
            meter?.isReplacementInput == null ? undefined : `${meter?.isReplacementInput}`
          }
          values={isChangeInput}
          disabled={disabledAll}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <TextField
          id='trns-number'
          label='Длинна СИП'
          variant='standard'
          sx={{ width: '100%' }}
          {...register(`${keyStr}.sipLength`, {
            value: meter?.sipLength,
          })}
          disabled={disabledAll}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Тип магистрали СМР'
          sx={{ width: '100%' }}
          fieldName={`${keyStr}.lineType`}
          defaultValue={meter?.lineType}
          values={getCatalogMapWithErr('smrLineType')}
          disabled={disabledAll}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Необходимость разделения ввода'
          sx={{ width: '100%' }}
          fieldName={`${keyStr}.isNeededSeparateInput`}
          defaultValue={
            meter?.isNeededSeparateInput == undefined || meter?.isNeededSeparateInput == null
              ? undefined
              : `${meter?.isNeededSeparateInput}`
          }
          values={isChangeInput}
          disabled={disabledAll}
        />
      </Grid>
    </Grid>
  );
};
