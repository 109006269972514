import { styled } from '@mui/material';

export const PnrFirstTabMainCarouselWrapper = styled('div')({
  display: 'flex',
  justifyItems: 'center',
  alignItems: 'center',
  width: '95%',
});

export const PnrFirstTabButtonsWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  alignItems: 'flex-end',
});

export const PnrFirstTabPaginationWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});
