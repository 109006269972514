import { FilterArgEnum } from '../../inputs/ArgumentSelectNew/types';
import { createStore } from '../../../utils/createStore';

interface SingleArg {
  value: string;
  argValue: FilterArgEnum;
}

interface MultiArg {
  value: string[];
  argValue: FilterArgEnum;
}

export type ISingleInput = {
  number: SingleArg;
  mac: SingleArg;
  imei: SingleArg;
  // phase: SingleArg;
  id: SingleArg;
  ip: SingleArg;
  simNum: SingleArg;
  simIccid: SingleArg;
};
export type IMultiInput = {
  typeId: MultiArg;
  phase: MultiArg;
};

type ObjectKey<Obj> = keyof Obj;
export type SingleInputKeys = ObjectKey<ISingleInput>;
export type MultiInputKeys = ObjectKey<IMultiInput>;

type IFunks = {
  setSingleValue: (key: SingleInputKeys) => (value: string) => void;
  setSingleValueArg: (key: SingleInputKeys) => (argValue: FilterArgEnum) => void;
  setMultiValue: (key: MultiInputKeys) => (value: string[]) => void;
  setMultiValueArg: (key: MultiInputKeys) => (argValue: FilterArgEnum) => void;
  setDefaultValue: () => void;
};
export type IStore = IFunks & ISingleInput & IMultiInput;

const defaultValue: ISingleInput & IMultiInput = {
  number: { value: '', argValue: FilterArgEnum.CONTAIN },
  imei: { value: '', argValue: FilterArgEnum.CONTAIN },
  mac: { value: '', argValue: FilterArgEnum.EQUAL },
  // phase: { value: '', argValue: FilterArgEnum.CONTAIN },
  id: { value: '', argValue: FilterArgEnum.EQUAL },
  ip: { value: '', argValue: FilterArgEnum.EQUAL },
  simNum: { value: '', argValue: FilterArgEnum.CONTAIN },
  simIccid: { value: '', argValue: FilterArgEnum.CONTAIN },
  typeId: { value: [], argValue: FilterArgEnum.IN_LIST },
  phase: { value: [], argValue: FilterArgEnum.IN_LIST },
};

export const useCatalogCounterFilter = createStore<IStore>(
  (set, get) => ({
    ...defaultValue,

    setDefaultValue: () =>
      set(() => ({
        ...defaultValue,
      })),

    setSingleValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setSingleValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.INSTALLED || get()[key].argValue === FilterArgEnum.INSTALLED) {
        set(() => ({
          [key]: {
            value: '',
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },

    setMultiValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setMultiValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.INSTALLED || get()[key].argValue === FilterArgEnum.INSTALLED) {
        set((state) => ({
          [key]: {
            value: [],
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },
  }),
  'Catalogs browse'
);
