import { styled } from '@mui/material';

export const StatisticsPieChartWrapper = styled('div')({
  padding: '15px 15px',
  width: '30vw',
});

export const StatisticsHeader = styled('p')({
  marginBottom: '0',
  fontSize: 'xx-large',
  textAlign: 'center',
});

export const StatisticsPieWrapper = styled('div')({
  display: 'flex',
  gap: 20,
  alignItems: 'center',
  justifyContent: 'center',
});

export const StatisticsPieLegendItem = styled('div')({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  marginTop: 2,
});

export const StatisticsPieLegendRectangle = styled('div')({
  width: '3.8vw',
  height: '3.2vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StatisticsPieLegendText = styled('p')({
  margin: '5px',
  fontWeight: '700',
  fontSize: '.8vw',
});
