import { Box, Grid, Typography, Stack } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { ReplacementResponseList, TransformerInfoResponse } from '@/dto/taskmap/task/TaskResultDto';
import { GeneralReplacement } from './GeneralReplacement';
import { toFormateDate } from '@/utils/heplers';
import { ModeViewerResult } from '@/components/features/taskEditor/types';
import { concatImagesFromTransformerInfoResponse } from '../panel/utils';
import { useGalleryData } from '@/components/Gallery/store';
import { useGsmStore } from '@/components/GSM/store';
import { useCatalog } from '@/hooks/CatalogHook';
import { TaskEditorOverflowText, TASK_EDITOR_BIG_PREVIEW } from './TaskEditorItem.styled';
import { ExpandButton } from '@/components/button/ExpandButton';
import TransformerVoltage from '@/components/Transformer/TransformerVoltage';
import { ReplacementStateIcon } from '@/components/ReplacementStateIcon/ReplacementStateIcon';
import { CableDescent } from '@/components/CableDescent';
import { GSMBlockNew } from '@/components/GSM/GSMBlockNew';

export interface ReplacementItemProps {
  replacementResponse: ReplacementResponseList;
  transformerInfoResponse?: TransformerInfoResponse;
  updateReplacementResponse?: () => void;
  mode?: ModeViewerResult;
  ids?: { executorId?: string; id?: string; contractorId?: string; contractId?: string };
}

export const ReplacementItem = (props: ReplacementItemProps) => {
  const { data } = useGsmStore((store) => ({
    data: store.gsmData,
  }));
  const {
    replacementResponse,
    mode = ModeViewerResult.SMALL,
    ids,
    updateReplacementResponse,
  } = props;
  const [innerMode, setInnerMode] = useState<ModeViewerResult>(mode);

  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setImageGroups(concatImagesFromTransformerInfoResponse(props));
  };

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  useEffect(() => {
    getCatalogMapWithErr('amperageTransformerType');
    getCatalogMapWithErr('stampInstallStatus');
    getCatalogMapWithErr('actStatus');
    getCatalogMapWithErr('changeStatus');
    getCatalogMapWithErr('automaticBreaker');
  }, []);

  const handleMode = () => (isExpanded: boolean) => {
    setInnerMode(isExpanded ? ModeViewerResult.LARGE : ModeViewerResult.SMALL);
  };

  const getPreview = (): ReactNode => {
    return (
      <Grid {...TASK_EDITOR_BIG_PREVIEW.inner}>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.iconItem}>
          <ReplacementStateIcon status={replacementResponse?.replacementState} />
        </Grid>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.firstItem}>
          <Typography>{toFormateDate(replacementResponse?.replacedOn)}</Typography>
        </Grid>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.secondItem}>
          <TaskEditorOverflowText>{replacementResponse?.montageName}</TaskEditorOverflowText>
        </Grid>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.thirdItem}>
          <Typography>MAC: {replacementResponse?.mounted?.mac || '-'}</Typography>
        </Grid>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.fourthItem}>
          <Typography>ПУ: {replacementResponse?.mounted?.number || '-'}</Typography>
        </Grid>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.lastItem}>
          <Stack
            direction={'row'}
            justifyContent='flex-end'
          >
            <Typography>Замена</Typography>
            <ExpandButton
              onAction={handleMode()}
              sx={{ marginLeft: 1 }}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  const simStatus = (): string =>
    data?.find((el) =>
      el.sims.find((elem) => elem.installStatus == 'NOT_FOUND' || elem.installStatus == 'NOT_VALID')
    )
      ? 'Ошибка'
      : 'Ок';

  return (
    <Box
      sx={{ backgroundColor: '#FCFCFC' }}
      onDoubleClick={handleClick}
    >
      <GeneralReplacement
        preview={getPreview()}
        mode={innerMode}
        modeCallback={handleMode}
        replacementResponse={replacementResponse}
        ids={ids}
        updateReplacementResponse={updateReplacementResponse}
        simStatus={simStatus()}
      >
        <TransformerVoltage />
        {replacementResponse?.cableDescentResponse && (
          <CableDescent {...replacementResponse.cableDescentResponse} />
        )}
        <GSMBlockNew data={replacementResponse.simChangeResponseList} />
      </GeneralReplacement>
    </Box>
  );
};
