import React, { useState, useMemo } from 'react';
import { useMutation } from 'react-query';
import {
  DataGrid,
  ruRU,
  GridColDef,
  GridColumnHeaderParams,
  GridEventListener,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { getCallCenterRequests, getCountOfCallCenterRequests } from '@/services/TaskService';
import {
  ApiCreateGuarantee,
  ApiGetCallTypes,
  ApiGetCCContracts,
  ApiGetTkoStatusList,
  ApiGetWorkTypes,
  ApiSaveCallCenterRefund,
  ApiSaveCallCenterTaskComment,
  ApiSaveCallCenterTko,
  ApiSaveRemoteSolution,
  ApiSaveSendToBilling,
} from '@/services/YodaRestService';
import {
  Box,
  createTheme,
  Grid,
  LinearProgress,
  styled,
  TextField,
  ThemeProvider,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
  Typography,
  TablePagination,
} from '@mui/material';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { CallCenterFilterParam, CallCenterRequestTableItem } from '@/dto/taskmap/Dto';
import CallTypeModalTypeOne from './modaldialogs/CallTypeModalTypeOne';
import CallTypeModalTypeFour from './modaldialogs/CallTypeModalTypeFour';
import CallTypeModalTypeFive from './modaldialogs/CallTypeModalTypeFive';
import CallTypeModalTypeSix from './modaldialogs/CallTypeModalTypeSix';
import CallTypeModalTypeTwo from './modaldialogs/CallTypeModalTypeTwo';
import CallTypeModalTypeSeven from './modaldialogs/CallTypeModalTypeSeven';
import moment from 'moment';
import * as locales from '@mui/material/locale';
import { Link } from 'react-router-dom';
import { MultiSelect } from '../inputs/MultiSelectNew';
import { getDataFromFilter } from '../inputs/MultiSelectNew/utils';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { arrowLabel, taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';

const TAG = 'RequestsTable';

const FILTERS_LABELS = {
  contracts: 'Контракт',
  responsables: 'Ответственный',
  executors: 'Исполнитель',
  workTypes: 'Причина обращения',
  callTypes: 'Тип звонка',
};

function logError(error: string) {
  console.log(TAG + 'error data ' + error);
}

function convertIntoFilter(data: any): any {
  return data.map((gr: any) => {
    return { value: gr.id, label: gr.title };
  });
}

function convertWorkTypesIntoFilter(data: any): any {
  return data.map((workType: any) => {
    return { value: workType.id, label: workType.workTypeName };
  });
}

const COUNT = 10000000;
export const CallCenterRequestsComponent = () => {
  const taskGroupMap = useCatalog((state) => state.taskGroupMap);

  const applyTypeMap: Map<string, any> = new Map<string, any>();

  applyTypeMap.set(
    '1',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeFour
        remoteSolution={remoteSolution}
        save={createGuarantee}
        requestId={requestId}
        taskStatus={taskStatus}
        executors={executors}
        taskId={taskId}
        callTypeId={callTypeId}
      />
    )
  );
  applyTypeMap.set(
    '2',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeOne
        requestId={requestId}
        save={createGuarantee}
        executors={executors}
        taskStatus={taskStatus}
        taskId={taskId}
        callTypeId={callTypeId}
      />
    )
  );
  applyTypeMap.set(
    '3',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeOne
        requestId={requestId}
        save={createGuarantee}
        executors={executors}
        taskStatus={taskStatus}
        taskId={taskId}
        callTypeId={callTypeId}
      />
    )
  );
  applyTypeMap.set(
    '4',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeFive
        requestId={requestId}
        sendToAsumbStatus={sendToAsumbStatus}
        sendedToBilling={sendedToBilling}
        taskStatus={taskStatus}
        taskId={taskId}
      />
    )
  );
  applyTypeMap.set(
    '5',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeSix
        refund={refund}
        taskStatus={taskStatus}
        taskId={taskId}
        requestId={requestId}
      />
    )
  );
  applyTypeMap.set(
    '6',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeTwo
        requestId={requestId}
        save={save}
        executors={executors}
        taskStatus={taskStatus}
        taskId={taskId}
      />
    )
  );
  applyTypeMap.set(
    '102',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeSeven
        requestId={requestId}
        save={save}
        saveTko={saveTko}
        taskStatus={taskStatus}
        executors={executors}
        taskId={taskId}
        tkoStatuses={tkoStatusList}
      />
    )
  );
  applyTypeMap.set(
    '103',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeSeven
        requestId={requestId}
        save={save}
        saveTko={saveTko}
        taskStatus={taskStatus}
        executors={executors}
        taskId={taskId}
        tkoStatuses={tkoStatusList}
      />
    )
  );
  applyTypeMap.set(
    '104',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeSeven
        requestId={requestId}
        save={save}
        saveTko={saveTko}
        taskStatus={taskStatus}
        executors={executors}
        taskId={taskId}
        tkoStatuses={tkoStatusList}
      />
    )
  );
  applyTypeMap.set(
    '105',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeTwo
        requestId={requestId}
        save={save}
        executors={executors}
        taskStatus={taskStatus}
        taskId={taskId}
      />
    )
  );
  applyTypeMap.set(
    '106',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeTwo
        requestId={requestId}
        save={save}
        executors={executors}
        taskStatus={taskStatus}
        taskId={taskId}
      />
    )
  );
  applyTypeMap.set(
    '107',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeTwo
        requestId={requestId}
        save={save}
        executors={executors}
        taskStatus={taskStatus}
        taskId={taskId}
      />
    )
  );
  applyTypeMap.set(
    '108',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeTwo
        requestId={requestId}
        save={save}
        executors={executors}
        taskStatus={taskStatus}
        taskId={taskId}
      />
    )
  );
  applyTypeMap.set(
    '109',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeOne
        requestId={requestId}
        save={save}
        executors={executors}
        taskStatus={taskStatus}
        taskId={taskId}
        callTypeId={callTypeId}
      />
    )
  );
  applyTypeMap.set(
    '201',
    (
      taskStatus: string,
      taskId: string,
      callTypeId: string,
      requestId: string,
      sendToAsumbStatus?: string
    ) => (
      <CallTypeModalTypeOne
        requestId={requestId}
        save={save}
        executors={executors}
        taskStatus={taskStatus}
        taskId={taskId}
        callTypeId={callTypeId}
      />
    )
  );

  const responsables = useMemo(() => taskMapToArray(taskGroupMap), [taskGroupMap]);
  const executors = useMemo(() => taskMapToArray(taskGroupMap), [taskGroupMap]);

  const [callCenterRequests, setCallCenterRequests] = React.useState<CallCenterRequestTableItem[]>(
    []
  );
  const [filterParam, setFilterParam] = React.useState<CallCenterFilterParam>({});

  const [workTypes, setWorkTypes] = React.useState<SelectFilterOptType[]>([]);
  const [selectedWorkTypes, setSelectedWorkTypes] = React.useState<SelectFilterOptType[]>([]);
  const [workTypesArg, setWorkTypesArg] = React.useState(0);

  const [callTypes, setCallTypes] = React.useState<SelectFilterOptType[]>([]);
  const [selectedCallTypes, setSelectedCallTypes] = React.useState<SelectFilterOptType[]>([]);
  const [callTypesArg, setCallTypesArg] = React.useState(0);

  const [contracts, setContracts] = React.useState<SelectFilterOptType[]>([]);
  const [selectedContracts, setSelectedContracts] = React.useState<SelectFilterOptType[]>([]);
  const [contractsArg, setContractsArg] = React.useState(0);

  const [selectedExecutors, setSelectedExecutors] = React.useState<SelectFilterOptType[]>([]);
  const [executorsArg, setExecutorsArg] = React.useState(0);

  const [tkoStatusList, setTkoStatusList] = React.useState<SelectFilterOptType[]>([]);

  const [selectedResponsables, setSelectedResponsables] = React.useState<SelectFilterOptType[]>([]);
  const [responsablesArg, setResponsablesArg] = React.useState(0);

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [callRequestCount, setCallRequestCount] = React.useState<number>(COUNT);
  const [isLoadingPag, setIsLoadingPag] = useState<boolean>(true);
  const { catchError } = useActionLog();
  const theme = useTheme();

  const themeWithLocale = createTheme(theme, locales['ruRU']);

  const [selectedRowId, setSelectedRowId] = React.useState('');

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'contractName',
        headerName: 'Контракт',
        width: 130,
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <LightTooltip title={params.value + ''}>
            <Typography
              fontSize={14}
              sx={{ mt: 1 }}
              variant='caption'
              gutterBottom
            >
              {params.value}
            </Typography>
          </LightTooltip>
        ),
      },
      {
        field: 'taskNumber',
        width: 80,
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => (
          <div>
            <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Номер</div>
            <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>заявки</div>
          </div>
        ),
        renderCell: (params: GridRenderCellParams<string>) => (
          <strong>
            <Link
              to={'/task/edit/' + params.row.taskId}
              tabIndex={params.hasFocus ? 0 : -1}
            >
              {params.value + ''}
            </Link>
          </strong>
        ),
      },
      {
        field: 'accountNum',
        width: 130,
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => (
          <div>
            <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Лицевой</div>
            <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>счет</div>
          </div>
        ),
        renderCell: (params: GridRenderCellParams<string>) => (
          <LightTooltip title={params.value + ''}>
            <Typography
              fontSize={14}
              sx={{ mt: 1 }}
              variant='caption'
              gutterBottom
            >
              {params.value}
            </Typography>
          </LightTooltip>
        ),
      },
      {
        field: 'fullName',
        headerName: 'ФИО',
        width: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <TextField
            fullWidth
            multiline
            maxRows={2}
            value={params.value ? params.value + '' : ''}
            id={`${params.field}-${params?.id}`}
            variant='standard'
          />
        ),
      },
      {
        field: 'address',
        headerName: 'Адрес',
        width: 380,
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <TextField
            fullWidth
            multiline
            maxRows={2}
            value={params.value + ''}
            id={`${params.field}-${params?.id}`}
            variant='standard'
          />
        ),
      },
      {
        field: 'phoneNumber',
        width: 130,
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => (
          <div>
            <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Номер</div>
            <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>телефона</div>
          </div>
        ),
        renderCell: (params: GridRenderCellParams<string>) => (
          <LightTooltip title={params.value ? params.value : ''}>
            <TextField
              fullWidth
              multiline
              maxRows={2}
              value={params.value ? params.value : ''}
              id={`${params.field}-${params?.id}`}
              variant='standard'
            />
          </LightTooltip>
        ),
      },

      {
        field: 'callDate',
        headerName: 'Дата звонка',
        sortable: false,
        type: 'date',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value ? moment(params.value).format('DD.MM.yyyy') : '';
        },
        valueGetter: (params: GridValueGetterParams) => {
          return params.value ? moment(new Date(params.value)) : '';
        },
      },

      {
        field: 'workType',
        width: 100,
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => (
          <div>
            <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Причина</div>
            <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>обращения</div>
          </div>
        ),
        renderCell: (params: GridRenderCellParams<string>) => (
          <LightTooltip title={params.value + ''}>
            <Typography
              fontSize={14}
              sx={{ mt: 1 }}
              variant='caption'
              gutterBottom
            >
              {params.value}
            </Typography>
          </LightTooltip>
        ),
      },
      {
        field: 'comment',
        headerName: 'Комментарий',
        width: 270,
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <LightTooltip title={params.value + ''}>
            <TextField
              fullWidth
              multiline
              maxRows={2}
              value={params.value + ''}
              id={`${params.field}-${params?.id}`}
              variant='standard'
            />
          </LightTooltip>
        ),
      },
      {
        field: 'contractorName',
        headerName: 'Ответственный',
        width: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <LightTooltip title={params.value + ''}>
            <Typography
              fontSize={14}
              sx={{ mt: 1 }}
              variant='caption'
              gutterBottom
            >
              {params.value}
            </Typography>
          </LightTooltip>
        ),
      },
      {
        field: 'executor',
        headerName: 'Исполнитель',
        width: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <LightTooltip title={params.value + ''}>
            <Typography
              fontSize={14}
              sx={{ mt: 1 }}
              variant='caption'
              gutterBottom
            >
              {params.value}
            </Typography>
          </LightTooltip>
        ),
      },
      {
        field: 'action',
        headerName: 'Применить',
        sortable: false,

        renderCell: (params) => {
          if (applyTypeMap.get(params.row.workTypeId)) {
            return applyTypeMap.get(params.row.workTypeId)(
              params.row.taskStatus,
              params.row.taskId,
              params.row.callType.id,
              params.row.id,
              params.row.sendToAsumbStatus
            );
          } else {
            return (
              <CallTypeModalTypeOne
                requestId={params.row.id}
                save={save}
                executors={executors}
                taskStatus={params.row.taskStatus}
                taskId={params.row.taskId}
                callTypeId={params.row.callType.id}
              />
            );
          }
        },
      },
    ],
    []
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const onResetClick = () => {
    setSelectedWorkTypes([]);
    setSelectedContracts([]);
    setSelectedResponsables([]);
    setSelectedExecutors([]);
    setSelectedCallTypes([]);
  };
  const onSubmitSearch = () => {
    const filtParam: CallCenterFilterParam = {};
    if (selectedContracts.length) {
      filtParam.contract = getDataFromFilter(selectedContracts, 'value');
      filtParam.contractArg = contractsArg;
    }
    if (selectedWorkTypes.length) {
      filtParam.workType = getDataFromFilter(selectedWorkTypes, 'value');
      filtParam.workTypeArg = workTypesArg;
    }
    if (selectedCallTypes.length) {
      filtParam.callType = getDataFromFilter(selectedCallTypes, 'value');
      filtParam.callTypeArg = callTypesArg;
    }
    if (selectedResponsables.length) {
      filtParam.responsiblePersonId = getDataFromFilter(selectedResponsables, 'value');
      filtParam.responsiblePersonIdArg = responsablesArg;
    }
    if (selectedExecutors.length) {
      filtParam.executorId = getDataFromFilter(selectedExecutors, 'value');
      filtParam.executorIdArg = executorsArg;
    }
    setFilterParam(filtParam);
    setPage(0);
    mutGetCallCenterRequests.mutate(filtParam);
  };

  const createGuarantee = (
    taskId: string,
    requestId: string,
    resultJobLog: string,
    planDate?: string,
    executor?: string,
    comment?: string
  ) => {
    ApiCreateGuarantee({
      oldTaskId: taskId,
      requestId: requestId,
      planDate: planDate!,
      executorId: executor!,
      comment: comment,
      resultJobLog: resultJobLog,
    })
      .then(function (value) {
        if (value) {
          console.log('Done');
          console.log(value);
          mutGetCallCenterRequests.mutate(filterParam);
        } else {
          console.log('Something wrong');
        }
      })
      .catch((err) => {
        alert('Ошибка при создании гарантии');
        console.log(err);
      });
  };

  const save = (
    taskId: string,
    requestId: string,
    resultJobLog: string,
    planDate?: string,
    executor?: string,
    comment?: string
  ) => {
    ApiSaveCallCenterTaskComment({
      taskId: taskId,
      requestId: requestId,
      comment: comment!,
      planDate: planDate!,
      executorId: executor!,
      resultJobLog: resultJobLog,
    })
      .then(function (value) {
        if (value) {
          console.log('Done');
          mutGetCallCenterRequests.mutate(filterParam);
        } else {
          console.log('Something wrong');
        }
      })
      .catch((err) => {
        alert('Ошибка при сохранении комментария');
        console.log(err);
      });
  };

  const saveTko = (
    taskId: string,
    requestId: string,
    resultJobLog: string,
    tkoId: string,
    comment: string
  ) => {
    ApiSaveCallCenterTko({
      taskId: taskId,
      requestId: requestId,
      tkoStatusId: tkoId,
      description: comment,
      resultJobLog: resultJobLog,
    })
      .then(function (value) {
        if (value) {
          console.log('Done');
          mutGetCallCenterRequests.mutate(filterParam);
        } else {
          console.log('Something wrong');
        }
      })
      .catch((err) => {
        alert('Ошибка при создании ТКО');
        console.log(err);
      });
  };

  const sendedToBilling = (taskId: string, requestId: string, resultJobLog: string) => {
    ApiSaveSendToBilling({ taskId: taskId, requestId: requestId, resultJobLog: resultJobLog })
      .then(function (value) {
        if (value) {
          console.log('Done');
          mutGetCallCenterRequests.mutate(filterParam);
        } else {
          console.log('Something wrong');
        }
      })
      .catch((err) => {
        alert('Ошибка при создании гарантии');
        console.log(err);
      });
  };

  const remoteSolution = (taskId: string, requestId: string, resultJobLog: string) => {
    ApiSaveRemoteSolution({ taskId: taskId, requestId: requestId, resultJobLog: resultJobLog })
      .then(function (value) {
        if (value) {
          console.log('Done');
          mutGetCallCenterRequests.mutate(filterParam);
        } else {
          console.log('Something wrong');
        }
      })
      .catch((err) => {
        alert('Ошибка при создании гарантии');
        console.log(err);
      });
  };

  const refund = (
    taskId: string,
    requestId: string,
    resultJobLog: string,
    comment: string,
    file: string,
    fileName: string,
    fileExt: string
  ) => {
    ApiSaveCallCenterRefund({
      taskId: taskId,
      requestId: requestId,
      file: file,
      fileName: fileName,
      fileExt: fileExt,
      comment: comment,
      resultJobLog: resultJobLog,
    })
      .then(function (value) {
        if (value) {
          console.log('Done');
          mutGetCallCenterRequests.mutate(filterParam);
        } else {
          console.log('Something wrong');
        }
      })
      .catch((err) => {
        alert('Ошибка при создании гарантии');
        console.log(err);
      });
  };

  React.useEffect(() => {
    mutGetCallCenterRequests.mutate(filterParam);
  }, [page, pageSize]);

  React.useEffect(() => {
    let values = selectedCallTypes.map((it) => it.value);
    if (values.length == 0) {
      values = callTypes.map((it) => it.value);
    }
    ApiGetWorkTypes(values)
      .then((res) => {
        setWorkTypes(convertWorkTypesIntoFilter(res.data));
      })
      .catch((err) => logError(err));
  }, [selectedCallTypes]);

  React.useEffect(() => {
    ApiGetCCContracts()
      .then((res) => {
        setContracts(convertIntoFilter(res.data));
      })
      .catch((err) => logError(err));
    ApiGetCallTypes()
      .then((res) => {
        setCallTypes(convertIntoFilter(res.data));
        ApiGetWorkTypes(res.data.map((it) => it.id))
          .then((res) => {
            setWorkTypes(convertWorkTypesIntoFilter(res.data));
          })
          .catch((err) => logError(err));
      })
      .catch((err) => logError(err));
    ApiGetTkoStatusList()
      .then(function (value) {
        setTkoStatusList(convertIntoFilter(value.data));
      })
      .catch((err) => {
        alert('Ошибка получения ТКО');
        console.log(err);
      });
  }, []);

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    setSelectedRowId(String(params.row.mcId));
  };

  const mutGetCallCenterRequests = useMutation<void, any, CallCenterFilterParam>((filter) => {
    const offset = page * pageSize;
    // getCountOfCallCenterRequests(filter)
    //   .then((data) => {
    //     setCallRequestCount(data.data);
    //   })
    //   .catch((error) => {
    //     alert('Возникла ошибка');
    //     logError(error);
    //   });
    return getCallCenterRequests(filter, offset, pageSize)
      .then((data) => {
        const requests = data.data;
        setCallCenterRequests(requests);
      })
      .catch((error) => {
        alert('Возникла ошибка');
        logError(error);
      });
  });
  const updateCount = () => {
    getCountOfCallCenterRequests(filterParam)
      .then((data) => {
        setCallRequestCount(data.data);
        setIsLoadingPag(false);
      })
      .catch((error) => {
        catchError('Возникла ошибка', error);
      });
  };

  const detailedInfo = generateDetailedInfo(
    [selectedContracts, FILTERS_LABELS.contracts],
    [selectedResponsables, FILTERS_LABELS.responsables],
    [selectedExecutors, FILTERS_LABELS.executors],
    [selectedWorkTypes, FILTERS_LABELS.workTypes],
    [selectedCallTypes, FILTERS_LABELS.callTypes]
  );

  return (
    <Box>
      <ThemeProvider theme={themeWithLocale}>
        <Grid
          direction='column'
          container
        >
          {mutGetCallCenterRequests.isLoading ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : (
            <div />
          )}

          <Grid
            container
            item
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-end'
            wrap='nowrap'
            marginBottom={'10px'}
            position='relative'
          >
            <MultiSelect
              label={FILTERS_LABELS.contracts}
              options={contracts}
              value={selectedContracts}
              argValue={contractsArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedContracts(value)}
              onArgChange={(arg: number) => setContractsArg(arg)}
            />

            <MultiSelect
              label={FILTERS_LABELS.responsables}
              options={responsables}
              value={selectedResponsables}
              argValue={responsablesArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedResponsables(value)}
              onArgChange={(arg: number) => setResponsablesArg(arg)}
            />

            <MultiSelect
              label={FILTERS_LABELS.executors}
              options={executors}
              value={selectedExecutors}
              argValue={executorsArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedExecutors(value)}
              onArgChange={(arg: number) => setExecutorsArg(arg)}
            />

            <MultiSelect
              label={FILTERS_LABELS.workTypes}
              options={workTypes}
              value={selectedWorkTypes}
              argValue={workTypesArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedWorkTypes(value)}
              onArgChange={(arg: number) => setWorkTypesArg(arg)}
            />

            <MultiSelect
              label={FILTERS_LABELS.callTypes}
              options={callTypes}
              value={selectedCallTypes}
              argValue={callTypesArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedCallTypes(value)}
              onArgChange={(arg: number) => setCallTypesArg(arg)}
            />

            <FilterButtons
              info={detailedInfo}
              onSearchClick={onSubmitSearch}
              onCloseClick={onResetClick}
            />
          </Grid>

          <div style={{ height: '650px' }}>
            <DataGrid
              disableVirtualization
              disableColumnFilter
              localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
              rows={callCenterRequests}
              columns={columns}
              onRowEditStop={handleRowEditStop}
              onRowClick={handleRowClick}
              components={{
                Pagination: () => (
                  <TablePagination
                    count={callRequestCount}
                    page={page}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={(newPageSize) =>
                      setPageSize(Number(newPageSize.target.value))
                    }
                    labelDisplayedRows={({ from, to, count }) => (
                      <>
                        {`${from} - ${to} из `}
                        {isLoadingPag ? (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={updateCount}
                          >
                            ?
                          </span>
                        ) : (
                          count
                        )}
                      </>
                    )}
                    onPageChange={(ev, newPage) => setPage(newPage)}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    labelRowsPerPage={'Заявок на странице:'}
                    getItemAriaLabel={arrowLabel}
                  />
                ),
              }}
            />
          </div>
        </Grid>
      </ThemeProvider>
    </Box>
  );
};
