import { styled } from '@mui/material';

export const StatisticUrgentAndSuperUrgentTasksFiltersWrapper = styled('div')({
  padding: '0 0 0 5vw',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  gap: 16,
});

export const StatisticUrgentAndSuperUrgentTasksTableWrapper = styled('div')({
  display: 'flex',
  width: '89%',
  margin: 'auto',
  overflowX: 'auto',
  marginTop: 15,
  minHeight: 300,
  overflowY: 'auto',
  height: 'auto',
});

export const StatisticUrgentAndSuperUrgentTasksEmpty = styled('p')({
  minHeight: 250,
  border: '1px solid #F0F0F0',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
