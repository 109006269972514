import { GridColDef } from '@mui/x-data-grid';

export const reorder = <T>(items: T[], startIndex: number, endIndex: number) => {
  const result = [...items];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const sortColumns = (columnList: GridColDef[], dragSortedList: GridColDef[]) =>
  columnList.sort((a, b) => {
    const indexA = dragSortedList.findIndex((dragItem) => a.field === dragItem.field);
    const indexB = dragSortedList.findIndex((dragItem) => b.field === dragItem.field);
    if (indexA > indexB) {
      return 1;
    } else if (indexA < indexB) {
      return -1;
    }

    return 0;
  });

export const getNextIndex = (array: unknown[], index: number) => {
  return index < array.length - 1 ? index + 1 : 0;
};
