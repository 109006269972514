export const NetworkMapType: any = {
  PU_CONNECTED: 'Подключенные ПУ',
  PU_UNCONNECTED: 'Не подключенные ПУ',
  USPD_EXISTS: 'УСПД',
  USPD_CREATED: 'УСПД нет в системе',
};

export interface NetworkResponse {
  longitudeX: number;
  latitudeY: number;
  connectedCount?: number;
  type: string;
  count?: number;
  id?: number | null;
  imei?: string;
  networkPollingUspdId?: null | string;
  retranslatorTaskId?: null | string;
  taskId?: null | string;
  entities?: null | NetworkResponse[];
  uspdExisting?: null | string;
  coverageRadius?: number;
  networkElementId?: string;
}
export enum Type {
  PU_CONNECTED = 'PU_CONNECTED',
  PU_UNCONNECTED = 'PU_UNCONNECTED',
  USPD_EXISTS = 'USPD_EXISTS',
  USPD_CREATED = 'USPD_CREATED',
  USPD_CREATED_NEW = 'USPD_CREATED_NEW',
}

export const lookUp: Record<string, string> = {
  USPD_EXISTS: 'УСПД сушествует',
  USPD_CREATED: 'УСПД создано йдой',
  PU_CONNECTED: 'ПУ подключен',
  USPD_CREATED_NEW: 'Создана пользователем',
  PU_UNCONNECTED: 'ПУ не подключен',
};

export type ActionType = 'default' | 'drag' | 'add';
