import React, { FC } from 'react';
import moment from 'moment';
import { Date, Title } from './DateInfoString.styled';

interface IDateInfoString {
  title?: string;
  date?: Date;
}

const DateInfoString: FC<IDateInfoString> = ({ title = 'Дата: ', date }) => {
  return date ? (
    <>
      <Title>{title}</Title>
      <Date>{moment(date).format('DD.MM.yyyy')}</Date>
    </>
  ) : null;
};

export default DateInfoString;
