import { Button, styled } from '@mui/material';
import { color } from '@/styles/mixins';

export const NewGalleryWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  padding: 30,
  gap: 100,
  alignItems: 'flex-start',
});

export const NewGalleryLeft = styled('div')({
  position: 'relative',
  width: '50%',
  flexShrink: 0,
});

export const NewGalleryRight = styled('div')({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 40,
  overflowY: 'auto',
  maxHeight: '90svh',
  scrollbarWidth: 'auto',
  zIndex: 1,
});

export const NewGalleryHeader = styled('div')({
  display: 'flex',
  gap: 20,
  alignItems: 'center',
});

export const NewGalleryTitle = styled('span')({
  fontSize: '20px',
  fontWeight: 500,
  textTransform: 'uppercase',
});

export const NewGalleryResetButton = styled(Button)({
  color: color('blue'),
});

export const NewGalleryActiveImageHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  justifyContent: 'flex-end',
});

export const NewGalleryPreviewImage = styled('img')({
  minHeight: 150,
  objectFit: 'cover',
  borderRadius: 20,
});

export const NewGalleryActiveImageWrapper = styled('div')({});

interface NewGalleryPreviewItemProps {
  active: boolean;
}

export const NewGalleryPreviewItem = styled('div')<NewGalleryPreviewItemProps>(({ active }) => ({
  width: 200,
  height: 260,
  opacity: active ? 1 : 0.6,
  transition: '0.3s',
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: 20,

  '&:hover': {
    opacity: 1,
  },
}));
