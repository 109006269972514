import { styled } from '@mui/material';
// import { DataGrid as DataGrid_ } from '@mui/x-data-grid';
import { DataGridPro as DataGrid_ } from '@mui/x-data-grid-pro';

export const DataGrid = styled(DataGrid_)({
  '&.MuiDataGrid-root': {
    display: 'flex',
  },

  '.MuiDataGrid-selectedRowCount': {
    order: 2,
  },

  '.MuiTablePagination-root': {
    order: 4,
    marginLeft: 'auto',
  },

  '.MuiDataGrid-footerContainer': {
    justifyContent: 'flex-start',
  },
});
