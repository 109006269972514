import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { sendAllCipher } from '@/services/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { IPopup, Popup } from './Popup';
import { LoadingButton } from '@mui/lab';

interface Props extends IPopup {
  taskIds: string[];
}
type FormData = {
  cipher: string;
};

export const SelectAllCipherPopup: React.FC<Props> = (props) => {
  const { taskIds, onClose, onSuccess } = props;
  const { control, setValue, handleSubmit, watch } = useForm<FormData>();

  const [isLoading, setIsLoading] = useState(false);
  const { catchError, addActionLog } = useActionLog();

  const handleSave = ({ cipher }: FormData) => {
    const payload = {
      taskIdList: taskIds,
      cipher,
    };

    setIsLoading(true);
    sendAllCipher(payload)
      .then(() => {
        onClose();
        onSuccess && onSuccess();
        addActionLog(ActionLogType.SUCCESS, 'Шифр присвоен');
      })
      .catch((error) => {
        if (error?.response?.data) {
          catchError(error?.response?.data.message, {});
        } else {
          catchError('Непредвиденная ошибка', {});
        }
      })
      .finally(() => {
        setValue('cipher', '');
        setIsLoading(false);
        onClose();
      });
  };

  const chiperValue = watch('cipher');
  const disableSaveButton = !chiperValue;

  return (
    <Popup
      {...props}
      customStyle={{ overflow: 'visible' }}
    >
      <Typography
        variant='h5'
        mb={3}
        textAlign={'center'}
      >
        Внесите данные
      </Typography>
      <Grid
        container
        spacing={5}
        padding={3}
        minWidth={900}
        mb={3}
      >
        <Grid
          item
          xs={12}
        >
          <Controller
            control={control}
            name='cipher'
            render={({ field }) => (
              <TextField
                {...field}
                label={'Шифр'}
                variant='standard'
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        spacing={4}
      >
        <Grid item>
          <Button
            onClick={onClose}
            variant='text'
          >
            отменить
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            size='small'
            onClick={handleSubmit(handleSave)}
            loading={isLoading}
            loadingPosition='start'
            variant='text'
            disabled={disableSaveButton}
          >
            {!isLoading && <span>сохранить</span>}
          </LoadingButton>
        </Grid>
      </Grid>
    </Popup>
  );
};
