import axios from 'axios';
import { PillarResponse, ReplacementRequest } from '@/dto/taskmap/task/TaskResultDto';
import { HOST, yodaGetAccessToken } from './YodaRestService';
import { axiosGet, axiosPost, axiosPut } from './helpers';
import { ApiResponse } from '@/types/api';

export async function saveReplacementOld(
  id: string,
  replacementRequest: ReplacementRequest
): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${id}`;
  return axiosPost(url, replacementRequest);
}

export async function saveReplacement(
  id: string,
  replacementRequest: ReplacementRequest
): ApiResponse<any> {
  const url = HOST + `/rest/odpu/${id}/smr`;
  return axiosPut(url, replacementRequest);
}

export async function toCancelReplacement(id: string, replacementReason: string): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${id}/cancel`;
  const token = await yodaGetAccessToken();
  return axios.post(url, replacementReason, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function toRepeatInstallationReplacement(id: string): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${id}/repeat-installation`;
  return axiosPost(url, null);
}

export async function toSwapReplacement(currentId: string, targetId: string): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${currentId}/swap/${targetId}`;
  return axiosPost(url, null);
}

export async function toInstalledIncorrect(id: string, reason: string): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${id}/installed-incorrect/${reason}`;
  return axiosPost(url, null);
}
export async function removeByguarantee(id: string): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${id}/removed-by-guarantee`;
  return axiosPost(url, null);
}

export async function oldPUIndicationsIncorrect(id: string): ApiResponse<any> {
  const url = HOST + `/rest/measurement/old/${id}`;
  return axiosPost(url, null);
}

export async function findYungaAnalyzeLogByReplacementId(replacementId: string): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${replacementId}/analyzer`;
  return axiosGet(url);
}

export async function getLastYungaAnalyzeValidationMessage(taskId: string): ApiResponse<any> {
  const url = HOST + `/rest/task/${taskId}/last-yunga-analyze-validation-message`;
  return axiosGet(url);
}

export async function yungaAnalyzeByReplacementId(id: string): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${id}/analyzer`;
  return axiosPost(url, null);
}

export async function changeStatus(id: string): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${id}/actual`;
  return axiosPost(url, {});
}

export async function replacementSendAct(id: string): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${id}/act/status/next`;
  return axiosPost(url, {});
}
export async function fidnReplacementById(id: string): ApiResponse<any> {
  const url = HOST + `/rest/replacement?id=${id}`;
  return axiosGet(url);
}
export async function toSwapReplacementByTaskId(
  replacementId: string,
  taskId: string
): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${replacementId}/move/${taskId}`;
  return axiosPost(url, null);
}

export async function getSmrLockerCatalogById(contractId: string): ApiResponse<PillarResponse> {
  const url = HOST + `/rest/catalog/accepted-smr-locker/${contractId}`;
  return axiosGet(url);
}
