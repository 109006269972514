import * as React from 'react';
import { headCells } from './constants';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ITableCells, TableCells } from '../TableCells';

export const TableHeader = (props: ITableCells) => {
  return (
    <TableHead>
      <TableRow>
        <TableCells
          {...props}
          headCells={headCells}
        />
      </TableRow>
    </TableHead>
  );
};
