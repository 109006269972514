import React, { useState } from 'react';
import { Button } from '@mui/material';

import { playAudioByKey } from '@/utils/heplers';
import { SupplyOsVirtualizedTableProps } from './SupplyOSVirtualizedTable';
import { SupplyPUTableProps } from '../SupplyPUComponent/SupplyPURow';
import {
  SupplyPUComponentTableRowWrapper,
  SupplyPUTableCell,
  SupplyPUTableInputCell,
  SupplyPUTableInput,
  SupplyPURowButton,
} from '../SupplyPUComponent/SupplyPUComponent.styled';
import {
  focusOnCell,
  DOUBLE_NUMBER_METER_TYPE_CELL_DATA_ATTRIBUTE,
  SupplyPUItemConfirmState,
  useAdminAccess,
  useStorehouseWorkerAccess,
} from '../SupplyPUComponent/utils';
import { SupplyNetworkElementScanField } from '@/dto/SupplyBillServiceDto';
import SupplyReturnBillExtraColumns from '../SupplyReturnBillExtraColumns/SupplyReturnBillExtraColumns';
import { EquipmentTypes } from '../utils';

interface SupplyOsTableProps extends Omit<SupplyPUTableProps, 'data'> {
  data: Omit<SupplyOsVirtualizedTableProps, 'confirmScanedNumber' | 'setOsListItems'>;
}

const SupplyOsRow = ({
  index,
  style,
  data: {
    osListItems,
    removeScanRow,
    handleSave,
    onEditStart,
    disabledOrDraftCheck,
    selectedOs,
    osDupFieldError,
    osExistsFieldError,
    returnBill,
    showMessage,
    confirmScanRow,
    viewMode,
    billWithConfirm,
    relocationGetBill,
    tabType,
    accessProject,
  },
}: SupplyOsTableProps) => {
  const workerAccess = useStorehouseWorkerAccess();
  const adminAccess = useAdminAccess();

  const row = osListItems[index];
  const macImeiScan = selectedOs?.typeScanField === SupplyNetworkElementScanField.MAC_AND_IMEI;

  const [macNumber, setMacNumber] = useState(() => row.mac || '');
  const [imeiNumber, setImeiNumber] = useState(() => row.imei || '');

  const error =
    osDupFieldError?.get(selectedOs?.tmpId ?? -1)?.get(row.tmpId) ||
    osExistsFieldError?.get(selectedOs?.tmpId ?? -1)?.get(row.tmpId);

  const handleBlur = ({ withSound }: { withSound?: boolean }) => {
    handleSave({
      ...row,
      mac: macNumber,
      imei: imeiNumber,
    });
    if (withSound) {
      playAudioByKey('attention');
    }
  };
  const handleFocus = () => onEditStart({ ...row });
  const handleDelete = (rowId: number) => row?.tmpId && removeScanRow(rowId);
  const handleConfirm = (rowId: number) => row?.tmpId && confirmScanRow(rowId);

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const selectNextRow = () => {
        const nextRowCell = osListItems[index + 1]?.tmpId;
        nextRowCell && focusOnCell({ cellRowId: nextRowCell });
      };
      if (macImeiScan) {
        const cellDoubleNumberStage =
          e.currentTarget.dataset[DOUBLE_NUMBER_METER_TYPE_CELL_DATA_ATTRIBUTE]!;
        if (cellDoubleNumberStage === '1') {
          const currentRowId = osListItems[index]?.tmpId;
          currentRowId && focusOnCell({ cellRowId: currentRowId, secondNumber: true });
        } else {
          selectNextRow();
        }
      } else {
        selectNextRow();
      }
    }
  };

  const disableWorkerAccess = workerAccess && returnBill && !adminAccess;
  const disableNumberInput = disabledOrDraftCheck || disableWorkerAccess || relocationGetBill;

  return (
    <div style={style}>
      <SupplyPUComponentTableRowWrapper
        error={!!error}
        unSavedItem={!row.backendItem}
        className='SupplyVirtualizedTable_Row'
      >
        <SupplyPUTableCell
          withoutPadding
          narrow
        >
          {row.rowNum}
        </SupplyPUTableCell>
        <SupplyPUTableCell>{selectedOs?.typeName}</SupplyPUTableCell>
        {macImeiScan ? (
          <>
            <SupplyPUTableInputCell
              confirmed={!error && row.confirmed === SupplyPUItemConfirmState.bothNumbers}
            >
              <SupplyPUTableInput
                type='text'
                name='imei'
                value={imeiNumber}
                onChange={(e) => setImeiNumber(e.target.value)}
                onFocus={handleFocus}
                onKeyDown={handleEnter}
                autoComplete='off'
                data-tmpid={row.tmpId}
                data-double-number='1'
                disabled={disableNumberInput}
              />
            </SupplyPUTableInputCell>
            <SupplyPUTableInputCell
              onBlur={() => handleBlur({ withSound: true })}
              confirmed={!error && row.confirmed === SupplyPUItemConfirmState.bothNumbers}
            >
              <SupplyPUTableInput
                type='text'
                name='mac'
                value={macNumber}
                onChange={(e) => setMacNumber(e.target.value)}
                onFocus={handleFocus}
                onKeyDown={handleEnter}
                autoComplete='off'
                data-tmpid={row.tmpId}
                data-double-number='2'
                disabled={disableNumberInput}
              />
            </SupplyPUTableInputCell>
          </>
        ) : (
          <SupplyPUTableInputCell
            onBlur={() => handleBlur({ withSound: false })}
            confirmed={!error && row.confirmed === SupplyPUItemConfirmState.bothNumbers}
          >
            <SupplyPUTableInput
              type='text'
              name='imei'
              value={imeiNumber}
              onChange={(e) => setImeiNumber(e.target.value)}
              onFocus={handleFocus}
              onKeyDown={handleEnter}
              autoComplete='off'
              data-tmpid={row.tmpId}
              disabled={disableNumberInput}
            />
          </SupplyPUTableInputCell>
        )}
        {returnBill && (
          <>
            <SupplyReturnBillExtraColumns
              handleSave={handleSave}
              row={row}
              disabled={disabledOrDraftCheck && !accessProject}
              showMessage={showMessage}
              type={EquipmentTypes.OS}
              selectedEquipment={selectedOs}
            />
          </>
        )}
        {!viewMode && (
          <>
            {tabType !== 'relocation_get' && (
              <SupplyPUTableCell>
                <Button
                  disabled={disabledOrDraftCheck}
                  onClick={() => handleDelete(row.tmpId)}
                >
                  Удалить
                </Button>
              </SupplyPUTableCell>
            )}

            {billWithConfirm && workerAccess && (
              <SupplyPUTableCell>
                <SupplyPURowButton
                  disabled={disabledOrDraftCheck && !relocationGetBill}
                  onClick={() => handleConfirm(row.tmpId)}
                  errorBorder={row.confirmed !== SupplyPUItemConfirmState.bothNumbers}
                >
                  Подтвердить
                </SupplyPURowButton>
              </SupplyPUTableCell>
            )}
          </>
        )}
      </SupplyPUComponentTableRowWrapper>
    </div>
  );
};

export default SupplyOsRow;
