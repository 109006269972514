import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useYunga } from '../hooks/YungaHook';
import moment from 'moment';
import CopyButton from '@/components/button/CopyButton';
import { YungaStatus } from '../types/yungaTypes';
import { YungaItemResponse } from '../api/dto/response';

interface YungaLogItemProps {
  yungaLogResponse: YungaItemResponse;
}

export const YungaLogItem = (props: YungaLogItemProps) => {
  const { yungaLogResponse } = props;
  const { fileId, setFileId } = useYunga();

  const handleChange = (val: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setFileId(isExpanded ? val : undefined);
  };

  const getDateTime = (val?: Date) => {
    return val ? moment(val).format('DD.MM.yyyy HH:mm:ss') : '-';
  };

  const getColorStatus = (val?: string) => {
    if (!val) {
      return '';
    }
    switch (val) {
      case YungaStatus.SENT:
        return '';
      case YungaStatus.OK:
        return '#CEEDC7';
      case YungaStatus.ERROR:
        return '#FFCAC8';
    }
  };

  return (
    <Accordion
      expanded={fileId === yungaLogResponse.fileId}
      onChange={handleChange(yungaLogResponse.fileId)}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='yunga-item'
        id='yunga-item-header'
        sx={{
          boxShadow: '0px 4px 10px 0px #00000040',
          backgroundColor: getColorStatus(yungaLogResponse.status),
        }}
      >
        <Grid
          container
          direction={'row'}
          spacing={2}
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item>
            <Grid
              item
              container
              direction={'row'}
              alignItems='center'
              justifyContent={'space-between'}
              gap={1}
            >
              <Grid item>
                <Typography variant='subtitle2'>log ID: {yungaLogResponse.id}</Typography>
              </Grid>
              <Grid
                item
                sx={{ display: 'flex', alignItems: 'center' }}
                onClick={(e) => e.stopPropagation()}
              >
                <CopyButton
                  onlyIcon
                  textToCopy={yungaLogResponse.id}
                  tooltipText='Копировать log ID'
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={'row'}
              alignItems='center'
              gap={1}
              justifyContent={'space-between'}
            >
              <Grid item>
                <Typography variant='subtitle2'>file ID: {yungaLogResponse.fileId}</Typography>
              </Grid>
              <Grid
                item
                sx={{ display: 'flex', alignItems: 'center' }}
                onClick={(e) => e.stopPropagation()}
              >
                <CopyButton
                  onlyIcon
                  textToCopy={yungaLogResponse.fileId}
                  tooltipText='Копировать file ID'
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant='subtitle2'
                color={'#7B8FA1'}
              >
                to: {getDateTime(yungaLogResponse.requestAt)} from:{' '}
                {getDateTime(yungaLogResponse.responseAt)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant='h6'> {yungaLogResponse.status}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {yungaLogResponse.response && (
          <Typography variant='h6'>
            <pre style={{ whiteSpace: 'pre-wrap' }}> {yungaLogResponse.response} </pre>
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
