import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { Button, Typography } from '@mui/material';
import { Popup } from '@/components/popups';
import { useGalleryData } from '@/components/Gallery/store';
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import { useCatalog } from '@/hooks/CatalogHook';
import { uploadFile } from '@/services/FileService';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { LoadingButton } from '@mui/lab';

const TAG = 'UnAttachmentDialog';

export interface AddUnAttachmentDialogProps {
  obj: string;
  openDialog: boolean;
  onClose: () => void;
  filterIds?: string[];
}

const mapToArray = (map: Map<string, string>) => {
  return Array.from(map, ([value, label]) => ({ value, label }));
};

function logError(error: string) {
  console.log(TAG + 'error data ' + error);
}

export const AddUnAttachmentDialog = (props: AddUnAttachmentDialogProps) => {
  const { obj, openDialog, onClose, filterIds } = props;
  const [files, setFiles] = useState<any>();

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const [selectedFileType, setSelectedFileType] = React.useState<SelectFilterOptType[]>([]);

  const [file, setFile] = React.useState<string>('');
  const [fileName, setFileName] = React.useState<string>('');

  const methods = useForm();
  const { handleSubmit } = methods;

  const [visHint, setVisHint] = useState(false);

  const [visFileType, setVisFileType] = useState(false);
  const [visFile, setVisFile] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const getTaskFileStage = useGalleryData(({ getTaskFileStage }) => getTaskFileStage);
  const addImageToGroupById = useGalleryData(({ addImageToGroupById }) => addImageToGroupById);

  const checkValues = () => {
    const tempVisFile = file.length < 30;
    const tempVisType = selectedFileType.length === 0;
    setVisFile(tempVisFile);
    setVisFileType(tempVisType);
    return tempVisFile || tempVisType;
  };

  const handleClose = () => {
    onClose();
  };
  const handleCreate = (obj: string) => {
    const check: boolean = checkValues();

    if (!check) {
      setIsLoading(true);
      uploadFile(obj, getDataFromFilter(selectedFileType, 'value'), getTaskFileStage(), files)
        .then((res) => {
          addImageToGroupById(
            obj,
            res.data[0]?.fileId,
            getDataFromFilter(selectedFileType, 'label'),
            new Date(),
            res.data[0]?.url
          );
          onClose();
        })
        .catch((err) => logError(err))
        .finally(() => setIsLoading(false));
    }
    setVisHint(check);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(e.target.files);
    const files = e.target.files;
    if (files) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        setFileName(files[0].name);
        setFile(reader.result as string);
      };
    }
  };

  return (
    <Popup
      onClose={handleClose}
      onSuccess={() => handleCreate(obj)}
      isOpen={openDialog}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(() => handleCreate(obj))}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Typography
            variant={'h4'}
            style={{ marginBottom: 12 }}
          >
            Добавить фото
          </Typography>

          <MultiSelect
            options={
              filterIds
                ? mapToArray(getCatalogMapWithErr('fileType')).filter((elem) =>
                    filterIds.find((el) => el == elem.value)
                  )
                : mapToArray(getCatalogMapWithErr('fileType'))
            }
            value={selectedFileType}
            label={'Тип файла'}
            onChange={(sel: SelectFilterOptType[]) => setSelectedFileType(sel)}
            sx={{ minWidth: 300 }}
          />
          <div>
            <input
              type='file'
              id='input__file'
              className='input input__file'
              hidden={true}
              onChange={handleImageChange}
              required={true}
              multiple={false}
            />
            <label
              htmlFor='input__file'
              style={{ cursor: 'pointer', marginTop: 16, marginBottom: 12 }}
            >
              <span className='input__file-icon-wrapper'>
                <AddPhotoAlternateRoundedIcon />
              </span>
              <span className='input__file-button-text'>Прикрепить файл</span>
            </label>
            <p>{fileName}</p>
          </div>
          {visHint && (
            <div style={{ marginLeft: '10px' }}>
              <p>
                Пожалуйста, заполните обязательные поля:{' '}
                <span>{visFileType ? 'Тип файла ' : ''}</span>
                <span>{visFile ? 'Файл ' : ''}</span>
              </p>
            </div>
          )}
          <div>
            <LoadingButton
              loading={isLoading}
              style={{ marginRight: 34 }}
              variant={'contained'}
              onClick={() => handleCreate(obj)}
            >
              Сохранить
            </LoadingButton>
            <Button
              variant={'contained'}
              onClick={handleClose}
            >
              отменить
            </Button>
          </div>
        </form>
      </FormProvider>
    </Popup>
  );
};
