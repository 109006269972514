import React, { FC } from 'react';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import HistoryToggleOffRoundedIcon from '@mui/icons-material/HistoryToggleOffRounded';

export enum StatusItemTypes {
  EDIT,
  MESSAGE,
  LOADING,
}
interface IStatusItem {
  type?: StatusItemTypes;
  countAll?: number;
  countTmp?: number;
}

export const StatusItem: FC<IStatusItem> = ({ type, countTmp = 0, countAll = 0 }) => {
  return (
    <div style={{ width: '33%', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
      {type === StatusItemTypes.EDIT && <BorderColorRoundedIcon />}
      {type === StatusItemTypes.LOADING && <HourglassTopRoundedIcon />}
      {type === StatusItemTypes.MESSAGE && (
        <>
          <MailOutlineRoundedIcon style={{ marginRight: '15%' }} />
          <span style={{ marginRight: '15%' }}>{`${countTmp}/${countAll}`}</span>
          {countTmp === countAll ? <InventoryRoundedIcon /> : <HistoryToggleOffRoundedIcon />}
        </>
      )}
    </div>
  );
};
