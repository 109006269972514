import { AdditionalParam, GeoBounds, GeoRequest, TaskFilterParam } from '@/dto/taskmap/Dto';
import { GeoCoordinate } from '@/components/maps/types';
import { GeoBoundsNew, GeoPoint } from '@/components/maps/zoningMap/Dto';
import * as geolib from 'geolib';

export const makeGeoRequest = (
  filter: TaskFilterParam,
  geoBounds?: GeoBounds,
  additionalParam?: AdditionalParam
): GeoRequest => {
  return {
    taskFilterParam: filter,
    geoBounds: geoBounds,
    additionalParam: additionalParam,
  };
};

export const coorfinateToGeoBounds = (coords: number[][]): GeoBoundsNew | undefined => {
  if (coords) {
    return {
      leftBottom: {
        longitudeX: coords[0][0],
        latitudeY: coords[0][1],
      },
      rightTop: {
        longitudeX: coords[1][0],
        latitudeY: coords[1][1],
      },
    };
  }
  return undefined;
};

export const geoBoundsToCoorfinate = (geoBounds: GeoBoundsNew): number[][] => {
  return [
    [geoBounds.leftBottom.longitudeX, geoBounds.leftBottom.latitudeY],
    [geoBounds.rightTop.longitudeX, geoBounds.rightTop.latitudeY],
  ];
};

export const coordinateToGeoCoordinate = (coords: number[][][]) => {
  let result: GeoCoordinate[] = [];
  if (coords && coords.length > 0) {
    result = coords[0].map((t: number[]) => {
      return { x: t[0], y: t[1] };
    });
  }
  return result;
};
// todo remove
export const geoCoordinateToCoordinate = (geoCoordinate: GeoCoordinate[]) => {
  const result: number[][][] = [];
  if (geoCoordinate) {
    result[0] = geoCoordinate.map((t) => {
      return [t.x, t.y];
    });
  }
  return result;
};

export const geoPointToCoordinate = (geoPoints: GeoPoint[]) => {
  const result: number[][][] = [];
  if (geoPoints) {
    result[0] = geoPoints.map((t) => {
      return [t.longitudeX, t.latitudeY];
    });
  }
  return result;
};

export const geoBoundsToGeometry = (bounds: GeoBoundsNew): number[][][] => {
  const lb: GeoPoint = bounds.leftBottom;
  const rt: GeoPoint = bounds.rightTop;
  const rb: GeoPoint = { longitudeX: lb.longitudeX, latitudeY: rt.latitudeY };
  const lt: GeoPoint = { longitudeX: rt.longitudeX, latitudeY: lb.latitudeY };
  return geoPointToCoordinate([lt, rt, rb, lb]);
};

export const toColor = (val: string) => {
  let hash = 0;
  if (val == 'none') {
    return '#989293';
  }
  for (let i = 0; i < val.length; i++) {
    hash = val.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }

  return colour?.toUpperCase() || '';
};

export const toGeolibFormat = (coords: number[][]) => {
  return coords
    .filter((t) => t[0] && t[1])
    .map((t) => {
      return { latitude: t[1], longitude: t[0] };
    });
};

const polygonToGeolibFormat = (coords: number[][][]) => {
  const outside = coords[0];
  if (outside) {
    return toGeolibFormat(coords[0]);
  }
};

export const calculateCenter = (coordinate?: number[][][]) => {
  if (coordinate) {
    const geometry = polygonToGeolibFormat(coordinate);
    if (geometry) {
      const center = geolib.getCenter(geometry);
      return center ? [center?.longitude, center?.latitude] : [];
    }
  }
  return [];
};

export const calculateBounds = (coordinate?: number[][][]): number[][][] | undefined => {
  if (coordinate) {
    const geometry = polygonToGeolibFormat(coordinate);
    if (geometry) {
      const bounds = geolib.getBounds(geometry);
      const result = [
        [bounds.minLng, bounds.minLat],
        [bounds.maxLng, bounds.maxLat],
      ];
      return result;
    }
  }
  return undefined;
};

export const prettyNumber = (val?: number) => {
  return val?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
