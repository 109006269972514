import { getYodaJWTLocal } from '@/services/SupplyBillService';
import React, { useEffect, useState } from 'react';
import { decodeJWT, SAVE_SUPPLY_JWT_TOKEN } from './utils';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { UserNameText } from './StoryHouseUserName.styled';

const StorehouseUserName = () => {
  const [userName, setUserName] = useState(null);
  const storehouseAccess = useAccess({
    access: [AccessEnum.Storehouse, AccessEnum.StorehouseInfo],
    stopRedirect: true,
  });

  useEffect(() => {
    const decodeAndSetName = () => {
      const localJwt = getYodaJWTLocal();
      const decodedUserName = decodeJWT(localJwt)?.user_full_name;
      if (decodedUserName) setUserName(decodedUserName);
    };

    decodeAndSetName();

    window.addEventListener(SAVE_SUPPLY_JWT_TOKEN, decodeAndSetName);

    return () => {
      window.removeEventListener(SAVE_SUPPLY_JWT_TOKEN, decodeAndSetName);
    };
  }, []);

  if (!storehouseAccess) return null;

  return <UserNameText>{userName}</UserNameText>;
};

export default StorehouseUserName;
