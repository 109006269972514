import { Table, Empty } from 'antd';
import { SmsStatusStatisticsItem } from '../types';
import { SmsStatusStatisticsMainTableStaticColumns } from './utils';

const lookUp = {
  FIRST_SMS: 'Первое СМС',
  SECOND_SMS: 'Второе СМС',
  THIRD_SMS: 'Третье СМС',
};

interface RecordType {
  contractName: string;
  type: keyof typeof lookUp;
}
interface SmsStatusStatisticsMainTableProps {
  data: SmsStatusStatisticsItem[];
  renderExtradDataTables: any;
}

export const SmsStatusStatisticsMainTable = ({
  data,
  renderExtradDataTables,
}: SmsStatusStatisticsMainTableProps) => {
  const columns: any[] = [
    {
      title: 'Проект',
      dataIndex: 'contractName',
      align: 'center',
      width: 200,
      render: (text: string, record: RecordType) => <>{lookUp[record.type] ?? text}</>,
    },
    {
      title: 'Кол-во абонентов, которым невозможно отправить СМС (некорректный/городской номер)',
      dataIndex: 'subscriberWhomImpossibleSendSms',
      align: 'center',
      className: 'custom-column-class-yellow',
      render: (text: string, record: any) => (
        <>{record?.subscriberWhomImpossibleSendSms?.length ?? 0}</>
      ),
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: () => {
            renderExtradDataTables(record.subscriberWhomImpossibleSendSms, 1);
          },
        };
      },
    },
    ...SmsStatusStatisticsMainTableStaticColumns,
    {
      title: 'Кол-во абонентов, перезвонивших после получения СМС',
      dataIndex: 'incomingCallAfterSms',
      align: 'center',
      className: 'custom-column-class-blue',
      render: (text: string, record: any) => <>{record?.incomingCallAfterSms?.length ?? 0}</>,
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: () => {
            renderExtradDataTables(record.incomingCallAfterSms, 2);
          },
        };
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false} // Отключаем пагинацию
      scroll={{ y: 300 }}
      style={{ tableLayout: 'fixed', width: '89%', margin: 'auto', minHeight: '300px' }}
      bordered
      locale={{
        emptyText: (
          <Empty
            style={{ minHeight: 250 }}
            description={'Нет данных для отображения'}
            image={null}
          />
        ),
      }}
      size='small'
    />
  );
};
