import React, { FC } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { ActDateHistoryResponse } from '@/dto/taskmap/Dto';

export const TaskActHistoryItem: FC<ActDateHistoryResponse> = ({
  actOn,
  status,
  description,
  ksDateType,
  contractor,
}) => {
  return (
    <Grid
      item
      container
      spacing={2}
      xs={6}
    >
      <Grid
        item
        xs={4}
      >
        <b>
          {ksDateType == 'SMR' ? 'СМР' : ksDateType == 'PNR' ? 'ПНР' : ''}{' '}
          {moment(actOn).format('DD.MM.YYYY')}
        </b>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <b>{status}</b> {contractor}
      </Grid>
      <Grid
        item
        xs={4}
      >
        Оборудование: {description}
      </Grid>
    </Grid>
  );
};
