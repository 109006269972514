import {
  HouseEntranceShemeFlat,
  HouseEntranceShemeFlatsWrapper,
} from '@/components/sections/HouseEntranceSheme/HouseEntranceSheme.styled';
import { syncErrorCatch } from '@/hooks/ActionLogHook';

import { Button, IconButton, Menu, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import {
  Droppable,
  Draggable,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import {
  PnrDndEditModalDroppableInner,
  PnrDndEditModalFloorWrapper,
  PnrDndEditModalAddFlatForm,
} from './PnrDndEditModal.styled';
import { color } from '@/styles/mixins';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BlockIcon from '@mui/icons-material/Block';
import { HouseFloor } from '@/dto/taskmap/Dto';
import {
  existedFlatsByNumber,
  generateExistedFlatsErrorMessage,
  UpdateDnDFloorFunc,
} from './utils';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { HouseEntranceHeaderTextField } from '@/components/features/pnr/HouseEntranceHeader/HouseEntranceHeader.styled';
import { replaceNonNums } from '@/components/storehouse/SupplyPUComponent/utils';
import { useAnchorElement } from '@/hooks/useAnchorElement';

export interface PnrDndEditModalFloorProps {
  floor: HouseFloor;
  entranceNumber: number;
  updateFloorEntry: (
    entranceNumber: number,
    floorNumber: number,
    updateFunc: UpdateDnDFloorFunc,
    addFlatNumber?: string
  ) => void;
  erroredFlats?: string[];
}

const PnrDndEditModalFloor = ({
  floor,
  entranceNumber,
  updateFloorEntry,
}: PnrDndEditModalFloorProps) => {
  const {
    anchorEl: anchorFlatAdd,
    handleOpen: handleOpenFlatAdd,
    handleClose: handleCloseFlatAdd,
    open: openFlatAdd,
  } = useAnchorElement();

  const [addFlatNumber, setAddFlatNumber] = useState('');

  const getItemStyle = (
    snapshot: DraggableStateSnapshot,
    draggableStyle?: DraggingStyle | NotDraggingStyle
  ) => {
    if (!snapshot.isDropAnimating) {
      return {
        ...draggableStyle,
        border: `0.5px solid ${snapshot.isDragging ? color('muiBlue') : color('trueGray')}`,
      };
    }
    const { moveTo } = snapshot.dropAnimation ?? { moveTo: { x: 0, y: 0 }, curve: 0.5 };
    const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;

    return {
      ...draggableStyle,
      transform: `${translate} `,
      border: `0.5px solid ${snapshot.isDragging ? color('muiBlue') : color('trueGray')}`,
      ...(snapshot.isDropAnimating && snapshot.draggingOver === 'trash' && { opacity: 0 }),
    };
  };

  const handleClearFlats = () => {
    updateFloorEntry(entranceNumber, floor.number, UpdateDnDFloorFunc.CLEAR);
  };

  const handleBlockFloor = () => {
    updateFloorEntry(entranceNumber, floor.number, UpdateDnDFloorFunc.BLOCK);
  };

  const handleFlatNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = replaceNonNums(event.target.value);
    setAddFlatNumber(numericValue ?? '');
  };

  const hanleFlatSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (addFlatNumber.length < 1) {
      syncErrorCatch('Введите номер квартиры', {});
      return;
    }
    const existedFlats = existedFlatsByNumber(addFlatNumber);
    if (!existedFlats.length) {
      updateFloorEntry(entranceNumber, floor.number, UpdateDnDFloorFunc.ADD, addFlatNumber);
    } else {
      generateExistedFlatsErrorMessage(existedFlats);
    }
    setAddFlatNumber('');
  };

  return (
    <div>
      <PnrDndEditModalFloorWrapper>
        <Tooltip
          title={floor?.isNonResidential ? 'Этаж заблокирован' : 'Заблокировать этаж'}
          placement='top'
        >
          <IconButton
            style={{ fontSize: 20 }}
            onClick={handleBlockFloor}
            color={floor?.isNonResidential ? 'info' : undefined}
          >
            <BlockIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={'Очистить этаж'}
          placement='top'
        >
          <IconButton
            style={{ fontSize: 20 }}
            onClick={handleClearFlats}
          >
            <DeleteForeverIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>
        <HouseEntranceShemeFlat
          empty
          sideWidth
        >
          {floor.number}
        </HouseEntranceShemeFlat>
        <HouseEntranceShemeFlatsWrapper style={{ minWidth: 37 }}>
          <Droppable
            droppableId={`${entranceNumber}-${floor.number}`}
            direction='horizontal'
          >
            {(provided, snapshot) => (
              <PnrDndEditModalDroppableInner
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
                disableDrop={floor?.isNonResidential}
              >
                {floor.flats
                  ?.sort((a, b) => Number(a.number) - Number(b.number))
                  ?.map((flat, index) => (
                    <React.Fragment key={flat.number}>
                      {!!flat?.number.length && (
                        <Draggable
                          draggableId={flat.number}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <HouseEntranceShemeFlat
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot, provided.draggableProps.style)}
                              dndStyles
                            >
                              {flat.number}
                            </HouseEntranceShemeFlat>
                          )}
                        </Draggable>
                      )}
                    </React.Fragment>
                  ))}
                {provided.placeholder}
              </PnrDndEditModalDroppableInner>
            )}
          </Droppable>
        </HouseEntranceShemeFlatsWrapper>
        <Tooltip
          title={'Добавить квартиру'}
          placement='top'
        >
          <div>
            <IconButton
              style={{ fontSize: 20 }}
              onClick={handleOpenFlatAdd}
              disabled={floor?.isNonResidential}
            >
              <AddRoundedIcon fontSize='inherit' />
            </IconButton>
          </div>
        </Tooltip>

        <Menu
          anchorEl={anchorFlatAdd}
          open={openFlatAdd}
          onClose={() => handleCloseFlatAdd()}
        >
          <PnrDndEditModalAddFlatForm onSubmit={hanleFlatSubmit}>
            <HouseEntranceHeaderTextField
              label='Номер квартиры'
              value={addFlatNumber}
              variant='standard'
              InputLabelProps={{ shrink: true }}
              onChange={handleFlatNumberChange}
              autoFocus
              autoComplete='off'
            />
            <Button
              variant='contained'
              type='submit'
            >
              Добавить
            </Button>
          </PnrDndEditModalAddFlatForm>
        </Menu>
      </PnrDndEditModalFloorWrapper>
    </div>
  );
};

export default React.memo(PnrDndEditModalFloor);
