import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { createStore } from '@/utils/createStore';

export interface SelectValue {
  value: string;
  argValue?: FilterArgEnum;
  label: string;
}
export interface SingleArg {
  value: string;
  argValue: FilterArgEnum;
}
export interface DateArg {
  value: number[];
  argValue: FilterArgEnum;
}

export interface MultiArg {
  value: SelectFilterOptType[];
  argValue: FilterArgEnum;
}

type ISingleSelectValue = {
  odpuStage: SelectValue;
};

export type ILabelCheckbox = {
  onlyWithTko: boolean;
  onlyWithChanges: boolean;
  onlyWithCallCenter: boolean;
  onlyWithCreationConfirmation: boolean;
  isShowingNotRelevant: boolean;
};
export type IDateInput = {
  date: DateArg;
  plannedDateOnList: DateArg;
};

export type ISingleInput = {
  address: SingleArg;
  korpus: SingleArg;
  settlement: SingleArg;
  street: SingleArg;
  house: SingleArg;
  tpName: SingleArg;
  cipher: SingleArg;
  appartment: SingleArg;
  accountingNum: SingleArg;
  schemeBYT: SingleArg;
  networkElementNum: SingleArg;
  taskId: SingleArg;
  vruInputId: SingleArg;
  meterChangeId: SingleArg;
  stampNum: SingleArg;
  puNumOrMac: SingleArg;
  taskNum: SingleArg;
  tkoStatus: SingleArg;
  territorialBranch: SingleArg;
  existingPuNum: SingleArg;
  // networkFilial: SingleArg;
  // networkResArea: SingleArg;
  customerId: SingleArg;
  registrationNum: SingleArg;
  subscriberFio: SingleArg;
  exPuNum: SingleArg;
};
export type IMultiInput = {
  contractorIds: MultiArg;
  networkFilial: MultiArg;
  electricNetworksEnterprise: MultiArg;
  executorIds: MultiArg;
  taskPriority: MultiArg;
  taskStatus: MultiArg;
  taskType: MultiArg;
  contractIds: MultiArg;
  consumer: MultiArg;
  actStatus: MultiArg;
  managingOrganization: MultiArg;
};

type ObjectKey<Obj> = keyof Obj;
export type SingleInputKeys = ObjectKey<ISingleInput>;
export type MultiInputKeys = ObjectKey<IMultiInput>;
export type DateInputKeys = ObjectKey<IDateInput>;

export type LabelCheckboxKeys = ObjectKey<ILabelCheckbox>;
export type SingleSelectValueKeys = ObjectKey<ISingleSelectValue>;
export type AllInputKeys =
  | SingleInputKeys
  | MultiInputKeys
  | DateInputKeys
  | LabelCheckboxKeys
  | SingleSelectValueKeys;

type ITasksBrowseFunks = {
  setSingleValue: (key: SingleInputKeys) => (value: string) => void;
  setSingleValueArg: (key: SingleInputKeys) => (argValue: FilterArgEnum) => void;
  setMultiValue: (key: MultiInputKeys) => (value: SelectFilterOptType[]) => void;
  setMultiValueArg: (key: MultiInputKeys) => (argValue: FilterArgEnum) => void;
  setDateValue: (key: DateInputKeys) => (value: number[]) => void;
  setDateValueArg: (key: DateInputKeys) => (argValue: FilterArgEnum) => void;
  setBooleanValue: (key: LabelCheckboxKeys) => (value: boolean) => void;
  setSingleSelectValue: (key: SingleSelectValueKeys) => (value: SelectValue) => void;
  setDefaultValue: () => void;
  setDefaultHelpValue: () => void;
};
export type ITasksBrowseStore = ITasksBrowseFunks &
  ISingleInput &
  IMultiInput &
  ILabelCheckbox &
  ISingleSelectValue &
  IDateInput;

export const defaultValueSingleInputs: ISingleInput = {
  address: { value: '', argValue: FilterArgEnum.CONTAIN },
  korpus: { value: '', argValue: FilterArgEnum.CONTAIN },
  settlement: { value: '', argValue: FilterArgEnum.CONTAIN },
  street: { value: '', argValue: FilterArgEnum.CONTAIN },
  house: { value: '', argValue: FilterArgEnum.CONTAIN },
  tpName: { value: '', argValue: FilterArgEnum.CONTAIN },
  cipher: { value: '', argValue: FilterArgEnum.CONTAIN },
  appartment: { value: '', argValue: FilterArgEnum.EQUAL },
  accountingNum: { value: '', argValue: FilterArgEnum.EQUAL },
  taskNum: { value: '', argValue: FilterArgEnum.EQUAL },
  stampNum: { value: '', argValue: FilterArgEnum.EQUAL },
  puNumOrMac: { value: '', argValue: FilterArgEnum.EQUAL },
  schemeBYT: { value: '', argValue: FilterArgEnum.EQUAL },
  networkElementNum: { value: '', argValue: FilterArgEnum.EQUAL },
  taskId: { value: '', argValue: FilterArgEnum.EQUAL },
  vruInputId: { value: '', argValue: FilterArgEnum.EQUAL },
  meterChangeId: { value: '', argValue: FilterArgEnum.EQUAL },
  tkoStatus: { value: '', argValue: FilterArgEnum.EQUAL },
  territorialBranch: { value: '', argValue: FilterArgEnum.EQUAL },
  existingPuNum: { value: '', argValue: FilterArgEnum.EQUAL },
  // networkResArea: { value: '', argValue: FilterArgEnum.EQUAL },
  customerId: { value: '', argValue: FilterArgEnum.CONTAIN },
  registrationNum: { value: '', argValue: FilterArgEnum.CONTAIN },
  subscriberFio: { value: '', argValue: FilterArgEnum.CONTAIN },
  exPuNum: { value: '', argValue: FilterArgEnum.CONTAIN },
};

export const defaultValueMultiInput: IMultiInput = {
  contractIds: { value: [], argValue: FilterArgEnum.IN_LIST },
  contractorIds: { value: [], argValue: FilterArgEnum.IN_LIST },
  executorIds: { value: [], argValue: FilterArgEnum.IN_LIST },
  taskType: { value: [], argValue: FilterArgEnum.IN_LIST },
  taskPriority: { value: [], argValue: FilterArgEnum.IN_LIST },
  taskStatus: { value: [], argValue: FilterArgEnum.IN_LIST },
  consumer: { value: [], argValue: FilterArgEnum.IN_LIST },
  actStatus: { value: [], argValue: FilterArgEnum.IN_LIST },
  managingOrganization: { value: [], argValue: FilterArgEnum.IN_LIST },
  networkFilial: { value: [], argValue: FilterArgEnum.IN_LIST },
  electricNetworksEnterprise: { value: [], argValue: FilterArgEnum.IN_LIST },
};

export const defaultValueCheckbox: ILabelCheckbox = {
  onlyWithChanges: false,
  onlyWithTko: false,
  onlyWithCallCenter: false,
  onlyWithCreationConfirmation: false,
  isShowingNotRelevant: false,
};

export const defaultValueDateInput: IDateInput = {
  date: { value: [], argValue: FilterArgEnum.RANGE },
  plannedDateOnList: { value: [], argValue: FilterArgEnum.RANGE },
};

export const DEFAULT_SINGLE_SELECT = { value: '', label: '', argValue: FilterArgEnum.IN_LIST };
export const defaultValueSingleSelectValue: ISingleSelectValue = {
  odpuStage: DEFAULT_SINGLE_SELECT,
};

const defaultValue: ISingleInput & IMultiInput & ILabelCheckbox & IDateInput & ISingleSelectValue =
  {
    ...defaultValueSingleInputs,
    ...defaultValueMultiInput,
    ...defaultValueCheckbox,
    ...defaultValueDateInput,
    ...defaultValueSingleSelectValue,
  };

export const useTasksBrowseFilter = createStore<ITasksBrowseStore>(
  (set, get) => ({
    ...defaultValue,

    setDefaultValue: () =>
      set(() => ({
        ...defaultValue,
      })),

    setDefaultHelpValue: () =>
      set(() => ({
        meterChangeId: defaultValue.meterChangeId,
        stampNum: defaultValue.stampNum,
        taskNum: defaultValue.taskNum,
        taskId: defaultValue.taskId,
        networkElementNum: defaultValue.networkElementNum,
        puNumOrMac: defaultValue.puNumOrMac,
      })),

    setBooleanValue: (key) => (value) =>
      set(() => ({
        [key]: value,
      })),

    setSingleSelectValue: (key) => (value) =>
      set(() => ({
        [key]: value ? { ...value, argValue: FilterArgEnum.IN_LIST } : DEFAULT_SINGLE_SELECT,
      })),

    setSingleValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setSingleValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.INSTALLED || get()[key].argValue === FilterArgEnum.INSTALLED) {
        set(() => ({
          [key]: {
            value: '',
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },

    setDateValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setDateValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.RANGE || get()[key].argValue === FilterArgEnum.RANGE) {
        set(() => ({
          [key]: {
            value: [],
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },

    setMultiValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setMultiValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.INSTALLED || get()[key].argValue === FilterArgEnum.INSTALLED) {
        set((state) => ({
          [key]: {
            value: [],
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },
  }),
  'Task browse'
);
