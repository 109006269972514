import { Paper } from '@mui/material';
import StoreHouseComponent from '@/components/storehouse/StoreHouseComponent/StoreHouseComponent';
import { useAccess } from '@/hooks/useAccess';
import '@/styles/callcenterstyle.css';
import { AccessEnum } from '@/app/auth/roles';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const StoreHousePage = () => {
  const hasAccess = useAccess({
    access: [AccessEnum.Storehouse, AccessEnum.StorehouseInfo],
  });

  useMetaTitle('Склад');

  return <Paper className='main-container'>{hasAccess && <StoreHouseComponent />}</Paper>;
};
export default StoreHousePage;
