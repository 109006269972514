import { AxiosResponse } from 'axios';
import { HOST } from './YodaRestService';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from './helpers';
import { NetworkElementRequest, NetworkElementResponse } from '@/dto/NetworkDto';
import { SimChanges } from '@/dto/taskmap/task/TaskResultDto';

export async function getNetworkElements(
  taskId: string
): Promise<AxiosResponse<NetworkElementResponse[], any>> {
  const url = HOST + `/rest/task/${taskId}/network-element`;
  return axiosGet(url);
}

export async function PutSimChange(
  id: string,
  data: {
    iccid: string;
    type: string;
  }
): Promise<AxiosResponse<SimChanges, any>> {
  const url = HOST + `/rest/simChange/${id}`;
  return axiosPut(url, data);
}

export async function СancelSimChange(id: string): Promise<AxiosResponse<string, any>> {
  const url = HOST + `/rest/simChange/${id}/canceled`;
  return axiosPost(url, {});
}

export async function repeatNetworkElement(
  id: string,
  data: NetworkElementRequest
): Promise<AxiosResponse<NetworkElementResponse, any>> {
  const url = HOST + `/rest/network-element/${id}/execute`;
  return axiosPost(url, data);
}

export async function deleteEquippedSimNetworkElement(
  simId: string
): Promise<AxiosResponse<NetworkElementResponse, any>> {
  const url = HOST + `/rest/network-element-equipped-sim/${simId}`;
  return axiosDelete(url);
}
