import { color } from '@/styles/mixins';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { RequestHistoryType } from '../types/types';
import { formatPhoneNumber } from '../utils/CallingMapUtils';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import LanguageIcon from '@mui/icons-material/Language';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';

interface RequestHistoryProps {
  history: RequestHistoryType;
}

const callTypeCatalog: Record<string, string> = {
  'Исходящий звонок': 'Wilstream',
  'Входящий звонок': 'Wilstream',
  'Заявка из сайта': 'Заявка из сайта',
  Звонобот: 'Звонобот',
};
const callTypeCatalogIcon: any = {
  'Исходящий звонок': <PhoneForwardedIcon style={{ color: color('willStrimColor') }} />,
  'Входящий звонок': <PhoneCallbackIcon style={{ color: color('willStrimColor') }} />,
  'Заявка из сайта': <LanguageIcon style={{ color: color('lightBlue') }} />,
  Звонобот: <RssFeedIcon style={{ color: color('green') }} />,
};

const renderCalltype = (type: string) => {
  if (type === 'Исходящий звонок' || type === 'Входящий звонок') {
    return (
      <>
        <span style={{ color: color('willStrimColor') }}>{callTypeCatalog[type]}</span> /{' '}
        <span>{type}</span>
      </>
    );
  } else {
    return (
      <>
        <span>{type}</span>
      </>
    );
  }
};
const RequestHistory = ({ history }: RequestHistoryProps) => {
  return (
    <Stack
      gap={1}
      style={{
        marginTop: 5,
        borderBottom: `1px solid grey`,
        borderTopRightRadius: 0,
      }}
    >
      <Stack
        paddingX={1}
        style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
      >
        <Stack
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <Stack>{callTypeCatalogIcon[history.callType]}</Stack>
          <Stack>
            <span style={{ fontWeight: 'bold', color: color('typographyColorMap1') }}>
              {renderCalltype(history.callType)}
            </span>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant='overline'>
            {history.createAt && moment(history.createAt).format('HH:mm')}{' '}
            {history.createAt && moment(history.createAt).format('DD.MM.YYYY')}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        paddingX={1}
        gap={1}
      >
        {history.requestType && (
          <Typography
            color={color('gray')}
            variant='body1'
          >
            <span style={{ color: color('typographyColorMap2') }}>Тип обращения: </span>
            <span style={{ fontWeight: 'bold', color: color('typographyColorMap1') }}>
              {history.requestType}
            </span>
          </Typography>
        )}
        {history.phoneNumber && (
          <Typography
            color={color('gray')}
            variant='body1'
          >
            <span style={{ color: color('typographyColorMap2') }}> Номер телефона: </span>

            <span style={{ fontWeight: 'bold', color: color('typographyColorMap1') }}>
              {formatPhoneNumber(history.phoneNumber, true)}
            </span>
          </Typography>
        )}
        {history?.id && (
          <Typography
            color={color('gray')}
            variant='body1'
          >
            <span style={{ color: color('typographyColorMap2') }}> Номер обращения: </span>

            <span style={{ fontWeight: 'bold', color: color('typographyColorMap1') }}>
              {history?.id}
            </span>
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
export default RequestHistory;
