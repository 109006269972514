import { Contractor, StatisticDistributionDto } from '@/dto/taskmap/Dto';
import moment from 'moment';

interface AggregatedDataResult {
  labels: string[] | null;
  datasets:
    | {
        label: string;
        data: number[];
        backgroundColor: string;
        minBarLength: number;
        stack: string;
      }[]
    | null;
}

export interface FilterStatisticDistribution {
  contractId: any;
  dateFrom: string;
  dateTo: string;
  montagePlaceSet: string[];
  onlyOnePhase: boolean;
  onlyThreePhase: boolean;
  onlyTko: boolean;
}

export const aggregatedData = (
  data: StatisticDistributionDto[],
  onlyPlaces: boolean,
  justTko: boolean,
  filterPhases: number[] = [],
  filterMontagePlaces: string[] = []
): AggregatedDataResult => {
  const COLORS = [
    'rgb(121, 85, 72)',
    'rgb(247, 68, 55)',
    'rgb(155, 39, 176)',
    'rgb(64, 81, 181)',
    'rgb(4, 169, 243)',
    'rgb(0, 150, 136)',
    'rgb(76, 176, 81)',
    'rgb(255, 200, 60)',
  ];

  function generateColors(numColors: number) {
    const additionalColors: string[] = [];
    const baseHue = Math.floor(Math.random() * 360);
    const saturation = 70;
    const lightness = 60;

    for (let i = 0; i < numColors; i++) {
      const hue = (baseHue + (i * 360) / numColors) % 360;
      const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
      additionalColors.push(color);
    }

    return additionalColors;
  }

  const additionalColors = generateColors(25);
  const allColors = [...COLORS, ...additionalColors];
  const result: AggregatedDataResult = { labels: null, datasets: null };

  const sortedData = [...data].sort((a, b) => {
    if (a.year !== b.year) {
      return a.year - b.year;
    }
    return a.month - b.month;
  });

  const contractorColors: { [contractorName: string]: string } = {};

  const labels = sortedData.map(({ month, year }: StatisticDistributionDto) => {
    const monthNames = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ];

    return `${monthNames[month - 1]} ${year}`;
  });

  result.labels = labels;
  const phases = filterPhases.length > 0 ? filterPhases : [1, 3];
  const montagePlaces =
    filterMontagePlaces.length > 0 ? filterMontagePlaces : ['холл', 'квартира', 'лестница'];
  const datasets = onlyPlaces
    ? montagePlaces.flatMap((montagePlace, stackIndex) => {
        const contractorNamesForPlace = Array.from(
          new Set(
            sortedData.flatMap((entry: StatisticDistributionDto) =>
              entry.distributionList
                .filter(
                  (contractor: Contractor) =>
                    contractor.montagePlace === montagePlace &&
                    (!phases || phases.includes(contractor.phase))
                )
                .map((contractor: Contractor) => contractor.contractorName)
            )
          )
        );

        return contractorNamesForPlace.map((contractorName, contractorIndex) => {
          const color =
            contractorColors[contractorName] || allColors[contractorIndex % COLORS.length];
          contractorColors[contractorName] = color;

          const dataForContractor = sortedData.map((entry: StatisticDistributionDto) => {
            const contractorEntries = entry.distributionList.filter(
              (contractor: Contractor) =>
                contractor.contractorName === contractorName &&
                contractor.montagePlace === montagePlace &&
                (!phases || phases.includes(contractor.phase))
            );

            const replacementCountSum = contractorEntries.reduce(
              (sum, contractor) =>
                sum + (justTko ? contractor.tkoCount : contractor.replacementCount),
              0
            );

            return replacementCountSum;
          });

          return {
            label: `${contractorName} - ${montagePlace}`,
            data: dataForContractor,
            backgroundColor: color,
            minBarLength: 10,
            stack: `stack-${stackIndex}`,
            place: `${montagePlace}`,
            datalabels: {
              labels: {
                l1: {
                  anchor: 'center',
                  align: 'center',
                  color: 'white',
                  font: {
                    size: 10,
                  },
                },
              },
            },
          };
        });
      })
    : phases
    ? phases.map((phase, stackIndex) => {
        const contractorNamesForPhase = Array.from(
          new Set(
            sortedData.flatMap((entry: StatisticDistributionDto) =>
              entry.distributionList
                .filter((contractor: Contractor) => contractor.phase === phase)
                .map((contractor: Contractor) => contractor.contractorName)
            )
          )
        );

        return contractorNamesForPhase.map((contractorName, contractorIndex) => {
          const color =
            contractorColors[contractorName] || allColors[contractorIndex % COLORS.length];
          contractorColors[contractorName] = color;

          const dataForContractor = sortedData.map((entry: StatisticDistributionDto) => {
            const contractorEntries = entry.distributionList.filter(
              (contractor: Contractor) =>
                contractor.contractorName === contractorName && contractor.phase === phase
            );

            const replacementCountSum = contractorEntries.reduce(
              (sum, contractor) =>
                sum + (justTko ? contractor.tkoCount : contractor.replacementCount),
              0
            );

            return replacementCountSum;
          });

          return {
            label: `${contractorName} - Фаза ${phase}`,
            data: dataForContractor,
            backgroundColor: color,
            minBarLength: 10,
            stack: `stack-${stackIndex}`,
            place: `Фаза ${phase}`,
            datalabels: {
              labels: {
                l1: {
                  anchor: 'center',
                  align: 'center',
                  color: 'white',
                  font: {
                    size: 10,
                  },
                },
              },
            },
          };
        });
      })
    : [];
  result.datasets = datasets.flat();
  return result;
};

export const options = {
  plugins: {
    title: {
      display: true,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      grace: '5%',
      stacked: true,
    },
  },
};

export function showValue(
  montagePlaceOptions: { label: string; value: string }[],
  item: null | string
): any {
  return {
    label: montagePlaceOptions.find((el) => el.value === item)?.label ?? '',
    value: montagePlaceOptions.find((el) => el.value === item)?.value ?? '',
  };
}

export const DEFAULT_FILTER: FilterStatisticDistribution = {
  contractId: { value: '' },
  dateFrom: moment(new Date(Date.now() - 86400000 * 90)).format('yyyy-MM-DD'),
  dateTo: moment(new Date()).format('yyyy-MM-DD'),
  montagePlaceSet: [],
  onlyOnePhase: false,
  onlyThreePhase: false,
  onlyTko: false,
};

interface MontagePlaceData {
  totalReplacementCount: number;
  totalTkoCount: number;
}

interface PhaseData {
  [montagePlace: string]: MontagePlaceData;
}

interface ContractorData {
  [phase: string]: PhaseData;
}

interface AggregatedData {
  [monthYear: string]: {
    [contractorName: string]: ContractorData;
  };
}
export const aggregatedDataNew = (data: StatisticDistributionDto[]) => {
  const sortedData = [...data].sort((a, b) => {
    if (a.year !== b.year) {
      return b.year - a.year;
    }
    return b.month - a.month;
  });

  const result: AggregatedData = {};

  sortedData.forEach(({ distributionList, year, month }) => {
    const monthNames = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ];
    // Ensure year and month are stringified for consistent key structure
    const key = `${monthNames[month - 1]} ${year}`;

    if (!result[key]) {
      result[key] = {};
    }

    distributionList.forEach(
      ({ contractorName, phase, montagePlace, replacementCount, tkoCount }) => {
        if (!result[key][contractorName]) {
          result[key][contractorName] = {};
        }

        if (!result[key][contractorName][phase]) {
          result[key][contractorName][phase] = {};
        }

        if (!result[key][contractorName][phase][montagePlace]) {
          result[key][contractorName][phase][montagePlace] = {
            totalReplacementCount: 0,
            totalTkoCount: 0,
          };
        }

        result[key][contractorName][phase][montagePlace].totalReplacementCount += replacementCount;
        result[key][contractorName][phase][montagePlace].totalTkoCount += tkoCount;
      }
    );
  });

  return result;
};

interface PhaseDataAgre {
  [place: string]: {
    [phase: string]: number;
  };
}

export const aggregatePhases = (phases: any, tko: boolean): PhaseDataAgre => {
  const aggregatedData: any = {};
  Object.entries(phases).forEach(([phase, places]: any) => {
    Object.entries(places).forEach(([place, values]: any) => {
      if (!aggregatedData[place]) {
        aggregatedData[place] = {};
      }
      if (!aggregatedData[place][phase]) {
        aggregatedData[place][phase] = 0;
      }
      aggregatedData[place][phase] += tko ? values?.totalTkoCount : values?.totalReplacementCount;
    });
  });

  return aggregatedData;
};

export const shortNames: Record<string, string> = {
  лестница: 'лест.',
  холл: 'холл',
  квартира: 'кв.',
};

const colors = [
  '#F74437',
  '#EB1E61',
  '#9B27B0',
  '#653AB6',
  '#4051B5',
  '#2197F1',
  '#8CC44B',
  '#CCDE3B',
  '#FFEB3C',
  '#FFC209',
  '#FF9800',
  '#FF5721',
];

const contractColors: any = {};
let colorIndex = 0;

export const getColorForContract = (contract: string): string => {
  if (!contractColors[contract]) {
    contractColors[contract] = colors[colorIndex];
    colorIndex = (colorIndex + 1) % colors.length;
  }
  return contractColors[contract];
};

export const getChartOptions = (aggregatedData: any, maxX: number): any => ({
  indexAxis: 'y',
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      intersect: false,
      callbacks: {
        title: () => '',
        label: () => {
          return Object.entries(aggregatedData).flatMap(([place, phases]: any) =>
            Object.entries(phases).map(([phase, value]) => `${place} Ф${phase}: ${value}`)
          );
        },
      },
      enabled: true,
      backgroundColor: '#FFF',
      titleColor: '#042a0b',
      bodyColor: '#042a0b',
      padding: 10,
      cornerRadius: 10,
      borderColor: 'gray',
      borderWidth: 1,
      xAlign: 'left',
      caretSize: 0,
    },
    datalabels: {
      color: 'white',
      anchor: 'end',
      align: 'start',
      font: { weight: 'bold' },
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      max: maxX,
      grid: {
        display: false,
        barPercentage: 1,
      },

      ticks: {
        display: false,
      },
    },

    y: {
      beginAtZero: true,
      barPercentage: 1,
      ticks: {
        color: 'black',
        font: { weight: 'bold' },
        suggestedMin: 0,

        padding: 0,
        marrgin: 0,
        barPercentage: 1,
        categoryPercentage: 1,
      },
      grid: {
        display: false,
      },
    },
  },

  responsive: true,
  maintainAspectRatio: false,
});

export const getChartData = (aggregatedData: any, contract: string) => ({
  labels: Object.keys(aggregatedData).map(
    (el) =>
      `${shortNames[el]} ${Object.values(aggregatedData[el]).reduce((a: any, b: any) => a + b, 0)}`
  ),
  datasets: [
    {
      label: '',
      data: Object.keys(aggregatedData).map((place) =>
        Object.values(aggregatedData[place]).reduce((a: any, b: any) => a + b, 0)
      ),
      backgroundColor: Object.keys(aggregatedData).map(() => getColorForContract(contract)),
      minBarLength: 15,
      barPercentage: 0.8,
      categoryPercentage: 0.9,
      barThickness: 20,
      callbacks: {},
      datalabels: {
        labels: {
          l1: null,
        },
      },
    },
  ],
});

export const calculatePaddingBottom = (
  xs: number,
  isVerySmallScreen: boolean,
  isSmallScreen: boolean,
  isMediumScreen: boolean,
  isWideScreen: boolean
): number => {
  if (xs !== 6) {
    if (isVerySmallScreen) {
      return 150;
    } else if (isSmallScreen) {
      return 100;
    } else if (isMediumScreen) {
      return 150;
    } else if (isWideScreen) {
      return 190;
    } else {
      return 250;
    }
  } else {
    if (isVerySmallScreen) {
      return 150;
    } else if (isSmallScreen) {
      return 190;
    } else if (isMediumScreen) {
      return 250;
    } else if (isWideScreen) {
      return 310;
    } else {
      return 250;
    }
  }
};
