import { NetworkEquipmentIds } from '@/components/sections/HouseEntranceSheme/utils';
import { usePnrPlanningStore } from '@/components/sections/PnrPlanning/store';
import { HouseOrderDto } from '@/dto/taskmap/Dto';
import { KEYS } from '@/hooks/useQuery/Keys';
import { useMemo } from 'react';
import { useIsMutating } from 'react-query';

export const useIsFetchingPlannings = () => {
  const isFetchingPlannings = useIsMutating(KEYS.pnrPlannings);
  const isFetchingHouseData = useIsMutating(KEYS.pnrHouseData);
  const isFetching = !!isFetchingPlannings || !!isFetchingHouseData;

  return isFetching;
};

export const useIsFetchingOrders = () => {
  const isFetchingHouseData = useIsMutating(KEYS.pnrHouseData);
  const isFetchingOrders = useIsMutating(KEYS.pnrHouseOrders);

  const isFetching = !!isFetchingOrders || !!isFetchingHouseData;

  return isFetching;
};

const UNSET_TYPE = 'UNSET_TYPE';

export const usePlanedOrdersByType = () => {
  const plannedOrders = usePnrPlanningStore((state) => state.plannedOrders);
  const plannedOrdersByType = useMemo(() => {
    const ordersByTypeMap = [...(plannedOrders?.values() || [])]
      ?.flat()
      ?.reduce((acc: Map<string, HouseOrderDto[]>, current) => {
        const key = current.typeId || UNSET_TYPE;
        if (!acc.has(key)) {
          acc.set(key, []);
        }
        acc.get(key)?.push(current);
        return acc;
      }, new Map());

    return {
      allTypesOrders: ordersByTypeMap,
      routerOrders: ordersByTypeMap.get(NetworkEquipmentIds.router),
      gatewayOrders: ordersByTypeMap.get(NetworkEquipmentIds.gateway),
    };
  }, [plannedOrders]);

  return plannedOrdersByType;
};
