import { Box, Grid } from '@mui/material';
import { MouseEvent } from 'react';
import { PpoVruInputResponse } from '@/dto/taskmap/task/TaskResultDto';
import { PpoOdpuItem } from './PpoOdpuItem';
import { PpoEnterInput } from './PpoEnterInput/PpoEnterInput';
import { useGalleryData } from '@/components/Gallery/store';
import { getPpoEnterInputResImageGroup } from '../panel/utils';

interface PpoEnterItemProps {
  enter: PpoVruInputResponse;
  vruId: string;
  index: number;
  loadAll: any;
}

export const PpoEnterItem = (props: PpoEnterItemProps) => {
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);
  const { enter, vruId, index } = props;
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setImageGroups(getPpoEnterInputResImageGroup(enter));
  };

  return (
    <Box
      onDoubleClick={handleClick}
      sx={{
        borderRadius: '10px',
        border: '1px solid #EFEFEF',
        background:
          'linear-gradient(0deg, #EFEFEF, #EFEFEF), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        width: '100%',
        p: 2,
      }}
    >
      <Grid
        container
        spacing={2}
        item
      >
        <PpoEnterInput {...props} />
        <Grid
          xs={12}
          item
        >
          {enter && (
            <PpoOdpuItem
              enter={enter}
              prevString={`${vruId}.inputRequestList.${index}`}
            />
          )}
          {/*  TODO если в вводе несколько одпу менять тут ^ */}
        </Grid>
      </Grid>
    </Box>
  );
};
