import React from 'react';
import { Grid, IconButton } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

import { PopupOpenButton } from '@/components/popups/PopupOpenButton';
import { GroupButtons } from '@/components/TableMenu';
import TasksBrowseDragOrder from '../TasksBrowseDragOrder/TasksBrowseDragOrder';
import { useDisabledMesManButtons } from './utils';

interface TasksBrowseToolbarProps {
  checkPopupError: boolean;
  setShowCopyPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCopyPopup1: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCopyPopup2: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCopyPopup3: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAssignContractorPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAssignContractPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReturnBackPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReAssignContractPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowExecAndDatePopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCancelPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLoadingVectorDiagrams: React.Dispatch<React.SetStateAction<boolean>>;
  setShowshowCreateColumn: React.Dispatch<React.SetStateAction<boolean>>;
  setShowChiperPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddTagPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const TasksBrowseToolbar = ({
  checkPopupError,
  setShowCopyPopup,
  setShowCopyPopup1,
  setShowCopyPopup2,
  setShowCopyPopup3,
  setShowAssignContractorPopup,
  setShowAssignContractPopup,
  setShowReturnBackPopup,
  setShowReAssignContractPopup,
  setShowExecAndDatePopup,
  setShowCancelPopup,
  setShowLoadingVectorDiagrams,
  setShowshowCreateColumn,
  setShowChiperPopup,
  setShowAddTagPopup,
}: TasksBrowseToolbarProps) => {
  const isDisabled = useDisabledMesManButtons();

  return (
    <div style={{ margin: '8px 10px -8px 10px' }}>
      <Grid
        container
        columnSpacing={2}
        alignItems='center'
      >
        <Grid item>
          <GroupButtons
            groupName={'работа с заявкой'}
            disabled={isDisabled}
          >
            <PopupOpenButton
              openHandler={setShowCopyPopup}
              checkError={!!checkPopupError}
            >
              создать копию
            </PopupOpenButton>
            <PopupOpenButton
              openHandler={setShowCancelPopup}
              checkError={!!checkPopupError}
            >
              отменить заявку
            </PopupOpenButton>
            <PopupOpenButton
              openHandler={setShowAssignContractPopup}
              checkError={!!checkPopupError}
            >
              Назначить контракт
            </PopupOpenButton>
            <PopupOpenButton
              openHandler={setShowReturnBackPopup}
              checkError={!!checkPopupError}
            >
              возврат исполнителю
            </PopupOpenButton>
            <PopupOpenButton
              openHandler={setShowChiperPopup}
              checkError={!!checkPopupError}
            >
              Присвоить шифр
            </PopupOpenButton>
            <PopupOpenButton
              checkError={!!checkPopupError}
              openHandler={setShowCopyPopup1}
            >
              создать жалобу
            </PopupOpenButton>
            <PopupOpenButton openHandler={setShowCopyPopup2}>
              создать заявку на перепрограмирование
            </PopupOpenButton>
            <PopupOpenButton openHandler={setShowCopyPopup3}>
              создать заявку в рамках сбсд
            </PopupOpenButton>
            <PopupOpenButton openHandler={setShowLoadingVectorDiagrams}>
              загрузка векторных диаграмм
            </PopupOpenButton>
            <PopupOpenButton openHandler={setShowshowCreateColumn}>
              создать заявку ФЗ-522 / Тех.Прис.
            </PopupOpenButton>
            <PopupOpenButton
              openHandler={setShowAddTagPopup}
              checkError={!!checkPopupError}
            >
              Добавить тег
            </PopupOpenButton>
          </GroupButtons>
        </Grid>
        <Grid item>
          <GroupButtons
            groupName={'назначить ответственных'}
            disabled={isDisabled}
          >
            <PopupOpenButton
              openHandler={setShowAssignContractorPopup}
              checkError={!!checkPopupError}
            >
              Назначить подрядчика
            </PopupOpenButton>
            <PopupOpenButton
              openHandler={setShowExecAndDatePopup}
              checkError={!!checkPopupError}
            >
              Назначить бригаду и плановую дату
            </PopupOpenButton>
            <PopupOpenButton
              openHandler={setShowReAssignContractPopup}
              checkError={!!checkPopupError}
            >
              Снять подрядчика
            </PopupOpenButton>
          </GroupButtons>
        </Grid>
        <Grid item>
          <GroupButtons groupName={'архив ту'}>
            <PopupOpenButton>сформировать архив точек учета</PopupOpenButton>
            <PopupOpenButton>загрузить архив точек учета</PopupOpenButton>
          </GroupButtons>
        </Grid>
        <Grid
          item
          style={{ flex: '1' }}
        />
        <Grid item>
          <IconButton>
            <CachedIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <TasksBrowseDragOrder />
        </Grid>
        {/* TODO callcenter button */}
        {/*<Grid item>*/}
        {/*  <Link to='/callcenterrequests'>*/}
        {/*    <PhoneCallbackOutlinedIcon*/}
        {/*      sx={{ color: '#000' }}*/}
        {/*      style={{ cursor: 'pointer' }}*/}
        {/*    />*/}
        {/*  </Link>*/}
        {/*</Grid>*/}
      </Grid>
    </div>
  );
};

export default React.memo(TasksBrowseToolbar);
