const REQUIRED_FIELD = 'Обязательное поле';

export const emptyFieldValidation = {
  required: REQUIRED_FIELD,
};

export const onlyRussianLettersAndDigits = {
  required: REQUIRED_FIELD,
  pattern: {
    value: /^[а-яА-ЯёЁ0-9\s]+$/,
    message: 'Поле не заполнено или содержит английские буквы',
  },
};

export const phoneNumberRule = {
  required: REQUIRED_FIELD,
  pattern: {
    value: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/,
    message: 'Поле не заполнено или указан неверный формат номера',
  },
};

export const fioRegex = /^[а-яА-ЯёЁ0-9\s]+$/;
export const contactPhoneRegex = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/;
