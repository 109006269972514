import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ZoningMap } from '../../../components/maps/zoningMap/map';
import { ZoningControlPane } from '../../../components/maps/zoningMap/controlPane';
import { ZoningItemPane } from '../../../components/maps/zoningMap/controlPane/itemPane';
import { useSearch } from '../../../components/maps/zoningMap/hook/SearchHook';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { useMetaTitle } from '@/hooks/useMetaTitle';

export const ZonedMapPage = () => {
  const { getCount } = useSearch();
  useAccess({
    access: [AccessEnum.TasksBrowse],
  });
  useMetaTitle('Карта (назначить)');

  return (
    <Box m={1}>
      <Grid
        container
        my={2}
      >
        <Grid
          item
          xs={12}
        >
          <ZoningControlPane />
        </Grid>
        <Grid
          item
          container
          spacing={2}
          my={1}
          height={'72vh'}
        >
          <Grid
            item
            container
            xs={9}
          >
            <Grid
              item
              xs={12}
            >
              <ZoningMap />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography align='right'>{`На карте показано ${getCount()} объектов`}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <ZoningItemPane />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
