import { useLocation, useNavigate } from 'react-router-dom';
import { syncErrorCatch } from '@/hooks/ActionLogHook';
import { useYunga } from '@/components/features/Yunga/hooks/YungaHook';

export const TASK_UUID_REG_EXP = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
export const PNR_PAGES = 'pnr-entrance';

export const checkUUID = (id: string) => {
  const validId = new RegExp(TASK_UUID_REG_EXP).test(id);
  if (validId) return id;
  else return null;
};

export const useBoltFunctionality = () => {
  const boltSearch = useYunga((state) => state.boltSearch);
  const pathName = useLocation()?.pathname;
  const navigate = useNavigate();

  const boltFunctionality = async () => {
    try {
      const data = await navigator.clipboard.readText();
      const validUUID = checkUUID(data);

      if (!validUUID) {
        syncErrorCatch(`Текст в буфере обмена не подходит под формат UUID`, {
          bufferText: data,
          testRegExp: TASK_UUID_REG_EXP,
        });
        return;
      }

      const routeMap = [
        {
          condition: () => pathName.includes('yunga'),
          action: () => boltSearch(data),
        },
        {
          condition: () => pathName.includes(PNR_PAGES),
          action: () => window.open(`/pnr-entrance/edit/${validUUID}`),
        },
        {
          condition: () => pathName.includes('storehouse'),
          action: () => navigate(`/storehouse/${validUUID}`),
        },
        {
          condition: () => true, // На всех других страницах - открываем эдит таски
          action: () => window.open(`/task/edit/${validUUID}`),
        },
      ];

      for (const route of routeMap) {
        if (route.condition()) {
          route.action();
          break;
        }
      }
    } catch (error) {
      syncErrorCatch('Ошибка чтения буфера обмена', error);
    }
  };

  return { boltFunctionality };
};
