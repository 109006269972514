import { SupplyStampItem, SupplyStamp } from '@/dto/taskmap/Dto';
import { syncErrorCatch } from '@/hooks/ActionLogHook';
import { getNumberFromString } from '../utils';

export const generateStampItems = (
  backendStamps?: (SupplyStampItem | undefined)[],
  stampFullNumbers?: string[]
) => {
  const generatedStampItems = stampFullNumbers?.map((stampNumber) => {
    const existedStamp = backendStamps?.find(
      (backendStamp) => backendStamp?.stampNumber === stampNumber
    );
    return {
      stampNumber: stampNumber,
      id: existedStamp ? existedStamp.id : null,
    };
  });
  return generatedStampItems;
};

export const calcWholeCount = (stampList: SupplyStamp[]) => {
  let result = 0;
  stampList.forEach((obj) => {
    if (
      obj.to &&
      obj.from &&
      !isNaN(getNumberFromString(obj.to)) &&
      !isNaN(getNumberFromString(obj.from))
    ) {
      result += getNumberFromString(obj.to) - getNumberFromString(obj.from) + 1;
    }
  });
  return result;
};

export const isNumberExist = (data: SupplyStamp, newStamps: SupplyStamp[]) => {
  const letter = data.stampLetter;
  let res = false;
  const numbers = data.stampNumbers;
  const otherData = newStamps.filter((it) => it.id !== data.id && it.stampLetter === letter);
  if (numbers) {
    otherData.forEach((it) => {
      if (res) {
        return true;
      } else {
        numbers.forEach((currentNumber) => {
          if (it.stampNumbers && it.stampNumbers.includes(currentNumber)) {
            res = true;
          }
        });
      }
    });
  }
  return res;
};

export const getStampsNumbers = (from: string, to: string) => {
  const fromNumber = getNumberFromString(from);
  const toNumber = getNumberFromString(to);
  const arr = [];
  for (let i = fromNumber; i <= toNumber; i++) {
    const num = String(i);
    const stampNumber = '0'.repeat(Math.max(from.length - 1 - num.length, 0)) + num;
    arr.push(stampNumber);
  }
  return arr;
};

export const getStampsFullNumbers = (data: SupplyStamp) => {
  const from = getNumberFromString(data.from!);
  const to = getNumberFromString(data.to!);
  const arr = [];
  for (let i = from; i <= to; i++) {
    arr.push(
      data.from!.replace(/\d+$/, (str) => {
        const num = String(i);
        return '0'.repeat(Math.max(str.length - num.length, 0)) + num;
      })
    );
  }
  return arr;
};

export interface StampList {
  id: number;
  stampFullNumbers: string[];
  stampNumbers: string[];
  stampLetter: string;
  from?: string | undefined;
  to?: string | undefined;
  count?: number | undefined;
  stampItems?: SupplyStampItem[] | undefined;
}

export const generateNextStampId = (ids?: StampList[]) => {
  let maxId = 0;
  if (ids && ids?.length > 0) {
    maxId = Math.max(...ids.map((o) => o.id));
  }
  return maxId + 1;
};

export const catchLsStampInitError = (error: unknown, errorStamps: SupplyStamp[]) => {
  syncErrorCatch('Ошибка инициализации несохраненных пломб', {
    message: error instanceof Error ? error.message : '',
    errorStamps: errorStamps,
  });
};
