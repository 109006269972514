import { Paper } from '@mui/material';
import React from 'react';
import { CallCenterRequestsComponent } from '@/components/callcenter/CallCenterRequestsComponent';
import '../../styles/callcenterstyle.css';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const CallCenterRequestsPage = () => {
  useMetaTitle(`Колл-центр запросы`);

  return (
    <Paper className='main-container'>
      <CallCenterRequestsComponent />
    </Paper>
  );
};
export default CallCenterRequestsPage;
