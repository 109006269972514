import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { DesktopDatePicker, TextField } from '@/components/fixUI';
import React, { FC, useEffect, useState } from 'react';
import { TechInspectionResponse } from '@/dto/taskmap/task/TaskResultDto';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { getCatalogTKOStatusByContractId } from '@/services/TaskService';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useParams } from 'react-router-dom';
import { GetCurrentUser } from '@/services/YodaRestService';
import { responseToMap } from '@/utils/heplers';
import { ru } from 'date-fns/locale';

interface IForm {
  data?: TechInspectionResponse;
  contractId?: string;
  isAddedNewTKO?: boolean;
}

export const Form: FC<IForm> = ({ data, contractId, isAddedNewTKO }) => {
  const { register, setValue } = useFormContext();
  const { taskId } = useParams();

  const keyString = 'TKOForm';
  if (data?.id) {
    setValue(`${keyString}.id`, data.id);
  }
  setValue(`${keyString}.taskId`, taskId);

  const [statusCatalog, setStatusCatalog] = useState<Map<string, string>>(new Map());
  const [currentUserName, setCurrentUserName] = useState<string | undefined>(data?.executorName);
  useEffect(() => {
    contractId &&
      getCatalogTKOStatusByContractId(contractId)
        .then(({ data }) => setStatusCatalog(responseToMap(data)))
        .catch((res) => console.log(res));
    (data == undefined || data.executorName == undefined) &&
      GetCurrentUser()
        .then((res) => {
          res.data.fullName && setCurrentUserName(res.data.fullName);
          res.data.fullName && setValue(`${keyString}.executorName`, res.data.fullName);
        })
        .catch((err) => console.log(err));
  }, []);

  const [eventOn, setEventOn] = useState<Date | null>(
    isAddedNewTKO ? new Date() : data?.eventOn ?? null
  );

  const optionReason = new Map([
    ['periodIsNotExpired', 'ПУ не вышли за МПИ'],
    ['existedAskue', 'Существующая АСКУЭ'],
    ['null', 'Не указано'],
  ]);

  useEffect(() => {
    if (!eventOn) {
      setEventOn(isAddedNewTKO ? new Date() : null);
      setValue(`${keyString}.tkoDate`, isAddedNewTKO ? new Date() : null);
    } else {
      setEventOn(eventOn);
      setValue(`${keyString}.tkoDate`, moment(eventOn).valueOf());
    }
  }, []);
  const onHandleEventOn = (newValue: Date | null) => {
    console.log(newValue);
    if (newValue) {
      setEventOn(newValue);
      setValue(`${keyString}.tkoDate`, moment(newValue).valueOf());
    }
  };
  const [dateOneCall, setDateOneCall] = useState<Date | null>(data?.dateOneCall ?? null);
  const onHandleDateOneCall = (newValue: Date | null) => {
    if (newValue) {
      setDateOneCall(newValue);
      setValue(`${keyString}.dateOneCall`, moment(newValue).valueOf());
    }
  };
  const [dateTwoCall, setDateTwoCall] = useState<Date | null>(data?.dateTwoCall ?? null);
  const onHandleDateTwoCall = (newValue: Date | null) => {
    if (newValue) {
      setDateTwoCall(newValue);
      setValue(`${keyString}.dateTwoCall`, moment(newValue).valueOf());
    }
  };
  const [dateThreeCall, setDateThreeCall] = useState<Date | null>(data?.dateThreeCall ?? null);
  const onHandleDateThreeCall = (newValue: Date | null) => {
    if (newValue) {
      setDateThreeCall(newValue);
      setValue(`${keyString}.dateThreeCall`, moment(newValue).valueOf());
    }
  };
  const isInContractCatalog = contractId && statusCatalog.get(data?.tkoStatusResponse?.id || '');

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={6}
        >
          {!isAddedNewTKO && !isInContractCatalog ? (
            <TextField
              id='tko-status'
              label='Статус ТКО'
              variant='standard'
              sx={{ width: '100%' }}
              disabled={!isInContractCatalog}
              defaultValue={data?.tkoStatusResponse?.description}
            />
          ) : (
            <AutocompleteValue
              title='Статус ТКО'
              defaultValue={data?.tkoStatusResponse?.id}
              fieldName={`${keyString}.statusId`}
              values={statusCatalog}
              required={true}
            />
          )}
          <TextField
            id='tf-number-tt'
            label='Исполнитель ТКО'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.executorName`, {
              value: data?.executorName || currentUserName || 'Тестовый',
            })}
          />
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ru}
          >
            <DesktopDatePicker
              label='Дата проведения работ'
              inputFormat='dd.MM.yyyy'
              value={eventOn}
              onChange={onHandleEventOn}
              renderInput={(params) => (
                <TextField
                  sx={{ width: '100%' }}
                  {...params}
                  variant='standard'
                />
              )}
            />
            <TextField
              id='tf-number-tt'
              label='Тип счетчика по факту'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${keyString}.exMeterType`, {
                value: data?.puType,
              })}
            />
            <TextField
              id='tf-number-tt'
              label='Общее состояние счетчика'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${keyString}.meterState`, {
                value: data?.meterState,
              })}
            />
            <DesktopDatePicker
              label='Дата первого звонка'
              inputFormat='dd.MM.yyyy'
              value={dateOneCall}
              onChange={onHandleDateOneCall}
              renderInput={(params) => (
                <TextField
                  sx={{ width: '100%' }}
                  {...params}
                  variant='standard'
                />
              )}
            />
            <DesktopDatePicker
              label='Дата второго звонка'
              inputFormat='dd.MM.yyyy'
              value={dateTwoCall}
              onChange={onHandleDateTwoCall}
              renderInput={(params) => (
                <TextField
                  sx={{ width: '100%' }}
                  {...params}
                  variant='standard'
                />
              )}
            />
            <DesktopDatePicker
              label='Дата третьего звонка'
              inputFormat='dd.MM.yyyy'
              value={dateThreeCall}
              onChange={onHandleDateThreeCall}
              renderInput={(params) => (
                <TextField
                  sx={{ width: '100%' }}
                  {...params}
                  variant='standard'
                />
              )}
            />
            <AutocompleteValue
              title='Причина недопуска УК'
              defaultValue={
                data?.managementCompanyRefuseAdmission
                  ? data?.managementCompanyRefuseAdmission
                  : 'null'
              }
              fieldName={`${keyString}.managementCompanyRefuseAdmission`}
              values={optionReason}
            />
            <TextField
              id='tf-number-tt'
              label='Наименование УК'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${keyString}.managementCompanyName`, {
                value: data?.managementCompanyName,
              })}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            id='tf-number-tt'
            label='№ счетчика'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.meterNum`, {
              value: data?.meterNum,
            })}
          />
          <TextField
            id='tf-number-tt'
            label='№ СТДО основной заявки'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.stdoBasicTask`, {
              value: data?.stdoBasicTask,
            })}
          />

          <TextField
            id='tf-number-tt'
            label='№ пломбы'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.stampNum`, {
              value: data?.stampNum,
            })}
          />

          <Grid
            container
            xs={12}
          >
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-number-tt'
                label='Т1'
                variant='standard'
                sx={{ width: '90%' }}
                {...register(`${keyString}.t1`, {
                  value: data?.t1,
                })}
                type='number'
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-number-tt'
                label='Т2'
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${keyString}.t2`, {
                  value: data?.t2,
                })}
                type='number'
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-number-tt'
                label='Т3'
                variant='standard'
                sx={{ width: '90%' }}
                {...register(`${keyString}.t3`, {
                  value: data?.t3,
                })}
                type='number'
              />
            </Grid>
          </Grid>

          <TextField
            sx={{ mt: 2 }}
            id='outlined-basic'
            label='Коментарий'
            variant='outlined'
            multiline
            fullWidth
            rows={4}
            {...register(`${keyString}.comment`, {
              value: data?.comment,
            })}
          />
        </Grid>
      </Grid>
    </>
  );
};
