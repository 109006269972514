import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { FC } from 'react';
import { IRegistryEditItem } from './EditTable/store';
import { Order } from './constants';
import * as React from 'react';
import { IRegistryResultItem } from './ResultTable/store';
import { IRegistryMonitoringEditItem } from './MonitoringEditTable/store';

export interface HeadCell {
  disablePadding: boolean;
  id: keyof IRegistryEditItem | keyof IRegistryResultItem | keyof IRegistryMonitoringEditItem;
  label: string;
}

export type OnRequestSort = {
  (
    event: React.MouseEvent<unknown>,
    property:
      | keyof IRegistryEditItem
      | keyof IRegistryResultItem
      | keyof IRegistryMonitoringEditItem
  ): void;
};

export interface ITableCells {
  onRequestSort: OnRequestSort;
  order: Order;
  orderBy: string;
}

interface ITableCellsMain extends ITableCells {
  headCells: HeadCell[];
}

export const TableCells: FC<ITableCellsMain> = ({ orderBy, order, onRequestSort, headCells }) => {
  const createSortHandler =
    (
      property:
        | keyof IRegistryEditItem
        | keyof IRegistryResultItem
        | keyof IRegistryMonitoringEditItem
    ) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          padding={'normal'}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : 'asc'}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box
                component='span'
                sx={visuallyHidden}
              >
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </>
  );
};
