import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';

interface ConfirmationDialogProps {
  title: string;
  tooltip: string;
  handleOk(value?: string): void;
  handleCancel(): void;
  open: boolean;
  mapValue: Map<string, string>;
}

export const LookUpDialog = (props: ConfirmationDialogProps) => {
  const { title, handleOk, handleCancel, open, mapValue, tooltip } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [selected, setSelected] = useState(undefined);

  const defaultProps = {
    options: Array.from(mapValue?.keys()).sort((a, b) => a.localeCompare(b)),
    getOptionLabel: (option: any) => mapValue.get(option) || '',
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenDialog(false);
  };

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleAction = (isOk: boolean) => {
    if (isOk) {
      handleOk(selected);
    } else {
      handleCancel();
    }
    handleClose();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby='look-up-dialog-title'
      aria-describedby='look-up-dialog-description'
    >
      {title && title.length > 0 && <DialogTitle id='look-up-dialog-title'>{title}</DialogTitle>}
      <DialogContent>
        <Autocomplete
          {...defaultProps}
          id={`look-up-autocomplete-value`}
          onChange={(event: any, newValue: any) => {
            setSelected(newValue);
          }}
          isOptionEqualToValue={(option, value) => option === value}
          renderInput={(params) => (
            <TextField
              {...params}
              label={tooltip}
              variant='standard'
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleAction(false)}>Отмена</Button>
        <Button
          onClick={() => handleAction(true)}
          autoFocus
        >
          Оk
        </Button>
      </DialogActions>
    </Dialog>
  );
};
