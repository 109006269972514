import React from 'react';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { Autocomplete, TextField, Grid } from '@mui/material';
import { Empty, Table } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
  FilterStatisticReportByContractPerDay,
  StatisticReportByContractPerDayDto,
} from '@/dto/taskmap/Dto';
import { DateRange } from '@/components/inputs/DateRange';
import { useAllContractQuery } from '@/hooks/useQuery/useAllContractQuery';
import {
  STATISTIC_REPORT_BY_CONTRACT_PER_DAY_COLUMNS,
  STATISTIC_REPORT_BY_CONTRACT_PER_DAY_TASK_TYPES,
  AggregatedData,
  aggregatedDataFunc,
} from './utils';
import {
  StatisticReportByContractPerDayFiltersWrapper,
  StatisticReportByContractPerDayWrapper,
} from './StatisticReportByContractPerDay.styled';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';

interface StatisticReportByContractPerDayProps {
  header: string;
  load: boolean;
  data: StatisticReportByContractPerDayDto[];
  updateFunction: (filter: FilterStatisticReportByContractPerDay) => void;
}

export const StatisticReportByContractPerDay: React.FC<StatisticReportByContractPerDayProps> = ({
  header,
  load,
  data,
  updateFunction,
}) => {
  const methods = useForm();

  const { handleSubmit } = methods;

  const [dataInRange, setDateInRange] = React.useState<number[] | Date[]>([
    new Date(Date.now() - 86400000 * 14),
    new Date(),
  ]);
  const contractListResponse = useAllContractQuery();
  const [contract, setContract] = React.useState<string>('');
  const [locaclData, setlocaclData] = React.useState<AggregatedData[]>([]);

  const isInRange = dataInRange.length !== 2;
  const disableSearch = dataInRange.length < 2 || !contract;

  const onSubmitSearch = () => {
    if (disableSearch) return;
    const filter: FilterStatisticReportByContractPerDay = {
      taskTypes: STATISTIC_REPORT_BY_CONTRACT_PER_DAY_TASK_TYPES,
      dateFrom: new Date(dataInRange[0]).getTime(),
      dateTo: new Date(dataInRange[1]).getTime(),
      contractId: contract,
    };
    updateFunction(filter);
  };

  React.useEffect(() => {
    const transformedData = aggregatedDataFunc(data);
    if (transformedData) setlocaclData(transformedData);
  }, [data]);

  const onResetClick = () => {
    setlocaclData([]);
    setContract('');
  };

  return (
    <StatisticReportByContractPerDayWrapper elevation={6}>
      <p className={'headerPageStatistics'}>{header}</p>
      {load ? (
        <LoadSpinner />
      ) : (
        <Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitSearch)}>
              <StatisticReportByContractPerDayFiltersWrapper>
                <Autocomplete
                  style={{ width: '250px' }}
                  options={contractListResponse}
                  value={{
                    label: contractListResponse.find((el) => el.value === contract)?.label ?? '',
                    value: contractListResponse.find((el) => el.value === contract)?.value ?? '',
                  }}
                  onChange={(event: any, newValue: any) => {
                    setContract(newValue?.value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      name='contractorId'
                      {...params}
                      label={'Контракт'}
                      variant='standard'
                    />
                  )}
                />

                <DateRange
                  label={'выбрать диапазон'}
                  isInRange={isInRange}
                  onChange={setDateInRange}
                  value={dataInRange}
                  argValue={8}
                  showSelect={false}
                />

                <FilterButtons
                  disable={disableSearch}
                  onSearchClick={() => handleSubmit(onSubmitSearch)}
                  onCloseClick={onResetClick}
                />
              </StatisticReportByContractPerDayFiltersWrapper>
            </form>
          </FormProvider>
          <div style={{ height: 'auto', width: '89%', margin: 'auto', marginTop: 15 }}>
            <Table
              bordered
              pagination={{
                pageSize: 100,
                hideOnSinglePage: true,
              }}
              scroll={{ y: 300 }}
              size='small'
              expandable={{
                expandRowByClick: true,
              }}
              rowKey={(record) => record.key}
              style={{ marginBottom: 20 }}
              columns={STATISTIC_REPORT_BY_CONTRACT_PER_DAY_COLUMNS}
              dataSource={locaclData}
              locale={{
                emptyText: (
                  <Empty
                    style={{ minHeight: 250 }}
                    description={'Нет данных для отображения'}
                    image={null}
                  />
                ),
              }}
            />
          </div>
        </Grid>
      )}
    </StatisticReportByContractPerDayWrapper>
  );
};
