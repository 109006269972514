import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, TextField } from '@mui/material';
import CallTypeModalTypeEight from './CallTypeModalTypeEight';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};


interface CallTypeModalTypeOneProps {
  save: (taskId: string, requestId: string, resultJobLog: string, planDate?: string, executor?: string, comment?: string) => any;
  saveTko: (taskId: string, requestId: string, resultJobLog: string, tkoId: string, comment: string) => any;
  tkoStatuses: SelectFilterOptType[];
  executors: SelectFilterOptType[];
  taskStatus: string;
  taskId: string;
  requestId: string;
}

export default function CallTypeModalTypeOne(props: CallTypeModalTypeOneProps) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [planDate, setPlanDate] = React.useState("");
  const [selectedExecutor, setSelectedExecutor] = React.useState<SelectFilterOptType | null>(null);
  const [comment, setComment] = React.useState("");

  const clearFields = () => {
    setPlanDate("")
    setSelectedExecutor(null)
    setComment("")
  }

  const save = () => {
    if (planDate && selectedExecutor) {
      props.save(props.taskId, props.requestId, "Принял плановую дату: " + planDate + " и назначел ответственного: " + selectedExecutor.label + "", planDate, selectedExecutor?.value, comment)
      clearFields();
      setOpen(false)
    }

  }

  return (
    <div>
      <Button onClick={handleOpen}>Принять</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Выберите исполнителя и плановую дату работ
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginTop: 35, marginBottom: 15 }}>
            <TextField
              sx={{ minWidth: 200 }}
              id="plan-date-field"
              type="date"
              label="Выбрать планируемую дату работ"
              InputLabelProps={{ shrink: true }}
              value={planDate}
              error={!planDate}
              variant="outlined"
              onChange={(e) => setPlanDate(e.target.value)} />
            <div style={{ marginLeft: 25 }}>
              <Autocomplete
                disablePortal
                sx={{ minWidth: 300 }}
                id="executor-select"
                value={selectedExecutor}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={props.executors}
                onChange={(e, val) => {
                  setSelectedExecutor(val)
                }}
                renderInput={(params) => <TextField {...params} label="Исполнитель" error={!selectedExecutor} />}
              />
            </div>

          </div>
          <TextField
            fullWidth
            multiline
            rows={4}
            id="comment-field"
            label="Комментарий"
            value={comment}
            variant="outlined"
            onChange={(e) => setComment(e.target.value)} />
          <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'end', marginTop: 10 }}>
            <CallTypeModalTypeEight taskId={props.taskId} requestId={props.requestId} taskStatus={props.taskStatus} saveTko={props.saveTko} tkoStatuses={props.tkoStatuses} />
            <Button onClick={(e) => {
              save()
            }}>Сохранить</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
