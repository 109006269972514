import { useState } from 'react';
import { TextField } from '@mui/material';
import { Toast } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAuth from '@/hooks/useAuth';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { GetJWT, ydoaAouth } from '@/services/YodaRestService';
import Alert from '@mui/material/Alert';
import { YodaTokenContextType } from '@/app/auth/types';
import { saveYodaToken } from '@/app/auth/AuthProvider';
import {
  LoginPageFieldsWrapper,
  LoginPageButton,
  LoginPageTitle,
  LoginPageErrorText,
  LoginPageTextField,
  LoginPageText,
} from './LoginPage.styled';
import { LOGIN_VALIDATION, PASSWORD_VALIDATION } from './utils';

type UserCredit = { login: string; pwd: string };

export const LoginPage = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const hideAlert = () => setAlertMessage('');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserCredit>();
  const auth: YodaTokenContextType = useAuth() as YodaTokenContextType;
  const navigate = useNavigate();
  const location: any = useLocation();
  // смотрим, есть ли сохранённый путь в localStorage
  const from = localStorage.getItem('redirectPath') || location.state?.from?.pathname || '/';

  watch(() => hideAlert());
  const onSubmit = (user: UserCredit) => {
    ydoaAouth(user.login, user.pwd)
      .then((respose) => {
        saveYodaToken(respose.data);
        // очищаем сохранённый путь после логина
        localStorage.removeItem('redirectPath');
        navigate(from, { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        if (error.response?.status === 401)
          setAlertMessage('Технческая ошибка обратитесь в тех.поддержку код ответа 401');
        else if (error.response?.status === 400) setAlertMessage('Неверный логин или пароль');
        else setAlertMessage('Сервер не доступен попробуйте через 5 минут ');
      });
  };

  return (
    <>
      {auth?.getYodaToken() ? (
        <Navigate
          to={from ?? '/tasks/browse'}
          state={{ from: location }}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <LoginPageFieldsWrapper>
            <LoginPageTitle>YODA</LoginPageTitle>
            <LoginPageText>Авторизация</LoginPageText>
            <LoginPageTextField
              {...register('login', LOGIN_VALIDATION)}
              id='login'
              label='Логин'
              error={!!errors.login?.message}
              fullWidth
            />
            {/*<LoginPageErrorText>{errors.login?.message}</LoginPageErrorText>*/}

            <LoginPageTextField
              {...register('pwd', PASSWORD_VALIDATION)}
              id='passwerd'
              label='Пароль'
              type='password'
              error={!!errors.pwd?.message}
              fullWidth
              // helperText={errors.pwd?.message}
            />
            {/*<LoginPageErrorText>{errors.pwd?.message}</LoginPageErrorText>*/}

            <Toast show={alertMessage.length > 1}>
              <Alert
                variant='outlined'
                severity='error'
                onClick={hideAlert}
              >
                {alertMessage}
              </Alert>
            </Toast>
            <LoginPageButton
              type='submit'
              variant='contained'
              fullWidth
            >
              ВОЙТИ
            </LoginPageButton>
          </LoginPageFieldsWrapper>
        </form>
      )}
    </>
  );
};
