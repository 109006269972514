import { StatisticCallingMapDto } from '@/dto/taskmap/Dto';
import { color } from '@/styles/mixins';

export function getColorByStatus(status: string) {
  switch (status) {
    case 'AGREEMENT':
      return '#228B22';
    case 'MISSED_CALL':
      return '#D2691E';
    case 'ADD_NUMBER':
      return '#004953';
    case 'REFUSAL':
      return '#140F0B';
    case null:
      return 'red';
    case 'CALLBACK':
      return '#FF00FF';
    case 'WRONG_ADDRESS':
      return '#B5B8B1';
    case 'OBSOLETE_AGREEMENT':
      return '#808000';
    default:
      return 'navy'; // Цвет по умолчанию
  }
}

export const callStatus: Record<string, string> = {
  REFUSAL: 'REFUSAL',
  AGREEMENT: 'AGREEMENT',
  WRONG_ADDRESS: 'WRONG_ADDRESS',
  ADD_NUMBER: 'ADD_NUMBER',
  CALLBACK: 'CALLBACK',
  MISSED_CALL: 'MISSED_CALL',
  OBSOLETE_AGREEMENT: 'OBSOLETE_AGREEMENT',
};

export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
export const weekdaysRus = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const options: any = {
  plugins: {
    legend: {
      position: 'top',
      align: 'center',
    },
  },

  scales: {
    x: {
      title: {
        display: true,
        text: '',
      },
    },
    y: {
      title: {
        display: true,
        text: 'Количество',
      },
      beginAtZero: true,
    },
  },
};

export const chartOptionsPie: any = {
  plugins: {
    title: {
      display: false,
      text: 'Распределение статусов',
    },
    legend: {
      display: false,
      position: 'left',
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem: any) => {
          return `${tooltipItem.label}: ${tooltipItem.formattedValue}%`;
        },
      },
    },
  },
};

export const statusColors: Record<string, string> = {
  AGREEMENT: '#228B22',
  MISSED_CALL: '#D2691E',
  ADD_NUMBER: '#004953',
  REFUSAL: '#140F0B',
  CALLBACK: '#FF00FF',
  WRONG_ADDRESS: '#B5B8B1',
  OBSOLETE_AGREEMENT: '#808000',
  // Добавьте здесь другие статусы и их соответствующие цвета
};

export const lookUp: Record<string, string> = {
  REFUSAL: 'ОТКАЗ',
  AGREEMENT: 'СОГЛАСИЕ',
  WRONG_ADDRESS: 'НЕВЕРНЫЙ АДРЕСС',
  ADD_NUMBER: 'ДОБАВЛЕН НОМЕР',
  CALLBACK: 'ПЕРЕЗВОНИТЬ',
  MISSED_CALL: 'НЕДОЗВОН',
  OBSOLETE_AGREEMENT: 'СОГЛАСИЕ ОТМЕНЕНО',
};

export const processData = (data: StatisticCallingMapDto[], total: number) => {
  const statusCounts: Record<string, number> = {};
  data.forEach((entry) => {
    const { status, count } = entry;
    if (status !== null) {
      if (status in statusCounts) {
        statusCounts[status] += count;
      } else {
        statusCounts[status] = count;
      }
    }
  });

  // Преобразуем количество в процентное соотношение от общего количества
  const percentageData: Record<string, string> = {};
  Object.keys(statusCounts).forEach((status) => {
    const percentage = (statusCounts[status] / total) * 100;
    percentageData[status] = percentage.toFixed(0); // Округляем
  });

  // Формируем данные для Doughnut чарта
  const chartData = {
    labels: Object.keys(percentageData).map((label) => lookUp[label] || 'НЕИЗВЕСТНО'),
    datasets: [
      {
        data: Object.values(percentageData),
        backgroundColor: Object.keys(percentageData).map(
          (status) => statusColors[status] || 'black'
        ),

        datalabels: {
          labels: {
            l: {
              anchor: 'center',
              align: 'center',
              color: 'white',
              font: {
                size: 12,
              },
            },
          },
        },
      },
    ],
  };

  return chartData;
};

export const textCenterPlugin = (total: number) => ({
  id: 'textCenter',
  beforeDatasetsDraw(chart: any) {
    const { ctx } = chart;
    ctx.save();
    ctx.font = 'bolder 16px sans-serif';
    ctx.fillStyle = color('typographyColorMap2');
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(
      `Всего: ${total}`,
      chart.getDatasetMeta(0).data[0]?.x,
      chart.getDatasetMeta(0).data[0]?.y
    );
  },
});

export const defaultKeys = [
  'CALLBACK',
  'AGREEMENT',
  'REFUSAL',
  'WRONG_ADDRESS',
  'MISSED_CALL',
  'ADD_NUMBER',
];
