import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CCTaskSearch from './CCTaskSearch';
import CCTaskItem from './CCTaskItem';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface CallCenterCompProps {
  taskItemId?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      style={{ width: '100%' }}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function CallCenterComponent(props: CallCenterCompProps) {
  const [value, setValue] = React.useState(0);

  let { taskItemId } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const clearRow = () => {
    taskItemId = undefined;
  };

  const taskComp = taskItemId ? (
    <CCTaskItem
      taskItemId={taskItemId!}
      clearRow={clearRow}
    ></CCTaskItem>
  ) : (
    <CCTaskSearch />
  );

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab
          style={{ width: '220px' }}
          label='Поиск заявки'
          {...a11yProps(0)}
        />
      </Tabs>
      <TabPanel
        value={value}
        index={0}
      >
        {taskComp}
      </TabPanel>
    </Box>
  );
}
