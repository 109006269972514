export interface ISettings {
  rowFields: IDndList[]; // может быть расширен до N полей
  columnFields: IDndList[]; // может быть расширен до N полей
  valueFields: string[];
}
export interface IDndList {
  id: string;
  label: string;
  value: string;
}

export interface IDndParams {
  dndList: IDndList[];
  listName: string;
}
export interface IRPData {
  [key: string]: string | number;
}

export interface ValueField {
  [fieldName: string]: number;
}

export interface AggregatedData {
  [rowKey: string]: {
    [columnKey: string]: ValueField;
  };
}

export interface Result {
  result: AggregatedData;
  columnHeaders: string[];
  columns: any;
  rows: any;
  parents: string[];
}

export type ValueType = {
  value: string;
  label: string;
};

export interface IQureFilter {
  name: string;
  arg: string | number;
  type?: string;
  values: ValueType[];
}
export interface IQureInput {
  [key: string]: {
    arg: string;
    values: string[];
  };
}

export enum SettingsEnum {
  Row = 'Строки',
  Column = 'Столбцы',
  Value = 'Значение',
}
