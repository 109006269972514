import React, { FC } from 'react';
import { SingleLine } from '@/components/inputs/SingleLineNew';
import SearchIcon from '@mui/icons-material/Search';
import { useTasksBrowseFilter } from '../store';
import { defaultList } from '@/components/inputs/ArgumentSelectNew';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import shallow from 'zustand/shallow';
import {
  SearchIconWrapper,
  HelpFilterRow,
  HelpFilterOpenButton,
  HelpFilterWrapper,
  HelpFilterOpenButtonDiv,
} from './HelpFilter.styled';
import ToggleArrow from '@/components/ui/ToggleArrow/ToggleArrow';

const argList = [FilterArgEnum.IN_LIST, ...defaultList];

const FILTERS_LABELS = {
  accountingNum: 'Лицевой счет',
  stampNum: 'Номер пломбы',
  taskNum: '№ Заявки',
  taskId: 'Id Заявки',
  meterChangeId: 'Id Замены',
  networkElementNum: 'МАС/Номер/IMEI ОС',
  puNumOrMac: 'Номер/МАС ПУ',
  existingPuNum: '№ демонтированого пу',
  vruInputId: 'ID Ввода',
};

interface IHelpFilter {
  onSubmit: () => void;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: (event: HTMLButtonElement | null) => void;
}

export const HelpFilter: FC<IHelpFilter> = ({ onSubmit, anchorEl, setAnchorEl }) => {
  const {
    meterChangeId,
    stampNum,
    taskNum,
    taskId,
    vruInputId,
    networkElementNum,
    puNumOrMac,
    existingPuNum,
    accountingNum,
    setSingleValue,
    setSingleValueArg,
    setDefaultHelpValue,
  } = useTasksBrowseFilter(
    (state) => ({
      meterChangeId: state.meterChangeId,
      stampNum: state.stampNum,
      taskNum: state.taskNum,
      taskId: state.taskId,
      vruInputId: state.vruInputId,
      networkElementNum: state.networkElementNum,
      puNumOrMac: state.puNumOrMac,
      existingPuNum: state.existingPuNum,
      accountingNum: state.accountingNum,
      setSingleValue: state.setSingleValue,
      setSingleValueArg: state.setSingleValueArg,
      setDefaultHelpValue: state.setDefaultHelpValue,
    }),
    shallow
  );

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    if (open) {
      setDefaultHelpValue();
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <HelpFilterOpenButton onClick={handleClick}>
        <ToggleArrow open={open} />
      </HelpFilterOpenButton>
      {open && (
        <HelpFilterWrapper>
          <HelpFilterOpenButtonDiv onClick={handleClick}>
            <ToggleArrow open={open} />
          </HelpFilterOpenButtonDiv>
          <HelpFilterRow>
            {/*<SingleLine*/}
            {/*  isTrim*/}
            {/*  argList={argList}*/}
            {/*  onChange={setSingleValue('stampNum')}*/}
            {/*  label={FILTERS_LABELS.stampNum}*/}
            {/*  onArgChange={setSingleValueArg('stampNum')}*/}
            {/*  {...stampNum}*/}
            {/*  isCleared*/}
            {/*/>*/}

            <SingleLine
              isTrim
              argList={argList}
              onChange={setSingleValue('taskNum')}
              label={FILTERS_LABELS.taskNum}
              onArgChange={setSingleValueArg('taskNum')}
              {...taskNum}
              isCleared
            />

            <SingleLine
              isTrim
              argList={argList}
              onChange={setSingleValue('taskId')}
              label={FILTERS_LABELS.taskId}
              onArgChange={setSingleValueArg('taskId')}
              {...taskId}
              isCleared
            />

            {/*<SingleLine*/}
            {/*  isTrim*/}
            {/*  argList={argList}*/}
            {/*  onChange={setSingleValue('accountingNum')}*/}
            {/*  label={FILTERS_LABELS.accountingNum}*/}
            {/*  onArgChange={setSingleValueArg('accountingNum')}*/}
            {/*  {...accountingNum}*/}
            {/*  isCleared*/}
            {/*/>*/}

            <SingleLine
              isTrim
              argList={argList}
              onChange={setSingleValue('meterChangeId')}
              label={FILTERS_LABELS.meterChangeId}
              onArgChange={setSingleValueArg('meterChangeId')}
              {...meterChangeId}
              isCleared
            />

            <SingleLine
              isTrim
              argList={argList}
              onChange={setSingleValue('networkElementNum')}
              label={FILTERS_LABELS.networkElementNum}
              onArgChange={setSingleValueArg('networkElementNum')}
              {...networkElementNum}
              isCleared
            />

            <SingleLine
              isTrim
              onChange={setSingleValue('puNumOrMac')}
              label={FILTERS_LABELS.puNumOrMac}
              onArgChange={setSingleValueArg('puNumOrMac')}
              {...puNumOrMac}
              isCleared
            />

            {/*<SingleLine*/}
            {/*  isTrim*/}
            {/*  onChange={setSingleValue('existingPuNum')}*/}
            {/*  label={FILTERS_LABELS.existingPuNum}*/}
            {/*  onArgChange={setSingleValueArg('existingPuNum')}*/}
            {/*  {...existingPuNum}*/}
            {/*  isCleared*/}
            {/*/>*/}
            <SingleLine
              isTrim
              argList={argList}
              onChange={setSingleValue('vruInputId')}
              label={FILTERS_LABELS.vruInputId}
              onArgChange={setSingleValueArg('vruInputId')}
              {...vruInputId}
              isCleared
            />
            <SearchIconWrapper onClick={() => onSubmit()}>
              <SearchIcon />
            </SearchIconWrapper>
          </HelpFilterRow>
        </HelpFilterWrapper>
      )}
    </>
  );
};
