import { List, ListItem, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import { useActionLog } from '@/hooks/ActionLogHook';
import { ActionLogItem } from '../ActionLogItem/ActionLogItem';
import FrontendVersion from '@/components/widgets/FrontendVersion/FrontendVersion';

export interface ActionaLogDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ActionaLogDialog = (props: ActionaLogDialogProps) => {
  const { onClose, open } = props;
  const { actionLogList } = useActionLog();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
    >
      <DialogTitle sx={{ fontSize: 28, textAlign: 'center' }}>Журнал событий</DialogTitle>

      <FrontendVersion withSessionId />

      <Paper style={{ maxHeight: 500, width: '100%', overflow: 'auto' }}>
        <List>
          {[...actionLogList]
            .sort((o1, o2) => (moment(o1.eventAt).isAfter(o2.eventAt) ? -1 : 1))
            .map((t) => {
              return (
                <ListItem key={t.id}>
                  <ActionLogItem
                    key={t.id}
                    item={t}
                  />
                </ListItem>
              );
            })}
        </List>
      </Paper>
    </Dialog>
  );
};
