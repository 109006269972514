import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { SupplyPuDataTypography } from './PURemoveScanner.styled';

export const STATIC_COLUMNS: GridColDef[] = [
  {
    field: 'rowNum',
    headerName: '№',
    sortable: false,
    width: 100,
    renderCell: (params: GridRenderCellParams<string>) => (
      <SupplyPuDataTypography>{params.value}</SupplyPuDataTypography>
    ),
  },
  {
    field: 'meterTypeName',
    headerName: 'Тип прибора',
    sortable: false,
    width: 130,
    renderCell: (params: GridRenderCellParams<string>) => (
      <SupplyPuDataTypography>{params.value}</SupplyPuDataTypography>
    ),
  },
  {
    field: 'mac',
    headerName: 'Номер/MAC',
    sortable: false,
    width: 230,
    editable: true,
    renderCell: (params: GridRenderCellParams<string>) => {
      let resValue = '';
      if (params.value && params.value.length > 0) {
        resValue = params.value;
      } else if (params.row.number && params.row.number.length > 0) {
        resValue = params.row.number;
      }
      return <SupplyPuDataTypography>{resValue}</SupplyPuDataTypography>;
    },
  },
];
