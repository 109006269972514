import { Order } from '../constants';
import { findAllRegistryByOdpuId } from '@/services/RegistryService';
import moment from 'moment';
import { createStore } from '../../../../utils/createStore';

export interface IRegistryResultItem {
  id: string;
  type: string;
  adress: string;
  data: string;
  executor: string;
  responsible: string;
  managementCompany?: string;
  taskId: string;
}

interface IRegistryStore {
  registryResultTable: IRegistryResultItem[];
  order: Order;
  orderBy: keyof IRegistryResultItem;
  selected: string[];
}

interface IRegistryStoreFunc {
  setRegistryResultTable: (registryResultTable: IRegistryResultItem[]) => void;
  setOrder: (order: Order) => void;
  setOrderBy: (orderBy: keyof IRegistryResultItem) => void;
  setSelected: (selected: string[]) => void;
  updateTable: (id: string) => void;
}

export const useRegistryResultStore = createStore<IRegistryStore & IRegistryStoreFunc>(
  (set) => ({
    registryResultTable: [],
    order: 'asc',
    orderBy: 'type',
    selected: [],

    setRegistryResultTable: (registryResultTable) => set(() => ({ registryResultTable })),
    setOrder: (order) => set(() => ({ order })),
    setOrderBy: (orderBy) => set(() => ({ orderBy })),
    setSelected: (selected) => set(() => ({ selected })),

    updateTable: async (id) => {
      const result = await findAllRegistryByOdpuId(id);
      set(() => ({
        registryResultTable: [
          ...result.data.map((elem) => ({
            id: elem.id,
            type: elem.taskScheduleResponse.type,
            data: elem?.taskScheduleResponse?.scheduleOn
              ? moment(elem.taskScheduleResponse.scheduleOn).format('DD.MM.yyyy')
              : '',
            adress: elem.taskScheduleResponse.addressTitle,
            executor: elem.taskScheduleResponse.executorId,
            responsible: elem.taskScheduleResponse.contractorId,
            managementCompany: elem.taskScheduleResponse.managementCompanyId,
            taskId: elem.taskScheduleResponse.id,
          })),
        ],
      }));
    },
  }),
  'Registry result'
);
