import { Stack, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { findCountByStatus } from '@/components/features/Yunga/api/YungaService';
import { YungaStatus } from '../types/yungaTypes';

const ResentTypography = withStyles({
  root: {
    color: 'blue',
  },
})(Typography);

const OkTypography = withStyles({
  root: {
    color: 'green',
  },
})(Typography);

const ErrorTypography = withStyles({
  root: {
    color: 'red',
  },
})(Typography);

export const YungaStatusPane = () => {
  const [okCount, setOkCount] = useState(0);
  const [setnCount, setSentCount] = useState(0);
  const [resentCount, setResentCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);

  const findAllCount = () => {
    findCountByStatus(YungaStatus.OK).then((res) => setOkCount(res.data));
    findCountByStatus(YungaStatus.SENT).then((res) => setSentCount(res.data));
    findCountByStatus(YungaStatus.RESENT).then((res) => setResentCount(res.data));
    findCountByStatus(YungaStatus.ERROR).then((res) => setErrorCount(res.data));
  };

  useEffect(() => {
    findAllCount();
    const interval = setInterval(() => {
      findAllCount();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Stack
      direction={'row'}
      spacing={1}
    >
      <Typography>SENT:</Typography>
      <Typography>{setnCount.toLocaleString()}</Typography>
      <ResentTypography>RESEND:</ResentTypography>
      <Typography>{resentCount.toLocaleString()}</Typography>
      <OkTypography>OK:</OkTypography>
      <Typography>{okCount.toLocaleString()}</Typography>
      <ErrorTypography>ERROR:</ErrorTypography>
      <Typography>{errorCount.toLocaleString()}</Typography>
    </Stack>
  );
};
