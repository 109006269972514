import React, {FC} from "react";
import {
	FormControl,
	MenuItem,
	Select,
} from "@mui/material";
import {useFormContext} from "react-hook-form";
import {getIconsPath} from "./icons";
import {ArgumentSelectType, FilterArgEnum} from "./types";

const defaultList = [
	FilterArgEnum.EQUAL,
	FilterArgEnum.NOT_EQUAL,
	FilterArgEnum.CONTAIN,
	FilterArgEnum.MORE,
	FilterArgEnum.LESS,
	FilterArgEnum.INSTALLED,
];

export const ArgumentSelect: FC<ArgumentSelectType> = (props) => {
	const {
		defaultValue = defaultList[0],
		onArgChange,
		registrName,
		argList = defaultList
	} = props;
	const {register} = useFormContext();
	return (
		<FormControl
			className="child"
			variant="standard"
			sx={{margin: 0, padding: 0, flexShrink: 0}}
			style={{margin: 0, padding: 0, flexShrink: 0}}
		>
			<Select
				style={{
					width: 25,
					paddingRight: 10,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginRight: 0,
				}}
				variant={'standard'}
				{...register(registrName)}
				renderValue={(val: any) => {
					let path = getIconsPath(val);
					return (
						<img
							src={path.path}
							style={{margin: 0, marginLeft: 12, padding: 0}}
							title={path.title}
						/>
					);
				}}
				//IconComponent={(val: any) => <div style={{ width: 0, height: 0, padding: 0 }} />}
				IconComponent={() => null}
				defaultValue={defaultValue}
				onChange={onArgChange}
			>
				{argList.map((i) => {
					let item = getIconsPath(i);
					return (
						<MenuItem key={i} value={i}>
							{item.title}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};
