import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';

interface ITableTopToolbar {
  numSelected: number;
  children: JSX.Element;
}

export const TableTopToolbar = (props: ITableTopToolbar) => {
  const { numSelected, children } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          Выбрано: {numSelected}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant='subtitle1'
          id='tableTitle'
          component='div'
        >
          Выберите строки для изменения
        </Typography>
      )}
      {numSelected > 0 ? children : null}
    </Toolbar>
  );
};
