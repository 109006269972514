import { Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { color } from '@/styles/mixins';
import { ECallCenterResult } from '../CallCenterModal/utils';
import React, { useMemo } from 'react';
import { useComplaintStore } from '../store';
import { ResultCallsMaped } from '../types';

const MissedCall = () => {
  const selectedCompliant = useComplaintStore((state) => state.selectedCompliant);
  const missedCalls = useMemo(
    () =>
      (selectedCompliant?.callCenterResults ?? []).reduce<ResultCallsMaped>((acc, item) => {
        const title = item.resultType?.title ?? '';
        const createAt = { createAt: item.base.createAt };
        if (!acc[title]) {
          acc[title] = [];
        }
        acc[title].push(createAt);
        return acc;
      }, {}),
    [selectedCompliant?.callCenterResults]
  );

  return (
    <>
      {!!Object.keys(missedCalls)?.length && (
        <Grid
          item
          xs={12}
        >
          <Stack
            style={{
              borderStyle: 'solid',
              borderColor: color('trueRed'),
              backgroundColor: color('trueWhite'),
              borderRadius: 15,
            }}
            p={1}
          >
            {Object.entries(missedCalls).map(([key, values], index) => {
              return (
                <React.Fragment key={index}>
                  <Typography pr={1}>
                    {key}:{' '}
                    {values.map((el: any) => moment(el.createAt).format('DD.MM.YYYY')).join(', ')}
                  </Typography>
                </React.Fragment>
              );
            })}
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default MissedCall;
