import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { TextField } from '@mui/material';

import { PpoCableResponse } from '@/dto/taskmap/task/TaskResultDto';
import { AutocompleteValue } from '../../inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import {
  ExternalConnectionPopupFormInputWrapper,
  ExternalConnectionPopupIconButton,
  ExternalConnectionPopupFormRow,
} from './ExternalConnectionPopup.styled';
import { useExternalConnectionPopup } from '@/components/popups/externalConnectionPopup/store';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { useDisabledAll } from '@/hooks/useAccess';

interface IExternalConnectionPopupCabelForm {
  cabel: PpoCableResponse;
  externalConnectionId: string;
  index: number;
}

const CabelItem: FC<IExternalConnectionPopupCabelForm> = ({
  cabel,
  externalConnectionId,
  index,
}) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const methods = useFormContext();
  const { register, setValue } = methods;

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const { deleteExtraConnectionCabel } = useExternalConnectionPopup(
    ({ deleteExtraConnectionCabel }) => ({
      deleteExtraConnectionCabel,
    })
  );

  const formString = `${externalConnectionId}.ppoCableRequestList.${index}`;
  setValue(`${externalConnectionId}.ppoCableRequestList.${index}.id`, cabel.id);

  const handleDelete = () => {
    deleteExtraConnectionCabel(externalConnectionId, cabel.id);
    setValue(`${formString}.id`, 'delete');
  };

  return (
    <ExternalConnectionPopupFormRow key={externalConnectionId + cabel.id}>
      <ExternalConnectionPopupIconButton
        onClick={handleDelete}
        disabled={disabledAll || onlyShow}
      >
        <DeleteForeverOutlinedIcon />
      </ExternalConnectionPopupIconButton>

      <ExternalConnectionPopupFormInputWrapper width={'200px'}>
        <AutocompleteValue
          title='Тип'
          defaultValue={cabel?.type}
          fieldName={`${formString}.type`}
          values={getCatalogMapWithErr('ppoCableComposition')}
        />
      </ExternalConnectionPopupFormInputWrapper>
      <ExternalConnectionPopupFormInputWrapper width={'200px'}>
        <TextField
          sx={{ width: '100%' }}
          type='number'
          id='count'
          label='Количество жил'
          variant='standard'
          defaultValue={cabel?.linesNumber}
          {...register(`${formString}.linesNumber`, {
            value: cabel?.linesNumber,
          })}
        />
      </ExternalConnectionPopupFormInputWrapper>
      <ExternalConnectionPopupFormInputWrapper width={'200px'}>
        <AutocompleteValue
          title='Сечение'
          defaultValue={cabel?.sectionId?.toString()}
          fieldName={`${formString}.sectionId`}
          values={getCatalogMapWithErr('cableSection')}
        />
      </ExternalConnectionPopupFormInputWrapper>
    </ExternalConnectionPopupFormRow>
  );
};

export default CabelItem;
