import create, { GetState, SetState, State, StoreApi, UseBoundStore } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

/**
 *  Необходимо установить расширение Redux DevTools
 *  (https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd)
 *
 *  - певый аргумент стандартная функция, которая передается в create ('zustand')
 *  - второй аргумент отвечает за название стора (name: string)
 *  - третий аргумент отвечает за ключ в localStorage (localStorageName?: string). Должен быть уникальным.
 *    Persist используется когда заполнено поле localStorageName, иначе в localStorage ничего не сохраняется
 * @param migratePersistedStorage - Функция при миграции Persist стора
 * */

export const createStore = <T extends State>(
  fn: (set: SetState<T>, get: GetState<T>, api: StoreApi<T>) => T,
  name: string,
  localStorageName?: string,
  migratePersistedStorage?: {
    version: number;
    migrate: (persistedState: unknown, version: number) => T | Promise<T>;
  }
): UseBoundStore<StoreApi<T>> => {
  if (localStorageName) {
    if (import.meta.env.MODE === 'dev') {
      return create(
        persist(devtools(fn, { name: `${name} store` }), {
          name: localStorageName,
          ...(migratePersistedStorage && { ...migratePersistedStorage }),
        })
      );
    }
    return create(
      persist(fn, {
        name: localStorageName,
        ...(migratePersistedStorage && { ...migratePersistedStorage }),
      })
    );
  }
  if (import.meta.env.MODE === 'dev') {
    return create(devtools(fn, { name: `${name} store` }));
  }
  return create(fn);
};
