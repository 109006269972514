import React, { useEffect } from 'react';
import shallow from 'zustand/shallow';
import { Grid, Stack, TablePagination } from '@mui/material';
import { useComplaintStore } from '../store';
import {
  ComplaintBrowseTablePaginatioCount,
  ComplaintBrowseTablePaginatioText,
  ComplaintBrowseTablePagination,
  ComplaintBrowseTablePaginationWrapper,
} from './ComplaintBrowsePagination.styled';

const ComplaintBrowsePagination = () => {
  const {
    totalPages,
    currentPage,
    pageSize,
    compalaintList,
    totalElements,
    isLoading,
    setNewPage,
    setPageSize,
    handleSearch,
  } = useComplaintStore(
    (state) => ({
      totalPages: state.totalPages,
      currentPage: state.currentPage,
      pageSize: state.pageSize,
      compalaintList: state.compalaintList,
      totalElements: state.totalElements,
      setNewPage: state.setNewPage,
      setPageSize: state.setPageSize,
      isLoading: state.isLoading,
      handleSearch: state.handleSearch,
    }),
    shallow
  );

  useEffect(() => {
    if (totalElements) handleSearch();
  }, [currentPage, pageSize]);

  return (
    <ComplaintBrowseTablePaginationWrapper disabled={isLoading}>
      {!!compalaintList.length && (
        <ComplaintBrowseTablePagination
          labelDisplayedRows={({ from, to }) => (
            <ComplaintBrowseTablePaginatioCount>
              {from} - {to} из {totalElements}
            </ComplaintBrowseTablePaginatioCount>
          )}
          count={totalElements}
          page={currentPage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(newPageSize) => setPageSize(Number(newPageSize.target.value))}
          onPageChange={(ev, newPage) => setNewPage(newPage)}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          labelRowsPerPage={
            <ComplaintBrowseTablePaginatioText>
              Жалоб на странице:
            </ComplaintBrowseTablePaginatioText>
          }
          nextIconButtonProps={{
            disabled: currentPage + 1 === totalPages,
          }}
        />
      )}
    </ComplaintBrowseTablePaginationWrapper>
  );
};

export default ComplaintBrowsePagination;
