import { ListItemButton, ListItemText } from '@mui/material';

type PhoneProps = {
  handleEndCall?: () => void;
  startCall?: () => void;
  image: string;
};

const Phone = ({ handleEndCall, startCall, image }: PhoneProps) => {
  const handleClick = () => {
    if (handleEndCall) return handleEndCall();
    if (startCall) return startCall();
    return;
  };
  return (
    <ListItemButton
      onClick={handleClick}
      style={{ borderRadius: 50 }}
    >
      <ListItemText
        primary={
          <img
            src={image}
            alt={'phone'}
            loading='lazy'
          />
        }
      />
    </ListItemButton>
  );
};

export default Phone;
