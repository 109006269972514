import { Autocomplete, Button, TextField } from '@mui/material';
import React from 'react';
import moment from 'moment';
import ru from 'date-fns/locale/ru';
import { Controller, useForm } from 'react-hook-form';
import { useCatalog } from '@/hooks/CatalogHook';
import DatePicker from 'react-datepicker';
import styles from './Popup.module.scss';
import { ApiCopyRequest } from '@/services/YodaRestService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { IPopup, Popup } from './Popup';

interface Props extends IPopup {
  taskIds: string[];
  contractsList: any[];
  taskType?: string;
  contractId?: string;
}

export const CopyPopup: React.FC<Props> = (props) => {
  const { taskIds, contractsList, onClose, onSuccess, taskType, contractId } = props;
  const { getCatalogMapWithErr, taskGroupMap } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    taskGroupMap: state.taskGroupMap,
  }));
  const { register, control, reset, handleSubmit, setValue } = useForm();
  setValue('contractId', contractId);

  const { catchError, addActionLog } = useActionLog();
  const optionsContractsList = contractsList?.map(([key, value]) => ({
    label: value,
    value: key,
  }));
  const options = [...taskGroupMap].map(([key, value]) => ({ label: value, value: key }));
  const optionsTypeTask = [...getCatalogMapWithErr('taskType')].map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const handleClick = (values: any) => {
    if (!values.plannedDate) {
      catchError('Укажите дату', {});
      return;
    }
    const fixedValues = {
      taskType: taskType,
      ...values,
      plannedDate: values.plannedDate ? moment(values.plannedDate).format('YYYY-MM-DD') : '',
    };
    ApiCopyRequest({
      taskIds,
      ...(fixedValues as any),
    })
      .then(() => {
        onClose();
        addActionLog(ActionLogType.SUCCESS, 'Заявка создана');
        onSuccess && onSuccess();
      })
      .catch((error) => {
        if (error?.response?.data?.result) {
          catchError(error?.response?.data?.result, {});
        } else {
          catchError(error.response.data.message, {});
        }
      });
  };

  return (
    <Popup
      {...props}
      customStyle={{ overflow: 'visible' }}
    >
      {!taskType && (
        <div className={styles.item}>
          <span>Тип заявки</span>
          <Controller
            control={control}
            name='taskType'
            render={({ field }) => (
              <Autocomplete
                style={{ width: '37%' }}
                options={optionsTypeTask}
                value={{
                  value: field?.value ?? '',
                  label: getCatalogMapWithErr('taskType').get(field?.value) ?? '',
                }}
                onChange={(event: any, newValue: any) => {
                  field?.onChange(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    name='taskType'
                    {...params}
                    label={'Тип заявки'}
                    variant='standard'
                  />
                )}
              />
            )}
          />
        </div>
      )}
      <div className={styles.item}>
        <span>Контракт</span>
        <Controller
          control={control}
          name='contractId'
          render={({ field }) => (
            <Autocomplete
              style={{ width: '37%' }}
              options={optionsContractsList}
              value={{
                value: field?.value ?? contractId,
                label:
                  optionsContractsList.find((item) => item.value === field?.value)?.label ||
                  optionsContractsList.find((item) => item.value === contractId)?.label ||
                  '',
              }}
              onChange={(event: any, newValue: any) => {
                field?.onChange(newValue?.value || contractId);
              }}
              renderInput={(params) => (
                <TextField
                  name='contractId'
                  {...params}
                  label={'Контракт'}
                  variant='standard'
                />
              )}
            />
          )}
        />
      </div>
      <div className={styles.item}>
        <span>Подрядчик</span>
        <Controller
          control={control}
          name='contractorId'
          render={({ field }) => (
            <Autocomplete
              style={{ width: '37%' }}
              options={options}
              value={{ value: field?.value ?? '', label: taskGroupMap.get(field?.value) ?? '' }}
              onChange={(event: any, newValue: any) => {
                field?.onChange(newValue?.value);
              }}
              renderInput={(params) => (
                <TextField
                  name='contractorId'
                  {...params}
                  label={'Подрядчик'}
                  variant='standard'
                />
              )}
            />
          )}
        />
      </div>
      <div className={styles.item}>
        <span>Бригадир</span>
        <Controller
          control={control}
          name='executorId'
          render={({ field }) => (
            <Autocomplete
              style={{ width: '37%' }}
              options={options}
              value={{ value: field?.value ?? '', label: taskGroupMap.get(field?.value) ?? '' }}
              onChange={(event: any, newValue: any) => {
                field?.onChange(newValue?.value);
              }}
              renderInput={(params) => (
                <TextField
                  name='executorId'
                  {...params}
                  label={'Бригадир'}
                  variant='standard'
                />
              )}
            />
          )}
        />
      </div>
      <div className={styles.item}>
        <span>Плановая дата</span>
        <div>
          <Controller
            control={control}
            name='plannedDate'
            render={({ field }) => (
              <DatePicker
                dateFormat='dd-MM-yyyy'
                locale={ru}
                className={styles.input}
                selected={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </div>
      <div>
        <Controller
          control={control}
          name='taskComment'
          render={({ field }) => (
            <TextField
              fullWidth
              id='taskComment-field'
              label='Комментарий'
              multiline
              rows={4}
              value={field.value ? field.value : ''}
              variant='outlined'
              onChange={(e) => field.onChange(e)}
            />
          )}
        />
      </div>
      <div className={styles['modal-buttons']}>
        <Button
          onClick={handleSubmit(handleClick)}
          variant='contained'
        >
          Создать новую заявку
        </Button>
      </div>
    </Popup>
  );
};
