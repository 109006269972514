import { useQuery } from 'react-query';
import { KEYS } from './Keys';
import { syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { FetchCathError } from '@/types/api';
import {
  SupplyBillApiGetPuTypes,
  SupplyBillApiGetPuTypesParents,
} from '@/services/SupplyBillService';

export const useAllPuTypes = (onlyParentTypes?: boolean) => {
  const { fetchCatch } = useActionLog();
  const { data: axiosResponse, isLoading } = useQuery({
    queryKey: KEYS.allPuTypes,
    staleTime: Infinity,
    queryFn: SupplyBillApiGetPuTypes,
    onError: (error: FetchCathError) => fetchCatch(error, 'Ошибка инициализации приборов учета'),
    onSuccess: ({ data }) => {
      if (!data.length) {
        syncErrorCatch('Получен пустой список типов приборов учета', data);
      }
    },
  });

  const { data: parentsResponse, isLoading: parentsLoading } = useQuery({
    queryKey: [...KEYS.allPuTypes, 'parents'],
    staleTime: Infinity,
    queryFn: SupplyBillApiGetPuTypesParents,
    onError: (error: FetchCathError) => fetchCatch(error, 'Ошибка инициализации приборов учета'),
    enabled: !!onlyParentTypes,
  });

  return {
    puTypesResponse: onlyParentTypes ? parentsResponse?.data : axiosResponse?.data,
    isLoading: onlyParentTypes ? parentsLoading : isLoading,
  };
};
