import { createStore } from '@/utils/createStore';

interface MapPopperStore {
  isOpenMapPopper: boolean;
  mapPopperTitle: string;
  anchorEl: HTMLElement | null;
  openMapPopper: (mapPopperTitle: string, anchorEl: HTMLElement) => void;
  closeMapPopper: () => void;
}

export const useMapPopper = createStore<MapPopperStore>(
  (set, get) => ({
    isOpenMapPopper: false,
    mapPopperTitle: '',
    anchorEl: null,

    openMapPopper(mapPopperTitle: string, anchorEl: HTMLElement) {
      set({ isOpenMapPopper: true, mapPopperTitle: mapPopperTitle, anchorEl: anchorEl });
    },

    closeMapPopper() {
      setTimeout(function () {
        set({ isOpenMapPopper: false, mapPopperTitle: undefined, anchorEl: undefined });
      }, 500);
    },
  }),
  'Map popper'
);
