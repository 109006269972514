import React from 'react';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { color } from '@/styles/mixins';
import { useCatalog } from '@/hooks/CatalogHook';
import moment from 'moment';
import { IComplaintItem } from './types';
import ComplaintItem from './ComplaintItem/ComplaintItem';
import { useComplaintIcon } from './utils';
import {
  InfoComplaintBlockLabel,
  InfoComplaintBlockText,
} from './ComplaintInfo/InfoComplaintBlock.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

export default function ComplaintItemEnergy(complaintDTO: IComplaintItem) {
  const { sapNumber, responseDate, declarerFio, account, phoneNumber, res, source, complaint } =
    complaintDTO;
  const iconSource = useComplaintIcon(source);
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const isMobile = useMobileSize();

  return (
    <ComplaintItem complaintDTO={complaintDTO}>
      <Grid
        container
        md={12}
        xs={12}
        spacing={1}
      >
        <Grid
          item
          xs={8}
        >
          <Typography
            variant='body2'
            gutterBottom
            color='textSecondary'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {complaintDTO.complaint.address.readOnlyAddress}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Typography
            variant='subtitle2'
            gutterBottom
            textAlign={'end'}
            sx={{ fontWeight: '500' }}
          >
            Жалоба {complaint.base.id}
          </Typography>
        </Grid>
      </Grid>
      {/* <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        gap={1}
      >
        <Typography
          variant='body2'
          gutterBottom
          color='textSecondary'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {complaintDTO.complaint.address.readOnlyAddress}
        </Typography>

        <Typography
          variant='subtitle2'
          gutterBottom
          sx={{ fontWeight: '500' }}
        >
          Жалоба {complaint.base.id}
        </Typography>
      </Stack> */}
      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={12}
          md={1}
        >
          <Stack
            flexDirection={'row'}
            gap={3.1}
          >
            <InfoComplaintBlockLabel variant='body2'>ЛС</InfoComplaintBlockLabel>
            <InfoComplaintBlockText variant='body2'>{account}</InfoComplaintBlockText>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={1.5}
        >
          <Stack
            flexDirection={'row'}
            justifyContent={isMobile ? 'start' : 'center'}
          >
            <InfoComplaintBlockText
              variant='body2'
              color={color('muiBlue')}
            >
              {declarerFio}
            </InfoComplaintBlockText>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={8.5}
        >
          <Stack
            flexDirection={'row'}
            justifyContent={'start'}
          >
            <InfoComplaintBlockText variant='body2'>{complaint?.text}</InfoComplaintBlockText>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
        >
          <Stack
            flexDirection={'row'}
            justifyContent={'end'}
          >
            <InfoComplaintBlockText variant='subtitle2'>
              {moment(responseDate).format('DD.MM.YYYY')}
            </InfoComplaintBlockText>
          </Stack>
        </Grid>
      </Grid>
      {/* secondLine */}
      <Grid
        container
        xs={12}
        md={12}
      >
        <Grid
          item
          xs={12}
          md={1}
        >
          <Stack
            flexDirection={'row'}
            gap={1}
          >
            <InfoComplaintBlockLabel variant='body2'>№ ПУ</InfoComplaintBlockLabel>
            <InfoComplaintBlockText
              variant='body2'
              color={color('muiBlue')}
            >
              {complaint.meterNumber}
            </InfoComplaintBlockText>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={1.5}
        >
          <Stack
            flexDirection={'row'}
            justifyContent={isMobile ? 'start' : 'center'}
          >
            <InfoComplaintBlockText
              variant='body2'
              color={color('muiBlue')}
            >
              {phoneNumber}
            </InfoComplaintBlockText>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={8.5}
        >
          <Stack flexDirection={'row'}>
            <InfoComplaintBlockText
              variant='body2'
              color='textSecondary'
            >
              {complaint.lastComment && complaint.lastComment.text}
            </InfoComplaintBlockText>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
        >
          <Stack
            flexDirection={'row'}
            justifyContent={'end'}
            alignItems={'center'}
            gap={1}
          >
            <img
              src={iconSource}
              alt='description'
              style={{ width: '25px', height: 'auto' }}
            />
            <Typography
              variant='body2'
              sx={{ fontWeight: '500' }}
            >
              {sapNumber}
            </Typography>
          </Stack>

          <Stack
            flexDirection={'row'}
            justifyContent={'end'}
            alignItems={'center'}
            paddingY={complaint.reasonTaskNumber ? 2 : 0}
          >
            {complaintDTO.complaint.reasonTaskId && complaintDTO.complaint.reasonTaskNumber && (
              <Typography
                variant='body2'
                gutterBottom
                color={color('muiBlue')}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open('/task/edit/' + complaintDTO.complaint.reasonTaskId);
                }}
                sx={{ fontWeight: '500', position: 'absolute' }}
              >
                YODA {complaintDTO.complaint.reasonTaskNumber}
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
      {/* third line  */}
      <Grid
        container
        xs={12}
        md={12}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <Stack
            flexDirection={'row'}
            gap={1}
          >
            <InfoComplaintBlockLabel variant='body2'>РЭС</InfoComplaintBlockLabel>
            <InfoComplaintBlockText variant='body2'>
              {getCatalogMapWithErr('energyCompany').get(res || '')}
            </InfoComplaintBlockText>
          </Stack>
        </Grid>
        {!isMobile && (
          <Grid
            item
            xs={12}
            md={6}
          >
            <Stack
              flexDirection={'row'}
              justifyContent={'end'}
            >
              <Typography variant='body2'>
                этап <span style={{ fontWeight: '500' }}>{complaint.stage.title}</span>
              </Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
    </ComplaintItem>
  );
}
