import React, { FC } from 'react';
import { color } from '@/styles/mixins';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { usePillar } from '../../resCard/pillarStore';
import { PillarItem } from '@/components/features/taskEditor/pillar/PillarItem';

export const PillarCard: FC = () => {
  const methods = useForm();
  const { infoPillar } = usePillar();

  return infoPillar ? (
    <BoxShadow
      background={color('lightWhite')}
      sx={{ mb: 2 }}
    >
      <FormProvider {...methods}>
        <PillarItem />
      </FormProvider>
    </BoxShadow>
  ) : (
    <></>
  );
};
