import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ScatterDataPoint,
  BubbleDataPoint,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Button, Paper } from '@mui/material';
import './statisticsChartStyle.css';

import UpdateIcon from '@mui/icons-material/Update';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { DateRange } from '../../inputs/DateRange';
import { STATISTICS_CHART_OPTIONS } from './utils';

interface StatisticsProps {
  data: ChartData<'line', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>;
  header: string;
  load: boolean;
  setDateInRange: (date: number[] | Date[]) => void;
  dataInRange: number[] | Date[];
  updateFunction: () => void;
}
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const StatisticsChart: React.FC<StatisticsProps> = ({
  data,
  header,
  load,
  dataInRange,
  setDateInRange,
  updateFunction,
}) => {
  const isInRange = dataInRange.length !== 2;

  return (
    <Paper elevation={6}>
      <p className={'headerPageStatistics'}>{header}</p>
      {load ? (
        <LoadSpinner />
      ) : (
        <div>
          <div className={'chartStyle'}>
            <Line
              options={STATISTICS_CHART_OPTIONS}
              data={data}
            />
          </div>
          <div className={'statisticsTableFlex'}>
            <DateRange
              label={'выбрать диапазон'}
              onChange={setDateInRange}
              isInRange={isInRange}
              value={dataInRange}
              argValue={8} // set interval by default
              showSelect={false} // hide select option
            />

            <Button
              onClick={updateFunction}
              variant='contained'
            >
              <UpdateIcon />
            </Button>
          </div>
        </div>
      )}
    </Paper>
  );
};
export default StatisticsChart;
