export const TaskStatuses = [
  { value: 'new', label: 'Новая' },
  { value: 'in_work', label: 'В работе' },
  { value: 'assigned', label: 'Назначенная' },
  { value: 'check', label: 'На проверке' },
  { value: 'returned', label: 'Возврат исполнителю' },
  { value: 'completed', label: 'Закрыта' },
  { value: 'not_relevant', label: 'Не актуальна' },
  { value: 'disput', label: 'Disput' },
];
