const mesStatus = [
  'SENT_TO_TO',
  'READY_TO_SEND_IN_TO',
  'READY_TO_CLOSE',
  'READY_TO_MOVE_TO_MES',
  'SIGNED_TRANSFERRED_TO_THE_MES_CALCULATION',
];
const mesLockStatus = [
  'READY_TO_CLOSE',
  'READY_TO_MOVE_TO_MES',
  'SIGNED_TRANSFERRED_TO_THE_MES_CALCULATION',
];

export const getEnRuStatus = (status: string | undefined): string => {
  switch (status) {
    case 'SENT_TO_TO':
      return '-';
    case 'READY_TO_SEND_IN_TO':
      return 'Готов к передаче в ТО';
    case 'READY_TO_CLOSE':
      return 'Готов к закрытию';
    case 'READY_TO_MOVE_TO_MES':
      return 'Готов к передаче в МЭС';
    case 'SIGNED_TRANSFERRED_TO_THE_MES_CALCULATION':
      return 'Подписан (Переведен в расчет МЭС)';
    default:
      return 'Не определен';
  }
};

export const getButtonText = (actStatus: string | undefined): string => {
  if (actStatus && mesStatus.includes(actStatus)) {
    return 'Передача акта в МЭС';
  }
  // TODO: если добавлять другие статусы, добавить условие !actStatus || actStatus === ''
  return 'Отправить в ТО';
};

export const getButtonDisabled = (actStatus: string | undefined): boolean => {
  return !!(actStatus && mesLockStatus.includes(actStatus));
};
