const variables = {
  offset: {
    mobile: 20,
    tablet: 25,
    desktop: 40,
  },
  videoRatio: (9 / 16) * 100,
  fonts: {
    roboto: 'Roboto, Arial',
    inter: 'Inter, Arial',
  },
};

export type FontFamily = keyof typeof variables.fonts;

export type Offset = keyof typeof variables.offset;

export default variables;
