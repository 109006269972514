import { color } from '@/styles/mixins';
import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const PnrHermesWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});

export const PnrHermesButtonsWrapper = styled('div')({
  display: 'flex',
  gap: 20,
  alignItems: 'center',
});

export const PnrHermesTerminalMacWrapper = styled('div')({
  display: 'flex',
  width: 'fit-content',
  gap: 10,
  alignItems: 'center',
  borderBottom: `1px solid ${color('trueGray', 0.7)}`,

  '&:last-child': {
    borderBottom: 'none',
  },
});

export const PnrHermesDataWrapper = styled('div')({
  display: 'flex',
  gap: 20,
});

export const PnrHermesTerminal = styled('div')({
  border: `1px solid ${color('trueGray', 0.7)}`,
  height: 340,
  overflowY: 'scroll',
  borderRadius: '4px',

  flexGrow: 1,
  padding: 16,
  p: {
    marginBottom: 2,
  },
});

export const PnrHermesTerminalRow = styled('div')({
  borderBottom: `1px solid ${color('trueGray', 0.7)}`,
  padding: '16px 0',
});

export const PnrHermesDataGrid = styled(DataGrid)({
  height: '340px',
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },

  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
});

export const PnrHermesTerminalEmptyText = styled('p')({
  color: color('trueGray'),
  fontSize: 40,
  fontWeight: 500,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '90%',
});
