import { MONTAGE_PLACE_VALUES } from '@/components/blocks/PnrPlanningModalSteps/util';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { NetworkEquipmentIds } from '../HouseEntranceSheme/utils';
import { PlannedInstalation, PlannedInstalationsSheme, PnrPlanningCount } from './store';
import { PlannedInstalationsDto } from '@/dto/taskmap/Dto';
import {
  HouseLevelFloorType,
  NetworkPlanningTypes,
  NOT_DEFINED_MONTAGE_PLACE,
} from '@/components/blocks/PnrElementsInfo/utils';
import { ROUTER_TYPE, GATEWAY_TYPE } from '@/components/features/pnr/constants';

export const getFilteredNetworkOptions = (networkEquipmentTypes: Map<string, string>) => {
  if (networkEquipmentTypes) {
    return taskMapToArray(networkEquipmentTypes).filter((networkEquip) => {
      const lowerCaseValue = networkEquip.label.toLowerCase();
      return lowerCaseValue === ROUTER_TYPE || lowerCaseValue === GATEWAY_TYPE;
    });
  } else return [];
};

export const convertPlanningsToPlanningRows = (
  plannings: PlannedInstalationsDto[],
  networkEquipmentTypes: Map<string, string>,
  networkElementPlanningTypes: Map<string, string>,
  floorTypes: Map<string, string>
): PlannedInstalation[] => {
  return plannings?.map((planning, index) => {
    const equipmentFilterLabel = networkEquipmentTypes.get(planning?.equipmentTypeId || '');
    const planTypeFilterLabel = networkElementPlanningTypes.get(planning?.type || '');

    return {
      ...planning,
      tmpId: index,
      entrance: planning?.houseLevel?.entranceNumber?.toString(),
      floor: planning?.houseLevel?.floorNumber
        ? planning?.houseLevel?.floorNumber.toString()
        : null,
      deleted: false,
      ...(planning?.houseLevel?.floorType && floorTypes.has(planning?.houseLevel?.floorType)
        ? {
            montagePlace: {
              label: floorTypes.get(planning.houseLevel.floorType) ?? '',
              value: planning.houseLevel.floorType ?? '',
            },
          }
        : { montagePlace: null }),
      ...(equipmentFilterLabel && planning.equipmentTypeId
        ? { equipmentTypeFilter: { label: equipmentFilterLabel, value: planning.equipmentTypeId } }
        : { equipmentTypeFilter: null }),

      ...(planTypeFilterLabel && planning.type
        ? { planningTypeFilter: { label: planTypeFilterLabel, value: planning.type } }
        : { planningTypeFilter: null }),
    };
  });
};

export type PlannedInstalationByTypes = {
  [key in 'router' | 'gateway']: {
    userEditedInstalationsCount: number;
    backendInstalationsCount: number;
    hasConflictUser: boolean;
    hasConflictBackend: boolean;
  };
};

export interface CurrentPlannedInstalations {
  [NetworkPlanningTypes.FIRSTLY]: PlannedInstalationByTypes;
  [NetworkPlanningTypes.SECONDLY]: PlannedInstalationByTypes;
  [NetworkPlanningTypes.PREPLAN]: PlannedInstalationByTypes;
}

const emptyPlannedInstalationByType = {
  router: {
    userEditedInstalationsCount: 0,
    backendInstalationsCount: 0,
    hasConflictUser: false,
    hasConflictBackend: false,
  },
  gateway: {
    userEditedInstalationsCount: 0,
    backendInstalationsCount: 0,
    hasConflictUser: false,
    hasConflictBackend: false,
  },
};
export const INITIAL_CURRENT_PLANNED_INSTALATIONS: CurrentPlannedInstalations = {
  [NetworkPlanningTypes.FIRSTLY]: { ...emptyPlannedInstalationByType },
  [NetworkPlanningTypes.SECONDLY]: { ...emptyPlannedInstalationByType },
  [NetworkPlanningTypes.PREPLAN]: { ...emptyPlannedInstalationByType },
};

export const getCurrentEntranceInstalations = (plannedInstalations: PlannedInstalation[]) => {
  const currentEntranceInstalations: CurrentPlannedInstalations = {
    [NetworkPlanningTypes.FIRSTLY]: {
      router: {
        userEditedInstalationsCount: 0,
        backendInstalationsCount: 0,
        hasConflictUser: false,
        hasConflictBackend: false,
      },
      gateway: {
        userEditedInstalationsCount: 0,
        backendInstalationsCount: 0,
        hasConflictUser: false,
        hasConflictBackend: false,
      },
    },
    [NetworkPlanningTypes.SECONDLY]: {
      router: {
        userEditedInstalationsCount: 0,
        backendInstalationsCount: 0,
        hasConflictUser: false,
        hasConflictBackend: false,
      },
      gateway: {
        userEditedInstalationsCount: 0,
        backendInstalationsCount: 0,
        hasConflictUser: false,
        hasConflictBackend: false,
      },
    },
    [NetworkPlanningTypes.PREPLAN]: {
      router: {
        userEditedInstalationsCount: 0,
        backendInstalationsCount: 0,
        hasConflictUser: false,
        hasConflictBackend: false,
      },
      gateway: {
        userEditedInstalationsCount: 0,
        backendInstalationsCount: 0,
        hasConflictUser: false,
        hasConflictBackend: false,
      },
    },
  };

  plannedInstalations.forEach((instalation) => {
    const isUserEdited = instalation?.userEdited;
    const hasConflict = instalation?.hasConflict;

    if (instalation?.equipmentTypeId === NetworkEquipmentIds.router) {
      currentEntranceInstalations[instalation.type].router = {
        userEditedInstalationsCount:
          currentEntranceInstalations[instalation.type].router?.userEditedInstalationsCount +
          (isUserEdited ? 1 : 0),

        backendInstalationsCount:
          currentEntranceInstalations[instalation.type].router?.backendInstalationsCount +
          (isUserEdited ? 0 : 1),

        hasConflictUser: isUserEdited
          ? currentEntranceInstalations[instalation.type].router?.hasConflictUser === true
            ? true
            : hasConflict
          : currentEntranceInstalations[instalation.type].router?.hasConflictUser,

        hasConflictBackend: isUserEdited
          ? currentEntranceInstalations[instalation.type].router?.hasConflictBackend
          : currentEntranceInstalations[instalation.type].router?.hasConflictBackend === true
          ? true
          : hasConflict,
      };
    }

    if (instalation?.equipmentTypeId === NetworkEquipmentIds.gateway) {
      currentEntranceInstalations[instalation.type].gateway = {
        userEditedInstalationsCount:
          currentEntranceInstalations[instalation.type].gateway?.userEditedInstalationsCount +
          (isUserEdited ? 1 : 0),

        backendInstalationsCount:
          currentEntranceInstalations[instalation.type].gateway?.backendInstalationsCount +
          (isUserEdited ? 0 : 1),

        hasConflictUser: isUserEdited
          ? currentEntranceInstalations[instalation.type].gateway?.hasConflictUser === true
            ? true
            : hasConflict
          : currentEntranceInstalations[instalation.type].gateway?.hasConflictUser,

        hasConflictBackend: isUserEdited
          ? currentEntranceInstalations[instalation.type].gateway?.hasConflictBackend
          : currentEntranceInstalations[instalation.type].gateway?.hasConflictBackend === true
          ? true
          : hasConflict,
      };
    }
  });

  return currentEntranceInstalations;
};

const getMontagePlace = (planning: PlannedInstalation) => {
  const montagePlace = planning?.montagePlace?.value as MONTAGE_PLACE_VALUES;
  const montageFloor = planning?.houseLevel?.floorNumber?.toString() ?? '';

  switch (montagePlace) {
    case HouseLevelFloorType.ATTIC:
    case HouseLevelFloorType.BASEMENT:
      return montagePlace;
    case HouseLevelFloorType.FLOOR:
      return montageFloor;
    case null:
      return NOT_DEFINED_MONTAGE_PLACE;

    default:
      return montageFloor;
  }
};

export const getPlannedNetworkElementsSheme = (plannings: PlannedInstalation[]) => {
  const plannedNetworkElementsSheme: PlannedInstalationsSheme = new Map();
  try {
    if (!plannings.length) return plannedNetworkElementsSheme;

    for (let i = 0; i < plannings.length; i++) {
      const element = plannings[i];

      if (!element?.houseLevel?.entranceNumber || !element?.equipmentTypeFilter?.value) continue;

      const montagePlace = getMontagePlace(element);

      if (plannedNetworkElementsSheme.has(Number(element?.houseLevel?.entranceNumber))) {
        const entranceMap = plannedNetworkElementsSheme.get(
          Number(element?.houseLevel?.entranceNumber)
        );
        const floorMap = new Map(entranceMap);
        if (entranceMap?.has(montagePlace)) {
          // Добавление элементов в существующее место установки
          const currentNetworkElements = entranceMap?.get(montagePlace) ?? [];
          floorMap.set(montagePlace, [...currentNetworkElements, element]);
        } else {
          // Новое место установки
          floorMap.set(montagePlace, [element]);
        }

        plannedNetworkElementsSheme.set(Number(element?.houseLevel?.entranceNumber), floorMap);
      } else {
        // Новый подъезд
        const floorMap: Map<string, PlannedInstalation[]> = new Map();
        floorMap.set(montagePlace, [element]);
        plannedNetworkElementsSheme.set(Number(element?.houseLevel?.entranceNumber), floorMap);
      }
    }

    return plannedNetworkElementsSheme;
  } catch (error) {
    return plannedNetworkElementsSheme;
  }
};

export const getPlaningsCount = (planings: PlannedInstalationsDto[]) => {
  const plannedCount: PnrPlanningCount = {};

  for (let i = 0; i < planings.length; i++) {
    const element = planings[i];
    if (element?.equipmentTypeId === NetworkEquipmentIds.gateway) {
      plannedCount.gateway = plannedCount.gateway ? plannedCount.gateway + 1 : 1;
    }
    if (element?.equipmentTypeId === NetworkEquipmentIds.router) {
      plannedCount.router = plannedCount.router ? plannedCount.router + 1 : 1;
    }
  }

  return plannedCount;
};

export const deepCopyMap = <T>(map: Map<any, T>): Map<any, T> => {
  const copy = new Map<any, T>();

  for (const [key, value] of map) {
    let newValue: any;

    if (value instanceof Map) {
      newValue = deepCopyMap(value);
    } else if (Array.isArray(value)) {
      newValue = value.map((item) => {
        if (item instanceof Map) {
          return deepCopyMap(item);
        } else if (typeof item === 'object' && item !== null) {
          return deepCopyObject(item);
        }
        return item;
      });
    } else if (typeof value === 'object' && value !== null) {
      newValue = deepCopyObject(value);
    } else {
      newValue = value;
    }

    copy.set(key, newValue);
  }

  return copy;
};

const deepCopyObject = <T>(obj: T): T => {
  const newObj: any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value instanceof Map) {
        newObj[key] = deepCopyMap(value);
      } else if (Array.isArray(value)) {
        newObj[key] = value.map((item) => {
          if (item instanceof Map) {
            return deepCopyMap(item);
          } else if (typeof item === 'object' && item !== null) {
            return deepCopyObject(item);
          }
          return item;
        });
      } else if (typeof value === 'object' && value !== null) {
        newObj[key] = deepCopyObject(value);
      } else {
        newObj[key] = value;
      }
    }
  }

  return newObj;
};
