import React, { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ILabelCheckbox } from './types';

export const LabelCheckbox: FC<ILabelCheckbox> = (props) => {
  const { onChange, value, label, style, disabled = false, className } = props;

  return (
    <FormControlLabel
      control={<Checkbox sx={style} />}
      onChange={(e, c) => onChange(c)}
      checked={value}
      label={label}
      disabled={disabled}
      className={className}
    />
  );
};
