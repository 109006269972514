import { color } from '@/styles/mixins';
import {
  Checkbox,
  CheckboxProps,
  ListItem,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[3],
    fontSize: 11,
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: color('lightBlue'),
    color: 'white',
  },
  '&': {
    color: color('typographyColorMap1'),
    borderRadius: 10,
  },
}));

type StyledTypographyPropsSpan = {
  customColor?: string;
  theme?: any;
};

export const StyledSpan = styled('span')<StyledTypographyPropsSpan>(({ theme, customColor }) => ({
  '&': {
    color: customColor || color('gray'),
  },
}));

type StyledTypographyProps = {
  colorLengthSelectedEx?: boolean;
} & TypographyProps;

export const StyledTypography = styled((props: StyledTypographyProps) => <Typography {...props} />)(
  ({ colorLengthSelectedEx = true }: StyledTypographyProps) => ({
    color: colorLengthSelectedEx ? color('trueBlack') : color('trueRed'),
    width: '100%',
  })
);

type StyledCheckboxProps = {
  checked?: boolean;
} & CheckboxProps;

export const StyledCheckbox = styled(({ ...props }: StyledCheckboxProps) => (
  <Checkbox {...props} />
))((checkboxProps) => ({
  color: color('blue2'),
  '&.Mui-checked': {
    color: checkboxProps.checked ? color('blue2') : undefined,
  },
}));

interface StyledDivProps {
  isHighlighted?: boolean;
  isBold?: boolean;
}

export const StyledDiv = styled('div')<StyledDivProps>((props) => ({}));
