import { TextFieldProps } from '@mui/material/TextField/TextField';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { TextField } from './TextField';

type ClearingTextFieldProps = TextFieldProps & { name: string; options: any };

export const ClearingTextField = (props: ClearingTextFieldProps): JSX.Element => {
  const { register, setValue } = useFormContext();
  useEffect(() => {
    setValue(props.name, props.options.value);
  }, []);
  return (
    <TextField
      {...props}
      {...register(props.name, props.options)}
    />
  );
};
