import { CircularProgress } from '@mui/material';
import { WEB_RTC_CALL_STATUS } from '../types/types';
declare const ITooLabs: any;
export const VATS_CONFIG = {
  apiHost: 'vats985332.megapbx.ru',
  //Скрипт скопирован и лежит по адресу ./webRTC.js
  webRTCScriptUrl: 'https://vats985332.megapbx.ru/framework/itl_webrtc.js',
  //Только для теста, номер Константина П.
  testPhone: '79027377336',
  //Только для теста, номер Александра К.
  testPhone2: '89636398089',
  //Только для теста, номер Максима И.
  testPhone3: '79200023319',
  // только для теста номер Сергей М.
  testPhone4: '79537108960',
};

function debug(dir: any, msg: any) {
  // console.log(dir + '-----' + (JSON.stringify(msg) || '')); // разкоментировать что бы дебажить
}

export const getWebRTCClient = () => ITooLabs?.WebRTC.Factory.getInstance();

export const initWebRTC = (
  login: string,
  password: string,
  onAuth: () => void,
  onNoAuth: () => void,
  onConnectionFailed: () => void
) => {
  const client = getWebRTCClient();

  if (!client) return;
  if (!client.initialized()) {
    client
      .addEventListener(ITooLabs?.WebRTC.Events.SDKReady, function () {
        client.connect();
      })
      .addEventListener(ITooLabs?.WebRTC.Events.ConnectionEstablished, function () {
        client.login(login, password);
      })
      .addEventListener(ITooLabs?.WebRTC.Events.ConnectionFailed, function () {
        onConnectionFailed();
      })
      .addEventListener(ITooLabs?.WebRTC.Events.AuthResult, function (event: { result: boolean }) {
        if (event.result) onAuth();
        else onNoAuth();
      });

    // Входящий звонок
    // .addEventListener(ITooLabs?.WebRTC.Events.IncomingCall, function (event) {
    //   if (client.getActiveCall()) {
    //     debug('info', 'Reject incoming call, cause already has active call.');
    //     event.call.decline();
    //   } else {
    //     const theCall = event.call;
    //     debug('info', 'Incoming call from: ' + theCall.getNumber());

    //     theCall.answer();
    //     // If you don't want accept call:
    //     // theCall.decline();

    //     theCall
    //       .addEventListener(ITooLabs?.WebRTC.CallEvents.Connected, function (event) {
    //         debug('info', 'Incoming call connected.');
    //         // Use theCall.hangup(); to hangup.
    //       })
    //       .addEventListener(ITooLabs?.WebRTC.CallEvents.Disconnected, function (event) {
    //         debug('info', 'Incoming call disconnected: ' + JSON.stringify(event.call.cdr()));
    //       });
    //   }
    // });

    client.init({
      // debug: debug,
      APIHostName: VATS_CONFIG.apiHost,
      domain: VATS_CONFIG.apiHost,
      // Отключение "входящих" звонков
      bind: false,
    });
  } else {
    if (!client.isConnected()) {
      client.connect();
    }
  }
};

export const startWebRTCCall = (
  phone: string,
  onConnected: () => void,
  onDisconnected: () => void,
  onFailed: (e: any) => void
) => {
  const client = getWebRTCClient();
  if (client?.getActiveCall()) {
    // debug('info', 'Skip outgoing call, cause already has active call.');
    return;
  }
  if (phone) {
    // debug('info', 'Calling: ' + phone);

    const theCall = client.call(phone);
    theCall
      .addEventListener(ITooLabs?.WebRTC.CallEvents.Connected, function () {
        // debug('info', 'Call connected.');
        onConnected();
      })
      .addEventListener(ITooLabs?.WebRTC.CallEvents.Disconnected, function (e: any) {
        // debug('info------', 'Call Disconnected.');
        if (e.call.cdr()?.disconnectReason && e.call.cdr()?.disconnectReason !== 'OK') {
          onFailed(e.call.cdr());
        }
        onDisconnected();
      });
    // .addEventListener(ITooLabs?.WebRTC.CallEvents.ConnectionFailed, function (e: any) {
    //   // debug('info------', 'Call onConnetionFailed.');
    //   onFailed(theCall);
    // });
  }
};

export const endCall = () => {
  const client = getWebRTCClient();
  const activeCall = client?.getActiveCall();
  if (activeCall) {
    activeCall.hangup();
  }
};
export const hardCancel = () => {
  const client = getWebRTCClient();
  const activeCall = client?.getActiveCall();
  if (activeCall) {
    activeCall.hangup();
    activeCall.cancel();
  }
};

export const testButton = () => {
  const client = getWebRTCClient();
  const activeCall = client?.getActiveCall();
  if (activeCall) {
    console.log('🚀 ~ activeCall:', activeCall);
    console.log('active', activeCall.active());
    console.log('callId', activeCall.callId());
    console.log('cdr', activeCall.cdr());
    console.log('displayName', activeCall.displayName());
    console.log('getCallId', activeCall.getCallId());
    console.log('getCdr', activeCall.getCdr());
    console.log('getDisplayName', activeCall.getDisplayName());
    console.log('getDiversion', activeCall.getDiversion());
    console.log('getHeaders', activeCall.getHeaders());
    console.log('getId', activeCall.getId());
    console.log('getLeg', activeCall.getLeg());
    console.log('getNumber', activeCall.getNumber());
    console.log('getState', activeCall.getState());
    console.log('getUUID', activeCall.getUUID());
    console.log('headers', activeCall.headers());
    console.log('id', activeCall.id());
    console.log('leg', activeCall.leg());
    console.log('number', activeCall.number());
    console.log('state', activeCall.state());
  } else {
    console.log('no active call');
  }
};

export const getTESTCallStatusText = (webRtcCallStatus: WEB_RTC_CALL_STATUS) => {
  switch (webRtcCallStatus) {
    case 'default':
      return 'Нажмите на кнопку вызова';
    case 'connecting':
      return (
        <span>
          Вызов абонента
          <CircularProgress
            size={15}
            sx={{ ml: 1 }}
          />
        </span>
      );
    case 'connected':
      return 'В процессе разговора';
    case 'failed':
      return 'Ошибка вызова';
    case 'ended':
      return 'Вызов завершен';

    default:
      return;
  }
};
