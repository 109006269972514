import React, { FC } from 'react';
import moment from 'moment';
import { LightTooltip, SupplyBillBrowseItemDate, Wrapper } from './SupplyBillBrowseItem.styled';
import { SupplyBillBrowseDto } from '@/dto/taskmap/Dto';
import { SupplyConstant } from '../SupplyConstant';
import SupplyBillBrowseItemDelete, {
  SupplyBillBrowseItemDeleteProps,
} from './SupplyBillBrowseItemDelete';
import { AccessController } from '@/components/common/AccessController';
import { AccessEnum } from '@/app/auth/roles';
import { useAllResponsables } from '@/hooks/useQuery/useAllSupplyCatalogs';

interface IProps extends SupplyBillBrowseDto, SupplyBillBrowseItemDeleteProps {
  onDoubleClick: () => void;
}

const SupplyBillBrowseItem: FC<IProps> = ({
  onDoubleClick,
  billNumber,
  billDate,
  contractorId,
  billTypeId,
  id,
  updateData,
}) => {
  const { responsablesMap } = useAllResponsables();
  const ownerName = responsablesMap.get(contractorId) ?? '';
  return (
    <Wrapper onDoubleClick={onDoubleClick}>
      <div>№ {billNumber}</div>
      <LightTooltip title={ownerName}>
        <div>
          {ownerName && ownerName.length > 14 ? ownerName.substring(0, 13) + '...' : ownerName}
        </div>
      </LightTooltip>
      <SupplyBillBrowseItemDate>
        {moment(new Date(billDate)).format('DD.MM.yyyy')}

        <AccessController access={[AccessEnum.Storehouse]}>
          {(billTypeId === SupplyConstant.DRAFT_TYPE_ISSUE ||
            billTypeId === SupplyConstant.DRAFT_TYPE_RETURN ||
            billTypeId === SupplyConstant.DRAFT_TYPE_RELOCATION_POST ||
            billTypeId === SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST ||
            billTypeId === SupplyConstant.DRAFT_FACTORY_TYPE_GET) && (
            <SupplyBillBrowseItemDelete
              id={id}
              updateData={updateData}
              //TODO: временные трудности на бэке
              disabled={billTypeId === SupplyConstant.DRAFT_TYPE_RELOCATION_POST}
            />
          )}
        </AccessController>
      </SupplyBillBrowseItemDate>
    </Wrapper>
  );
};

export default SupplyBillBrowseItem;
