import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useState } from 'react';
import { GSMInfoItem } from '@/components/GSM/GSMInfoItem';
import { SimChanges } from '@/dto/taskmap/task/TaskResultDto';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { color } from '@/styles/mixins';
import { FormProvider, useForm } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';
import { TextField } from '@/components/fixUI';
import { AccessPopup } from '../popups/AccessPopup/AccessPopup';
import { PutSimChange, СancelSimChange } from '@/services/GSMService';
import { useTaskEditorOnlyShow } from '../features/taskEditor/useTaskEditorOnlyShow';

export const GSMItemNew: FC<SimChanges> = (props) => {
  const onlyShow = useTaskEditorOnlyShow();
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [isOpenCancel, setIsOpenCancel] = useState<boolean>(false);
  const id = props.id;
  const [simChange, setSimChange] = useState<SimChanges | any>(props);

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const { fetchCatch, addActionLog } = useActionLog();

  const methods = useForm();
  const { register, handleSubmit, reset, setValue } = methods;

  const handleCloseEdite = () => {
    setIsOpenEdit(false);
    setValue(`${id}.type`, undefined);
    setValue(`${id}.iccid`, undefined);
  };
  const handleSuccessEdite = (data: any) => {
    PutSimChange(id, data[id])
      .then((res) => {
        addActionLog(ActionLogType.SUCCESS, 'SIM-карта изменена');
        setSimChange(res.data);
      })
      .catch((res) => {
        fetchCatch(res, 'Ошибка изменения SIM-карты');
      })
      .finally(handleCloseEdite);
  };

  const handleCloseCancel = () => {
    setIsOpenCancel(false);
  };
  const handleSuccessCancel = () => {
    СancelSimChange(id)
      .then((res) => {
        addActionLog(ActionLogType.SUCCESS, 'SIM-карта отменена');
        setSimChange(res.data);
      })
      .catch((res) => {
        fetchCatch(res, 'Ошибка отмены SIM-карты');
      })
      .finally(handleCloseCancel);
  };

  return (
    <Box sx={{ p: 2, border: 'dashed rgb(152, 152, 152)', position: 'relative' }}>
      <>
        <AccessPopup
          isOpen={isOpenCancel}
          onClose={handleCloseCancel}
          onSuccess={handleSuccessCancel}
          titleText={'Отменить SIM-карту'}
          closeText={'Нет'}
          successText={'Да'}
        />

        <AccessPopup
          isOpen={isOpenEdit}
          onClose={handleCloseEdite}
          onSuccess={handleSubmit(handleSuccessEdite)}
          titleText={'Заполните данные для изменения SIM-карты'}
          closeText={'Отмена'}
          successText={'Сохранить'}
        >
          <FormProvider {...methods}>
            <TextField
              id='ICCID'
              label='ICCID'
              variant='standard'
              fullWidth
              {...register(`${id}.iccid`)}
            />
            <AutocompleteValue
              title='Тип'
              fieldName={`${id}.type`}
              values={getCatalogMapWithErr('simType')}
            />
          </FormProvider>
        </AccessPopup>
        <BorderColorRoundedIcon
          sx={{
            color: color('muiBlue'),
            fontSize: 22,
            cursor: onlyShow ? '' : 'pointer',
            position: 'absolute',
            top: 5,
            right: 5,
          }}
          onClick={() => !onlyShow && setIsOpenEdit(true)}
        />
        <Button
          sx={{
            position: 'absolute',
            bottom: 5,
            right: 5,
          }}
          variant='text'
          size='small'
          onClick={() => setIsOpenCancel(true)}
          disabled={onlyShow}
        >
          отменить
        </Button>

        <Grid
          container
          spacing={1}
        >
          <GSMInfoItem
            title={'Статус'}
            value={
              simChange?.installStatus
                ? getCatalogMapWithErr('simChangeStatus').get(simChange.installStatus) ?? ''
                : ''
            }
          />
          <GSMInfoItem
            title={'SIM'}
            value={
              (simChange?.sim?.operator
                ? getCatalogMapWithErr('simOperator').get(simChange.sim.operator) ?? ''
                : '') +
              ' ' +
              (simChange?.sim?.number ?? '')
            }
          />
          <GSMInfoItem
            title={'Тип'}
            value={
              simChange?.simType ? getCatalogMapWithErr('simType').get(simChange.simType) ?? '' : ''
            }
          />
          <GSMInfoItem
            title={'ICCID'}
            value={simChange?.iccid ?? ''}
          />
          <GSMInfoItem
            title={'IP'}
            value={simChange?.sim?.ip ?? ''}
          />
        </Grid>
      </>
    </Box>
  );
};
