import { FC } from 'react';

interface IHeaderTitle {
  type?: string;
  contractTitle?: string;
  number?: number;
  status?: string;
}

export const HeaderTitle: FC<IHeaderTitle> = ({ type, number, contractTitle, status }) => {
  return (
    <>
      {type || '(ТИП НЕ ЗАДАН)'} №{number} {contractTitle}: {status || '(СТАТУС НЕ ЗАДАН)'}
    </>
  );
};
