import { color } from '@/styles/mixins';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { RemoveTagIcon } from '@/components/Tags/RemoveTagIcon';

const FONT = {
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 400,
  textDecoration: 'none',
  lineHeight: '24px',
};

export const TagWrapper = styled('div')({
  backgroundColor: color('gray3'),
  padding: '5px 10px',
  borderRadius: '10px',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '5px',
  minWidth: '76px',
});
export const TagName = styled('div')({
  color: color('black1'),
  ...FONT,
});
