import { styled } from '@mui/material';

export const FolderImg = styled('img')({
  width: 105,
  height: 105,
  objectFit: 'contain',
  objectPosition: 'center',
});

export const FolderWrapper = styled('div')({
  width: 123,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
