import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { scrollbarThin } from '@/styles/mixins';

export const TaskEditorHeightWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  gap: 16,
  paddingTop: 32,
  paddingLeft: 16,
  height: '100vh',
});

export const TaskEditorScrollWrapper = styled(Grid)({
  height: '100%',
  overflow: 'auto',
});

export const TaskEditorScrollWrapperMain = styled(TaskEditorScrollWrapper)`
  ${scrollbarThin}
`;

export const TaskEditorImagePannelWrapper = styled(Grid)({
  height: '100%',
});
