import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Autocomplete, Grid, TextField } from '@mui/material';
import * as React from 'react';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';
import { CallCenterBashkortostanRequest, CallCenterScheduledDateDto } from '@/dto/taskmap/Dto';
import {
  GetCallCenterRequestBashkortostanbyTaskId,
  UpdateCallCenterRequestScheduledDate,
} from '@/services/YodaRestService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { toFormateDate } from '@/utils/heplers';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '70%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

interface CallTypeWithCommentModalProps {
  requestId: number;
  data: string;
  callStatuses: SelectFilterOptType[];
  refreshParentFunc: () => void;
}

export const CallTypeWithCommentModal = (props: CallTypeWithCommentModalProps) => {
  const { catchError } = useActionLog();
  const { data } = props;
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [scheduledDate, setScheduleDate] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [selectedCallStatus, setSelectedCallStatus] = useState<SelectFilterOptType | null>({
    value: 'NEED_TO_CALL_BACK',
    label: 'Нужно перезвонить',
  });

  const clearFields = () => {
    setSelectedCallStatus({ value: 'NEED_TO_CALL_BACK', label: 'Нужно перезвонить' });
    setScheduleDate('');
    setComment('');
  };

  const closeModal = () => {
    setOpen(false);
  };

  const [ccRequest, setCcRequest] = useState<CallCenterBashkortostanRequest>();

  useEffect(() => {
    if (props.requestId) {
      GetCallCenterRequestBashkortostanbyTaskId(props.requestId + '')
        .then((data) => {
          setCcRequest(data.data);
        })
        .catch((errors) => catchError('Ошибка загрузки данных', errors));
    }
  }, [open]);

  const saveComment = () => {
    if (scheduledDate && scheduledDate != '') {
      const commentRequest: CallCenterScheduledDateDto = {
        requestId: props.requestId,
        scheduledOn: scheduledDate ? scheduledDate : '',
        callStatus: selectedCallStatus ? selectedCallStatus.value : '',
        comment: comment ? comment : '',
      };
      UpdateCallCenterRequestScheduledDate(commentRequest)
        .then((res) => {
          setOpen(false);
          props.refreshParentFunc();
          clearFields();
        })
        .catch((errors) => {
          catchError('Возникла ошибка при сохранении!', errors);
        });
    } else {
      catchError('Заполните обязательные поля', null);
    }
  };

  return (
    <div style={{ minWidth: '150px', blockSize: 'f' }}>
      <Button
        onClick={handleOpen}
        fullWidth={true}
        style={{ textAlign: 'left', height: '100%' }}
      >
        {data + ''}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
          >
            Выберите дату следующего звонка
          </Typography>
          <Grid
            container
            spacing={4}
          >
            <Grid
              container
              item
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginTop: '1%',
              }}
            >
              <TextField
                sx={{ minWidth: 200 }}
                id='plan-date-field'
                type='date'
                label='Дата следующего звонка'
                InputLabelProps={{ shrink: true }}
                value={scheduledDate}
                variant='outlined'
                error={!scheduledDate}
                onChange={(e) => setScheduleDate(e.target.value)}
              />
              <Grid
                style={{ marginLeft: 25 }}
                item
              >
                <Autocomplete
                  disablePortal
                  sx={{ minWidth: 300 }}
                  id='executor-select'
                  value={selectedCallStatus}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  options={props.callStatuses}
                  onChange={(e, val) => {
                    setSelectedCallStatus(val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Статус звонка'
                      error={!selectedCallStatus}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                multiline
                rows={4}
                id='comment-field'
                label='Комментарий'
                value={comment}
                variant='outlined'
                onChange={(e) => setComment(e.target.value)}
              />
            </Grid>
            <Grid
              item
              container
              spacing={2}
            >
              {ccRequest?.callCenterComments &&
                ccRequest.callCenterComments.map((item) => (
                  <TextField
                    key={Math.random() + ''}
                    label={'Комментарий ' + toFormateDate(new Date(item.createdOn))}
                    value={item.value}
                    variant={'outlined'}
                    fullWidth={true}
                    sx={{ marginLeft: '2%', marginTop: '3%' }}
                  />
                ))}
            </Grid>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'end',
                marginTop: 10,
                marginLeft: '80%',
              }}
            >
              <Button
                onClick={(event) => {
                  saveComment();
                }}
              >
                Сохранить
              </Button>
              <Button
                onClick={(e) => {
                  //Clear fields
                  clearFields();
                  closeModal();
                }}
              >
                Отмена
              </Button>
            </div>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
