import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, TextField } from '@mui/material';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

interface CallTypeModalTypeOneProps {
  saveTko: (
    taskId: string,
    requestId: string,
    tkoId: string,
    comment: string,
    resultJobLog: string
  ) => any;
  tkoStatuses: SelectFilterOptType[];
  taskStatus: string;
  requestId: string;
  taskId: string;
}

export default function CallTypeModalTypeOne(props: CallTypeModalTypeOneProps) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [comment, setComment] = React.useState('');

  const [selectedTko, setSelectedTko] = React.useState<SelectFilterOptType | null>(null);

  const clearFields = () => {
    setComment('');
    setSelectedTko(null);
  };

  const save = () => {
    if (selectedTko) {
      props.saveTko(props.taskId, props.requestId, selectedTko!.value, comment, 'Создал ТКО');
      clearFields();
      setOpen(false);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>Абонент отказался</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
          >
            Укажите ФИО абонент и причину отказа
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              marginTop: 35,
              marginBottom: 15,
            }}
          >
            <div style={{ width: '100%' }}>
              <Autocomplete
                disablePortal
                fullWidth
                sx={{ minWidth: 300 }}
                id='tko-select'
                value={selectedTko}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={props.tkoStatuses}
                onChange={(e, val) => {
                  setSelectedTko(val);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!selectedTko}
                    label='Статус ТКО'
                  />
                )}
              />
            </div>
          </div>
          <TextField
            fullWidth
            multiline
            rows={4}
            id='comment-field'
            label='Комментарий'
            value={comment}
            variant='outlined'
            onChange={(e) => setComment(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'end',
              marginTop: 10,
            }}
          >
            <Button
              onClick={(e) => {
                clearFields();
                setOpen(false);
              }}
            >
              Отмена
            </Button>
            <Button
              onClick={(e) => {
                save();
              }}
            >
              Сохранить
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
