import { color, mediaBreakpointDown } from '@/styles/mixins';
import { Button, styled, TextField } from '@mui/material';

export const LoginPageFieldsWrapper = styled('div')({
  marginTop: '20vh',
  width: 520,
  marginLeft: 'auto',
  marginRight: 'auto',
  [mediaBreakpointDown('md')]: {
    width: 'auto',
    padding: 10,
  },
});

export const LoginPageButton = styled(Button)({
  marginTop: 28,
});

export const LoginPageTitle = styled('div')({
  color: color('trueBlack', 0.87),
  width: '100%',
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '32px',
});
export const LoginPageText = styled('div')({
  color: color('trueBlack', 0.6),
  width: '100%',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 400,
  marginBottom: 16,
});
export const LoginPageErrorText = styled('div')({
  color: color('muiError'),
});
export const LoginPageTextField = styled(TextField)({
  marginTop: 16,
});
