import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';

export const ActionLogItemPre = styled('pre')({
  whiteSpace: 'pre-wrap',
});

interface ActionLogItemExpandIconProps {
  hide: boolean;
}
export const ActionLogItemExpandIcon = styled(ExpandMoreIcon)<ActionLogItemExpandIconProps>(
  ({ hide }) => ({
    transform: `rotate(${hide ? 0 : 180}deg)`,
    transition: '0.3s',
    color: 'gray',
    fontSize: 22,
    cursor: 'pointer',
    marginLeft: 16,
  })
);
