import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from './fixUI';
import { formatPhoneNumberMask } from '@/utils/heplers';

interface SubscriberFormProps {
  fio?: string;
  contactPhone?: string;
}

export const SubscriberForm: FC<SubscriberFormProps> = ({ fio, contactPhone }) => {
  const methods = useFormContext();

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={9}
      >
        <Controller
          control={methods.control}
          name='fio'
          rules={{ required: true, pattern: /^[A-Za-zА-Яа-я ]+$/ }}
          render={({ field }) => (
            <TextField
              id='tf-number-tt'
              label='ФИО контактного лица'
              variant='standard'
              fullWidth
              {...field}
              error={methods?.formState?.errors?.fio?.type === 'pattern'}
              required
              InputLabelProps={{
                shrink: true,
                sx: {
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                },
              }}
            />
          )}
        />
        {methods?.formState?.errors?.fio?.type === 'required' && (
          <Typography sx={{ width: '100%' }}>Поле должно быть заполнено</Typography>
        )}
        {methods?.formState?.errors?.fio?.type === 'pattern' && (
          <Typography sx={{ width: '100%' }}>
            Поле должно содержать только буквы иили пробелы
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={3}
      >
        <Controller
          control={methods.control}
          name='contactPhone'
          rules={{
            required: true,
            // eslint-disable-next-line no-useless-escape
            pattern: /^((8|\+7)[\- ]?)(\(?\d{3}\)?[\- ]?)(\d{3}[\- ]?)(\d{2}[\- ]?)(\d{2})$/,
          }}
          render={({ field }) => (
            <TextField
              id='tf-number-tt'
              variant='standard'
              label='Номер телефона'
              fullWidth
              {...field}
              error={methods?.formState?.errors?.contactPhone?.type === 'pattern'}
              value={formatPhoneNumberMask(field.value)}
              onChange={(e) => field.onChange(formatPhoneNumberMask(e.target.value))}
              required
              InputLabelProps={{
                shrink: true,
                sx: {
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                },
              }}
            />
          )}
        />
        {methods?.formState?.errors?.contactPhone?.type === 'pattern' && (
          <Typography sx={{ width: '100%' }}>Введите номер в формате +79998887766</Typography>
        )}
        {methods?.formState?.errors?.contactPhone?.type === 'required' && (
          <Typography sx={{ width: '100%' }}>Поле должно быть заполнено</Typography>
        )}
      </Grid>
    </Grid>
  );
};
