import { BoxShadow } from '@/components/ui/BoxShadow';
import { color } from '@/styles/mixins';
import { Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { TechInspectionType } from '../types/types';
import { useState } from 'react';

type TkoForCallingMapProps = {
  techInspections: TechInspectionType[];
};

const TkoForCallingMapContainer = ({ techInspections }: TkoForCallingMapProps) => {
  const [showAll, setShowAll] = useState(false);

  const renderTechInspections = (inspections: TechInspectionType[], showAll: boolean) => {
    const visibleInspections = showAll ? inspections : inspections.slice(0, 1);

    return visibleInspections.map((tko, index) => (
      <Stack
        key={tko.id}
        width='100%'
      >
        <Stack
          style={{
            borderBottom: inspections.length > 1 ? '1px solid grey' : 'none',
            borderTopRightRadius: 0,
          }}
        >
          <Stack
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
          >
            <Typography
              variant='subtitle1'
              gutterBottom
            >
              TKO: {tko?.tkoStatusResponse?.description}
            </Typography>
            <Typography
              variant='subtitle1'
              gutterBottom
            >
              {moment(tko.eventOn).format('DD.MM.YYYY')}
            </Typography>
          </Stack>
          <Stack
            display='flex'
            flexDirection='row'
          >
            <Typography
              variant='subtitle1'
              gutterBottom
            >
              Коментарий: {tko?.comment}
            </Typography>
          </Stack>
        </Stack>
        {inspections.length > 1 && (
          <Typography
            variant='body2'
            textAlign='center'
          >
            {inspections.length - 1 === index && (
              <span onClick={() => setShowAll(false)}>скрыть &uarr;</span>
            )}
          </Typography>
        )}
      </Stack>
    ));
  };

  return (
    <BoxShadow
      border={color('trueRed')}
      p={1}
      sx={{ marginY: 1, maxWidth: 600 }}
    >
      <Grid
        container
        xs={12}
      >
        {renderTechInspections(techInspections, showAll)}
        {!showAll && techInspections.length > 1 && (
          <Stack width={'100%'}>
            <Typography
              textAlign='center'
              variant='body2'
            >
              <span onClick={() => setShowAll(!showAll)}>
                {!showAll && <span>показать еще &darr;</span>}
              </span>
            </Typography>
          </Stack>
        )}
      </Grid>
    </BoxShadow>
  );
};

export default TkoForCallingMapContainer;
