import { GridFooter, GridValidRowModel, ruRU } from '@mui/x-data-grid';
import { CatalogTypes, getColumnsWithRenderCell } from './constants';
import CloseIcon from '@mui/icons-material/Close';
import { TablePagination } from '@mui/material';
import { arrowLabel } from './utils';
import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid } from './CatalogBrowse';
import CatalogHeader from './CatalogHeader';
import { useCol } from '@/components/sections/CatalogsBrowse/storeCol';

interface ResultTableProps {
  items: GridValidRowModel[];
  isLoading: boolean;
  pageSize: number;
  currentPage: number;
  totalRows: number;
  isLoadingPag: boolean;
  setCurrentPage: (page: number) => void;
  setPageSize: (size: number) => void;
  type: CatalogTypes;
}

export const ResultTable = React.memo(
  ({
    items,
    isLoading,
    pageSize,
    setPageSize,
    setCurrentPage,
    currentPage,
    isLoadingPag,
    totalRows,
    type,
  }: ResultTableProps) => {
    const [checkedRows, setCheckedRows] = useState<string[]>([]);
    const { columns } = useCol((state) => ({
      columns: state.columns,
    }));
    const columnsWithRenderCells = useMemo(
      () => getColumnsWithRenderCell(columns[type], type),
      [columns, type]
    );

    useEffect(() => {
      function keydownHandler(ev: KeyboardEvent) {
        if (ev.code == 'KeyA' && (ev.ctrlKey || ev.metaKey)) {
          ev.preventDefault();
          setCheckedRows(items.map((elem) => elem.id));
        }
      }
      document.addEventListener('keydown', keydownHandler, false);
      return function cleanup() {
        document.removeEventListener('keydown', keydownHandler, false);
      };
    });
    return (
      <div style={{ marginTop: '10px', height: '6000px' }}>
        <DataGrid
          checkboxSelection
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          disableColumnFilter
          onSelectionModelChange={(model: any) => {
            setCheckedRows(model);
          }}
          onRowDoubleClick={(el) => {
            (type === CatalogTypes.PU || type === CatalogTypes.KOO) &&
              window.open(`/catalogs/${type}/${el.id}`);
          }}
          selectionModel={checkedRows}
          loading={isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          columns={columnsWithRenderCells}
          rows={items}
          componentsProps={{
            header: {
              type: type,
            },
          }}
          components={{
            Header: CatalogHeader,
            Footer: GridFooter,
            Pagination: () => (
              <>
                {checkedRows.length > 0 && (
                  <CloseIcon
                    style={{ marginRight: 'auto', cursor: 'pointer' }}
                    onClick={() => setCheckedRows([])}
                  />
                )}
                <TablePagination
                  count={totalRows}
                  page={currentPage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={(newPageSize) =>
                    setPageSize(Number(newPageSize.target.value))
                  }
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from} - ${to} из ${isLoadingPag ? '?' : count}`
                  }
                  onPageChange={(ev, newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  labelRowsPerPage={'Заявок на странице:'}
                  getItemAriaLabel={arrowLabel}
                />
              </>
            ),
          }}
        />
      </div>
    );
  }
);

ResultTable.displayName = 'ResultTable';
