import { HeadCell } from '../TableCells';

export const headCells: HeadCell[] = [
  {
    id: 'managementCompany',
    disablePadding: false,
    label: 'Управляющая компания',
  },
  {
    id: 'file',
    disablePadding: false,
    label: 'File',
  },
];

export const FILE_SMR_TYPE_ID = '786e0d24-3af7-8f2f-130f-3f0bb4fc6c5c';
export const FILE_SMR_STAGE_ID = '94d6f6ec-50d7-c896-78cf-072eeabd214e';
