import fileExl from '@/images/file/fileExl.png';
import filePdf from '@/images/file/filePdf.png';

export interface DriveItem {
  id: string;
  name: string;
}

export const driveMapToArray = (map: any): DriveItem[] =>
  Object.keys(map).map((elem) => ({ id: elem, name: map[elem] }));

export enum FileType {
  PDF,
  EXL,
}

export const convertStr = (str: string): string =>
  `${str.substring(6, 10)}-${str.substring(3, 5)}-${str.substring(0, 2)}`;

export const getType = (name: string) => {
  const nameArr = name.toLowerCase().split('.');
  const typeStr = nameArr[nameArr.length - 1];
  if (typeStr === 'xlsx') {
    return FileType.EXL;
  }
  if (typeStr === 'pdf') {
    return FileType.PDF;
  }
  return FileType.PDF;
};

export const getImgByType = (type: FileType) => {
  switch (type) {
    case FileType.EXL:
      return fileExl;
    case FileType.PDF:
      return filePdf;
    default:
      return;
  }
};
