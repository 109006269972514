import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useAllCacheNames } from '@/hooks/useQuery/useAllSupplyCatalogs';
import { SupplyBillApiCacheInvalidate } from '@/services/SupplyBillService';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { StorehouseAdminPuWrapper } from '../StorehouseAdminPu/StorehouseAdminPu.styled';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';

const StorehouseAdminCache = () => {
  const { setIsInProgress } = useScreenHoldHook();
  const { cacheNamesOptions } = useAllCacheNames();
  const { catchError, addActionLog } = useActionLog();

  const [cacheClear, setCacheClear] = useState<SelectFilterOptType[]>([]);

  const handleChange = (newValues: SelectFilterOptType[]) => setCacheClear(newValues);
  const handleClear = () => {
    if (!cacheClear.length) return;
    setIsInProgress(true);

    SupplyBillApiCacheInvalidate(cacheClear?.map((el) => el.value))
      .then(() => addActionLog(ActionLogType.SUCCESS, `Кеш очищен`))
      .catch((err) => catchError('Ошибка очистки кешей', err))
      .finally(() => {
        setIsInProgress(false);
        setCacheClear([]);
      });
  };

  return (
    <StorehouseAdminPuWrapper>
      <MultiSelect
        options={cacheNamesOptions}
        value={cacheClear}
        onlyInListArgument
        label={'Название кеша'}
        onChange={handleChange}
      />

      <Button
        variant='contained'
        onClick={handleClear}
      >
        Очистить
      </Button>
    </StorehouseAdminPuWrapper>
  );
};

export default StorehouseAdminCache;
