import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Switch } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import {
  TasksBrowseDragItem,
  TasksBrowseDragIconWrapper,
  TasksBrowseDragItemControllLabel,
  TasksBrowseDragItemWidthIcon,
  TasksBrowseDragItemWidthIconWrapper,
  TasksBrowseDragItemWidthIconButton,
} from './TasksBrowseDragOrder.styled';
import { useCol } from '../storeCol';
import { getNextIndex } from './utils';

interface TasksBrowseDragOrderItemProps {
  column: GridColDef;
  index: number;
}

const WIDTH_TYPES = [
  { columnWidth: 140, iconWidth: 8 },
  { columnWidth: 200, iconWidth: 12 },
  { columnWidth: 600, iconWidth: 18 },
];

const TasksBrowseDragOrderItem = ({ column, index }: TasksBrowseDragOrderItemProps) => {
  const [width, setWidth] = useState(1);
  const { columns, setCurrentColumns } = useCol((state) => ({
    columns: state.columns,
    setCurrentColumns: state.setCurrentColumns,
  }));

  useEffect(() => {
    const widthIndex = WIDTH_TYPES.findIndex((width) => width.columnWidth === column.width);
    if (widthIndex > -1) {
      setWidth(widthIndex);
    }
  }, [column]);

  const handleChangeVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentColumns(
      columns.taskBrowseCol.map((column) => {
        if (column.field === event.target.name) {
          return { ...column, hide: !event.target.checked };
        } else return column;
      })
    );
  };

  const handleChangeWidth = (fieldName: string) => {
    setWidth(getNextIndex(WIDTH_TYPES, width));
    setCurrentColumns(
      columns.taskBrowseCol.map((column) => {
        if (column.field === fieldName) {
          return {
            ...column,
            width: WIDTH_TYPES[getNextIndex(WIDTH_TYPES, width)].columnWidth,
          };
        } else return column;
      })
    );
  };

  return (
    <Draggable
      draggableId={column.field}
      index={index}
    >
      {(provided) => (
        <TasksBrowseDragItem
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <TasksBrowseDragIconWrapper {...provided.dragHandleProps}>
            <DragHandleIcon />
          </TasksBrowseDragIconWrapper>

          <TasksBrowseDragItemWidthIconButton onClick={() => handleChangeWidth(column.field)}>
            <TasksBrowseDragItemWidthIconWrapper>
              <TasksBrowseDragItemWidthIcon columnWidth={WIDTH_TYPES[width].iconWidth} />
            </TasksBrowseDragItemWidthIconWrapper>
          </TasksBrowseDragItemWidthIconButton>

          <TasksBrowseDragItemControllLabel
            control={
              <Switch
                checked={!column.hide}
                onChange={handleChangeVisibility}
                name={column.field}
              />
            }
            label={column.headerName}
          />
        </TasksBrowseDragItem>
      )}
    </Draggable>
  );
};

export default TasksBrowseDragOrderItem;
