import React, { useMemo } from 'react';
import { Button, TablePagination, Tooltip } from '@mui/material';
import { ruRU } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import {
  HousePnrBrowseDataGridWrapper,
  HousePnrBrowseCloseIconButton,
} from './HousePnrBrowse.styled';
import { getColumns } from './utils';
import shallow from 'zustand/shallow';
import { usePnrBrowseStore } from './store';
import { HouseDataBrowseDto } from '@/dto/taskmap/Dto';
import { RolesEnum } from '@/app/auth/roles';
import { useAllowedAccessByRole } from '@/hooks/useAccess';

export interface HousePnrBrowseDataGridProps {
  houseData: HouseDataBrowseDto[];
  houseDataCount: number;
  pageSize: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
}

const HousePnrBrowseDataGrid = ({
  houseData,
  houseDataCount,
  pageSize,
  page,
  setPage,
  setPageSize,
  loading,
}: HousePnrBrowseDataGridProps) => {
  const {
    openEditAddressModal,
    openMergeAddressModal,
    selectedRowsData,
    setSelectedRowsData,
    resetSelection,
  } = usePnrBrowseStore(
    (state) => ({
      openEditAddressModal: state.openEditAddressModal,
      openMergeAddressModal: state.openMergeAddressModal,
      selectedRowsData: state.selectedRowsData,
      setSelectedRowsData: state.setSelectedRowsData,
      resetSelection: state.resetSelection,
    }),
    shallow
  );

  const allowMergeAdresses = useAllowedAccessByRole({
    allowRoles: [RolesEnum.MergeHouse],
  });

  const selectedRowsHouseIds = useMemo(
    () => selectedRowsData.map((row) => row.houseId),
    [selectedRowsData]
  );

  const columns = React.useMemo(() => getColumns(), []);

  const handleSelectionChange = (newSelection: string[]) => {
    // Удаляем повторно нажатые строки
    const updatedSelectedRows = selectedRowsData.filter((row) =>
      newSelection.includes(row.houseId)
    );

    // Добавляем новые выбранные строки, которых еще нет в selectedRowsData
    const newlySelectedRows = newSelection
      .map(
        (id) =>
          houseData.find((house) => house.houseId === id) ||
          selectedRowsData.find((el) => el.houseId === id)
      )
      .filter(Boolean) as HouseDataBrowseDto[];

    const finalSelectedRows = [...updatedSelectedRows, ...newlySelectedRows].filter(
      (row, index, self) => self.findIndex((r) => r.houseId === row.houseId) === index
    );

    setSelectedRowsData(finalSelectedRows);
  };

  return (
    <div>
      <Tooltip
        title={selectedRowsHouseIds?.length !== 1 ? 'Выберите 1 адрес для редактирования' : ''}
      >
        <span>
          <Button
            disabled={selectedRowsHouseIds?.length !== 1}
            onClick={openEditAddressModal}
          >
            Редактировать адрес
          </Button>
        </span>
      </Tooltip>

      <Tooltip title={selectedRowsHouseIds?.length < 2 ? 'Выберите 2 адреса для объединения' : ''}>
        <span>
          <Button
            disabled={!allowMergeAdresses || selectedRowsHouseIds?.length < 2}
            onClick={openMergeAddressModal}
          >
            Объединить адреса
          </Button>
        </span>
      </Tooltip>

      <HousePnrBrowseDataGridWrapper
        checkboxSelection
        keepNonExistentRowsSelected
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row.houseId}
        rows={[
          ...selectedRowsData,
          ...(houseData
            ? houseData.filter((el) => !selectedRowsData?.find((f) => el.houseId === f.houseId))
            : []),
        ]}
        columns={columns}
        loading={loading}
        components={{
          Pagination: () => (
            <>
              {selectedRowsHouseIds.length > 0 && (
                <HousePnrBrowseCloseIconButton onClick={resetSelection}>
                  <CloseIcon />
                </HousePnrBrowseCloseIconButton>
              )}
              <TablePagination
                count={houseDataCount}
                page={page}
                rowsPerPage={pageSize}
                onRowsPerPageChange={(newPageSize) => setPageSize(Number(newPageSize.target.value))}
                onPageChange={(ev, newPage) => setPage(newPage)}
                rowsPerPageOptions={[5, 10, 25, 50]}
                labelRowsPerPage={'Заявок на странице:'}
              />
            </>
          ),
        }}
        selectionModel={selectedRowsHouseIds}
        onSelectionModelChange={(newSelection: any) => handleSelectionChange(newSelection)}
      />
    </div>
  );
};

export default HousePnrBrowseDataGrid;
