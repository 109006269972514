import React from 'react';
import { AccessEnum } from '@/app/auth/roles';
import { useAccess } from '@/hooks/useAccess';
import ComplaintsMainScreen from '@/components/features/complaints/ComplaintsMainScreen/ComplaintsMainScreen';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const ComplaintsPage = () => {
  const hasAccess = useAccess({
    access: [AccessEnum.ComplaintsPage],
  });
  useMetaTitle('Жалобы');
  return <>{hasAccess && <ComplaintsMainScreen />}</>;
};

export default ComplaintsPage;
