import { color } from '@/styles/mixins';
import { styled } from '@mui/material';

interface BottomPaginationPnrButtonProps {
  selected?: boolean;
}

export const BottomPaginationPnrButton = styled('div')<BottomPaginationPnrButtonProps>(
  ({ selected }) => ({
    position: 'relative',
    borderRadius: '20px',
    padding: '10px 15px',
    transition: 'background 0.4s',
    marginLeft: 5,
    marginRight: 5,
    cursor: 'pointer',

    background: selected ? color('gray', 0.1) : undefined,

    '&:hover': {
      background: color('gray', 0.2),
    },
  })
);

export const BottomPaginationPnrButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'baseline',
  alignItems: 'center',
});
