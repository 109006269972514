import { aggregateDynamicData, canculateCellSum, highlightTotal, makeTreeStructure } from './utils';
import { IRPData, ISettings } from './types';
import React, { useEffect, useState } from 'react';
import { useHackedDataGridPro } from '@/hooks/useHackedDataGridPro';
import { ruRU } from '@mui/x-data-grid';
import { DataGrid } from './StatisticWithDateForRP.styled';
import { Typography } from '@mui/material';

type DynamicPivotTablePropsType = {
  data: IRPData[];
  settings: ISettings;
  isLoadingData: boolean;
};
const DynamicPivotTable = ({ data, settings, isLoadingData }: DynamicPivotTablePropsType) => {
  useHackedDataGridPro();
  const [choosenCells, setChoosenCells] = useState<Record<string, any>>({});
  const { rows, columns, parents } = aggregateDynamicData(data, settings, choosenCells);
  const headerColumnHeight = settings.columnFields.length * 50;
  useEffect(() => {
    if (!data.length) setChoosenCells({});
  }, [data]);

  return (
    <>
      <DataGrid
        defaultGroupingExpansionDepth={settings.rowFields.length}
        {...makeTreeStructure(settings, parents)}
        loading={isLoadingData}
        columns={columns}
        rows={rows}
        showCellRightBorder
        showColumnRightBorder
        disableColumnMenu
        hideFooter
        getRowClassName={highlightTotal}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        headerHeight={headerColumnHeight}
        onCellClick={(data) => canculateCellSum(data, setChoosenCells)}
      />
      <Typography variant='subtitle2'>
        Сумма выбранных ячеек: {new Intl.NumberFormat('ru-RU').format(choosenCells?.value || 0)}
      </Typography>
    </>
  );
};

export default DynamicPivotTable;
