import { AxiosResponse } from 'axios';
import { HOST } from './YodaRestService';
import { axiosGet, axiosPatch, axiosPost } from './helpers';
import { TaskTransformator, WarrantyWorkType } from '@/dto/taskmap/Dto';

export async function getWaranty(taskId: string): Promise<AxiosResponse<WarrantyWorkType[], any>> {
  const url = HOST + `/rest/warranty-work/task/${taskId}`;
  return axiosGet(url);
}

export async function getAutomaticBreaker(
  contractId: string
): Promise<AxiosResponse<{ id: string; automaticBreakerName: string }[], any>> {
  const url = HOST + `/rest/automatic-breaker/contract/${contractId}`;
  return axiosGet(url);
}

export async function updateWaranty(
  id: string,
  data: any
): Promise<AxiosResponse<WarrantyWorkType, any>> {
  const url = HOST + `/rest/warranty-work/${id}`;
  return axiosPatch(url, data);
}
export async function canceledWaranty(id: string): Promise<AxiosResponse<WarrantyWorkType, any>> {
  const url = HOST + `/rest/warranty-work/${id}/canceled`;
  return axiosPost(url, {});
}
export async function upDateTaskTransformer(
  body: TaskTransformator
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/task-transformer`;
  return axiosPost(url, body);
}
