import { Autocomplete } from '@mui/material';
import { TextField } from '@/components/fixUI';

interface ZoningAutocompleteProps {
  values: any;
  onChange: any;
  title: string;
  value: any;
}

export const ZoningAutocomplete = (props: ZoningAutocompleteProps) => {
  const defaultProps = (values: any) => {
    return {
      options: Array.from(values?.keys()).sort(),
      getOptionLabel: (option: any) => values.get(option) || '',
    };
  };

  const { values, onChange, title, value } = props;

  return (
    <Autocomplete
      {...defaultProps(values)}
      key={`zoning-filter-autocompelte-${title}`}
      id={`look-up-autocomplete-value`}
      onChange={(event: any, newValue: any) => onChange(newValue)}
      isOptionEqualToValue={(option, value) => option === value}
      value={value || null}
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          variant='standard'
        />
      )}
    />
  );
};
