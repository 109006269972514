import { Grid, styled } from '@mui/material';

export const MeterChangeReworkRow = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  position: 'relative',
});

export const MeterChangeReworkWrapper = styled(Grid)({
  height: 410,
});
