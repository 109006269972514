import {
  Accordion,
  AccordionDetails as AccordionDetails_,
  AccordionSummary,
  Divider,
  Grid,
  Skeleton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { TaskCollCenterCommentResponse, TaskCommentResponse } from '@/dto/taskmap/task/TasksDto';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import { findAllTaskCommentById, saveTaskComment } from '@/services/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LoadingButton } from '@mui/lab';
import { toFormateDate } from '@/utils/heplers';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { useLastComment } from '@/components/features/taskEditor/hooks/useLastComment';
import { useDisabledAll } from '@/hooks/useAccess';
import { useTaskEditorOnlyShow } from '../useTaskEditorOnlyShow';

interface TaskCommentPanelProps {
  taskId: string;
  callCenterComments?: TaskCollCenterCommentResponse[];
}

const AccordionDetails = styled(AccordionDetails_)(() => ({
  padding: 0,
}));
const GridGray = styled(Grid)(() => ({
  color: 'rgba(0, 0, 0, 0.38)',
}));
const GridGrayRight = styled(GridGray)(() => ({
  textAlign: 'right',
}));

const getItem = (comment: TaskCommentResponse | TaskCollCenterCommentResponse) => {
  return (
    <Grid
      key={`comment-${comment?.eventAt || comment?.createdOn}`}
      container
      item
      xs={12}
      spacing={1}
    >
      <Grid
        item
        xs={12}
      >
        <Divider />
      </Grid>
      <GridGray
        item
        xs={12}
      >
        {comment.author || comment.createdBy}
      </GridGray>
      <Grid
        item
        xs={12}
      >
        <Typography style={{ wordBreak: 'break-word' }}>
          {comment.message || comment.value}
        </Typography>
      </Grid>
      <GridGrayRight
        item
        xs={12}
      >
        {toFormateDate(comment.eventAt) || toFormateDate(comment.createdOn)}
      </GridGrayRight>
    </Grid>
  );
};
export const TaskCommentPanel = (props: TaskCommentPanelProps) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { taskId, callCenterComments } = props;
  const { setLastComment } = useLastComment((state) => ({ setLastComment: state.setLastComment }));

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { addActionLog, catchError } = useActionLog();
  const [comments, setComments] = useState<TaskCommentResponse[]>();
  const [message, setMessage] = useState('');

  useEffect(() => {
    getComments();
  }, [taskId]);

  useEffect(() => {
    setLastComment('');
  }, []);

  const getComments = () => {
    if (taskId) {
      setLoading(true);
      findAllTaskCommentById(taskId)
        .then((res) => {
          const last = res.data.length - 1;
          setComments(res.data);
          setLastComment(res?.data[last]?.message);
        })
        .catch((err) => catchError('Ошибка загрузки комментариев к заявке', err))
        .finally(() => setLoading(false));
    }
  };

  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  const handleSaveComment = () => {
    setSaving(true);
    saveTaskComment(taskId, message)
      .then((res) => {
        addActionLog(ActionLogType.SUCCESS, 'Комментарий сохранен');
        getComments();
      })
      .catch((err) => catchError('Ошибка при сохранении комментария', err))
      .finally(() => setSaving(false));
    setMessage('');
  };

  const onKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSaveComment();
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton animation='wave' />
      ) : (
        <BoxShadow>
          <Accordion
            elevation={0}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>Комментарии</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {comments && [...comments].map((t) => getItem(t))}
              <Grid
                container
                direction='row'
                justifyContent='space-around'
                alignItems='flex-end'
                spacing={0}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={10}
                >
                  <TextField
                    multiline
                    variant='standard'
                    label='комментарий'
                    fullWidth
                    disabled={disabledAll || onlyShow}
                    onChange={handleChange}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                >
                  {message.length > 0 && (
                    <LoadingButton
                      color='secondary'
                      loading={saving}
                      loadingPosition='start'
                      startIcon={
                        <CheckCircleOutlineIcon
                          sx={{ fontSize: 22, cursor: 'pointer' }}
                          color='success'
                          onClick={handleSaveComment}
                        />
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </BoxShadow>
      )}
      {!!callCenterComments?.length && (
        <>
          <BoxShadow>
            <Accordion
              elevation={0}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>Колл-Центр</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {callCenterComments && [...callCenterComments].map((t) => getItem(t))}
              </AccordionDetails>
            </Accordion>
          </BoxShadow>
        </>
      )}
    </>
  );
};
