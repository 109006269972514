import { SupplyStamp, SupplyPU, SupplyNetworkElement } from '@/dto/taskmap/Dto';
import { StorehouseBillType, SupplyConstant } from '../SupplyConstant';
import { SupplyPUItemConfirmState } from '../SupplyPUComponent/utils';

export interface SaveDialog {
  open: boolean;
  action: StorehouseBillType;
  stamps?: SupplyStamp[];
  pus?: SupplyPU[];
  os?: SupplyNetworkElement[];
  files?: File[];
}

export const RETURN_TEXT = 'принять возврат';
const RETURN_TEXT_ACTION = 'прием возврата';
export const ISSUE_TEXT = 'выдать подрядчику';
const ISSUE_TEXT_ACTION = 'выдачу подрядчику';
export const FACTORY_TEXT = 'принять товар';
const FACTORY_TEXT_ACTION = 'принятие товара';
export const FACTORY_TEXT_POST = 'отправить на завод';
const FACTORY_TEXT_POST_ACTION = 'отправку на завод';
export const RELOCATION_TEXT = 'выдать складу';
const RELOCATION_TEXT_ACTION = 'выдачу складу';
export const RELOCATION_TEXT_GET = 'принять перемещение';
const RELOCATION_TEXT_GET_ACTION = 'приём перемещения';

export const getAcceptActionText = (billType?: StorehouseBillType) => {
  switch (billType) {
    case SupplyConstant.FACTORY_TYPE_GET:
      return FACTORY_TEXT_ACTION;
    case SupplyConstant.ISSUED_TYPE:
      return ISSUE_TEXT_ACTION;
    case SupplyConstant.RETURNED_TYPE:
      return RETURN_TEXT_ACTION;
    case SupplyConstant.RELOCATION_TYPE_POST:
      return RELOCATION_TEXT_ACTION;
    case SupplyConstant.RELOCATION_TYPE_GET:
      return RELOCATION_TEXT_GET_ACTION;
    case SupplyConstant.FACTORY_TYPE_POST:
      return FACTORY_TEXT_POST_ACTION;

    default:
      return 'действие';
  }
};

export const getNonConfirmedListItems = (
  checkedList: (SupplyPU | SupplyNetworkElement | SupplyStamp)[]
) => {
  return checkedList.filter((pu) => checkNonConfirmedItems(pu));
};

export const checkNonConfirmedItems = (
  checkedItem: SupplyPU | SupplyNetworkElement | SupplyStamp
) => {
  if ('networkElementItems' in checkedItem) {
    if (!checkedItem.networkElementItems.length) return true;
    return checkedItem.networkElementItems.some((item) => !item.confirmed);
  }
  if ('puItems' in checkedItem) {
    const doubleNumberPu = checkedItem?.isRim;
    if (!checkedItem.puItems.length) return true;
    return checkedItem.puItems.some((item) => {
      if (doubleNumberPu) {
        return item.confirmed !== SupplyPUItemConfirmState.bothNumbers;
      } else {
        return !item.confirmed;
      }
    });
  }
  if ('stampItems' in checkedItem) {
    return !checkedItem?.confirmed;
  }
};

export const unconfirmedCheckText = (
  stampList: SupplyStamp[],
  puList: SupplyPU[],
  osList: SupplyNetworkElement[]
) => {
  let unconfirmedText = null;
  const unconfirmedItemsNames = [];
  const nonConfirmedPus = getNonConfirmedListItems(puList);
  const nonConfirmedOs = getNonConfirmedListItems(osList);
  const nonConfirmedStamps = getNonConfirmedListItems(stampList);
  if (nonConfirmedPus.length) {
    unconfirmedItemsNames.push('приборы учета');
  }

  if (nonConfirmedOs.length) {
    unconfirmedItemsNames.push('сетевое оборудование');
  }

  if (nonConfirmedStamps.length) {
    unconfirmedItemsNames.push('пломбы');
  }

  if (unconfirmedItemsNames.length) {
    unconfirmedText = `В накладной имеется неподтвержденное оборудование: ${unconfirmedItemsNames?.join(
      ', '
    )}`;
  }

  return unconfirmedText;
};
