import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { TransStationInputResponse } from '@/dto/taskmap/task/TaskResultDto';
import { getTransTRImageGroup } from '../panel/utils';
import { useGalleryData } from '@/components/Gallery/store';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { ItemInput } from './ItemInput';
import { useFormContext } from 'react-hook-form';
import { LOCK_STYLE } from './utils';
import { useStatus } from '../panel/store';

interface ItemTransformProps {
  transInput: TransStationInputResponse;
  keyStr: string;
}

export const ItemTransform: FC<ItemTransformProps> = ({ transInput, keyStr }) => {
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);
  const [lock, setLock] = useState<boolean>(false);

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(`${keyStr}.id`, transInput.id);
    setValue(`${keyStr}.name`, transInput.name);
  }, [transInput]);

  const handleClick = (e: any) => {
    e.stopPropagation();
    !lock && setImageGroups(getTransTRImageGroup(transInput));
  };
  const toggleDelete = () => {
    setValue(`${keyStr}.deleted`, !lock);
    setLock(!lock);
  };
  const { isDisabledAll } = useStatus((state) => ({
    isDisabledAll: state.isDisabledAll,
  }));
  return (
    <Box
      onDoubleClick={handleClick}
      sx={{
        borderRadius: '10px',
        border: '1px solid #EFEFEF',
        background:
          'linear-gradient(0deg, #EFEFEF, #EFEFEF), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        width: '100%',
        p: 2,
        position: 'relative',
      }}
    >
      {lock && (
        <div style={LOCK_STYLE}>
          <Button
            onClick={toggleDelete}
            disabled={isDisabledAll()}
          >
            Восстановить
          </Button>
        </div>
      )}
      <Grid
        container
        spacing={1}
        item
        direction={'column'}
      >
        <Grid
          item
          paddingTop={5}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h5'>{`${
              transInput.name ?? ''
            } Силовой трансформатор 10квА`}</Typography>
            <IconButton
              onClick={toggleDelete}
              disabled={isDisabledAll()}
            >
              <DeleteOutlineRoundedIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item>
          {transInput.tpFeeder && (
            <ItemInput
              transFeeder={transInput.tpFeeder}
              keyStr={`${keyStr}.tpFeeder`}
              isInput
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
