import { styled, TextField as TextField_ } from '@mui/material';

export const TextField = styled(TextField_)({
  '&.MuiTextField-root .MuiInputLabel-root.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    color: '#000',
    opacity: 1,
    textFillColor: 'rgba(0, 0, 0, 1)',
    cursor: 'not-allowed',
  },
  '&.MuiTextField-root [data-shrink="true"] ~ .MuiInput-root::before': {
    borderBottom: '2px solid #000000',
  },

  '&[contenteditable="false"] > .MuiInput-root::before': {
    borderBottom: '1px solid #000000 !important',
  },
});
