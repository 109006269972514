import { Box, Modal, SxProps } from '@mui/material';
import React from 'react';
import styles from './Popup.module.scss';

export interface IPopup {
  onSuccess?: () => void;
  isOpen: boolean;
  onClose: () => void;
  children?: any;
  customStyle?: SxProps;
  backdropStyles?: SxProps;
}

const style = {
  maxHeight: 'calc(100vh - 48px)',
  overflow: 'auto',
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

export const Popup: React.FC<IPopup> = ({
  isOpen,
  onClose,
  children,
  customStyle = {},
  backdropStyles,
}) => {
  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      BackdropProps={{ sx: backdropStyles }}
    >
      <Box
        className={styles.container}
        sx={{ ...style, ...customStyle }}
      >
        {children}
      </Box>
    </Modal>
  );
};
