import { HouseOrderDto, MKDNetworkElementHouseDto } from '@/dto/taskmap/Dto';

export const getMontagePlace = (
  networkElement: HouseOrderDto | MKDNetworkElementHouseDto,
  floorTypes: Map<string, string>
) => {
  if (networkElement.houseLevel === null) {
    return NOT_DEFINED_MONTAGE_PLACE;
  } else {
    if (networkElement?.houseLevel?.floorType === HouseLevelFloorType.FLOOR) {
      return `${networkElement?.houseLevel?.floorNumber?.toString()} этаж`;
    } else {
      return floorTypes.get(networkElement?.houseLevel?.floorType ?? '');
    }
  }
};

export enum NetworkPlanningTypes {
  SECONDLY = 'SECONDLY',
  FIRSTLY = 'FIRSTLY',
  PREPLAN = 'PREPLAN',
}

export enum HouseLevelFloorType {
  //чердак
  ATTIC = 'ATTIC',
  //этаж
  FLOOR = 'FLOOR',
  //подвал
  BASEMENT = 'BASEMENT',
  //не определен
  UNDEFINED = 'UNDEFINED',
}

// Используется для кривых мест установки
export const NOT_DEFINED_MONTAGE_PLACE = 'не определен';
