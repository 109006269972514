import moment from 'moment';
import { StatisticByInstallationLocationData } from './types';
import * as XLSX from 'xlsx';

const today = moment();
const thisSunday = today.clone().startOf('week').add(0, 'day');
const lastSunday = today.clone().startOf('week').add(-7, 'day');

type MontagePlaceItem = {
  montagePlace: string;
  allTaskOnePhase: number;
  allTaskThreePhase: number;
  tkoOnePhaseCount: number;
  tkoThreePhaseCount: number;
  replacementOnePhaseCount: number;
  replacementThreePhaseCount: number;
  remainderOnePhaseCount: number;
  remainderThreePhaseCount: number;
  dynamicOneWeek: number;
  dynamicTwoWeek: number;
  dynamicTotal: number;
  [key: string]: number | string;
};

type ContractorItem = {
  contractorName: string;
  montagePlaceItem: MontagePlaceItem[];
};

type ContractItem = {
  contractName: string;
  contractorItems: ContractorItem[];
  total: MontagePlaceItem[];
};

type FormattedData = {
  wholeData: Record<string, ContractItem>;
  contractorItems: ContractorItem[];
  total: MontagePlaceItem[];
};

type ContractObject = {
  contractName: string;
  contractorItems: ContractorObject[];
  total: any[]; // Замените any на нужный тип данных, если он существует
};

type ContractorObject = {
  contractorName: string;
  montagePlaceItem: MontagePlaceItem[];
};

export const columns: any[] = [
  {
    title: ' ',
    dataIndex: 'montagePlace',
    key: 'id',
    align: 'right',
    width: 200,
    render: formattedValue,
  },
  {
    title: 'Всего заявок',
    children: [
      {
        title: '1ф',
        dataIndex: 'allTaskOnePhase',
        key: 'id',
        align: 'center',
        className: 'custom-column-class-blue',
        render: formattedValue,
      },
      {
        title: '3ф',
        dataIndex: 'allTaskThreePhase',
        key: 'id',
        align: 'center',
        className: 'custom-column-class-yellow ',
        render: formattedValue,
      },
    ],
  },
  {
    title: 'ТКО',
    children: [
      {
        title: '1ф',
        dataIndex: 'tkoOnePhaseCount',
        key: 'id',
        align: 'center',
        className: 'custom-column-class-blue',
        render: formattedValue,
      },
      {
        title: '3ф',
        dataIndex: 'tkoThreePhaseCount',
        key: 'id',
        align: 'center',
        className: 'custom-column-class-yellow',
        render: formattedValue,
      },
    ],
  },
  {
    title: 'Есть замена',
    children: [
      {
        title: '1ф',
        dataIndex: 'replacementOnePhaseCount',
        key: 'id',
        align: 'center',
        className: 'custom-column-class-blue',
        render: formattedValue,
      },
      {
        title: '3ф',
        dataIndex: 'replacementThreePhaseCount',
        key: 'id',
        align: 'center',
        className: 'custom-column-class-yellow ',
        render: formattedValue,
      },
    ],
  },
  {
    title: 'Остаток всего без учета ТКО',
    children: [
      {
        title: '1ф',
        dataIndex: 'remainderOnePhaseCount',
        key: 'id',
        align: 'center',
        className: 'custom-column-class-blue',
        render: formattedValue,
      },
      {
        title: '3ф',
        dataIndex: 'remainderThreePhaseCount',
        key: 'id',
        align: 'center',
        className: 'custom-column-class-yellow ',
        render: formattedValue,
      },
    ],
  },
  {
    title: `Всего остаток на ${today.format('DD.MM.YYYY')}`,
    dataIndex: 'dynamicTotal',
    key: 'id',
    align: 'center',
    render: formattedValue,
  },
  {
    title: `Динамика за неделю ${thisSunday.format('DD.MM')}`,
    dataIndex: 'dynamicOneWeek',
    key: 'id',
    align: 'center',
    render: formattedValue,
  },
  {
    title: `Динамика за неделю ${lastSunday.format('DD.MM')}`,
    dataIndex: 'dynamicTwoWeek',
    key: 'id',
    align: 'center',
    render: formattedValue,
  },
];

export const options: any = {
  plugins: {
    datalabels: {
      color: 'black',
    },

    title: {
      display: true,
      text: 'Всего по всем подрядчикам',
      color: 'black',
    },

    legend: {
      labels: {
        color: 'black', // Устанавливаем цвет шрифта легенды в черный
      },
    },

    tooltip: {
      // Устанавливаем положение tooltip над баром
      displayColors: false,
      backgroundColor: (color: any) => color?.tooltip?.labelColors[0]?.backgroundColor || '',
      callbacks: {},
    },
  },

  responsive: true,
  indexAxis: 'y',
  scales: {
    y: {
      stacked: true,
      ticks: {
        color: 'black', // Устанавливаем цвет текста бара в черный
      },
      grid: {
        display: false,
      },
    },
    x: {
      offset: false,
      ticks: {
        color: 'black',
      },
      stacked: true,
      grid: {
        display: false,
      },
    },
  },
};

interface LabelToPropertyMap {
  [label: string]: {
    total: string;
    apartment: string;
  };
}

const labelToPropertyMap: LabelToPropertyMap = {
  ТКО: {
    total: 'tkoOnePhaseCount',
    apartment: 'tkoThreePhaseCount',
  },
  'Есть замена': {
    total: 'replacementOnePhaseCount',
    apartment: 'replacementThreePhaseCount',
  },
  'Остаток всего без учета ТКО': {
    total: 'remainderOnePhaseCount',
    apartment: 'remainderThreePhaseCount',
  },
  default: {
    total: 'allTaskOnePhase',
    apartment: 'allTaskThreePhase',
  },
};

export const labels = ['Всего заявок', 'ТКО', 'Есть замена', 'Остаток всего без учета ТКО'];

export const getDataForCharts = (summary: MontagePlaceItem[]) => {
  return labels.map((label) => {
    const total1 = summary.find((item) => item.montagePlace === 'Всего')?.[
      labelToPropertyMap[label]?.total || labelToPropertyMap['default'].total
    ];

    const total3 = summary.find((item) => item.montagePlace === 'Всего')?.[
      labelToPropertyMap[label]?.apartment || labelToPropertyMap['default'].apartment
    ];

    const apartment1 = summary.find((item) => item.montagePlace === 'Квартира')?.[
      labelToPropertyMap[label]?.total || labelToPropertyMap['default'].total
    ];

    const apartment3 = summary.find((item) => item.montagePlace === 'Квартира')?.[
      labelToPropertyMap[label]?.apartment || labelToPropertyMap['default'].apartment
    ];

    const hall1 = summary.find((item) => item.montagePlace === 'Холл')?.[
      labelToPropertyMap[label]?.total || labelToPropertyMap['default'].total
    ];

    const hall3 = summary.find((item) => item.montagePlace === 'Холл')?.[
      labelToPropertyMap[label]?.apartment || labelToPropertyMap['default'].apartment
    ];

    const stairs1 = summary.find((item) => item.montagePlace === 'Лестница')?.[
      labelToPropertyMap[label]?.total || labelToPropertyMap['default'].total
    ];

    const stairs3 = summary.find((item) => item.montagePlace === 'Лестница')?.[
      labelToPropertyMap[label]?.apartment || labelToPropertyMap['default'].apartment
    ];

    const newItem = {
      name: label,
      total1,
      total3,
      apartment1,
      apartment3,
      hall1,
      hall3,
      stairs1,
      stairs3,
    };

    return newItem;
  });
};

export const structureTableDataFunction = (
  data: StatisticByInstallationLocationData[]
): FormattedData => {
  const montagePlaceNames = ['Квартира', 'Лестница', 'Холл'];

  const resultMap: Record<string, ContractItem> = {};

  data.forEach((mpsItem) => {
    const contractName = mpsItem.contract;
    const contractorName = mpsItem.contractor;
    const montagePlaceKey = mpsItem.montagePlace;

    if (!resultMap[contractName]) {
      resultMap[contractName] = initContractObject(contractName);
    }

    let contractorObject = resultMap[contractName].contractorItems.find(
      (contractorItem) => contractorItem.contractorName === contractorName
    );

    if (!contractorObject) {
      contractorObject = initContractorObject(contractorName);
      resultMap[contractName].contractorItems.push(contractorObject);
    }

    montagePlaceNames.forEach((name) => {
      if (!contractorObject!.montagePlaceItem.some((item) => item.montagePlace === name)) {
        contractorObject!.montagePlaceItem.push(initMontagePlaceItem(name));
      }
    });

    let montagePlaceItem = contractorObject.montagePlaceItem.find(
      (item) => item.montagePlace === montagePlaceKey
    );

    if (!montagePlaceItem) {
      montagePlaceItem = initMontagePlaceItem(montagePlaceKey);
      contractorObject.montagePlaceItem.push(montagePlaceItem);
    }

    if (mpsItem.phase === 1) {
      montagePlaceItem.tkoOnePhaseCount += mpsItem.tkoCount || 0;
      montagePlaceItem.replacementOnePhaseCount += mpsItem.replacementCount || 0;
      montagePlaceItem.remainderOnePhaseCount += mpsItem.withoutResult || 0;
      montagePlaceItem.allTaskOnePhase += mpsItem.tkoCount || 0;
      montagePlaceItem.allTaskOnePhase += mpsItem.replacementCount || 0;
      montagePlaceItem.allTaskOnePhase += mpsItem.withoutResult || 0;
    } else {
      montagePlaceItem.tkoThreePhaseCount += mpsItem.tkoCount || 0;
      montagePlaceItem.replacementThreePhaseCount += mpsItem.replacementCount || 0;
      montagePlaceItem.remainderThreePhaseCount += mpsItem.withoutResult || 0;
      montagePlaceItem.allTaskThreePhase += mpsItem.tkoCount || 0;
      montagePlaceItem.allTaskThreePhase += mpsItem.replacementCount || 0;
      montagePlaceItem.allTaskThreePhase += mpsItem.withoutResult || 0;
    }

    montagePlaceItem.dynamicOneWeek += mpsItem.dynamicsForOneWeek || 0;
    montagePlaceItem.dynamicTwoWeek += mpsItem.dynamicsForTwoWeek || 0;
    montagePlaceItem.dynamicTotal =
      montagePlaceItem.allTaskThreePhase +
      montagePlaceItem.allTaskOnePhase -
      (montagePlaceItem.tkoOnePhaseCount + montagePlaceItem.tkoThreePhaseCount) -
      (montagePlaceItem.replacementOnePhaseCount + montagePlaceItem.replacementThreePhaseCount);
  });

  // Добавление объекта "Всего" к каждому месту монтажа и его вычисление
  for (const contractKey in resultMap) {
    const contractObject = resultMap[contractKey];

    for (const contractor of contractObject.contractorItems) {
      contractor.montagePlaceItem.push(initMontagePlaceItem('Всего'));

      const totalMontagePlaceItem = contractor.montagePlaceItem.find(
        (item) => item.montagePlace === 'Всего'
      );

      montagePlaceNames.forEach((name) => {
        if (name !== 'Всего') {
          const montagePlaceItem = contractor.montagePlaceItem.find(
            (item) => item.montagePlace === name
          );
          if (montagePlaceItem) {
            const keys = Object.keys(montagePlaceItem);
            keys.forEach((key) => {
              if (key !== 'montagePlace' && key !== 'id') {
                if (totalMontagePlaceItem) {
                  totalMontagePlaceItem[key] =
                    Number(totalMontagePlaceItem[key] || 0) + Number(montagePlaceItem[key] || 0);
                }
              }
            });
          }
        }
      });
    }
  }

  // Создание массива "total" на основе суммированных данных
  const totalMontagePlaceItemWithTotal = montagePlaceNames.map((name) => {
    const totalItem: MontagePlaceItem = {
      montagePlace: name,
      allTaskOnePhase: 0,
      allTaskThreePhase: 0,
      tkoOnePhaseCount: 0,
      tkoThreePhaseCount: 0,
      replacementOnePhaseCount: 0,
      replacementThreePhaseCount: 0,
      remainderOnePhaseCount: 0,
      remainderThreePhaseCount: 0,
      dynamicOneWeek: 0,
      dynamicTwoWeek: 0,
      dynamicTotal: 0,
    };

    for (const contractKey in resultMap) {
      const contractObject = resultMap[contractKey];

      for (const contractor of contractObject.contractorItems) {
        const montagePlaceItem = contractor.montagePlaceItem.find(
          (item) => item.montagePlace === name
        );

        if (montagePlaceItem) {
          const keys = Object.keys(montagePlaceItem);
          for (const key of keys) {
            if (key !== 'montagePlace' && key !== 'id') {
              totalItem[key] = (Number(totalItem[key]) || 0) + (Number(montagePlaceItem[key]) || 0);
            }
          }
        }
      }
    }

    return totalItem;
  });

  // Добавляем объект "Всего" в массив "total"

  totalMontagePlaceItemWithTotal.push({
    montagePlace: 'Всего',
    allTaskOnePhase: calculateTotalField('allTaskOnePhase'),
    allTaskThreePhase: calculateTotalField('allTaskThreePhase'),
    tkoOnePhaseCount: calculateTotalField('tkoOnePhaseCount'),
    tkoThreePhaseCount: calculateTotalField('tkoThreePhaseCount'),
    replacementOnePhaseCount: calculateTotalField('replacementOnePhaseCount'),
    replacementThreePhaseCount: calculateTotalField('replacementThreePhaseCount'),
    remainderOnePhaseCount: calculateTotalField('remainderOnePhaseCount'),
    remainderThreePhaseCount: calculateTotalField('remainderThreePhaseCount'),
    dynamicOneWeek: calculateTotalField('dynamicOneWeek'),
    dynamicTwoWeek: calculateTotalField('dynamicTwoWeek'),
    dynamicTotal: calculateTotalField('dynamicTotal'),
  });

  function calculateTotalField(fieldName: string) {
    return totalMontagePlaceItemWithTotal.reduce((sum, item) => sum + Number(item[fieldName]), 0);
  }

  totalMontagePlaceItemWithTotal.sort((a, b) => {
    const nameA = a.montagePlace.toLowerCase();
    const nameB = b.montagePlace.toLowerCase();
    return nameA.localeCompare(nameB);
  });

  // Добавление массива "total" в объект resultMap
  for (const contractKey in resultMap) {
    const contractObject = resultMap[contractKey];
    contractObject.total = totalMontagePlaceItemWithTotal;
  }
  // Сортировка montagePlaceItem по полю montagePlace
  for (const contractKey in resultMap) {
    const contractObject = resultMap[contractKey];

    for (const contractor of contractObject.contractorItems) {
      contractor.montagePlaceItem.sort((a, b) => a.montagePlace.localeCompare(b.montagePlace));
    }
  }

  const result = Object.values(resultMap)
    .reduce((accumulator, current) => {
      const contractorItems: any = current.contractorItems || [];
      return accumulator.concat(contractorItems);
    }, [])
    .sort((a: any, b: any) => a.contractorName?.localeCompare(b.contractorName))
    .map((item: any) => {
      const first = {
        montagePlace: item.contractorName,
        allTaskOnePhase: null,
        allTaskThreePhase: null,
        dynamicOneWeek: null,
        dynamicTotal: null,
        dynamicTwoWeek: null,
        remainderOnePhaseCount: null,
        remainderThreePhaseCount: null,
        replacementOnePhaseCount: null,
        replacementThreePhaseCount: null,
        tkoOnePhaseCount: null,
        tkoThreePhaseCount: null,
        perrant: true,
      };
      const a = { montagePlaceItem: [first, ...item.montagePlaceItem] };
      return { ...a };
    });

  const mergedArray = [];

  // Итерируемся по каждому элементу в исходном массиве
  for (const item of result) {
    // Итерируемся по каждому элементу внутри свойства "montagePlaceItem"
    for (const montagePlaceItem of item.montagePlaceItem) {
      // Добавляем текущий объект в объединенный массив
      mergedArray.push(montagePlaceItem);
    }
  }

  // mergedArray теперь содержит все объекты из "montagePlaceItem" в одном массиве

  const withUniqIdData = mergedArray.map((item, index) => ({ ...item, id: index.toString() }));

  const formatedData: FormattedData = {
    wholeData: resultMap,
    contractorItems: withUniqIdData,
    total: Object.values(resultMap)[0]?.total,
  };

  return formatedData;
};

function initContractObject(contractName: string): ContractObject {
  return {
    contractName,
    contractorItems: [],
    total: [],
  };
}

function initContractorObject(contractorName: string): ContractorObject {
  return {
    contractorName,
    montagePlaceItem: [],
  };
}

function initMontagePlaceItem(montagePlace: string): MontagePlaceItem {
  return {
    montagePlace,
    allTaskOnePhase: 0,
    allTaskThreePhase: 0,
    tkoOnePhaseCount: 0,
    tkoThreePhaseCount: 0,
    replacementOnePhaseCount: 0,
    replacementThreePhaseCount: 0,
    remainderOnePhaseCount: 0,
    remainderThreePhaseCount: 0,
    dynamicOneWeek: 0,
    dynamicTwoWeek: 0,
    dynamicTotal: 0,
  };
}

export const TABLE_CELL_OPTIONS = {
  index: 2,
  colSpan: 1,
  align: 'center' as const,
};

export function formattedValue(text: string | number, record: any = null) {
  if (record?.perrant) {
    return <span style={{ fontWeight: 'bold' }}>{text}</span>;
  } else if (typeof text === 'string') {
    return text;
  }
  const formattedNumber = new Intl.NumberFormat('ru-RU').format(+text || 0);
  return formattedNumber;
}

export const generateStatiscByInstallationLocationExcelFile = (data: any) => {
  const columns = [
    { field: 'montagePlace', headerName: '' },
    { field: 'allTaskOnePhase', headerName: '1ф Всего заявок' },
    { field: 'allTaskThreePhase', headerName: '3ф Всего заявок' },
    { field: 'tkoOnePhaseCount', headerName: '1ф ТКО' },
    { field: 'tkoThreePhaseCount', headerName: '3ф ТКО' },
    { field: 'replacementOnePhaseCount', headerName: '1ф Есть замена' },
    { field: 'replacementThreePhaseCount', headerName: '3ф Есть замена' },
    { field: 'remainderOnePhaseCount', headerName: '1ф Остаток всего без учета ТКО' },
    { field: 'remainderThreePhaseCount', headerName: '3ф Остаток всего без учета ТКО' },
    { field: 'dynamicTotal', headerName: `Всего остаток на ${today.format('DD.MM.YYYY')}` },
    { field: 'dynamicOneWeek', headerName: `Динамика за неделю ${thisSunday.format('DD.MM')}` },
    { field: 'dynamicTwoWeek', headerName: `Динамика за неделю ${lastSunday.format('DD.MM')}` },
  ];

  const dataForExcel: (string | number)[][] = [columns.map((column) => column.headerName)];

  data.forEach((row: any) => {
    dataForExcel.push(columns.map((column) => row[column.field])); // Excel data rows
  });

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(dataForExcel);
  worksheet['!cols'] = columns.map((_, index) => ({ wpx: !index ? 350 : 180 })); //ширина колонок

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Лист1');
  XLSX.writeFile(workbook, `${'Статистика по местам установки'}.xlsx`);
};
