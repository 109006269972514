import React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { FormControl, IconButton, Menu, Tooltip } from '@mui/material';
import TocIcon from '@mui/icons-material/Toc';

import { reorder } from './utils';
import { useCol } from '../storeCol';
// import { ITasksBrowse } from '../TasksBrowseNew';
import { useAnchorElement } from '@/hooks/useAnchorElement';
// import { useColumnsWithAccess } from '@/hooks/useColumnsWithAccess';
import CatalogsBrowseDragOrderItem from './CatalogsBrowseDragOrderItem';
import { CatalogTypes } from '@/components/sections/CatalogsBrowse/constants';

type CatalogsBrowseDragOrderProps = { type: CatalogTypes };

const CHECKBOX_COLUMN = '__check__';

const CatalogsBrowseDragOrder = ({ type }: CatalogsBrowseDragOrderProps) => {
  const { handleOpen, handleClose, open, anchorEl } = useAnchorElement();
  // const { colmnsWithAccessAndRenderCell } = useColumnsWithAccess(type);
  const { setCurrentColumns, columns } = useCol((state) => ({
    setCurrentColumns: state.setCurrentColumns,
    columns: state.columns,
  }));
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    const newItems = reorder(columns[type], source.index, destination.index);
    setCurrentColumns(newItems, type);
  };

  return (
    <FormControl
      component='fieldset'
      variant='standard'
    >
      <Tooltip title='Настройка столбцов'>
        <IconButton onClick={handleOpen}>
          <TocIcon />
        </IconButton>
      </Tooltip>

      <DragDropContext onDragEnd={onDragEnd}>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <Droppable droppableId='droppable-list'>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {columns[type]?.map((column, index) => {
                  if (column.field === CHECKBOX_COLUMN) return null;
                  return (
                    <CatalogsBrowseDragOrderItem
                      column={column}
                      index={index}
                      key={column.field}
                      type={type}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Menu>
      </DragDropContext>
    </FormControl>
  );
};

export default React.memo(CatalogsBrowseDragOrder);
