import { GeoTaskInBounds } from '@/dto/taskmap/Dto';
import { useGeoQuery } from '@/components/features/pnr/hooks/GeoQueryHook';
import { useResArea } from '@/components/maps/pnrMap/hooks/ResAreaHook';
import { TaskScopePlacemark } from '../placemark/TaskScopePlacemark';

export const ScopePlacemarkLayout = () => {
  const { currentGeoResponse } = useGeoQuery();
  const { isResAreaSelected } = useResArea();

  return (
    <>
      {currentGeoResponse?.geoTaskInBoundsList
        .filter((t) => isResAreaSelected(t.networkResArea))
        .map((t: GeoTaskInBounds, i: number) => {
          return (
            <TaskScopePlacemark
              key={i}
              geoTaskInBounds={t}
            ></TaskScopePlacemark>
          );
        })}
    </>
  );
};
