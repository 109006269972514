import { mediaBreakpointDown, font } from '@/styles/mixins';
import { styled, Grid, Tab, Button } from '@mui/material';

export const WrapperForActionButtons = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  [mediaBreakpointDown('md')]: {
    flexDirection: 'column',
  },
});
export const WrapperForReports = styled('div')({
  [mediaBreakpointDown('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
});
