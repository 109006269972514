import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useCatalog } from '@/hooks/CatalogHook';
import moment from 'moment';
import { CombinedDto } from './ReplacemetnViewDialog';
import { useActionLog } from '@/hooks/ActionLogHook';

interface ReplacementViewItemProps {
  replacementResponse: CombinedDto;
  handleOk(id?: string): void;
  serchByTaskId: boolean;
  taskId: string;
  swapByTaskId(taskId?: string): void;
}

export const ReplacementViewItem = (props: ReplacementViewItemProps) => {
  const { getCatalogMapWithErr, taskGroupMap } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    taskGroupMap: state.taskGroupMap,
  }));
  const { handleOk, serchByTaskId, replacementResponse, taskId, swapByTaskId } = props;
  const { catchError } = useActionLog();
  const handleClick = () => {
    handleOk(replacementResponse?.id);
  };

  return (
    <Box>
      <Grid
        container
        alignItems='center'
        spacing={3}
      >
        {serchByTaskId ? (
          <>
            <Grid
              item
              xs={1}
            >
              {(replacementResponse?.status && <DoneIcon color='success'></DoneIcon>) || (
                <HighlightOffIcon color='error'>{replacementResponse?.status}</HighlightOffIcon>
              )}
            </Grid>
            {replacementResponse?.actDate ||
              (replacementResponse?.completionDate && (
                <Grid
                  item
                  xs={2}
                >
                  <Typography variant='caption'>
                    {moment(
                      replacementResponse?.actDate || replacementResponse?.completionDate
                    ).format('HH:mm:ss / DD:MM:yyyy')}
                  </Typography>
                </Grid>
              ))}

            <Grid
              item
              xs={8}
            >
              <Tooltip title={replacementResponse?.actAgentName || ''}>
                <Typography noWrap> адресс: {replacementResponse?.addressTitle || '-'} </Typography>
              </Tooltip>
              <Tooltip title={replacementResponse?.actAgentName || ''}>
                <Typography noWrap>
                  {' '}
                  бригада: {taskGroupMap.get(replacementResponse?.executorId || '')}{' '}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent={'center'}
            >
              <Grid>
                <Button
                  variant='text'
                  onClick={() => swapByTaskId(taskId)}
                >
                  выбрать
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              {(replacementResponse?.actual && <DoneIcon color='success'></DoneIcon>) || (
                <HighlightOffIcon color='error'></HighlightOffIcon>
              )}
            </Grid>
            <Grid
              item
              xs={2}
            >
              <Typography mb={-1}>
                <strong>
                  {getCatalogMapWithErr('changeStatus').get(replacementResponse?.status || '')}
                </strong>
              </Typography>
              <Typography variant='caption'>
                {moment(replacementResponse?.replacedOn).format('HH:mm:ss / DD:MM:yyyy')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
            >
              <Tooltip title={replacementResponse?.actAgentName || ''}>
                <Typography noWrap> ФИО: {replacementResponse?.actAgentName || '-'} </Typography>
              </Tooltip>
              <Tooltip title={replacementResponse?.mounted?.montagePlace || ''}>
                <Typography
                  noWrap
                  variant='subtitle2'
                >
                  Место установки: {replacementResponse?.mounted?.montagePlace || '-'}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={2}
            >
              <Typography
                noWrap
                variant='subtitle2'
              >
                Смонтированный ПУ: {replacementResponse?.mounted?.mac || '-'}
              </Typography>
              <Typography
                noWrap
                variant='subtitle2'
              >
                Демонтированный ПУ: {replacementResponse?.unmounted?.mac || '-'}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
            >
              <Typography variant='subtitle2'>
                Комментарий: {replacementResponse?.lastValidMessage || '-'}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent={'center'}
            >
              <Grid>
                <Button
                  variant='text'
                  onClick={handleClick}
                >
                  выбрать
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
