import { InputLabel, styled, TextField } from '@mui/material';

export const EntranceEnumerationModalInner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
});

export const EntranceEnumerationModalInputLabel = styled(InputLabel)({
  fontWeight: '600',
});

export const EntranceEnumerationModalInputRow = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
  gap: 15,
});

export const EntranceEnumerationModalButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 40,
  gap: 20,
});

export const EntranceEnumerationModalTextField = styled(TextField)({
  width: 75,
});
