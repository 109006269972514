import StorehouseAdminPanel from '@/components/sections/StorehouseAdminPanel/StorehouseAdminPanel';
import { useAccess } from '@/hooks/useAccess';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import { AccessEnum } from '@/app/auth/roles';
import { Paper } from '@mui/material';
import React from 'react';

const StorehouseAdminPage = () => {
  const hasAccess = useAccess({
    access: [AccessEnum.Admin, AccessEnum.StorehouseChief],
  });
  useMetaTitle(`Склад админ-панель`);
  return <Paper className='main-container'>{hasAccess && <StorehouseAdminPanel />}</Paper>;
};

export default StorehouseAdminPage;
