import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { FC } from 'react';
import { color } from '@/styles/mixins';

interface IReplacementStateIcon {
  status?: string;
}

export const getColorByStatus = (
  status: any
): { border: string; borderWidth: string } | undefined => {
  switch (status) {
    case 'CANCELED':
      return { border: color('statusCancel', 0.5), borderWidth: '3' };
    case 'WARNING':
      return { border: color('statusError', 0.5), borderWidth: '3' };
    case 'OK':
      return { border: color('statusSuccess', 0.5), borderWidth: '3' };
  }
  return undefined;
};

export const ReplacementStateIcon: FC<IReplacementStateIcon> = ({ status }) => {
  switch (status) {
    case 'CANCELED':
      return (
        <HighlightOffRoundedIcon
          sx={{ mr: 1 }}
          htmlColor={color('statusCancel')}
        />
      );
    case 'WARNING':
      return (
        <RemoveCircleOutlineRoundedIcon
          sx={{ mr: 1 }}
          htmlColor={color('statusError')}
        />
      );
    case 'OK':
      return (
        <CheckCircleOutlineRoundedIcon
          sx={{ mr: 1 }}
          htmlColor={color('statusSuccess')}
        />
      );
  }
  return null;
};
