import { useNavigate } from 'react-router-dom';
import { YodaTokenContextType } from '@/app/auth/types';
import useAuth from './useAuth';

export const useLogOut = () => {
  const auth: YodaTokenContextType = useAuth() as YodaTokenContextType;
  const navigate = useNavigate();
  const logOut = () => {
    auth.logOut();
    // сохраняем текущий путь в localStorage
    const currentPath = location.pathname + location.search;
    localStorage.setItem('redirectPath', currentPath);
    navigate('/login');
    window.location.reload();
  };

  return logOut;
};
