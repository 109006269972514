import { Button } from '@mui/material';
import React from 'react';
import styles from './Popup.module.scss';
import { ApiAssignContractorRequest } from '@/services/YodaRestService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { IPopup, Popup } from './Popup';
import { FetchCathError } from '@/types/api';

interface Props extends IPopup {
  taskIds: string[];
}

export const ReAssignContractorPopup: React.FC<Props> = (props) => {
  const { taskIds, onClose, onSuccess } = props;
  const { fetchCatch } = useActionLog();

  return (
    <Popup {...props}>
      <h2>Снять подрядчика</h2>
      <div>Вы уверены, что хотите снять подрядчика?</div>
      <div className={styles['modal-buttons']}>
        <Button
          onClick={() => {
            ApiAssignContractorRequest({
              taskIds,
            })
              .then(() => {
                onClose();
                onSuccess && onSuccess();
              })
              .catch((err: FetchCathError) => fetchCatch(err));
          }}
          variant='contained'
        >
          Снять
        </Button>
      </div>
    </Popup>
  );
};
