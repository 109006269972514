import { Button, styled } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';

export const DriveWrapper = styled('div')({
  width: 'calc(100% - 30px)',
  maxWidth: '1170px',
  margin: '0 auto 30px',
});

export const FoldersWrapper = styled(BoxShadow)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  marginTop: 30,
  marginBottom: 30,
  paddingLeft: 30,
  paddingRight: 30,
});

export const GroupsWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  marginLeft: -30,
});

export const AddButton = styled('div')({
  margin: '0 auto',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});
