import React, { useState } from 'react';
import Draggable from 'react-draggable';

import { GetBase64File, GetUnAttachmentByFilter } from '@/services/FileService';
import './HorizontalGalleryStyle.css';

interface GalleryProps {
  id: string;
}
const getLinks = (ids: string[]) => {
  const tempLinks: string[] = [];
  for (let i = 0; i < ids.length; i++) {
    GetBase64File(ids[i]).then(
      function (file) {
        tempLinks[i] = file;
      },
      function () {
        alert('Ошибка получения файла');
      }
    );
  }
  return tempLinks;
};

const HorizontalGallery: React.FC<GalleryProps> = ({ id }) => {
  const zoomStep = 1;
  const minScale = 1;
  const maxScale = 10;

  const [files, setFiles] = useState(['']);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [scale, setScale] = useState(minScale);
  const [rotate, setRotate] = useState(0);
  const [version, setVersion] = useState(0);
  const isDraggable = scale > 1;

  const getFiles = (id: string) => {
    if (id.length == 36) {
      const filter =
        '{"filter":{"conditions":[{"property": "obj","value": "' +
        id +
        '","operator": "="}]},"view": "unAttachment-view"}';
      GetUnAttachmentByFilter(filter)
        .then((value) => {
          const ids: string[] = value.data?.map((attachment) => attachment.file.id);
          setFiles(getLinks(ids));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const zoomIn = () => {
    const newScale = scale + zoomStep;
    setScale(newScale <= maxScale ? newScale : maxScale);
  };

  const zoomOut = () => {
    const newScale = scale - zoomStep;
    setScale(newScale <= minScale ? minScale : newScale);
  };
  const reset = () => {
    setScale(minScale);
    setRotate(0);
    setVersion(version + 1);
  };
  const onClose = () => {
    setSelectedImageIndex(-1);
  };
  const onScroll = (e: WheelEvent) => {
    const delta = e.deltaY * -0.01;
    const newScale = scale + delta;

    if (newScale > scale) zoomIn();
    else zoomOut();
  };

  React.useEffect(() => {
    onClose();
    getFiles(id);
  }, [id]);

  return (
    <div className='viewport'>
      <ul className={'list'}>
        {id.length == 36 ? (
          files.map((link, index) =>
            selectedImageIndex != index ? (
              <li
                className={'item'}
                key={index}
              >
                <img
                  key={link}
                  className='item'
                  src={link}
                  style={{ textAlign: 'center', fontSize: 'large', textAlignLast: 'center' }}
                  onDoubleClick={() => setSelectedImageIndex(index)}
                  alt={'Изображение ' + index}
                />
              </li>
            ) : (
              <li
                className={'item'}
                key={index}
              >
                <div className={'container'}>
                  <Draggable
                    disabled={!isDraggable}
                    key={version}
                  >
                    <div
                      onWheelCapture={(event) => onScroll(event.nativeEvent)}
                      style={isDraggable ? { cursor: 'move' } : {}}
                    >
                      <img
                        className={'item'}
                        style={{
                          zIndex: '9999',
                          transform: `scale(${scale}) rotate(${rotate}deg)`,
                        }}
                        draggable='false'
                        src={link}
                        alt={'Изображение ' + index}
                      />
                    </div>
                  </Draggable>
                  <button
                    className={'btnPlus'}
                    onClick={zoomIn}
                  >
                    +
                  </button>
                  <button
                    className={'btnMinus'}
                    onClick={zoomOut}
                  >
                    -
                  </button>
                  <button
                    className={'btnReset'}
                    onClick={reset}
                  >
                    Сброс
                  </button>
                  <button
                    className={'btnClose'}
                    onClick={onClose}
                  >
                    X
                  </button>
                </div>
              </li>
            )
          )
        ) : (
          <li className={'item'}>
            <div style={{ textAlign: 'center', fontSize: 'large' }}>
              <p>Выберете строку для отображения приложенных изображений</p>
            </div>
          </li>
        )}
        {files.length == 0 && id.length == 36 ? (
          <li className={'item'}>
            <div style={{ textAlign: 'center', fontSize: 'large' }}>
              <p>Не найдено фотографий приложенных к данному элементу</p>
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  );
};
export default HorizontalGallery;
