import React, { useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import { useBoltFunctionality } from './utils';
import { useLocation } from 'react-router-dom';

const NavbarBolt = () => {
  const { boltFunctionality } = useBoltFunctionality();
  const pathName = useLocation()?.pathname;

  useEffect(() => {
    const keydownHandler = (ev: KeyboardEvent) => {
      if (ev.key === 'F4') handleEditNavigation();
    };
    document.addEventListener('keydown', keydownHandler, false);
    return () => {
      document.removeEventListener('keydown', keydownHandler, false);
    };
  }, [pathName]);

  const handleEditNavigation = () => {
    boltFunctionality();
  };

  return (
    <Tooltip title='Горячая клавиша - f4'>
      <IconButton onClick={handleEditNavigation}>
        <BoltIcon color='info' />
      </IconButton>
    </Tooltip>
  );
};

export default NavbarBolt;
