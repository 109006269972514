import { TaskInfoResponse, TaskResponse } from '@/dto/taskmap/task/TasksDto';
import { createStore } from '@/utils/createStore';

interface PpoMapStore {
  isEditRouterMode: boolean;
  tempDataRouter: any;
  latitudeRouter?: number;
  longitudeRouter?: number;
  showRouters: boolean;

  changeRouterMode: (flag: boolean) => void;
  setShowRouters: () => void;
  setTempDataRouter: (data: TaskResponse | null | undefined) => void;

  setLatitudeRouter: (latitude: number) => void;
  setLongitudeRouter: (longitude: number) => void;
}

export const useRouterStore = createStore<PpoMapStore>(
  (set: any, get: any) => ({
    isEditRouterMode: false,
    tempDataRouter: null,
    showRouters: false,

    setLatitudeRouter: (latitude) => {
      set({ latitudeRouter: latitude });
    },
    setLongitudeRouter: (longitude) => {
      set({ longitudeRouter: longitude });
    },
    setShowRouters: () => {
      const showRoutersNow = get().showRouters;
      set({ showRouters: !showRoutersNow });
    },
    setTempDataRouter: (data) => {
      set({ tempDataRouter: data });
    },

    changeRouterMode: (flag) => {
      if (!flag) {
        set({
          tempDataRouter: null,
          isEditRouterMode: flag,
          latitudeRouter: undefined,
          longitudeRouter: undefined,
        });
      }
      set({
        isEditRouterMode: flag,
        latitudeRouter: undefined,
        longitudeRouter: undefined,
      });
    },
  }),
  'Router'
);
