import { CatalogTypes } from '@/components/sections/CatalogsBrowse/constants';

export const makeTitle = (type: string) => {
  switch (type) {
    case CatalogTypes.STAMP:
      return 'Пломбы';
    case CatalogTypes.PU:
      return 'Приборы учета';
    case CatalogTypes.SIM:
      return 'Сим карты';
    case CatalogTypes.KOO:
      return 'Сетевое оборудование';
    default:
      return 'Справочники';
  }
};
