import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  NewGalleryActiveImageHeader,
  NewGalleryActiveImageWrapper,
  NewGalleryResetButton,
} from './NewGallery.styled';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import Draggable from 'react-draggable';
import { getImageUrl, NOT_DEFINED } from './utils';
import { useGalleryData } from '@/components/Gallery/store';
import { ApiGetFileById } from '@/services/YodaRestService';

export interface GalleryActiveImageProps {
  imageId: string;
}

const NewGalleryActiveImage = ({ imageId }: GalleryActiveImageProps) => {
  const getImageUrlById = useGalleryData((state) => state.getImageUrlById);
  const [contentType, setContentType] = useState('');

  const zoomStep = 0.5;
  const minScale = 1;
  const maxScale = 10;
  const defaultScale = 1;
  const defaultRotate = 0;

  const [scale, setScale] = useState(defaultScale);
  const [rotate, setRotate] = useState(defaultRotate);
  const [version, setVersion] = useState(0);
  const isDraggable = true;
  const zoomIn = () => {
    const newScale = scale + zoomStep;
    setScale(newScale <= maxScale ? newScale : maxScale);
  };

  const zoomOut = () => {
    const newScale = scale - zoomStep;
    setScale(newScale <= minScale ? minScale : newScale);
  };
  const reset = () => {
    setScale(defaultScale);
    setRotate(0);
    setVersion(version + 1);
  };

  const onRotate = () => {
    setRotate(rotate + 90);
  };

  const onScroll = (e: WheelEvent) => {
    const delta = e.deltaY * -0.01;
    const newScale = scale + delta;

    if (newScale > scale) {
      zoomIn();
    } else {
      zoomOut();
    }
  };

  useEffect(() => {
    ApiGetFileById(imageId)
      .then((res) => setContentType(res.headers['content-type']))
      .catch(() => setContentType(NOT_DEFINED));
  }, [imageId]);

  return (
    <>
      <NewGalleryActiveImageHeader>
        <div>
          <NewGalleryResetButton onClick={reset}>Сброс</NewGalleryResetButton>

          <IconButton onClick={zoomIn}>
            <ZoomInRoundedIcon color='info' />
          </IconButton>

          <IconButton onClick={zoomOut}>
            <ZoomOutRoundedIcon color='info' />
          </IconButton>

          <IconButton onClick={onRotate}>
            <CachedRoundedIcon color='info' />
          </IconButton>
        </div>
      </NewGalleryActiveImageHeader>
      <NewGalleryActiveImageWrapper
        onMouseOut={() => (document.body.style.overflow = 'unset')}
        onMouseOver={() => (document.body.style.overflow = 'hidden')}
      >
        <Draggable
          disabled={!isDraggable}
          key={version}
        >
          <div
            onWheelCapture={(event) => onScroll(event.nativeEvent)}
            style={{ zIndex: 20, position: 'relative', ...(isDraggable && { cursor: 'move' }) }}
          >
            <img
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                minHeight: '150px',
                maxWidth: '100%',
                maxHeight: '80svh',
                transition: '0.3s',
              }}
              draggable='false'
              src={getImageUrl(imageId, getImageUrlById, contentType)}
              srcSet={getImageUrl(imageId, getImageUrlById, contentType)}
              onDoubleClick={zoomIn}
              loading='lazy'
            />
          </div>
        </Draggable>
      </NewGalleryActiveImageWrapper>
    </>
  );
};

export default NewGalleryActiveImage;
