import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import PinDropIcon from '@mui/icons-material/PinDrop';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { GeoTask, TaskElement } from '@/dto/taskmap/Dto';
import { useMap } from '@/components/features/pnr/hooks/MapHook';
import { ApiFindTaskElementByTaskId } from '@/services/YodaRestService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { TaskGeoPoint } from '@/components/maps/GeoPoint';

export const GeoTaskItem = (props: any) => {
  const [geoTask, setGeoTask] = useState<GeoTask>();
  const [isSelected, setSelected] = useState<boolean | undefined>(false);
  const [taskElement, setTaskElement] = useState<TaskElement>();
  const [loading, setLoading] = useState<boolean>(false);
  const { setSelectedGeoPointList, selectedGeoPointList } = useMap();
  const { catchError } = useActionLog();

  useEffect(() => {
    setGeoTask(props.geoTask);
    findTaskElementById(props.geoTask.taskId);
  }, [props.geoTask]);

  useEffect(() => {
    setSelected(
      selectedGeoPointList &&
        selectedGeoPointList.findIndex((t) => t.getObject()?.taskId === props.geoTask?.taskId) > -1
    );
  }, [selectedGeoPointList]);

  const findTaskElementById = (id: string) => {
    setLoading(true);
    ApiFindTaskElementByTaskId(id)
      .then((res) => setTaskElement(res.data))
      .catch((err) => catchError('Ошибка', err))
      .finally(() => setLoading(false));
  };

  const handleMoveTo = () => {
    if (geoTask) {
      setSelectedGeoPointList([new TaskGeoPoint(geoTask)]);
    }
  };

  const handleMoveBack = () => {
    setSelectedGeoPointList([]);
  };

  return (
    <>
      <Stack direction='row'>
        <Box
          width={'100%'}
          sx={{
            boxShadow: 1,
            margin: 1,
            padding: 1,
            border: '1px solid silver',
            borderRadius: 3,
            backgroundColor: isSelected ? '#DFF6FF' : '',
          }}
        >
          {loading ? (
            <Skeleton animation='wave' />
          ) : (
            <>
              <Grid container>
                <Grid
                  container
                  item
                >
                  <Grid
                    item
                    xs={10}
                  >
                    <Typography variant='button'>
                      {props.index + 1}. Заявка № {geoTask?.number}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                  ></Grid>
                </Grid>
                {taskElement && (
                  <Grid
                    container
                    item
                    sx={{ ml: 2 }}
                  >
                    <Stack>
                      {taskElement.type && (
                        <Typography variant='caption'>
                          Тип оборудования: {taskElement?.type}
                        </Typography>
                      )}
                      {taskElement.imei && (
                        <Typography variant='caption'>IMEI: {taskElement?.imei}</Typography>
                      )}
                      {taskElement.mac && (
                        <Typography variant='caption'>MAC: {taskElement?.mac}</Typography>
                      )}
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
        <Box sx={{ pt: 1 }}>
          {isSelected ? (
            <CancelPresentationIcon
              sx={{ color: 'gray', fontSize: 22, cursor: 'pointer' }}
              onClick={handleMoveBack}
            ></CancelPresentationIcon>
          ) : (
            <PinDropIcon
              sx={{ color: 'gray', fontSize: 22, cursor: 'pointer' }}
              onClick={handleMoveTo}
            ></PinDropIcon>
          )}
        </Box>
      </Stack>
    </>
  );
};
