import {
  ApiFindAsumbTaskStatistics,
  ApiGetAsumbTaskStatisticsCheckStatus,
  ApiGetAsumbTaskStatusStatisticsForDate,
  HOST,
} from './YodaRestService';
import { BubbleDataPoint, ChartData, ScatterDataPoint } from 'chart.js';
import {
  FilterSmsStatisitc,
  FilterStatisticReportByContractPerDay,
  FilterStatisticUrgentAndSuperUrgent,
  MeterChangeForRevisionFilterParam,
  SMROdpuStatisticsFilter,
  SMRStatisticsFilter,
  StatisticByInstallPuDto,
  StatisticDistributionDto,
  StatisticReportByContractPerDayDto,
  StatisticUrgentAndSuperUrgentTasksDto,
  TaskFilterParam,
  filterStatisticByInstallPu,
} from '@/dto/taskmap/Dto';
import { axiosGet, axiosPost } from './helpers';
import { Filter } from '@/components/statistics/CustomerInteractionStatistics/StatisticMoreThanOneApeal/StatisticMoreThanOneApeal';
import {
  CallCenterStatisticsFilter,
  SmsStatusStatisticsItem,
} from '@/components/statistics/CustomerInteractionStatistics/types';
import { CallCenterStatisticsDto } from '@/components/statistics/CustomerInteractionStatistics/CallCenterStatistics/types';
import { StatisticByInstallationLocationData } from '@/components/statistics/RequestStatistic/StatisticByInstallationLocation/types';
import { ApiResponse } from '@/types/api';
import { StatisticsResponsebleDto } from '@/components/statistics/CustomerInteractionStatistics/StatisticsResponseble/types';
import { SMROdpuStatisticsTable } from '@/components/statistics/OdpuStatistic/SMROdpuStatistics/types';
import { SMRStatisticsTableItem } from '@/components/statistics/RequestStatistic/SMRStatistics/types';
import { StatisticMoreThanOneApealDto } from '@/components/statistics/CustomerInteractionStatistics/StatisticMoreThanOneApeal/types';
import { AsumbTaskStatisticsType } from '@/components/statistics/AsumbStatistic/types';
import { AsumbTaskStatisticsForCheckChartType } from '@/components/statistics/RequestStatistic/CheckStatistics/types';
import { MeterChangeForRevisionData } from '@/components/statistics/RequestStatistic/MeterChangeForRevision/types';

const TAG = 'StatisticsService';
const ID_CALLING_MAP_STATISTIC = 'db1b8102-b6a3-f28d-b2c8-54ebd37432a4';

export function GetASUMBTaskStatistics(): ApiResponse<AsumbTaskStatisticsType[]> {
  return ApiFindAsumbTaskStatistics();
}
export function GetASUMBTaskStatisticsForDate(
  dateFrom: string,
  dateTo: string
): ApiResponse<ChartData<'line', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>> {
  return ApiGetAsumbTaskStatusStatisticsForDate(dateFrom, dateTo);
}

export function GetASUMBTaskStatisticsCheckStatus(): ApiResponse<AsumbTaskStatisticsForCheckChartType> {
  return ApiGetAsumbTaskStatisticsCheckStatus();
}

export async function GetASUMBTaskStatisticsByFilter(
  filter: TaskFilterParam
): ApiResponse<AsumbTaskStatisticsType[]> {
  const url = HOST + `/rest/statistics/asumb/task`;
  return axiosPost(url, filter);
}

export async function GetASUMBTaskStatisticsCheckStatusByFilter(
  filter: TaskFilterParam
): ApiResponse<AsumbTaskStatisticsForCheckChartType> {
  const url = HOST + `/rest/statistics/asumb/check`;
  return axiosPost(url, filter);
}

export async function GetMeterChangeForRevisionByFilter(
  filter: MeterChangeForRevisionFilterParam
): ApiResponse<MeterChangeForRevisionData> {
  const url = HOST + `/rest/statistics/meter-change/revision`;
  return axiosPost(url, filter);
}

export async function GetSMRStatisticsTableByFilter(
  filter: SMRStatisticsFilter
): ApiResponse<SMRStatisticsTableItem[]> {
  const url = HOST + '/rest/statistics/smr';
  return axiosPost(url, filter);
}

export async function GetSMROdpuStatisticsTableByFilter(
  filter: SMROdpuStatisticsFilter
): ApiResponse<SMROdpuStatisticsTable> {
  const url = HOST + '/rest/statistics/smr/odpu';
  return axiosPost(url, filter);
}

export async function GetCallCenterStatisticsTableByFilter(
  filter: CallCenterStatisticsFilter
): ApiResponse<CallCenterStatisticsDto[]> {
  const url = HOST + '/rest/statistics/call-center';
  return axiosPost(url, filter);
}

export async function GetCallCenterStatisticsXlsx(filter: {
  taskIdList: string[];
  template: string;
}): ApiResponse<any> {
  const url = HOST + '/rest/report/task';
  return axiosPost(url, filter, true);
}
export async function GetStatisticMoreThanOneApealByFilter(
  filter: Filter
): ApiResponse<StatisticMoreThanOneApealDto[]> {
  const { pageSize, currentPage, ...payload } = filter;
  const url =
    HOST + `/rest/statistics/task-with-calls?offset=${currentPage * pageSize}&limit=${pageSize}`;
  return axiosPost(url, payload);
}

export async function GetSmsStatusStatistics(
  contractIdList: FilterSmsStatisitc
): ApiResponse<SmsStatusStatisticsItem[]> {
  const url = HOST + '/rest/statistics/sms/status/contracts';

  return axiosPost(url, contractIdList);
}
export async function GetStatisticsResponsebles(
  contractIdList: any
): ApiResponse<StatisticsResponsebleDto[]> {
  const url = HOST + '/rest/statistics/call-center/contractor';
  return axiosPost(url, contractIdList);
}
export async function GetStatisticByInstallationLocation(
  contractIdList: any
): ApiResponse<StatisticByInstallationLocationData[]> {
  const url = HOST + '/rest/statistics/montage-place';
  return axiosPost(url, contractIdList);
}
export async function GetStatisticUrgentAndSuperUrgentTasks(
  filter: FilterStatisticUrgentAndSuperUrgent
): ApiResponse<StatisticUrgentAndSuperUrgentTasksDto[]> {
  const url = HOST + '/rest/statistics/task-urgent';
  return axiosPost(url, filter);
}
export async function GetStatisticReportByContract(
  filter: FilterStatisticReportByContractPerDay
): ApiResponse<StatisticReportByContractPerDayDto[]> {
  const url = HOST + '/rest/statistics/execution/contract/day';
  return axiosPost(url, filter);
}
export async function GetStatisticByInstallPu(
  filter: filterStatisticByInstallPu
): ApiResponse<StatisticByInstallPuDto[]> {
  const url = HOST + '/rest/statistics/montage-places-compare';
  return axiosPost(url, filter);
}

export async function GetStatisticDistribution(
  filter: any
): ApiResponse<StatisticDistributionDto[]> {
  const url = HOST + '/rest/statistics/distribution/contract/month';
  return axiosPost(url, filter);
}
export async function GetStatisticCallingMap(filter: any): ApiResponse<any[]> {
  const url = HOST + '/rest/statistics/installation-call-status';
  return axiosPost(url, filter);
}
export async function GetStatisticCallingMapResponsible(): ApiResponse<any[]> {
  const url = HOST + `/rest/group/${ID_CALLING_MAP_STATISTIC}/child `;
  return axiosGet(url);
}
export async function GetStatisticCallingMapExecutors(contractId: string): ApiResponse<any[]> {
  const url = HOST + `/rest/group/${contractId}/child-embedded`;
  return axiosGet(url);
}

export async function GetStatisticCallingMapOperators(contractId: string): ApiResponse<any[]> {
  const url = HOST + `/rest/group/${contractId}/role?role-name=ContractorOperatorRole `;
  return axiosGet(url);
}

export async function GetAttributesRp(): ApiResponse<any> {
  const url = HOST + '/rest/statistics/rp-report/attributes';
  return axiosGet(url);
}
export async function GetCurrentGroup(): ApiResponse<any> {
  const url = HOST + '/rest/user/current/group';
  return axiosGet(url);
}
