import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { Grow, Tooltip } from '@mui/material';
import { IssuedStampUpdateFileIconButton } from './IssuedStamp.styled';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useStorehouseWorkerAccess } from '../SupplyPUComponent/utils';

export interface IssuedStampUpdateFileConfirmProps {
  saveFiles: () => void;
}

const IssuedStampUpdateFileConfirm = ({ saveFiles }: IssuedStampUpdateFileConfirmProps) => {
  const { isInProgress } = useScreenHoldHook();
  const workerAccess = useStorehouseWorkerAccess();

  if (isInProgress) return null;

  return (
    <>
      <Grow
        in
        timeout={2000}
      >
        <Tooltip title='Сохранить изменения файлов'>
          <IssuedStampUpdateFileIconButton
            onClick={saveFiles}
            disabled={!workerAccess}
          >
            <SaveIcon
              color={workerAccess ? 'info' : undefined}
              fontSize='large'
            />
          </IssuedStampUpdateFileIconButton>
        </Tooltip>
      </Grow>
    </>
  );
};

export default IssuedStampUpdateFileConfirm;
