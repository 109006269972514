import {Button} from "@mui/material";
import React from "react";
import styles from "./Popup.module.scss";
import {IPopup, Popup} from "./Popup";


interface Props extends IPopup {

}

export const CancelPopup: React.FC<Props> = (props) => {
	const {onClose, onSuccess} = props;
	return (
		<Popup {...props}>
			<div>
				<h2>Отмена заявки!</h2>
				<div>Вы дествительно хотите сделать заявку не актуальной?</div>
			</div>
			<div className={styles["modal-buttons"]}>
				<Button
					onClick={onSuccess}
					variant="contained"
				>
					Да
				</Button>
				<Button
					onClick={onClose}
					variant="contained"
				>
					Нет
				</Button>
			</div>
		</Popup>
	);
};
