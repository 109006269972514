import { GeoTask } from '@/dto/taskmap/Dto';

export interface GeoPoint {
  getCoords: () => number[];
  setCoords: (coords: number[]) => void;
  getType: () => GeoPointType;
  getObject: () => GeoTask | null;
  getColor?: () => string | undefined;
}

export enum GeoPointType {
  TASK,
  NETWORK,
  FREE_POINT,
  PPO_POINT,
  Zoning_TASK,
}

export class TaskGeoPoint implements GeoPoint {
  geoTask: GeoTask;
  constructor(geoTask: GeoTask) {
    this.geoTask = geoTask;
  }

  setCoords(coords: number[]) {
    if (coords) {
      this.geoTask.longitudeX = coords[0];
      this.geoTask.latitudeY = coords[1];
    }
  }

  getObject() {
    return this.geoTask;
  }

  getCoords() {
    if (this.geoTask) {
      return [this.geoTask.longitudeX, this.geoTask.latitudeY];
    }
    throw new Error('Coordinates is GeoTask not defined');
  }
  getType() {
    return GeoPointType.TASK;
  }
}

export class FreeGeoPoint implements GeoPoint {
  latitude: number;
  longitude: number;
  constructor(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
  }

  setCoords(coords: number[]) {
    this.latitude = coords[0];
    this.longitude = coords[1];
  }

  getType() {
    return GeoPointType.FREE_POINT;
  }

  getObject() {
    return null;
  }

  getCoords() {
    if (this.latitude && this.longitude) {
      return [this.latitude, this.longitude];
    }
    throw new Error('Coordinates is FreePoint not defined');
  }
}

export class NetworkGeoPoint implements GeoPoint {
  geoTask: GeoTask;
  constructor(geoTask: GeoTask) {
    this.geoTask = geoTask;
  }

  setCoords(coords: number[]) {
    if (coords) {
      this.geoTask.longitudeX = coords[0];
      this.geoTask.latitudeY = coords[1];
    }
  }

  getObject() {
    return this.geoTask;
  }
  getCoords() {
    if (this.geoTask) {
      return [this.geoTask.longitudeX, this.geoTask.latitudeY];
    }
    throw new Error('Coordinates is NetworkGeoTask not defined');
  }
  getType() {
    return GeoPointType.NETWORK;
  }
}

export class ZoningTaskPoint implements GeoPoint {
  geoTask: GeoTask;
  constructor(geoTask: GeoTask) {
    this.geoTask = geoTask;
  }

  setCoords(coords: number[]) {
    if (coords) {
      this.geoTask.longitudeX = coords[0];
      this.geoTask.latitudeY = coords[1];
    }
  }

  getObject() {
    return this.geoTask;
  }

  getCoords() {
    if (this.geoTask) {
      return [this.geoTask.longitudeX, this.geoTask.latitudeY];
    }
    throw new Error('Coordinates is GeoTask not defined');
  }
  getType() {
    return GeoPointType.Zoning_TASK;
  }

  getColor() {
    return (this.geoTask.reassignable && 'orange') || undefined;
  }
}

export class PpoGeoPoint implements GeoPoint {
  geoTask: GeoTask;
  constructor(geoTask: GeoTask) {
    this.geoTask = geoTask;
  }

  setCoords(coords: number[]) {
    if (coords) {
      this.geoTask.longitudeX = coords[0];
      this.geoTask.latitudeY = coords[1];
    }
  }

  getObject() {
    return this.geoTask;
  }
  getCoords() {
    if (this.geoTask) {
      return [this.geoTask.longitudeX, this.geoTask.latitudeY];
    }
    throw new Error('Coordinates not defined');
  }
  getType() {
    return GeoPointType.PPO_POINT;
  }
}
