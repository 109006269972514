import { BoxShadow } from '@/components/ui/BoxShadow';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';
import CommonModal from '@/components/common/CommonModal/CommonModal';
import { color } from '@/styles/mixins';

export const StorehouseAdminPuWrapper = styled(BoxShadow)({
  position: 'relative',
  display: 'inline-flex',
  flexDirection: 'column',
  gap: 20,
  padding: 20,
  borderRadius: 20,
  width: 'auto',
  minWidth: 300,
});

export const StorehouseAdminModalInner = styled('div')({
  minWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,

  '.parent': {
    margin: 0,
    padding: 0,
  },
});

export const StorehouseAdminSaveButton = styled(LoadingButton)({
  alignSelf: 'center',
  marginTop: 10,
});

export const StorehouseAdminCommonModal = styled(CommonModal)({
  zIndex: 1,
});

export const StorehouseAdminDeleteAttention = styled('span')({
  color: color('muiError'),
});
