import { Card, CardMedia, Grid, Paper, Typography, CardActionArea } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import analysis from '../../../assets/analysis.svg';
import asumb from '../../../assets/asumb.svg';
import callCenter from '../../../assets/callCenter.svg';
import statistic from '../../../assets/statistic.svg';
import { StatisticCardContent, StatisticCardMediaWrapper } from './StatisticCard.styled';

interface StatisticCardProps {
  title: string;
  img: string;
  navigateTo: string;
}
const images = [
  { id: 'analysis', imeg: analysis },
  { id: 'asumb', imeg: asumb },
  { id: 'callCenter', imeg: callCenter },
  { id: 'statistic', imeg: statistic },
];

function StatisticCard({ title, img, navigateTo }: StatisticCardProps) {
  const navigate = useNavigate();
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      onClick={() => navigate(navigateTo)}
    >
      <CardActionArea>
        <Paper elevation={6}>
          <Card
            variant='outlined'
            style={{ minHeight: 250, display: 'flex' }}
          >
            <StatisticCardContent>
              <Typography
                variant='h5'
                sx={{ fontWeight: 'bold' }}
              >
                {title}
              </Typography>
            </StatisticCardContent>
            <StatisticCardMediaWrapper>
              <CardMedia
                component='img'
                src={images.find((i) => i.id === img)?.imeg}
                alt={img}
                style={{ width: '90%', height: 'auto' }} // Set width to 90% and let height adjust
              />
            </StatisticCardMediaWrapper>
          </Card>
        </Paper>
      </CardActionArea>
    </Grid>
  );
}

export default StatisticCard;
