import { styled, Typography } from '@mui/material';

export const AccessPopupTextWrapper = styled('div')({
  textAlign: 'center',
});

export const AccessPopupHeader = styled(Typography)({
  marginBottom: 12,
  textAlign: 'center',
});
