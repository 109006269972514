import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CallTypeModalSendedToBilling from './CallTypeModalSendedToBilling';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

interface CallTypeModalTypeFiveProps {
  sendedToBilling: (taskId: string, requestId: string, resultJobLog: string) => any;
  sendToAsumbStatus?: string;
  taskStatus: string;
  taskId: string;
  requestId: string;
}

export default function CallTypeModalTypeFive(props: CallTypeModalTypeFiveProps) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();


  return (
    <div>
      <Button onClick={handleOpen}>Принять</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Информация по этой заявке передана в биллинг?
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'end', marginTop: 10 }}>
            <Button onClick={(e) => {
              navigate(`/task/edit/${props.taskId}`)
            }}>Открыть заявку</Button>
            <CallTypeModalSendedToBilling taskId={props.taskId} requestId={props.requestId} taskStatus={props.taskStatus} sendedToBilling={props.sendedToBilling} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
