import { useGalleryData } from '@/components/Gallery/store';
import { ApiGetFileById } from '@/services/YodaRestService';
import React, { useEffect, useState } from 'react';
import { NewGalleryPreviewImage, NewGalleryPreviewItem } from './NewGallery.styled';
import { getImageUrl, NOT_DEFINED } from './utils';

export interface NewGalleryPreviewProps {
  imageId: string;
  onClick: (index: number) => void;
  index: number;
  activeIndex: number;
}

const NewGalleryPreview = (props: NewGalleryPreviewProps) => {
  const getImageUrlById = useGalleryData((state) => state.getImageUrlById);
  const [contentType, setContentType] = useState('');

  useEffect(() => {
    ApiGetFileById(props.imageId)
      .then((res) => setContentType(res.headers['content-type']))
      .catch(() => setContentType(NOT_DEFINED));
  }, [props.imageId]);

  return (
    <NewGalleryPreviewItem
      onClick={() => props.onClick(props.index)}
      active={props.index === props.activeIndex}
    >
      <NewGalleryPreviewImage
        src={getImageUrl(props.imageId, getImageUrlById, contentType)}
        srcSet={getImageUrl(props.imageId, getImageUrlById, contentType)}
        loading='lazy'
        height='100%'
        width='100%'
      />
    </NewGalleryPreviewItem>
  );
};

export default React.memo(NewGalleryPreview);
