import { BoxShadow } from '@/components/ui/BoxShadow';
import { mediaBreakpointDown } from '@/styles/mixins';
import { styled } from '@mui/material';

export const ComplaintInfoHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ComplaintInfoStage = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

export const ComplaintInfoFadeInner = styled('div')({
  zIndex: 2,
});

interface ComplaintInfoRoundProps {
  color?: string;
}
interface ComplaintInfoWrapperProps {
  isCallCenter?: boolean;
}

export const ComplaintInfoRound = styled('div')<ComplaintInfoRoundProps>(({ color }) => ({
  borderRadius: '50%',
  backgroundColor: color,
  width: 20,
  height: 20,
}));

export const ComplaintInfoWrapper = styled(BoxShadow)<ComplaintInfoWrapperProps>(
  ({ isCallCenter }) => ({
    position: 'absolute',
    top: 0,
    right: 5,
    zIndex: 100,
    minWidth: isCallCenter ? 1000 : 500,
    maxWidth: '30vw',
    width: 'fit-content',
    minHeight: 400,
    padding: 15,
    borderRadius: 16,
    margin: 8,
    backgroundColor: isCallCenter ? '#FFF' : '#F5F9FF',
    [mediaBreakpointDown('lg')]: {
      position: 'fixed',
      minWidth: isCallCenter ? 700 : 350,
      bottom: 15,
      top: 'auto', // отменяем привязку к верху
      margin: 0, // убираем внешний отступ
      left: '50%', // центрируем по горизонтали
      transform: 'translateX(-50%)', // смещаем на половину ширины для центрирования
    },
    [mediaBreakpointDown('md')]: {
      position: 'fixed',
      minWidth: isCallCenter ? 360 : 350,
      bottom: 15,
      top: 'auto', // отменяем привязку к верху
      margin: 0, // убираем внешний отступ
      left: '50%', // центрируем по горизонтали
      transform: 'translateX(-50%)', // смещаем на половину ширины для центрирования
    },
  })
);
