import React, { useState } from 'react';
import { PURemoveScannerScannButton } from '../PURemoveScanner/PURemoveScanner.styled';
import { ConfirmationDialog } from '@/components/common/ConfirmationDialog';

export interface SupplyClearProps {
  buttonText: string;
  modalText: string;
  onSuccess: () => void;
  disabled?: boolean;
}

const SupplyClear = ({ buttonText, modalText, onSuccess, disabled }: SupplyClearProps) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const handleConfirm = () => {
    onSuccess();
    setAlertOpen(false);
  };
  return (
    <div>
      <PURemoveScannerScannButton
        variant='contained'
        onClick={() => setAlertOpen(true)}
        disabled={disabled}
      >
        {buttonText}
      </PURemoveScannerScannButton>

      <ConfirmationDialog
        title={`Удаление!`}
        handleOk={handleConfirm}
        handleCancel={() => setAlertOpen(false)}
        open={alertOpen}
        textForOk='Да'
        textForCancel='Нет'
      >
        {modalText}
      </ConfirmationDialog>
    </div>
  );
};

export default SupplyClear;
