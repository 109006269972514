import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';
import { TaskStatus } from '@/dto/taskmap/Dto';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

interface CallTypeModalTypeThreeProps {
  taskId: string;
  requestId: string;
  taskStatus: string;
  planDate?: string;
  callTypeId: string;
  executor?: SelectFilterOptType | null;
  comment?: string;
  close: () => any;
  onSave: (
    taskId: string,
    requestId: string,
    resultJobLog: string,
    planDate?: string,
    executor?: string,
    comment?: string
  ) => any;
}

export default function CallTypeModalTypeThree(props: CallTypeModalTypeThreeProps) {
  const [open, setOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState('');
  const [alertDescription, setAlertDescription] = React.useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const onSaveClick = () => {
    if (props.callTypeId === '301') {
      if (props.taskStatus === getKeyByValue(TaskStatus.not_relevant)) {
        setAlertTitle('Для данной операции заявка не должна быть в статусе "Не актуальна".');
        handleOpen();
      } else {
        save();
      }
    } else {
      if (
        props.taskStatus !== getKeyByValue(TaskStatus.completed) &&
        props.taskStatus !== getKeyByValue(TaskStatus.disput)
      ) {
        setAlertTitle(
          `Для данной операции заявка должна быть в статусе "Закрыта". На данный момент заявка в статусе ${
            TaskStatus[props.taskStatus as keyof typeof TaskStatus]
          }.`
        );
        setAlertDescription('Перейдите в заявку и закройте ее пожалуйста.');
        handleOpen();
      } else {
        save();
      }
    }
  };

  const save = () => {
    if (props.planDate && props.executor) {
      props.onSave(
        props.taskId,
        props.requestId,
        'Принял плановую дату: ' +
          props.planDate +
          ' и назначил ответственного: ' +
          props.executor.label +
          '',
        props.planDate,
        props.executor.value,
        props.comment
      );
      props.close();
    }
  };

  function getKeyByValue(value: string) {
    const indexOfS = Object.values(TaskStatus).indexOf(value as unknown as TaskStatus);

    const key = Object.keys(TaskStatus)[indexOfS];

    return key;
  }

  return (
    <div>
      <Button onClick={() => onSaveClick()}>Сохранить</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            sx={{ marginBottom: 3 }}
            variant='h6'
            component='h2'
          >
            {alertTitle}
          </Typography>
          <Typography
            id='modal-modal-description'
            variant='h6'
            component='h2'
          >
            {alertDescription}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'end',
              marginTop: 10,
            }}
          >
            <Button
              onClick={(e) => {
                setOpen(false);
                props.close();
              }}
            >
              Отмена
            </Button>
            <Button
              sx={{ marginRight: 2 }}
              onClick={(e) => {
                navigate(`/task/edit/${props.taskId}`);
              }}
            >
              Открыть заявку
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
