import { PillarGeo } from '@/components/maps/ppoMap/dto';

export const PillarsBalloon = (geoObject: PillarGeo) => {
  return `<div class="map__delivery-point-balloon">
        <a href="#" class="delivery-point-balloon-link"
           id="delivery-point-balloon_id_${geoObject.id}">
          Информация об опоре
        </a>
      </div>
    `;
};
