export const STATISTIC_REPORT_BY_CONTRACT_PER_DAY_COLUMNS: any = [
  {
    key: 'contractor',
    title: '',
    dataIndex: 'taskType',
    ellipsis: true,
    width: 500,
    className: 'custom-column-class-yellow',

    render: (text: string, record: any) => {
      const dataIndexToUse = record.contractor ? 'contractor' : 'taskPriority';
      return (
        <span
          style={{
            fontWeight: dataIndexToUse === 'taskPriority' ? 'bold' : 'normal',
          }}
        >
          {record[dataIndexToUse]}
          {text}
        </span>
      );
    },
  },
  {
    key: 'apartmentCount',
    title: 'Квартира',
    dataIndex: 'apartmentCount',
    align: 'center',
  },
  {
    key: 'ladderCount',
    title: 'Лестница',
    dataIndex: 'ladderCount',
    align: 'center',
  },
  {
    key: 'hallCount',
    title: 'Холл',
    dataIndex: 'hallCount',
    align: 'center',
  },
  {
    key: 'total',
    title: 'Общий итог',
    dataIndex: 'total',
    align: 'center',
  },
];

export const STATISTIC_REPORT_BY_CONTRACT_PER_DAY_TASK_TYPES: any = [
  'SMR_MKD',
  'SMR_STOLBI',
  'ASUMB_NETWORK_ELEMENTS',
  'ASUMB_SMR_MKD',
  'SMR_ODPU',
  'SMR_ODPU_ENTITY',
  'SMR_ODPU_MOEK',
  'ASUMB_UGP',
  'ASUMB_GUARANTEE',
  'ASUMB_GOVERNMENT',
  'ASUMB_COMPLAINT_MKD',
];

export type AggregatedData = {
  key: number;
  taskType: string;
  children: AggregatedContractor[];
  apartmentCount: number;
  ladderCount: number;
  hallCount: number;
  total: number;
};

type AggregatedContractor = {
  key: number;
  contractor: string;
  children: AggregatedTask[];
  apartmentCount: number;
  ladderCount: number;
  hallCount: number;
  total: number;
};

type AggregatedTask = {
  key: number;
  taskPriority: string;
  apartmentCount: number;
  ladderCount: number;
  hallCount: number;
  total: number;
};

export const aggregatedDataFunc = (data: any[]) => {
  const aggregatedData: { [key: string]: AggregatedData } = {};
  let uniqueKey = 0;

  data.forEach((item) => {
    const taskType = item.taskType;
    const contractor = item.contractor;
    const taskPriority = item.taskPriority;

    if (!aggregatedData[taskType]) {
      aggregatedData[taskType] = {
        key: uniqueKey++,
        taskType: `Тип заявки: ${taskType}`,
        children: [],
        apartmentCount: 0,
        ladderCount: 0,
        hallCount: 0,
        total: 0,
      };
    }

    const taskTypeData = aggregatedData[taskType];

    if (!taskTypeData.children.some((child) => child.contractor === contractor)) {
      taskTypeData.children.push({
        key: uniqueKey++,
        contractor,
        children: [],
        apartmentCount: 0,
        ladderCount: 0,
        hallCount: 0,
        total: 0,
      });
    }

    const contractorData = taskTypeData.children.find((child) => child.contractor === contractor);
    if (contractorData) {
      contractorData.children.push({
        key: uniqueKey++,
        taskPriority,
        apartmentCount: item.apartmentCount,
        ladderCount: item.ladderCount,
        hallCount: item.hallCount,
        total: item.total,
      });

      // Обновляем общий результат для текущего contractor
      contractorData.apartmentCount += item.apartmentCount;
      contractorData.ladderCount += item.ladderCount;
      contractorData.hallCount += item.hallCount;
      contractorData.total += item.total;
    }
  });

  for (const taskType in aggregatedData) {
    const taskTypeData = aggregatedData[taskType];
    const totalObject: AggregatedContractor = {
      contractor: 'Общий итог',
      key: uniqueKey++,
      apartmentCount: 0,
      ladderCount: 0,
      hallCount: 0,
      total: 0,
      children: [],
    };

    const taskPriorities = new Set<string>();

    taskTypeData.children.forEach((contractorData) => {
      contractorData.children.forEach((taskData) => {
        totalObject.apartmentCount += taskData.apartmentCount;
        totalObject.ladderCount += taskData.ladderCount;
        totalObject.hallCount += taskData.hallCount;
        totalObject.total += taskData.total;

        taskPriorities.add(taskData.taskPriority);
      });
    });

    taskPriorities.forEach((taskPriority) => {
      const taskPriorityTotal: AggregatedTask = {
        key: uniqueKey++,
        taskPriority,
        apartmentCount: 0,
        ladderCount: 0,
        hallCount: 0,
        total: 0,
      };

      taskTypeData.children.forEach((contractorData) => {
        contractorData.children.forEach((taskData) => {
          if (taskData.taskPriority === taskPriority) {
            taskPriorityTotal.apartmentCount += taskData.apartmentCount;
            taskPriorityTotal.ladderCount += taskData.ladderCount;
            taskPriorityTotal.hallCount += taskData.hallCount;
            taskPriorityTotal.total += taskData.total;
          }
        });
      });

      totalObject.children.push(taskPriorityTotal);
    });

    taskTypeData.apartmentCount = totalObject.apartmentCount;
    taskTypeData.ladderCount = totalObject.ladderCount;
    taskTypeData.hallCount = totalObject.hallCount;
    taskTypeData.total = totalObject.total;
    taskTypeData.children.push(totalObject);
  }

  const transformedData: AggregatedData[] = Object.values(aggregatedData);
  return transformedData;
};
