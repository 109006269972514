import { AxiosResponse } from 'axios';
import { HOST } from '@/services/YodaRestService';
import { axiosPut } from '@/services/helpers';
import { CableDescentRequest, CableDescentResponse } from '@/dto/CableDescentDto';

export async function saveCableDescent(
  id: string,
  cableDescent: CableDescentRequest
): Promise<AxiosResponse<CableDescentResponse, any>> {
  const url = HOST + `/rest/cable-descent/${id}`;
  return axiosPut(url, cableDescent);
}
