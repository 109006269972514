import { PpoInputExpendabResponse } from '@/dto/taskmap/task/TaskResultDto';
import { createStore } from '@/utils/createStore';

interface IExpendablePopup {
  isExpendableToolsPopupOpen: boolean;

  expendableTools: PpoInputExpendabResponse[];
  ppoVruInputId: string;
  reloadCounter: number;
  setInputExpendableList: (
    expendableTools: PpoInputExpendabResponse[],
    ppoVruInputId: string
  ) => void;

  setIsExpendableToolsPopupOpen: (isOpen: boolean) => void;

  addExpendableTool: (expendableTool: PpoInputExpendabResponse) => void;
  deleteExpendableTool: (expendableToolId: string) => void;
  forceReload: () => void;
}

const defaultValue: IExpendablePopup = {
  ppoVruInputId: '',
  isExpendableToolsPopupOpen: false,
  expendableTools: [],
  reloadCounter: 0,
  setIsExpendableToolsPopupOpen: () => null,
  setInputExpendableList: () => null,
  addExpendableTool: () => null,
  deleteExpendableTool: () => null,
  forceReload: () => null,
};

export const useExpendableToolsPopup = createStore<IExpendablePopup>(
  (set, get) => ({
    ...defaultValue,

    setIsExpendableToolsPopupOpen: (isOpen) => set({ isExpendableToolsPopupOpen: isOpen }),
    setInputExpendableList: (cables, ppoVruInputId) =>
      set({
        expendableTools: cables,
        ppoVruInputId: ppoVruInputId,
      }),

    addExpendableTool: (expendableTool) =>
      set({ expendableTools: [...get().expendableTools, expendableTool] }),

    deleteExpendableTool: (expendableToolId) => {
      set({
        expendableTools: [
          ...get().expendableTools.filter(
            (expendableTool) => expendableTool.id !== expendableToolId
          ),
        ],
      });
    },

    forceReload: () => set(() => ({ reloadCounter: get().reloadCounter + 1 })),
  }),
  'ExpendableTools popup'
);
