import React, { MutableRefObject, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { DataGrid, GridCellEditCommitParams, GridColDef, ruRU } from '@mui/x-data-grid';
import { SupplyPUItem } from '@/dto/taskmap/Dto';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';
import useSupplyStore from '../SupplyStore';
import { generateRowNum, generateScanId } from '../utils';
import { STATIC_COLUMNS } from './utils';
import {
  SupplyPUModalInner,
  PURemoveScannerModalButtonsWrapper,
  PURemoveScannerModalFormWrapper,
  PURemoveScannerModalInner,
  PURemoveScannerScannButton,
} from './PURemoveScanner.styled';
import { SupplyPuTypeResponse } from '@/dto/SupplyBillServiceDto';
import { updateRowNums } from '../SupplyPUComponent/utils';

interface PURemoveScannerProps {
  removeItems: (puItems: SupplyPUItem[], meterTypeId: number) => any;
  disabled?: boolean;
  meterTypes: SelectFilterOptType[];
  supplyMeterTypes: Map<number, SupplyPuTypeResponse>;
}

export default function PURemoveScanner(props: PURemoveScannerProps) {
  const billNumber = useSupplyStore((state) => state.billNumber);
  const refButton = useRef() as MutableRefObject<HTMLButtonElement>;

  const [selectedMeterType, setSelectedMeterType] = useState<SelectFilterOptType | null>(null);
  const [puListItems, setPUListItems] = useState<SupplyPUItem[]>([]);
  const [open, setOpen] = useState(false);
  const [openMeterTypeDialog, setOpenMeterTypeDialog] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedMeterType(null);
    setPUListItems([]);
    setOpen(false);
  };
  const handleMeterTypeDialogOpen = () => setOpenMeterTypeDialog(true);
  const handleMeterTypeDialogClose = () => setOpenMeterTypeDialog(false);

  const columns: GridColDef[] = [
    ...STATIC_COLUMNS,
    {
      field: 'action',
      headerName: 'Удалить',
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              removeRow(params.row.id);
            }}
          >
            Удалить
          </Button>
        );
      },
    },
  ];

  const addNewRow = () => {
    const rowScanId = generateScanId(puListItems);
    const rowNumId = generateRowNum(puListItems);
    const newPUList = puListItems.concat({
      tmpId: rowScanId,
      rowNum: rowNumId,
      meterTypeId: Number(selectedMeterType?.value),
      meterTypeName: selectedMeterType?.label ?? '',
      number: '',
      mac: '',
      id: null,
    });
    setPUListItems(newPUList);
  };

  const removeRow = (rowId: number) => {
    const maxId = Math.max(...puListItems.map((o) => o.tmpId));
    if (puListItems.length > 1 && rowId !== maxId) {
      const arr = [...puListItems];
      const scanIndex = arr.indexOf(arr.find((it) => it.tmpId === rowId)!);
      if (scanIndex !== -1) {
        arr.splice(scanIndex, 1);
        setPUListItems(updateRowNums(arr));
      }
    }
  };

  const commitNewRow = (params: GridCellEditCommitParams) => {
    const item = puListItems.find((it) => it.rowNum === params.id)!;
    if (selectedMeterType) {
      const supplyMeterType = props.supplyMeterTypes.get(selectedMeterType.value);
      if (supplyMeterType?.meterTypeScanField?.toLocaleLowerCase() === 'mac') {
        item.mac = params.value;
      } else if (supplyMeterType?.meterTypeScanField?.toLocaleLowerCase() === 'number') {
        item.number = params.value;
      }
    } else {
      if (params?.field?.toLocaleLowerCase() === 'number') {
        item.number = params.value;
      } else if (params?.field?.toLocaleLowerCase() === 'mac') {
        item.mac = params.value;
      }
    }

    if (
      item?.meterTypeId &&
      ((item.mac && item.mac?.length > 0) || (item.number && item.number?.length > 0))
    ) {
      const arr = [...puListItems];
      setPUListItems(arr);
    }
  };

  const removeItems = () => {
    refButton.current.focus();

    // Фикс onCellEditCommit не отрабатывает, если нажать кнопку сохранить без нажатие Enter
    // при ручном вводе номера
    setTimeout(() => {
      props.removeItems(puListItems, Number(selectedMeterType?.value));
      setPUListItems([]);
      setSelectedMeterType(null);
      handleClose();
    }, 0);
  };

  const handleStartScanClick = () => {
    addNewRow();
    handleMeterTypeDialogClose();
    handleOpen();
  };

  return (
    <div>
      <PURemoveScannerScannButton
        disabled={props.disabled}
        variant='contained'
        onClick={handleMeterTypeDialogOpen}
      >
        Удалить ПУ через сканирование
      </PURemoveScannerScannButton>
      <Modal
        open={openMeterTypeDialog}
        onClose={handleMeterTypeDialogClose}
      >
        <PURemoveScannerModalInner>
          <PURemoveScannerModalFormWrapper>
            <Typography variant='h6'>{'Выберите тип ПУ'}</Typography>
            <Autocomplete
              disablePortal
              value={selectedMeterType}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={props.meterTypes}
              sx={{ width: 200 }}
              onChange={(e, value) => {
                setSelectedMeterType(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Тип'
                  variant='standard'
                />
              )}
            />
          </PURemoveScannerModalFormWrapper>
          <PURemoveScannerModalButtonsWrapper>
            <Button
              variant='contained'
              onClick={handleStartScanClick}
              disabled={!selectedMeterType}
            >
              Начать сканирование
            </Button>
            <Button
              variant='contained'
              onClick={() => handleMeterTypeDialogClose()}
            >
              Отмена
            </Button>
          </PURemoveScannerModalButtonsWrapper>
        </PURemoveScannerModalInner>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <SupplyPUModalInner>
          <Grid
            container
            item
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-end'
            wrap='nowrap'
            width={'100%'}
            marginBottom={3}
          >
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                <h3 style={{ marginLeft: 5, fontSize: 38 }}>
                  {'Внимание! Просканированые ПУ будут удалены из накладной: ' + billNumber}
                </h3>
              </div>

              <Button
                variant='contained'
                sx={{ marginRight: 3 }}
                onClick={() => removeItems()}
                ref={refButton}
              >
                Сохранить
              </Button>
              <Button
                variant='outlined'
                onClick={handleClose}
              >
                Закрыть
              </Button>
            </div>
          </Grid>
          <DataGrid
            sx={{ height: '80%' }}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            rows={puListItems}
            disableColumnMenu
            getRowId={(row: any) => row.rowNum}
            columns={columns}
            onCellEditCommit={(params) => commitNewRow(params)}
          />
        </SupplyPUModalInner>
      </Modal>
    </div>
  );
}
