import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, keyframes, styled } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import HouseEntranceFlatCountMatched from '../HouseEntranceFlatCountMatched/HouseEntranceFlatCountMatched';

export const HouseEntranceHeaderRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const HouseEntranceHeaderBoxWrapper = styled('div')({
  display: 'flex',
  gap: 10,
});

export const HouseEntranceAutocompleteValue = styled(AutocompleteValue)({
  '.MuiInputBase-root::before': {
    borderBottom: '2px solid #000000 !important',
  },
});

export const HouseEntranceHeaderTextField = styled(TextField)({
  width: 135,
});

export const HouseEntranceHeaderInputsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  flexWrap: 'wrap',
});

export const HouseEntranceHeaderButtonsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 40,
});

export const HouseEntranceHeaderEditWrapper = styled(HouseEntranceHeaderInputsWrapper)({
  gap: 10,
  alignSelf: 'flex-start',
  flexWrap: 'nowrap',
});

export const HouseEntranceSettingsButton = styled(Button)({
  svg: {
    transition: '0.3s',
  },
  '&:hover': { svg: { transform: 'rotate(90deg)' } },
});

export const bounce = keyframes`
  0%, 20%, 53%, 80%, 100% {
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transform: translate3d(0,0,0);
  }
  40%, 43% {
  transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
  transform: translate3d(0, -8px, 0);
  }
  70% {
  transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
  transform: translate3d(0, -4px, 0);
  }
  90% { transform: translate3d(0,-1px,0);
  }
`;

export const flipAnimationKeyframes = keyframes`
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
    }
    40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transition-timing-function: ease-in;
    }
    60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
    }
    80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }
    100% {
    transform: perspective(400px);
    }
`;

export const shake = keyframes`
    0%, 100% {
  transform: translate3d(0, 0, 0);
  }
  15%, 45%,  75%  {
  transform: translate3d(-2px, 0, 0);
  }
  30%,  60%, 95% {
  transform: translate3d(2px, 0, 0);
  }
`;

export const pulse = keyframes`
  0%, 100% {
  transform: scale3d(1, 1, 1);
  }
  50% {
  transform: scale3d(1.1, 1.1, 1.1);
  }

`;

export const swingAnimationKeyframes = keyframes`
 20% {
  transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
  transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
  transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
  transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
  transform: rotate3d(0, 0, 1, 0deg);
  }
`;

export const HouseEntranceAnimationButton = styled(Button)({
  svg: { transformOrigin: 'center bottom' },
});

export const HouseEntranceMapButton = styled(HouseEntranceAnimationButton)({
  '&:hover': { svg: { animation: `${bounce} 1s 1 both` } },
});

export const HouseEntranceDocumentButton = styled(HouseEntranceAnimationButton)({
  '&:hover': { svg: { animation: `${flipAnimationKeyframes} 1s 1 both` } },
});

export const HouseEntranceDownloadButton = styled(LoadingButton)({
  svg: { transformOrigin: 'center bottom' },
  '&:hover': { svg: { animation: `${shake} 0.7s 1 both` } },
});

export const HouseEntranceCancelButton = styled(HouseEntranceAnimationButton)({
  '&:hover': { svg: { animation: `${pulse} 1s 1 both` } },
});

export const HouseEntrancePencilButton = styled(IconButton)({
  svg: { transformOrigin: 'center bottom' },
  '&:hover': { svg: { animation: `${swingAnimationKeyframes} 0.7s 1 both` } },
});

export const HouseEntranceHeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

export const HouseEntranceHeaderFlatCountMatched = styled(HouseEntranceFlatCountMatched)({
  display: 'flex',
  gap: 20,
});
