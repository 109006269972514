import StorehouseAdminCache from '@/components/blocks/StorehouseAdmin/StorehouseAdminCache/StorehouseAdminCache';
import StorehouseAdminOs from '@/components/blocks/StorehouseAdmin/StorehouseAdminOs/StorehouseAdminOs';
import StorehouseAdminPu from '@/components/blocks/StorehouseAdmin/StorehouseAdminPu/StorehouseAdminPu';
import { StoreHouseComponentWrapper } from '@/components/storehouse/StoreHouseComponent/StoreHouseComponent.styled';
import StoreHouseTabPanel from '@/components/storehouse/StoreHouseComponent/StoreHouseTabPanel';
import { Tabs, Tab } from '@mui/material';
import React from 'react';

const StorehouseAdminPanel = () => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <StoreHouseComponentWrapper>
      <Tabs
        value={activeTab}
        onChange={handleChange}
      >
        <Tab label='Приборы учета' />
        <Tab label='Сетевое оборудование' />
        <Tab label='Очистка кеша' />
      </Tabs>

      <StoreHouseTabPanel
        value={activeTab}
        index={0}
        unmount
      >
        <StorehouseAdminPu />
      </StoreHouseTabPanel>

      <StoreHouseTabPanel
        value={activeTab}
        index={1}
        unmount
      >
        <StorehouseAdminOs />
      </StoreHouseTabPanel>

      <StoreHouseTabPanel
        value={activeTab}
        index={2}
        unmount
      >
        <StorehouseAdminCache />
      </StoreHouseTabPanel>
    </StoreHouseComponentWrapper>
  );
};

export default StorehouseAdminPanel;
