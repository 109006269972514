import { AxiosResponse } from 'axios';
import { HOST } from './YodaRestService';
import { axiosDelete, axiosGet, axiosPost } from './helpers';
import { GeoCoordinate } from '@/components/maps/types';
import {
  CoveredItem,
  ZoningBoundRequest,
  ZoningBoundResponse,
  ZoningGroupResponse,
  ZoningResponse,
  ZoningTaskFilter,
} from '@/components/maps/zoningMap/Dto';
import { GeoTask } from '@/dto/taskmap/Dto';

export async function create(
  groupId: string,
  coordinate: GeoCoordinate[]
): Promise<AxiosResponse<ZoningGroupResponse, any>> {
  const url = HOST + `/rest/group/${groupId}/zoning`;
  return axiosPost(url, coordinate);
}

export async function remove(zoningId: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/group/zoning/${zoningId}`;
  return axiosDelete(url);
}

export async function findByGroupId(
  groupId: string
): Promise<AxiosResponse<ZoningGroupResponse, any>> {
  const url = HOST + `/rest/group/${groupId}/zoning`;
  return axiosGet(url);
}

export async function findAllZonningGroup(): Promise<AxiosResponse<ZoningGroupResponse[], any>> {
  const url = HOST + `/rest/group/zoning`;
  return axiosGet(url);
}

export async function findZoningCoverage(
  groupId: string,
  filter: ZoningTaskFilter
): Promise<AxiosResponse<GeoTask[], any>> {
  const url = HOST + `/rest/geo/group/${groupId}/zoning/coverage`;
  return axiosPost(url, filter);
}

export async function findAllZoningBound(
  request: ZoningBoundRequest
): Promise<AxiosResponse<ZoningBoundResponse[], any>> {
  const url = HOST + `/rest/geo/zoning/map`;
  return axiosPost(url, request);
}

export async function findAllByZoningByFilter(
  filter: ZoningTaskFilter
): Promise<AxiosResponse<ZoningResponse, any>> {
  const url = HOST + `/rest/geo/zoning/search`;
  return axiosPost(url, filter);
}

export async function assignTasks(
  coveredItemList: CoveredItem[]
): Promise<AxiosResponse<Map<string, string[]>, any>> {
  const url = HOST + `/rest/geo/zoning/assign`;
  return axiosPost(url, coveredItemList);
}
