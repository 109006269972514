import { color } from '@/styles/mixins';
import { Button, FormHelperText, styled, Typography } from '@mui/material';

export const FileInputContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  alignItems: 'flex-end',
});

interface FileInputLabelTextProps {
  disabled?: boolean;
}
export const FileInputLabelText = styled(Typography)<FileInputLabelTextProps>(({ disabled }) => ({
  color: color('muiBlue'),
  textAlign: 'end',
  cursor: 'pointer',
  lineHeight: 'unset',
  padding: '0 8px',
  userSelect: 'none',

  ...(disabled && { pointerEvents: 'none', color: color('gray') }),
}));

export const InputFileErrorText = styled(FormHelperText)({
  color: color('muiError'),
  marginTop: 0,
  padding: '0 8px',
});

export const InputFileAttachButton = styled(Button)({
  marginTop: 0,
});

export const InputFileErrorInfoIconWrapper = styled('sup')({
  marginLeft: 4,
});

export const InputFileProgressWrapper = styled('div')({
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const InputFilesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: 12,
  minWidth: 300,
});

export const InputFileItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const FileDeleteModalWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  alignItems: 'center',
});

export const FileDeleteModalButtonsWrapper = styled('div')({
  display: 'flex',
  marginTop: 15,
  gap: 25,
});
