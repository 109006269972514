import { Box } from '@mui/material';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  unmount?: boolean;
}

const StoreHouseTabPanel = (props: TabPanelProps) => {
  const { children, value, index, unmount, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      style={{ width: '100%' }}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {unmount ? (
        <>{value === index && <Box sx={{ p: 3 }}>{children}</Box>}</>
      ) : (
        <Box sx={{ p: 3, display: value === index ? 'block' : 'none' }}>{children}</Box>
      )}
    </div>
  );
};

export default StoreHouseTabPanel;
