import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Divider, IconButton } from '@mui/material';
import {
  ArrowBackIcon,
  ClickedText,
  NavigationWrapper,
} from '@/components/features/drive/navigation/Navigation.styled';
import Breadcrumbs from '@/components/features/drive/breadcrumbs/Breadcrumbs';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const Navigation = () => {
  const navigate = useNavigate();
  const { groupId, folderId, resGroupId } = useParams();
  const handleClick = () => {
    if (resGroupId && groupId && folderId) {
      navigate(`/registry/drive/${resGroupId}/${groupId}`);
    }
    if (resGroupId && groupId && !folderId) {
      navigate(`/registry/drive/${resGroupId}`);
    }
    if (resGroupId && !groupId && !folderId) {
      navigate(`/registry/drive`);
    }
  };

  const metaTitle = () => {
    let title = resGroupId?.split('_')[0];
    if (groupId) {
      title += ` / ${groupId.split('_')[0]}`;
    }
    if (folderId) {
      title += ` / ${folderId.split('_')[0]}`;
    }
    return title;
  };
  useMetaTitle(`${metaTitle()} - ИД ТехПрис`);
  return (
    <>
      <NavigationWrapper>
        <Breadcrumbs>
          {resGroupId && (
            <ClickedText onClick={() => navigate(`/registry/drive/${resGroupId}`)}>
              {resGroupId.split('_')[0]}
            </ClickedText>
          )}
          {resGroupId && groupId && (
            <ClickedText onClick={() => navigate(`/registry/drive/${resGroupId}/${groupId}`)}>
              {groupId.split('_')[0]}
            </ClickedText>
          )}
          {folderId && <div>{folderId.split('_')[0]}</div>}
        </Breadcrumbs>
        <IconButton onClick={handleClick}>
          <ArrowBackIcon />
        </IconButton>
      </NavigationWrapper>
      <Divider />
    </>
  );
};

export default Navigation;
