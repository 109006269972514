import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useNavbar } from '@/components/widgets/Navbar/store';

const ActionLogsSnackbar = () => {
  const setOpenErrorsDialog = useNavbar((state) => state.setOpenErrorsDialog);

  const { enqueueSnackbar } = useSnackbar();
  const { setActionLogViewed, actionLogList } = useActionLog();
  useEffect(() => {
    actionLogList.forEach((t) => {
      if (!t.viewed) {
        enqueueSnackbar(t.message, {
          variant: t.type,
          key: t.id,
          onClick: () => setOpenErrorsDialog(true),
        });
        setActionLogViewed(t.id);
      }
    });
  }, [actionLogList]);
  return null;
};

export default ActionLogsSnackbar;
