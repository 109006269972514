import { styled } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';

export const GroupWrapper = styled(BoxShadow)({
  marginTop: 30,
  width: 270,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginLeft: 30,
  cursor: 'pointer',
});

export const GroupImg = styled('img')({
  width: 200,
  height: 200,
  objectFit: 'contain',
  objectPosition: 'center',
});
export const GroupName = styled('div')({
  fontWeight: 'bold',
});
