import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

interface CallTypeModalTypeSixProps {
  refund: (taskId: string, requestId: string, resultJobLog: string, comment: string, file: string, fileName: string, fileExt: string) => any;
  taskStatus: string;
  taskId: string;
  requestId: string;
}

export default function CallTypeModalTypeSix(props: CallTypeModalTypeSixProps) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [file, setFile] = React.useState<string>("");
  const [fileName, setFileName] = React.useState<string>("");
  const [fileExt, setFileExt] = React.useState<string>("");
  const [comment, setComment] = React.useState("");


  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const files = e.target.files;

    if (files) {
      const fileRef = files[0] || ""
      const fileType: string = fileRef.type || ""
      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = (ev: any) => {
        setFileExt(files[0].name.split(".")[1])
        setFileName(files[0].name)
        setFile(reader.result as string)
      }
    }
  }

  const clearFields = () => {
    setFile("")
    setFileName("")
    setComment("")
  }
  const saveRefund = () => {
    props.refund(props.taskId, props.requestId, "Описал решение проблемы и прикрепил фото", comment, file, fileName, fileExt)
    clearFields()
    setOpen(false)
  }

  return (
    <div>
      <Button onClick={handleOpen}>Принять</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Опишите кратко, как Вы решили проблему и прикрепите фото чека или ПУ который Вы вернули
          </Typography>

          <TextField
            fullWidth
            multiline
            rows={4}
            id="comment-field"
            label="Комментарий"
            value={comment}
            variant="outlined"
            onChange={(e) => setComment(e.target.value)} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginBottom: 2 }}>
            <Button
              sx={{ marginTop: 2 }}
              variant="contained"
              component="label"
            >
              Загрузить файл
              <input
                style={{ width: 250 }}
                type="file"
                hidden
                onChange={handleImageChange}
              />
            </Button>
            <InputLabel sx={{ minWidth: 10, fontSize: '0.8rem', ml: 2 }}>{fileName}</InputLabel>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'end', marginTop: 10 }}>
            <Button onClick={(e) => {
              clearFields()
              setOpen(false)
            }}>Отмена</Button>
            <Button onClick={(e) => {
              saveRefund()
            }}>Сохранить</Button>

          </div>
        </Box>
      </Modal>
    </div>
  );
}
