import DatePicker, { DateObject } from 'react-multi-date-picker';
import React, { FC, useState } from 'react';
import { ArgumentSelect } from '../ArgumentSelectNew';
import { FormControl, FormHelperText, IconButton } from '@mui/material';
import { FilterArgEnum } from '../ArgumentSelectNew/types';
import { DataRangeType } from './types';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { monthsDataRange, weekDaysDataRange } from './constants';
import './styles.scss';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { color } from '@/styles/mixins';

const agrumentTypes: FilterArgEnum[] = [
  FilterArgEnum.LESS,
  FilterArgEnum.MORE,
  FilterArgEnum.RANGE,
  FilterArgEnum.EQUAL,
  FilterArgEnum.NOT_EQUAL,
];

export const DateRange: FC<DataRangeType> = ({
  label,
  argList = agrumentTypes,
  onArgChange,
  onChange,
  value,
  argValue,
  isInRange,
  showSelect = true,
  showCalenderIcon = false,
  style = {},
  isDisable = false,
  showClearButton = true,
  showPalceHolder = true,
  labelstyle = {},
  errorText,
  id,
  calendarPosition,
}) => {
  const [isFocuse, setIsFocuse] = useState<boolean>(false);

  return (
    <div>
      <div
        className='parent'
        style={{ marginTop: 16 }}
      >
        {showSelect && (
          <ArgumentSelect
            onArgChange={onArgChange}
            argList={argList}
            argValue={argValue}
          />
        )}
        <FormControl
          className='child child_input'
          variant='standard'
          style={{ position: 'relative' }}
        >
          {argValue === FilterArgEnum.RANGE ? (
            <>
              <DatePicker
                id={id}
                inputClass={`data-range-input${value?.length >= 2 ? ' entered' : ''}`}
                style={{
                  borderBottomColor: isInRange && value.length > 0 ? 'red' : '',
                  ...style,
                }}
                weekStartDayIndex={1}
                months={monthsDataRange}
                weekDays={weekDaysDataRange}
                value={value.map((elem: any) => new DateObject(elem))}
                onChange={(selectedDates) => {
                  onChange((selectedDates as DateObject[]).map((elem) => elem.valueOf()));
                }}
                onFocus={() => setIsFocuse(true)}
                onBlur={() => setIsFocuse(false)}
                placeholder={isFocuse ? '' : showPalceHolder ? label : ''}
                format={'DD.MM.YY'}
                range
                disabled={isDisable}
              />
              {showCalenderIcon && <CalendarMonthIcon style={{ color: color('gray1') }} />}
            </>
          ) : (
            <>
              <div style={{ display: 'flex' }}>
                <DatePicker
                  inputClass={`data-range-input${value[0] ? ' entered' : ''}`}
                  weekStartDayIndex={1}
                  months={monthsDataRange}
                  weekDays={weekDaysDataRange}
                  value={value[0] ? new DateObject(value[0]) : null}
                  onChange={(selectedDates) => {
                    onChange([(selectedDates as DateObject).valueOf()]);
                  }}
                  onFocus={() => setIsFocuse(true)}
                  onBlur={() => setIsFocuse(false)}
                  placeholder={isFocuse ? '' : showPalceHolder ? label : ''}
                  format={'DD.MM.YY'}
                  style={{ color: isDisable ? color('gray1') : '', ...style }}
                  disabled={isDisable}
                  calendarPosition={calendarPosition}
                />
                {showCalenderIcon && (
                  <CalendarMonthIcon style={{ color: isDisable ? color('gray1') : '#757575' }} />
                )}
              </div>
              {errorText && (
                <FormHelperText style={{ color: color('trueRed') }}>{errorText}</FormHelperText>
              )}
            </>
          )}
          {value.length > 0 && (
            <div
              className={'data-range-input-label'}
              style={{ color: isDisable ? color('gray1') : '#757575', ...labelstyle }}
            >
              {label}
            </div>
          )}
          {showClearButton && value.length > 0 && (
            <IconButton
              size='small'
              aria-label='delete'
              style={{ position: 'absolute', right: '-5px' }}
              onClick={() => onChange([])}
            >
              <ClearRoundedIcon fontSize={'small'} />
            </IconButton>
          )}
        </FormControl>
      </div>
    </div>
  );
};
