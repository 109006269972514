import { Tooltip, Typography } from '@mui/material';

interface TypographyReducerProps {
  value: string;
  length: number;
}

export const TypographyReducer = (props: TypographyReducerProps) => {
  const { value, length } = props;
  return (
    <Tooltip title={value}>
      <Typography>
        {value.length < length ? value : `${value.substring(0, length - 3)}...`}
      </Typography>
    </Tooltip>
  );
};
