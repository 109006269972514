import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { TransStationFeederResponse } from '@/dto/taskmap/task/TaskResultDto';
import { getTransFeederImageGroup } from '../panel/utils';
import { useGalleryData } from '@/components/Gallery/store';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { FormPU } from './FormPU';
import { FormTT } from './FormTT';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { LOCK_STYLE } from './utils';
import { useStatus } from '../panel/store';
import { TextField } from '@/components/fixUI';

interface ItemInputProps {
  transFeeder: TransStationFeederResponse;
  keyStr: string;
  isInput?: boolean;
}

export const ItemInput: FC<ItemInputProps> = ({ transFeeder, keyStr, isInput = false }) => {
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);
  const [lock, setLock] = useState<boolean>(false);

  const feederType = isInput ? 'INPUT' : 'CONSUMER';

  const { setValue, register } = useFormContext();

  useEffect(() => {
    setValue(`${keyStr}.id`, transFeeder.id);
    setValue(
      `${keyStr}.tpFeederType`,
      transFeeder?.tpFeederType ? transFeeder.tpFeederType : feederType
    );
  }, [transFeeder]);

  const handleClick = (e: any) => {
    e.stopPropagation();
    !lock && setImageGroups(getTransFeederImageGroup(transFeeder));
  };
  const toggleDelete = () => {
    setValue(`${keyStr}.deleted`, !lock);
    setLock(!lock);
  };
  const { isDisabledAll } = useStatus((state) => ({
    isDisabledAll: state.isDisabledAll,
  }));

  return (
    <BoxShadow
      sx={{ backgroundColor: '#FCFCFC', position: 'relative' }}
      onDoubleClick={handleClick}
    >
      {lock && (
        <div style={LOCK_STYLE}>
          <Button
            onClick={toggleDelete}
            disabled={isDisabledAll()}
          >
            Восстановить
          </Button>
        </div>
      )}
      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Grid
          item
          xs={12}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {isInput ? (
              <Typography variant={'h5'}>Ввод</Typography>
            ) : (
              <>
                <TextField
                  id='tf-num'
                  label='Название'
                  variant='standard'
                  sx={{ mr: 2 }}
                  {...register(`${keyStr}.num`, {
                    value: transFeeder?.num,
                  })}
                  disabled={isDisabledAll()}
                />
                <Typography variant={'h5'}>Фидер 0,4 кВ</Typography>
              </>
            )}
            {!isInput && (
              <IconButton
                sx={{ ml: 'auto' }}
                onClick={toggleDelete}
                disabled={isDisabledAll()}
              >
                <DeleteOutlineRoundedIcon />
              </IconButton>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <FormPU
            keyStr={`${keyStr}.tpMeter`}
            meter={transFeeder.tpMeter}
          />
        </Grid>
        <Grid
          item
          xs={7}
        >
          <FormTT
            keyStr={`${keyStr}.tpMeter.ppoStolbiTransformator`}
            meter={transFeeder?.tpMeter?.ppoStolbiTransformator}
          />
        </Grid>
      </Grid>
    </BoxShadow>
  );
};
