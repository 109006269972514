import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import { usePnrPlanningStore } from '@/components/sections/PnrPlanning/store';
import { BoxShadow } from '@/components/ui/BoxShadow';
import {
  PnrPlanningModalFirstStep,
  PnrPlanningModalSecondStep,
  PnrPlanningModalThirdStep,
} from '../PnrPlanningModalSteps/PnrPlanningModalSteps';
import { PnrPlanningCommonModal } from './PnrPlanningModal.styled';
import { getSubtitle, PNR_PLANNING_MODAL_STEPS } from './utils';
import shallow from 'zustand/shallow';

const PnrPlanningModal = () => {
  const { openModalType, activeStep, closeModal } = usePnrPlanningStore(
    (state) => ({
      openModalType: state.openModalType,
      activeStep: state.activeStep,
      closeModal: state.closeModal,
    }),
    shallow
  );

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <PnrPlanningModalFirstStep />;
      case 1:
        return <PnrPlanningModalSecondStep />;
      case 2:
        return <PnrPlanningModalThirdStep />;
      default:
        return <></>;
    }
  };

  return (
    <PnrPlanningCommonModal
      open={!!openModalType}
      onClose={closeModal}
      sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
    >
      <BoxShadow sx={{ padding: '24px 16px' }}>
        <Stepper activeStep={activeStep}>
          {PNR_PLANNING_MODAL_STEPS.map((step, index) => (
            <Step key={step.title}>
              <StepLabel optional={getSubtitle(step, activeStep, index)}>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </BoxShadow>

      {renderStep()}
    </PnrPlanningCommonModal>
  );
};

export default PnrPlanningModal;
