import { Box, Grid, Stack, Typography } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { ExpandButton } from '@/components/button/ExpandButton';
import { ModeViewerResult } from '@/components/features/taskEditor/types';
import { useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { color } from '@/styles/mixins';
import EditConsumableEquipment from './EditConsumableEquipment';
import { useNetworkStore } from './store';
import { Spin } from 'antd';

export const ConsumableEquipment = ({ networkId }: { networkId: string }) => {
  const { getConsumableEquipment, consumableEquipment, loading } = useNetworkStore((store) => ({
    getConsumableEquipment: store.getConsumableEquipment,
    consumableEquipment: store.consumableEquipment,
    loading: store.loading,
  }));
  const [mode, setMode] = useState<ModeViewerResult>(ModeViewerResult.SMALL);
  const [isOpen, setIsOpen] = useState(false);
  const handleMode = (forMode: ModeViewerResult) => (isExpanded: boolean) => {
    if (forMode === ModeViewerResult.SMALL) {
      !consumableEquipment[networkId] && getConsumableEquipment(networkId);
      setMode(isExpanded ? ModeViewerResult.LARGE : ModeViewerResult.SMALL);
    } else if (forMode === ModeViewerResult.LARGE) {
      setMode(isExpanded ? ModeViewerResult.LARGE : ModeViewerResult.SMALL);
    }
  };

  return (
    <BoxShadow>
      {mode === ModeViewerResult.SMALL && (
        <Grid
          container
          alignItems='center'
          width='100%'
        >
          <Grid
            item
            xs={'auto'}
          >
            <Typography variant='h6'>Расходное оборудование</Typography>
          </Grid>
          <Grid
            item
            sx={{ ml: 'auto' }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <ExpandButton onAction={handleMode(ModeViewerResult.SMALL)} />
            </Box>
          </Grid>
        </Grid>
      )}
      {mode === ModeViewerResult.LARGE && (
        <Stack>
          <Grid
            container
            item
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              item
              xs={12}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              mb={2}
            >
              <Typography variant='h6'>Расходное оборудование</Typography>
              {!!consumableEquipment[networkId]?.length && (
                <BorderColorIcon
                  sx={{ cursor: 'pointer' }}
                  htmlColor={color('muiBlue')}
                  onClick={() => setIsOpen(true)}
                />
              )}
            </Grid>
            <Grid
              container
              spacing={1}
              xs={12}
            >
              <Grid
                container
                spacing={1}
                xs={12}
              >
                {loading ? (
                  <Grid
                    item
                    xs={12}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Spin />
                  </Grid>
                ) : consumableEquipment[networkId]?.length ? (
                  consumableEquipment[networkId].map((el) => (
                    <Grid
                      item
                      xs={3}
                      key={el.id}
                    >
                      <Typography variant='body2'>{el.description}</Typography>
                      <Typography variant='h6'>
                        {el.quantity} {el.unit}
                      </Typography>
                    </Grid>
                  ))
                ) : (
                  <Grid
                    item
                    xs={12}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Typography variant='h6'>Нет расходного оборудования</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              gap={1}
              item
              display='flex'
              ml={'auto'}
            >
              <ExpandButton
                expand={mode === ModeViewerResult.LARGE}
                onAction={handleMode(ModeViewerResult.LARGE)}
              />
            </Grid>
          </Grid>
        </Stack>
      )}
      <EditConsumableEquipment
        isOpen={isOpen}
        networkId={networkId}
        onClose={() => setIsOpen(false)}
      />
    </BoxShadow>
  );
};
