import { color } from '@/styles/mixins';
import { styled } from '@mui/material';
import { DataGridPro as DataGrid_ } from '@mui/x-data-grid-pro';

export const DataGrid = styled(DataGrid_)({
  '& .MuiDataGrid-columnHeadersInner': {
    lineHeight: 2,
  },
  '& .MuiDataGrid-root': {
    display: 'flex',
    textAlign: 'center',
  },

  '& .MuiDataGrid-overlay': {
    minHeight: 400,
  },
  '& .highlight': {
    backgroundColor: color('lightGreen'),
    '&:hover': {},
  },
  '& .highlightCell': {
    backgroundColor: color('lightGreen'),
    '&:hover': {},
  },
  '& .highlightChoosenCell': {
    backgroundColor: color('sandy'),
    '&:hover': {},
  },
});
