import React, { FC, useState } from 'react';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Button } from '@mui/material';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { GetDownloadPhotoArchive } from '@/services/DownloadPhotoArchiveService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { deletePUItem } from '@/services/CatalogsService';

export const DownloadPhotoArchive: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const { fetchCatch, addActionLog } = useActionLog();

  const methods = useForm();
  const { register, handleSubmit, setValue } = methods;

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    setValue('resId', undefined);
  };
  const handleSuccess = (data: any) => {
    GetDownloadPhotoArchive(data.resId)
      .then((res) => {
        addActionLog(ActionLogType.SUCCESS, 'Архив фото отправлен на почту');
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка загрузки фото');
      })
      .finally(handleClose);
  };

  return (
    <>
      <Button
        startIcon={<DownloadRoundedIcon />}
        sx={{ alignSelf: 'flex-start' }}
        onClick={handleOpen}
      >
        Архив фото
      </Button>
      <AccessPopup
        isOpen={isOpen}
        onClose={handleClose}
        onSuccess={handleSubmit(handleSuccess)}
        titleText={'ВЫБЕРИТЕ РЭС ДЛЯ СКАЧИВАНИЯ АРХИВА ФОТО'}
        closeText={'Отмена'}
        successText={'Скачать'}
      >
        <FormProvider {...methods}>
          <AutocompleteValue
            title='РЭС'
            // defaultValue={defaultData?.meterType ? `${defaultData.meterType}` : undefined}
            fieldName={`resId`}
            values={getCatalogMapWithErr('regionalElectricNetwork')}
          />
        </FormProvider>
      </AccessPopup>
    </>
  );
};
