import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, IconButton, Typography, Tooltip } from '@mui/material';
import { saveAs } from 'file-saver';

import { useActionLog } from '@/hooks/ActionLogHook';
import { BaseUI } from '@/types/ui';
import { DownloadAllLoadingWrapper, DownloadAllButtonWrapper } from './DownloadAllButton.styled';

interface DownloadFile {
  url: Promise<string> | string;
  folder: string;
  fileName?: string;
}

interface DownloadAllButtonProps extends BaseUI {
  disabled?: boolean;
  buttonTitle?: string;
  zipName?: string;
  downloadLinksPromises: DownloadFile[];
  text?: string;
  buttonText?: string;
}

const DownloadAllButton = ({
  disabled,
  buttonTitle,
  className,
  zipName,
  downloadLinksPromises,
  text,
  buttonText,
}: DownloadAllButtonProps) => {
  const { catchError } = useActionLog();
  const [loading, setLoading] = useState(false);

  const handleDownloadClick = async () => {
    setLoading(true);

    try {
      const zip = (await import('jszip')).default();

      const filePromises = downloadLinksPromises.map(async (file, index) => {
        const fileUrl = await file.url;
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const filename = `${file.folder ? `${file.folder}/` : ''}${
          file.fileName ?? index + 1
        }.${blob.type.split('/').pop()}`;
        zip.file(filename, blob);
      });

      await Promise.all(filePromises).then(() => {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `${zipName ?? 'Архив'}.zip`);
        });
      });
    } catch (error) {
      catchError('Ошибка загрузки архива фотографий', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DownloadAllButtonWrapper className={className}>
      {buttonText ? (
        loading ? (
          <DownloadAllLoadingWrapper>
            <CircularProgress size={15} />
          </DownloadAllLoadingWrapper>
        ) : (
          <Button
            sx={{ width: '100%' }}
            disabled={disabled}
            onClick={handleDownloadClick}
            endIcon={<DownloadIcon color={disabled ? undefined : 'primary'} />}
          >
            {buttonText}
          </Button>
        )
      ) : (
        <>
          <Typography>{text}</Typography>
          {loading ? (
            <DownloadAllLoadingWrapper>
              <CircularProgress size={15} />
            </DownloadAllLoadingWrapper>
          ) : (
            <Tooltip title={buttonTitle ?? 'Скачать'}>
              <IconButton
                onClick={handleDownloadClick}
                size='large'
                disabled={disabled}
              >
                <DownloadIcon color={disabled ? undefined : 'primary'} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </DownloadAllButtonWrapper>
  );
};

export default DownloadAllButton;
