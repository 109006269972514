import { Box, Grid } from '@mui/material';
import { Filter } from './filter';
import { PpoMapPane } from './mapPage';
import ResCard from '@/components/maps/ppoMap/resCard';
import ConsumerCard from '@/components/maps/ppoMap/consumerCard';
import { usePpoMap } from '@/components/maps/ppoMap/store';
import { useMemo } from 'react';
import { GeoTask } from '@/dto/taskmap/Dto';
import { DeliveryPointCard } from '@/components/maps/ppoMap/placemark/delivery-point/DeliveryPointCard';
import { PillarCard } from './placemark/pillars/PillarCard';

const SCREEN_ID = 'screenShotMap';

export const PpoMap = () => {
  const { geoTaskInBounds, tmpTaskId, geoTransStation } = usePpoMap();

  const geoTask: GeoTask = useMemo(() => {
    return geoTaskInBounds
      ?.flatMap((elem) => elem.geoTaskList) // распределяет все списки задач в один массив
      .find((task) => task.taskId === tmpTaskId);
  }, [tmpTaskId, geoTaskInBounds, geoTransStation?.deliveryPoints]) as GeoTask;

  return (
    <Box sx={{ m: 2 }}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={tmpTaskId ? 8 : 12}
          container
          direction='column'
          height='100%'
          spacing={2}
        >
          <Grid item>
            <Filter />
          </Grid>
          <Grid
            item
            xs={10}
            sx={{ overflow: 'hidden', minHeight: 700 }}
            width='100%'
            height='100%'
            id={SCREEN_ID}
          >
            <PpoMapPane />
          </Grid>
        </Grid>
        {tmpTaskId && (
          <Grid
            item
            xs={4}
            sx={{ height: '94vh', overflow: 'scroll' }}
          >
            <DeliveryPointCard />
            <ConsumerCard />
            {tmpTaskId && geoTask && (
              <ResCard
                {...geoTask}
                t={geoTask}
                deliveryPoints={geoTransStation?.deliveryPoints}
              />
            )}
            <PillarCard />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
