import { styled } from '@mui/material/styles';
import { color } from '@/styles/mixins';
import { Box, Stack } from '@mui/material';

export const StackCenter = styled(Stack)({
  alignItems: 'center',
  marginBottom: 8,
  marginTop: 8,
});

export const TitleDiv = styled('div')({
  width: 170,
  fontWeight: 400,
  fontSize: '20px',
});
export const CountDiv = styled('div')({
  width: 40,
  textAlign: 'right',
  cursor: 'pointer',
  color: color('muiBlue'),
  fontWeight: 500,
  fontSize: '20px',
});

export const MoreInfo = styled(Box)({
  marginLeft: -8.5,
  padding: '0 8px',
  marginTop: 15,
  border: `0.5px solid ${color('chronologyLine')}`,
  borderRadius: 5,
  backgroundColor: color('trueWhite'),
});
