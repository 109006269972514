import { HeadCell } from '../TableCells';

export const headCells: HeadCell[] = [
  {
    id: 'type',
    disablePadding: false,
    label: 'Тип',
  },
  {
    id: 'adress',
    disablePadding: false,
    label: 'Адрес',
  },
  {
    id: 'data',
    disablePadding: false,
    label: 'Плановая дата',
  },
  {
    id: 'executor',
    disablePadding: false,
    label: 'Исполнитель',
  },
  {
    id: 'responsible',
    disablePadding: false,
    label: 'Ответственный',
  },
  {
    id: 'managementCompany',
    disablePadding: false,
    label: 'Управляющая компания',
  },
];
