import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { FilterArgEnum } from '../../inputs/ArgumentSelectNew/types';
import { createStore } from '../../../utils/createStore';
import {
  ExecuterType,
  IInstallationCall,
  IgeoPointCallingMap,
  RequestHistoryType,
  TechInspectionType,
  WEB_RTC_CALL_STATUS,
} from './types/types';
import { SubscriberResponse } from '@/dto/SubscribersDto';
import { handleExecutorSchedule } from './utils/CallingMapUtils';
import { formatPhoneNumberMask } from '@/utils/heplers';
export type ISingleInput = {
  phoneNumber: SingleArg;
  termTaskId: SingleArg;
};
export interface SelectValue {
  value: string;
  argValue?: FilterArgEnum;
  label: string;
}
export interface SingleArg {
  value: string;
  argValue: FilterArgEnum;
}
export interface DateArg {
  value: number[];
  argValue: FilterArgEnum;
}

export interface MultiArg {
  value: SelectFilterOptType[];
  argValue: FilterArgEnum;
}

export type IDateInput = {
  callPeriod: DateArg;
  requestPeriod: DateArg;
};

export type IMultiInput = {
  installationCallStatus: MultiArg;
  tkoPeriodDefiner: MultiArg;
  phase: MultiArg;
  taskType: MultiArg;
  callCenterRequestType: MultiArg;
  taskStatus: MultiArg;
  montagePlaceType: MultiArg;
};
type ObjectKey<Obj> = keyof Obj;

export type MultiInputKeys = ObjectKey<IMultiInput>;
export type DateInputKeys = ObjectKey<IDateInput>;
export type SingleInputKeys = ObjectKey<ISingleInput>;
export type AllInputKeys = MultiInputKeys | DateInputKeys;

// Define types for your actions
interface Actions {
  setSingleValue: (key: SingleInputKeys) => (value: string) => void;
  setSingleValueArg: (key: SingleInputKeys) => (argValue: FilterArgEnum) => void;
  setDefaultValue: () => void;
  setGeoPoints: (data: IgeoPointCallingMap[]) => void;
  setMapCenterCoordinats: (coordinats: number[]) => void;
  setSelectExecotorSchedule: (executor: ExecuterType | null) => void;
  setTKO: (data: TechInspectionType[]) => void;
  setRequestHistory: (data: RequestHistoryType[]) => void;
  setUrgentPoints: (data: IgeoPointCallingMap[]) => void;
  setTaskId: (taskId: string) => void;
  setSingleAbonent: (data: SubscriberResponse) => void;
  setExecutors: (data: ExecuterType[]) => void;
  setAvalibleExecutors: (data: ExecuterType[]) => void;
  setOpenPopup: (open: boolean) => void;
  setIsOpenEventCalendarPopUp: (open: boolean) => void;
  setOpenAddAbonentPopup: (open: boolean) => void;
  setIsOpenSettings: (open: boolean) => void;
  setSingleGeoPoint: (data: IInstallationCall | null) => void;
  setIsLoading: (loading: boolean) => void;
  setIsConnection: (connect: boolean) => void;
  setMapZoom: (mapZoom: number) => void;
  setGeoLess: (geoLess: IgeoPointCallingMap[]) => void;
  claerSingleGeoPoint: () => void;
  setToggleUrgentGeoPoints: () => void;
  resestConnection: () => void;
  setOpenSnackBar: (open: boolean) => void;
  setBounds: (bounds: number[][]) => void;
  setGeoLessCount: (count: number) => void;
  setInitBounds: (initBounds: number[][]) => void;
  setMapAPI: (mapAPI: any) => void;
  setIsAuthorized: (isAuthorized: boolean) => void;
  startConnection: () => void;
  setWebRtcCallStatus: (status: WEB_RTC_CALL_STATUS) => void;
  setCurrentPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;

  setDateValue: (key: DateInputKeys) => (value: number[]) => void;
  setDateValueArg: (key: DateInputKeys) => (argValue: FilterArgEnum) => void;
  setMultiValue: (key: MultiInputKeys) => (value: SelectFilterOptType[]) => void;
  setMultiValueArg: (key: MultiInputKeys) => (argValue: FilterArgEnum) => void;
}

interface CallingMapState {
  geoPoints: IgeoPointCallingMap[];
  urgentGeoPoints: IgeoPointCallingMap[];
  singleGeoPoint: IInstallationCall | null;
  singleAbonent: SubscriberResponse | null;
  isOpenPopUp: boolean;
  isOpenEventCalendarPopUp: boolean;
  isOpenSettings: boolean;
  toggleUrgentGeoPoins: boolean;
  isLoading: boolean;
  taskId: string | null;
  executors: ExecuterType[];
  avalibleExecutors: ExecuterType[];
  isConnecting: boolean;
  endSession: boolean;
  isOpenSnackBar: boolean;
  bounds: number[][];
  initBounds: number[][];
  mapZoom: number;
  mapAPI: null | any;
  geoLess: IgeoPointCallingMap[];
  isAuthorized: boolean;
  webRtcCallStatus: WEB_RTC_CALL_STATUS;
  tko: TechInspectionType[];
  requestHistory: RequestHistoryType[];
  selectExecotorSchedule: ExecuterType | null;
  mapCenterCoordinats: number[];
  isOpenAddAbonentPopUp: boolean;
  geoLessCount: number;
  limit: number;
  currentPage: number;
  pageSize: number;
}

const defaultValue: IMultiInput & IDateInput & CallingMapState & ISingleInput = {
  installationCallStatus: { value: [], argValue: FilterArgEnum.IN_LIST },
  montagePlaceType: { value: [], argValue: FilterArgEnum.EQUAL },
  tkoPeriodDefiner: { value: [], argValue: FilterArgEnum.EQUAL },
  phase: { value: [], argValue: FilterArgEnum.IN_LIST },
  termTaskId: { value: '', argValue: FilterArgEnum.EQUAL },
  phoneNumber: { value: '', argValue: FilterArgEnum.EQUAL },
  taskType: { value: [], argValue: FilterArgEnum.IN_LIST },
  callCenterRequestType: { value: [], argValue: FilterArgEnum.IN_LIST },
  callPeriod: { value: [], argValue: FilterArgEnum.RANGE },
  requestPeriod: { value: [], argValue: FilterArgEnum.RANGE },
  taskStatus: { value: [], argValue: FilterArgEnum.IN_LIST },
  geoPoints: [],
  urgentGeoPoints: [],
  singleGeoPoint: null,
  singleAbonent: null,
  isOpenPopUp: false,
  isOpenEventCalendarPopUp: false,
  isLoading: false,
  isOpenSettings: false,
  toggleUrgentGeoPoins: false,
  taskId: '',
  executors: [],
  avalibleExecutors: [],
  isConnecting: false,
  endSession: false,
  isOpenSnackBar: false,
  bounds: [],
  initBounds: [],
  geoLess: [],
  mapZoom: 5,
  mapAPI: null,
  isAuthorized: false,
  tko: [],
  requestHistory: [],
  webRtcCallStatus: 'default',
  selectExecotorSchedule: null,
  mapCenterCoordinats: [37.57, 55.75],
  isOpenAddAbonentPopUp: false,
  geoLessCount: 0,
  limit: 10,
  currentPage: 0,
  pageSize: 10,
};

//  const [currentPage, setCurrentPage] = useState(0);
//  const [pageSize, setPageSize] = useState(10);
const { executors, avalibleExecutors, isAuthorized, ...resetDefaultValue } = defaultValue;
export type ICallingMapStore = IMultiInput & IDateInput & Actions & ISingleInput & CallingMapState;

export const useCallingMapState = createStore<ICallingMapStore>(
  (set, get) => ({
    ...defaultValue,
    setDefaultValue: () =>
      set(() => ({
        ...resetDefaultValue,
      })),

    setGeoPoints: (data) => set({ geoPoints: data }),
    setGeoLessCount: (count) => set({ geoLessCount: count }),
    setCurrentPage: (page) => set({ currentPage: page }),
    setPageSize: (pageSize) => set({ pageSize: pageSize }),

    setMapCenterCoordinats: (coordinats) => set({ mapCenterCoordinats: coordinats }),
    setSelectExecotorSchedule: (excutor) => set({ selectExecotorSchedule: excutor }),
    setTKO: (tko) => set({ tko: tko }),
    setRequestHistory: (requestHistory) => set({ requestHistory: requestHistory }),
    setToggleUrgentGeoPoints: () => set({ toggleUrgentGeoPoins: !get().toggleUrgentGeoPoins }),
    setUrgentPoints: (data) => set({ urgentGeoPoints: data }),
    setTaskId: (taskId) => set({ taskId: taskId }),
    setGeoLess: (geoLess) => set({ geoLess: geoLess }),
    setSingleAbonent: (data) => set({ singleAbonent: data }),
    setBounds: (bounds) => set({ bounds: bounds }),
    setInitBounds: (initBounds) => set({ initBounds: initBounds }),
    setMapZoom: (mapZoom) => set({ mapZoom: mapZoom }),
    setMapAPI: (mapAPI) => set({ mapAPI: mapAPI }),
    setExecutors: (data) => set({ executors: data }),

    setAvalibleExecutors: (data) => set({ avalibleExecutors: data }),
    setSingleValue: (key) => (value) => {
      if (key === 'phoneNumber') {
        const formatred = formatPhoneNumberMask(value);
        return set((state) => ({
          [key]: {
            ...state[key],
            value: formatred,
          },
        }));
      } else {
        return set((state) => ({
          [key]: {
            ...state[key],
            value,
          },
        }));
      }
    },
    setSingleValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.INSTALLED || get()[key].argValue === FilterArgEnum.INSTALLED) {
        set(() => ({
          [key]: {
            value: '',
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },
    setOpenPopup: (open) => set({ isOpenPopUp: open }),
    setOpenAddAbonentPopup: (open) => set({ isOpenAddAbonentPopUp: open }),
    setIsOpenEventCalendarPopUp: (open) => set({ isOpenEventCalendarPopUp: open }),
    setIsConnection: (connect) => {
      set({ isConnecting: true, endSession: false });
      fakePromiseCall()
        .then((result) => {
          console.log(result); // Выведет "Прошло 5 секунд!" через 5 секунд
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          set({ isConnecting: false, endSession: true });
        });
    },
    resestConnection: () =>
      set({ isConnecting: false, endSession: false, webRtcCallStatus: 'default' }),
    startConnection: () =>
      set({ isConnecting: true, endSession: false, webRtcCallStatus: 'connecting' }),
    setWebRtcCallStatus: (status) => set({ webRtcCallStatus: status }),

    setIsOpenSettings: (open) => set({ isOpenSettings: open }),
    setSingleGeoPoint: (data) => set({ singleGeoPoint: data }),
    setIsLoading: (loading) => set({ isLoading: loading }),
    claerSingleGeoPoint: () => set({ singleGeoPoint: null }),
    setOpenSnackBar: (open) => set({ isOpenSnackBar: open }),
    setIsAuthorized: (isAuthorized) => set({ isAuthorized: isAuthorized }),

    setDateValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setDateValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.RANGE || get()[key].argValue === FilterArgEnum.RANGE) {
        set(() => ({
          [key]: {
            value: [],
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },

    setMultiValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setMultiValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.INSTALLED || get()[key].argValue === FilterArgEnum.INSTALLED) {
        set((state) => ({
          [key]: {
            value: [],
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },
  }),

  'CallingMap'
);

export function fakePromiseCall() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('Прошло 5 секунд!');
    }, 5000);
  });
}

// Использование фейкового промиса
