import React, { FC, useEffect, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { Box, StackCenter, Status, TitleDiv, ValueDiv } from './index.styled';
import {
  Button,
  Dialog,
  Input,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  debounce,
  TablePagination,
} from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Popup } from '@/components/popups';
import ConsumerItem from '@/components/maps/ppoMap/consumerCard/ConsumerItem';
import { useDeliveryPoints } from '@/components/maps/ppoMap/placemark/delivery-point/store';
import { useCatalog } from '@/hooks/CatalogHook';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from '../../zoningMap/hook/useDebounce';
import { searchSubscriberByTerm } from '@/services/TaskService';
import DraggablePopup from '@/components/popups/DraggablePopup';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { useActionLog } from '@/hooks/ActionLogHook';
import { SubscriberResponse } from '@/dto/taskmap/task/TaskResultDto';
import { EditAddressPopup } from '@/components/maps/ppoMap/consumerCard/EditAddressPopup';

const ConsumerCard: FC = () => {
  const { tmpDeliveryPoint } = useDeliveryPoints();
  const { catchError } = useActionLog();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLodaing, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [tableData, setTableData] = useState<SubscriberResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const sub = tmpDeliveryPoint?.subscriber;
  const subModValue = sub && {
    id: sub.id,
    region: sub.modifiedRegion ?? null,
    areaName: sub.modifiedAreaName ?? null,
    locality: sub.modifiedLocality ?? null,
    street: sub.modifiedStreet ?? null,
    house: sub.modifiedHouse ?? null,
    apartmant: sub.modifiedApartmant ?? null,
  };
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const debounceSearchTerm = useDebounce(searchTerm, 500);

  const onSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const fetchTableData = (page: number, pageSize: number) => {
    setIsLoading(true);
    const offset = page * pageSize;
    searchSubscriberByTerm(debounceSearchTerm, offset, pageSize)
      .then(({ data }) => {
        setTableData(data);
      })
      .catch((err) => catchError('Ошибка поиска', err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchTableData(currentPage, pageSize);
  }, [debounceSearchTerm, currentPage, pageSize]);

  useEffect(() => {
    setCurrentPage(0);
    setPageSize(5);
  }, [debounceSearchTerm]);

  const resetSearch = () => {
    setIsOpen(false);
    setCurrentPage(0);
    setPageSize(5);
    setSearchTerm('');
  };

  return sub ? (
    <Box sx={{ mb: 2 }}>
      <StackCenter
        direction='row'
        spacing={2}
      >
        <TitleDiv>Адрес</TitleDiv>
        <ValueDiv>
          {sub.address} {sub.apartmant && `, кв.${sub.apartmant}`}
        </ValueDiv>
        {!sub?.modifiedAddress && <EditAddressPopup address={sub} />}
      </StackCenter>
      {subModValue && sub?.modifiedAddress && (
        <StackCenter
          direction='row'
          spacing={2}
        >
          <TitleDiv>Измененный адрес</TitleDiv>
          <ValueDiv>
            {sub.modifiedAddress} {sub.modifiedApartmant && `, кв.${sub.modifiedApartmant}`}
          </ValueDiv>
          <EditAddressPopup address={subModValue} />
        </StackCenter>
      )}
      <StackCenter
        direction='row'
        spacing={2}
      >
        <TitleDiv>Категория строения</TitleDiv>
        <ValueDiv>{sub.buildingCategory}</ValueDiv>
      </StackCenter>
      <StackCenter
        direction='row'
        spacing={2}
      >
        <TitleDiv>Имя</TitleDiv>
        <ValueDiv>{sub.fio}</ValueDiv>
      </StackCenter>
      <StackCenter
        direction='row'
        spacing={2}
      >
        <TitleDiv>ЛС</TitleDiv>
        <ValueDiv>{sub.contractNum}</ValueDiv>
      </StackCenter>
      <StackCenter
        direction='row'
        spacing={2}
      >
        <TitleDiv>Контрагент</TitleDiv>
        <ValueDiv>{getCatalogMapWithErr('taskConsumer').get(sub.type)}</ValueDiv>
      </StackCenter>
      <StackCenter
        direction='row'
        spacing={2}
      >
        <TitleDiv>Тип ПУ</TitleDiv>
        <ValueDiv>{sub.puType}</ValueDiv>
      </StackCenter>
      <StackCenter
        direction='row'
        spacing={2}
      >
        <TitleDiv>№ ПУ</TitleDiv>
        <ValueDiv>{sub.puNumber}</ValueDiv>
      </StackCenter>
      <StackCenter
        direction='row'
        spacing={2}
      >
        <TitleDiv>ТП</TitleDiv>
        <ValueDiv>{sub.tp}</ValueDiv>
      </StackCenter>

      <Status style={{ backgroundColor: '#51DD50' }} />
      <Stack
        direction='row-reverse'
        spacing={2}
      >
        <Button
          startIcon={<BorderColorOutlinedIcon />}
          onClick={() => setIsOpen(true)}
          disabled={!tmpDeliveryPoint?.id}
        >
          сменить потребителя
        </Button>
      </Stack>
      <DraggablePopup
        maxWidth='lg'
        isOpen={isOpen}
        onClose={resetSearch}
      >
        <Stack
          spacing={1}
          p={2}
        >
          <Box sx={{ padding: 1 }}>
            <TextField
              placeholder='Введите для поиска ФИО, номер лицевого счета, номер сущ. ПУ или адрес потребителя'
              fullWidth
              value={searchTerm}
              onChange={onSearchTermChange}
              id='input-with-icon-textfield'
              label='поиск'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant='outlined'
            />
          </Box>

          <Stack
            height={400}
            overflow={'auto'}
            pt={2}
          >
            {isLodaing ? (
              <LoadSpinner />
            ) : (
              <>
                {tableData.map((elem, index) => (
                  <ConsumerItem
                    setIsLoading={setIsLoading}
                    key={index}
                    subscriber={elem}
                    {...elem}
                  />
                ))}
              </>
            )}
          </Stack>

          {!!tableData.length && (
            <TablePagination
              count={-1}
              rowsPerPage={pageSize}
              onRowsPerPageChange={(newPageSize) => {
                setPageSize(Number(newPageSize.target.value));
              }}
              page={currentPage}
              labelDisplayedRows={({ from, to }) => (
                <div style={{ paddingTop: 16 }}>
                  {from} - {to}
                </div>
              )}
              onPageChange={(ev, newPage) => {
                setCurrentPage(newPage);
              }}
              nextIconButtonProps={{
                disabled: !tableData.length,
              }}
              labelRowsPerPage={null}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
            />
          )}
        </Stack>
      </DraggablePopup>
    </Box>
  ) : (
    <></>
  );
};

export default ConsumerCard;
