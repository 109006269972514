import React from 'react';
import { TextField, Typography, Button } from '@mui/material';
import { Popup } from '@/components/popups';
import {
  SupplyPUCommentModalWrapper,
  SupplyPUCommentModalButtonsWrapper,
} from '@/components/storehouse/SupplyPUComponent/SupplyPUComponent.styled';
import { usePnrPlanningStore } from '@/components/sections/PnrPlanning/store';
import shallow from 'zustand/shallow';

const PnrPlanningCommentModal = () => {
  const { commentModal, closeCommentModal, saveCommentModal, setCommentModalText } =
    usePnrPlanningStore(
      (state) => ({
        commentModal: state.commentModal,
        closeCommentModal: state.closeCommentModal,
        saveCommentModal: state.saveCommentModal,
        setCommentModalText: state.setCommentModalText,
      }),
      shallow
    );
  return (
    <Popup
      isOpen={commentModal.open}
      onClose={closeCommentModal}
    >
      <SupplyPUCommentModalWrapper>
        <Typography variant='h6'>Комментарий</Typography>

        <TextField
          variant='standard'
          value={commentModal.text}
          sx={{ width: '75%' }}
          multiline
          autoFocus
          maxRows={6}
          onChange={(event) => setCommentModalText(event.target.value)}
        />

        <SupplyPUCommentModalButtonsWrapper>
          <Button
            onClick={saveCommentModal}
            variant='contained'
          >
            Сохранить
          </Button>

          <Button onClick={closeCommentModal}>Отменить</Button>
        </SupplyPUCommentModalButtonsWrapper>
      </SupplyPUCommentModalWrapper>
    </Popup>
  );
};

export default PnrPlanningCommentModal;
