import { Paper, styled } from '@mui/material';

export const SupplyButtonPanelWrapper = styled('div')({
  marginTop: 24,
  display: 'flex',
  justifyContent: 'center',
  gap: 16,
  flexWrap: 'wrap',
});

export const SupplyButtonPanelModalContent = styled('div')({
  minWidth: 500,
  display: 'flex',
  flexDirection: 'column',
});
