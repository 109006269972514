import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  NewGalleryHeader,
  NewGalleryLeft,
  NewGalleryRight,
  NewGalleryTitle,
  NewGalleryWrapper,
} from './NewGallery.styled';
import NewGalleryActiveImage from './NewGalleryActiveImage';
import NewGalleryPreview from './NewGalleryPreview';
import DownloadAllButton from '@/components/button/DownloadAllButton/DownloadAllButton';
import { GetFileUrlByFileId } from '@/services/YodaRestService';

const NewGallery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const galleryTitle = searchParams.get('title');
  const imageIds = searchParams.getAll('ids');

  const handlePreviewImageClick = (index: number) => {
    setActiveImageIndex(index);
  };

  const allFilesPromise = imageIds
    ?.map((id, index) => ({
      url: GetFileUrlByFileId(id),
      folder: galleryTitle ?? 'Фото',
      fileName: `${galleryTitle ?? 'Фото'}_${index + 1}`,
    }))
    .flat();

  return (
    <NewGalleryWrapper>
      <NewGalleryLeft>
        <NewGalleryHeader>
          <NewGalleryTitle>{galleryTitle}</NewGalleryTitle>
          <DownloadAllButton
            disabled={!allFilesPromise.length}
            zipName={galleryTitle ?? 'Архив_фотографий'}
            downloadLinksPromises={allFilesPromise}
            buttonTitle={'Скачать все фото'}
          />
        </NewGalleryHeader>

        <NewGalleryActiveImage imageId={imageIds[activeImageIndex]} />
      </NewGalleryLeft>
      <NewGalleryRight>
        {imageIds?.map((imageId, index) => (
          <NewGalleryPreview
            key={index}
            imageId={imageId}
            onClick={handlePreviewImageClick}
            index={index}
            activeIndex={activeImageIndex}
          />
        ))}
      </NewGalleryRight>
    </NewGalleryWrapper>
  );
};

export default NewGallery;
