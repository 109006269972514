import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IssuedStampUpdateInfoWrapper, IssuedStampUpdateInfoBold } from './IssuedStamp.styled';
import shallow from 'zustand/shallow';
import useSupplyStore from '../SupplyStore';
import { useAdminAccess } from '../SupplyPUComponent/utils';
import moment from 'moment';
import { IdPopup } from '@/components/features/taskEditor/panel/IdPopup';

const IssuedStampUpdateInfo = () => {
  const adminAccess = useAdminAccess();
  const supplyBillDto = useSupplyStore((state) => state.supplyBillDto, shallow);

  if (!adminAccess) return null;
  return (
    <>
      {(supplyBillDto?.createdAt ||
        supplyBillDto?.createdBy ||
        supplyBillDto?.updatedAt ||
        supplyBillDto?.updatedBy ||
        supplyBillDto?.releaseDate) && (
        <IdPopup
          title={
            <InfoOutlinedIcon
              color='primary'
              fontSize='small'
            />
          }
        >
          <IssuedStampUpdateInfoWrapper>
            {supplyBillDto?.createdAt && (
              <div>
                <IssuedStampUpdateInfoBold>Дата создания: </IssuedStampUpdateInfoBold>
                {supplyBillDto?.createdAt}
              </div>
            )}

            {supplyBillDto?.releaseDate && (
              <div>
                <IssuedStampUpdateInfoBold>
                  Разрешено редактировать файлы до:{' '}
                </IssuedStampUpdateInfoBold>
                {moment(supplyBillDto?.releaseDate).add(8, 'hours').format('YYYY-MM-DD HH:mm:ss')}
              </div>
            )}

            {supplyBillDto?.createdBy && (
              <div>
                <IssuedStampUpdateInfoBold>Создана пользователем: </IssuedStampUpdateInfoBold>
                {supplyBillDto?.createdBy}
              </div>
            )}
            {supplyBillDto?.updatedAt && (
              <div>
                <IssuedStampUpdateInfoBold>Последнее обновление: </IssuedStampUpdateInfoBold>
                {supplyBillDto?.updatedAt}
              </div>
            )}
            {supplyBillDto?.updatedBy && (
              <div>
                <IssuedStampUpdateInfoBold>Обновлено пользователем: </IssuedStampUpdateInfoBold>
                {supplyBillDto?.updatedBy}
              </div>
            )}
          </IssuedStampUpdateInfoWrapper>
        </IdPopup>
      )}
    </>
  );
};

export default React.memo(IssuedStampUpdateInfo);
