import './dashboardStyle.css';
import { Outlet } from 'react-router-dom';

export default function DashboardOutlet() {
  return (
    <div className={'rootDiv'}>
      <Outlet />
    </div>
  );
}
