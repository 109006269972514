import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { GSMInfoTitle, GSMInfoValue } from '@/components/GSM/GSMInfoItem.styled';

interface IGSMInfoItemProps {
  title: string;
  value: string;
}

export const GSMInfoItem: FC<IGSMInfoItemProps> = ({ title, value }) => {
  return (
    <>
      <Grid
        item
        xs={1.8}
      >
        <GSMInfoTitle>{title}</GSMInfoTitle>
      </Grid>
      <Grid
        item
        xs={10.2}
      >
        <GSMInfoValue>{value}</GSMInfoValue>
      </Grid>
    </>
  );
};
