import React from 'react';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { color } from '@/styles/mixins';
import { IComplaintItem } from './types';
import ComplaintItem from './ComplaintItem/ComplaintItem';
import resiveLetter from '@/images/icons/issues/resiveLetter.svg';
import responseLetter from '@/images/icons/issues/responseLetter.svg';
import moment from 'moment';

export default function ComplaintItemOdpu(complaintDTO: IComplaintItem) {
  const { complaint, declarerFio, inputName, responseDetails, letterDetails } = complaintDTO;
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <ComplaintItem complaintDTO={complaintDTO}>
      <Typography
        variant='subtitle2'
        gutterBottom
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        textAlign={'end'}
      >
        Жалоба {complaint.base.id}
      </Typography>
      <Stack
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={'space-between'}
      >
        <Typography
          variant='body2'
          color='textSecondary'
        >
          {complaint.address.readOnlyAddress}
        </Typography>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          gap={3}
          flexWrap={isMobile ? 'wrap' : 'nowrap'}
        >
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={1}
          >
            <img
              src={resiveLetter}
              alt='description'
              style={{ width: '20px', height: 'auto', alignSelf: 'baseline' }}
            />
            <Typography
              variant='body2'
              gutterBottom
            >
              {letterDetails}
            </Typography>
          </Stack>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={1}
          >
            <img
              src={responseLetter}
              alt='description'
              style={{ width: '20px', height: 'auto', alignSelf: 'baseline' }}
            />
            <Typography
              variant='body2'
              gutterBottom
            >
              {responseDetails}
            </Typography>
          </Stack>

          <Typography
            variant='subtitle2'
            gutterBottom
          >
            {moment(complaint.base.createAt).format('DD.MM.YYYY')}
          </Typography>
        </Stack>
      </Stack>
      <Grid
        container
        xs={12}
        md={12}
      >
        <Grid
          item
          xs={1}
        >
          <Stack
            flexDirection={'row'}
            gap={3.1}
          >
            <Typography variant='body2'>{inputName}</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          maxWidth={1.5}
        >
          <Stack
            flexDirection={'row'}
            justifyContent={'center'}
          >
            <Typography variant='body2'>{declarerFio}</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={8.5}
        >
          <Stack flexDirection={'row'}>
            <Typography variant='body2'>{complaint?.text}</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
        >
          <Stack
            flexDirection={'row'}
            justifyContent={'end'}
          >
            <Typography variant='body2'>№ {complaint.reasonTaskNumber}</Typography>
          </Stack>
        </Grid>
      </Grid>
      {/* secondLine */}
      <Grid
        container
        xs={12}
        md={12}
      >
        <Grid
          item
          xs={12}
          md={2}
        >
          <Stack
            flexDirection={'row'}
            gap={1}
          >
            <Typography variant='body2'>№ ПУ</Typography>
            <Typography
              variant='body2'
              color={complaint.reasonTaskId ? color('muiBlue') : color('gray')}
              onClick={(e) => {
                e.stopPropagation();
                complaint.reasonTaskId && window.open('/task/edit/' + complaint.reasonTaskId);
              }}
            >
              {complaint.meterNumber || 'отсутствует'}
            </Typography>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
        >
          <Stack flexDirection={'row'}>
            <Typography
              variant='body2'
              color='textSecondary'
            >
              {complaint.lastComment && complaint.lastComment.text}
            </Typography>
          </Stack>
        </Grid>
        {!isMobile && (
          <Grid
            item
            xs={12}
            md={2}
          >
            <Stack alignItems={'end'}>
              <Typography variant='body2'> этап {complaint.stage.title}</Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
      {/* third line  */}
    </ComplaintItem>
  );
}
