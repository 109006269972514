import {
  SMROdpuStatisticsTableItem,
  SMROdpuStatisticsTable,
  SMROdpuStatisticsTableDataItem,
  SMROdpuStatisticsTableMonthResultItem,
} from './types';
import { TableCell, Tooltip } from '@mui/material';

export const getResultRow = (
  typeData?: SMROdpuStatisticsTableItem[],
  data?: SMROdpuStatisticsTable
) => {
  const numberArray: number[] = new Array<number>(
    (data?.allDays.length ?? 1 + (data?.previousMonth.length ?? 1)) * 3
  ).fill(0);
  const resultArray: React.ReactElement[] = new Array(
    (data?.allDays.length ?? 1 + (data?.previousMonth.length ?? 1)) * 3 + 3
  );
  resultArray[0] = (
    <TableCell
      key={new Date().getDate() + '' + Math.random()}
      className={'leftCell'}
    >
      Итого
    </TableCell>
  );
  let resultAll = 0;
  let resultInstalled = 0;
  let resultAssigned = 0;
  if (typeData != undefined || typeData != null) {
    if (typeData?.length === 0) return [];
    typeData?.map((item) => {
      let tempIndex = 0;
      item.monthResults?.map((monthResult) => {
        tempIndex = addValueToNumberArrayAndReturnLastIndex(monthResult, tempIndex, numberArray);
      });
      item.data?.map((itemData) => {
        tempIndex = addValueToNumberArrayAndReturnLastIndex(itemData, tempIndex, numberArray);
        resultAll += itemData.all != null ? itemData.all : 0;
        resultInstalled += itemData.installed != null ? itemData.installed : 0;
        resultAssigned += itemData.assigned != null ? itemData.assigned : 0;
      });
    });
  } else {
    return [];
  }

  numberArray.map((item, index) => {
    resultArray[index + 1] = getTableCellWithTooltip(
      (index + 1) % 3 == 1
        ? 'Количество произведённых замен'
        : (index + 1) % 3 === 0
        ? 'Количество назначенных задач'
        : 'Количество установленных замен',
      'regularCell ' +
        ((index + 1) % 3 == 1 ? 'firstCell' : (index + 1) % 3 === 0 ? 'thirdCell' : ''),
      item
    );
  });
  resultArray[resultArray.length] = getTableCellWithTooltip(
    'Количество произведённых замен',
    'regularCell firstCell',
    resultAll
  );
  resultArray[resultArray.length] = getTableCellWithTooltip(
    'Количество установленных замен',
    'regularCell',
    resultInstalled
  );
  resultArray[resultArray.length] = getTableCellWithTooltip(
    'Количество назначенных задач',
    'regularCell thirdCell',
    resultAssigned
  );
  return resultArray;
};

const addValueToTableCellArrayAndReturnLastIndex = (
  executorName: any,
  item: SMROdpuStatisticsTableDataItem | SMROdpuStatisticsTableMonthResultItem,
  index: number,
  elementArray: React.ReactElement[]
) => {
  elementArray[index] = getTableCellWithTooltip(
    'Количество произведённых замен ' + executorName,
    'regularCell firstCell',
    item.all != undefined ? item.all : 0
  );
  elementArray[index + 1] = getTableCellWithTooltip(
    'Количество установленных замен ' + executorName,
    'regularCell',
    item.installed != undefined ? item.installed : 0
  );
  elementArray[index + 2] = getTableCellWithTooltip(
    'Количество назначенных задач ' + executorName,
    'regularCell thirdCell',
    item.assigned != undefined ? item.assigned : 0
  );
  index += 3;
  return index;
};

const addValueToNumberArrayAndReturnLastIndex = (
  item: SMROdpuStatisticsTableDataItem | SMROdpuStatisticsTableMonthResultItem,
  tempIndex: number,
  numberArray: number[]
) => {
  numberArray[tempIndex] += item.all != null ? item.all : 0;
  numberArray[tempIndex + 1] += item.installed != null ? item.installed : 0;
  numberArray[tempIndex + 2] += item.assigned != null ? item.assigned : 0;
  tempIndex += 3;
  return tempIndex;
};

export const getRowValues = (item: SMROdpuStatisticsTableItem) => {
  const smrOdpuArray: React.ReactElement[] = [];
  let tempIndex = 0;
  if (item) {
    smrOdpuArray[tempIndex] = (
      <TableCell
        key={new Date().getDate() + '' + Math.random()}
        className={'leftCell'}
        style={{
          overflow: 'visible',
          whiteSpace: 'nowrap',
        }}
      >
        {item.executorName != undefined ? item.executorName : ''}
      </TableCell>
    );
    tempIndex += 1;
    let allResult = 0;
    let installResult = 0;
    let assignedResult = 0;

    item.monthResults?.map((monthItem) => {
      tempIndex = addValueToTableCellArrayAndReturnLastIndex(
        item.executorName,
        monthItem,
        tempIndex,
        smrOdpuArray
      );
    });
    item.data?.map((monthItem) => {
      const all: number = monthItem.all != undefined ? monthItem.all : 0;
      const install: number = monthItem.installed != undefined ? monthItem.installed : 0;
      const assigned: number = monthItem.assigned != undefined ? monthItem.assigned : 0;
      allResult += all;
      installResult += install;
      assignedResult += assigned;
      tempIndex = addValueToTableCellArrayAndReturnLastIndex(
        item.executorName,
        monthItem,
        tempIndex,
        smrOdpuArray
      );
    });
    smrOdpuArray[tempIndex] = getTableCellWithTooltip(
      'Количество произведённых замен ' + item.executorName,
      'regularCell firstCell',
      allResult
    );
    tempIndex += 1;
    smrOdpuArray[tempIndex] = getTableCellWithTooltip(
      'Количество установленных замен ' + item.executorName,
      'regularCell',
      installResult
    );
    tempIndex += 1;
    smrOdpuArray[tempIndex] = getTableCellWithTooltip(
      'Количество назначенных задач ' + item.executorName,
      'regularCell thirdCell',
      assignedResult
    );
    tempIndex += 1;
  }
  return smrOdpuArray;
};

const getTableCellWithTooltip = (tooltipText: string, cellClassName: string, cellValue: any) => {
  return (
    <Tooltip
      key={new Date().getDate() + '' + Math.random()}
      title={tooltipText}
    >
      {getTableCellWithoutTooltip(cellClassName, cellValue)}
    </Tooltip>
  );
};
const getTableCellWithoutTooltip = (cellClassName: string, cellValue: any) => {
  return (
    <TableCell
      key={new Date().getDate() + '' + Math.random()}
      className={cellClassName}
    >
      {cellValue}
    </TableCell>
  );
};
