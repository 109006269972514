import { color } from '@/styles/mixins';
import { styled } from '@mui/material';

interface NavbarLinksWrapper {
  isMobile?: boolean;
}

export const NavbarLinksWrapper = styled('div')<NavbarLinksWrapper>(({ isMobile }) => ({
  display: 'flex',
  justifyContent: isMobile ? 'flex-start' : 'flex-end',
  flex: '1 1 auto',
  alignItems: isMobile ? 'start' : 'center',
  flexDirection: isMobile ? 'column' : 'row',
  padding: 10,
  width: '80vw',
  flexWrap: 'wrap',
  gap: 16,
  backgroundColor: color('mapBackgroundColorBoxShadow'),
}));
