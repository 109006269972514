import React from 'react';
import { Stack, Typography } from '@mui/material';
import { PpoEnterInputProps } from '../PpoEnterInput/PpoEnterInput';
import { color } from '@/styles/mixins';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { useExternalConnectionPopup } from '@/components/popups/externalConnectionPopup/store';

type ExpendableListProps = Pick<PpoEnterInputProps, 'enter'>;

const PpoExternalConnection = ({ enter }: ExpendableListProps) => {
  const { setIsOpen, setInputExternalConnectionList } = useExternalConnectionPopup((state) => ({
    setIsOpen: state.setIsExternalConnectionPopupOpen,
    setInputExternalConnectionList: state.setInputExternalConnectionList,
  }));

  const handleClick = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    console.log(enter);
    setIsOpen(true);
    setInputExternalConnectionList(enter.extraConnectionResponseList ?? [], enter.id);
  };

  return (
    <Stack
      sx={{ mr: 1 }}
      onDoubleClick={(e) => handleClick(e)}
    >
      <BoxShadow
        sx={{ backgroundColor: color('trueWhite'), paddingY: 1, cursor: 'pointer' }}
        onDoubleClick={handleClick}
      >
        <Typography
          style={{ fontSize: 10 }}
          textAlign={'center'}
        >
          Сторонние
        </Typography>
        <Typography
          style={{ fontSize: 10 }}
          textAlign={'center'}
        >
          подключения
        </Typography>
        <Typography
          style={{ fontSize: 16, color: color('blue') }}
          textAlign={'center'}
        >
          {enter?.extraConnectionResponseList &&
            enter.extraConnectionResponseList?.length < 1 &&
            '+'}
        </Typography>
      </BoxShadow>
      <Typography
        style={{ fontSize: 10, fontWeight: 'bolder' }}
        textAlign={'center'}
      >
        {!!enter.extraConnectionResponseList?.length &&
          `${enter?.extraConnectionResponseList?.length} шт`}
      </Typography>
    </Stack>
  );
};

export default PpoExternalConnection;
