import React from 'react';
import { FormControlLabel, MenuItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStoredFilters } from './store';
import { IAddToSaveButtonPopup } from '@/components/popups/AddToSaveButtonPopup/AddToSaveButtonPopup';
import { SaveButtonDeleteWrapper } from './SaveButton.styled';

interface SaveButtonMenuItemProps extends IAddToSaveButtonPopup {
  onClick: (clickedValue?: string) => void;
  value: string;
}

const SaveButtonMenuItem = ({ onClick, value, saveFilter }: SaveButtonMenuItemProps) => {
  const { removeFilter, allFilters } = useStoredFilters();

  const clickItemHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    onClick(value);
    event.preventDefault();
  };

  const clickDeleteHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    removeFilter(saveFilter?.filterType, value);
  };

  const filterEqual =
    JSON.stringify(saveFilter?.infoToSave) ===
    JSON.stringify(
      allFilters[saveFilter?.filterType].find((el) => el.filterName === value)?.filters
    );

  return (
    <MenuItem
      divider
      onClick={clickItemHandler}
    >
      <FormControlLabel
        control={<Checkbox />}
        checked={filterEqual}
        label={value}
      />

      <SaveButtonDeleteWrapper onClick={clickDeleteHandler}>
        <DeleteIcon />
      </SaveButtonDeleteWrapper>
    </MenuItem>
  );
};

export default SaveButtonMenuItem;
