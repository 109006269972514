import { color } from '@/styles/mixins';
import { styled } from '@mui/material';

export const Title = styled('span')({
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '16.01px',
  color: color('fontLightGray'),
});

export const Date = styled('span')({
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '16.01px',
});
