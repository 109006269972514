import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCatalog } from '@/hooks/CatalogHook';
import { returnTaskBack } from '@/services/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { IPopup, Popup } from './Popup';
import { DateRange } from '@/components/inputs/DateRange';
import { LoadingButton } from '@mui/lab';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';

interface Props extends IPopup {
  taskIds: string[];
  singleExecutorId: string | null | undefined;
}
type FormData = {
  executorId: string;
  scheduledDate: number;
};

export const ReturnTaskBackPopup: React.FC<Props> = (props) => {
  const { taskIds, onClose, onSuccess, singleExecutorId } = props;
  const taskGroupMap = useCatalog((state) => state.taskGroupMap);
  const { control, setValue, handleSubmit, reset } = useForm<FormData>();
  const [scheduledDate, setScheduledDate] = useState<Date | null | Date[] | number>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { catchError, addActionLog } = useActionLog();

  const options = useMemo(() => taskMapToArray(taskGroupMap), [taskGroupMap]);

  const onHandleScheduledDate = (newValue: number[] | null) => {
    if (newValue && newValue[0]) {
      setScheduledDate(newValue[0]);
      setValue('scheduledDate', newValue[0]);
    }
  };

  const handleSave = ({ executorId, scheduledDate }: FormData) => {
    const payload = {
      taskIdList: taskIds,
      executorId: executorId ?? singleExecutorId,
      scheduledDate,
    };
    setIsLoading(true);
    returnTaskBack(payload)
      .then(() => {
        onClose();
        onSuccess && onSuccess();
        addActionLog(ActionLogType.SUCCESS, 'Заявка возврашена исполнителю');
      })
      .catch((error) => {
        if (error?.response?.data) {
          catchError(error?.response?.data.message, {});
        } else {
          catchError('Непредвиденная ошибка', {});
        }
      })
      .finally(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue('executorId', undefined);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue('scheduledDate', []);
        setScheduledDate(null);
        setIsLoading(false);
      });
  };
  const disableSaveButton = typeof scheduledDate !== 'number';

  return (
    <Popup
      {...props}
      customStyle={{ overflow: 'visible' }}
    >
      <Typography
        variant='h5'
        mb={3}
        textAlign={'center'}
      >
        Внесите данные
      </Typography>
      <Grid
        container
        spacing={5}
        padding={3}
        minWidth={900}
        mb={3}
      >
        <Grid
          item
          xs={6}
        >
          <Controller
            control={control}
            name='executorId'
            render={({ field }) => (
              <Autocomplete
                style={{ width: '100%' }}
                options={options}
                value={{
                  value: field.value ?? '',
                  label:
                    taskGroupMap.get(field.value) || taskGroupMap.get(singleExecutorId || '') || '',
                }}
                onChange={(event: any, newValue: any) => {
                  field?.onChange(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    name='executorId'
                    {...params}
                    label={'Бригада исполнитель'}
                    variant='standard'
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={6}
          mt={0.4}
        >
          <DateRange
            label={'Плановая дата'}
            onChange={onHandleScheduledDate}
            value={[scheduledDate]}
            argValue={FilterArgEnum.EQUAL}
            showSelect={false}
            showCalenderIcon={true}
            style={{ width: '100%' }}
            showClearButton={false}
            showPalceHolder={false}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        spacing={4}
      >
        <Grid item>
          <Button
            onClick={onClose}
            variant='text'
          >
            отменить
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            size='small'
            onClick={handleSubmit(handleSave)}
            loading={isLoading}
            loadingPosition='start'
            variant='text'
            disabled={disableSaveButton}
          >
            {!isLoading && <span>сохранить</span>}
          </LoadingButton>
        </Grid>
      </Grid>
    </Popup>
  );
};
