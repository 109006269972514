export const STATISTICS_CHART_OPTIONS = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
    },
    legend: {
      display: true,
      position: 'right' as const,
      usePointStyle: true,
      labels: {
        boxWidth: 70,
        boxHeight: 30,
        color: '#000000',
        font: {
          size: 16,
          family: 'Segoe UI',
          weight: 'bold',
        },
      },
    },
  },
  scales: {
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    },
  },
};
