import { styled } from '@mui/material';
import { Loading } from '@/components/ui/Loading';
import FileInput from '@/components/inputs/FileInput/FileInput';

export const CallCenterModalLoading = styled(Loading)({
  width: '180px !important',
});

export const CallCenterModalFileInput = styled(FileInput)({
  alignItems: 'flex-start',
});
