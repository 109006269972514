import { GridCellExpand } from '@/components/ui/GridCellExpand';
import CopyButton from '@/components/button/CopyButton';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

function renderCellExpand(params: GridRenderCellParams<any, any>) {
  return (
    <GridCellExpand
      value={params.value || ''}
      width={params.colDef.computedWidth}
    />
  );
}

export const StatisticMoreThanOneApealColumns: GridColDef[] = [
  {
    field: '',
    headerName: 'Номер заявки',
    disableColumnMenu: true,
    sortable: false,
    headerAlign: 'center',
    width: 120,
    renderCell: (params) => {
      return (
        <div
          style={{
            width: params.colDef.computedWidth,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => window.open(`/task/edit/${params.row.taskId}`)}
          >
            {params.row.taskNumber}
          </span>
          <CopyButton
            tooltipText='Копировать номер заявки'
            onlyIcon={true}
            textToCopy={params.row.taskNumber}
          />
        </div>
      );
    },
  },
  {
    field: 'requests',
    headerName: 'Обращение',
    flex: 2,
    width: 150,
    disableColumnMenu: true,
    sortable: false,
    align: 'right',
    headerAlign: 'center',
    renderCell: renderCellExpand,
  },
  {
    field: 'contractorName',
    headerName: 'Ответственный',
    width: 150,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    align: 'right',
    headerAlign: 'center',
    renderCell: renderCellExpand,
  },
  {
    field: 'tkoHistory',
    width: 150,
    flex: 2,
    headerName: 'История ТКО',
    disableColumnMenu: true,
    sortable: false,
    align: 'right',
    headerAlign: 'center',
    renderCell: renderCellExpand,
  },
];
