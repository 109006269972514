import { styled, Typography, FormHelperText, IconButton } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { color } from '@/styles/mixins';
import { Loading } from '@/components/ui/Loading';
import { swingAnimationKeyframes } from '@/components/features/pnr/HouseEntranceHeader/HouseEntranceHeader.styled';

export const HousePnrBrowseEditAddressModalWrapper = styled(BoxShadow)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  padding: 24,
});

export const HousePnrBrowseModalHeader = styled(Typography)({
  textAlign: 'center',
  marginBottom: '15px',
});

export const HousePnrBrowseModalAnimatedButton = styled(IconButton)({
  '&:hover': { svg: { animation: `${swingAnimationKeyframes} 1s 1 both` } },
});

export const HousePnrBrowseModalRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  marginTop: 16,
});

export const HousePnrBrowseModalButtonsWrapper = styled(HousePnrBrowseModalRow)({
  marginTop: 48,
});

export const HousePnrBrowseModalErrorText = styled(FormHelperText)({
  color: color('muiError'),
  position: 'absolute',
  marginTop: 5,
});

export const HousePnrBrowseModalInputLoading = styled(Loading)({
  height: '32px !important',
  marginTop: 16,
});

export const HousePnrBrowseModalInputLoadingBig = styled(Loading)({
  height: '56px !important',
  transform: 'none',
});
