import shallow from 'zustand/shallow';
import { IFilter, IFilterCombo, useStoredFilters } from '@/components/button/SaveButton/store';
import {
  AllInputKeys,
  useTasksBrowseFilter,
  defaultValueCheckbox,
  defaultValueDateInput,
  defaultValueMultiInput,
  defaultValueSingleInputs,
  defaultValueSingleSelectValue,
} from '@/components/features/tasksBrowse/store';
import { SelectFilterOptType } from '../MultySelectFilter';

export const isObjKey = <T>(key: any, obj: T): key is keyof T => {
  return key in obj;
};

export const isNumberArray = (array: unknown[]): array is number[] => {
  return array.every((value) => typeof value === 'number');
};

export const isPrimitive = (value: unknown): value is string | number | boolean => {
  return typeof value !== 'object';
};

export const isIFilter = (value: unknown): value is IFilter => {
  return !isPrimitive(value) && !Array.isArray(value);
};

export const isSelectFilterArray = (value: unknown): value is SelectFilterOptType[] => {
  return !isPrimitive(value) && Array.isArray(value);
};

export const TMP_MAIN = '_tmpMainFilter_';

export const useMainFilter = (type?: string) => {
  const { allFilters } = useStoredFilters();
  const {
    setDateValue,
    setDateValueArg,
    setBooleanValue,
    setSingleValue,
    setSingleValueArg,
    setMultiValue,
    setMultiValueArg,
    setSingleSelectValue,
    setDefaultValue,
  } = useTasksBrowseFilter(
    (state) => ({
      setBooleanValue: state.setBooleanValue,
      setSingleSelectValue: state.setSingleSelectValue,
      setSingleValue: state.setSingleValue,
      setSingleValueArg: state.setSingleValueArg,
      setMultiValue: state.setMultiValue,
      setMultiValueArg: state.setMultiValueArg,
      setDateValue: state.setDateValue,
      setDateValueArg: state.setDateValueArg,
      setDefaultValue: state.setDefaultValue,
    }),
    shallow
  );

  const setValue = (key: AllInputKeys, selectedFilters: IFilterCombo) => {
    const filter = selectedFilters[key];

    if (!filter) return;

    if (isObjKey(key, defaultValueSingleInputs) && isIFilter(filter)) {
      if (!isPrimitive(filter.value)) return;
      setSingleValueArg(key)(filter.argValue);
      setSingleValue(key)(filter.value);
    }

    if (isObjKey(key, defaultValueCheckbox) && isPrimitive(filter)) {
      setBooleanValue(key)(filter);
    }

    if (isObjKey(key, defaultValueMultiInput) && isIFilter(filter)) {
      if (isPrimitive(filter.value) || isNumberArray(filter.value)) return;
      setMultiValueArg(key)(filter.argValue);
      setMultiValue(key)(filter.value);
    }

    if (isObjKey(key, defaultValueDateInput) && isIFilter(filter)) {
      if (isPrimitive(filter.value) || !isNumberArray(filter.value)) return;
      setDateValueArg(key)(filter.argValue);
      setDateValue(key)(filter.value);
    }

    if (isObjKey(key, defaultValueSingleSelectValue) && isIFilter(filter)) {
      if (!isPrimitive(filter.value)) return;
      setSingleSelectValue(key)({ value: filter.value, label: filter.label ?? '' });
    }
  };

  const setFilterValues = (filterKey: string) => {
    if (type) {
      const selectedFilters = allFilters[type]?.find(
        (filter) => filter.filterName === filterKey
      )?.filters;
      setDefaultValue();
      for (const key in selectedFilters) {
        const allInputKey = key as AllInputKeys;
        setValue(allInputKey, selectedFilters);
      }
    }
  };

  return { setFilterValues };
};

export const useSaveFilterButton = (type: string) => {
  const { allFilters } = useStoredFilters();

  const getFilters = <
    T extends IFilter | boolean | SelectFilterOptType[] | number | string | SelectFilterOptType
  >(
    filterKey: string
  ) => {
    const selectedFilters = allFilters[type]?.find(
      (filter) => filter.filterName === filterKey
    )?.filters;

    return selectedFilters as { [key: string]: T };
  };

  const getFirstFilters = <T extends IFilter | boolean | SelectFilterOptType[]>() => {
    const selectedFilters = allFilters[type]?.find(
      (filter) => filter.filterName === TMP_MAIN
    )?.filters;

    return selectedFilters as { [key: string]: T };
  };

  return { getFilters, getFirstFilters };
};
