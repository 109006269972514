import { Grid } from '@mui/material';
import React from 'react';
import { ZoningFilterPane } from './filter';
import { ZoningInfo } from './info';
import { ZoningItemPane } from './itemPane';

export const ZoningControlPane = () => {
  return (
    <Grid
      item
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={3}
    >
      <Grid
        item
        xs={9}
      >
        <ZoningFilterPane />
      </Grid>
      <Grid item>
        <ZoningInfo />
      </Grid>
    </Grid>
  );
};
