import React, { useEffect, useState } from 'react';
import { usePnrPlanningStore } from './store';
import { PnrPlanningModalConflictIcon } from '@/components/blocks/PnrPlanningModalSteps/PnrPlanningModalSteps.styled';
import shallow from 'zustand/shallow';
import {
  PnrElementsAddIcon,
  PnrElementsInfoCube,
  PnrElementsInfoSmallText,
  PnrElementsInfoText,
  PnrElementsInfoWrapper,
  PnrElementsLoading,
  PnrElementsWrapper,
  PnrElementWrapper,
} from '@/components/blocks/PnrElementsInfo/PnrElementsInfo.styled';
import { NetworkEquipmentIds } from '../HouseEntranceSheme/utils';
import {
  CurrentPlannedInstalations,
  getCurrentEntranceInstalations,
  INITIAL_CURRENT_PLANNED_INSTALATIONS,
  PlannedInstalationByTypes,
} from './utils';
import { useIsFetchingPlannings } from '@/components/blocks/PnrPlanningsCount/utils';
import { useDisabledPnrPlanningButtons } from '@/components/blocks/PnrPlanningButtons/utils';
import { NetworkPlanningTypes } from '@/components/blocks/PnrElementsInfo/utils';
import { getOsIcon } from '@/components/features/pnr/constants';
import { usePNRStore } from '@/components/features/pnr/store';

const PnrPlanning = () => {
  const isFetching = useIsFetchingPlannings();
  const disableByRole = useDisabledPnrPlanningButtons();

  const { setOpenModal, plannedInstalationsFromBackend, openModalType } = usePnrPlanningStore(
    (state) => ({
      setOpenModal: state.setOpenModal,
      plannedInstalationsFromBackend: state.plannedInstalationsFromBackend,
      openModalType: state.openModalType,
    }),
    shallow
  );

  const [currentPlannedInstalations, setCurrentPlannedInstalations] =
    useState<CurrentPlannedInstalations>({ ...INITIAL_CURRENT_PLANNED_INSTALATIONS });

  const selectedIndicator = usePNRStore((state) => state.selectedIndicator);

  const handleOpenModal = (openModalType: NetworkPlanningTypes) => setOpenModal(openModalType);

  useEffect(() => {
    if (!openModalType) {
      const currentEntrancePlannings = [...plannedInstalationsFromBackend.values()]
        .flat()
        .filter(
          (plannedInstalations) => plannedInstalations?.entrance === selectedIndicator.toString()
        );

      const currentPlannedInstalations = getCurrentEntranceInstalations(currentEntrancePlannings);
      setCurrentPlannedInstalations(currentPlannedInstalations);
    }
  }, [selectedIndicator, openModalType, plannedInstalationsFromBackend]);

  return (
    <>
      {Object.entries(currentPlannedInstalations)?.map(
        ([type, instalation]: [string, PlannedInstalationByTypes], index) => {
          return (
            <PnrElementsInfoCube
              key={index}
              onClick={() => handleOpenModal(type as NetworkPlanningTypes)}
              disabled={disableByRole}
            >
              <PnrElementsInfoWrapper>
                <PnrElementsInfoText>
                  {index === 0 && <>План (первичный)</>}
                  {index === 1 && <>План (вторичный)</>}
                  {index === 2 && <>План (вторичный предплан)</>}
                </PnrElementsInfoText>

                <PnrElementsLoading loading={isFetching}>
                  {!!instalation?.router?.backendInstalationsCount ||
                  !!instalation?.router?.userEditedInstalationsCount ||
                  !!instalation?.gateway?.backendInstalationsCount ||
                  !!instalation?.gateway?.userEditedInstalationsCount ? (
                    <PnrElementsWrapper>
                      {!!instalation?.router?.backendInstalationsCount && (
                        <PnrElementWrapper backendItem>
                          <div>
                            {getOsIcon(NetworkEquipmentIds.router)}

                            {!!instalation?.router?.hasConflictBackend && (
                              <sup>
                                <PnrPlanningModalConflictIcon
                                  color='error'
                                  fontSize='inherit'
                                />
                              </sup>
                            )}
                          </div>
                          <PnrElementsInfoSmallText>
                            {instalation?.router?.backendInstalationsCount}
                          </PnrElementsInfoSmallText>
                        </PnrElementWrapper>
                      )}

                      {!!instalation?.router?.userEditedInstalationsCount && (
                        <PnrElementWrapper>
                          <div>
                            {getOsIcon(NetworkEquipmentIds.router)}
                            {!!instalation?.router?.hasConflictUser && (
                              <sup>
                                <PnrPlanningModalConflictIcon
                                  color='error'
                                  fontSize='inherit'
                                />
                              </sup>
                            )}
                          </div>
                          <PnrElementsInfoSmallText>
                            {instalation?.router?.userEditedInstalationsCount}
                          </PnrElementsInfoSmallText>
                        </PnrElementWrapper>
                      )}

                      {!!instalation?.gateway?.backendInstalationsCount && (
                        <PnrElementWrapper backendItem>
                          <div>
                            {getOsIcon(NetworkEquipmentIds.gateway)}

                            {!!instalation?.gateway?.hasConflictBackend && (
                              <sup>
                                <PnrPlanningModalConflictIcon
                                  color='error'
                                  fontSize='inherit'
                                />
                              </sup>
                            )}
                          </div>
                          <PnrElementsInfoSmallText>
                            {instalation?.gateway?.backendInstalationsCount}
                          </PnrElementsInfoSmallText>
                        </PnrElementWrapper>
                      )}

                      {!!instalation?.gateway?.userEditedInstalationsCount && (
                        <PnrElementWrapper>
                          <div>
                            {getOsIcon(NetworkEquipmentIds.gateway)}

                            {!!instalation?.gateway?.hasConflictUser && (
                              <sup>
                                <PnrPlanningModalConflictIcon
                                  color='error'
                                  fontSize='inherit'
                                />
                              </sup>
                            )}
                          </div>
                          <PnrElementsInfoSmallText>
                            {instalation?.gateway?.userEditedInstalationsCount}
                          </PnrElementsInfoSmallText>
                        </PnrElementWrapper>
                      )}
                    </PnrElementsWrapper>
                  ) : (
                    <>{index !== 2 && <PnrElementsAddIcon />}</>
                  )}
                </PnrElementsLoading>
              </PnrElementsInfoWrapper>
            </PnrElementsInfoCube>
          );
        }
      )}
    </>
  );
};

export default PnrPlanning;
