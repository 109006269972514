import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { TasksBrowse } from '../../../components/features/tasksBrowse/TasksBrowseNew';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const TasksBrowsePage = () => {
  useAccess({
    access: [AccessEnum.TasksBrowse],
  });
  useMetaTitle(`Поиск заявок`);

  return <TasksBrowse />;
};

export default TasksBrowsePage;
