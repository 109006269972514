import {
  AdditionalParam,
  GeoBounds,
  GeoRequest,
  GeoResponse,
  TaskFilterParam,
} from '@/dto/taskmap/Dto';
import { ApiFindAllTaskGeoInBoundByParam } from '@/services/YodaRestService';
import { makeGeoRequest } from '@/components/maps/zoningMap/utils/GeoUtils';
import { createStore } from '@/utils/createStore';

interface GeoQueryStore {
  currentTaskFilterParam: TaskFilterParam;
  currentAdditionalParam: AdditionalParam | null;
  currentGeoResponse: GeoResponse | null;
  currentGeoResponseWithAdditionalParam: GeoResponse | null;
  geoError: string | null;
  isLoading: boolean;
  isClusterMode: boolean;
  execute: (geoBounds: GeoBounds, filterParam: TaskFilterParam) => void;
  executeWithAdditionaParam: (additionalParam: AdditionalParam | null) => void;
  setLoading: (isLoading: boolean) => void;
  setGepResponse: (geoResponse: GeoResponse) => void;
  setCurrentAdditionalParam: (additionalParam: AdditionalParam | null) => void;
  setCurrentGeoResponseWithAdditionalParam: (geoResponse: GeoResponse | null) => void;
}

export const useGeoQuery = createStore<GeoQueryStore>(
  (set, get) => ({
    currentTaskFilterParam: {} as TaskFilterParam,
    currentAdditionalParam: null,
    currentGeoResponse: null,
    currentGeoResponseWithAdditionalParam: null,
    geoError: null,
    isLoading: false,
    isClusterMode: true,

    execute: (geoBounds: GeoBounds, taskFilterParam: TaskFilterParam) => {
      const geoRequest: GeoRequest = makeGeoRequest(taskFilterParam, geoBounds);
      const isValid =
        geoRequest?.taskFilterParam &&
        Object.values(geoRequest?.taskFilterParam)?.filter((t: any) => t.length > 0)?.length > 0;
      if (isValid) {
        set({ isLoading: true });
        ApiFindAllTaskGeoInBoundByParam(geoRequest)
          .then((res) => {
            const geoResponse: GeoResponse = res.data;
            set({
              currentTaskFilterParam: taskFilterParam,
              currentGeoResponse: geoResponse,
              isClusterMode: geoResponse.count > 999,
            });
          })
          .catch((err) => set({ currentGeoResponse: null, geoError: err.response.data }))
          .finally(() => set({ isLoading: false }));
      }
    },

    executeWithAdditionaParam: (additionalParam: AdditionalParam | null) => {
      if (additionalParam != null) {
        const filterParam = get().currentTaskFilterParam;
        const geoRequest: GeoRequest = makeGeoRequest(filterParam, undefined, additionalParam);
        const isValid =
          geoRequest?.taskFilterParam &&
          Object.values(geoRequest?.taskFilterParam)?.filter((t: any) => t.length > 0)?.length > 0;
        set({ currentAdditionalParam: additionalParam });
        if (isValid) {
          set({ isLoading: true });
          ApiFindAllTaskGeoInBoundByParam(geoRequest)
            .then((res) =>
              set({
                currentAdditionalParam: additionalParam,
                currentGeoResponseWithAdditionalParam: res.data,
              })
            )
            .catch((err) => set({ currentAdditionalParam: null, geoError: err.response.data }))
            .finally(() => set({ isLoading: false }));
        }
      }
    },

    setLoading: (isLoading: boolean) => {
      set({ isLoading: isLoading });
    },

    setGepResponse: (geoResponse: GeoResponse) => {
      set({ currentGeoResponse: geoResponse });
    },

    setCurrentAdditionalParam: (additionalParam: AdditionalParam | null) => {
      set({ currentAdditionalParam: additionalParam });
    },

    setCurrentGeoResponseWithAdditionalParam: (geoResponse: GeoResponse | null) => {
      set({ currentGeoResponseWithAdditionalParam: geoResponse });
    },
  }),
  'Geo query'
);
