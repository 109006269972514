import { Button, Grid, Stack, Typography } from '@mui/material';
import DraggableList from './DraggableList';
import { useEffect, useState } from 'react';
import { color } from '@/styles/mixins';
import { BoxShadow } from '../ui/BoxShadow';
import { DropResult } from 'react-beautiful-dnd';
import { MultiSelect } from '../inputs/MultiSelectNew';
import { Popup } from '@/components/popups';

export interface ListItem {
  id: string;
  label: string;
  value: string;
}
interface DndSelectProps {
  title: string;
  list: ListItem[];
  listName?: string;
  listener: (parms: any) => void;
  setTableData: (param: any) => void;
}

const reorder = (list: ListItem[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DndSelect = ({ title, list, listener, listName, setTableData }: DndSelectProps) => {
  const [dndList, setDndList] = useState<ListItem[]>([]);
  const [openPopUp, setOpenPopUp] = useState(false);

  const [selectedItemstList, setSelectedItemsList] = useState<ListItem[]>([]);
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    const newItems = reorder(dndList, source.index, destination.index);
    setDndList(newItems);
  };
  const handelDelete = (id: string) => {
    const filteredDndList = dndList.filter((el) => el.id !== id);
    setDndList(filteredDndList);
    setSelectedItemsList((prevItems) => prevItems.filter((el) => el.id !== id));
  };

  useEffect(() => {
    listener({ dndList, listName });
    setTableData([]);
  }, [dndList]);

  const handleInput = (el: ListItem[], title: string) => {
    if (title === 'Строки' && el.length >= 3) {
      setOpenPopUp(true);
      return;
    }
    if (!el.length) {
      setSelectedItemsList(el);
      setDndList([]);
      return;
    }

    const newEl = el.at(-1);
    setSelectedItemsList(el);
    const isAlreadyInDndList = el.find((el: ListItem) => el.id === newEl?.id);
    if (newEl && !isAlreadyInDndList) {
      setDndList((prevDndList) => [...prevDndList, newEl]);
    } else {
      setDndList(el);
    }
    listener({ dndList, listName });
  };

  return (
    <Grid
      style={{
        padding: 5,
        borderColor: color('trueBlack'),
        borderWidth: 1,
      }}
    >
      <Popup
        isOpen={openPopUp}
        onClose={() => setOpenPopUp(false)}
      >
        <Stack>
          <Typography
            variant='h5'
            gutterBottom
          >
            Вложенность строк не может быть больше двух
          </Typography>
          <Button
            variant='text'
            onClick={() => setOpenPopUp(false)}
          >
            закрыть
          </Button>
        </Stack>
      </Popup>
      <BoxShadow>
        <Stack
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={2}
        >
          <div style={{ width: '100%' }}>
            <MultiSelect
              sx={{ width: '100%' }}
              label={title}
              value={selectedItemstList}
              onlyInListArgument
              options={list}
              onChange={(el: ListItem[]) => handleInput(el, title)}
            />
          </div>
        </Stack>

        <DraggableList
          onDragEnd={onDragEnd}
          items={dndList}
          handelDelete={handelDelete}
        />
      </BoxShadow>
    </Grid>
  );
};

export default DndSelect;
