import React, { useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SupplyBillBrowseDto } from '@/dto/taskmap/Dto';
import { SupplyBillBrowseItemDeleteIcon } from './SupplyBillBrowseItem.styled';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { SupplyBillApiDeleteBill } from '@/services/SupplyBillService';

export interface SupplyBillBrowseItemDeleteProps extends Pick<SupplyBillBrowseDto, 'id'> {
  updateData: () => void;
  disabled?: boolean;
}

const SupplyBillBrowseItemDelete = ({
  id,
  updateData,
  disabled,
}: SupplyBillBrowseItemDeleteProps) => {
  const { addActionLog, catchError } = useActionLog();
  const [loading, setLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    openModal();
  };
  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);
  const successDeletion = () => {
    setLoading(true);
    SupplyBillApiDeleteBill(id?.toString())
      .then(() => addActionLog(ActionLogType.SUCCESS, 'Черновик удалён'))
      .catch((err) => catchError('Ошибка удаления черновика', { err }))
      .finally(() => {
        setLoading(false);
        closeModal();
        updateData();
      });
  };

  return (
    <>
      <SupplyBillBrowseItemDeleteIcon
        onClick={handleClick}
        onDoubleClick={handleClick}
        disabled={disabled}
      >
        <DeleteOutlineIcon
          fontSize='inherit'
          color={disabled ? undefined : 'error'}
        />
      </SupplyBillBrowseItemDeleteIcon>

      <AccessPopup
        isOpen={isOpenModal}
        onClose={closeModal}
        onSuccess={() => successDeletion()}
        headerText='Удаление'
        titleText='Вы действительно хотите удалить черновик?'
        successText='Да'
        closeText='Нет'
        isLoading={loading}
      />
    </>
  );
};

export default SupplyBillBrowseItemDelete;
