import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import DateField from '@/components/DateField';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { FormProvider, useForm } from 'react-hook-form';
import { TextField } from '@/components/fixUI';
import FileInput from '@/components/inputs/FileInput/FileInput';
import { createAdpu } from '@/services/AdpuService';
import { AdpuResponse } from '@/dto/AdpuDto';
import { useParams } from 'react-router-dom';
import { uploadFile } from '@/services/FileService';

interface IActPopup {
  openDialog: boolean;
  onClose: () => void;
  updateFunction: (data: AdpuResponse) => void;
}

export const FILE_TYPES = [
  '3b48d860-e78e-725e-0ec6-9eae7cb960a9', // АДПУ стр.1
  '6b0c9559-979f-a01c-39b2-92c1eba6fdd7', // АДПУ (лицевая сторона)
  '8b98cabd-9fa6-4c8d-9e87-2db2c4aee8a6', // АДПУ (доп. фото)
  '09e4fb07-67d1-5915-54c9-b7133137abca', // АДПУ стр.4
  '130c76ef-37a7-dde9-bc15-7347285ccb81', // АДПУ (доп.фото)
  '628a14b8-ce71-0305-7d6c-bcce6c0cba9b', // АДПУ в формате pdf
  '813f4b16-d4cd-cda0-5842-a5ba3ef1bc7d', // АДПУ стр.2
  '6049b887-d716-c1b8-c471-1ace05ace420', // Накладная АДПУ
  '7442b32f-fdf7-4858-995c-b7aa3a65d58a', // АДПУ (оборотная сторона)
  '718008e0-c9aa-58e1-069b-15dc002977f5', // АДПУ стр.3
  '12967411-9b3a-fb93-7001-8dc6d62097df', // АДПУ передан в ЦРИСУ
  'a17d4c6e-3ff4-4ca1-84e0-b9ac876f4c1f', // АДПУ (оборотная сторона)
  'b328016e-ebbd-419a-a9cf-843133c791a5', // АДПУ (лицевая сторона)
  'ded134c6-0922-eff7-5e23-7f411eb38628', // Подпись АДПУ
];

export const ActPopup: FC<IActPopup> = ({ openDialog, onClose, updateFunction }) => {
  const { fetchCatch, addActionLog } = useActionLog();
  const { taskId } = useParams();
  const methods = useForm();
  const { register, setValue, handleSubmit, watch } = methods;
  const [open, setOpen] = useState(false);
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { setIsInProgress } = useScreenHoldHook();

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const unlockSave =
    watch('newAdpu.files')?.length > 0 &&
    watch('newAdpu.actOn') > 0 &&
    watch('newAdpu.montageName') != '' &&
    watch('newAdpu.actStatus') != null;

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleSave = (b: any) => {
    setIsInProgress(true);
    taskId &&
      createAdpu({
        signedADPU: b.newAdpu.signedADPU,
        actStatus: b.newAdpu.actStatus,
        montageName: b.newAdpu.montageName,
        actOn: b.newAdpu.actOn,
        taskId,
      })
        .then(({ data }) => {
          updateFunction(data);
          uploadFile(data.id, FILE_TYPES, '', b.newAdpu.files)
            .then(() => {
              updateFunction(data);
              addActionLog(ActionLogType.SUCCESS, 'АДПУ успешно подписан');
            })
            .catch((res) => {
              fetchCatch(res, 'Ошибка загрузки файла');
            });
        })
        .catch((res) => {
          fetchCatch(res, 'Ошибка подписания АДПУ');
        })
        .finally(() => {
          setIsInProgress(false);
          onClose();
        });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='cancel-dialog-title'
      aria-describedby='cancel-dialog-description'
    >
      <DialogTitle id='replacement-reason-dialog-title'>Внесите данные</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <DateField
            label='Дата подписания акта'
            hookFormString={`newAdpu.actOn`}
            required
          />
          <AutocompleteValue
            title='Статус акта АДПУ'
            fieldName={`newAdpu.actStatus`}
            values={getCatalogMapWithErr('actStatus')}
            required
          />
          <TextField
            id='montageName'
            label='Подписано кем от подрядчика'
            variant='standard'
            {...register(`newAdpu.montageName`, { required: true })}
            sx={{ width: '100%' }}
          />
          <TextField
            id='montageName'
            label='Подписано кем от потребителя'
            variant='standard'
            {...register(`newAdpu.signedADPU`)}
            sx={{ width: '100%' }}
          />
          <FileInput
            setFormFiles={setValue}
            register={register('newAdpu.files', { required: true })}
          />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        {!unlockSave ? (
          <Tooltip title={'Необходимо заполнить обязательные поля'}>
            <span>
              <Button
                onClick={handleSubmit(handleSave)}
                autoFocus
                disabled={!unlockSave}
              >
                Сохранить
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            onClick={handleSubmit(handleSave)}
            autoFocus
            disabled={!unlockSave}
          >
            Сохранить
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
