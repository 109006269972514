import React from 'react';
import { HousePnrBrowse } from '@/components/features/pnr/HousePnrBrowse/HousePnrBrowse';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const PnrEntrancePage = () => {
  useAccess({
    access: [AccessEnum.Map, AccessEnum.MapUspd],
  });
  useMetaTitle('Дома');

  return <HousePnrBrowse />;
};

export default PnrEntrancePage;
