import { Grid, styled } from '@mui/material';

export const ComplaintFormODPUDatePickerFixWrapper = styled(Grid)({
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-end',

  '.parent': { padding: 8 },

  '.data-range-input': { padding: 0, borderBottom: '1px solid rgba(0, 0, 0, 0.42)', fontSize: 16 },

  '& >div': {
    width: '100%',
  },
});
