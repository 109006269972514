import { TextField } from '@/components/fixUI';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { Typography } from '@mui/material';
import { TransStationFeederMeterResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { useStatus } from '../panel/store';
import { useActionLog } from '@/hooks/ActionLogHook';

interface IFormPU {
  meter?: TransStationFeederMeterResponse;
  keyStr: string;
}

export const FormPU: FC<IFormPU> = ({ keyStr, meter = {} }) => {
  const { register, setValue } = useFormContext();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  useEffect(() => {
    setValue(`${keyStr}.id`, meter?.id ? meter.id : 'new');
  }, [meter]);
  const { isDisabledAll } = useStatus((state) => ({
    isDisabledAll: state.isDisabledAll,
  }));
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant={'body1'}
          sx={{ mb: 0.5, mr: 1 }}
        >
          Тип ПУ:
        </Typography>
        <TextField
          id='tf-ktt'
          variant='standard'
          {...register(`${keyStr}.puType`, {
            value: meter?.puType,
          })}
          disabled={isDisabledAll()}
        />
      </div>
      <TextField
        id='tf-number'
        label='Номер'
        variant='standard'
        sx={{ width: '100%' }}
        {...register(`${keyStr}.number`, {
          value: meter?.number,
        })}
        disabled={isDisabledAll()}
      />
      <AutocompleteValue
        title='Место установки'
        fieldName={`${keyStr}.exPuPlace.id`}
        defaultValue={'1'}
        values={getCatalogMapWithErr('exPuPlace')}
        disabled={isDisabledAll()}
      />
    </>
  );
};
