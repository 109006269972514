import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { color } from '@/styles/mixins';
import agree from '../images/agree.svg';
import newCall from '../images/newCall.svg';
import refuseCall from '../images/refuseCall.svg';
import missedCall from '../images/missedCall.svg';
import callBack from '../images/callBack.svg';
import noResultCall from '../images/noResultCall.svg';
import wrongAdressCall from '../images/wrongAdressCall.svg';
type LegendData = Record<string, string>;

const LegendData: LegendData = {
  'Новая заявка': newCall,
  'Согласие на замену ПУ ': agree,
  Недозвон: missedCall,
  'Отказ от замены ПУ': wrongAdressCall,
  Перезвонить: callBack,
  'Просрочена ': noResultCall,
  'Не имеет отношение к  адресу': refuseCall,
};
interface LegendProps {
  handelCloseSettings: () => void;
}
const calculateXs = (index: number, totalItems: number): number => {
  return index === totalItems - 1 ? 7 : 6;
};

const Legend = ({ handelCloseSettings }: LegendProps) => {
  return (
    <Stack>
      <Divider />
      <Stack>
        <CloseFullscreenIcon
          onClick={handelCloseSettings}
          style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
        />
      </Stack>
      <Stack p={1}>
        <Grid
          container
          spacing={2}
        >
          {Object.entries(LegendData).map(([key, image], index) => {
            return (
              <Grid
                item
                xs={calculateXs(index, Object.keys(LegendData).length)}
                key={key}
              >
                <Box
                  display={'flex'}
                  gap={2}
                >
                  <img
                    width={25}
                    height={25}
                    src={image}
                    alt='statusIcon'
                  />
                  <Typography
                    variant='subtitle1'
                    sx={{ width: '100%', color: color('trueBlack') }}
                  >
                    {key}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default Legend;
