import React from 'react';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import Paper from '@mui/material/Paper';
import { Grid } from '@material-ui/core';
import { MultiSelect } from '../../inputs/MultiSelectNew';
import { Divider, Table } from 'antd';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';

import {
  DownloadStatisticUrgentAndSuperUrgentExel,
  GetScheduleDate,
} from '@/services/YodaRestService';
import { useActionLog } from '@/hooks/ActionLogHook';

import moment from 'moment';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '../../features/tasksBrowse/utils';
import {
  FilterStatisticUrgentAndSuperUrgent,
  StatisticUrgentAndSuperUrgentTasksDto,
} from '@/dto/taskmap/Dto';
import { getDataFromFilter } from '../../inputs/MultiSelectNew/utils';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '../../button/DetailedInfo/utils';
import { LoadingButton } from '@mui/lab';
import { createChartData, options, renderColumns } from './utils';
import { downLoadExcel } from '../../../utils/heplers';
import { useAllContractQuery } from '@/hooks/useQuery/useAllContractQuery';
import {
  StatisticUrgentAndSuperUrgentTasksEmpty,
  StatisticUrgentAndSuperUrgentTasksFiltersWrapper,
  StatisticUrgentAndSuperUrgentTasksTableWrapper,
} from './StatisticUrgentAndSuperUrgentTasks.styled';

interface StatisticUrgentAndSuperUrgentTasksProps {
  header: string;
  load: boolean;
  data: StatisticUrgentAndSuperUrgentTasksDto[];
  updateFunction: (filter: FilterStatisticUrgentAndSuperUrgent) => void;
}

function convertDate(date: any) {
  return date.map((item: any) => ({ value: item, label: moment(item).format('DD.MM.YYYY') }));
}

export const StatisticUrgentAndSuperUrgentTasks: React.FC<
  StatisticUrgentAndSuperUrgentTasksProps
> = ({ header, load, data, updateFunction }) => {
  const methods = useForm();
  const { catchError } = useActionLog();
  const { getCatalogMapWithErr, taskType, taskPriority } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    taskType: state.taskType,
    taskPriority: state.taskPriority,
  }));
  const valueToFilterTaskType = ['ASUMB_GOVERNMENT', 'ASUMB_COMPLAINT_MKD', 'ASUMB_UGP'];
  const { handleSubmit } = methods;
  const contractListResponse = useAllContractQuery();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [selectedTypeTask, setSelectedTypeTask] = React.useState<SelectFilterOptType[]>([]);
  const [selectedTaskPriority, setSelectedTaskPriority] = React.useState<SelectFilterOptType[]>([]);
  const [selectedDate, setSelectedDate] = React.useState<SelectFilterOptType[]>([]);
  const [initDate, setInitDate] = React.useState<SelectFilterOptType[]>([]);

  const [loading, setLoading] = React.useState(false);

  const filteredTypeTasks = React.useMemo(
    () =>
      taskMapToArray(getCatalogMapWithErr('taskType')).filter((item) =>
        valueToFilterTaskType.includes(item.value)
      ),
    [taskType]
  );
  const filteredTaskPriorit = React.useMemo(
    () =>
      taskMapToArray(getCatalogMapWithErr('taskPriority')).filter(
        (item) => item.value !== 'REGULAR'
      ),
    [taskPriority]
  );
  const hasAllFilterParams =
    selectedContractList.length && selectedTaskPriority.length && selectedTypeTask.length;

  const onSubmitSearch = () => {
    if (hasAllFilterParams) {
      const filter: FilterStatisticUrgentAndSuperUrgent = {
        customerScheduleDateFilter: {
          contractIdList: getDataFromFilter(selectedContractList, 'value'),
          taskTypeList: getDataFromFilter(selectedTypeTask, 'value'),
          taskPriorityList: getDataFromFilter(selectedTaskPriority, 'value'),
        },
        customerScheduleDateList: getDataFromFilter(selectedDate, 'value') as unknown as Date[],
      };
      updateFunction(filter);
    }
  };

  React.useEffect(() => {
    if (hasAllFilterParams) {
      const body = {
        contractIdList: getDataFromFilter(selectedContractList, 'value'),
        taskTypeList: getDataFromFilter(selectedTypeTask, 'value'),
        taskPriorityList: getDataFromFilter(selectedTaskPriority, 'value'),
      };
      GetScheduleDate(body).then(({ data }) => {
        setInitDate(convertDate(data));
      });
    }
  }, [selectedContractList, selectedTaskPriority, selectedTypeTask]);

  const onResetClick = () => {
    setSelectedContractList([]);
    setSelectedTypeTask([]);
    setSelectedTaskPriority([]);
    setInitDate([]);
    setSelectedDate([]);
    updateFunction({
      customerScheduleDateFilter: {
        contractIdList: [],
        taskTypeList: [],
        taskPriorityList: [],
      },
      customerScheduleDateList: [],
    });
  };
  const handleLoding = (isLoading: boolean) => setLoading(isLoading);

  const handeLDownLoadExcel = () =>
    downLoadExcel(DownloadStatisticUrgentAndSuperUrgentExel, data, catchError, handleLoding);

  const disableExcelButton = data.length <= 0;

  const detailedInfo = generateDetailedInfo(
    [selectedContractList, 'Контракт'],
    [selectedTypeTask, 'Тип заявки'],
    [selectedTaskPriority, 'Приоритет'],
    [selectedDate, 'Дата']
  );
  return (
    <>
      <Paper elevation={6}>
        <p className={'headerPageStatistics'}>{header}</p>

        <Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitSearch)}>
              <StatisticUrgentAndSuperUrgentTasksFiltersWrapper>
                <MultiSelect
                  label={'Контракт'}
                  value={selectedContractList}
                  onlyInListArgument
                  options={contractListResponse ?? []}
                  onChange={setSelectedContractList}
                />

                <MultiSelect
                  label={'Тип заявки'}
                  value={selectedTypeTask}
                  onlyInListArgument
                  options={filteredTypeTasks}
                  onChange={setSelectedTypeTask}
                />

                <MultiSelect
                  label={'Приоритет'}
                  value={selectedTaskPriority}
                  onlyInListArgument
                  options={filteredTaskPriorit}
                  onChange={setSelectedTaskPriority}
                />

                <MultiSelect
                  label={'Дата'}
                  value={selectedDate}
                  onlyInListArgument
                  options={initDate}
                  onChange={setSelectedDate}
                />

                <FilterButtons
                  disable={!hasAllFilterParams}
                  info={detailedInfo}
                  onSearchClick={() => handleSubmit(onSubmitSearch)}
                  onCloseClick={onResetClick}
                />

                <LoadingButton
                  loading={loading}
                  disabled={disableExcelButton || loading}
                  startIcon={
                    <SaveAltIcon
                      fontSize='large'
                      style={{ color: disableExcelButton || loading ? '#CACACA' : '#6EA8E2' }}
                    />
                  }
                  onClick={handeLDownLoadExcel}
                  variant='text'
                  sx={{ marginLeft: '10px' }}
                >
                  скачать excel
                </LoadingButton>
              </StatisticUrgentAndSuperUrgentTasksFiltersWrapper>
            </form>
          </FormProvider>
          {load ? (
            <LoadSpinner />
          ) : (
            <div style={{ height: 'auto', width: '100%' }}>
              <StatisticUrgentAndSuperUrgentTasksTableWrapper>
                {!!data.length &&
                  data.map((tableData: any, index: number) => (
                    <Table
                      bordered
                      pagination={false}
                      size='small'
                      key={tableData.customerScheduleDate}
                      rowKey={(reocrd) => reocrd.contract}
                      expandable={{
                        showExpandColumn: false,
                        expandRowByClick: true,
                        childrenColumnName: 'montagePlaceStatistic',
                      }}
                      style={{ marginBottom: 20 }}
                      columns={renderColumns()}
                      dataSource={tableData.taskUrgentStatisticList}
                      title={() => {
                        return (
                          <Divider style={{ textAlign: 'center' }}>
                            Срок до {moment(data[index]?.customerScheduleDate).format('DD.MM.YYYY')}
                          </Divider>
                        );
                      }}
                    />
                  ))}

                {!data.length && (
                  <StatisticUrgentAndSuperUrgentTasksEmpty>
                    Нет данных для отображения
                  </StatisticUrgentAndSuperUrgentTasksEmpty>
                )}
              </StatisticUrgentAndSuperUrgentTasksTableWrapper>
              <StatisticUrgentAndSuperUrgentTasksTableWrapper>
                {!!data.length &&
                  data.map((tableData: any, index: number) => {
                    const filterData = tableData.taskUrgentStatisticList.filter(
                      (c: any) => c.contract !== 'Всего'
                    );
                    const dataBar: any = createChartData(filterData);
                    return (
                      <div key={tableData.customerScheduleDate}>
                        <Divider style={{ textAlign: 'center' }}>
                          Срок до {moment(data[index]?.customerScheduleDate).format('DD.MM.YYYY')}
                        </Divider>
                        <Chart
                          style={{ minHeight: 250, minWidth: 300 }}
                          options={options}
                          type='bar'
                          data={dataBar}
                          plugins={[ChartDataLabels]}
                        />
                      </div>
                    );
                  })}
              </StatisticUrgentAndSuperUrgentTasksTableWrapper>
            </div>
          )}
        </Grid>
      </Paper>
    </>
  );
};
