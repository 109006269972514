import { Box, Button, Divider, Grid, Stack, TextField } from '@mui/material';

import { useEffect, useState } from 'react';
import { ExecuterType } from '../types/types';
import { checkName, compareUsers } from '../utils/CallingMapUtils';
import { useCallingMapState } from '../store';
import {
  getAllExecutersCallMap,
  getExecutersAvalibleCallMap,
  sendExecutersCallMap,
} from '@/services/CallingMapService';
import shallow from 'zustand/shallow';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';

import React from 'react';
import ExecuterItem from './ExecuterItem';
import { StyledTypography } from './СallingMap.styled';

const ExecutersList = ({ handelCloseSettings }: any) => {
  const taskGroupMap = useCatalog((state) => state.taskGroupMap);

  const { executors, avalibleExecutors, setAvalibleExecutors, setExecutors } = useCallingMapState(
    (state) => ({
      setAvalibleExecutors: state.setAvalibleExecutors,
      executors: state.executors,
      avalibleExecutors: state.avalibleExecutors,
      setExecutors: state.setExecutors,
    }),
    shallow
  );
  const { setIsInProgress } = useScreenHoldHook();

  useEffect(() => {
    if (!avalibleExecutors.length) {
      getExecutersAvalibleCallMap().then(({ data }) => {
        setAvalibleExecutors(data);
      });
      getAllExecutersCallMap().then(({ data }) => {
        setExecutors(data);
      });
    }
  }, []);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<ExecuterType[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<ExecuterType[]>([]);
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]);
  const [isTouched, setIsTouched] = useState(false);
  const { catchError, addActionLog } = useActionLog();

  const handleCheckboxToggle = (userId: string) => {
    const updatedSelectedUsers = selectedUsers.map((user) =>
      user.userId === userId ? { ...user, selected: !user.selected } : user
    );
    setSelectedUsersIds(
      updatedSelectedUsers.filter((user) => user.selected).map((user) => user.userId)
    );
    setSelectedUsers(updatedSelectedUsers);
    setIsTouched(true);
  };

  const sendSelectedExecuters = () => {
    setIsInProgress(true);
    sendExecutersCallMap(selectedUsersIds)
      .then(({ data }) => {
        setExecutors(data);
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
      })
      .catch((error) => catchError('Ошибка загрузки исполнителей', error))
      .finally(() => setIsInProgress(false));
  };

  useEffect(() => {
    const customEx = avalibleExecutors.map((avEx) => {
      return {
        ...avEx,
        selected: executors.some((ex) => ex.userId === avEx.userId),
      };
    });
    const sortedBySelectedFieldAndLastName = [...customEx].sort(compareUsers);
    setSelectedUsers(sortedBySelectedFieldAndLastName);
  }, [avalibleExecutors, executors]);

  useEffect(() => {
    const filteredEx = selectedUsers.filter((user) =>
      checkName(user, taskGroupMap.get(user.groupId))
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filteredEx);
  }, [searchTerm, selectedUsers]);

  const colorLengthSelectedEx = !!selectedUsers.filter((u) => u.selected).length;

  return (
    <Stack>
      <Divider />

      <Grid
        xs={12}
        container
        paddingX={1}
        paddingTop={2}
      >
        <Grid
          item
          xs={4}
        >
          <StyledTypography variant='subtitle1'>Монтажников в списке:</StyledTypography>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <StyledTypography variant='subtitle1'>{avalibleExecutors.length}</StyledTypography>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        container
        paddingX={1}
        paddingBottom={2}
      >
        <Grid
          item
          xs={4}
        >
          <StyledTypography variant='subtitle1'>Выбрано монтажников:</StyledTypography>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <StyledTypography
            variant='subtitle1'
            colorLengthSelectedEx={colorLengthSelectedEx}
          >
            {selectedUsers.filter((u) => u.selected).length}
          </StyledTypography>
        </Grid>
      </Grid>

      <Box paddingX={1}>
        <TextField
          fullWidth
          id='standard-basic'
          label='Введите Ф.И.О или бригаду '
          variant='outlined'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      <Stack
        overflow={'auto'}
        maxHeight={300}
        paddingX={1}
      >
        {filteredUsers.map((user) => (
          <ExecuterItem
            key={user.userId}
            user={user}
            handleCheckboxToggle={handleCheckboxToggle}
            taskGroupMap={taskGroupMap}
          />
        ))}
      </Stack>
      <Stack
        p={2}
        flexDirection={'row'}
        justifyContent={'space-evenly'}
      >
        <Button
          disabled={!isTouched}
          variant='outlined'
          onClick={() => {
            sendSelectedExecuters();
            handelCloseSettings();
          }}
        >
          Сохранить
        </Button>
        <Button
          variant='outlined'
          onClick={handelCloseSettings}
        >
          Отмена
        </Button>
      </Stack>
    </Stack>
  );
};

export default ExecutersList;
