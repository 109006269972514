import { color } from '@/styles/mixins';

const getCellStyle = (record: any, defaultColor: string) => {
  const dataIndexToUse = 'montagePlace';
  let backgroundColor;
  if (record[dataIndexToUse] === 'квартира') {
    backgroundColor = color('mintGreen');
  } else if (record[dataIndexToUse] === 'лестница') {
    backgroundColor = color('salmon');
  } else if (record[dataIndexToUse] === 'холл') {
    backgroundColor = color('cream');
  } else {
    backgroundColor = defaultColor;
  }
  return { backgroundColor };
};

export const options: any = {
  plugins: {
    datalabels: {
      color: color('trueBlack'),
    },
    legend: {
      labels: {
        color: color('trueBlack'), // Устанавливаем цвет шрифта легенды в черный
      },
    },
    tooltip: {
      displayColors: false,
      backgroundColor: (color: any) => color?.tooltip?.labelColors[0]?.backgroundColor || '',
      callbacks: {},
    },
  },
  scales: {
    y: {
      ticks: {
        color: color('trueBlack'), // Устанавливаем цвет текста бара в черный
      },
      grid: {
        display: false,
      },
    },
    x: {
      ticks: {
        color: color('trueBlack'),
      },
    },
  },

  responsive: true,
};

export const renderColumns: any = () => {
  return [
    {
      title: '',
      dataIndex: 'dynamicDataIndex',
      align: 'left',
      render: (text: string, record: any) => {
        const dataIndexToUse = record.contract ? 'contract' : 'montagePlace';
        return (
          <span
            style={{
              maxWidth: '100px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              cursor: 'pointer',
            }}
          >
            {record[dataIndexToUse]}
          </span>
        );
      },
    },
    {
      title: 'Всего',
      dataIndex: 'total',
      align: 'center',
      className: 'custom-column-class-yellow',
    },
    {
      title: 'Установлено',
      children: [
        {
          title: 'шт',
          dataIndex: 'installedQuantity',
          align: 'center',
          render(text: string, record: any) {
            const cellStyle = getCellStyle(record, '#c6e6c6');
            return {
              props: {
                style: cellStyle,
              },
              children: <>{text}</>,
            };
          },
        },
        {
          title: '%',
          dataIndex: 'installedPercent',
          align: 'center',
          render(text: string, record: any) {
            const cellStyle = getCellStyle(record, '#c6e6c6');
            return {
              props: {
                style: cellStyle,
              },
              children: <>{text}%</>,
            };
          },
        },
      ],
      align: 'center',
    },
    {
      title: 'ТКО',
      children: [
        {
          title: 'шт',
          dataIndex: 'tkoQuantity',
          align: 'center',
          render(text: string, record: any) {
            const cellStyle = getCellStyle(record, '#f4fafe');
            return {
              props: {
                style: cellStyle,
              },
              children: <>{text}</>,
            };
          },
        },
        {
          title: '%',
          dataIndex: 'tkoPercent',
          align: 'center',
          render(text: string, record: any) {
            const cellStyle = getCellStyle(record, '#f4fafe');
            return {
              props: {
                style: cellStyle,
              },
              children: <>{text}%</>,
            };
          },
        },
      ],
      align: 'center',
    },
    {
      title: 'Остаток',
      children: [
        {
          title: 'шт',
          dataIndex: 'remainderQuantity',
          align: 'center',
          render(text: string, record: any) {
            const cellStyle = getCellStyle(record, '#dfbae6');
            return {
              props: {
                style: cellStyle,
              },
              children: <>{text}</>,
            };
          },
        },
        {
          title: '%',
          dataIndex: 'remainderPercent',
          align: 'center',
          render(text: string, record: any) {
            const cellStyle = getCellStyle(record, '#dfbae6');
            return {
              props: {
                style: cellStyle,
              },
              children: <>{text}%</>,
            };
          },
        },
      ],
      align: 'center',
    },
  ];
};

export const createChartData = (filterData: any) => ({
  labels: filterData.map((c: any) => c.contract),
  datasets: [
    createBarDataset('Установлено', filterData, 'installedQuantity', 'installedPercent', '#93BF95'),
    createBarDataset('ТКО', filterData, 'tkoQuantity', 'tkoPercent', '#AFD3FA'),
    createBarDataset('Остаток', filterData, 'remainderQuantity', 'remainderPercent', '#A172A9'),
  ],
});

// Функция для создания конфигурации столбца графика
const createBarDataset = (
  label: string,
  filterData: any,
  dataKey: string,
  percentKey: string,
  backgroundColor: string
) => ({
  type: 'bar',
  label,
  data: filterData.map((c: any) => c[dataKey]),
  backgroundColor,
  minBarLength: 20,
  datalabels: {
    labels: {
      l1: {
        anchor: 'end',
        align: 'end',
        offset: -7,
      },
      l2: {
        anchor: 'start',
        align: 'start',
        offset: -20,
        color: 'white',
        font: {
          size: 16,
        },
        formatter: (value: any, ctx: any) => {
          const val = filterData[ctx.dataIndex]?.[percentKey];
          return `${val}%`;
        },
      },
    },
  },
});
