import { RegistryEditor } from '@/components/features/registryEditor/RegistryEditor';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import React from 'react';

const RegistryEditorPage = () => {
  useAccess({
    access: [AccessEnum.Registry],
  });

  return <RegistryEditor />;
};

export default RegistryEditorPage;
