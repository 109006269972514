import { useActionLog } from '@/hooks/ActionLogHook';
import { useMemo, useState } from 'react';
import { KEYS } from './Keys';
import { getTransformerAmperage } from '@/services/TransformerService';
import { FetchCathError } from '@/types/api';
import { useQuery } from 'react-query';
import { TransformerAmperage } from '@/dto/TransformerDto';

export function useTransformerAmperage(id: string, type: 'task' | 'vru-input') {
  const { catchError } = useActionLog();
  const [data, setData] = useState<TransformerAmperage>();
  const [isExist, setIsExist] = useState(false);

  const { error, isFetching } = useQuery({
    enabled: !!id && !!type,
    queryKey: [KEYS.transformerAmperage, id, type],
    refetchOnWindowFocus: false,
    queryFn: () => getTransformerAmperage(id, type),
    onSuccess: (res) => {
      setData(res.data);
      setIsExist(
        !!res.data.smrDate ||
          !!res.data.montageName ||
          !!res.data.unmount ||
          !!res.data.mount ||
          !!res.data.ktt
      );
    },
    onError: (err: FetchCathError) => {
      setIsExist(false);
      catchError(err?.response?.data?.message || 'Непредвиденная ошибка', {});
    },
  });

  // Мемоизация результата хука для предотвращения ненужных запросов
  const memoizedResult = useMemo(
    () => ({
      data,
      error,
      isFetching,
      isExist,
    }),
    [data, error, isFetching, isExist]
  );

  return memoizedResult;
}
