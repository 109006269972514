import { BubbleDataPoint, ChartData, ScatterDataPoint } from 'chart.js';
import { color } from '@/styles/mixins';
import { AsumbTaskStatisticsForCheckChartType } from './RequestStatistic/CheckStatistics/types';
import { MeterChangeForRevisionData } from './RequestStatistic/MeterChangeForRevision/types';

export const MeterChangeForRevisionStatisticsInitData: MeterChangeForRevisionData = {
  all: {
    labels: ['2022-01-03', '2022-01-04', '2022-01-05'],
    datasets: [
      {
        label: 'Статус не определён',
        data: [500000, 500000],
        borderColor: color('yellow'),
      },
    ],
  },
  dataBar: [
    {
      labels: ['На проверке'],
      datasets: [
        {
          label: 'Загрузка',
          data: [500000],
          borderColor: [color('blue2')],
          backgroundColor: [color('blue2')],
        },
        {
          label: 'Загрузка',
          data: [500000],
          borderColor: [color('purple')],
          backgroundColor: [color('purple')],
        },
      ],
    },
    {
      labels: ['На проверке'],
      datasets: [
        {
          label: 'Загрузка',
          data: [500000],
          borderColor: [color('blue2')],
          backgroundColor: [color('blue2')],
        },
        {
          label: 'Загрузка',
          data: [500000],
          borderColor: [color('purple')],
          backgroundColor: [color('purple')],
        },
      ],
    },
    {
      labels: ['На проверке'],
      datasets: [
        {
          label: 'Загрузка',
          data: [500000],
          borderColor: [color('blue2')],
          backgroundColor: [color('blue2')],
        },
        {
          label: 'Загрузка',
          data: [500000],
          borderColor: [color('purple')],
          backgroundColor: [color('purple')],
        },
      ],
    },
  ],
};

export const AsumbTaskStatisticsForCheckInitData: AsumbTaskStatisticsForCheckChartType = {
  all: {
    labels: ['На проверке'],
    datasets: [
      {
        label: 'Загрузка',
        data: [500000],
        borderColor: [color('blue2')],
      },
    ],
  },
  tko: {
    labels: ['Статус не определён'],
    datasets: [
      {
        label: 'Статус не определён',
        data: [500000],
        borderColor: [color('yellow')],
      },
    ],
  },
  meterChange: {
    labels: ['Статус не определён'],
    datasets: [
      {
        label: 'Статус не определён',
        data: [500000],
        borderColor: [color('yellow')],
      },
    ],
  },
};

export const ASUMB_STATISTICS_INIT_DATA = {
  title: 'Ожидает загрузки',
  value: 100,
  color: color('yellow'),
};

export const ASUMB_CHART_INIT_DATA = {
  labels: ['2022-01-01', '2022-01-02', '2022-01-03', '2022-01-04'],
  datasets: [
    {
      label: 'Статус не определён',
      data: [500000, null, 500000, 500000],
      borderColor: color('yellow'),
    },
  ],
};

export type ASUMB_STATISTICS_CHART_TYPE = ChartData<
  'line',
  (number | ScatterDataPoint | BubbleDataPoint | null)[],
  unknown
>;
