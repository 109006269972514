import {
  ArcElement,
  BarElement,
  BubbleDataPoint,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  ScatterDataPoint,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import React from 'react';
import { EMBEDDED_STATISTICS_BAR_OPTIONS } from './utils';

interface EmbeddedStatisticsBarProps {
  data: ChartData<'bar', (number | ScatterDataPoint | BubbleDataPoint | null)[]>;
}
ChartJS.register(CategoryScale, ArcElement, BarElement, Title, Tooltip, Legend);

const EmbeddedStatisticsBar: React.FC<EmbeddedStatisticsBarProps> = ({ data }) => {
  return (
    <Bar
      data={data}
      options={EMBEDDED_STATISTICS_BAR_OPTIONS}
    />
  );
};
export default EmbeddedStatisticsBar;
