import CommonModal, { CommonModalProps } from '@/components/common/CommonModal/CommonModal';
import React from 'react';
import { FileDeleteModalButtonsWrapper, FileDeleteModalWrapper } from './FileInput.styled';
import { Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export interface FileDeleteModalProps extends CommonModalProps {
  onSuccess: () => void;
  fileName?: string;
  backendDeletionPending?: boolean;
}

const FileDeleteModal = (props: FileDeleteModalProps) => {
  return (
    <CommonModal {...props}>
      <FileDeleteModalWrapper>
        <Typography variant='h5'>Удаление файла</Typography>
        <Typography variant='body1'>
          Вы действительно хотите удалить файл{' '}
          <span style={{ fontWeight: 500 }}>{props?.fileName}</span>?
        </Typography>

        <FileDeleteModalButtonsWrapper>
          <LoadingButton
            variant='contained'
            onClick={props.onSuccess}
            loading={props.backendDeletionPending}
          >
            Да
          </LoadingButton>
          <Button
            variant='contained'
            onClick={props.onClose}
          >
            Нет
          </Button>
        </FileDeleteModalButtonsWrapper>
      </FileDeleteModalWrapper>
    </CommonModal>
  );
};

export default FileDeleteModal;
