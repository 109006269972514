import { Box, styled } from '@mui/material';
import Tab from '@mui/material/Tab';

export const StoreHouseComponentWrapper = styled(Box)({
  flexGrow: 1,
  backgroundColor: 'background.paper',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});
