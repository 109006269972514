import { createStore } from '@/utils/createStore';

interface ILastComment {
  lastComment: string;
  setLastComment: (comment: string) => void;
}

export const useLastComment = createStore<ILastComment>(
  (set, get) => ({
    lastComment: '',

    setLastComment(lastComment) {
      set({ lastComment });
    },
  }),
  'Last Comment'
);
