import { useEffect, useState } from 'react';
import { Placemark } from 'react-yandex-maps';
import { PnrTransformerStation } from '@/dto/taskmap/Dto';
import transformer from '@/images/icons/transformer.svg';

function getHint(obj: PnrTransformerStation): string {
  if (obj) {
    return `<div>ID Технического места: ${obj.functionalLocationId}</div>
        <div>Диспетчерское наименование:  ${obj.dispatcherName}</div>
        <div>Филиал: ${obj.networkCompanyBranch}</div>
        <div>Подразделение:  ${obj.networkCompanyUnit}</div>`;
  }
  return '';
}

export const PnrTrransformerStationPlacemark = (props: any) => {
  const [html, setHtml] = useState<string>();
  const [pnrTrransformerStation, setPnrTrransformerStation] = useState<PnrTransformerStation>();

  useEffect(() => {
    setPnrTrransformerStation(props.pnrTrransformerStation);
    setHtml(getHint(props.pnrTrransformerStation));
  }, [props.pnrTrransformerStation]);

  return (
    <>
      {pnrTrransformerStation && (
        <Placemark
          geometry={[pnrTrransformerStation.latitude, pnrTrransformerStation.longitude]}
          properties={{
            balloonPanelMaxMapArea: 0,
            balloonContentBody: html,
            hintContent: html,
          }}
          options={{
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: transformer,
            // Размеры метки.
            iconImageSize: [30, 42],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-5, -38],
          }}
        />
      )}
    </>
  );
};
