import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { TransformerAmperage } from '@/dto/TransformerDto';
import { TransTT, TTMount } from '@/components/Transformer/TransformerAmperage/TransTT';
import { TransPu } from '@/components/Transformer/TransformerAmperage/TransPU';
import { TransInfo } from '@/components/Transformer/TransformerAmperage/TransInfo';

interface ITransformerBlock {
  data: TransformerAmperage;
}
export const TransformerBlock: FC<ITransformerBlock> = ({ data }) => {
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <TransInfo data={data} />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <TransTT
          mountType={TTMount.UNMOUNT}
          transformer={data.unmount}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        {data?.mount ? (
          <TransTT
            mountType={TTMount.MOUNT}
            transformer={data.mount}
          />
        ) : (
          <TransTT
            mountType={TTMount.NOTMOUNT}
            transformer={data.mount}
          />
        )}
      </Grid>
      {data.exPuInfoResponse && (
        <Grid
          item
          xs={12}
        >
          <TransPu data={data.exPuInfoResponse} />
        </Grid>
      )}
    </Grid>
  );
};
