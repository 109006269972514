import { Grid } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { useCatalog } from '@/hooks/CatalogHook';
import { DEFAULT_PPO_MAP_FILTER, usePpoMap } from '../store';
import React, { useEffect, useMemo, useState } from 'react';
import { findAllByFilter } from '../service';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useActionLog } from '@/hooks/ActionLogHook';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { SingleLine } from '@/components/inputs/SingleLineNew';
import usePressEnterButton from '@/hooks/usePressEnterButton';
import { DownloadPhotoArchive } from '@/components/DownloadPhotoArchive';
import { usePillar } from '../resCard/pillarStore';

export const Filter = () => {
  const { setFilterParam, setGeoResonse, setGeoTransStation, clearFilter } = usePpoMap();
  const { clearInfoPillar } = usePillar();
  const filter = usePpoMap((state) => state.getFilter());
  const { setIsInProgress } = useScreenHoldHook();
  const { catchError } = useActionLog();
  const { taskGroupMap, regionalElectricNetwork, getCatalogMapWithErr } = useCatalog((state) => ({
    taskGroupMap: state.taskGroupMap,
    regionalElectricNetwork: state.regionalElectricNetwork,
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  useEffect(() => {
    if (filter) {
      setIsInProgress(true);
      findAllByFilter(filter)
        .then(({ data }) => {
          setGeoResonse(data);
          findAllByFilter(filter, true)
            .then(({ data }) => {
              setGeoResonse(data);
            })
            .catch((err) => catchError('Ошибка при поиске гео точек в ППО', err));
        })
        .catch((err) => catchError('Ошибка при поиске гео точек в ППО', err))
        .finally(() => setIsInProgress(false));
    }
  }, [filter]);

  const [res, setRes] = useState<SelectFilterOptType[]>(DEFAULT_PPO_MAP_FILTER.resIdList);
  const [resArg, setResArg] = useState<number>(DEFAULT_PPO_MAP_FILTER.resIdArg);
  const resOpt = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('regionalElectricNetwork')),
    [regionalElectricNetwork]
  );

  const [executor, setExecutor] = useState<SelectFilterOptType[]>(
    DEFAULT_PPO_MAP_FILTER.executorIdList
  );
  const [executorArg, setExecutorArg] = useState<number>(DEFAULT_PPO_MAP_FILTER.executorIdArg);
  const executorOpt = useMemo(() => taskMapToArray(taskGroupMap), [taskGroupMap]);

  const [contractor, setContractor] = useState<SelectFilterOptType[]>(
    DEFAULT_PPO_MAP_FILTER.contractorIdList
  );
  const [contractorArg, setContractorArg] = useState<number>(
    DEFAULT_PPO_MAP_FILTER.contractorIdArg
  );
  const contractorOpt = useMemo(() => taskMapToArray(taskGroupMap), [taskGroupMap]);

  const [dispatcherName, setDispatcherName] = useState<string>(
    DEFAULT_PPO_MAP_FILTER.dispatcherName
  );
  const [dispatcherNameArg, setDispatcherNameArg] = useState<number>(
    DEFAULT_PPO_MAP_FILTER.dispatcherNameArg
  );

  const onClean = () => {
    setRes(DEFAULT_PPO_MAP_FILTER.resIdList);
    setResArg(DEFAULT_PPO_MAP_FILTER.resIdArg);

    setExecutor(DEFAULT_PPO_MAP_FILTER.executorIdList);
    setExecutorArg(DEFAULT_PPO_MAP_FILTER.executorIdArg);

    setContractor(DEFAULT_PPO_MAP_FILTER.contractorIdList);
    setContractorArg(DEFAULT_PPO_MAP_FILTER.contractorIdArg);

    setDispatcherName(DEFAULT_PPO_MAP_FILTER.dispatcherName);
    setDispatcherNameArg(DEFAULT_PPO_MAP_FILTER.dispatcherNameArg);
  };

  const onSearchClick = () => {
    const req = {
      executorIdList: executor.map((elem) => elem.value),
      executorIdArg: executorArg,
      contractorIdList: contractor.map((elem) => elem.value),
      contractorIdArg: contractorArg,
      resIdList: res.map((elem) => elem.value),
      resIdArg: resArg,
      dispatcherName,
      dispatcherNameArg,
    };
    setGeoTransStation(undefined);
    clearInfoPillar();
    setFilterParam(req);
  };
  usePressEnterButton(onSearchClick);

  return (
    <BoxShadow>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
      >
        <Grid
          item
          xs={2}
          alignSelf='end'
        >
          <SingleLine
            onChange={setDispatcherName}
            label={'Номер ТП'}
            onArgChange={setDispatcherNameArg}
            value={dispatcherName}
            argValue={dispatcherNameArg}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <MultiSelect
            label={'Ответственный'}
            options={contractorOpt}
            value={contractor}
            argValue={contractorArg}
            onChange={(value: SelectFilterOptType[]) => setContractor(value)}
            onArgChange={(arg: number) => setContractorArg(arg)}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <MultiSelect
            label={'Исполнитель'}
            options={executorOpt}
            value={executor}
            argValue={executorArg}
            onChange={(value: SelectFilterOptType[]) => setExecutor(value)}
            onArgChange={(arg: number) => setExecutorArg(arg)}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <MultiSelect
            label={'РЭС'}
            options={resOpt}
            value={res}
            argValue={resArg}
            onChange={(value: SelectFilterOptType[]) => setRes(value)}
            onArgChange={(arg: number) => setResArg(arg)}
          />
        </Grid>
        <Grid
          item
          container
          xs={1}
          alignSelf='center'
        >
          <FilterButtons
            onSearchClick={() => {
              onSearchClick();
            }}
            onCloseClick={onClean}
          />
        </Grid>
        <Grid
          item
          xs={1}
        >
          <DownloadPhotoArchive />
        </Grid>
      </Grid>
    </BoxShadow>
  );
};
