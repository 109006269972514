import { AxiosResponse } from 'axios';
import { HOST } from './YodaRestService';
import { axiosGet, axiosPost } from './helpers';

export async function downloadVRU(
  reportTemplate: string,
  vruInputId: string
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/report/adpu-vru`;
  return axiosPost(url, { vruInputId, reportTemplate }, true);
}
export async function download(
  reportTemplate: string,
  objectId: string
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/report/adpu`;
  return axiosPost(url, { objectId, reportTemplate }, true);
}
export async function downloadTKO(
  reportTemplate: string,
  objectId: string
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/report/act-tko`;
  return axiosPost(url, { objectId, reportTemplate }, true);
}

export async function getAdpuArray(): Promise<AxiosResponse<string[], any>> {
  const url = HOST + `/rest/report/adpu/types`;
  return axiosGet(url);
}
export async function getTKOArray(): Promise<AxiosResponse<string[], any>> {
  const url = HOST + `/rest/report/templates?code=tkoActs`;
  return axiosGet(url);
}
