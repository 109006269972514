import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Switch } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import {
  CatalogsBrowseDragItem,
  CatalogsBrowseDragIconWrapper,
  CatalogsBrowseDragItemControllLabel,
  CatalogsBrowseDragItemWidthIcon,
  CatalogsBrowseDragItemWidthIconWrapper,
  CatalogsBrowseDragItemWidthIconButton,
} from './CatalogsBrowseDragOrder.styled';
import { useCol } from '../storeCol';
import { getNextIndex } from './utils';
import { CatalogTypes } from '@/components/sections/CatalogsBrowse/constants';

interface CatalogsBrowseDragOrderItemProps {
  column: GridColDef;
  index: number;
  type: CatalogTypes;
}

const WIDTH_TYPES = [
  { columnWidth: 140, iconWidth: 8 },
  { columnWidth: 200, iconWidth: 12 },
  { columnWidth: 600, iconWidth: 18 },
];

const CatalogsBrowseDragOrderItem = ({ column, index, type }: CatalogsBrowseDragOrderItemProps) => {
  const [width, setWidth] = useState(1);
  const { columns, setCurrentColumns } = useCol((state) => ({
    columns: state.columns,
    setCurrentColumns: state.setCurrentColumns,
  }));
  useEffect(() => {
    const widthIndex = WIDTH_TYPES.findIndex((width) => width.columnWidth === column.width);
    if (widthIndex > -1) {
      setWidth(widthIndex);
    }
  }, [column]);

  const handleChangeVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentColumns(
      columns[type].map((column) => {
        if (column.field === event.target.name) {
          return { ...column, hide: !event.target.checked };
        } else return column;
      }),
      type
    );
  };

  const handleChangeWidth = (fieldName: string) => {
    setWidth(getNextIndex(WIDTH_TYPES, width));
    setCurrentColumns(
      columns[type].map((column) => {
        if (column.field === fieldName) {
          return {
            ...column,
            width: WIDTH_TYPES[getNextIndex(WIDTH_TYPES, width)].columnWidth,
          };
        } else return column;
      }),
      type
    );
  };

  return (
    <Draggable
      draggableId={column.field}
      index={index}
    >
      {(provided) => (
        <CatalogsBrowseDragItem
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <CatalogsBrowseDragIconWrapper {...provided.dragHandleProps}>
            <DragHandleIcon />
          </CatalogsBrowseDragIconWrapper>

          <CatalogsBrowseDragItemWidthIconButton onClick={() => handleChangeWidth(column.field)}>
            <CatalogsBrowseDragItemWidthIconWrapper>
              <CatalogsBrowseDragItemWidthIcon columnWidth={WIDTH_TYPES[width].iconWidth} />
            </CatalogsBrowseDragItemWidthIconWrapper>
          </CatalogsBrowseDragItemWidthIconButton>

          <CatalogsBrowseDragItemControllLabel
            control={
              <Switch
                checked={!column.hide}
                onChange={handleChangeVisibility}
                name={column.field}
              />
            }
            label={column.headerName}
          />
        </CatalogsBrowseDragItem>
      )}
    </Draggable>
  );
};

export default CatalogsBrowseDragOrderItem;
