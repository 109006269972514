import {
  ArcElement,
  BubbleDataPoint,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  ScatterDataPoint,
  Title,
  Tooltip,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import React from 'react';
import { EMBEDDED_STATISTICS_PIE_OPTIONS } from './utils';

interface EmbeddedStatisticsPieProps {
  data: ChartData<'pie', (number | ScatterDataPoint | BubbleDataPoint | null)[]>;
}
ChartJS.register(CategoryScale, ArcElement, Title, Tooltip, Legend);

const EmbeddedStatisticsPie: React.FC<EmbeddedStatisticsPieProps> = ({ data }) => {
  return (
    <Pie
      data={data}
      options={EMBEDDED_STATISTICS_PIE_OPTIONS}
    />
  );
};
export default EmbeddedStatisticsPie;
