import { Button, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const TasksBrowseNewSelectAll = styled(Button)({
  order: 1,
  marginLeft: 10,
});

export const TasksBrowseNewCloseIcon = styled(CloseIcon)({
  order: 3,
  cursor: 'pointer',
});

export const TasksBrowseTotalCountWrapper = styled('div')({
  display: 'inline-block',
  padding: 4,
  minWidth: 28,
});
