import { FormControlLabel, IconButton, styled } from '@mui/material';

export const CatalogsBrowseDragIconWrapper = styled('div')({
  padding: 8,
});

export const CatalogsBrowseDragItem = styled('div')({
  display: 'flex',
  padding: 4,
});

export const CatalogsBrowseDragItemControllLabel = styled(FormControlLabel)({
  flexGrow: 1,
});

export const CatalogsBrowseDragItemWidthIconWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 20,
});

export const CatalogsBrowseDragItemWidthIconButton = styled(IconButton)({
  marginRight: 4,
});

interface CatalogsBrowseDragItemWidthIconProps {
  columnWidth?: number;
}
export const CatalogsBrowseDragItemWidthIcon = styled('div')<CatalogsBrowseDragItemWidthIconProps>(
  ({ columnWidth }) => ({
    width: columnWidth,
    height: 2,
    backgroundColor: 'gray',
    position: 'relative',
    transition: '0.3s ease',

    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 2,
      height: '10px',
      backgroundColor: 'gray',
      top: '50%',
      transform: 'translateY(-50%)',
    },

    '&::after': {
      right: 0,
    },
  })
);
