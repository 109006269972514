import { Button, Grid } from '@mui/material';
import React, { FC, useState } from 'react';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { getButtonDisabled, getButtonText } from './constants';
import { IChangeActStatus } from './types';
import { AccessController } from '../common/AccessController';
import { AccessEnum } from '../../app/auth/roles';

export const ChangeActStatus: FC<IChangeActStatus> = ({ taskType, actStatus, onSuccess }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleSuccess = () => {
    onSuccess();
    setIsOpen(false);
  };

  return (
    <AccessController access={[AccessEnum.OnlyManagerAndLeadAndTeamManager]}>
      <Grid>
        {taskType === 'SMR_ODPU' && (
          <>
            <Button
              variant='text'
              size='small'
              onClick={() => setIsOpen(true)}
              disabled={getButtonDisabled(actStatus)}
            >
              {getButtonText(actStatus)}
            </Button>

            <AccessPopup
              isOpen={isOpen}
              onSuccess={handleSuccess}
              onClose={handleClose}
              headerText={'После согласия, статус акта будут изменен'}
              titleText={'Продолжить?'}
              closeText={'Отмена'}
              successText={'Да'}
            />
          </>
        )}
      </Grid>
    </AccessController>
  );
};
