import { SupplyNetworkElement, SupplyNetworkElementItem, SupplyPUItem } from '@/dto/taskmap/Dto';
import { osDupErrorMap, OSExistErrorMap, PUExistErrorMap } from '../SupplyPUComponent/utils';
import * as XLSX from 'xlsx';
import { SupplyNetworkElementScanField } from '@/dto/SupplyBillServiceDto';
import { SelectedOs } from './SupplyOSComponent';

export const generateScanId = (os: SupplyNetworkElement, osList: SupplyNetworkElementItem[]) => {
  let maxId = 0;
  if (osList.length > 0) {
    maxId = Math.max(...osList.map((o) => o.tmpId));
  } else if (os.networkElementItems.length > 0) {
    maxId = Math.max(...os.networkElementItems.map((o) => o.tmpId));
  }
  return maxId + 1;
};

export const generateRowNum = (os: SupplyNetworkElement, osList: SupplyNetworkElementItem[]) => {
  let maxRowNum = 0;
  if (osList.length > 0) {
    maxRowNum = Math.max(...osList.map((o) => o.rowNum));
  } else if (os.networkElementItems.length > 0) {
    maxRowNum = Math.max(...os.networkElementItems.map((o) => o.rowNum));
  }
  return maxRowNum + 1;
};

export const isNumberExist = (
  data: SupplyNetworkElementItem,
  osList: SupplyNetworkElementItem[],
  typeScanField: SupplyNetworkElementScanField
) => {
  const mac = data?.mac;
  const imei = data?.imei;

  switch (typeScanField) {
    case SupplyNetworkElementScanField.MAC:
      return osList.filter((it) => it?.mac && it?.mac?.length > 0 && it.mac === mac);
    case SupplyNetworkElementScanField.IMEI:
      return osList.filter((it) => it?.imei && it?.imei?.length > 0 && it.imei === imei);
    default:
      return osList.filter(
        (it) =>
          it?.imei &&
          it?.imei?.length > 0 &&
          it.imei === imei &&
          it?.mac &&
          it?.mac?.length > 0 &&
          it.mac === mac
      );
  }
};

export const checkOsErrors = (
  osDupFieldError: osDupErrorMap,
  osExistsFieldError: PUExistErrorMap | OSExistErrorMap,
  selectedPuId?: number
) => {
  if (osExistsFieldError?.get(selectedPuId ?? -1)?.size) {
    return true;
  }
  const dupErrors = osDupFieldError?.get(selectedPuId ?? -1);
  if (dupErrors) {
    const filteredDupErrors = [...dupErrors].filter((el) => el[1] === true);
    if (filteredDupErrors.length) {
      return true;
    }
  }
  return false;
};

export const getOsFromErrorMaps = (
  osDupFieldError: osDupErrorMap,
  osExistsFieldError: OSExistErrorMap,
  osListItems: SupplyNetworkElementItem[],
  selectedOs: SelectedOs | undefined
) => {
  const selectedOsId = selectedOs?.tmpId;
  const typeScanField = selectedOs?.typeScanField;
  if (!selectedOsId) return;
  const errorIds: number[] = [];
  const osToRemoveIds: number[] = [];
  const resultDupMap: Map<string, SupplyNetworkElementItem[]> = new Map();
  const resultDupStrings = [];
  const resultList = osListItems.filter(
    (it) => (it.mac && it.mac.length > 0) || (it.imei && it.imei.length > 0)
  );

  const selectedOsDupErrors = osDupFieldError.get(selectedOsId);
  if (selectedOsDupErrors) {
    const osDupFieldErrorIds = [...selectedOsDupErrors]
      ?.filter((osError) => osError[1] === true)
      ?.map((osError) => osError[0]);

    osDupFieldErrorIds.forEach((osDupFieldErrorId) => {
      const errorOs = resultList?.find((os) => os?.tmpId === osDupFieldErrorId);

      if (errorOs) {
        const key = errorOs?.mac ?? errorOs?.imei;
        if (key) {
          const dup = resultDupMap.get(key);
          if (dup) {
            resultDupMap.set(key, [...dup, errorOs]);
          } else {
            resultDupMap.set(key, [errorOs]);
          }
          errorIds.push(errorOs.tmpId);
        }
      }
    });

    for (const [key, value] of resultDupMap) {
      resultDupStrings.push(
        `IMEI/МАС "${key}" продублирован в строках: ${value.map((os) => os.rowNum).join(',')}`
      );
      osToRemoveIds.push(...value.map((os) => os.tmpId).slice(1));
    }
  }

  const resultExistStrings: string[] = [];
  const selectedPuExistErrors = osExistsFieldError.get(selectedOsId);
  if (selectedPuExistErrors) {
    const osExistFieldErrorIds = [...selectedPuExistErrors];

    osExistFieldErrorIds.forEach((osExistFieldErrorId) => {
      const existPu = osExistFieldErrorId[1];
      resultExistStrings.push(
        `IMEI/МАС "${existPu?.item?.mac || existPu?.item?.imei}" продублирован в накладной: ${
          existPu.billName
        }`
      );
      errorIds.push(existPu.item.tmpId);
      osToRemoveIds.push(existPu.item.tmpId);
    });
  }

  const uniqueErrorIds = [...new Set(errorIds)];

  return {
    dup: resultDupStrings,
    exist: resultExistStrings,
    allIds: uniqueErrorIds,
    removeIds: osToRemoveIds,
  };
};

export const generateOsExcelFile = (
  data: SupplyNetworkElementItem[],
  meterName?: string,
  withMac?: boolean,
  m15FormNumber?: string,
  billDate?: string,
  contractor?: string
) => {
  const columns = [
    { field: 'meterTypeName', headerName: 'Тип прибора' },
    { field: 'imei', headerName: 'IMEI' },
    ...(withMac ? [{ field: 'mac', headerName: 'MAC' }] : []),
    { field: 'contractor', headerName: 'Кому выдано' },
    { field: 'numberM15', headerName: 'М15' },
    { field: 'billDate', headerName: 'Дата выдачи' },
  ];

  const dataForExcel: (string | number | null)[][] = [columns.map((column) => column.headerName)];

  data.forEach((row) => {
    dataForExcel.push([
      meterName ?? '',
      row.imei,
      ...(withMac ? [row.mac] : []),
      contractor ?? '',
      m15FormNumber ?? '',
      billDate ?? '',
    ]);
  });

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(dataForExcel);
  worksheet['!cols'] = columns.map((item) => {
    return { wpx: 250 };
  });

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Лист1');
  XLSX.writeFile(workbook, `${meterName ?? 'Сетевое оборудование'}.xlsx`);
};
