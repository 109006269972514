export const LOGIN_VALIDATION = {
  required: 'Введите логин',
  minLength: {
    value: 3,
    message: 'Минимальная длина логина 3 символа',
  },
};

export const PASSWORD_VALIDATION = {
  required: 'Введите пароль',
  minLength: {
    value: 3,
    message: 'Минимальная длина пароля 3 символа',
  },
};
