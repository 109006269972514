import { useEffect } from 'react';

/**
 * Настраивает title документа на указанный и сбрасывает его на 'Yoda' при размонтировании компонента
 *
 * @param {string} title - Заголовок, который будет установлен в качестве заголовка документа.
 * @example useMetaTitle('Жалобы');
 */
export const useMetaTitle = (title: string) => {
  useEffect(() => {
    document.title = title;

    return () => {
      document.title = DEFAULT_TITLE;
    };
  }, [title]);
};

const DEFAULT_TITLE = 'Yoda';
