import { styled } from '@mui/material';

export const SmallExtraTableWrapper = styled('div')({
  height: '150px',
  width: '75%',
  margin: 'auto',
  paddingBottom: '30px',
  display: 'flex',
  flexDirection: 'row-reverse',
  position: 'relative',
});

export const SmallExtraTableSubHeader = styled('p')({
  fontSize: '24px',
  textAlign: 'center',
});
