import { Paper, styled } from '@mui/material';

export const StatisticReportByContractPerDayFiltersWrapper = styled('div')({
  padding: '0px 0px 0px 5vw',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  flexWrap: 'wrap',
  gap: 6,
  marginTop: 16,
});

export const StatisticReportByContractPerDayWrapper = styled(Paper)({
  paddingBottom: 20,
});
