import { Box, Grid } from '@mui/material';
import React, { FC } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

interface IRegistryBrowseAdd {
  onClick: () => void;
}

export const RegistryBrowseAdd: FC<IRegistryBrowseAdd> = ({ onClick }) => {
  return (
    <Grid item>
      <Box
        onClick={onClick}
        sx={{
          borderRadius: '10px',
          border: '1px solid #EFEFEF',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          background: 'rgba(245, 245, 245, 1)',
          padding: '14px 28px',
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'space-around',
        }}
      >
        <AddRoundedIcon />
      </Box>
    </Grid>
  );
};
