import React, { useCallback } from 'react';
import {
  GetASUMBTaskStatisticsCheckStatusByFilter,
  GetAttributesRp,
  GetMeterChangeForRevisionByFilter,
  GetSMRStatisticsTableByFilter,
  GetStatisticByInstallationLocation,
  GetStatisticByInstallPu,
  GetStatisticDistribution,
  GetStatisticReportByContract,
  GetStatisticUrgentAndSuperUrgentTasks,
} from '@/services/StatisticsService';

import CheckStatistics from './CheckStatistics/CheckStatistics';
import {
  filterStatisticByInstallPu,
  FilterStatisticReportByContractPerDay,
  FilterStatisticUrgentAndSuperUrgent,
  MeterChangeForRevisionFilterParam,
  SMRStatisticsFilter,
  StatisticByInstallPuDto,
  StatisticDistributionDto,
  StatisticReportByContractPerDayDto,
  StatisticUrgentAndSuperUrgentTasksDto,
  TaskFilterParam,
} from '@/dto/taskmap/Dto';
import MeterChangeForRevision from './MeterChangeForRevision/MeterChangeForRevision';
import SMRStatistics from './SMRStatistics/SMRStatistics';
import { useActionLog } from '@/hooks/ActionLogHook';
import {
  AsumbTaskStatisticsForCheckInitData,
  MeterChangeForRevisionStatisticsInitData,
} from '../DashboardInitConst';
import { StatisticByInstallationLocation } from './StatisticByInstallationLocation/StatisticByInstallationLocation';
import { sortByAlthabit } from '@/utils/heplers';
import { StatisticUrgentAndSuperUrgentTasks } from '../StatisticUrgentAndSuperUrgentTasks/StatisticUrgentAndSuperUrgentTasks';
import { StatisticReportByContractPerDay } from '../StatisticReportByContractPerDay/StatisticReportByContractPerDay';
import { StatisticByInstallPu } from '../StatisticByInstallPu/StatisticByInstallPu';
import { StatisticDistributionOfInstallationByConctror } from '../StatisticDistributionOfInstallationByConctror/StatisticDistributionOfInstallationByConctror';
import { FilterStatisticDistribution } from '../StatisticDistributionOfInstallationByConctror/utils';
import MiniatureSelect from '@/components/blocks/MiniatureSelect/MiniatureSelect';
import StatisticWithDateForRP from '../StatisticWithDateForRP';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { StatisticRPDto } from './types';
import { StatisticByInstallationLocationData } from './StatisticByInstallationLocation/types';
import { SMRStatisticsTableItem } from './SMRStatistics/types';
import { AsumbTaskStatisticsForCheckChartType } from './CheckStatistics/types';
import { MeterChangeForRevisionData } from './MeterChangeForRevision/types';

const RequestStatistic = () => {
  const { catchError } = useActionLog();
  const timeFrom: string = new Date(Date.now() - 86400000 * 10).toISOString().split('T')[0];
  const timeTo: string = new Date().toISOString().split('T')[0];

  const [loadCheck, setLoadCheck] = React.useState(false);
  const [loadMeterChangeForImprovement, setLoadMeterChangeForRevision] = React.useState(false);
  const [loadStatisticByInstallationLocation, setLoadStatisticByInstallationLocation] =
    React.useState(false);
  const [loadStatisticReportByContractPerDay, setLoadStatisticReportByContractPerDay] =
    React.useState(false);
  const [loadStatisticUrgentAndSuperUrgentTasks, setLoadStatisticUrgentAndSuperUrgentTasks] =
    React.useState(false);
  const [loadStatisticDistributionData, setLoadStatisticDistributionData] = React.useState(false);

  const [statisticUrgentAndSuperUrgentTasksData, setStatisticUrgentAndSuperUrgentTasksData] =
    React.useState<StatisticUrgentAndSuperUrgentTasksDto[]>([]);
  const [statisticReportByContractPerDayTaskDto, setStatisticReportByContractPerDayTaskDto] =
    React.useState<StatisticReportByContractPerDayDto[]>([]);
  const [statisticByInstallationLocationData, setStatisticByInstallationLocationData] =
    React.useState<StatisticByInstallationLocationData[]>([]);
  React.useState<StatisticReportByContractPerDayDto[]>([]);
  const [statisticByInstallPuData, setStatisticByInstallPuData] = React.useState<
    StatisticByInstallPuDto[]
  >([]);
  const [statisticDistributionData, setStatisticDistributionData] = React.useState<
    StatisticDistributionDto[]
  >([]);

  const [dataCheckChart, setDataCheckChart] = React.useState<AsumbTaskStatisticsForCheckChartType>(
    AsumbTaskStatisticsForCheckInitData
  );

  const [meterChangeForImprovementStatisticsData, setMeterChangeForRevisionStatisticsData] =
    React.useState<MeterChangeForRevisionData>(MeterChangeForRevisionStatisticsInitData);
  const [parentContractorName, setParentContractorName] = React.useState<string>('ПОДРЯДЧИКИ');

  const [smrStatisticsLoad, setSmrStatisticsLoad] = React.useState(false);
  const [statisticByInstallPuLoad, setStatisticByInstallPuLoad] = React.useState(false);

  const [smrStatisticsTableData, setSmrStatisticsTableData] =
    React.useState<SMRStatisticsTableItem[]>();
  const [statisticWithDateForRPData, setStatisticWithDateForRPData] = React.useState<
    StatisticRPDto[]
  >([]);

  const updateCheckChartWithFilter = (filter: TaskFilterParam) => {
    setLoadCheck(true);
    GetASUMBTaskStatisticsCheckStatusByFilter(filter)
      .then((value) => setDataCheckChart(value.data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', error))
      .finally(() => setLoadCheck(false));
  };

  const updateMeterChangeForRevision = useCallback(
    (
      standardFilter: TaskFilterParam,
      dateFrom: string = timeFrom,
      dateTo: string = timeTo,
      parentContractorName: string
    ) => {
      setLoadMeterChangeForRevision(true);
      const meterChangeStatisticsFilter: MeterChangeForRevisionFilterParam = {
        filter: standardFilter,
        dateFrom,
        dateTo,
        groupName: parentContractorName,
      };
      GetMeterChangeForRevisionByFilter(meterChangeStatisticsFilter)
        .then((value) => {
          setLoadMeterChangeForRevision(false);
          if (value.data?.all?.labels?.length !== 0) {
            setMeterChangeForRevisionStatisticsData(value.data);
          }
        })
        .catch((error) =>
          catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', error)
        )
        .finally(() => setLoadMeterChangeForRevision(false));
    },
    []
  );

  const updateSMRStatisticsTable = (
    standardFilter: TaskFilterParam,
    date: Date,
    isNow: boolean
  ) => {
    setSmrStatisticsLoad(true);
    const smrStatisticsFilter: SMRStatisticsFilter = {
      taskFilterParam: standardFilter,
      date: date.toISOString().split('T')[0],
      isNow: isNow,
    };
    GetSMRStatisticsTableByFilter(smrStatisticsFilter)
      .then(({ data }) => {
        const sortedData = sortByAlthabit(data, 'Итого');
        setSmrStatisticsTableData(sortedData);
      })
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', error))
      .finally(() => setSmrStatisticsLoad(false));
  };

  const updateStatisticByInstallationLocation = (filter: any) => {
    setLoadStatisticByInstallationLocation(true);
    GetStatisticByInstallationLocation(filter)
      .then(({ data }) => setStatisticByInstallationLocationData(data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', error))
      .finally(() => setLoadStatisticByInstallationLocation(false));
  };
  const updateStatisticUrgentAndSuperUrgentTasks = (
    filter: FilterStatisticUrgentAndSuperUrgent
  ) => {
    setLoadStatisticUrgentAndSuperUrgentTasks(true);
    GetStatisticUrgentAndSuperUrgentTasks(filter)
      .then(({ data }) => setStatisticUrgentAndSuperUrgentTasksData(data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', error))
      .finally(() => setLoadStatisticUrgentAndSuperUrgentTasks(false));
  };

  const updateStatisticReportByContract = (filter: FilterStatisticReportByContractPerDay) => {
    setLoadStatisticReportByContractPerDay(true);
    GetStatisticReportByContract(filter)
      .then(({ data }) => setStatisticReportByContractPerDayTaskDto(data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', error))
      .finally(() => setLoadStatisticReportByContractPerDay(false));
  };

  const updateStatisticByInstallPu = (filter: filterStatisticByInstallPu) => {
    setStatisticByInstallPuLoad(true);
    GetStatisticByInstallPu(filter)
      .then(({ data }) => setStatisticByInstallPuData(data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', error))
      .finally(() => setStatisticByInstallPuLoad(false));
  };

  const updateStatisticDistribution = (filrer: FilterStatisticDistribution) => {
    setLoadStatisticDistributionData(true);
    GetStatisticDistribution(filrer)
      .then(({ data }) => setStatisticDistributionData(data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', error))
      .finally(() => setLoadStatisticDistributionData(false));
  };
  const updateStatisticWithDateForRP = () => {
    GetAttributesRp()
      .then(({ data }) => setStatisticWithDateForRPData(data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', {}));
  };
  const hasAccess = useAccess({
    access: [AccessEnum.Statistic, AccessEnum.TeamManagerVoormanAndExecutor],
    stopRedirect: true,
  });

  React.useEffect(() => {
    updateCheckChartWithFilter({});
    hasAccess && updateMeterChangeForRevision({}, timeFrom, timeTo, parentContractorName);
    // updateSMRStatisticsTable({}, new Date(Date.now()), false);
    updateStatisticWithDateForRP();
  }, []);

  const statistics = [
    {
      hasAccess: useAccess({
        access: [AccessEnum.Statistic],
        stopRedirect: true,
      }),
      title: 'Статистика задач в статусе "На проверке"',
      component: (
        <CheckStatistics
          data={dataCheckChart}
          header={'Статистика задач в статусе "На проверке"'}
          load={loadCheck}
          updateFunction={updateCheckChartWithFilter}
        />
      ),
    },
    {
      hasAccess: useAccess({
        access: [AccessEnum.Statistic],
        stopRedirect: true,
      }),
      title: 'Статистика ошибок в заменах',
      component: (
        <MeterChangeForRevision
          header={'Статистика ошибок в заменах'}
          load={loadMeterChangeForImprovement}
          data={meterChangeForImprovementStatisticsData}
          updateFunction={updateMeterChangeForRevision}
          setParentContractorName={setParentContractorName}
          groupName={parentContractorName}
        />
      ),
    },
    {
      hasAccess: useAccess({
        access: [
          AccessEnum.Statistic,
          AccessEnum.TeamManagerVoormanAndExecutor,
          AccessEnum.SupplyInfoStatistic,
        ],
        stopRedirect: true,
      }),
      title: 'Статистика по фазности в динамике',
      component: (
        <SMRStatistics
          load={smrStatisticsLoad}
          header={'Статистика по фазности в динамике'}
          data={smrStatisticsTableData}
          updateFunction={updateSMRStatisticsTable}
        />
      ),
    },
    {
      hasAccess: useAccess({
        access: [
          AccessEnum.Statistic,
          AccessEnum.TeamManagerVoormanAndExecutor,
          AccessEnum.SupplyInfoStatistic,
        ],
        stopRedirect: true,
      }),
      title: 'Статистика по местам установки',
      component: (
        <StatisticByInstallationLocation
          header={'Статистика по местам установки'}
          load={loadStatisticByInstallationLocation}
          data={statisticByInstallationLocationData}
          updateFunction={updateStatisticByInstallationLocation}
        />
      ),
    },
    {
      hasAccess: useAccess({
        access: [AccessEnum.Statistic],
        stopRedirect: true,
      }),
      title: 'Заявки от абонентов в статусах Срочные и Очень срочные',
      component: (
        <StatisticUrgentAndSuperUrgentTasks
          header={'Заявки от абонентов в статусах Срочные и Очень срочные'}
          load={loadStatisticUrgentAndSuperUrgentTasks}
          data={statisticUrgentAndSuperUrgentTasksData}
          updateFunction={updateStatisticUrgentAndSuperUrgentTasks}
        />
      ),
    },
    {
      hasAccess: useAccess({
        access: [AccessEnum.Statistic],
        stopRedirect: true,
      }),
      title: 'Выполнение по подрядчикам по дням',
      component: (
        <StatisticReportByContractPerDay
          header={'Выполнение по подрядчикам по дням'}
          load={loadStatisticReportByContractPerDay}
          data={statisticReportByContractPerDayTaskDto}
          updateFunction={updateStatisticReportByContract}
        />
      ),
    },
    {
      hasAccess: useAccess({
        access: [AccessEnum.Statistic],
        stopRedirect: true,
      }),
      title: 'Места установки ПУ',
      component: (
        <StatisticByInstallPu
          header={'Места установки ПУ'}
          load={statisticByInstallPuLoad}
          data={statisticByInstallPuData}
          updateFunction={updateStatisticByInstallPu}
        />
      ),
    },
    {
      hasAccess: useAccess({
        access: [AccessEnum.Statistic],
        stopRedirect: true,
      }),
      title: 'Помесячное распределение установок по подрядчикам',
      component: (
        <StatisticDistributionOfInstallationByConctror
          header={'Помесячное распределение установок по подрядчикам'}
          load={loadStatisticDistributionData}
          data={statisticDistributionData}
          updateFunction={updateStatisticDistribution}
        />
      ),
    },
    {
      hasAccess: useAccess({
        access: [AccessEnum.Statistic],
        stopRedirect: true,
      }),
      title: 'Отчётность по заявкам с датой для РП (CSV)',
      component: (
        <StatisticWithDateForRP
          header={'Отчётность по заявкам с датой для РП (CSV)'}
          data={statisticWithDateForRPData}
        />
      ),
    },
  ];
  const hasAccessStatistics = statistics.filter((s) => s.hasAccess);

  return <MiniatureSelect components={hasAccessStatistics}></MiniatureSelect>;
};
export default RequestStatistic;
