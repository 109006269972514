export const SMR_STATISTICS_TABLE_COLUMNS = [
  'Ответственный',
  '1ф',
  '3ф',
  'ПУ не найден',
  'Используется',
  'Пломба не найдена',
  'Не установлен',
  'Некорректные показания',
  'Некорректные 1ф',
  'Некорректные 3ф',
  'Некорректные неизвестная фаза',
  'Всего 1ф',
  'Всего 3ф',
  'Всего неизвестная фаза',
  'Динамика 1ф',
  'Динамика 3ф',
  'Динамика неизвестная фаза',
];

export const getHeaderColor = (index: number) => {
  if (index > 13) return 'rgb(201,201,201)';
  if (index > 10) return 'rgb(146,208,80)';
  if (index > 7) return 'rgb(255,255,0)';
  if (index > 2) return 'rgb(252,228,214)';
  if (index > 0) return 'rgb(255,242,204)';
  return 'rgb(255,255,255)';
};
