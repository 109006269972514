import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Accesses } from '@/app/auth/accesses';
import { AccessEnum, RolesEnum } from '@/app/auth/roles';
import { useRole } from './useRole';

interface IParams {
  access: AccessEnum[];
  stopRedirect?: boolean;
}

export const useAccess = ({ access, stopRedirect }: IParams) => {
  const navigate = useNavigate();

  const { role } = useRole();

  const hasAccess = useMemo(() => {
    if (access && role) {
      return role?.some(
        (item: RolesEnum) =>
          !!Accesses[item] &&
          Accesses[item].find(
            (accessItem) => !!access.find((innerAcces) => innerAcces === accessItem)
          )
      );
    }
  }, [access, role]);

  if (role && !hasAccess && !stopRedirect) {
    navigate('/');
  }

  return !!hasAccess;
};

interface DisableAccessParams {
  disabledRoles: RolesEnum[];
  refreshCondition?: string;
}
interface AllowAccessParams {
  allowRoles: RolesEnum[];
}

/**
 * Возвращает логическое значение, указывающее, имеет ли пользователь доступ, на основе предоставленных запрещенных ролей.
 *
 * @param {RolesEnum[]} params.disabledRoles - Массив ролей, доступ которым запрещён
 * @param {string} [params.refreshCondition] - Условие для пересчета состояния доступа (применимо в динамически приходящих пропсах)
 */
export const useDisabledAccess = ({ disabledRoles, refreshCondition }: DisableAccessParams) => {
  const { role } = useRole();
  const [disableAccess, setDisableAccess] = useState(true);
  const memoizedRoles = useMemo(() => disabledRoles, [refreshCondition]);

  useEffect(() => {
    if (role) {
      setDisableAccess(role.some((item: RolesEnum) => memoizedRoles.includes(item)));
    }
  }, [role, memoizedRoles]);

  return disableAccess;
};

export const useAllowedAccessByRole = ({ allowRoles }: AllowAccessParams) => {
  const { role } = useRole();
  const [allow, setAllowAccess] = useState(false);
  const memoizedRoles = useMemo(() => allowRoles, []);

  useEffect(() => {
    if (!role) return;

    if (role.includes(RolesEnum.Administrators)) {
      setAllowAccess(true);
      return;
    }

    setAllowAccess(role.some((item: RolesEnum) => memoizedRoles.includes(item)));
  }, [role, memoizedRoles]);

  return useMemo(() => allow, [allow]);
};

export const useDisabledAll = (): boolean => {
  const disabled = useAccess({
    access: [AccessEnum.DisabledAll],
    stopRedirect: true,
  });

  return useMemo(() => disabled, [disabled]);
};

/**
 * Возвращает логическое значение, указывающее, совпадает ли хотя бы один "комплект" ролей с ролями пользователя,
 * или пользователь обладает ролью "Administrators".
 *
 * @param {RolesEnum[][]} roleSets - Массив комплектов ролей. Каждый комплект — массив обязательных ролей.
 * @returns boolean - true, если у пользователя есть все роли из хотя бы 1 комплекта или роль "Administrators".
 * @example
    const createOdpu = useHasAnyRoleSet([[RolesEnum.ComplaintCustomer, RolesEnum.ComplaintOdpu]]);
 */
export const useHasAnyRoleSet = (roleSets: RolesEnum[][]): boolean => {
  const { role } = useRole();

  const memoizedRoleSets = useMemo(() => roleSets, []);

  return useMemo(() => {
    if (!role) {
      return false;
    }

    // Если пользователь имеет роль "Administrators", возвращаем true
    if (role.includes(RolesEnum.Administrators)) {
      return true;
    }

    // Проверяем, совпадает ли хотя бы один комплект ролей
    return memoizedRoleSets.some((roleSet) =>
      roleSet.every((requiredRole) => role.includes(requiredRole))
    );
  }, [role, memoizedRoleSets]);
};
