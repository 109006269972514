import { useState } from 'react';
import DetailedInfoIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { DetailedInfoItem } from './DetailedInfoItem';
import { color } from '@/styles/mixins';
import { Rnd } from 'react-rnd';
import { Portal } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export interface DetailedInfoButtonProps {
  info: DetailedInfoItem[];
  disable?: boolean;
}

export const DetailedInfoButton = ({ info, disable }: DetailedInfoButtonProps) => {
  const [open, setOpen] = useState(false);
  const defaultPosition = {
    x: 10,
    y: 100,
    width: 'auto',
    height: 'auto',
  };

  return (
    <>
      {open ? (
        <Portal>
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 1000,
              backgroundColor: 'transparent',
              overflowY: 'auto',
              height: '100vh',
              width: '100vw',
            }}
            onClick={() => setOpen(false)}
          >
            <Rnd
              default={defaultPosition}
              minWidth={1}
              minHeight={1}
              bounds='window'
            >
              <Box
                sx={{
                  backgroundColor: '#FFF',
                  border: '1px solid gray',
                  borderRadius: '5px',
                  position: 'relative',
                  marginRight: 2,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {info?.map((infoElement, index) => (
                  <DetailedInfoItem
                    key={infoElement.title}
                    index={index}
                    {...infoElement}
                  />
                ))}
                <IconButton
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: -25,
                    right: -15,
                    color: color('trueBlack'),
                    zIndex: 100000,
                  }}
                  onTouchStart={() => setOpen(false)}
                  onClick={(e) => setOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Rnd>
          </Box>
        </Portal>
      ) : (
        <>
          <DetailedInfoIcon
            onClick={() => {
              if (!disable) setOpen(true);
            }}
            fontSize='large'
            style={{ cursor: disable ? 'not-allowed' : 'pointer', color: color('muiBlue') }}
          />
        </>
      )}
    </>
  );
};
