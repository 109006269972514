import moment from 'moment';
import { AggregatedData, IRPData, ISettings, Result } from './types';
import React from 'react';
import { Divider } from '@mui/material';

export const TOTAL_ROW_NAME = 'Итого';
const UNIX_TIME_START = '01.01.1970';

export const SAVE_FILTER_KEY = 'DynamicFilterInputs';

export function aggregateDynamicData(
  data: IRPData[],
  settings: ISettings,
  choosenCells: any
): Result {
  const { rowFields, columnFields, valueFields } = settings;
  const result: AggregatedData = {};
  let columnHeaders = new Set<string>();

  // Подготавливаем структуру данных для агрегации
  data.forEach((item) => {
    // Генерируем ключи для строк и колонок
    const rowKey = rowFields.map((field) => item[field.value]).join('|');
    const columnKey = columnFields.map((field) => item[field.value]).join('|');
    // Добавляем комбинацию колонок в набор для создания заголовков таблицы
    columnHeaders.add(columnKey);

    // Инициализируем агрегацию по строкам и колонкам
    if (!result[rowKey]) {
      result[rowKey] = {};
    }
    if (!result[rowKey][columnKey]) {
      result[rowKey][columnKey] = {};
      valueFields.forEach((valueField) => {
        result[rowKey][columnKey][valueField] = 0;
      });
    }

    // Агрегируем значения
    valueFields.forEach((valueField) => {
      result[rowKey][columnKey][valueField] += Number(item[valueField]) || 0;
    });
  });

  const totalObject: any = {};

  // Вычисляем сумму по всем ключам объекта и добавляем её в "итого"
  for (const rowKey in result) {
    for (const columnKey in result[rowKey]) {
      for (const valueField of valueFields) {
        if (!totalObject[columnKey]) {
          totalObject[columnKey] = {};
        }
        if (!totalObject[columnKey][valueField]) {
          totalObject[columnKey][valueField] = { [valueField]: 0 };
        }
        totalObject[columnKey][valueField][valueField] += result[rowKey][columnKey][valueField];
      }
    }
  }

  // Вычисляем общую сумму по всем ключам объекта и добавляем её в "итого"
  for (const columnKey in totalObject) {
    for (const valueField in totalObject[columnKey]) {
      totalObject[columnKey][valueField] = Object.values(totalObject[columnKey][valueField]).reduce(
        (acc: number, val: any) => acc + val,
        0
      );
    }
  }
  if (Object.keys(totalObject).length) {
    result[TOTAL_ROW_NAME] = totalObject;
  }
  // Вычисляем общую сумму по всем ключам объекта и добавляем её в ячейку итого у каждего объекта
  for (const rowKey in result) {
    let totalColSum = 0;
    for (const columnKey in result[rowKey]) {
      if (columnKey === TOTAL_ROW_NAME || typeof result[rowKey][columnKey] !== 'object') {
        continue;
      }
      for (const status in result[rowKey][columnKey]) {
        totalColSum += result[rowKey][columnKey][status] || 0;
      }
    }
    //  инициализируем totalCol с суммой всех значений
    result[rowKey][TOTAL_ROW_NAME] = { summary_count: totalColSum };
  }

  const parents = [
    ...new Set(
      Object.keys(result)
        .map((el) => el.split('|')[0])
        .filter((el) => el !== TOTAL_ROW_NAME)
    ),
  ];

  for (const parent of parents) {
    const parentTotals: { [key: string]: number } = {};
    for (const key in result) {
      if (key.startsWith(parent)) {
        for (const status in result[key]) {
          if (!parentTotals[status]) {
            parentTotals[status] = 0;
          }
          parentTotals[status] += result[key][status].summary_count;
        }
      }
    }
    result[parent] = {};
    for (const status in parentTotals) {
      result[parent][status] = { summary_count: parentTotals[status] };
    }
  }

  columnHeaders = new Set(Array.from(columnHeaders)); // Преобразуем Set в массив для упорядочивания

  const colWithTotla = Object.keys(result).length
    ? Array.from([TOTAL_ROW_NAME, ...columnHeaders])
    : Array.from(columnHeaders);
  const { rows, columns } = makeColumns(result, colWithTotla, settings, choosenCells);

  return {
    result,
    columnHeaders: colWithTotla,
    rows,
    columns,
    parents,
  };
}

export const DATA_LIMIT = 100000;
export const makeColumns = (
  result: AggregatedData,
  columnHeaders: string[],
  settings: ISettings,
  choosenCells: any
) => {
  const rows = Object.entries(result).map(([key, value], index) => {
    if (key.split('|').length > 1) {
      return {
        id: key.split('|'),
        path: returnValidFormat(key, true),
        ...value,
      };
    }
    return {
      id: key.split('|'),
      [settings.rowFields[0]?.value]: returnValidFormat(key),
      ...value,
      path: key.split('|'),
    };
  });
  const columns: any = [...columnHeaders].sort(sortColumns).map((c) => {
    return {
      field: c,
      width: 130,
      height: 400,
      sortable: c !== TOTAL_ROW_NAME,
      minHeight: 300,
      cellClassName: (params: any) => {
        const second = params.id.length > 1 ? params?.id.join('|') : params?.id[0];
        if (choosenCells?.cellSum) {
          if (choosenCells?.cellSum[`${params?.field}|${second}`]) {
            return 'highlightChoosenCell';
          }
        }
        if (params.field === TOTAL_ROW_NAME) {
          return 'highlightCell';
        }
      },
      renderHeader: (params: any) => {
        return (
          <div>
            {params.field &&
              params.field?.split('|').map((el: any, index: number) => (
                <React.Fragment key={index}>
                  {params.field?.split('|').length > 1 && index !== 0 && <Divider />}
                  {checkIfItIsDate(el)}
                </React.Fragment>
              ))}
          </div>
        );
      },
      renderCell: (param: any) => {
        const formattedNumber = new Intl.NumberFormat('ru-RU').format(
          param?.value?.summary_count || 0
        );
        return <div>{formattedNumber}</div>;
      },
    };
  });
  if (settings.rowFields.length < 2) {
    columns.unshift({
      // sortable: false,
      field: settings.rowFields[0]?.value,
      headerName: settings?.rowFields[0]?.label,
    });
  }

  const sortedRowsWithTotalRowFirst = [...rows].sort(customSort);

  return {
    columns,
    rows: sortedRowsWithTotalRowFirst,
  };
};

export function checkIfItIsDate(value: string, addMs = false) {
  const timestamp = parseInt(value, 10);
  // добавлена еще проверка что это действитно число
  const isNum = Number.isInteger(+value);
  //  является ли значение временной меткой UNIX
  const isValidUnixTimestamp = moment.unix(timestamp).isValid();
  //  значение временной метки не равно 1 января 1970
  const isNot1970 = moment(timestamp).format('DD.MM.YYYY') !== UNIX_TIME_START;
  if (isValidUnixTimestamp && isNum && isNot1970) {
    return `${moment(timestamp).format(`DD.MM.YYYY ${addMs ? 'HH:mm:ss.SSS' : ''}`)}`;
  } else {
    return value;
  }
}

export const makeTreeStructure = (settings: ISettings, parents: string[]) => {
  if (settings.rowFields.length > 1) {
    return {
      treeData: true,
      getTreeDataPath: (row: any) => {
        if (row?.path[0] === TOTAL_ROW_NAME || parents.some((el) => el === row.path[0])) {
          return row?.path;
        }
        return row?.path?.split('|');
      },
    };
  } else {
    return {
      treeData: false,
      getTreeDataPath: (row: any) => {
        return row?.path;
      },
    };
  }
};

export const converToNumber = (type: string) => {
  return type === 'DOUBLE' || type === 'LONG';
};

function customSort(a: any, b: any) {
  const idA = a.id;
  const idB = b.id;

  const hasTotalA = idA.includes(TOTAL_ROW_NAME);
  const hasTotalB = idB.includes(TOTAL_ROW_NAME);

  if (hasTotalA && !hasTotalB) {
    return -1;
  } else if (!hasTotalA && hasTotalB) {
    return 1;
  }

  const idStrA = idA.join(',');
  const idStrB = idB.join(',');
  return idStrA.localeCompare(idStrB);
}

function returnValidFormat(string: string, addMs = false) {
  return string
    .split('|')
    .map((el) => checkIfItIsDate(el, addMs))
    .join('|');
}

export function highlightTotal(params: any) {
  return params.id[0] === TOTAL_ROW_NAME ? 'highlight' : '';
}
const cellSum: Record<string, any> = {};

export function canculateCellSum(data: any, cb: any) {
  const second = data.id.length > 1 ? data?.id.join('|') : data?.id[0];
  if (!cellSum[`${data.field}|${second}`] && data.value) {
    cellSum[`${data.field}|${second}`] = data.value;
  } else {
    delete cellSum[`${data.field}|${second}`];
  }
  const value = Object.values(cellSum).reduce((acc, cur) => (acc += cur?.summary_count), 0);
  cb({ cellSum, value });
}

export function sortColumns(a: string, b: string) {
  if (a?.toLowerCase() === TOTAL_ROW_NAME.toLowerCase()) return -1; // "Итого" всегда первая строка
  if (b?.toLowerCase() === TOTAL_ROW_NAME.toLowerCase()) return 1;
  return a?.toLowerCase()?.localeCompare(b?.toLowerCase());
}
