import shallow from 'zustand/shallow';
import { StateSelector } from 'zustand';
import { createStore } from '@/utils/createStore';

type PolygonEditType = 'edit' | 'stop';
interface UspdMapState {
  coverageAreaValue: number;
  setCoverageAreaValue: (value: number | undefined) => void;
  isDrawing: boolean;
  setIsDrawing: (flag: boolean) => void;
  setPolygonCoords: (polygon: number[][]) => void;
  polygonCoords: number[][];
  polygonMod: PolygonEditType;
  setPolygonMod: (mode: PolygonEditType) => void;
  centerCoords: number[];
  setCoords: (coords: number[]) => void;
}

const useUspdMap = createStore<UspdMapState>((set, get) => {
  return {
    coverageAreaValue: 100,
    isDrawing: false,
    polygonCoords: [],
    polygonMod: 'stop',
    centerCoords: [],
    setCoverageAreaValue: (value: number | undefined) => {
      set({
        coverageAreaValue: value,
      });
    },
    setIsDrawing: (flag: boolean) => {
      set({
        isDrawing: flag,
      });
    },
    setPolygonCoords: (polygon: number[][]) => {
      set({
        polygonCoords: polygon,
      });
    },
    setPolygonMod: (mode: PolygonEditType) => {
      set({
        polygonMod: mode,
      });
    },
    setCoords: (coords: number[]) => {
      set({
        centerCoords: coords,
      });
    },
  };
}, 'useUspdMap');

export const useUspdMapStore: <T>(selector: StateSelector<UspdMapState, T>) => T = (selector) =>
  useUspdMap(selector, shallow);
