import { GalleryFileStage } from './constants';
import { GetBase64File } from '@/services/FileService';
import { createStore } from '../../utils/createStore';
import { ResultImage } from 'src/dto/taskmap/task/TaskResultDto';

export interface IImageGroup extends ResultImage {
  title: string;
  obj?: string;
  filterIds?: string[];
}

interface IGalleryData {
  imageGroups: IImageGroup[];
  taskType: string;
  imageUrl: Map<string, string>;

  setTaskType: (taskType: string) => void;
  setImageGroups: (imageGroups: IImageGroup[]) => void;
  clearImageGroups: () => void;
  getTaskFileStage: () => string;
  getImageUrlById: (id: string) => string;
  setImageUrlById: (id: string) => void;

  addImageToGroupById: (
    groupId: string,
    imgId: string,
    typeList: string[],
    createdOn: Date,
    url?: string
  ) => void;
  removeImageToGroupById: (groupId: string, imgId: string) => void;
}

export const useGalleryData = createStore<IGalleryData>(
  (set, get) => ({
    imageGroups: [],
    taskType: '',
    imageUrl: new Map(),

    addImageToGroupById: (
      groupId: string,
      imgId: string,
      typeList: string[],
      createdOn: Date,
      url
    ) => {
      set(() => ({
        imageGroups: get().imageGroups.map((elem) =>
          elem.obj === groupId
            ? {
                ...elem,
                imageList: [
                  ...elem.imageList,
                  { id: imgId, typeList: typeList, createdOn: createdOn, url },
                ],
              }
            : elem
        ),
      }));
    },

    removeImageToGroupById: (groupId: string, imgId: string) => {
      set(() => ({
        imageGroups: get().imageGroups.map((elem) =>
          elem.obj === groupId
            ? {
                ...elem,
                imageList: [...elem.imageList.filter((e) => e.id !== imgId)],
              }
            : elem
        ),
      }));
    },

    setImageUrlById: async (id: string) => {
      GetBase64File(id).then((url) => {
        set(() => ({ imageUrl: get().imageUrl.set(id, url) }));
      });
    },

    getImageUrlById: (id: string) => {
      const tmpUrl = get().imageUrl.get(id);
      if (tmpUrl == undefined) {
        get().setImageUrlById(id);
        return get().imageUrl.get(id)!;
      } else {
        return tmpUrl;
      }
    },

    setTaskType: (taskType) => set(() => ({ taskType })),

    setImageGroups: (imageGroups) =>
      set(() => ({
        imageGroups: imageGroups,
      })),

    clearImageGroups: () =>
      set(() => ({
        imageGroups: [],
      })),

    getTaskFileStage: () => {
      switch (get().taskType) {
        case 'SMR_ODPU_ENTITY':
        case 'SMR_UNDER_RENOVATION':
        case 'SMR_MKD':
        case 'SMR_ODPU':
        case 'SMR_STOLBI':
        case 'SMR_ODPU_MOEK':
          return GalleryFileStage.SMR;
        case 'SBSD':
        case 'ZIGBEE_MODULE':
        case 'OS_ORDER':
        case 'NETWORKELEMENTS':
        case 'REPROGRAMMING':
        case 'REQUEST':
        case 'COMPLAINT_MKD':
        case 'REPEAT_REQUEST_PSK':
        case 'PU_ORDER':
        case 'ASUMB_NETWORK_ELEMENTS':
        case 'ASUMB_GUARANTEE':
        case 'ASUMB_UGP':
        case 'ASUMB_COMPLAINT_MKD':
        case 'ASUMB_GOVERNMENT':
        case 'ASUMB_SMR_MKD':
        default:
          return GalleryFileStage.OTHER;
      }
    },
  }),
  'Gallery'
);
