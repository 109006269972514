import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import {
  HouseEntranceShemeFlat,
  HouseEntranceShemeWrapper,
  HouseEntranceShemeUpDownStairs,
  HouseEntranceShemeEmptyFloor,
  HouseEntranceShemeFlatsWrapper,
  HouseEntranceShemeFloorWrapper,
  HouseEntranceShemeEntranceCube,
  HouseEntranceShemeFullEntrance,
  HouseEntranceShemeUpDownStairsInner,
  HouseEntranceShemeCenter,
  HouseEntranceShemeZoom,
  HouseEntranceShemeButton,
  EmptyHouseEntranceCube,
  HouseEntranceShemeModal,
} from './HouseEntranceSheme.styled';
import {
  getNetworkElementsCube,
  getFlatStyles,
  SCHEME_OPEN_QUERY,
  getHermesFlatStyles,
  getPlanningNetworkElementsCube,
} from './utils';
import HouseEntranceShemeHeader from './HouseEntranceShemeHeader';
import { Loading } from '@/components/ui/Loading';
import { ReformaGkhTaskDto } from '@/dto/taskmap/Dto';
import { usePnrPlanningStore } from '../PnrPlanning/store';
import shallow from 'zustand/shallow';
import {
  HouseLevelFloorType,
  NOT_DEFINED_MONTAGE_PLACE,
} from '@/components/blocks/PnrElementsInfo/utils';
import { usePNRStore } from '@/components/features/pnr/store';
import {
  SCHEME_LAYER_MONTAGE_OPTION,
  SCHEME_LAYER_HERMES_OPTION,
  SCHEME_LAYER_PLANNING_OPTION,
} from '@/components/features/pnr/constants';

interface HouseEntranceShemeProps {
  isLoading: boolean;
}
const HouseEntranceSheme: React.FC<HouseEntranceShemeProps> = ({ isLoading }) => {
  const {
    entranceIdx,
    floorInEntranceIdx,
    floorToFlats,
    smrTaskToAppartment,
    networkElementsSheme,
    shemeLayer,
    hermesPositiveMacs,
    houseRelevantTasksApartmentsCurrentYear,
    houseRelevantTasksApartmentsPreviousYears,
  } = usePNRStore(
    (state) => ({
      shemeLayer: state.shemeLayer,
      entranceIdx: state.entranceIdx,
      floorInEntranceIdx: state.floorInEntranceIdx,
      floorToFlats: state.floorToFlats,
      smrTaskToAppartment: state.smrTaskToAppartment,
      networkElementsSheme: state.networkElementsSheme,
      hermesPositiveMacs: state.hermesPositiveMacs,
      houseRelevantTasksApartmentsCurrentYear: state.houseRelevantTasksApartmentsCurrentYear,
      houseRelevantTasksApartmentsPreviousYears: state.houseRelevantTasksApartmentsPreviousYears,
    }),
    shallow
  );
  const plannedOrders = usePnrPlanningStore((state) => state.plannedOrders);
  const plannedInstalationsSheme = usePnrPlanningStore((state) => state.plannedInstalationsSheme);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.ctrlKey || event.metaKey) {
      // Для переключения на новую вкладку
      setTimeout(() => {
        window.open(`${window.location.pathname}?scheme_open=${SCHEME_LAYER_MONTAGE_OPTION}`);
      }, 20);
    } else {
      setSearchParams({ [SCHEME_OPEN_QUERY]: SCHEME_LAYER_MONTAGE_OPTION });
    }
  };

  const handleClose = () => {
    searchParams.delete(SCHEME_OPEN_QUERY);
    setSearchParams(searchParams);
  };

  const tooltipText = (
    <div>
      При клике с зажатой клавишей Ctrl <br /> схема откроется в новой вкладке
    </div>
  );

  const isOpen = !!searchParams.get(SCHEME_OPEN_QUERY);
  const flatStyles = (flatData?: ReformaGkhTaskDto | undefined, flatNumber?: string) => {
    switch (shemeLayer?.value) {
      case SCHEME_LAYER_MONTAGE_OPTION:
        return getFlatStyles(
          flatData,
          houseRelevantTasksApartmentsCurrentYear,
          houseRelevantTasksApartmentsPreviousYears,
          flatNumber
        );

      case SCHEME_LAYER_HERMES_OPTION:
        return getHermesFlatStyles(flatData, hermesPositiveMacs);

      default:
        return getFlatStyles(
          flatData,
          houseRelevantTasksApartmentsCurrentYear,
          houseRelevantTasksApartmentsPreviousYears,
          flatNumber
        );
    }
  };

  const networkElementCube = (
    entrance: number,
    floor: string | number | null,
    type: 'router' | 'gateway',
    empty?: boolean,
    hideEmpty?: boolean
  ) => {
    switch (shemeLayer?.value) {
      case SCHEME_LAYER_PLANNING_OPTION:
        return getPlanningNetworkElementsCube(
          entrance,
          floor,
          type,
          plannedInstalationsSheme,
          plannedOrders,
          hideEmpty
        );

      default:
        return getNetworkElementsCube(
          networkElementsSheme,
          entrance,
          floor,
          type,
          shemeLayer?.value,
          hermesPositiveMacs,
          empty,
          hideEmpty
        );
    }
  };

  return (
    <>
      <Tooltip title={tooltipText}>
        <HouseEntranceShemeButton
          onClick={handleOpen}
          sx={{ paddingTop: '7px' }}
          variant='button'
        >
          Схема
        </HouseEntranceShemeButton>
      </Tooltip>
      <HouseEntranceShemeModal
        open={isOpen}
        onClose={handleClose}
      >
        <HouseEntranceShemeWrapper>
          <Loading loading={isLoading}>
            <HouseEntranceShemeHeader />
          </Loading>
          <Loading
            loading={isLoading}
            sx={{ minHeight: '200px' }}
          >
            <HouseEntranceShemeZoom>
              <HouseEntranceShemeCenter>
                {entranceIdx.map((entranceNumber) => (
                  <HouseEntranceShemeFullEntrance key={entranceNumber}>
                    {/* Чердак дома */}
                    <HouseEntranceShemeUpDownStairs>
                      <EmptyHouseEntranceCube />
                      <EmptyHouseEntranceCube />

                      <HouseEntranceShemeUpDownStairsInner>
                        {networkElementCube(entranceNumber, HouseLevelFloorType.ATTIC, 'gateway')}

                        <HouseEntranceShemeEntranceCube>
                          {entranceNumber}
                        </HouseEntranceShemeEntranceCube>

                        {networkElementCube(entranceNumber, HouseLevelFloorType.ATTIC, 'router')}
                      </HouseEntranceShemeUpDownStairsInner>
                      <EmptyHouseEntranceCube />
                    </HouseEntranceShemeUpDownStairs>

                    {/* Этажи дома */}
                    {floorInEntranceIdx
                      .get(entranceNumber)!
                      .sort((a, b) => b - a)
                      .map((floor) => (
                        <div key={floor}>
                          <HouseEntranceShemeFloorWrapper>
                            {/* Номер этажа */}
                            <HouseEntranceShemeFlat
                              empty
                              sideWidth
                            >
                              {floor}
                            </HouseEntranceShemeFlat>
                            {networkElementCube(entranceNumber, floor, 'gateway')}
                            <HouseEntranceShemeFlatsWrapper>
                              {!floorToFlats.get(entranceNumber)?.get(floor)?.length && (
                                <HouseEntranceShemeEmptyFloor />
                              )}
                              {floorToFlats
                                .get(entranceNumber)
                                ?.get(floor)
                                ?.map((flatNumber) => (
                                  <HouseEntranceShemeFlat
                                    key={flatNumber}
                                    flatStyles={flatStyles(
                                      smrTaskToAppartment.get(flatNumber.toString()),
                                      flatNumber
                                    )}
                                  >
                                    {flatNumber}
                                  </HouseEntranceShemeFlat>
                                ))}
                            </HouseEntranceShemeFlatsWrapper>

                            {networkElementCube(entranceNumber, floor, 'router')}
                          </HouseEntranceShemeFloorWrapper>
                        </div>
                      ))}
                    {/* Подвал дома */}
                    <HouseEntranceShemeUpDownStairs>
                      <EmptyHouseEntranceCube />
                      <EmptyHouseEntranceCube />

                      <HouseEntranceShemeUpDownStairsInner>
                        {networkElementCube(
                          entranceNumber,
                          HouseLevelFloorType.BASEMENT,
                          'gateway'
                        )}

                        {networkElementCube(entranceNumber, HouseLevelFloorType.BASEMENT, 'router')}
                      </HouseEntranceShemeUpDownStairsInner>
                      <EmptyHouseEntranceCube />
                    </HouseEntranceShemeUpDownStairs>

                    <HouseEntranceShemeUpDownStairs>
                      <EmptyHouseEntranceCube />
                    </HouseEntranceShemeUpDownStairs>

                    {/* Место для неопределенных montagePlace */}
                    <HouseEntranceShemeUpDownStairs>
                      <EmptyHouseEntranceCube />
                      <EmptyHouseEntranceCube />

                      <HouseEntranceShemeUpDownStairsInner>
                        {networkElementCube(
                          entranceNumber,
                          NOT_DEFINED_MONTAGE_PLACE,
                          'gateway',
                          false,
                          true
                        )}

                        {networkElementCube(
                          entranceNumber,
                          NOT_DEFINED_MONTAGE_PLACE,
                          'router',
                          false,
                          true
                        )}
                      </HouseEntranceShemeUpDownStairsInner>
                      <EmptyHouseEntranceCube />
                    </HouseEntranceShemeUpDownStairs>
                  </HouseEntranceShemeFullEntrance>
                ))}
              </HouseEntranceShemeCenter>
            </HouseEntranceShemeZoom>
          </Loading>
        </HouseEntranceShemeWrapper>
      </HouseEntranceShemeModal>
    </>
  );
};

export default React.memo(HouseEntranceSheme);
