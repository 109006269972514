import { createStore } from '@/utils/createStore';
import { PillarGeo } from '../dto';
import { PillarResponse } from '@/dto/taskmap/task/TaskResultDto';

interface PpoMapStore {
  isEditPillarMode: boolean;
  pillar: PillarGeo | null;
  infoPillar: PillarResponse | null;
  changeModePillar: () => void;
  setPillar: (pillar: PillarGeo) => void;
  setInfoPillar: (infoPillar: PillarResponse) => void;
  clearInfoPillar: () => void;
}

export const usePillar = createStore<PpoMapStore>(
  (set: any, get: any) => ({
    isEditPillarMode: false,
    pillar: null,
    infoPillar: null,
    setPillar: (pillar) => {
      set({ pillar: pillar });
    },
    setInfoPillar: (infoPillar) => {
      set({ infoPillar: infoPillar });
    },

    clearInfoPillar: () => {
      set({
        infoPillar: null,
      });
    },

    changeModePillar: () => {
      const isEditPillarMode = get().isEditPillarMode;
      if (isEditPillarMode) {
        set({
          isEditPillarMode: !isEditPillarMode,
        });
      } else {
        set({
          isEditPillarMode: !isEditPillarMode,
          pillar: null,
        });
      }
    },
  }),
  'Pillar'
);
