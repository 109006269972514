import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  debounce,
} from '@mui/material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import { Controller, useForm, useFormContext } from 'react-hook-form';
import { ZOOM_FOR_SEARCH } from '../utils/CallingMapUtils';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { useEffect, useState } from 'react';
import {
  getTaskByAdressCallMap,
  getTaskByIdCallMap,
  getTaskByNumberCallMap,
  getTasksByAdressIdCallMap,
} from '@/services/CallingMapService';
import { useCallingMapState } from '../store';
import shallow from 'zustand/shallow';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { DataAddressItem } from '../types/types';
import { formatPhoneNumberMask } from '@/utils/heplers';

interface LegendProps {
  handelCloseSettings: () => void;
  show: boolean;
  mapAPI: any;
}
interface FormData {
  adress: string;
  id: string;
  contactPhone: string;
}
interface AddressOption {
  value: string;
  label: string;
  coordinats: number[];
}

const SearchByAdressIdNumber = ({ handelCloseSettings, show, mapAPI }: LegendProps) => {
  const [addressOptions, setAddressOptions] = useState<AddressOption[]>([]);
  const [addressOptionCoordinats, setaddressOptionCoordinats] = useState<number[]>([]);
  const { setGeoPoints, setDefaultValue } = useCallingMapState(
    (state) => ({
      setGeoPoints: state.setGeoPoints,
      setMapZoom: state.setMapZoom,
      setMapCenterCoordinats: state.setMapCenterCoordinats,
      setDefaultValue: state.setDefaultValue,
    }),
    shallow
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      adress: '',
      id: '',
      contactPhone: '',
    },
  });

  const { catchError, addActionLog } = useActionLog();
  const { setIsInProgress } = useScreenHoldHook();

  const onSubmit = (formData: FormData) => {
    const { id, contactPhone } = formData;
    if (id) {
      setIsInProgress(true);
      getTaskByIdCallMap(id)
        .then(({ data }) => {
          if (data.length) {
            setaddressOptionCoordinats([data[0]?.longitudeX, data[0]?.latitudeY]);
            setGeoPoints(data);
            addActionLog(ActionLogType.SUCCESS, 'Успешно');
          }
        })
        .catch((error) => catchError('Произошла ошибка', error))
        .finally(() => setIsInProgress(false));
    } else if (contactPhone) {
      setIsInProgress(true);
      getTaskByNumberCallMap(contactPhone.replace(/\D/g, ''))
        .then(({ data }) => {
          if (data.length) {
            setGeoPoints(data);
            setaddressOptionCoordinats([data[0]?.longitudeX, data[0]?.latitudeY]);
            addActionLog(ActionLogType.SUCCESS, 'Успешно');
          } else {
            catchError('Заявка не найдена', {});
          }
        })
        .catch((error) => catchError('Произошла ошибка', error))
        .finally(() => setIsInProgress(false));
    }
    setAddressOptions([]);

    reset();
  };

  useEffect(() => {
    if (addressOptionCoordinats.length) {
      mapAPI?.setCenter(addressOptionCoordinats, 17, {
        checkZoomRange: true,
      });
    }
  }, [addressOptionCoordinats]);

  const handleAdressOptions = (term: string) => {
    getTaskByAdressCallMap(term).then(({ data }) => {
      const uniqueAddresses: { [address: string]: boolean } = {};
      const uniqueData: DataAddressItem[] = [];
      data.forEach((item) => {
        if (!uniqueAddresses[item.address] && item.latitudeY && item.longitudeX) {
          uniqueAddresses[item.address] = true;
          uniqueData.push(item);
        }
      });
      setAddressOptions(
        uniqueData.map((el) => ({
          value: el.id,
          label: el.address,
          coordinats: [el?.longitudeX ?? 0, el?.latitudeY ?? 0],
        }))
      );
    });
  };

  const getAdressDebounce = debounce(handleAdressOptions, 800);

  return (
    <Stack style={{ display: show ? 'block' : 'none' }}>
      <Divider />
      <Stack>
        <CloseFullscreenIcon
          onClick={handelCloseSettings}
          style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
        />
      </Stack>
      <Stack p={1}>
        <Grid
          container
          xs={12}
          spacing={3}
        >
          <Grid
            item
            xs={6}
          >
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <TextField
                  fullWidth
                  placeholder='ID заявки'
                  label={'Поиск по ID заявки'}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  variant='standard'
                  onFocus={() => reset({ adress: '', contactPhone: '', id: value })}
                />
              )}
              name='id'
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Controller
              control={control}
              rules={{
                // eslint-disable-next-line no-useless-escape
                pattern: /^((8|\+7)[\- ]?)(\(?\d{3}\)?[\- ]?)(\d{3}[\- ]?)(\d{2}[\- ]?)(\d{2})$/,
              }}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <TextField
                  placeholder='+79998887766'
                  label={'Поиск по номеру'}
                  onBlur={onBlur}
                  onChange={(e) => onChange(formatPhoneNumberMask(e.target.value))}
                  value={value}
                  fullWidth
                  variant='standard'
                  error={errors?.contactPhone?.type === 'pattern'}
                  onFocus={() => reset({ adress: '', id: '', contactPhone: value })}
                />
              )}
              name='contactPhone'
            />
            {errors.contactPhone && (
              <Typography sx={{ width: '100%' }}>Введите номер в формате +79998887766</Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          mt={3}
        >
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Autocomplete
                options={addressOptions}
                fullWidth
                value={value}
                freeSolo
                inputValue={value}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
                onInputChange={(event, newInputValue) => {
                  getAdressDebounce(newInputValue);
                  onChange(newInputValue);
                  const coordinats = addressOptions.find(
                    (el) => el.label === newInputValue
                  )?.coordinats;
                  if (coordinats?.length) {
                    mapAPI?.setCenter(coordinats, ZOOM_FOR_SEARCH, {
                      checkZoomRange: true,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    onFocus={() => reset({ id: '', contactPhone: '' })}
                    {...params}
                    label='Адресс заявки'
                    variant='standard'
                  />
                )}
              />
            )}
            name='adress'
          />
        </Grid>
        <Grid
          item
          xs={12}
          mt={3}
          justifyContent={'end'}
          display={'flex'}
        >
          <FilterButtons
            onSearchClick={() => handleSubmit(onSubmit)()}
            onCloseClick={() => {
              setAddressOptions([]);
              reset();
              setDefaultValue();
            }}
          />
        </Grid>
      </Stack>
    </Stack>
  );
};

export default SearchByAdressIdNumber;
