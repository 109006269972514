import { Checkbox, ListItem, Typography, styled } from '@mui/material';
import { checkName } from '../utils/CallingMapUtils';
import React from 'react';
import { color } from '@/styles/mixins';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ExecuterType } from '../types/types';
import { LightTooltip, StyledCheckbox, StyledListItem, StyledSpan } from './СallingMap.styled';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useCallingMapState } from '../store';

import shallow from 'zustand/shallow';
import { getExecutorsSchedule } from '@/services/CallingMapService';
import moment from 'moment';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';

type ExecuterItemProps = {
  user: ExecuterType;
  handleCheckboxToggle: (id: string) => void;
  taskGroupMap: any;
};

const ExecuterItem = ({ user, handleCheckboxToggle, taskGroupMap }: ExecuterItemProps) => {
  const { catchError } = useActionLog();
  const { setIsInProgress } = useScreenHoldHook();

  const { setIsOpenEventCalendarPopUp, setSelectExecotorSchedule, avalibleExecutors, executors } =
    useCallingMapState(
      (state) => ({
        setIsOpenEventCalendarPopUp: state.setIsOpenEventCalendarPopUp,
        setSelectExecotorSchedule: state.setSelectExecotorSchedule,
        avalibleExecutors: state.avalibleExecutors,
        executors: state.executors,
      })
      // shallow
    );
  const checkLength = checkName(user, taskGroupMap.get(user.groupId)).length > 50;

  const handleExecutorSchedule = (user: ExecuterType) => {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
    setIsInProgress(true);
    getExecutorsSchedule(startOfMonth, endOfMonth, user.userId)
      .then(({ data: { workloadExecutors } }) => {
        if (workloadExecutors.length) {
          const mergeExcutor = { ...user, workloadDays: workloadExecutors[0].workloadDays };
          setSelectExecotorSchedule(mergeExcutor);
        } else {
          setSelectExecotorSchedule(user);
        }
        setIsOpenEventCalendarPopUp(true);
      })
      .catch((err) => {
        catchError(err?.response?.data?.message ?? 'Ошибка', err);
      })
      .finally(() => setIsInProgress(false));
  };

  return (
    <StyledListItem key={user.userId}>
      <StyledCheckbox
        checked={user.selected}
        onChange={() => handleCheckboxToggle(user.userId)}
      />
      {checkLength ? (
        <LightTooltip
          title={
            <React.Fragment>
              <Typography
                variant='subtitle2'
                color={'inherit'}
              >
                {checkName(user)}{' '}
                <StyledSpan>{user.groupId && `(${taskGroupMap.get(user.groupId)})`}</StyledSpan>
              </Typography>
            </React.Fragment>
          }
        >
          <Typography
            variant='subtitle1'
            sx={{
              width: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {checkName(user)}{' '}
            <StyledSpan>{user.groupId && `(${taskGroupMap.get(user.groupId)})`}</StyledSpan>
          </Typography>
        </LightTooltip>
      ) : (
        <Typography
          variant='subtitle1'
          sx={{
            width: '100%',
          }}
        >
          {checkName(user)}{' '}
          <StyledSpan>{user.groupId && `(${taskGroupMap.get(user.groupId)})`}</StyledSpan>
        </Typography>
      )}
      <IconButton
        sx={{ '&:hover': { color: color('trueWhite') } }}
        onClick={() => {
          handleExecutorSchedule(user);
        }}
      >
        <CalendarMonthIcon />
      </IconButton>
    </StyledListItem>
  );
};

export default ExecuterItem;
