import { Typography } from '@mui/material';

interface PnrPlanningModalStep {
  title: string;
  subtitle?: string;
}

export const PNR_PLANNING_MODAL_STEPS = [
  {
    title: 'Планирование',
    subtitle: 'Ознакомьтесь с предложенным планом.<br/>  Внесите изменения, если это необходимо',
  },
  { title: 'Создание заявок', subtitle: 'Выберете необходимые параметры' },
  { title: 'Результат' },
];

export const getSubtitle = (
  step: PnrPlanningModalStep,
  activeStep: number,
  currentStep: number
) => {
  return step.subtitle && activeStep === currentStep ? (
    <Typography
      variant='caption'
      dangerouslySetInnerHTML={{ __html: step.subtitle }}
    />
  ) : undefined;
};
