import { Box, Grid } from '@mui/material';
import React, { FC } from 'react';
import { StatusItem, StatusItemTypes } from './StatusItem';
import { Months } from './constants';
import { useNavigate } from 'react-router-dom';
import { IRegistryBrowse } from './store';
import { Item } from '@/components/features/registryEditor/constants';

const Vertical = () => <div style={{ width: '1px', background: 'rgba(0, 0, 0, 0.35)' }} />;

export const RegistryBrowseItem: FC<IRegistryBrowse> = ({
  id,
  month,
  fileCount,
  year,
  fileAttachedCount,
  completed,
}) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/registry/browse/${id}`);
  return (
    <Grid
      item
      xs={4}
    >
      <Box
        onClick={handleClick}
        sx={{
          borderRadius: '10px',
          border: '1px solid #EFEFEF',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          background: 'rgba(245, 245, 245, 1)',
          padding: '14px 28px',
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'space-around',
        }}
      >
        <Item>
          {Months[month]} {year}
        </Item>
        <Vertical />
        <Item>Реестр №{id}</Item>
        <Vertical />
        <StatusItem
          countAll={fileCount}
          countTmp={fileAttachedCount}
          type={completed ? StatusItemTypes.MESSAGE : StatusItemTypes.EDIT}
        />
      </Box>
    </Grid>
  );
};
