import { HouseOrderDto, MKDNetworkElementHouseDto } from '@/dto/taskmap/Dto';
import CopyButton from '@/components/button/CopyButton';

import React, { useMemo } from 'react';
import {
  PnrElementsInfoCopyButton,
  PnrElementsInfoEndAlign,
  PnrElementsInfoMainText,
  PnrElementsInfoRowWrapper,
  PnrElementsInfoTaskLink,
  PnrElementsInfoText,
  PnrElementsInfoWrapper,
} from './PnrElementsInfo.styled';
import { getMontagePlace } from './utils';
import { useCatalog } from '@/hooks/CatalogHook';
import { useHouseLevelFloorType } from '@/components/features/pnr/HouseEntranceEdit/utils';

type OrdersInfo = { order: true; element: HouseOrderDto; index: number };
type InstalledElementsInfo = { order: false; element: MKDNetworkElementHouseDto; index: number };
type PnrElementsInfoRowProps = OrdersInfo | InstalledElementsInfo;

const PnrElementsInfoRow = ({ element, index, order }: PnrElementsInfoRowProps) => {
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const { floorTypes } = useHouseLevelFloorType();

  const elementType = useMemo(
    () => getCatalogMapWithErr('networkEquipmentType').get(element?.typeId || '-1'),
    [element.typeId]
  );

  return (
    <PnrElementsInfoRowWrapper>
      <PnrElementsInfoEndAlign>
        <PnrElementsInfoText>{index + 1}. </PnrElementsInfoText>
      </PnrElementsInfoEndAlign>
      <PnrElementsInfoWrapper>
        <PnrElementsInfoText>Тип</PnrElementsInfoText>
        <PnrElementsInfoMainText>{elementType}</PnrElementsInfoMainText>
      </PnrElementsInfoWrapper>
      <PnrElementsInfoWrapper>
        <PnrElementsInfoText>{order ? 'Место установки' : 'Установлен'}</PnrElementsInfoText>
        <PnrElementsInfoMainText>{getMontagePlace(element, floorTypes)}</PnrElementsInfoMainText>
      </PnrElementsInfoWrapper>
      {order ? (
        <>
          <PnrElementsInfoWrapper>
            <PnrElementsInfoText>Заявка</PnrElementsInfoText>
            <PnrElementsInfoTaskLink onClick={() => window.open(`/task/edit/${element.taskId}`)}>
              {element.taskNum}
            </PnrElementsInfoTaskLink>
          </PnrElementsInfoWrapper>
        </>
      ) : (
        <>
          <>
            <PnrElementsInfoWrapper>
              <PnrElementsInfoText>MAC</PnrElementsInfoText>
              <PnrElementsInfoMainText>{element?.smrMac}</PnrElementsInfoMainText>
            </PnrElementsInfoWrapper>
            {element?.smrMac && (
              <PnrElementsInfoEndAlign>
                <PnrElementsInfoCopyButton>
                  <CopyButton
                    onlyIcon
                    textToCopy={element?.smrMac}
                  />
                </PnrElementsInfoCopyButton>
              </PnrElementsInfoEndAlign>
            )}
          </>
        </>
      )}
    </PnrElementsInfoRowWrapper>
  );
};

export default React.memo(PnrElementsInfoRow);
