import { styled, Popover } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { color, scrollbarThin } from '@/styles/mixins';

export const SupplyStatisticWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

export const SupplyStatisticDataGridWrapper = styled('div')({
  height: 650,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,

  p: {
    marginBottom: 0,
  },

  '& .MuiDataGrid-columnHeaderTitle': {
    overflow: 'visible',
    lineHeight: '1.43rem',
    whiteSpace: 'normal',
  },
});

interface SupplyStatisticExpandIconProps {
  expanded: boolean;
}

export const SupplyStatisticExpandIcon = styled(ExpandMoreIcon)<SupplyStatisticExpandIconProps>(
  ({ expanded }) => ({
    transform: `rotate(${expanded ? 180 : 0}deg)`,
    fontSize: 22,
    ml: 2,
    transition: 'transform 0.2s ease',
  })
);

export const SupplyStatisticCellAdditionalInfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  padding: 15,
});

export const SupplyStatisticCellAdditionalInfoRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 20,
});

export const SupplyStatisticCellPopover = styled(Popover)`
  .MuiPopover-paper {
    ${scrollbarThin};
  }
`;

interface SupplyStatisticCellCountProps {
  withAdditionalInfo: boolean;
}

export const SupplyStatisticCellCount = styled('div')<SupplyStatisticCellCountProps>(
  ({ withAdditionalInfo }) => ({
    width: '100%',
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    pointerEvents: withAdditionalInfo ? 'auto' : 'none',

    span: {
      color: withAdditionalInfo ? color('muiBlue') : 'default',
    },
  })
);
