import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { SubscriberResponse } from '@/dto/SubscribersDto';
import { IImage } from '@/dto/taskmap/task/TaskResultDto';

export interface IgeoPointCallingMap {
  contractorId: string;
  customerId: null | string;
  executorId: string;
  installationCallOn: number;
  latitudeY: number;
  longitudeX: number;
  networkResArea: null | string;
  number: number;
  reassignable: boolean;
  status: string;
  taskId: string;
  type: string;
  zoningGroupId: null | string;
  addressTitle: string | null;
  accountingNumber: string | null;
  urgent?: boolean;
  callRequestStatus: string;
  installationCallType: string | null;
  installationCallGeneralStatus: string | null;
  apartmentNumber: string | null;
}
export interface IInstallationCall {
  address: string | null | undefined;
  accountingNumber: string;
  meterNumber: null | number;
  techInspections: TechInspectionType[];
  abonents: SubscriberResponse[];
  installationCallLogs: InstallationCallLogType[];
  installationCallType: string | null;
  montagePlaceType: string | null;
  phase: string | null;
  requestCallType: string | null;
  taskType: string | null;
  installationCallGeneralStatus: string | null;
  taskStatus: string;
  exMeterNumber: string;
  exMeterType: string;
  executorId: string;
  apartmentNumber: string | null;
  tariff?: string | number;
  contractId?: string;
}
export type RequestHistoryType = {
  id: number;
  createAt: number;
  requestType: string;
  callType: string;
  phoneNumber: string;
};

export type TechInspectionType = {
  id: string;
  eventOn: number;
  executorName: null | string;
  puType: null | string;
  meterState: null | string;
  dateOneCall: number;
  dateTwoCall: number;
  dateThreeCall: number;
  meterNum: null | number;
  stdoBasicTask: null | string | number;
  stampNum: null | number;
  t1: null | number;
  t2: null | number;
  t3: null | number;
  comment: string | null;
  imageList: IImage[];
  tkoStatusResponse?: { id: string; description: string };
};

export type InstallationCallLogType = {
  id: string;
  abonentId: string;
  type: InstallationCallType;
  scheduledOn: null | number;
  scheduledPeriod: string;
  note: string | null;
  executor: null | ExecuterType;
  callAt: number;
  status: string;
};

export type InstallationCallType =
  | 'AGREEMENT'
  | 'REFUSAL'
  | 'CALLBACK'
  | 'MISSED_CALL'
  | 'ADD_NUMBER'
  | '';

export type ExecuterType = {
  userId: string;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  selected?: boolean;
  groupId: string;
  workloadDays?: WorkLoadDay[];
};
export type ExecuterSchedule = {
  executorId: string;
  amCount?: number;
  pmCount?: number;
  workloadDays: WorkLoadDay[];
};

export type WorkLoadDay = {
  date: number;
  countMap: Record<string, string>;
  // amCount: number;
  // pmCount: number;
};

export type ExecuterScheduleMain = {
  workloadExecutors: ExecuterSchedule[];
};

export const CALL_TYPES = {
  AGREEMENT: 'AGREEMENT',
  REFUSAL: 'REFUSAL',
  CALLBACK: 'CALLBACK',
  MISSED_CALL: 'MISSED_CALL',
  ADD_NUMBER: 'ADD_NUMBER',
  WRONG_ADDRESS: 'WRONG_ADDRESS',
};

export type WEB_RTC_CALL_STATUS = 'default' | 'connecting' | 'connected' | 'ended' | 'failed';
export interface ObjectOfFilterParams {
  [key: string]: {
    value:
      | (string | SelectFilterOptType | number)[]
      | string
      | { value: string | number | any; label: string }
      | Array<{ value: string | number; label: string }>;
    argValue: number;
  };
}
export interface Filter {
  attribute?: string;
  value?: string[] | string;
  filterArg?: number;
}
export interface DataAddressItem {
  id: string;
  address: string;
  latitudeY: number | null;
  longitudeX: number | null;
  coordinats: number[];
}
export interface GeolessGetDataPayload {
  currentPage: number;
  pageSize: number;
}

export type InfoBlockProps = {
  isTableMode: boolean;
};
