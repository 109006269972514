/* eslint-disable react/jsx-key */
import { Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import WifiIcon from '@mui/icons-material/Wifi';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import RouterOutlinedIcon from '@mui/icons-material/RouterOutlined';
import { RadioItem } from '@/components/RadioGroupForm/types';
import { color } from '@/styles/mixins';
import { Colors } from '@/styles/colors';

export const SCHEME_LAYER_MONTAGE_OPTION = 'montage';
export const SCHEME_LAYER_PLANNING_OPTION = 'planning';
export const SCHEME_LAYER_HERMES_OPTION = 'hermes';

export type ShemeLayerType =
  | typeof SCHEME_LAYER_MONTAGE_OPTION
  | typeof SCHEME_LAYER_HERMES_OPTION
  | typeof SCHEME_LAYER_PLANNING_OPTION;

export interface ShemeLayerOption {
  value: ShemeLayerType;
  label: string;
  sheme: {
    [key: string]: {
      text: string;
      background: Colors;
      color?: Colors;
      opacity?: number;
      narrow?: boolean;
    };
  };
}

export const ROUTER_TYPE = 'роутер';
export const GATEWAY_TYPE = 'шлюз';

export const SHEME_LEGEND_MONTAGE = {
  puWithoutMAC: { background: 'muiBlue', text: 'Есть пу без мас', opacity: 0.12 },
  puWithMAC: { background: 'muiBlue', text: 'Есть пу с мас', opacity: 1 },
  empty: { background: 'trueWhite', text: 'нет пу', opacity: 1 },
  relevantTask_current: {
    background: 'trueWhite',
    text: 'Планируется работа в текущем году',
    border: `2px solid ${color('pink')}`,
  },
  relevantTask_previous: {
    background: 'trueWhite',
    text: 'Планируется работа - прошлые года',
    border: `2px solid ${color('returnedTaskColorMap')}`,
  },
  router: { background: 'trueGray', text: ROUTER_TYPE, narrow: true },
  gateway: { background: 'gatewayGreen', text: GATEWAY_TYPE, narrow: true },
} as const;

export const SHEME_LEGEND_HERMES = {
  puWithoutMAC: { background: 'muiBlue', text: 'Есть пу без мас', opacity: 0.12 },
  puWithMAC_missed: { background: 'pink', text: 'Есть пу с мас (нет в опросе)', opacity: 1 },
  puWithMAC_hit: { background: 'darkGreen', text: 'Есть пу с мас (попал в опрос)', opacity: 1 },
  empty: { background: 'trueWhite', text: 'нет пу', opacity: 1 },
  router_missed: {
    background: 'trueGray',
    text: 'Роутер (нет в опросе)',
    narrow: true,
  },
  router_hit_full: {
    background: 'sandy',
    text: 'Роутер (попал в опрос полностью)',
    border: `2px solid ${color('sandy')}`,
    narrow: true,
  },
  router_hit_partly: {
    background: 'trueWhite',
    text: 'Роутер (попал в опрос частично)',
    border: `2px solid ${color('sandy')}`,
    narrow: true,
  },
  gateway: { background: 'gatewayGreen', text: GATEWAY_TYPE, narrow: true },
} as const;

export const SHEME_LEGEND_PLANNING = {
  puWithoutMAC: { background: 'muiBlue', text: 'Есть пу без мас', opacity: 0.12 },
  puWithMAC: { background: 'muiBlue', text: 'Есть пу с мас', opacity: 1 },
  empty: { background: 'trueWhite', text: 'нет пу', opacity: 1 },
  relevantTask_current: {
    background: 'trueWhite',
    text: 'Планируется работа в текущем году',
    border: `2px solid ${color('pink')}`,
  },
  relevantTask_previous: {
    background: 'trueWhite',
    text: 'Планируется работа - прошлые года',
    border: `2px solid ${color('returnedTaskColorMap')}`,
  },
  router: {
    background: 'trueWhite',
    text: ROUTER_TYPE,
    border: `3px solid ${color('darkGray')}`,
    narrow: true,
  },

  gateway: {
    background: 'trueWhite',
    text: GATEWAY_TYPE,
    border: `3px solid ${color('gatewayGreen2')}`,
    narrow: true,
  },
  order_router: {
    background: 'trueWhite',
    text: 'есть заявка Роутер',
    backgroundGradient: `linear-gradient(135deg, ${color('trueGray')} 50%, ${color(
      'trueWhite'
    )} 50%)`,
    narrow: true,
  },
  order_gateway: {
    background: 'trueWhite',
    text: 'есть заявка Шлюз',
    backgroundGradient: `linear-gradient(135deg, ${color('gatewayGreen')} 50%, ${color(
      'trueWhite'
    )} 50%)`,
    narrow: true,
  },
} as const;

export const SCHEME_LAYER_OPTIONS: ShemeLayerOption[] = [
  {
    value: SCHEME_LAYER_MONTAGE_OPTION,
    label: 'Смонтированное оборудование',
    sheme: SHEME_LEGEND_MONTAGE,
  },
  {
    value: SCHEME_LAYER_PLANNING_OPTION,
    label: 'Планируемое оборудование',
    sheme: SHEME_LEGEND_PLANNING,
  },
  { value: SCHEME_LAYER_HERMES_OPTION, label: 'Опрос Гермес', sheme: SHEME_LEGEND_HERMES },
];

export const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

export const radioItems: RadioItem[] = [
  {
    value: 'type',
    label: 'Тип',
  },
  {
    value: 'number',
    label: 'Номер',
  },
  {
    value: 'mac',
    label: 'MAC',
  },
];

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

export const iconStyle = {
  width: '35px',
  height: '35px',
};

export const elementToIcon: Map<string, JSX.Element> = new Map([
  ['8034dd00-f1fb-8f52-eed7-13b2724854bb', <WifiIcon sx={iconStyle} />],
  ['2592166e-96de-6b80-57c6-172c5fb63068', <RouterOutlinedIcon sx={iconStyle} />],
  ['e5b02d95-0606-de13-f945-51e6e0c95afe', <PriorityHighIcon sx={iconStyle} />],
  ['755e8e23-8b1c-9e82-8aec-5192bd3db768', <WifiIcon sx={iconStyle} />],
  ['f56c19c8-fcd1-d880-2acc-059ef95022c8', <WifiIcon sx={iconStyle} />],
  ['03153b4e-0ebd-fb0e-8866-207194448344', <WifiIcon sx={iconStyle} />],
  ['6ad266e7-0f39-a595-ec0b-3bda4b4bf771', <WifiIcon sx={iconStyle} />],
  ['edf20d3b-f345-e013-2df4-4a9d3700ab48', <WifiIcon sx={iconStyle} />],
  ['3dc4f179-870c-24b6-9af4-50837bb31459', <WifiIcon sx={iconStyle} />],
  ['398441f3-2506-bbd2-484d-048cdfb8d876', <WifiIcon sx={iconStyle} />],
  ['563ef6cb-1a0e-0476-8fbd-0b7a51a4a6ce', <WifiIcon sx={iconStyle} />],
]);

export const getOsIcon = (typeId?: string | null) => {
  if (typeId && elementToIcon.get(typeId)) {
    return elementToIcon.get(typeId);
  } else {
    return <PriorityHighIcon sx={iconStyle} />;
  }
};

export const tkosColumns: GridColDef[] = [
  {
    field: 'taskNumber',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>№ заявки</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Link
          style={{ widows: '100%' }}
          to={`/task/edit/${params.row.taskId}`}
          tabIndex={params.hasFocus ? 0 : -1}
        >
          {params.value + ''}
        </Link>
      </LightTooltip>
    ),
  },
  {
    field: 'apartment',
    width: 80,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>№ кв.</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'mac',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>MAC</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'taskType',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Тип</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'tkoDate',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Дата TKO</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <Typography
        fontSize={14}
        sx={{ mt: 1 }}
        variant='caption'
        gutterBottom
      >
        {moment(new Date(parseInt(params.value + ''))).format('DD.MM.yyyy')}
      </Typography>
    ),
  },
  {
    field: 'causeTko',
    width: 130,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>ТКО</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'comment',
    width: 130,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Комментарий</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
];

export const activePuOrderColumns: GridColDef[] = [
  {
    field: 'taskNumber',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>№ заявки</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Link
          style={{ widows: '100%' }}
          to={`/task/edit/${params.row.taskId}`}
          tabIndex={params.hasFocus ? 0 : -1}
        >
          {params.value + ''}
        </Link>
      </LightTooltip>
    ),
  },
  {
    field: 'apartment',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>№ кв.</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'mac',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>MAC</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'taskType',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Тип</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'plannedDate',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>План. дата</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <Typography
        fontSize={14}
        sx={{ mt: 1 }}
        variant='caption'
        gutterBottom
      >
        {moment(new Date(parseInt(params.value + ''))).format('DD.MM.yyyy')}
      </Typography>
    ),
  },
  {
    field: 'taskPriority',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Приоритет</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'comment',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Комментарий</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
];

export const activeOsOrderColumns: GridColDef[] = [
  {
    field: 'taskNumber',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>№ заявки</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Link
          style={{ widows: '100%' }}
          to={`/task/edit/${params.row.taskId}`}
          tabIndex={params.hasFocus ? 0 : -1}
        >
          {params.value + ''}
        </Link>
      </LightTooltip>
    ),
  },
  {
    field: 'apartment',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>№ кв</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'mac',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>MAC</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'imei',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>IMEI</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'taskType',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Тип</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'plannedDate',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>План. дата</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <Typography
        fontSize={14}
        sx={{ mt: 1 }}
        variant='caption'
        gutterBottom
      >
        {moment(new Date(parseInt(params.value + ''))).format('DD.MM.yyyy')}
      </Typography>
    ),
  },
  {
    field: 'taskPriority',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Приоритет</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'montagePlace',
    width: 200,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Место установки</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'comment',
    width: 130,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Комментарий</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
];

export const activeOsTaskColumns: GridColDef[] = [
  {
    field: 'taskNumber',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>№ заявки</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Link
          style={{ widows: '100%' }}
          to={`/task/edit/${params.row.taskId}`}
          tabIndex={params.hasFocus ? 0 : -1}
        >
          {params.value + ''}
        </Link>
      </LightTooltip>
    ),
  },
  {
    field: 'apartment',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>№ кв</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'taskType',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Тип</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'plannedDate',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>План. дата</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <Typography
        fontSize={14}
        sx={{ mt: 1 }}
        variant='caption'
        gutterBottom
      >
        {moment(new Date(params.value!)).format('DD.MM.yyyy')}
      </Typography>
    ),
  },
  {
    field: 'taskPriority',
    width: 100,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Приоритет</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'montagePlace',
    width: 200,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Место установки</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'comment',
    width: 130,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Комментарий</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
  {
    field: 'contractor',
    width: 200,
    filterable: false,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Ответственный</div>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value}
        </Typography>
      </LightTooltip>
    ),
  },
];
