import { createStore } from '@/utils/createStore';
import { count, findAllByParam } from '@/components/features/Yunga/api/YungaService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { YungaItemResponse } from '../api/dto/response';

interface YungaStoreFuncs {
  setFileId: (fileId?: string) => void;
  setTotal: (total: number) => void;
  setPage: (page: number) => void;
  setSearchId: (searchId: string) => void;
  setYungaLogResponseList: (list: YungaItemResponse[]) => void;
  findAll: () => void;
  boltSearch: (uuid: string) => void;
}

interface YungaStoreValues {
  fileId?: string;
  total: number;
  page: number;
  searchId: string;
  yungaLogResponseList: YungaItemResponse[];
}

type YungaStore = YungaStoreValues & YungaStoreFuncs;

export const useYunga = createStore<YungaStore>((set, get) => {
  return {
    fileId: undefined,
    setFileId: (fileId) => set({ fileId }),

    total: 0,
    setTotal: (total) => set({ total }),

    page: 1,
    setPage: (page) => set({ page }),

    searchId: '',
    setSearchId: (searchId) => set({ searchId }),

    yungaLogResponseList: [],
    setYungaLogResponseList: (list) => set({ yungaLogResponseList: list }),

    findAll: async () => {
      try {
        get().setFileId(undefined);
        useScreenHoldHook.getState().setIsInProgress(true);

        const totalResponse = await count(get().searchId);
        get().setTotal(totalResponse.data);

        const dataResponse = await findAllByParam(get().searchId, get().page - 1, 5);
        get().setYungaLogResponseList(dataResponse.data);

        if (get().searchId.length && dataResponse.data.length) {
          get().setFileId(dataResponse.data[0].fileId);
        }
      } catch (err) {
        useActionLog.getState().catchError('Ошибка при загрузке лога Юнги', err);
      } finally {
        useScreenHoldHook.getState().setIsInProgress(false);
      }
    },

    boltSearch: (uuid) => {
      set({ searchId: uuid, page: 1 });
      get().findAll();
    },
  };
}, 'Yunga');
