import { styled, Typography } from '@mui/material';

export const TaskEditorOverflowText = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const TASK_EDITOR_PREVIEW_INNER = {
  inner: {
    container: true,
    item: true,
    spacing: 1,
  },
};

export const TASK_EDITOR_SMALL_PREVIEW = {
  firstItem: { item: true, xs: 3, xl: 2 },
  secondItem: { item: true, xs: 5, xl: 4 },
  thirdItem: { item: true, xs: 4, xl: 4.5 },
  lastItem: { item: true, xl: 1.5, sx: { ml: 'auto' } },
  ...TASK_EDITOR_PREVIEW_INNER,
};

export const TASK_EDITOR_BIG_PREVIEW = {
  iconItem: { item: true, xs: 1, xl: 0.4 },
  firstItem: { item: true, xs: 2, xl: 3.3 },
  secondItem: { item: true, xs: 3.3, xl: 1.6 },
  thirdItem: { item: true, xs: 6.7, xl: 3.6 },
  fourthItem: { item: true, xs: 5, xl: 2.5 },
  lastItem: { item: true, xl: 1.6, sx: { ml: 'auto', textAlign: 'right' } },
  ...TASK_EDITOR_PREVIEW_INNER,
};

export const TASK_EDITOR_MEDIUM_PREVIEW = {
  ...TASK_EDITOR_BIG_PREVIEW,
  lastItem: { item: true, xl: 3.5, sx: { ml: 'auto', textAlign: 'right' } },
};
