import { useCatalog } from '@/hooks/CatalogHook';
import moment from 'moment';

export const useSmsStatusStatisticsSecondTableExtraColumns = () => {
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  return [
    {
      title: 'Дата входящего звонка',
      dataIndex: 'dateOfIncomingCall',
      align: 'center' as const,
      width: 150,
      className: 'custom-column-class-blue',
      render: (text: string) => {
        return <>{moment(text).format('DD.MM.YYYY')}</>;
      },
    },
    {
      title: 'Дата СМС',
      dataIndex: 'smsDate',
      align: 'center' as const,
      width: 150,
      className: 'custom-column-class-blue',
      render: (text: string) => {
        return <>{moment(text).format('DD.MM.YYYY')}</>;
      },
    },
    {
      title: 'Тип обращения',
      dataIndex: 'typeOfAppeal',
      align: 'center' as const,
      width: 150,
      className: 'custom-column-class-blue',
    },
    {
      title: 'Статус последнего ТКО',
      dataIndex: 'lastTkoStatus',
      align: 'center' as const,
      width: 150,
      className: 'custom-column-class-blue',
    },
    {
      title: 'Дата ТКО',
      dataIndex: 'lastTkoDate',
      align: 'center' as const,
      width: 150,
      className: 'custom-column-class-blue',
      render: (text: string) => {
        return <>{moment(text).format('DD.MM.YYYY')}</>;
      },
    },
    {
      title: 'История посещения',
      dataIndex: 'visitHistory',
      align: 'center' as const,
      width: 150,
      fixed: 'right' as const,
      className: 'custom-column-class-blue',
    },
    {
      title: 'Статус замены',
      dataIndex: 'replacementStatus',
      align: 'center' as const,
      width: 150,
      fixed: 'right' as const,
      className: 'custom-column-class-blue',
      render: (text: string) => {
        return <>{getCatalogMapWithErr('changeStatus').get(text) ?? 'Отсутствует'}</>;
      },
    },
  ];
};

type SmsStatusType = 'ERROR' | 'DELIVERED';
type PhaseType = 'ONE' | 'THREE';

const smsStatus = {
  ERROR: 'Ошибка',
  DELIVERED: 'Получено',
  ONE: 1,
  THREE: 3,
  INSTALLED: 'Установлена',
};
export const getSmsStatusStatisticsSecondTableColumns = (className: string) => {
  return [
    {
      title: 'ID Заявки',
      dataIndex: 'taskId',
      align: 'center' as const,
      width: 280,
      fixed: 'left' as const,
      height: 50,
      className: className,
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      align: 'center' as const,
      fixed: 'left' as const,
      width: 250,
      className: className,
    },
    {
      title: 'Номер телефона абонента',
      dataIndex: 'subscribersPhoneNumber',
      align: 'center' as const,
      width: 150,
      className: className,
    },
    {
      title: 'Статус собщения',
      dataIndex: 'messageStatus',
      align: 'center' as const,
      width: 100,
      className: className,
      render: (text: SmsStatusType) => {
        return <>{smsStatus[text]}</>;
      },
    },
    {
      title: 'Место устнововки',
      dataIndex: 'installationLocation',
      align: 'center' as const,
      width: 100,
      className: className,
    },
    {
      title: 'Фазность',
      dataIndex: 'phased',
      align: 'center' as const,
      width: 100,
      className: className,
      render: (text: PhaseType) => {
        return <>{smsStatus[text]}</>;
      },
    },
    {
      title: 'Тариф',
      dataIndex: 'tariff',
      align: 'center' as const,
      width: 70,
      className: className,
    },
  ];
};

export const SmsStatusStatisticsMainTableStaticColumns = [
  {
    title: 'Кол-во абонентов, которым отправили СМС',
    dataIndex: 'subscribersWhomSmsWasSentCount',
    align: 'center',
  },
  {
    title: 'Кол-во СМС, в том числе',
    dataIndex: 'smsCount',
    align: 'center',
  },
  {
    title: 'Кол-во СМС, доставлено',
    dataIndex: 'smsDeliveredCount',
    align: 'center',
  },
  {
    title: 'Кол-во СМС с ожиданием статуса доставки',
    dataIndex: 'smsAwaitingDeliveryStatusCount',
    align: 'center',
  },
  {
    title: 'Кол-во СМС не доставлено',
    dataIndex: 'smsNotDeliveredCount',
    align: 'center',
  },
];
