import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useExternalConnectionPopup } from './store';
import { ExtraConnectionRequest, PpoCableResponse } from '@/dto/taskmap/task/TaskResultDto';
import { editVruExternalConnection } from '@/services/TaskService';
import { useActionLog } from '@/hooks/ActionLogHook';
import {
  ExternalConnectionPopupFormWrapper,
  ExternalConnectionPopupFormButtonsWrapper,
} from './ExternalConnectionPopup.styled';
import ConnectionItem from '@/components/popups/externalConnectionPopup/ConnectionItem';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { useDisabledAll } from '@/hooks/useAccess';

interface ExternalConnectionFormData {
  [x: string]: ExtraConnectionRequest;
}

const ExternalConnectionPopupForm = () => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const methods = useForm<ExternalConnectionFormData>();
  const { handleSubmit } = methods;

  const { externalConnection, updateExtraConnection, setIsExternalConnectionPopupOpen } =
    useExternalConnectionPopup(
      ({ updateExtraConnection, externalConnection, setIsExternalConnectionPopupOpen }) => ({
        updateExtraConnection,
        externalConnection,
        setIsExternalConnectionPopupOpen,
      })
    );

  const { fetchCatch } = useActionLog();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setIsExternalConnectionPopupOpen(false);

  const saveAll = (data: any) => {
    setIsLoading(true);
    const connections = Object.keys(data)
      .filter((key) => key !== 'new')
      .map((key) => ({
        ...data[key],
        id: key,
        ppoCableRequestList: data[key]?.ppoCableRequestList
          ?.filter((elem: PpoCableResponse) => elem.id !== 'delete')
          ?.map((elem: PpoCableResponse) =>
            elem?.id.includes('new')
              ? {
                  linesNumber: elem.linesNumber,
                  sectionId: elem.sectionId,
                  type: elem.type,
                }
              : elem
          ),
      }));
    // console.log(connections);
    connections.forEach((elem) => {
      editVruExternalConnection(elem)
        .then((r) => {
          updateExtraConnection(r.data);
        })
        .catch((err) => {
          fetchCatch(err, 'Ошибка обновления сторнних подключений');
        })
        .finally(() => setIsLoading(false));
    });
  };

  return (
    <FormProvider {...methods}>
      <ExternalConnectionPopupFormWrapper disabled={isLoading}>
        {externalConnection.map((externalConnectionItem) => (
          <ConnectionItem
            key={externalConnectionItem.id}
            externalConnectionItem={externalConnectionItem}
          />
        ))}
      </ExternalConnectionPopupFormWrapper>
      <Grid>
        <ExternalConnectionPopupFormButtonsWrapper>
          <LoadingButton
            loading={isLoading}
            onClick={handleSubmit(saveAll)}
            disabled={disabledAll || onlyShow}
          >
            Сохранить
          </LoadingButton>
          <Button onClick={handleClose}>Отменить</Button>
        </ExternalConnectionPopupFormButtonsWrapper>
      </Grid>
    </FormProvider>
  );
};

export default ExternalConnectionPopupForm;
