import { Popper as PopperMui } from '@mui/material';
import React, { FC } from 'react';
import { PopperProps } from '@mui/material/Popper/Popper';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

const styles = {
  popper: {
    width: 'fit-content',
  },
};

export const Popper: FC<PopperProps> = (props) => {
  const isMobile = useMobileSize();
  return (
    <PopperMui
      {...props}
      {...(isMobile ? {} : { style: styles.popper })}
      placement='bottom-start'
    />
  );
};
