import React, { useState } from 'react';
import { Placemark, withYMaps } from 'react-yandex-maps';
import { DeliveryPointBalloon } from './DeliveryPointBalloon';
import { DeliveryPointGeo } from '@/components/maps/ppoMap/dto';
import { DeliveryPointResponse } from '@/dto/taskmap/task/TaskResultDto';
import { usePpoMap } from '@/components/maps/ppoMap/store';
import { useDeliveryPoints } from '@/components/maps/ppoMap/placemark/delivery-point/store';
import { usePillar } from '../../resCard/pillarStore';
import { deliveryPointLayout, IDeliveryPointLayout } from './CastomPlacemark/CastomPlacemark';

interface DeliveryPointPlacemarkProps {
  geoObject: DeliveryPointGeo;
}

export const DeliveryPointPlacemark = (props: DeliveryPointPlacemarkProps) => {
  const { geoObject } = props;
  const [data, setData] = useState<DeliveryPointResponse>();
  const { activeRes, activeSub } = usePpoMap();
  const { clearInfoPillar } = usePillar();
  const handleEditDeliveryPoint = (id: string, geoObject: any) => {
    // setImageGroups(getDeliveryPointImageGroup(data));
    // setOpen(true);
    clearInfoPillar();
    getTmpDeliveryPoint(id);
  };

  const { getTmpDeliveryPoint } = useDeliveryPoints();

  const PlacemarkCore = React.memo(function Y({ ymaps }: any) {
    const makeLayout = (layoutFactory: any) => {
      const Layout = layoutFactory.createClass(DeliveryPointBalloon(geoObject, data?.smrTaskId), {
        build: function () {
          Layout.superclass.build.call(this);

          this.element = document.querySelector('.map__delivery-point-balloon');
          this.element = this.element
            .querySelector('#delivery-point-balloon_id_' + geoObject.id)
            .addEventListener('click', this.myClick);
        },

        myClick: () => handleEditDeliveryPoint(geoObject.id, geoObject),
      });
      return Layout;
    };

    return geoObject?.subscriberSource && geoObject?.result ? (
      <Placemark
        geometry={[geoObject.longitude, geoObject.latitude]}
        options={{
          balloonContentLayout: makeLayout(ymaps.templateLayoutFactory),
          balloonPanelMaxMapArea: 0,

          ...deliveryPointLayout({
            ymaps: ymaps,
            id: geoObject.id,

            newPuTypeMarker: geoObject.newPuTypeMarker,
            subscriber: geoObject.subscriberSource,
            result: geoObject.result,
            subscriberActive: activeSub,
            resultActive: activeRes,
            isChangeNeeded: !!geoObject.isChangeNeeded,
          }),
        }}
      />
    ) : (
      <></>
    );
  });

  const DeliveryPointPlacemark = React.useMemo(() => {
    return withYMaps(PlacemarkCore, true, ['geoObject.addon.balloon', 'templateLayoutFactory']);
  }, [PlacemarkCore]);

  return (
    <>
      <DeliveryPointPlacemark />
      {/*          <ImagePanel />*/}
      {/*          <PersistentDrawerRight />*/}
    </>
  );
};
