import React, { useMemo } from 'react';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { useHouseOdpuReplacement } from '@/hooks/useQuery/useHouseOdpuReplacement';
import { HouseOdpuReplacementWrapper } from './HouseOdpuReplacement.styled';
import { ODPU_DATA_GRID_COLUMNS } from './utils';

const HouseOdpuReplacement = () => {
  const { houseDtoId } = useParams();
  const { odpuReplacement, isFetching } = useHouseOdpuReplacement(houseDtoId);

  const odpuReplacementColumns = useMemo(() => ODPU_DATA_GRID_COLUMNS, []);

  return (
    <HouseOdpuReplacementWrapper>
      <DataGrid
        rows={odpuReplacement ?? []}
        columns={odpuReplacementColumns}
        isRowSelectable={() => false}
        disableColumnFilter={true}
        sortingOrder={['asc', 'desc']}
        loading={isFetching}
        getRowId={(row) => row.tmpId}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        rowsPerPageOptions={[]}
        sx={{ height: '370px' }}
      />
    </HouseOdpuReplacementWrapper>
  );
};

export default HouseOdpuReplacement;
