import { GridCellParams, GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';
import { StatisticsContractorDto, StatisticsResponsebleDto } from './types';
import SmallExtraTable from './SmallExtraTable/SmallExtraTable';
import { StatisticsResponsebleDataGrid } from './StatisticsResponseble.styled';

interface TableComponentProps {
  data: StatisticsResponsebleDto[];
  columns: GridColDef[];
  showExtraTable: boolean;
  setShowExtraTable: React.Dispatch<React.SetStateAction<boolean>>;
  isloadinTable: boolean;
  columnGroupingModel?: GridColumnGroupingModel;
  subTileText?: React.RefObject<string>;
  extraColumns: GridColDef[];
  contractorTableData: StatisticsContractorDto[];
  onCellClick: (param: GridCellParams) => void;
}

export default function TableComponentResponsebleStatistic({
  data,
  columns,
  showExtraTable,
  setShowExtraTable,
  isloadinTable,
  columnGroupingModel,
  subTileText,
  extraColumns,
  contractorTableData,
  onCellClick,
}: TableComponentProps) {
  return (
    <>
      <div style={{ height: 400, width: '100%', paddingTop: 12 }}>
        <StatisticsResponsebleDataGrid
          className={'rootTableContainer'}
          columnGroupingModel={columnGroupingModel}
          experimentalFeatures={{ columnGrouping: true }}
          rows={data}
          getRowId={(row) => row?.contractorName}
          columns={columns}
          showColumnRightBorder
          showCellRightBorder
          isRowSelectable={() => false}
          disableColumnFilter={true}
          autoHeight={false}
          localeText={{ noRowsLabel: 'Нет данных для отображения' }}
          onCellClick={(param: any) => {
            onCellClick(param);
          }}
          hideFooter
          loading={isloadinTable}
        />
      </div>

      {!isloadinTable && showExtraTable && (
        <SmallExtraTable
          subTileText={subTileText?.current}
          setShowExtraTable={setShowExtraTable}
          contractorTableData={contractorTableData}
          extraColumns={extraColumns}
        />
      )}
    </>
  );
}
