import { Placemark, withYMaps } from 'react-yandex-maps';
import { PillarGeo } from '../../dto';
import React from 'react';
import { PillarsBalloon } from './PillarsBalloon';
import { usePillar } from '../../resCard/pillarStore';
import { findPillarById } from '@/services/TaskService';
import { catchError } from '@/hooks/ActionLogHook';
import { useDeliveryPoints } from '../delivery-point/store';

interface PillarsPlacemarkProps {
  geoObject: PillarGeo;
}

export const PillarsPlacemark = (props: PillarsPlacemarkProps) => {
  const { geoObject } = props;
  const { isEditPillarMode, setPillar, setInfoPillar } = usePillar();
  const { clearTmpDeliveryPoint } = useDeliveryPoints();

  const handleEditPillar = () => {
    clearTmpDeliveryPoint();
    findPillarById(geoObject.id)
      .then(({ data }) => {
        setInfoPillar(data);
      })
      .catch((err) => catchError('Ошибка загрузки', err));
  };

  const UserPlacemarkCore = React.memo(function Y({ ymaps }: any) {
    const makeLayout = (layoutFactory: any) => {
      const Layout = layoutFactory.createClass(PillarsBalloon(geoObject), {
        build: function () {
          Layout.superclass.build.call(this);

          this.element = document.querySelector('.map__delivery-point-balloon');
          this.element = this.element
            .querySelector('#delivery-point-balloon_id_' + geoObject.id)
            .addEventListener('click', this.myClick);
        },

        myClick: () => handleEditPillar(),
      });
      return Layout;
    };
    return (
      <Placemark
        geometry={[geoObject.longitude, geoObject.latitude]}
        options={{
          balloonContentLayout: makeLayout(ymaps.templateLayoutFactory),
          balloonPanelMaxMapArea: 0,
          preset: isEditPillarMode ? 'islands#redIcon' : 'islands#lightBlueStretchyIcon',
        }}
        properties={{
          iconContent: `${geoObject.number}`,
          pillar: geoObject.number,
        }}
        onClick={() => {
          if (isEditPillarMode) {
            setPillar(geoObject);
          }
        }}
      />
    );
  });

  const PillarsPlacemark = React.useMemo(() => {
    return withYMaps(UserPlacemarkCore, true, ['geoObject.addon.balloon', 'templateLayoutFactory']);
  }, [UserPlacemarkCore]);
  return <PillarsPlacemark />;
};
