import { CSSProperties } from 'react';

export const LOCK_STYLE: CSSProperties = {
  borderRadius: '10px',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(230,230,230,0.92)',
  padding: 10,
};

export const filter = (o: any, map: Map<string, string>) => ({
  ...o,
  uspdInfo: {
    ...o.uspdInfo,
    installPlace: map.get(o?.uspdInfo?.installPlace) ?? o?.uspdInfo?.installPlace,
  },
  tpFeeders: o?.tpFeeders
    ? Object.keys(o.tpFeeders)
        .map((elem) => o.tpFeeders[elem])
        .filter((elem: any) => !elem.deleted)
        .map((elem: any) => ({
          id: elem.id,
          num: elem.num,
          tpFeederType: elem.tpFeederType,
          tpMeter: elem.tpMeter,
        }))
    : [],
  inputs: o?.inputs
    ? Object.keys(o.inputs)
        .map((elem) => o.inputs[elem])
        .filter((elem: any) => !elem.deleted)
        .map((elem: any) => ({
          capascity: elem.capascity,
          fcFeeder: elem.fcFeeder,
          id: elem.id,
          name: elem.name,
          tpFeeder: elem.tpFeeder,
        }))
    : [],
});
