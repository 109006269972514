import { useEffect } from 'react';

export const useFormErrorToScrollUp = (arrErrorNames: string[] = []) => {
  useEffect(() => {
    if (arrErrorNames.length) {
      const firstError = arrErrorNames[0];
      const errorElement = document.getElementById(firstError);

      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [arrErrorNames]);
};
