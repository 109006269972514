import React, { FC } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getIconsPath } from './icons';
import { ArgumentSelectType, FilterArgEnum } from './types';

export const defaultList = [
  FilterArgEnum.EQUAL,
  FilterArgEnum.NOT_EQUAL,
  FilterArgEnum.CONTAIN,
  FilterArgEnum.MORE,
  FilterArgEnum.LESS,
  FilterArgEnum.INSTALLED,
];
const style = {
  width: 25,
  paddingRight: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 0,
};

export const ArgumentSelect: FC<ArgumentSelectType> = (props) => {
  const { onArgChange, argList = defaultList, argValue, isBooleanHandler = () => {} } = props;

  const handleChange = (e: SelectChangeEvent<FilterArgEnum>) => {
    onArgChange(e.target.value);
    isBooleanHandler(e.target.value === FilterArgEnum.INSTALLED);
  };

  const renderValue = (val: any) => {
    const path = getIconsPath(val);
    return (
      <img
        src={path.path}
        style={{ margin: 0, marginLeft: 12, padding: 0 }}
        title={path.title}
      />
    );
  };

  return (
    <Select
      style={style}
      value={argValue}
      variant={'standard'}
      renderValue={renderValue}
      IconComponent={() => null}
      onChange={handleChange}
    >
      {argList.map((i) => {
        const item = getIconsPath(i);
        return (
          <MenuItem
            key={i}
            value={i}
          >
            {item.title}
          </MenuItem>
        );
      })}
    </Select>
  );
};
