import { Grid } from '@mui/material';
import React from 'react';
import { EditTable } from './Table';

export const RegistryEnforse = () => {
  return (
    <>
      <Grid
        container
        spacing={2}
        p={4}
      >
        <Grid
          xs={12}
          item
          container
          spacing={2}
        >
          <EditTable />
        </Grid>
      </Grid>
    </>
  );
};
