import React, { useMemo } from 'react';
import { SingleLine } from '@/components/inputs/SingleLineNew';

import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { SupplyBillFilterParam } from '@/dto/SupplyBillServiceDto';
import { useAllBillTypes } from '@/hooks/useQuery/useAllBillTypes';
import {
  useAllCreators,
  useAllResponsables,
  useAllStorehouses,
} from '@/hooks/useQuery/useAllSupplyCatalogs';
import {
  SupplyBillBrowseFormPapper,
  SupplyBillBrowseFormWrapper,
  SupplyBillBrowseInputWrapper,
} from './SupplyBillBrowse.styled';
import {
  convertBillTypesIntoFilter,
  DATE_ARGS,
  FILTERS_LABELS,
  MULTIPLE_ARGS,
  SINGLE_LINE_ARGS,
  STOREHOUSE_SAVE_FILTER_KEY,
  useStoreHouseFilter,
} from './utils';
import { convertIntoFilter, useAdminAccess } from '../SupplyPUComponent/utils';
import { DateRange } from '@/components/inputs/DateRange';
import { useAllPuTypes } from '@/hooks/useQuery/useAllPuTypes';
import { useSupplyBillBrowseFilter } from './store';
import { useAllNetworkElementTypes } from '@/hooks/useQuery/useAllNetworkElementTypes';

export interface SupplyBillBrowseFormProps {
  updateData: (filt?: SupplyBillFilterParam) => void;
  isFetching: boolean;
}

const SupplyBillBrowseForm = ({ updateData, isFetching }: SupplyBillBrowseFormProps) => {
  const adminAccess = useAdminAccess();

  const {
    billDate,
    billNumber,
    puNumber,
    puTypes,
    billType,
    supplyId,
    responsables,
    osNumber,
    stampNumber,
    osTypes,
    billCreatorList,
    billEditorList,
    setDateValue,
    setDateValueArg,
    setDefaultValue,
    setMultiValue,
    setMultiValueArg,
    setSingleValue,
    setSingleValueArg,
    setPage,
  } = useSupplyBillBrowseFilter();
  const { setFilterValues, getFilterValues } = useStoreHouseFilter();

  const { data: billTypesData } = useAllBillTypes();
  const billTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(billTypesData ?? []),
    [billTypesData]
  );

  const { storehousesOptions } = useAllStorehouses();

  const { puTypesResponse } = useAllPuTypes();
  const puTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(puTypesResponse ?? []),
    [puTypesResponse]
  );

  const { networkElementTypesResponse } = useAllNetworkElementTypes();
  const osTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(networkElementTypesResponse ?? []),
    [networkElementTypesResponse]
  );

  const { responsablesOptions } = useAllResponsables();

  const { data: creatorsData } = useAllCreators();
  const creatorsOptions = useMemo(() => convertIntoFilter(creatorsData ?? []), [creatorsData]);

  const onSubmitSearch = () => {
    const filtParam = getFilterValues();
    const tmp = JSON.parse(JSON.stringify(filtParam));
    updateData(tmp);
    setPage(1);
  };

  const detailedInfo = [
    ...generateDetailedInfo(
      [billNumber.value, FILTERS_LABELS.billNumber],
      [puNumber.value, FILTERS_LABELS.puNumber],
      [puTypes.value, FILTERS_LABELS.puTypes],
      [osNumber.value, FILTERS_LABELS.osNumber],
      [stampNumber.value, FILTERS_LABELS.stampNumber],
      [osTypes.value, FILTERS_LABELS.osTypes],
      [billType.value, FILTERS_LABELS.billType],
      [supplyId.value, FILTERS_LABELS.supplyId],
      [responsables.value, FILTERS_LABELS.responsables],
      [billCreatorList.value, FILTERS_LABELS.billCreatorList],
      [billEditorList.value, FILTERS_LABELS.billEditorList]
    ),
    { items: billDate.value, title: FILTERS_LABELS.billDate },
  ];

  return (
    <SupplyBillBrowseFormPapper>
      <form onSubmit={(e) => e.preventDefault()}>
        <SupplyBillBrowseFormWrapper>
          <SupplyBillBrowseInputWrapper>
            <SingleLine
              label={FILTERS_LABELS.billNumber}
              onChange={setSingleValue('billNumber')}
              onArgChange={setSingleValueArg('billNumber')}
              {...billNumber}
              argList={SINGLE_LINE_ARGS}
            />
          </SupplyBillBrowseInputWrapper>

          <SupplyBillBrowseInputWrapper>
            <MultiSelect
              label={FILTERS_LABELS.billType}
              options={billTypesOptions}
              {...billType}
              onChange={setMultiValue('billType')}
              onArgChange={setMultiValueArg('billType')}
              argList={MULTIPLE_ARGS}
              withoutBullAnswerReset
            />
          </SupplyBillBrowseInputWrapper>

          <SupplyBillBrowseInputWrapper>
            <MultiSelect
              label={FILTERS_LABELS.responsables}
              options={responsablesOptions}
              {...responsables}
              onChange={setMultiValue('responsables')}
              onArgChange={setMultiValueArg('responsables')}
              argList={MULTIPLE_ARGS}
              withoutBullAnswerReset
            />
          </SupplyBillBrowseInputWrapper>

          <SupplyBillBrowseInputWrapper>
            <MultiSelect
              label={FILTERS_LABELS.supplyId}
              options={storehousesOptions}
              {...supplyId}
              onChange={setMultiValue('supplyId')}
              onArgChange={setMultiValueArg('supplyId')}
              argList={MULTIPLE_ARGS}
              withoutBullAnswerReset
            />
          </SupplyBillBrowseInputWrapper>

          <SupplyBillBrowseInputWrapper>
            <DateRange
              label={FILTERS_LABELS.billDate}
              onChange={setDateValue('billDate')}
              onArgChange={setDateValueArg('billDate')}
              {...billDate}
              argList={DATE_ARGS}
            />
          </SupplyBillBrowseInputWrapper>

          {adminAccess && (
            <>
              <SupplyBillBrowseInputWrapper>
                <MultiSelect
                  label={FILTERS_LABELS.billCreatorList}
                  options={creatorsOptions}
                  {...billCreatorList}
                  onChange={setMultiValue('billCreatorList')}
                  onArgChange={setMultiValueArg('billCreatorList')}
                  argList={MULTIPLE_ARGS}
                  withoutBullAnswerReset
                />
              </SupplyBillBrowseInputWrapper>

              <SupplyBillBrowseInputWrapper>
                <MultiSelect
                  label={FILTERS_LABELS.billEditorList}
                  options={creatorsOptions}
                  {...billEditorList}
                  onChange={setMultiValue('billEditorList')}
                  onArgChange={setMultiValueArg('billEditorList')}
                  argList={MULTIPLE_ARGS}
                  withoutBullAnswerReset
                />
              </SupplyBillBrowseInputWrapper>
            </>
          )}

          <SupplyBillBrowseInputWrapper>
            <SingleLine
              label={FILTERS_LABELS.puNumber}
              onChange={setSingleValue('puNumber')}
              onArgChange={setSingleValueArg('puNumber')}
              {...puNumber}
              argList={SINGLE_LINE_ARGS}
            />
          </SupplyBillBrowseInputWrapper>

          <SupplyBillBrowseInputWrapper>
            <MultiSelect
              label={FILTERS_LABELS.puTypes}
              options={puTypesOptions}
              {...puTypes}
              onChange={setMultiValue('puTypes')}
              onArgChange={setMultiValueArg('puTypes')}
              argList={MULTIPLE_ARGS}
              withoutBullAnswerReset
            />
          </SupplyBillBrowseInputWrapper>

          <SupplyBillBrowseInputWrapper>
            <SingleLine
              label={FILTERS_LABELS.osNumber}
              onChange={setSingleValue('osNumber')}
              onArgChange={setSingleValueArg('osNumber')}
              {...osNumber}
              argList={SINGLE_LINE_ARGS}
            />
          </SupplyBillBrowseInputWrapper>

          <SupplyBillBrowseInputWrapper>
            <MultiSelect
              label={FILTERS_LABELS.osTypes}
              options={osTypesOptions}
              {...osTypes}
              onChange={setMultiValue('osTypes')}
              onArgChange={setMultiValueArg('osTypes')}
              argList={MULTIPLE_ARGS}
              withoutBullAnswerReset
            />
          </SupplyBillBrowseInputWrapper>

          <SupplyBillBrowseInputWrapper>
            <SingleLine
              label={FILTERS_LABELS.stampNumber}
              onChange={setSingleValue('stampNumber')}
              onArgChange={setSingleValueArg('stampNumber')}
              {...stampNumber}
              argList={SINGLE_LINE_ARGS}
            />
          </SupplyBillBrowseInputWrapper>

          <FilterButtons
            onSearchClick={onSubmitSearch}
            onCloseClick={setDefaultValue}
            info={detailedInfo}
            disable={isFetching}
            saveFilter={{
              filterType: STOREHOUSE_SAVE_FILTER_KEY,
              infoToSave: {
                billNumber,
                puNumber,
                responsables,
                puTypes,
                billDate,
                billType,
                supplyId,
                osNumber,
                stampNumber,
                osTypes,
                billCreatorList,
                billEditorList,
              },
              selectCallback: setFilterValues,
            }}
          />
        </SupplyBillBrowseFormWrapper>
      </form>
    </SupplyBillBrowseFormPapper>
  );
};

export default SupplyBillBrowseForm;
