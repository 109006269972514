import { styled } from '@mui/material/styles';
import { color } from '@/styles/mixins';
import { Stack } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';

export const StackCenter = styled(Stack)({
  alignItems: 'start',
  marginBottom: 2,
  marginTop: 2,
});

export const TitleDiv = styled('div')({
  width: 160,
  fontFamily: 'Roboto',
  fontSize: 16,
  fontWeight: 400,
  color: color('textGray'),
});
export const ValueDiv = styled('div')({
  width: 200,
  fontFamily: 'Roboto',
  fontSize: 16,
  fontWeight: 500,
  color: color('trueBlack'),
});

export const Box = styled(BoxShadow)({
  position: 'relative',
});

export const Status = styled('div')({
  position: 'absolute',
  top: -1,
  left: -1,
  bottom: -1,
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10,
  right: 'calc(100% - 9px)',
  backgroundColor: color('searchGray'),
});
