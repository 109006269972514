import React from 'react';
import { Popup } from '../Popup';
import { useExternalConnectionPopup } from './store';
import {
  ExternalConnectionPopupColumn,
  ExternalConnectionPopupTitle,
} from './ExternalConnectionPopup.styled';
import PopupForm from './ExternalConnectionPopupForm';

export const ExternalConnectionPopup: React.FC = () => {
  const { isOpen, setIsOpen } = useExternalConnectionPopup((state) => ({
    isOpen: state.isExternalConnectionPopupOpen,
    setIsOpen: state.setIsExternalConnectionPopupOpen,
  }));

  const handleClose = () => setIsOpen(false);

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      backdropStyles={{ background: 'rgba(0, 0, 0, 0.29)' }}
    >
      <ExternalConnectionPopupColumn>
        <ExternalConnectionPopupTitle>Сторонние подключения</ExternalConnectionPopupTitle>
        <PopupForm />
      </ExternalConnectionPopupColumn>
    </Popup>
  );
};
