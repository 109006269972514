import React, { FC } from 'react';
import {
  SearchIcon,
  SearchInput,
  SearchWrapper,
} from '@/components/features/drive/search/Search.styled';

interface Search {
  value: string;
  onChange: (value: string) => void;
}

const Search: FC<Search> = ({ value, onChange }) => {
  return (
    <SearchWrapper>
      <SearchInput
        placeholder={'Поиск..'}
        value={value}
        type='text'
        onChange={(event) => onChange(event.target.value)}
      />
      <SearchIcon />
    </SearchWrapper>
  );
};

export default Search;
