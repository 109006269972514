import DraggablePopup from '@/components/popups/DraggablePopup';
import React from 'react';
import EventCalendar from './EventCalander';
import { IPopup, Popup } from '@/components/popups/Popup';

interface EventCalandarPopupProps extends IPopup {
  extraText?: string;
  maxWidth?: string;
}
export default function EventCalandarPopup(props: EventCalandarPopupProps) {
  return (
    <DraggablePopup {...props}>
      <EventCalendar />
    </DraggablePopup>
  );
}
