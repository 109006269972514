import {
  HouseLevelFloorType,
  NetworkPlanningTypes,
  NOT_DEFINED_MONTAGE_PLACE,
} from '@/components/blocks/PnrElementsInfo/utils';
import { MONTAGE_PLACE_VALUES } from '@/components/blocks/PnrPlanningModalSteps/util';
import {
  NetworkEquipmentIds,
  NO_DATA_MAC_VALUE,
} from '@/components/sections/HouseEntranceSheme/utils';
import { getFilteredNetworkOptions } from '@/components/sections/PnrPlanning/utils';
import {
  HouseDataDto,
  HouseOrderDto,
  MKDNetworkElementHouseDto,
  PlannedInstalationsDto,
} from '@/dto/taskmap/Dto';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { useMemo } from 'react';

const getMontagePlace = (networkElement: MKDNetworkElementHouseDto) => {
  const montagePlace = networkElement?.houseLevel?.floorType as MONTAGE_PLACE_VALUES;
  const montageFloor =
    networkElement?.houseLevel?.floorNumber?.toString() ?? NOT_DEFINED_MONTAGE_PLACE;

  switch (montagePlace) {
    case HouseLevelFloorType.ATTIC:
    case HouseLevelFloorType.BASEMENT:
      return montagePlace;

    default:
      return montageFloor;
  }
};
/**
 * Генерация схемы установки СО на основе houseData
 *
 * @return {Map<number, Map<string, MKDNetworkElementHouseDto[]>>} Map, представляющая схему сетевых элементов, где ключ - номер подъезда, а значение - map мест установки и соответствующих им сетевых элементов.
 */
export const getNetworkElementsSheme = (houseData: HouseDataDto) => {
  const houseNetworkElementsSheme = new Map<number, Map<string, MKDNetworkElementHouseDto[]>>();
  try {
    const allNetworkElements = houseData.osTasks?.map((task) => task.networkElements)?.flat();

    if (!allNetworkElements) return houseNetworkElementsSheme;

    for (let i = 0; i < allNetworkElements.length; i++) {
      const element = allNetworkElements[i];

      if (!element?.houseLevel?.entranceNumber || !element?.type) continue;

      const montagePlace = getMontagePlace(element);

      if (houseNetworkElementsSheme.has(element?.houseLevel?.entranceNumber)) {
        const entranceMap = houseNetworkElementsSheme.get(element?.houseLevel?.entranceNumber);
        const floorMap = new Map(entranceMap);
        if (entranceMap?.has(montagePlace)) {
          // Добавление элементов в существующее место установки
          const currentNetworkElements = entranceMap?.get(montagePlace) ?? [];
          floorMap.set(montagePlace, [...currentNetworkElements, element]);
        } else {
          // Новое место установки
          floorMap.set(montagePlace, [element]);
        }

        houseNetworkElementsSheme.set(element?.houseLevel?.entranceNumber, floorMap);
      } else {
        // Новый подъезд
        const floorMap: Map<string, MKDNetworkElementHouseDto[]> = new Map();
        floorMap.set(montagePlace, [element]);
        houseNetworkElementsSheme.set(element?.houseLevel?.entranceNumber, floorMap);
      }
    }

    return houseNetworkElementsSheme;
  } catch (error) {
    return houseNetworkElementsSheme;
  }
};

export interface NetworkElementsWithoutEntranceCount {
  router: number;
  gateway: number;
}
export const getNetworkElementsWithoutEntranceCount = (houseData: HouseDataDto) => {
  const networkElementsWithoutEntranceCount: NetworkElementsWithoutEntranceCount = {
    router: 0,
    gateway: 0,
  };
  const allNetworkElementsWithoutEntrance = houseData.osTasks
    ?.map((task) => task.networkElements)
    ?.flat()
    ?.filter((el) => !el?.houseLevel?.entranceNumber);

  if (!allNetworkElementsWithoutEntrance || !allNetworkElementsWithoutEntrance.length)
    return networkElementsWithoutEntranceCount;

  const allRoutersWithoutEntrance = allNetworkElementsWithoutEntrance?.filter(
    (element) => element?.typeId === NetworkEquipmentIds.router
  )?.length;

  const allGatewaysWithoutEntrance = allNetworkElementsWithoutEntrance?.filter(
    (element) => element?.typeId === NetworkEquipmentIds.gateway
  )?.length;

  networkElementsWithoutEntranceCount.router = allRoutersWithoutEntrance;
  networkElementsWithoutEntranceCount.gateway = allGatewaysWithoutEntrance;

  return networkElementsWithoutEntranceCount;
};

export const getPlannedOrdersByEntrance = (
  plannedOrders: HouseOrderDto[]
): Map<number, HouseOrderDto[]> => {
  const plannedOrdersMap: Map<number, HouseOrderDto[]> = new Map();

  for (let i = 0; i < plannedOrders.length; i++) {
    const element = plannedOrders[i];
    if (!element?.houseLevel?.entranceNumber) continue;
    if (plannedOrdersMap?.has(element.houseLevel.entranceNumber)) {
      // Добавление элементов в существующее место установки
      const currentOrders = plannedOrdersMap?.get(element.houseLevel.entranceNumber) ?? [];
      plannedOrdersMap.set(element.houseLevel.entranceNumber, [...currentOrders, element]);
    } else {
      // Новое место установки
      plannedOrdersMap.set(element.houseLevel.entranceNumber, [element]);
    }
  }

  return plannedOrdersMap;
};

export const getAllPuWithMacApartments = (houseData?: HouseDataDto) => {
  if (houseData) {
    return houseData.smrTasks
      ?.filter(
        (smrTask) =>
          smrTask.apartment?.length &&
          smrTask.mac?.length &&
          smrTask?.mac?.toLowerCase() !== NO_DATA_MAC_VALUE
      )
      ?.map((smrTask) => smrTask?.apartment) as string[];
  } else return [];
};

export const useNetworkEquipmentTypes = () => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const networkEquipmentTypes = getCatalogMapWithErr('networkEquipmentType');
  const filteredNetworkEquipment = useMemo(
    () => getFilteredNetworkOptions(networkEquipmentTypes),
    [networkEquipmentTypes]
  );

  return { networkEquipmentTypes, filteredNetworkEquipment };
};

export const useNetworkElementPlanningType = () => {
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const networkElementPlanningTypes = getCatalogMapWithErr('networkElementPlanningType');

  const networkElementPlanningOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('networkElementPlanningType')),
    [networkElementPlanningTypes]
  );

  return { networkElementPlanningOptions, networkElementPlanningTypes };
};

export const useHouseLevelFloorType = () => {
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const floorTypes = getCatalogMapWithErr('floorType');

  const floorTypesOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('floorType')),
    [floorTypes]
  );

  return { floorTypesOptions, floorTypes };
};
