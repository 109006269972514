import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps } from '@mui/material';
import { useEffect, useState } from 'react';

interface ExpandButtonProps {
  expand?: boolean;
  onAction: (isExpanded: boolean) => void;
  turnOnPropagation?: boolean;
  sx?: SxProps;
}

export const ExpandButton = (props: ExpandButtonProps) => {
  const { onAction } = props;
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(!!props.expand);
  }, [props.expand]);

  const handleExpand = (e: any) => {
    !props.turnOnPropagation && e.stopPropagation();
    const val = !isExpanded;
    setExpanded(val);
    onAction(val);
  };

  return (
    <ExpandMoreIcon
      onClick={handleExpand}
      sx={{
        transform: `rotate(${isExpanded ? 180 : 0}deg)`,
        color: 'gray',
        fontSize: 22,
        cursor: 'pointer',
        ml: 2,
        marginLeft: 'auto',
        transition: 'transform 0.3s ease',
        ...props.sx,
      }}
    />
  );
};
