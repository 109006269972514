import React, { FC } from 'react';
import { TextField } from './TextField';
import { useFormContext } from 'react-hook-form';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface Props {
  id?: string;
  label?: string;
  defaultValue?: string;
  keyString?: string;
  sx?: SxProps<Theme>;
}

export const DisabledTextField: FC<Props> = ({
  id = `${new Date().getDate()}`,
  label = '',
  defaultValue = '',
  keyString,
  sx,
}) => {
  const { setValue } = useFormContext();
  if (keyString) {
    setValue(keyString, defaultValue);
  }
  return (
    <TextField
      id={id}
      label={label}
      variant='standard'
      sx={{ width: '100%', ...sx }}
      disabled
      defaultValue={defaultValue}
    />
  );
};
