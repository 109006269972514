import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';

const dayItems: number[] = [3, 7, 14, 30, 60];

export const PnrTagPeriodSelector = (props: any) => {
  const [period, setPeriod] = useState(30);
  const handleChange = (event: any) => {
    setPeriod(event.target.value);
    props.onChange(event.target.value);
  };

  return (
    <FormControl
      variant='standard'
      sx={{ mx: 1, width: 75 }}
    >
      <InputLabel id='demo-simple-select-standard-label'>Период</InputLabel>
      <Select
        labelId='demo-simple-select-standard-label'
        id='demo-simple-select-standard'
        value={period}
        onChange={handleChange}
        label='Age'
      >
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        {dayItems.map((t) => (
          <MenuItem
            key={t}
            value={t}
          >
            {t}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
