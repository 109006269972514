import { Autocomplete, createFilterOptions, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SxProps } from '@mui/system';
import { TextField } from '../../fixUI/TextField';
import { Popper } from '../../fixUI/Popper';

interface AutocompleteValueProps {
  defaultValue?: string | null;
  title?: string;
  values: Map<string, string> | Array<string>;
  fieldName?: string;
  disabled?: boolean;
  disableClearable?: boolean;
  optionOnChangeValue?: (id: string) => void;
  sx?: SxProps<Theme>;
  clearFlag?: boolean;
  required?: boolean;
  forcedValueFromForm?: string;
  freeSolo?: boolean;
  className?: string;
  sortFn?: (a: string, b: string) => 1 | -1 | 0;
  getOptionDisabled?: (option: unknown) => boolean;
}

const filter = createFilterOptions<string>();
export const AutocompleteValue = (props: AutocompleteValueProps) => {
  const {
    disabled,
    disableClearable,
    sx,
    required,
    freeSolo,
    className,
    sortFn = (a, b) => a?.localeCompare(b),
    getOptionDisabled,
  } = props;
  const [stateValue, setStateValue] = useState<string | null>(null);
  const met = useFormContext();
  const setValue = met?.setValue;

  const defaultProps = {
    options: Array.from(
      props.values instanceof Map
        ? new Map([...props.values.entries()].sort((a, b) => sortFn(a[1], b[1]))).keys()
        : props.values.sort(sortFn)
    ),
    getOptionLabel: (option: string) =>
      props.values instanceof Map ? props?.values.get(option) || option || '' : option || '',
  };

  useEffect(() => {
    setStateValue(props?.defaultValue || null);
    props.fieldName && setValue && setValue(props.fieldName, props?.defaultValue || null);
  }, [props.defaultValue, props.clearFlag]);

  useEffect(() => {
    if (props.forcedValueFromForm) {
      setStateValue(props.forcedValueFromForm);
    }
  }, [props.forcedValueFromForm]);

  return (
    <Autocomplete
      sx={sx}
      freeSolo={freeSolo}
      disableClearable={disableClearable ? disableClearable : false}
      {...defaultProps}
      id={`${props.fieldName}-autocomplete-value`}
      value={stateValue}
      className={className}
      onChange={(event: any, newValue: any) => {
        if (!freeSolo && props.values instanceof Map && props?.values) {
          setStateValue(props.values.get(newValue) || '');
        } else {
          setStateValue(newValue);
        }
        props.fieldName && setValue && setValue(props.fieldName, newValue);
        if (props.optionOnChangeValue) {
          props.optionOnChangeValue(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        // Отключение необходимости клика на опцию для сета значения при freeSolo
        if (freeSolo) {
          setStateValue(newInputValue);
          props.fieldName && setValue && setValue(props.fieldName, newInputValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (freeSolo && params.inputValue !== '') {
          filtered.push(`${params.inputValue}`);
        }

        return filtered;
      }}
      getOptionDisabled={getOptionDisabled ? (option) => getOptionDisabled(option) : undefined}
      disabled={disabled}
      PopperComponent={Popper}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={props?.title}
            variant='standard'
            required={required}
            error={required && !stateValue}
            title={`${props?.title}${
              params?.inputProps?.value && `: ${params?.inputProps?.value}`
            } `}
          />
        );
      }}
    />
  );
};
