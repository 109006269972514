import { createStore } from '@/utils/createStore';

interface PpoMapStore {
  isEditMode: boolean;

  latitude?: number;
  longitude?: number;

  changeMode: () => void;

  setLatitude: (latitude: number) => void;
  setLongitude: (longitude: number) => void;
}

export const useCoords = createStore<PpoMapStore>(
  (set: any, get: any) => ({
    isEditMode: false,

    setLatitude: (latitude) => {
      set({ latitude: latitude });
    },
    setLongitude: (longitude) => {
      set({ longitude: longitude });
    },

    changeMode: () => {
      const isEdite = get().isEditMode;
      if (isEdite) {
        set({
          isEditMode: !isEdite,
        });
      } else {
        set({
          isEditMode: !isEdite,
          latitude: undefined,
          longitude: undefined,
        });
      }
    },
  }),
  'Coords'
);
