import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { TextField, Tooltip, Typography } from '@mui/material';
import { CallTypeWithCommentModal } from '../modaldialogs/CallTypeWithCommentModal';
import { taskMapToArray } from '../../features/tasksBrowse/utils';
import moment from 'moment';
import CallTypeModalTypeOne from '../modaldialogs/CallTypeModalTypeOne';
import React from 'react';
import { useCatalog } from '../../../hooks/CatalogHook';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';
import { color } from '@/styles/mixins';

interface ColumnsProps {
  refreshParentFunc: () => void;
  save: (
    taskId: string,
    requestId: string,
    resultJobLog: string,
    planDate?: string,
    executor?: string,
    comment?: string
  ) => any;
  executors: SelectFilterOptType[];
  callStatusMap: Map<string, string>;
}

export function getColumn(props: ColumnsProps): GridColDef[] {
  return [
    {
      field: 'taskNumber',
      minWidth: 80,
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div>
          <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Номер</div>
          <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>заявки</div>
        </div>
      ),
      renderCell: (params: GridRenderCellParams<string>) => (
        <strong
          style={{
            fontSize: '14px',
            fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
            color: color('muiBlue'),
            fontWeight: '400',
          }}
          onClick={() => window.open('/task/edit/' + params.row.taskId)}
        >
          {params.value + ''}
        </strong>
      ),
    },
    {
      field: 'address',
      headerName: 'Адрес',
      width: 380,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value ? params.value + '' : ''}
        </Typography>
      ),
    },
    {
      field: 'fullName',
      headerName: 'ФИО',
      minWidth: 250,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Typography
          fontSize={14}
          sx={{ mt: 1 }}
          variant='caption'
          gutterBottom
        >
          {params.value ? params.value + '' : ''}
        </Typography>
      ),
    },
    {
      field: 'phoneNumber',
      minWidth: 160,
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div>
          <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Номер</div>
          <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>телефона</div>
        </div>
      ),
      renderCell: (params: GridRenderCellParams<string>) => (
        <Tooltip title={params.value ? params.value : ''}>
          <Typography
            fontSize={14}
            sx={{ mt: 1 }}
            variant='caption'
            gutterBottom
          >
            {params.value ? params.value + '' : ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'operator',
      minWidth: 140,
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div>
          <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Имя</div>
          <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>диспетчера</div>
        </div>
      ),
      renderCell: (params: GridRenderCellParams<string>) => (
        <Tooltip title={params.value ? params.value : ''}>
          <TextField
            fullWidth
            multiline
            maxRows={2}
            value={params.value ? params.value : ''}
            id='standard-basic'
            variant='standard'
          />
        </Tooltip>
      ),
    },
    {
      field: 'exPuType',
      minWidth: 160,
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div>
          <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Тип</div>
          <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>
            Существующего ПУ
          </div>
        </div>
      ),
      renderCell: (params: GridRenderCellParams<string>) => (
        <Tooltip title={params.value + ''}>
          <Typography
            fontSize={14}
            sx={{ mt: 1 }}
            variant='caption'
            gutterBottom
          >
            {params.value ? params.value + '' : ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'comment',
      headerName: 'Комментарий',
      minWidth: 260,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Tooltip title={params.value + ''}>
          <CallTypeWithCommentModal
            data={params.value + ''}
            callStatuses={taskMapToArray(props.callStatusMap)}
            requestId={params.row.id}
            refreshParentFunc={props.refreshParentFunc}
          />
        </Tooltip>
      ),
    },
    {
      field: 'scheduledDate',
      headerName: 'Дата следующего звонка',
      minWidth: 150,
      filterable: false,
      sortable: false,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value ? moment(params.value).format('DD.MM.yyyy') : '';
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value ? moment(new Date(params.value)) : '';
      },
      renderCell: (params: GridRenderCellParams<string>) => (
        <CallTypeWithCommentModal
          data={params.value ? moment(params.value).format('DD.MM.yyyy') : ''}
          callStatuses={taskMapToArray(props.callStatusMap)}
          requestId={params.row.id}
          refreshParentFunc={props.refreshParentFunc}
        />
      ),
    },
    {
      field: 'status',
      minWidth: 150,
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div>
          <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Статус</div>
          <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Звонка</div>
        </div>
      ),
      renderCell: (params: GridRenderCellParams<string>) => (
        <Tooltip title={params.value + ''}>
          <Typography
            fontSize={14}
            sx={{ mt: 1 }}
            variant='caption'
            gutterBottom
          >
            {params.value ? params.value + '' : ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'action',
      headerName: 'Применить',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <CallTypeModalTypeOne
            requestId={params.row.id}
            save={props.save}
            executors={props.executors}
            taskStatus={'completed'}
            taskId={params.row.taskId}
            callTypeId={params.row.callType.id}
          />
        );
      },
    },
  ];
}
