import {
  HouseLevelFloorType,
  NetworkPlanningTypes,
} from '@/components/blocks/PnrElementsInfo/utils';

import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import {
  CreateHouseOrderResponseDto,
  HouseOrderDto,
  PlannedInstalationsDto,
} from '@/dto/taskmap/Dto';
import { createStore } from '@/utils/createStore';
import {
  convertPlanningsToPlanningRows,
  deepCopyMap,
  getPlaningsCount,
  getPlannedNetworkElementsSheme,
} from './utils';

export interface PlannedInstalation extends Omit<PlannedInstalationsDto, 'montagePlace'> {
  deleted: boolean;
  tmpId: number;
  montagePlace: SelectFilterOptType | null;
  equipmentTypeFilter: SelectFilterOptType | null;
  planningTypeFilter: SelectFilterOptType | null;
}

interface PnrPlanningCommentModal {
  open: boolean;
  text?: string | null;
  rowTpmId: number;
}

export interface PnrPlanningCount {
  router?: number;
  gateway?: number;
}

export type PlannedInstalationsSheme = Map<number, Map<string, PlannedInstalation[]>>;
export type PlannedInstalationsMap = Map<NetworkPlanningTypes, PlannedInstalation[]>;

export interface IPnrPlanningStore {
  openModalType: NetworkPlanningTypes | null;
  activeStep: number;
  plannedInstalations: PlannedInstalationsMap;
  networkEquipmentOptions: SelectFilterOptType[];
  networkElementPlanningOptions: SelectFilterOptType[];
  commentModal: PnrPlanningCommentModal;
  hasAnyBackendConflict: boolean;
  plannedInstalationsFromBackend: PlannedInstalationsMap;
  plannedOrders: Map<number, HouseOrderDto[]>;
  createResponse: CreateHouseOrderResponseDto;
  isEmptyPlanningsList: boolean;
  isEmptyPrePlanningsList: boolean;

  plannedInstalationsSheme: PlannedInstalationsSheme;
  plannedCount: PnrPlanningCount;
  planingsModalType?: NetworkPlanningTypes;
}
export interface IPnrPlanningStoreFunc {
  setOpenModal: (openModalType: NetworkPlanningTypes | null) => void;
  setActiveStep: (activeStep: number) => void;
  closeModal: () => void;
  nextStep: () => void;
  prevStep: () => void;
  setPlannedInstalations: (plannedInstalations: PlannedInstalationsMap) => void;
  setPlannedInstalationsSheme: (plannedInstalationsSheme: PlannedInstalationsSheme) => void;

  setPlannedInstalationsFromBackend: (
    plannedInstalationsFromBackend: PlannedInstalationsMap
  ) => void;
  setNetworkEquipmentOptions: (networkEquipmentOptions: SelectFilterOptType[]) => void;
  setNetworkElementPlanningOptions: (networkElementPlanningOptions: SelectFilterOptType[]) => void;
  setPlannedCount: (plannedCount: PnrPlanningCount) => void;

  flipDeleteStatus: (rowTmpId: number) => void;
  setRowEntrance: (rowTmpId: number, rowNewEntrance: string) => void;
  getNeededRow: (rowTmpId: number) => {
    allRows: PlannedInstalationsMap;
    neededItem: PlannedInstalation;
  };
  setRowFloor: (rowTmpId: number, rowNewFloor: string) => void;
  resetRowFloor: (rowTmpId: number) => void;
  setRowMontagePlace: (rowTmpId: number, rowNewMontagePlace: SelectFilterOptType) => void;
  setRowEquipmentType: (rowTmpId: number, rowNewEquipmentType: SelectFilterOptType) => void;
  setRowPlanningType: (rowTmpId: number, rowNewPlanningType: SelectFilterOptType) => void;

  closeCommentModal: () => void;
  saveCommentModal: () => void;
  setCommentModalText: (newText: string) => void;
  openComentModal: (rowTmpId: number) => void;
  setHasAnyBackendConflict: (hasAnyBackendConflict: boolean) => void;
  setPlannedOrders: (plannedOrders: Map<number, HouseOrderDto[]>) => void;
  resetStore: () => void;
  addPlannedInstalation: () => void;
  setCreateResponse: (createResponse: CreateHouseOrderResponseDto) => void;
  setIsEmptyPlanningsList: (isEmptyPlanningsList: boolean) => void;
  setIsEmptyPrePlanningsList: (isEmptyPrePlanningsList: boolean) => void;
  setPlaningsModalType: (planingsModalType: NetworkPlanningTypes) => void;

  setNewPlanning: (
    planings: PlannedInstalationsDto[],
    networkEquipmentTypes: Map<string, string>,
    stage: 0 | 1 | 2 | 'newPlan',
    networkElementPlanningTypes: Map<string, string>,
    floorTypes: Map<string, string>
    // planingsModalType: NetworkPlanningTypes
  ) => void;
}

const initialCommentModal = { open: false, text: '', rowTpmId: 0 };
const initialState: IPnrPlanningStore = {
  openModalType: null,
  activeStep: 0,
  plannedInstalations: new Map([
    [NetworkPlanningTypes.FIRSTLY, []],
    [NetworkPlanningTypes.SECONDLY, []],
    [NetworkPlanningTypes.PREPLAN, []],
  ]),
  plannedInstalationsFromBackend: new Map([
    [NetworkPlanningTypes.FIRSTLY, []],
    [NetworkPlanningTypes.SECONDLY, []],
    [NetworkPlanningTypes.PREPLAN, []],
  ]),
  networkEquipmentOptions: [],
  commentModal: { ...initialCommentModal },
  hasAnyBackendConflict: false,
  plannedOrders: new Map(),
  createResponse: [],
  isEmptyPlanningsList: true,
  isEmptyPrePlanningsList: true,
  plannedInstalationsSheme: new Map(),
  networkElementPlanningOptions: [],
  plannedCount: {},
};
const ATTIC_MONTAGE_PLACE_OPTION = {
  label: 'чердак',
  value: HouseLevelFloorType.ATTIC,
};

export const usePnrPlanningStore = createStore<IPnrPlanningStore & IPnrPlanningStoreFunc>(
  (set, get) => ({
    ...initialState,

    setOpenModal: (openModalType) => set(() => ({ openModalType })),
    setPlaningsModalType: (planingsModalType) => set(() => ({ planingsModalType })),

    setActiveStep: (activeStep) => set(() => ({ activeStep })),
    setPlannedInstalations: (plannedInstalations) => set(() => ({ plannedInstalations })),
    addPlannedInstalation: () => {
      const openModalTypeOption = get().networkElementPlanningOptions.find(
        (opt) => opt.value === get().openModalType
      );

      const plannedInstalationsMap: PlannedInstalationsMap = new Map(get().plannedInstalations);
      const openModalType = get().openModalType;
      if (!openModalType) return;
      const newPlannedInstalation: PlannedInstalation = {
        id: null,
        hasConflict: false,
        houseLevel: {
          id: null,
          entranceNumber: null,
          floorNumber: -1,
          floorType: HouseLevelFloorType.ATTIC,
        },
        conflictComment: null,
        equipmentTypeId: null,
        userEdited: true,
        tmpId: 1,
        montagePlace: { ...ATTIC_MONTAGE_PLACE_OPTION },
        equipmentTypeFilter: null,
        planningTypeFilter: openModalTypeOption || null,
        deleted: false,
        type: openModalTypeOption
          ? openModalType || NetworkPlanningTypes.FIRSTLY
          : NetworkPlanningTypes.FIRSTLY,
      };
      const existingPlannedInstalations = plannedInstalationsMap.get(openModalType);
      if (existingPlannedInstalations) {
        newPlannedInstalation.tmpId = existingPlannedInstalations.length + 1;
        existingPlannedInstalations.push(newPlannedInstalation);
      } else {
        plannedInstalationsMap.set(openModalType, [newPlannedInstalation]);
      }

      set(() => ({
        plannedInstalations: plannedInstalationsMap,
      }));
    },
    setCreateResponse: (createResponse) => set(() => ({ createResponse })),
    setPlannedCount: (plannedCount) => set(() => ({ plannedCount })),

    setPlannedInstalationsFromBackend: (plannedInstalationsFromBackend) =>
      set(() => ({ plannedInstalationsFromBackend })),

    setHasAnyBackendConflict: (hasAnyBackendConflict) => set(() => ({ hasAnyBackendConflict })),

    setPlannedOrders: (plannedOrders) => set(() => ({ plannedOrders })),

    setIsEmptyPlanningsList: (isEmptyPlanningsList) => set(() => ({ isEmptyPlanningsList })),
    setIsEmptyPrePlanningsList: (isEmptyPrePlanningsList) =>
      set(() => ({ isEmptyPrePlanningsList })),

    setPlannedInstalationsSheme: (plannedInstalationsSheme) =>
      set(() => ({ plannedInstalationsSheme })),

    setNetworkEquipmentOptions: (networkEquipmentOptions) =>
      set(() => ({ networkEquipmentOptions })),

    setNetworkElementPlanningOptions: (networkElementPlanningOptions) =>
      set(() => ({ networkElementPlanningOptions })),

    closeModal: () =>
      set(() => ({
        openModalType: null,
        activeStep: 0,
        plannedInstalations: new Map(get().plannedInstalationsFromBackend),
      })),
    nextStep: () => {
      const currentStep = get().activeStep;
      set(() => ({ activeStep: currentStep + 1 }));
    },
    prevStep: () => {
      const currentStep = get().activeStep;
      set(() => ({ activeStep: currentStep - 1 }));
    },

    flipDeleteStatus: (rowTmpId) => {
      const { allRows, neededItem } = get().getNeededRow(rowTmpId);
      neededItem.deleted = !neededItem.deleted;
      get().setPlannedInstalations(allRows);
    },

    getNeededRow: (rowTmpId) => {
      const openModalType = get().openModalType as NetworkPlanningTypes;
      const allRows = new Map(get().plannedInstalations);
      const neededItem = allRows
        .get(openModalType)
        ?.find((row) => row.tmpId === rowTmpId) as PlannedInstalation;

      return { allRows, neededItem };
    },

    setRowEntrance: (rowTmpId, rowNewEntrance) => {
      const { allRows, neededItem } = get().getNeededRow(rowTmpId);
      neededItem.entrance = rowNewEntrance;
      neededItem.houseLevel.entranceNumber = Number(rowNewEntrance);
      get().setPlannedInstalations(allRows);
    },

    setRowFloor: (rowTmpId, rowNewFloor) => {
      const { allRows, neededItem } = get().getNeededRow(rowTmpId);
      neededItem.houseLevel.floorNumber = Number(rowNewFloor);
      get().setPlannedInstalations(allRows);
    },

    resetRowFloor: (rowTmpId) => {
      const { allRows, neededItem } = get().getNeededRow(rowTmpId);
      neededItem.houseLevel.floorNumber = -1;
      neededItem.houseLevel.floorType = HouseLevelFloorType.ATTIC;
      neededItem.montagePlace = { ...ATTIC_MONTAGE_PLACE_OPTION };
      get().setPlannedInstalations(allRows);
    },

    setRowMontagePlace: (rowTmpId, rowNewMontagePlace) => {
      const { allRows, neededItem } = get().getNeededRow(rowTmpId);
      neededItem.montagePlace = { ...rowNewMontagePlace };
      neededItem.houseLevel.floorType = rowNewMontagePlace.value;

      if (rowNewMontagePlace.value === HouseLevelFloorType.ATTIC) {
        neededItem.houseLevel.floorNumber = -1;
      }
      if (rowNewMontagePlace.value === HouseLevelFloorType.BASEMENT) {
        neededItem.houseLevel.floorNumber = 0;
      }
      if (rowNewMontagePlace.value === HouseLevelFloorType.FLOOR) {
        neededItem.houseLevel.floorNumber = 1;
      }
      get().setPlannedInstalations(allRows);
    },

    setRowEquipmentType: (rowTmpId, rowNewEquipmentType) => {
      const { allRows, neededItem } = get().getNeededRow(rowTmpId);
      neededItem.equipmentTypeFilter = { ...rowNewEquipmentType };
      neededItem.equipmentTypeId = rowNewEquipmentType.value;

      get().setPlannedInstalations(allRows);
    },

    setRowPlanningType: (rowTmpId, rowNewPlanningType) => {
      const { allRows, neededItem } = get().getNeededRow(rowTmpId);
      neededItem.planningTypeFilter = { ...rowNewPlanningType };
      neededItem.type = rowNewPlanningType.value as NetworkPlanningTypes;

      get().setPlannedInstalations(allRows);
    },

    openComentModal: (rowTmpId) => {
      const { neededItem } = get().getNeededRow(rowTmpId);
      set(() => ({ commentModal: { open: true, text: neededItem.comment, rowTpmId: rowTmpId } }));
    },
    closeCommentModal: () => set(() => ({ commentModal: { ...initialCommentModal } })),
    saveCommentModal: () => {
      const commentModalRowId = get().commentModal.rowTpmId;
      const { allRows, neededItem } = get().getNeededRow(commentModalRowId);
      neededItem.comment = get().commentModal.text;
      get().setPlannedInstalations(allRows);
      get().closeCommentModal();
    },
    setCommentModalText: (newText) => {
      set(() => ({ commentModal: { ...get().commentModal, text: newText } }));
    },

    resetStore: () => {
      const equipmentOptions = [...get().networkEquipmentOptions];
      const planningOptions = [...get().networkElementPlanningOptions];

      const plannedOrders = new Map(get().plannedOrders);
      const createResponse = [...get().createResponse];
      set(initialState);
      get().setNetworkEquipmentOptions(equipmentOptions);
      get().setNetworkElementPlanningOptions(planningOptions);
      get().setPlannedOrders(plannedOrders);
      get().setCreateResponse(createResponse);
    },

    setNewPlanning: (
      planings,
      networkEquipmentTypes,
      stage,
      networkElementPlanningTypes,
      floorTypes
    ) => {
      const equipmentOptions = [...get().networkEquipmentOptions];
      const planningOptions = [...get().networkElementPlanningOptions];
      const planingsCount = getPlaningsCount(planings);
      const openModalType = get().openModalType;

      get().resetStore();
      const plannedInstalation = convertPlanningsToPlanningRows(
        planings,
        networkEquipmentTypes,
        networkElementPlanningTypes,
        floorTypes
      );

      const planningsByTypeMap: PlannedInstalationsMap = new Map();
      plannedInstalation.forEach((planning) => {
        if (!planningsByTypeMap.has(planning.type)) {
          planningsByTypeMap.set(planning.type, [planning]);
        } else {
          planningsByTypeMap.get(planning.type)?.push(planning);
        }
      });

      const firstlyPlaningsLength =
        planningsByTypeMap.get(NetworkPlanningTypes.FIRSTLY)?.length || 0;
      const prePlanningsLength =
        (planningsByTypeMap.get(NetworkPlanningTypes.SECONDLY)?.length || 0) +
        (planningsByTypeMap.get(NetworkPlanningTypes.PREPLAN)?.length || 0);
      const anyConflictRows = plannedInstalation.filter((row) => row.hasConflict);
      const plannedInstalationSheme = getPlannedNetworkElementsSheme(plannedInstalation);
      get().setPlannedCount(planingsCount);
      get().setNetworkEquipmentOptions(equipmentOptions);
      get().setNetworkElementPlanningOptions(planningOptions);
      get().setPlannedInstalations(planningsByTypeMap);
      get().setPlannedInstalationsSheme(plannedInstalationSheme);
      get().setPlannedInstalationsFromBackend(deepCopyMap(planningsByTypeMap));
      get().setHasAnyBackendConflict(!!anyConflictRows.length);
      get().setIsEmptyPlanningsList(!firstlyPlaningsLength);
      get().setIsEmptyPrePlanningsList(!prePlanningsLength);

      if (stage !== 'newPlan') {
        get().setOpenModal(openModalType);
        get().setActiveStep(stage);
      }
    },
  }),
  'Pnr_modal'
);
