import { TextField } from '@/components/fixUI';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { Typography } from '@mui/material';
import { PpoStolbiTransformatorResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { useStatus } from '../panel/store';
import { useActionLog } from '@/hooks/ActionLogHook';

interface IFormTT {
  keyStr: string;
  meter?: PpoStolbiTransformatorResponse;
}

export const FormTT: FC<IFormTT> = ({ keyStr, meter = {} }) => {
  const { register, setValue } = useFormContext();
  const { catchError } = useActionLog();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  useEffect(() => {
    setValue(`${keyStr}.id`, meter?.id ? meter.id : 'new');
  }, [meter]);
  const { isDisabledAll } = useStatus((state) => ({
    isDisabledAll: state.isDisabledAll,
  }));
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant={'body1'}
          sx={{ mb: 0.5, mr: 1 }}
        >
          Тип TT:
        </Typography>
        <AutocompleteValue
          sx={{ minWidth: '30%' }}
          fieldName={`${keyStr}.typeTT`}
          defaultValue={meter?.typeTT}
          values={getCatalogMapWithErr('ttType')}
          disabled={isDisabledAll()}
        />
      </div>
      <AutocompleteValue
        title='Коэффициент ТТ'
        sx={{ width: '50%' }}
        fieldName={`${keyStr}.ktt`}
        defaultValue={meter?.ktt}
        values={getCatalogMapWithErr('ktt')}
        disabled={isDisabledAll()}
      />
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant={'body1'}
          sx={{ mb: 0.5, mr: 1 }}
        >
          A
        </Typography>
        <TextField
          id='tt-number-a'
          label='Номер ТТ'
          variant='standard'
          sx={{ width: '30%', mr: 2 }}
          {...register(`${keyStr}.ttNumberA`, {
            value: meter?.ttNumberA,
          })}
          disabled={isDisabledAll()}
        />
        <Typography
          variant={'body1'}
          sx={{ mb: 0.5, mr: 1 }}
        >
          B
        </Typography>
        <TextField
          id='tt-number-b'
          label='Номер ТТ'
          variant='standard'
          sx={{ width: '30%', mr: 2 }}
          {...register(`${keyStr}.ttNumberB`, {
            value: meter?.ttNumberB,
          })}
          disabled={isDisabledAll()}
        />
        <Typography
          variant={'body1'}
          sx={{ mb: 0.5, mr: 1 }}
        >
          C
        </Typography>
        <TextField
          id='tt-number-c'
          label='Номер ТТ'
          variant='standard'
          sx={{ width: '30%' }}
          {...register(`${keyStr}.ttNumberC`, {
            value: meter?.ttNumberC,
          })}
          disabled={isDisabledAll()}
        />
      </div>
    </>
  );
};
