import React, { FC, useState, MouseEvent } from 'react';
import style from './PpoLocker.module.scss';
import { LockerEmpty } from './LockerIcon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Popover, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { color } from '@/styles/mixins';

interface IPpoLocker {
  name?: number | string;
  isNew?: boolean;
}
interface IPpoLockerWrapper {
  isNew?: boolean;
  count?: number;
  array: any;
}

export const PpoLockerWrapper: FC<IPpoLockerWrapper> = ({ count, array, isNew = false }) => (
  <>
    {isNew ? (
      <PpoLocker isNew />
    ) : (
      Array(count)
        .fill('')
        .map((e, i) => (
          <PpoLocker
            key={i}
            name={array[i]?.inputNumber}
          />
        ))
    )}
  </>
);

export const PpoLocker: FC<IPpoLocker> = ({ name, isNew = false }) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const handleClick = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <div className={style.locker_wrapper}>
        <div className={style.diag_wrapper}>
          <div className={style.diag_a} />
          <div className={style.diag_b} />
          {(!name || isNew) && (
            <div className={style.info}>
              <InfoOutlinedIcon
                aria-describedby={'info-locker-popover'}
                style={{ width: '100%' }}
                onClick={handleClick}
              />
              <Popover
                id={'info-locker-popover'}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                {!name && !isNew && (
                  <div className={style.popup}>
                    <Typography variant={'h5'}>Шкаф не заполнен!</Typography>
                    <Typography variant={'h6'}>Дополните информацию либо</Typography>
                    <Typography variant={'h6'}>выберете другой тип шкафа</Typography>
                  </div>
                )}
                {isNew && (
                  <div className={style.popup}>
                    <Typography variant={'h5'}>Шкаф отсутствует!</Typography>
                    <Typography variant={'h6'}>Добавьте шкаф</Typography>
                    <Typography variant={'h6'}>двойным нажатием</Typography>
                  </div>
                )}
              </Popover>
            </div>
          )}
        </div>
        <div className={style.text_wrapper}>
          {isNew ? (
            <AddRoundedIcon
              fontSize='large'
              htmlColor={color('muiBlue')}
            />
          ) : name ? (
            <div className={style.text}>{`Ввод ${name}`}</div>
          ) : (
            <LockerEmpty />
          )}
        </div>
      </div>
    </>
  );
};
