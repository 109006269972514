import React from 'react';

import List from '@mui/material/List';
import {
  SImageListItemBar,
  SCollapse,
  Wrapper,
  ImageItemTitleSup,
  ImageItemTag,
  ImageItemDate,
} from './ImageItemTitleBar.styled';
import moment from 'moment';

export interface ImageItemTitleBarProps {
  titles: string[];
  createdOn: Date;
}

const getDateString = (date: Date): string => {
  return moment(date).format('DD.MM.YYYY');
};

const ImageItemTitleBar = ({ titles, createdOn }: ImageItemTitleBarProps) => {
  const [open, setOpen] = React.useState(false);

  const additionalTags = titles.slice(1);
  const handleClick = () => (additionalTags.length ? setOpen(!open) : undefined);

  return (
    <Wrapper
      onClick={handleClick}
      disabled={!additionalTags.length}
    >
      <SImageListItemBar
        title={
          <>
            <span title={titles[0]}>{titles[0]}</span>
            {!!additionalTags.length && (
              <ImageItemTitleSup>{`+ ${titles.length - 1}`}</ImageItemTitleSup>
            )}
            <ImageItemDate>{getDateString(createdOn)}</ImageItemDate>
          </>
        }
      />

      {!!additionalTags.length && (
        <SCollapse in={open}>
          <List>
            {additionalTags.map((tag) => (
              <ImageItemTag
                key={tag}
                title={tag}
              >
                {tag}
              </ImageItemTag>
            ))}
          </List>
        </SCollapse>
      )}
    </Wrapper>
  );
};

export default ImageItemTitleBar;
