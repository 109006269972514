import { Box, Stack } from '@mui/material';
import { useComplaintStore } from '../store';
import ComplaintItemEnergy from '../CompaintItemEnergy';
import ComplaintItemOdpu from '../ComplaintItemOdpu';
import shallow from 'zustand/shallow';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { EComplaintType } from '../types';
import CircularProgress from '@mui/material/CircularProgress';
import { ComplaintListLoaderWrapper, ComplaintListWrapper } from './ComplaintList.styled';

const ComplaintList = () => {
  const { compalaintList, isLoading } = useComplaintStore(
    (state) => ({
      compalaintList: state.compalaintList,
      isLoading: state.isLoading,
    }),
    shallow
  );
  return (
    <>
      {isLoading ? (
        <ComplaintListLoaderWrapper>
          <CircularProgress />
        </ComplaintListLoaderWrapper>
      ) : (
        <ComplaintListWrapper>
          {compalaintList.map((item, index) => {
            if (item.complaint.type === EComplaintType.ENERGY_SERVICE) {
              return (
                <ComplaintItemEnergy
                  key={index}
                  {...item}
                />
              );
            }
            return (
              <ComplaintItemOdpu
                key={index}
                {...item}
              />
            );
          })}
        </ComplaintListWrapper>
      )}
    </>
  );
};

export default ComplaintList;
