import { color, font, mediaBreakpointDown } from '@/styles/mixins';
import { styled, Typography } from '@mui/material';

export const InfoComplaintBlockLabel = styled(Typography)({
  fontWeight: '500',
  color: '#6B6B6B', //очередной серый цвет, не хочется заводить под него 6 переменную
  [mediaBreakpointDown('md')]: {
    fontSize: 12,
  },
});

export const InfoComplaintBlockText = styled(Typography)({
  fontWeight: '500',
  [mediaBreakpointDown('md')]: {
    fontSize: 12,
  },
});
