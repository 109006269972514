import { SupplyConstant } from '../SupplyConstant';

export const getBillType = (value: number) => {
  let billType;
  switch (value) {
    case 1:
      billType = SupplyConstant.ISSUED_TYPE;
      break;
    case 2:
      billType = SupplyConstant.RETURNED_TYPE;
      break;
    case 3:
      billType = SupplyConstant.FACTORY_TYPE_GET;
      break;
    case 4:
      billType = SupplyConstant.RELOCATION_TYPE_POST;
      break;
    case 5:
      billType = SupplyConstant.RELOCATION_TYPE_GET;
      break;
    case 6:
      billType = SupplyConstant.FACTORY_TYPE_POST;
      break;
    default:
      billType = -1;
      break;
  }

  return billType;
};
