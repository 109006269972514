import { Box, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

interface BoxShadow {
  children: ReactNode;
  p?: any;
  onClick?: any;
  sx?: SxProps<Theme> | undefined;
  ref?: any;
  onDoubleClick?: any;
  background?: string;
  border?: string;
  borderLeftColor?: string;
  borderWidth?: string;
  borderLeftWidth?: string;
}

export const BoxShadow = (props: BoxShadow) => {
  const {
    p,
    children,
    sx,
    background = '#FFFFFF',
    border = '#EFEFEF',
    borderWidth = '1',
    borderLeftColor,
    borderLeftWidth,
  } = props;
  return (
    <Box
      {...props}
      width={'100%'}
      sx={{
        borderRadius: '10px',
        boxShadow: '0px 4px 10px 0px #00000040',
        background,
        border: `${borderWidth}px solid ${border}`,
        borderLeft: borderLeftWidth
          ? `${borderLeftWidth}px solid ${borderLeftColor}`
          : `${borderWidth}px solid ${border}`,
        ...sx,
      }}
      p={p ?? 2}
    >
      {children}
    </Box>
  );
};
