import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const TaskHeaderPanelSiblingsTitle = styled(Typography)({
  fontWeight: 600,
});

export const TaskHeaderPanelChildSiblingsTitle = styled(Typography)({
  fontWeight: 600,
  paddingTop: 8,
});

export const TaskHeaderPanelChildSiblingsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
