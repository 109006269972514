import { Stack, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { Box } from '@mui/system';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import shallow from 'zustand/shallow';
import { useStatus } from '@/components/features/taskEditor/panel/store';

import {
  TransformerVoltageRequestDto,
  TransformerListVoltageResponseDto,
} from '@/dto/TransformerDto';
import { useParams } from 'react-router-dom';

interface TransformerItemProps {
  transformer: TransformerListVoltageResponseDto;
  keyString: `transformerVoltageRequestList.${number}`;
}

export const mapToArray = (map: Map<string, string>): string[] =>
  [...map].map(([key, value]) => value);

export const TransformerItem: FC<TransformerItemProps> = ({ transformer, keyString }) => {
  const { register, setValue } = useFormContext<TransformerVoltageRequestDto>();
  const { taskId } = useParams();
  setValue(`${keyString}.id`, transformer?.id);
  setValue(`${keyString}.taskId`, taskId ?? '');
  // setValue(`${keyString}.phaseName`, transformer?.phaseName);
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { isDisabledAll } = useStatus(
    (state) => ({
      isDisabledAll: state.isDisabledAll,
    }),
    shallow
  );

  return (
    <Box sx={{ p: 2 }}>
      {transformer && (
        <Stack
          spacing={2}
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
        >
          <Typography variant='inherit'>{transformer?.phaseName}</Typography>
          <TextField
            id='tf-number-tt'
            label='Номер ТН'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.serial`, {
              value: transformer?.serial,
            })}
            disabled={isDisabledAll()}
          />
          <TextField
            id='tf-stamp-number'
            label='Номер пломбы'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.stamp`, {
              value: transformer?.stamp,
            })}
            disabled={isDisabledAll()}
          />
          <TextField
            id='tf-prev-factory-year'
            label='Год выпуска снятого ТН'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.exYear`, {
              value: transformer?.exYear,
            })}
            disabled={isDisabledAll()}
          />
          <TextField
            id='tf-prev-number'
            label='Номер снятого ТН'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.exSerial`, {
              value: transformer?.exSerial,
            })}
            disabled={isDisabledAll()}
          />
          <AutocompleteValue
            title='Тип ТН'
            defaultValue={transformer?.voltageTransformerTypeId}
            sx={{ minWidth: '130px' }}
            fieldName={`${keyString}.typeId`}
            values={getCatalogMapWithErr('voltageTransformerType')}
            disabled={isDisabledAll()}
          />
        </Stack>
      )}
    </Box>
  );
};
