import { color } from '@/styles/mixins';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const FONT = {
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 400,
  textDecoration: 'none',
};

export const NavWrapper = styled('nav')({
  backgroundColor: color('mapBackgroundColorBoxShadow'),
  color: color('fontGray'),
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  minHeight: 60,
  gap: 12,
  flexWrap: 'wrap',
  borderBottom: `1px solid ${color('borderGray')}`,
});

export const NavLink = styled(Link)({
  ...FONT,
  width: '100%',
  padding: '6px 16px',
  color: color('fontGray'),
  ':hover': {
    color: `${color('aBlue')}!important`,
  },
});

export const NavLinkMain = styled(Link)(() => ({
  ...FONT,
  ':hover': {
    color: `${color('aBlue')}!important`,
  },
}));

export const NavLinkOut = styled('a')({
  ...FONT,
  padding: '6px 16px',
});
export const NavLinkMainOut = styled('a')({
  ...FONT,
  color: color('fontGray'),
});

export const NavLinkMainWrapper = styled('div')({
  ...FONT,
  color: color('fontGray'),
  cursor: 'pointer',
  ':hover': {
    color: color('aBlue'),
  },
});

export const NavExitWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});

export const Arrow = styled('div')({
  display: 'inline-block',
  marginLeft: '.255em',
  verticalAlign: '.255em',
  borderTop: '.3em solid',
  borderRight: '.3em solid transparent',
  borderBottom: 0,
  borderLeft: '.3em solid transparent',
});
