import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import * as React from 'react';
import { useRegistryResultStore } from './store';
import { useState } from 'react';
import { TableTopToolbar } from '@/components/features/registryEditor/TableTopToolbar';
import { deleteRegistryItem } from '@/services/RegistryService';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { useRegistryBrowseStore } from '@/components/features/registryBrowse/store';

interface EnhancedTableToolbarProps {
  numSelected: number;
  id: string;
}

export const TableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, id } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { selected, updateResTable, setSelected } = useRegistryResultStore((state) => ({
    selected: state.selected,
    updateResTable: state.updateTable,
    setSelected: state.setSelected,
  }));
  const { registryItem } = useRegistryBrowseStore((state) => ({
    registryItem: state.registryItem,
  }));

  return (
    <>
      <AccessPopup
        headerText={'После удаления восстановить данные будет невозможно'}
        titleText={'Продолжить?'}
        successText={'Да'}
        closeText={'Отменить'}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSuccess={() => {
          selected.forEach((elem) => deleteRegistryItem(id, elem));
          setSelected([]);
          if (id) {
            updateResTable(id);
          }
          setIsOpen(false);
        }}
      />

      <TableTopToolbar numSelected={numSelected}>
        {!registryItem.completed ? (
          <IconButton onClick={() => setIsOpen(true)}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </TableTopToolbar>
    </>
  );
};
