import { GeoTransStation, PpoFilterRequest, ResultType, Subscriber } from './dto';
import { GeoBounds, GeoTaskInBounds } from '@/dto/taskmap/Dto';
import { createStore } from '@/utils/createStore';
import { FilterArgEnum } from '@/components/filter/MultySelectFilter';

interface PpoMapStore {
  activeSub: Subscriber[];
  activeRes: ResultType[];
  tmpTaskId?: string;
  setTmpTaskId: (tmpTaskId: string) => void;
  filter: PpoFilterRequest;
  geoTaskInBounds: GeoTaskInBounds[];
  geoTransStation?: GeoTransStation;
  setFilterParam: (filter: PpoFilterRequest) => void;
  setGeoBounds: (geoBounds: GeoBounds) => void;
  getFilter: () => PpoFilterRequest | undefined;
  setGeoResonse: (response: GeoTaskInBounds[]) => void;
  setGeoTransStation: (geoTransStation?: GeoTransStation) => void;
  clearFilter: () => void;
  setActive: ({
    activeSub,
    activeRes,
  }: {
    activeSub?: Subscriber[];
    activeRes?: ResultType[];
  }) => void;
}

export const DEFAULT_PPO_MAP_FILTER = {
  dispatcherName: '',
  dispatcherNameArg: FilterArgEnum.EQUAL,
  contractorIdList: [],
  contractorIdArg: FilterArgEnum.IN_LIST,
  executorIdList: [],
  executorIdArg: FilterArgEnum.IN_LIST,
  resIdList: [],
  resIdArg: FilterArgEnum.IN_LIST,
  electricNetworksEnterpriseIdList: [],
  electricNetworksEnterpriseIdArg: FilterArgEnum.IN_LIST,
};

export const usePpoMap = createStore<PpoMapStore>(
  (set: any, get: any) => ({
    filter: {},
    geoTaskInBounds: [],
    activeRes: [],
    activeSub: [],

    setActive: ({ activeSub, activeRes }) => {
      set({ activeSub: activeSub ?? [], activeRes: activeRes ?? [] });
    },

    setFilterParam: (param: PpoFilterRequest) => {
      const filter: PpoFilterRequest = {
        ...get().filter,
        dispatcherName: param.dispatcherName,
        dispatcherNameArg: param.dispatcherNameArg,
        contractorIdList: param.contractorIdList,
        contractorIdArg: param.contractorIdArg,
        executorIdList: param.executorIdList,
        executorIdArg: param.executorIdArg,
        resIdList: param.resIdList,
        resIdArg: param.resIdArg,
        electricNetworksEnterpriseIdList: param.electricNetworksEnterpriseIdList,
        electricNetworksEnterpriseIdArg: param.electricNetworksEnterpriseIdArg,
      };
      set({ filter: filter });
    },

    setTmpTaskId: (tmpTaskId) => set({ tmpTaskId }),

    clearFilter: () => {
      const filter: PpoFilterRequest = {
        ...get().filter,
        ...DEFAULT_PPO_MAP_FILTER,
      };
      set({ filter: filter });
    },

    setGeoBounds: (geoBounds: GeoBounds) => {
      const filter: PpoFilterRequest = {
        ...get().filter,
        geoBounds: geoBounds,
      };
      set({ filter: filter });
    },

    getFilter: () => {
      const filter = get().filter;
      return filter && Object.entries(filter).filter((t) => t[0] !== 'geoBounds' && t[1]).length > 0
        ? filter
        : undefined;
    },

    setGeoResonse: (response: GeoTaskInBounds[]) => {
      set({ geoTaskInBounds: response });
    },

    setGeoTransStation: (geoTransStation?: GeoTransStation) => {
      set({ geoTransStation: geoTransStation });
    },
  }),
  'Ppo'
);
