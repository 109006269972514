import moment from 'moment';

export const convertFormData = (data: any) => ({
  tkoEnumId: data.TKOForm.statusId,
  executor: data.TKOForm.executorName,
  meterState: data.TKOForm.meterState,
  t1: data.TKOForm.t1,
  t2: data.TKOForm.t2,
  t3: data.TKOForm.t3,
  stdoBasicTask: data.TKOForm.stdoBasicTask,
  stampNum: data.TKOForm.stampNum,
  dateOneCall: data.TKOForm.dateOneCall && moment(data.TKOForm.dateOneCall).format('yyyy-MM-DD'),
  dateTwoCall: data.TKOForm.dateTwoCall && moment(data.TKOForm.dateTwoCall).format('yyyy-MM-DD'),
  dateThreeCall:
    data.TKOForm.dateThreeCall && moment(data.TKOForm.dateThreeCall).format('yyyy-MM-DD'),
  meterNum: data.TKOForm.meterNum,
  exMeterType: data.TKOForm.exMeterType,
  tkoDate: data.TKOForm.tkoDate && moment(data.TKOForm.tkoDate).format('yyyy-MM-DD'),
  comment: data.TKOForm.comment,
  details: 'Test',
  managementCompanyName: data.TKOForm.managementCompanyName,
  managementCompanyRefuseAdmission:
    data.TKOForm.managementCompanyRefuseAdmission === 'null'
      ? null
      : data.TKOForm.managementCompanyRefuseAdmission,
});
