import { ConfirmationDialog } from '@/components/common/ConfirmationDialog';
import { TextField, Typography, Button } from '@mui/material';
import React, { useState } from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  SupplyPUTableHeader,
  SupplyPUTableHeaderItem,
  SupplyPUConfirmationDelete,
} from '../SupplyPUComponent/SupplyPUComponent.styled';
import {
  TextFieldChangeEvent,
  replaceNonNums,
  useValidReturnBillFields,
  FULL_COMPLETENESS_OPTION,
  useStorehouseWorkerAccess,
  EquipmentState,
} from '../SupplyPUComponent/utils';
import { SupplyOsModalProps } from './SupplyOSVirtualizedTable';
import { checkOsErrors, generateOsExcelFile, getOsFromErrorMaps } from './utils';
import { SupplyNetworkElementScanField } from '@/dto/SupplyBillServiceDto';
import { SupplyNetworkElementItem, SupplyServiceCatalogOption } from '@/dto/taskmap/Dto';
import { SupplyTabType } from '../StampComponent/StampComponent';
import useSupplyStore from '../SupplyStore';
import shallow from 'zustand/shallow';
import { useAllResponsables } from '@/hooks/useQuery/useAllSupplyCatalogs';

export interface SupplyOsModalHeaderProps extends SupplyOsModalProps, SupplyTabType {
  selectedOsDeclaredCount: string;
  setSelectedOsDeclaredCount: React.Dispatch<React.SetStateAction<string>>;
  saveOsItems: () => void;
  closeScanning: () => void;
  removeErroredScanRows: (dupIds: number[], existIds: number[]) => void;
  confirmAllRows: () => void;

  viewMode?: boolean;
  setOsListItems: React.Dispatch<React.SetStateAction<SupplyNetworkElementItem[]>>;
}

interface ErrorPuDialog {
  open: boolean;
  dup?: string[];
  exist?: string[];
  allIds?: number[];
  removeIds?: number[];
}

const SupplyOsModalHeader = ({
  selectedOsDeclaredCount,
  osListItems,
  osDupFieldError,
  osExistsFieldError,
  saveOsItems,
  closeScanning,
  removeErroredScanRows,
  setSelectedOsDeclaredCount,
  disabledOrDraftCheck,
  selectedOs,
  returnBill,
  viewMode,
  tabType,
  billWithConfirm,
  confirmAllRows,
  setOsListItems,
  accessProject,
}: SupplyOsModalHeaderProps) => {
  const macImeiScan = selectedOs?.typeScanField === SupplyNetworkElementScanField.MAC_AND_IMEI;
  const workerAccess = useStorehouseWorkerAccess();
  const isValidReturnBill = useValidReturnBillFields(osListItems, returnBill);

  const { responsablesOptions } = useAllResponsables();
  const { returnNewItemsMode, setReturnNewItemsMode, m15FormNumber, billDate, contractorId } =
    useSupplyStore(
      (state) => ({
        returnNewItemsMode: state.returnNewItemsMode,
        setReturnNewItemsMode: state.setReturnNewItemsMode,
        m15FormNumber: state.m15FormNumber,
        billDate: state.billDate,
        contractorId: state.contractorId,
      }),
      shallow
    );

  const [removeErrorOsDialog, setRemoveErrorOsDialog] = useState<ErrorPuDialog>({ open: false });
  const [closeAlertOpen, setCloseAlertOpen] = useState(false);

  const osListItemsErrorDisable = checkOsErrors(
    osDupFieldError,
    osExistsFieldError,
    selectedOs?.tmpId
  );
  const factoryTabType = tabType === 'factory_get';
  const disableSaveButton =
    disabledOrDraftCheck || (osListItemsErrorDisable && !factoryTabType) || !isValidReturnBill;

  const closeRemoveErrorOsDialog = () => {
    setRemoveErrorOsDialog({ ...removeErrorOsDialog, open: false });
  };

  const openRemoveErrorOsDialog = () => {
    const errorOs = getOsFromErrorMaps(
      osDupFieldError,
      osExistsFieldError,
      osListItems,
      selectedOs
    );

    setRemoveErrorOsDialog({ ...errorOs, open: true });
  };

  const removeErrorOs = () => {
    removeErroredScanRows(removeErrorOsDialog.allIds ?? [], removeErrorOsDialog.removeIds ?? []);
    setRemoveErrorOsDialog({ open: false });
  };

  const handleCsvDownload = () => {
    let filteredOs: SupplyNetworkElementItem[] = [];
    switch (selectedOs?.typeScanField) {
      case SupplyNetworkElementScanField.MAC:
        filteredOs = osListItems.filter((it) => it?.mac && it?.mac?.length > 0);
        break;
      case SupplyNetworkElementScanField.IMEI:
        filteredOs = osListItems.filter((it) => it?.imei && it?.imei?.length > 0);
        break;

      default:
        filteredOs = osListItems.filter(
          (it) => it?.mac && it?.mac?.length > 0 && it?.imei && it?.imei?.length > 0
        );
        break;
    }
    const contractor = responsablesOptions?.find((it) => it.value === contractorId)?.label;
    generateOsExcelFile(
      filteredOs,
      selectedOs?.typeName,
      macImeiScan,
      m15FormNumber,
      billDate,
      contractor
    );
  };

  const handleCloseScanning = () => {
    setCloseAlertOpen(false);
    closeScanning();
  };

  const handleChangeDeclaredCount = (event: TextFieldChangeEvent) => {
    const onlyNums = replaceNonNums(event?.target?.value);
    if (selectedOs) setSelectedOsDeclaredCount(onlyNums ?? '');
  };

  const handleCloseButton = () => {
    disabledOrDraftCheck ? handleCloseScanning() : setCloseAlertOpen(true);
  };

  const addedItemsCount = billWithConfirm || viewMode ? osListItems.length : osListItems.length - 1;

  const firstRow = osListItems[0];

  const handleReturnNewItemsClick = () => {
    setReturnNewItemsMode(!returnNewItemsMode);

    let stateOption: SupplyServiceCatalogOption | null = null;
    if (
      firstRow?.state?.id === EquipmentState.REPAIRED ||
      firstRow?.state?.id === EquipmentState.NEW ||
      firstRow?.state?.id === EquipmentState.DEFECT
    ) {
      stateOption = firstRow?.state;
    }

    const returnNewItemsOs = osListItems.map((os) => ({
      ...os,
      completeness: firstRow?.completeness,
      state: stateOption,
      defectCause: firstRow?.defectCause,
      taskNumber: null,
      completenessComment: firstRow?.completenessComment,
      address: firstRow?.address,
      generalComment: firstRow?.generalComment,
      supplyComment: '-',
      manufactureYear: firstRow?.manufactureYear,
      ownerId: firstRow?.ownerId,
    }));

    setOsListItems(returnNewItemsOs);
  };

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <SupplyPUTableHeader>
          <SupplyPUTableHeaderItem>
            <h3 style={{ fontSize: 38 }}>{selectedOs ? selectedOs.typeName : ''}</h3>
            <TextField
              disabled={accessProject}
              label='Заявленное количество'
              value={selectedOsDeclaredCount}
              onChange={(event) => handleChangeDeclaredCount(event)}
              error={addedItemsCount < parseInt(selectedOsDeclaredCount)}
            />
            <TextField
              disabled={accessProject}
              label='Уже добавлено'
              InputLabelProps={{ shrink: true }}
              contentEditable={false}
              error={addedItemsCount > parseInt(selectedOsDeclaredCount)}
              value={addedItemsCount}
            />
          </SupplyPUTableHeaderItem>
          <SupplyPUTableHeaderItem>
            {returnBill && (
              <Button
                variant='contained'
                color={returnNewItemsMode ? 'success' : undefined}
                onClick={handleReturnNewItemsClick}
                disabled={viewMode}
              >
                Массовый возврат СО
              </Button>
            )}

            {billWithConfirm && workerAccess && !viewMode && (
              <Button
                variant='contained'
                onClick={confirmAllRows}
              >
                Подтвердить все
              </Button>
            )}

            <Button
              endIcon={<FileDownloadIcon />}
              variant='contained'
              onClick={handleCsvDownload}
            >
              Скачать файл
            </Button>
            {osListItemsErrorDisable && !factoryTabType && (
              <Button
                variant='contained'
                color='error'
                onClick={openRemoveErrorOsDialog}
              >
                Удалить строки c ошибками
              </Button>
            )}

            <Button
              variant='contained'
              disabled={disableSaveButton}
              onClick={() => saveOsItems()}
            >
              Сохранить
            </Button>

            <Button
              variant='outlined'
              onClick={handleCloseButton}
            >
              Закрыть
            </Button>
          </SupplyPUTableHeaderItem>
        </SupplyPUTableHeader>
      </div>
      <ConfirmationDialog
        title={`Cистемой были выявлены следующие ошибки`}
        handleOk={removeErrorOs}
        handleCancel={closeRemoveErrorOsDialog}
        open={removeErrorOsDialog.open}
        textForOk={'Удалить'}
        fullWidth
      >
        <>
          {!!removeErrorOsDialog?.dup?.length && (
            <>
              <Typography variant='subtitle1'>Текущая накладная:</Typography>
              {removeErrorOsDialog.dup.map((errorMac, index) => (
                <Typography
                  key={index}
                  variant='subtitle2'
                  dangerouslySetInnerHTML={{ __html: errorMac }}
                />
              ))}
            </>
          )}
          {!!removeErrorOsDialog?.exist?.length && (
            <div style={{ marginTop: 24 }}>
              <Typography variant='subtitle1'>Накладные вкладки &ldquo;Архив&rdquo;:</Typography>
              {removeErrorOsDialog?.exist?.map((errorMac, index) => (
                <Typography
                  key={index}
                  variant='subtitle2'
                  dangerouslySetInnerHTML={{ __html: errorMac }}
                />
              ))}
            </div>
          )}

          <Typography
            variant='body1'
            sx={{ mt: 4 }}
          >
            Вы уверены что хотите <SupplyPUConfirmationDelete>удалить </SupplyPUConfirmationDelete>
            ошибочное сетевое оборудование?
          </Typography>
        </>
      </ConfirmationDialog>

      <ConfirmationDialog
        title={`Внимание!`}
        handleOk={handleCloseScanning}
        handleCancel={() => setCloseAlertOpen(false)}
        open={closeAlertOpen}
        textForOk={'Да'}
        textForCancel='Нет'
      >
        <Typography variant='subtitle1'>
          Вы хотите закрыть страницу без сохранения данных?
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default SupplyOsModalHeader;
