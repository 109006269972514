import { useEffect, useState } from 'react';

type TimerComponentProps = {
  isConnected: boolean;
  timerRef?: any;
};
const TimerComponent = ({ isConnected, timerRef }: TimerComponentProps) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    let timer: any;

    if (isConnected) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isConnected]);

  // Функция для форматирования времени в формат "00:00"
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  timerRef.current = time && formatTime(time);

  return <span>{isConnected && <span>Время: {formatTime(time)}</span>}</span>;
};

export default TimerComponent;
