import { styled } from '@mui/material';

export const SelectAllPopupButtonsWrapper = styled('div')({
  marginTop: 30,
  gap: 30,
  display: 'flex',
  justifyContent: 'center',
});

export const SelectAllPopupTitle = styled('h5')({
  textAlign: 'center',
});

export const SelectAllPopupTotal = styled('div')({
  marginTop: 10,
});
