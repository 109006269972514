import { Grid, styled } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { color } from '@/styles/mixins';
import { Loading } from '@/components/ui/Loading';

export const PnrPlanningsCountWrapper = styled(BoxShadow)({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  width: 160,
  padding: '4px 16px',
  height: 'fit-content',
});

export interface PnrPlanningsCountMainTextProps {
  bigFontSize?: boolean;
}

export const PnrPlanningsCountMainText = styled('span')<PnrPlanningsCountMainTextProps>(
  ({ bigFontSize }) => ({
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: bigFontSize ? 14 : 12,
    display: 'block',
    textWrap: 'nowrap',
  })
);

export const PnrPlanningsCountSecondaryText = styled(PnrPlanningsCountMainText)({
  fontWeight: 400,
  color: color('textGray'),
});

export const PnrPlanningsCountLoading = styled(Loading)({
  height: 'unset !important',
});

export interface PnrPlanningsCountMainTextWrapperProps {
  withTopBorder?: boolean;
}

export const PnrPlanningsCountMainTextWrapper = styled(Grid)<PnrPlanningsCountMainTextWrapperProps>(
  ({ withTopBorder }) => ({
    display: 'flex',
    justifyContent: 'center',
    borderTop: withTopBorder ? `1px solid  ${color('trueGray')}` : 'none',
  })
);

export const PnrPlanningsCountRightGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
});
