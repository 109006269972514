import { FC, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import * as React from 'react';
import { useImageDrawerStore } from './store';

const fixPositionStyle = {
  height: 'calc(100vh - 2rem)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ImageZoom: FC = () => {
  const zoomStep = 1;
  const minScale = 1;
  const maxScale = 10;
  const defaultScale = 2;
  const defaultRotate = 0;

  const { url, onClose, nextIndex, prevIndex } = useImageDrawerStore((state) => ({
    url: state.url,
    onClose: state.onClose,
    nextIndex: state.nextIndex,
    prevIndex: state.prevIndex,
  }));

  const [scale, setScale] = useState(defaultScale);
  const [rotate, setRotate] = useState(defaultRotate);
  const [version, setVersion] = useState(0);
  const isDraggable = scale > 1;

  useEffect(() => reset(), [url]);
  const zoomIn = () => {
    const newScale = scale + zoomStep;
    setScale(newScale <= maxScale ? newScale : maxScale);
  };

  const zoomOut = () => {
    const newScale = scale - zoomStep;
    setScale(newScale <= minScale ? minScale : newScale);
  };
  const reset = () => {
    setScale(defaultScale);
    setRotate(0);
    setVersion(version + 1);
  };

  const onRotate = () => {
    setRotate(rotate - 90);
  };

  const onScroll = (e: WheelEvent) => {
    const delta = e.deltaY * -0.01;
    const newScale = scale + delta;

    if (newScale > scale) {
      zoomIn();
    } else {
      zoomOut();
    }
  };

  return (
    <div
      className={'container'}
      style={fixPositionStyle}
    >
      <Draggable
        disabled={!isDraggable}
        key={version}
      >
        <div
          onWheelCapture={(event) => onScroll(event.nativeEvent)}
          style={isDraggable ? { cursor: 'move' } : {}}
        >
          <img
            className={'item'}
            style={{
              zIndex: '9999',
              transform: `scale(${scale}) rotate(${rotate}deg)`,
            }}
            draggable='false'
            src={url}
            onDoubleClick={zoomIn}
          />
        </div>
      </Draggable>
      <button
        className={'btnClose-start'}
        onClick={onClose}
      >
        <CloseRoundedIcon />
      </button>
      <button
        className={'btnPrev'}
        onClick={prevIndex}
      >
        <ArrowBackRoundedIcon />
      </button>
      <button
        className={'btnNext'}
        onClick={nextIndex}
      >
        <ArrowForwardRoundedIcon />
      </button>
      <button
        className={'btnPlus'}
        onClick={zoomIn}
      >
        <ZoomInIcon />
      </button>
      <button
        className={'btnMinus'}
        onClick={zoomOut}
      >
        <ZoomOutIcon />
      </button>
      <button
        className={'btnRotate'}
        onClick={onRotate}
      >
        <RotateLeftRoundedIcon />
      </button>
      <button
        className={'btnReset'}
        onClick={reset}
      >
        Сброс
      </button>
    </div>
  );
};
