import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ModeViewerResult } from '@/components/features/taskEditor/types';
import { useCatalog } from '@/hooks/CatalogHook';
import { BoxShadow } from '../../../../ui/BoxShadow';
import { ConfirmationDialog } from '../../../../common/ConfirmationDialog';
import { ExpandButton } from '../../../../button/ExpandButton';
import { DesktopDatePicker, TextField } from '../../../../fixUI';
import { useNetworkStore } from './store';
import { useGalleryData } from '../../../../Gallery/store';
import { NetworkElementEquippedSimResponse } from '@/dto/NetworkDto';
import { SimItem } from './SimItem';
import { AutocompleteValue } from '../../../../inputs/AutocompleteValue/AutocompleteValue';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CopyButton from '../../../../button/CopyButton';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { useHouseLevelFloorType } from '@/components/features/pnr/HouseEntranceEdit/utils';
import { HouseLevelFloorType } from '../../../../blocks/PnrElementsInfo/utils';
import { mapNetworkDataHouseLevel, useNetworkElementMontagePlaces } from './utils';
import DateInfoString from '@/components/DateInfoString';
import { GSMBlockNew } from '@/components/GSM/GSMBlockNew';
import { IdPopup } from '../IdPopup';
import { NetworkStateIcon, getColorByStatusForNewWork } from './NetworkStateIcon';
import { ConsumableEquipment } from './ConsumableEquipment';
import { AccessEnum } from '@/app/auth/roles';
import { AccessController } from '@/components/common/AccessController';

interface GeneralReplacementProps {
  mode?: ModeViewerResult;
  id: string;
}

const Network: FC<GeneralReplacementProps> = (props) => {
  const onlyShow = useTaskEditorOnlyShow();
  const { id } = props;
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { setImageGroups } = useGalleryData();
  const [mode, setMode] = useState<ModeViewerResult>(ModeViewerResult.SMALL);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { register, handleSubmit, setValue, watch, formState } = useFormContext();
  const { networkData, repeatNetworkElement, updateNetworkElement, cancelNetworkElement } =
    useNetworkStore((store) => ({
      networkData: store.networkDataN,
      repeatNetworkElement: store.repeatNetworkElement,
      updateNetworkElement: store.updateNetworkElement,
      cancelNetworkElement: store.cancelNetworkElement,
    }));
  const { floorTypes } = useHouseLevelFloorType();
  const { montagePlace } = useNetworkElementMontagePlaces();

  const [date, setDate] = useState<Date | number | undefined>(
    networkData && networkData[id]?.smrDate
  );

  const onHandleDate = (newValue: Date | null) => {
    if (newValue) {
      setDate(newValue);
      setValue(`${id}.smrDate`, moment(newValue).valueOf());
    }
  };

  const optionIsLockerUsed = new Map([
    ['true', 'Да'],
    ['false', 'Нет'],
    ['null', 'Неизвестно'],
  ]);

  const changeTypeData = (data: any) => {
    const newValue: any = {
      true: true,
      false: false,
      null: null,
    };
    return newValue[data];
  };

  const disabledFieldsAndButtons = networkData[id].installStatus === 'NOT_RELEVANT';

  useEffect(() => {
    setMode(props?.mode ? props.mode : ModeViewerResult.SMALL);
    setValue(`${id}.validMsg`, networkData[id]?.validMsg);
  }, [props]);

  const handleMode = (forMode: ModeViewerResult) => (isExpanded: boolean) => {
    if (forMode === ModeViewerResult.SMALL) {
      setMode(isExpanded ? ModeViewerResult.LARGE : ModeViewerResult.SMALL);
    } else if (forMode === ModeViewerResult.LARGE) {
      setMode(isExpanded ? ModeViewerResult.LARGE : ModeViewerResult.SMALL);
    }
  };

  const handleDoubleClick = (e: any) => {
    e.stopPropagation();
    setImageGroups([
      { title: 'network', imageList: networkData[id]?.imageList, obj: networkData[id]?.id },
    ]);
  };

  const onSave = (data: any) => {
    data[id].isLockerUsed = changeTypeData(data[id].isLockerUsed);

    if (onlyShow || disabledFieldsAndButtons) return;
    const dataWithHouseLevel = mapNetworkDataHouseLevel(data[id], networkData[id]);
    updateNetworkElement(id, dataWithHouseLevel);
  };

  const onCancel = () => {
    cancelNetworkElement(id);
  };

  const onRepeat = (data: any) => {
    repeatNetworkElement(id, data[id]);
    setOpenConfirm(false);
  };
  const houseLevelFloorTypeSelected =
    watch(`${id}.houseLevel.floorType`) === HouseLevelFloorType.FLOOR;

  const handleFloorTypeChange = (newValue: string) => {
    if (newValue === HouseLevelFloorType.FLOOR) return;
    setValue(`${id}.houseLevel.floorNumber`, '');
  };

  return (
    <BoxShadow
      onDoubleClick={handleDoubleClick}
      {...getColorByStatusForNewWork(networkData[id]?.installStatus)}
    >
      {mode === ModeViewerResult.SMALL && (
        <Grid
          container
          alignItems='center'
          width='100%'
        >
          {/* состояние сети */}
          <Grid
            item
            xs={'auto'}
          >
            <NetworkStateIcon status={networkData[id]?.installStatus} />
          </Grid>

          {/* установка статуса сети */}
          {networkData && (
            <Grid
              item
              xs={1}
            >
              <Typography>
                {networkData[id]?.installStatus &&
                  getCatalogMapWithErr('networkElementStatus').get(networkData[id]?.installStatus)}
              </Typography>
            </Grid>
          )}

          {/* информация по уровню дома */}
          {networkData &&
            (networkData[id]?.houseLevel?.entranceNumber ||
              networkData[id]?.houseLevel?.floorNumber ||
              networkData[id]?.houseLevel?.floorType) && (
              <Grid
                item
                xs={2}
              >
                <Typography>
                  {networkData[id]?.houseLevel?.entranceNumber && (
                    <span style={{ fontWeight: 'bold' }}>
                      {`Под. № ${networkData[id]?.houseLevel?.entranceNumber} `}
                    </span>
                  )}
                  {networkData[id]?.houseLevel?.floorNumber &&
                    networkData[id]?.houseLevel?.floorType === HouseLevelFloorType.FLOOR && (
                      <span style={{ fontWeight: 'bold' }}>
                        {`Эт. ${networkData[id]?.houseLevel?.floorNumber} `}
                      </span>
                    )}
                  {networkData[id]?.houseLevel?.floorType &&
                    networkData[id]?.houseLevel?.floorType !== HouseLevelFloorType.FLOOR && (
                      <span style={{ fontWeight: 'bold' }}>
                        {floorTypes.get(networkData[id]?.houseLevel?.floorType ?? '')}
                      </span>
                    )}
                </Typography>
              </Grid>
            )}

          {/* данные об устройстве */}
          {networkData &&
            (networkData[id]?.number ||
              networkData[id]?.mac ||
              networkData[id]?.imei ||
              networkData[id]?.networkTypeId) && (
              <Grid
                item
                xs='auto'
              >
                <Typography>
                  <span>{networkData[id]?.number && `s: ${networkData[id].number} `}</span>
                  <span>{networkData[id]?.mac && `m: ${networkData[id].mac} `}</span>
                  <span>{networkData[id]?.imei && `i: ${networkData[id].imei} `}</span>
                  <span>
                    {networkData[id]?.networkTypeId &&
                      `t: ${getCatalogMapWithErr('networkEquipmentType').get(
                        networkData[id].networkTypeId
                      )} `}
                  </span>
                </Typography>
              </Grid>
            )}

          {/* модель устройства */}
          {networkData && networkData[id]?.model && (
            <Grid
              item
              xs='auto'
            >
              <Typography>{`Модель: ${networkData[id].model} `}</Typography>
            </Grid>
          )}

          {/* дата актирования */}
          {networkData && networkData[id]?.actOn && (
            <Grid
              item
              xs='auto'
            >
              <Typography>
                <span style={{ fontWeight: 'bold' }}>Актирование:</span>
                <span style={{ marginLeft: 5 }}>
                  {`${moment(networkData[id].actOn).format('DD.MM.yyyy')}`}
                </span>
              </Typography>
            </Grid>
          )}

          <Grid
            item
            sx={{ ml: 'auto' }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <ExpandButton
                expand={mode !== ModeViewerResult.SMALL}
                onAction={handleMode(ModeViewerResult.SMALL)}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      {(mode === ModeViewerResult.MEDIUM || mode === ModeViewerResult.LARGE) && (
        <Stack
          width={'100%'}
          spacing={2}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Stack
                direction={'row'}
                sx={{ alignItems: 'center' }}
              >
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Статус:</span>{' '}
                  {networkData &&
                    networkData[id]?.installStatus &&
                    getCatalogMapWithErr('networkElementStatus').get(
                      networkData[id]?.installStatus
                    )}
                </Typography>
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                  <IdPopup sx={{ color: 'gray' }}>
                    {id && (
                      <div>
                        <CopyButton textToCopy={id} />
                        id Network {id}
                      </div>
                    )}
                  </IdPopup>
                </div>
              </Stack>
            </Grid>
            <Grid
              xs={12}
              item
              sx={{ textAlign: 'right' }}
            >
              <DateInfoString
                date={networkData && networkData[id]?.ksSmr}
                title={'Дата акт. СМР: '}
              />{' '}
              <DateInfoString
                date={networkData && networkData[id]?.ksPnr}
                title={'ПНР: '}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='outlined-basic'
                label='Валидационное сообщение'
                variant='outlined'
                sx={{ mb: 0.3, width: '100%' }}
                disabled
                multiline
                maxRows={3}
                {...register(`${id}.validMsg`, {
                  value: networkData && networkData[id]?.validMsg,
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='outlined-basic'
                label='Комментарий'
                disabled={disabledFieldsAndButtons}
                variant='outlined'
                placeholder='Введите комментрарий'
                sx={{ mb: 0.3, width: '100%' }}
                {...register(`${id}.entranceComment`, {
                  value: networkData && networkData[id]?.entranceComment,
                })}
                multiline
                maxRows={3}
                defaultValue={networkData && networkData[id]?.entranceComment}
              />
            </Grid>
            <Grid
              item
              xs={2}
            >
              <AutocompleteValue
                title='Тип оборудования'
                fieldName={`${id}.networkTypeId`}
                defaultValue={networkData && networkData[id]?.networkTypeId}
                values={getCatalogMapWithErr('networkEquipmentType')}
                disabled={disabledFieldsAndButtons}
              />
            </Grid>
            <Grid
              item
              xs={2}
            >
              <TextField
                id='network-number'
                label='Заводской номер'
                disabled={disabledFieldsAndButtons}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${id}.number`, {
                  value: networkData && networkData[id]?.number,
                })}
              />
            </Grid>
            <Grid
              item
              xs={2}
            >
              <TextField
                id='network-type'
                label='Пломба на оборудовании'
                disabled={disabledFieldsAndButtons}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${id}.equipmentStomp`, {
                  value: networkData && networkData[id]?.equipmentStomp,
                })}
              />
            </Grid>
            <Grid
              item
              xs={1.5}
            >
              <AutocompleteValue
                title='Расположение'
                fieldName={`${id}.houseLevel.floorType`}
                defaultValue={networkData && networkData[id]?.houseLevel?.floorType}
                values={floorTypes}
                disabled={disabledFieldsAndButtons}
                disableClearable
                getOptionDisabled={(option) => option === HouseLevelFloorType.UNDEFINED}
                optionOnChangeValue={handleFloorTypeChange}
              />
            </Grid>
            <Grid
              item
              xs={1.5}
            >
              <TextField
                id='network-entrance'
                label='Подъезд'
                variant='standard'
                disabled={disabledFieldsAndButtons}
                sx={{ width: '100%' }}
                {...register(`${id}.houseLevel.entranceNumber`, {
                  value: networkData && networkData[id]?.houseLevel?.entranceNumber,
                })}
              />
            </Grid>
            <Grid
              item
              xs={1.5}
            >
              <TextField
                id='network-floor'
                label='Этаж'
                disabled={disabledFieldsAndButtons || !houseLevelFloorTypeSelected}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${id}.houseLevel.floorNumber`, {
                  value:
                    networkData &&
                    networkData[id]?.houseLevel?.floorType === HouseLevelFloorType.FLOOR
                      ? networkData[id]?.houseLevel?.floorNumber
                      : '',
                  required: watch(`${id}.houseLevel.floorType`) === HouseLevelFloorType.FLOOR,
                })}
                error={
                  watch(`${id}.houseLevel.floorType`) === HouseLevelFloorType.FLOOR &&
                  !!formState?.errors?.[id]?.houseLevel?.floorNumber
                }
              />
            </Grid>
            <Grid
              item
              xs={1.5}
            >
              <AutocompleteValue
                title='Место установки'
                fieldName={`${id}.montagePlaceId`}
                defaultValue={(networkData[id] ?? {}).montagePlaceId?.toString() ?? null}
                values={montagePlace}
                disabled={disabledFieldsAndButtons}
                disableClearable
              />
            </Grid>
            <Grid
              item
              xs={2}
            >
              <TextField
                id='network-type'
                label='MAC'
                disabled={disabledFieldsAndButtons}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${id}.mac`, {
                  value: networkData && networkData[id]?.mac,
                })}
              />
            </Grid>
            <Grid
              item
              xs={2}
            >
              <TextField
                id='network-type'
                label='IMEI'
                disabled={disabledFieldsAndButtons}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${id}.imei`, {
                  value: networkData && networkData[id]?.imei,
                })}
              />
            </Grid>
            <Grid
              item
              xs={2}
            >
              <TextField
                id='network-type'
                label='Порт'
                variant='standard'
                disabled={disabledFieldsAndButtons}
                sx={{ width: '100%' }}
                {...register(`${id}.port`, {
                  value: networkData && networkData[id]?.port,
                })}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <AccessController access={[AccessEnum.CastomPskClose]}>
                <TextField
                  id='network-type'
                  label='Исполнитель монтажа'
                  variant='standard'
                  disabled={disabledFieldsAndButtons}
                  sx={{ width: '100%' }}
                  {...register(`${id}.smrExecutor`, {
                    value: networkData && networkData[id]?.smrExecutor,
                  })}
                />
              </AccessController>
            </Grid>
            <Grid
              item
              xs={3}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  {...register(`${id}.smrDate`, {
                    value: networkData && networkData[id]?.smrDate,
                  })}
                  label='Дата монтажа'
                  disabled
                  inputFormat='dd.MM.yyyy'
                  value={date ? date : undefined}
                  onChange={onHandleDate}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: '100%' }}
                      {...params}
                      variant='standard'
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={2}
            >
              <AutocompleteValue
                title='Используется шкаф'
                fieldName={`${id}.isLockerUsed`}
                defaultValue={(networkData[id] ?? {}).isLockerUsed?.toString() ?? 'null'}
                values={optionIsLockerUsed}
                disableClearable
                sortFn={() => 1}
              />
            </Grid>
            <Grid
              item
              xs={12}
            />
            {networkData &&
              networkData[id]?.networkElementEquippedSimResponseList?.map(
                (elem: NetworkElementEquippedSimResponse) => (
                  <Grid
                    item
                    xs={6}
                    key={elem.id}
                  >
                    <SimItem sim={elem} />
                  </Grid>
                )
              )}
          </Grid>
          <ConsumableEquipment networkId={networkData[id].id} />

          <GSMBlockNew data={networkData[id].simChangeResponseList} />

          <Grid
            container
            item
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Grid>
              <Button
                variant='text'
                size='small'
                onClick={() => onCancel()}
                disabled={onlyShow || disabledFieldsAndButtons}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              display='flex'
              justifyContent='center'
              flex={1}
            >
              <Button
                variant='text'
                size='small'
                onClick={() => setOpenConfirm(true)}
                disabled={onlyShow}
              >
                Повторить установку
              </Button>
              <ConfirmationDialog
                open={openConfirm}
                title='Вы уверены хотите повторить установку?'
                handleCancel={() => setOpenConfirm(false)}
                handleOk={handleSubmit(onRepeat)}
                textForOk='да'
              />
            </Grid>

            <Grid
              gap={1}
              display='flex'
            >
              <SaveIcon
                onClick={handleSubmit(onSave)}
                sx={{
                  color: 'gray',
                  fontSize: 22,
                  cursor: disabledFieldsAndButtons ? 'not-allowed' : 'pointer',
                  ml: 2,
                }}
              />
              <ExpandButton
                expand={mode === ModeViewerResult.LARGE}
                onAction={handleMode(ModeViewerResult.LARGE)}
              />
            </Grid>
          </Grid>
        </Stack>
      )}
    </BoxShadow>
  );
};

export default React.memo(Network);
