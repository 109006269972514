import React, { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
  DetailedInfoButton,
  DetailedInfoButtonProps,
} from '@/components/button/DetailedInfo/DetailedInfoButton';
import styles from './FilterButtons.module.scss';
import { Box, IconButton } from '@mui/material';
import SaveButton from '@/components/button/SaveButton/SaveButton';
import { IAddToSaveButtonPopup } from '@/components/popups/AddToSaveButtonPopup/AddToSaveButtonPopup';
import { color } from '@/styles/mixins';
import AddIcon from '@mui/icons-material/Add';
import { ButtonsBox } from '@/components/inputs/DynamicFilter/CustomeStyleDynamicFilter.styled';

interface FilterButtonsProps
  extends Partial<DetailedInfoButtonProps>,
    Partial<IAddToSaveButtonPopup> {
  onCloseClick?: () => void;
  onSearchClick?: (data?: unknown) => void;
  addFilterInput?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
const FilterButtons = ({
  info,
  onCloseClick,
  onSearchClick,
  disable = false,
  saveFilter,
  addFilterInput,
}: FilterButtonsProps) => {
  return (
    <ButtonsBox className={styles.container}>
      {addFilterInput && (
        <IconButton
          disableRipple={true}
          onClick={addFilterInput}
        >
          <AddIcon style={{ fontSize: 36, color: color('muiBlue') }} />
        </IconButton>
      )}
      {onSearchClick && (
        <button
          onClick={disable ? (e) => e.preventDefault() : onSearchClick}
          style={{ cursor: disable ? 'not-allowed' : 'pointer' }}
        >
          <SearchIcon
            fontSize='large'
            style={{ color: color('muiBlue'), opacity: disable ? 0.5 : 1, transition: '0.3s' }}
          />
        </button>
      )}

      {info && (
        <button type='button'>
          <DetailedInfoButton
            info={info}
            disable={disable || !info?.length}
          />
        </button>
      )}

      {saveFilter && <SaveButton saveFilter={saveFilter} />}

      {onCloseClick && (
        <button
          onClick={onCloseClick}
          type='button'
        >
          <CloseIcon
            fontSize='large'
            style={{ color: color('muiBlue') }}
          />
        </button>
      )}
    </ButtonsBox>
  );
};

export default FilterButtons;
