import { Placemark } from 'react-yandex-maps';
import { NetworkResponse, Type, lookUp } from './UspdDto';
import iconUspd from '../../../images/icons/map/uspd.svg';
import iconUspdVacant from '../../../images/icons/map/uspd-vacant.svg';
import iconConnected from '../../../images/icons/map/connected.svg';
import highlightPYIcon from '../../../images/icons/map/highlightPY.svg';
import iconNotConnected from '../../../images/icons/map/not-connected.svg';
import newUSPD from '../../../images/icons/map/highlight-vacant.svg';

interface NetworkMapItemProps {
  item: NetworkResponse;
  type: string;
  handleDragEnd: (point: any, index: number, item: any) => void;
  index: number;
  isEditMode: boolean;
  setSelectedPoint: (point: any) => void;
  selectedPoint?: NetworkResponse | null | undefined;
  setIsEditMode: (flag: boolean) => void;
}

const getIcon = (
  type: string,
  selectedPoint: NetworkResponse | null | undefined,
  point: NetworkResponse
) => {
  if (type === Type.PU_CONNECTED && selectedPoint?.entities?.some((el) => el?.id === point?.id))
    return highlightPYIcon;
  if (type === Type.PU_CONNECTED) return iconConnected;
  if (type === Type.PU_UNCONNECTED) return iconNotConnected;
  if (type === Type.USPD_EXISTS) return iconUspd;
  if (type === Type.USPD_CREATED) return iconUspdVacant;
  if (type === Type.USPD_CREATED_NEW) return newUSPD;
};
const checkUSPD = (point: NetworkResponse) => {
  return point.type === Type.USPD_CREATED || point.type === Type.USPD_CREATED_NEW;
};

const getOptions = (
  point: NetworkResponse,
  isEditMode: boolean = false,
  selectedPoint: NetworkResponse | null | undefined
  // highlightPY: boolean
) => {
  return {
    iconLayout: 'default#image',
    iconImageHref: getIcon(point?.type || '', selectedPoint, point),
    iconImageSize: [30, 30],
    draggable: checkUSPD(point) && isEditMode && selectedPoint?.id === point.id,
  };
};

const renderHint = (point: NetworkResponse) => {
  return `
  <div style="position: relative; padding: 10px;">
    ${point.imei ? `<div>EMEI: ${point.imei && `${point.imei}`} </div>` : ''}
    ${point.type ? `<div>Тип: ${point.type && `${lookUp[point.type]}`} </div>` : ''}
    ${point.taskId ? ` <div>taskId: ${point.taskId} </div>` : ''}
  </div>
`;
};

export const NetworkMapItem = (props: NetworkMapItemProps) => {
  const {
    item,
    type,
    handleDragEnd,
    index,
    isEditMode,
    setSelectedPoint,
    selectedPoint,
    setIsEditMode,
  } = props;

  if (item.count) {
    return (
      <Placemark
        geometry={[item.longitudeX, item.latitudeY]}
        options={{
          preset: 'islands#blueStretchyIcon',
          iconColor: 'blue',
        }}
        properties={{
          point: item,
          balloonContentBody: renderHint(item),

          iconContent: `${lookUp[item.type]} - ${item.count}`,
        }}
      />
    );
  }

  const handleClickUspd = () => {
    if (checkUSPD(item)) {
      setSelectedPoint(item);
      setIsEditMode(true);
      return;
    } else if (item.type === Type.USPD_EXISTS) {
      setSelectedPoint(item);
      setIsEditMode(false);
    } else {
      setSelectedPoint(null);
      setIsEditMode(false);
    }
  };

  return (
    <>
      <Placemark
        onClick={handleClickUspd}
        onDragEnd={(e: any) => handleDragEnd(e, index, item)}
        geometry={[item.longitudeX, item.latitudeY]}
        properties={{
          point: item,
          balloonContentBody: renderHint(item),
        }}
        options={getOptions(item, isEditMode, selectedPoint)}
      />
    </>
  );
};
