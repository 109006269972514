import './app/sentry';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globalStyles.css';
import { App } from './App';
import { AuthProvider } from '@/app/auth/AuthProvider';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StyledSnackbarProvider } from '@/app/context/SnackbarProvider/SnackbarProvider.styled';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './components/common/ErrorBoundary/ErrorFallback';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AuthProvider>
        <StyledSnackbarProvider
          autoHideDuration={3000}
          maxSnack={3}
        >
          <ErrorBoundary fallback={<ErrorFallback />}>
            <App />
          </ErrorBoundary>
        </StyledSnackbarProvider>
      </AuthProvider>
    </BrowserRouter>
  </QueryClientProvider>
);
