import { Paper, styled } from '@mui/material';

export const SupplyBillBrowseFormWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 10,
  alignItems: 'flex-end',
  justifyContent: 'center',
  paddingBottom: 12,

  '.data-range-input': {
    fontSize: '1rem',
  },
});

export const SupplyBillBrowseFormPapper = styled(Paper)({
  width: '100%',
  padding: '10px',
  position: 'relative',
});

export const SupplyBillBrowsePaginationWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 0',
});

export const SupplyBillBrowseLinearProgressWrapper = styled('div')({
  width: '100%',
  height: 4,
});

interface SupplyBillBrowseItemWrapperProps {
  disabled?: boolean;
}

export const SupplyBillBrowseItemWrapper = styled('div')<SupplyBillBrowseItemWrapperProps>(
  ({ disabled }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ...(disabled && { ...{ opacity: 0.7, pointerEvents: 'none' } }),
  })
);

export const SupplyBillBrowseInputWrapper = styled('div')({
  width: 200,
});

export const SupplyBillBrowseDateRangeWrapper = styled('div')({
  width: 215,
});
