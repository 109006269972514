import * as React from 'react';
import DraggableListItem from './DraggableListItem';
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { ListItem } from '.';

export type DraggableListProps = {
  items: ListItem[];
  onDragEnd: OnDragEndResponder;
  handelDelete: (id: string) => void;
};

const DraggableList = React.memo(function DraggableList({
  items,
  onDragEnd,
  handelDelete,
}: DraggableListProps) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable-list'>
        {(provided) => (
          <div
            style={{ overflowY: 'auto', maxHeight: 200, height: 180 }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {items.map((item, index) => (
              <DraggableListItem
                item={item}
                index={index}
                key={item.id}
                handelDelete={handelDelete}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableList;
