import { usePnrPlanningStore } from '@/components/sections/PnrPlanning/store';
import { useAnchorElement } from '@/hooks/useAnchorElement';
import React, { useState } from 'react';
import {
  PnrElementsInfoContainer,
  PnrElementsInfoText,
  PnrElementsInfoCube,
  PnrElementWrapper,
  PnrElementsInfoWrapper,
  PnrElementsWrapper,
  PnrElementsInfoRowsWrapper,
  PnrElementsInfoMenu,
  PnrElementsInfoSmallText,
  PnrElementsLoading,
} from './PnrElementsInfo.styled';
import PnrElementsInfoRow from './PnrElementsInfoRow';
import shallow from 'zustand/shallow';
import PnrPlanning from '@/components/sections/PnrPlanning/PnrPlanning';
import { KEYS } from '@/hooks/useQuery/Keys';
import { useIsMutating } from 'react-query';
import { getOsIcon } from '@/components/features/pnr/constants';
import { usePNRStore } from '@/components/features/pnr/store';

const PnrElementsInfo = () => {
  const isFetchingOrders = useIsMutating(KEYS.pnrHouseOrders);
  const { selectedIndicator, networkElements } = usePNRStore(
    (state) => ({
      selectedIndicator: state.selectedIndicator,
      networkElements: state.networkElements,
    }),
    shallow
  );

  const [networkElementsCount, setNetworkElementsCount] = useState<{ [key: string]: number }>();
  const [plannedOrdersCount, setPlannedOrdersCount] = useState<{ [key: string]: number }>();
  const plannedOrders = usePnrPlanningStore((state) => state.plannedOrders);
  const { anchorEl, handleOpen, handleClose, open } = useAnchorElement();
  const {
    anchorEl: anchorOrders,
    handleOpen: handleOpenOrders,
    handleClose: handleCloseOrders,
    open: openOrders,
  } = useAnchorElement();

  React.useEffect(() => {
    if (!networkElements) setNetworkElementsCount({});
    const elementTypes = networkElements?.reduce((acc: { [key: string]: number }, obj) => {
      const typeId = obj.typeId || '1';
      acc[typeId] = (acc[typeId] || 0) + 1;
      return acc;
    }, {});

    setNetworkElementsCount(elementTypes);
  }, [networkElements]);

  React.useEffect(() => {
    if (!plannedOrders.get(selectedIndicator)) setPlannedOrdersCount({});
    const elementTypes = plannedOrders
      .get(selectedIndicator)
      ?.reduce((acc: { [key: string]: number }, obj) => {
        const typeId = obj.typeId || '1';
        acc[typeId] = (acc[typeId] || 0) + 1;
        return acc;
      }, {});

    setPlannedOrdersCount(elementTypes);
  }, [plannedOrders, selectedIndicator]);

  return (
    <PnrElementsInfoContainer>
      <PnrElementsInfoCube
        onClick={handleOpen}
        disabled={!Object.values(networkElementsCount || {})?.length}
      >
        <PnrElementsInfoWrapper>
          <PnrElementsInfoText>Установленное оборудование</PnrElementsInfoText>

          <PnrElementsWrapper>
            {Object.entries(networkElementsCount || {})?.map((networkElement) => (
              <PnrElementWrapper key={networkElement[0]}>
                {getOsIcon(networkElement[0])}
                <PnrElementsInfoSmallText>{networkElement[1]}</PnrElementsInfoSmallText>
              </PnrElementWrapper>
            ))}
          </PnrElementsWrapper>
        </PnrElementsInfoWrapper>
      </PnrElementsInfoCube>
      <PnrElementsInfoMenu
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
      >
        <PnrElementsInfoRowsWrapper>
          {networkElements?.map((element, index) => (
            <PnrElementsInfoRow
              element={element}
              index={index}
              key={element?.smrMac || '1' + index}
              order={false}
            />
          ))}
        </PnrElementsInfoRowsWrapper>
      </PnrElementsInfoMenu>
      <PnrElementsInfoCube
        onClick={handleOpenOrders}
        disabled={!Object.values(plannedOrdersCount || {})?.length}
      >
        <PnrElementsInfoText>Заявки</PnrElementsInfoText>

        <PnrElementsWrapper>
          <PnrElementsLoading loading={!!isFetchingOrders}>
            {Object.entries(plannedOrdersCount || {})?.map((plannedOrder) => (
              <PnrElementWrapper key={plannedOrder[0]}>
                {getOsIcon(plannedOrder[0])}
                <PnrElementsInfoSmallText>{plannedOrder[1]}</PnrElementsInfoSmallText>
              </PnrElementWrapper>
            ))}
          </PnrElementsLoading>
        </PnrElementsWrapper>
      </PnrElementsInfoCube>
      <PnrElementsInfoMenu
        anchorEl={anchorOrders}
        open={openOrders}
        onClose={() => handleCloseOrders()}
      >
        <PnrElementsInfoRowsWrapper>
          {plannedOrders.get(selectedIndicator)?.map((element, index) => (
            <PnrElementsInfoRow
              order
              element={element}
              index={index}
              key={element?.taskId || '1' + index}
            />
          ))}
        </PnrElementsInfoRowsWrapper>
      </PnrElementsInfoMenu>

      <PnrPlanning />
    </PnrElementsInfoContainer>
  );
};

export default PnrElementsInfo;
