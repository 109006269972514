import { Box, Tab } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ViewMapManagerTab } from './ViewMapManagerTab';
import { SearchMapManagerTab } from './SearchMapManagerTab';
import { useGeoCard } from '@/components/maps/pnrMap/hooks/GeoCardHook';
import { useGeoQuery } from '@/components/features/pnr/hooks/GeoQueryHook';
import { useMap } from '@/components/features/pnr/hooks/MapHook';
import { useResArea } from '@/components/maps/pnrMap/hooks/ResAreaHook';

const MapManagetTabType = {
  VIEW: 'viewTab',
  SEARCH: 'searchTab',
};

const MapManager = forwardRef(() => {
  const { currentTaskFilterParam, currentAdditionalParam } = useGeoQuery();
  const { removeAllFromGeoCard } = useGeoCard();
  const [tabValue, setTabValue] = useState(MapManagetTabType.VIEW);
  const { cleanResArea } = useResArea();
  const { removeAllGeoCircle } = useMap();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    removeAllFromGeoCard();
    cleanResArea();
    removeAllGeoCircle();
  }, [currentTaskFilterParam]);

  return (
    <TabContext value={tabValue}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={handleChange}
          aria-label='map manager tabs'
        >
          <Tab
            label='Обзор'
            value={MapManagetTabType.VIEW}
          />
          <Tab
            label='Поиск'
            value={MapManagetTabType.SEARCH}
          />
        </TabList>
      </Box>
      <TabPanel value={MapManagetTabType.VIEW}>
        <ViewMapManagerTab />
      </TabPanel>
      <TabPanel value={MapManagetTabType.SEARCH}>
        <SearchMapManagerTab
          additionalType={currentAdditionalParam?.type}
          additioanlValue={currentAdditionalParam?.value}
        />
      </TabPanel>
    </TabContext>
  );
});

MapManager.displayName = 'MapManager';
export default MapManager;
