import inList from '../../../images/icons/in-list.svg';
import notInList from '../../../images/icons/not-in-list.svg';
import installed from '../../../images/icons/installed.svg';
import equal from '../../../images/icons/equal.svg';
import notEqual from '../../../images/icons/not-equal.svg';
import more from '../../../images/icons/more.svg';
import less from '../../../images/icons/less.svg';
import contain from '../../../images/icons/contain.svg';
import range from '../../../images/icons/range.svg';
import { PhotPath, FilterArgEnum } from './types';

export const iconsPathMap = new Map<FilterArgEnum, PhotPath>([
  [FilterArgEnum.IN_LIST, { path: inList, title: 'В списке' }],
  [FilterArgEnum.NOT_IN_LIST, { path: notInList, title: 'Вне списка' }],
  [FilterArgEnum.EQUAL, { path: equal, title: 'Равно' }],
  [FilterArgEnum.NOT_EQUAL, { path: notEqual, title: 'Не равно' }],
  [FilterArgEnum.CONTAIN, { path: contain, title: 'Содержит' }],
  [FilterArgEnum.MORE, { path: more, title: 'Больше' }],
  [FilterArgEnum.LESS, { path: less, title: 'Меньше' }],
  [FilterArgEnum.INSTALLED, { path: installed, title: 'Установлено' }],
  [FilterArgEnum.RANGE, { path: range, title: 'В промежутке' }],
]);

export function getIconsPath(argum: FilterArgEnum): PhotPath {
  let pt = iconsPathMap.get(argum);
  return pt ? pt : { path: '', title: 'не известно' };
}
