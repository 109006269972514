import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import { TaskHintAttributeList } from '@/dto/taskmap/Dto';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: 500,
  overflow: 'auto',
};

export function loadSelectedAttributeList(): string[] {
  const val = localStorage.getItem('pnr-task-attribute-list');
  if (val) {
    return JSON.parse(val);
  }
  return [];
}

function saveSelectedAttributeList(list: string[]) {
  localStorage.setItem('pnr-task-attribute-list', JSON.stringify(list));
}

export const MapPnrSetting = (props: any) => {
  const [open, setOpen] = useState(false);
  const [selectedAttributeList, setSelectedAttributeList] = useState<string[]>([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };

  const onSelectAttribute = (event: any) => {
    const val = event.target.value;
    const exists = loadSelectedAttributeList();
    const index = exists.indexOf(val);
    if (index > -1) {
      exists.splice(index, 1);
    } else {
      exists.push(val);
    }
    setSelectedAttributeList(exists);
    saveSelectedAttributeList(exists);
  };

  useEffect(() => {
    setOpen(props.open);
    setSelectedAttributeList(loadSelectedAttributeList());
  }, [props.open]);

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
    >
      <Box sx={style}>
        <Typography
          id='keep-mounted-modal-title'
          variant='h6'
          component='h2'
        >
          Показать атрибуты
        </Typography>
        <FormGroup>
          {selectedAttributeList &&
            Object.keys(TaskHintAttributeList).map((t: any) => (
              <FormControlLabel
                control={<Checkbox />}
                onChange={onSelectAttribute}
                checked={selectedAttributeList.indexOf(t) > -1}
                value={t}
                key={t}
                label={TaskHintAttributeList[t]}
              />
            ))}
        </FormGroup>
      </Box>
    </Modal>
  );
};
