import { GridColDef } from '@mui/x-data-grid';
import { createStore } from '@/utils/createStore';
import { getColumns, CatalogTypes } from './constants';

interface Col {
  columns: {
    // [CatalogTypes.METER]: GridColDef[];
    [CatalogTypes.KOO]: GridColDef[];
    [CatalogTypes.SIM]: GridColDef[];
    [CatalogTypes.STAMP]: GridColDef[];
    [CatalogTypes.PU]: GridColDef[];
  };

  setCurrentColumns: (columns: GridColDef[], type: CatalogTypes) => void;
}

export const useCol = createStore<Col>(
  (set, get) => ({
    columns: {
      // [CatalogTypes.METER]: [...getColumns(CatalogTypes.METER)],
      [CatalogTypes.KOO]: [...getColumns(CatalogTypes.KOO)],
      [CatalogTypes.SIM]: [...getColumns(CatalogTypes.SIM)],
      [CatalogTypes.STAMP]: [...getColumns(CatalogTypes.STAMP)],
      [CatalogTypes.PU]: [...getColumns(CatalogTypes.PU)],
    },

    setCurrentColumns: (newColumns, type) =>
      set(() => ({
        columns: { ...get().columns, [type]: newColumns },
      })),
  }),
  'ColCatalog',
  'dataGridColCatalog',
  {
    version: 2,
    migrate: (persistedState: unknown, version: number) => {
      if (version !== 2) {
        if ((persistedState as Col)?.columns) {
          (persistedState as Col).columns = {
            // [CatalogTypes.METER]: [...getColumns(CatalogTypes.METER)],
            [CatalogTypes.KOO]: [...getColumns(CatalogTypes.KOO)],
            [CatalogTypes.SIM]: [...getColumns(CatalogTypes.SIM)],
            [CatalogTypes.STAMP]: [...getColumns(CatalogTypes.STAMP)],
            [CatalogTypes.PU]: [...getColumns(CatalogTypes.PU)],
          };
        }
      }

      return persistedState as Col;
    },
  }
);
