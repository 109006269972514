import React from 'react';
import { Popup } from '../Popup';
import { useExpendableToolsPopup } from './store';
import {
  ExpendableToolsPopupColumn,
  ExpendableToolsPopupTitle,
} from './ExpendableToolsPopup.styled';
import CablePopupForm from './ExpendableToolsPopupForm';

export const ExpendableToolsPopup: React.FC = () => {
  const { isOpen, setIsOpen } = useExpendableToolsPopup((state) => ({
    isOpen: state.isExpendableToolsPopupOpen,
    setIsOpen: state.setIsExpendableToolsPopupOpen,
  }));

  const handleClose = () => setIsOpen(false);

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      backdropStyles={{ background: 'rgba(0, 0, 0, 0.29)' }}
    >
      <ExpendableToolsPopupColumn>
        <ExpendableToolsPopupTitle>Дополнительное оборудование</ExpendableToolsPopupTitle>
        <CablePopupForm />
      </ExpendableToolsPopupColumn>
    </Popup>
  );
};
