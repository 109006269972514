import { Box, styled } from '@mui/material';
import CommonModal from '@/components/common/CommonModal/CommonModal';
import { LoadingButton } from '@mui/lab';
import { font } from '@/styles/mixins';

export const ComplaintReportContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,

  '.parent': {
    marginTop: '40px !important',
    marginRight: '0 !important',
  },

  '.child ': {
    flexDirection: 'row !important',
  },
});

export const ComplaintReportModal = styled(CommonModal)({
  minWidth: '30%',

  '.CommonModalInner': {
    overflow: 'unset',
  },
});
