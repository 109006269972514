import React, { MutableRefObject, useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { DataGrid, GridCellEditCommitParams, GridColDef, ruRU } from '@mui/x-data-grid';
import { SupplyNetworkElementItem } from '@/dto/taskmap/Dto';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';
import useSupplyStore from '../SupplyStore';
import { generateRowNum, generateScanId } from '../utils';
import { generateOsRemoveColumns } from './utils';
import {
  SupplyPUModalInner,
  PURemoveScannerModalButtonsWrapper,
  PURemoveScannerModalFormWrapper,
  PURemoveScannerModalInner,
  PURemoveScannerScannButton,
} from '../PURemoveScanner/PURemoveScanner.styled';
import {
  SupplyNetworkElementScanField,
  SupplyNetworkElementTypeResponse,
} from '@/dto/SupplyBillServiceDto';
import { updateRowNums } from '../SupplyPUComponent/utils';

interface OSRemoveScannerProps {
  removeItems: (itemsToRemove: SupplyNetworkElementItem[], typeId: number) => void;
  disabled?: boolean;
  meterTypes: SelectFilterOptType[];
  supplyMeterTypes: Map<number, SupplyNetworkElementTypeResponse>;
}

export default function OSRemoveScanner(props: OSRemoveScannerProps) {
  const billNumber = useSupplyStore((state) => state.billNumber);
  const refButton = useRef() as MutableRefObject<HTMLButtonElement>;

  const [selectedMeterType, setSelectedMeterType] = useState<SelectFilterOptType | null>(null);
  const [osListItems, setOSListItems] = useState<SupplyNetworkElementItem[]>([]);
  const [open, setOpen] = useState(false);
  const [openMeterTypeDialog, setOpenMeterTypeDialog] = useState(false);

  const supplyMeterType = props?.supplyMeterTypes.get(selectedMeterType?.value ?? -1);
  const macImeiScan = supplyMeterType?.typeScanField === SupplyNetworkElementScanField.MAC_AND_IMEI;

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedMeterType(null);
    setOSListItems([]);
    setOpen(false);
  };
  const handleMeterTypeDialogOpen = () => setOpenMeterTypeDialog(true);
  const handleMeterTypeDialogClose = () => setOpenMeterTypeDialog(false);

  const columns = useMemo(
    () =>
      [
        ...generateOsRemoveColumns(supplyMeterType?.typeScanField),
        {
          field: 'action',
          headerName: 'Удалить',
          renderCell: (params) => {
            return (
              <Button
                onClick={() => {
                  removeRow(params.row.id);
                }}
              >
                Удалить
              </Button>
            );
          },
        },
      ] as GridColDef[],
    [selectedMeterType]
  );

  const addNewRow = () => {
    const rowScanId = generateScanId(osListItems);
    const rowNumId = generateRowNum(osListItems);
    const newOSList = osListItems.concat({
      tmpId: rowScanId,
      rowNum: rowNumId,
      typeId: Number(selectedMeterType?.value),
      typeName: selectedMeterType?.label ?? '',
      imei: '',
      mac: '',
      id: null,
    });
    setOSListItems(newOSList);
  };

  const removeRow = (rowId: number) => {
    const maxId = Math.max(...osListItems.map((o) => o.tmpId));
    if (osListItems.length > 1 && rowId !== maxId) {
      const arr = [...osListItems];
      const scanIndex = arr.indexOf(arr.find((it) => it.tmpId === rowId)!);
      if (scanIndex !== -1) {
        arr.splice(scanIndex, 1);
        setOSListItems(updateRowNums(arr));
      }
    }
  };

  const commitNewRow = (params: GridCellEditCommitParams) => {
    const item = osListItems.find((it) => it.rowNum === params.id)!;
    item[params.field as 'mac' | 'imei'] = params.value;

    if (
      item?.typeId &&
      ((item.mac && item.mac?.length > 0) || (item.imei && item.imei?.length > 0))
    ) {
      const arr = [...osListItems];
      setOSListItems(arr);
    }
  };

  const removeItems = () => {
    refButton.current.focus();

    // Фикс onCellEditCommit не отрабатывает, если нажать кнопку сохранить без нажатие Enter
    // при ручном вводе номера
    setTimeout(() => {
      props.removeItems(osListItems, Number(selectedMeterType?.value));
      setOSListItems([]);
      setSelectedMeterType(null);
      handleClose();
    }, 0);
  };

  const handleStartScanClick = () => {
    addNewRow();
    handleMeterTypeDialogClose();
    handleOpen();
  };

  return (
    <div>
      <PURemoveScannerScannButton
        disabled={props.disabled}
        variant='contained'
        onClick={handleMeterTypeDialogOpen}
      >
        Удалить оборудование через сканирование
      </PURemoveScannerScannButton>
      <Modal
        open={openMeterTypeDialog}
        onClose={handleMeterTypeDialogClose}
      >
        <PURemoveScannerModalInner>
          <PURemoveScannerModalFormWrapper>
            <Typography variant='h6'>{'Выберите тип оборудования'}</Typography>
            <Autocomplete
              disablePortal
              value={selectedMeterType}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={props.meterTypes}
              sx={{ width: 200 }}
              onChange={(e, value) => setSelectedMeterType(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Тип'
                  variant='standard'
                />
              )}
            />
          </PURemoveScannerModalFormWrapper>
          <PURemoveScannerModalButtonsWrapper>
            <Button
              variant='contained'
              onClick={handleStartScanClick}
            >
              Начать сканирование
            </Button>
            <Button
              variant='contained'
              onClick={() => handleMeterTypeDialogClose()}
            >
              Отмена
            </Button>
          </PURemoveScannerModalButtonsWrapper>
        </PURemoveScannerModalInner>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <SupplyPUModalInner>
          <Grid
            container
            item
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-end'
            wrap='nowrap'
            width={'100%'}
            marginBottom={3}
          >
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                <h3 style={{ marginLeft: 5, fontSize: 38 }}>
                  {'Внимание! Просканированое сетевое оборудование будет удалено: ' + billNumber}
                </h3>
              </div>

              <Button
                variant='contained'
                sx={{ marginRight: 3 }}
                onClick={() => removeItems()}
                ref={refButton}
              >
                Сохранить
              </Button>
              <Button
                variant='outlined'
                onClick={handleClose}
              >
                Закрыть
              </Button>
            </div>
          </Grid>
          <DataGrid
            sx={{ height: '80%' }}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            rows={osListItems}
            disableColumnMenu
            getRowId={(row: any) => row.rowNum}
            columns={columns}
            onCellEditCommit={(params) => {
              commitNewRow(params);
            }}
          />
        </SupplyPUModalInner>
      </Modal>
    </div>
  );
}
