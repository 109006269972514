import { styled } from '@mui/material/styles';

export const DownloadAllLoadingWrapper = styled('div')({
  width: '48px',
  height: '48px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const DownloadAllButtonWrapper = styled('label')({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
});
