import { Autocomplete, Button, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styles from './Popup.module.scss';
import { ApiAssignContractRequest } from '@/services/YodaRestService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { IPopup, Popup } from './Popup';
import { useAllContractQuery } from '@/hooks/useQuery/useAllContractQuery';
import { FetchCathError } from '@/types/api';

interface Props extends IPopup {
  taskIds: string[];
  contractsList: any[];
}

export interface AssignContractForm {
  contractId?: string;
}

export const AssignContractPopup: React.FC<Props> = (props) => {
  const { taskIds, onClose, onSuccess } = props;
  const { control, handleSubmit, reset } = useForm<AssignContractForm>();
  const contractListResponse = useAllContractQuery();
  const { fetchCatch, addActionLog } = useActionLog();

  useEffect(() => {
    reset();
  }, [props.isOpen]);

  const requestAssign = (values?: AssignContractForm) => {
    const newContractId = values?.contractId;
    ApiAssignContractRequest({
      taskIds,
      ...(newContractId ? { contractId: newContractId } : { contractId: null }),
    })
      .then(() => {
        addActionLog(
          ActionLogType.SUCCESS,
          newContractId ? 'Контракт назначен успешно' : 'Контракт снят успешно'
        );
        onClose();
        onSuccess && onSuccess();
      })
      .catch((err: FetchCathError) => fetchCatch(err));
  };

  const onAssign = handleSubmit((values) => requestAssign(values));
  const onCancelContract = handleSubmit(() => requestAssign());

  return (
    <Popup {...props}>
      <div className={styles.item}>
        <span>Контракт</span>
        <Controller
          control={control}
          name='contractId'
          render={({ field }) => (
            <Autocomplete
              options={contractListResponse}
              style={{ width: '50%' }}
              onChange={(event: any, newValue: any) => field?.onChange(newValue?.value)}
              renderInput={(params) => (
                <TextField
                  name='contractId'
                  {...params}
                  label={'Контракт'}
                  variant='standard'
                />
              )}
            />
          )}
        />
      </div>
      <div className={styles['modal-buttons']}>
        <Button
          onClick={onAssign}
          variant='contained'
        >
          Назначить
        </Button>
        <Button
          onClick={onCancelContract}
          variant='contained'
        >
          Снять
        </Button>
      </div>
    </Popup>
  );
};
