import { useDisabledAccess } from '@/hooks/useAccess';
import { RolesEnum } from '@/app/auth/roles';

export const useDisabledPnrPlanningButtons = () => {
  return useDisabledAccess({
    disabledRoles: [
      RolesEnum.Executor,
      RolesEnum.SupplyInfo,
      RolesEnum.TeamManager,
      RolesEnum.TeamManagerWithUSPDMap,
    ],
  });
};
