import { axiosGet, axiosPost } from './helpers';
import axios, { AxiosResponse } from 'axios';

function getHostRp() {
  const HOST_RP = import.meta.env.VITE_RP_DASHBOARD_BASE_URL;
  if (typeof HOST_RP == 'undefined') {
    alert('Адрес сервера для запросов к API не задан');
  } else return HOST_RP as string;
}

export const HOST_RP = getHostRp();

export async function GetTableRP(payLoad: any): Promise<AxiosResponse<any, any>> {
  const url = HOST_RP + '/aggregation/execute';

  return axiosPost(url, payLoad);
}

export async function GetCatalogForRP(fieldName: string): Promise<AxiosResponse<any, any>> {
  const url = HOST_RP + `/aggregation/catalog/${fieldName}`;

  return axiosGet(url);
}
