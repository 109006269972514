import { useActionLog } from '@/hooks/ActionLogHook';
import { getCallCenterResultType } from '@/services/ComplaintService/ComplaintService';
import { useQuery } from 'react-query';

export enum ECallCenterResult {
  NOT_RELEVANT = 'NOT_RELEVANT',
  NO_CONTACT_DETAILS = 'NO_CONTACT_DETAILS',
  NO_CALL = 'NO_CALL',
  CONSUMER_NOT_READY_TO_MEET = 'CONSUMER_NOT_READY_TO_MEET',
  READY_TO_GO = 'READY_TO_GO',
}

export const useAllCallcenterResults = () => {
  const { fetchWithUserMessageFlagCatch } = useActionLog();
  return useQuery({
    queryKey: ['complaints', 'call_center_results'],
    staleTime: Infinity,
    queryFn: getCallCenterResultType,
    onError: (error) => fetchWithUserMessageFlagCatch(error),
  });
};
