import EditRoundedIcon from '@mui/icons-material/EditRounded';
import FaceRetouchingNaturalRoundedIcon from '@mui/icons-material/FaceRetouchingNaturalRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import * as React from 'react';
import { RegistryEditPopup, RegistryEditPopupType } from '@/components/popups/RegistryEditPopup';
import { useState } from 'react';
import { TableTopToolbar } from '@/components/features/registryEditor/TableTopToolbar';
import { Tooltip, IconButton } from '@mui/material';

interface EnhancedTableToolbarProps {
  numSelected: number;
}

export const TableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;
  const [isOpenAll, setIsOpenAll] = useState<boolean>(false);
  const [isOpenDate, setIsOpenDate] = useState<boolean>(false);
  const [isOpenEx, setIsOpenEx] = useState<boolean>(false);

  return (
    <>
      <RegistryEditPopup
        type={RegistryEditPopupType.ALL}
        isOpen={isOpenAll}
        setIsOpen={setIsOpenAll}
      />
      <RegistryEditPopup
        type={RegistryEditPopupType.DATE}
        isOpen={isOpenDate}
        setIsOpen={setIsOpenDate}
      />
      <RegistryEditPopup
        type={RegistryEditPopupType.EXECUTOR}
        isOpen={isOpenEx}
        setIsOpen={setIsOpenEx}
      />
      <TableTopToolbar numSelected={numSelected}>
        <>
          <Tooltip title='Редактировать дату'>
            <IconButton onClick={() => setIsOpenDate(true)}>
              <EventAvailableRoundedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Редактировать исполнителя'>
            <IconButton onClick={() => setIsOpenEx(true)}>
              <FaceRetouchingNaturalRoundedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Редактировать все'>
            <IconButton onClick={() => setIsOpenAll(true)}>
              <EditRoundedIcon />
            </IconButton>
          </Tooltip>
        </>
      </TableTopToolbar>
    </>
  );
};
