import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StatisticMoreThanOneApealFiltersWrapper = styled('div')({
  padding: '0px 0px 0px 5vw',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  flexWrap: 'wrap',
  gap: 6,
  marginTop: 16,
  marginBottom: 16,
});

export const StatisticMoreThanOneApealDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeaderTitle': {
    textOverflow: 'clip',
    whiteSpace: 'break-spaces',
    lineHeight: 1,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});
