import { color } from '@/styles/mixins';
import { styled, Typography } from '@mui/material';
import FrontendVersion from '@/components/widgets/FrontendVersion/FrontendVersion';

export const ErrorText = styled(Typography)({
  textAlign: 'center',
  color: color('textGray'),
});

export const ErrorButtonsWrapper = styled('div')({
  display: 'flex',
  rowGapp: 20,
  columnGap: 5,
  alignItems: 'center',
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'center',

  svg: {
    marginBottom: '0.1rem',
  },
});

export const ErrorFallbackFrontendVersion = styled(FrontendVersion)({
  marginTop: 10,

  '.MuiTypography-root': {
    color: color('textGray'),
  },
});
