import React from 'react';
import { Popup } from '../Popup';
import { useCablePopup } from './store';
import { CablePopupColumn, CablePopupTitle } from './CablePopup.styled';
import CablePopupForm from './CablePopupForm';

export const CablePopup: React.FC = () => {
  const { isOpen, setIsOpen } = useCablePopup((state) => ({
    isOpen: state.isCablePopupOpen,
    setIsOpen: state.setIsCablePopupOpen,
  }));

  const handleClose = () => setIsOpen(false);

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      backdropStyles={{ background: 'rgba(0, 0, 0, 0.29)' }}
    >
      <CablePopupColumn>
        <CablePopupTitle>Кабель</CablePopupTitle>

        <CablePopupForm />
      </CablePopupColumn>
    </Popup>
  );
};
