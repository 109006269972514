import create from 'zustand';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { devtools } from 'zustand/middleware';
import { createStore } from '../../../utils/createStore';

interface DateArg {
  value: number[];
  argValue: FilterArgEnum;
}

export type IDateInput = {
  date: DateArg;
};

type ObjectKey<Obj> = keyof Obj;
export type DateInputKeys = ObjectKey<IDateInput>;

type IFunks = {
  setDateValue: (key: DateInputKeys) => (value: number[]) => void;
  setDateValueArg: (key: DateInputKeys) => (argValue: FilterArgEnum) => void;
  setDefaultValue: () => void;
};
export type IStore = IFunks & IDateInput;

const defaultValue: IDateInput = {
  date: { value: [], argValue: FilterArgEnum.RANGE },
};

export const useRegistryEnforseFilter = createStore<IStore>(
  (set, get) => ({
    ...defaultValue,

    setDefaultValue: () =>
      set(() => ({
        ...defaultValue,
      })),

    setDateValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setDateValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.RANGE || get()[key].argValue === FilterArgEnum.RANGE) {
        set(() => ({
          [key]: {
            value: [],
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },
  }),
  'Registry enforse filter'
);
