import { styled, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const SmallExtraTableWrapper = styled('div')({
  height: '150px',
  width: '75%',
  margin: 'auto',
  paddingBottom: '30px',
  display: 'flex',
  flexDirection: 'row-reverse',
  position: 'relative',
});

export const StatisticsResponsebleDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeaderTitle': {
    textOverflow: 'clip',
    whiteSpace: 'break-spaces',
    lineHeight: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '13px !important',
  },
  '& .super--cell': {
    backgroundColor: '#e7f7e7',
    fontWeight: '700',
  },
});

export const StatisticsResponsebleWrapper = styled(Paper)({
  paddingBottom: 20,
});

export const StatisticsResponsebleFiltersWrapper = styled('div')({
  padding: '0px 0px 0px 5vw',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  flexWrap: 'wrap',
  gap: 6,
  marginTop: 16,
});
