import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { createStore } from '@/utils/createStore';
import { transformToLowerCase } from './utils';

export interface SelectValue {
  value: string;
  argValue?: FilterArgEnum;
  label: string;
}
export interface SingleArg {
  value: string;
  argValue: FilterArgEnum;
}
export interface DateArg {
  value: number[];
  argValue: FilterArgEnum;
}

export interface MultiArg {
  value: SelectFilterOptType[];
  argValue: FilterArgEnum;
}

export type IDateInput = {
  billDate: DateArg;
};

export type ISingleInput = {
  billNumber: SingleArg;
  puNumber: SingleArg;
  osNumber: SingleArg;
  stampNumber: SingleArg;
};
export type IMultiInput = {
  billType: MultiArg;
  puTypes: MultiArg;
  osTypes: MultiArg;
  supplyId: MultiArg;
  responsables: MultiArg;
  billCreatorList: MultiArg;
  billEditorList: MultiArg;
};

export type IPageData = {
  page: number;
};

type ObjectKey<Obj> = keyof Obj;
export type SingleInputKeys = ObjectKey<ISingleInput>;
export type MultiInputKeys = ObjectKey<IMultiInput>;
export type DateInputKeys = ObjectKey<IDateInput>;

export type AllInputKeys = SingleInputKeys | MultiInputKeys | DateInputKeys;

type ITasksBrowseFunks = {
  setSingleValue: (key: SingleInputKeys) => (value: string) => void;
  setSingleValueArg: (key: SingleInputKeys) => (argValue: FilterArgEnum) => void;
  setMultiValue: (key: MultiInputKeys) => (value: SelectFilterOptType[]) => void;
  setMultiValueArg: (key: MultiInputKeys) => (argValue: FilterArgEnum) => void;
  setDateValue: (key: DateInputKeys) => (value: number[]) => void;
  setDateValueArg: (key: DateInputKeys) => (argValue: FilterArgEnum) => void;
  setDefaultValue: () => void;
  setPage: (page: number) => void;
};
export type ISupplyBillBrowseStore = ITasksBrowseFunks &
  ISingleInput &
  IMultiInput &
  IDateInput &
  IPageData;

export const defaultValueSingleInputs: ISingleInput = {
  billNumber: { value: '', argValue: FilterArgEnum.CONTAIN },
  puNumber: { value: '', argValue: FilterArgEnum.EQUAL },
  osNumber: { value: '', argValue: FilterArgEnum.EQUAL },
  stampNumber: { value: '', argValue: FilterArgEnum.EQUAL },
};

export const defaultValueMultiInput: IMultiInput = {
  puTypes: { value: [], argValue: FilterArgEnum.IN_LIST },
  osTypes: { value: [], argValue: FilterArgEnum.IN_LIST },
  billType: { value: [], argValue: FilterArgEnum.IN_LIST },
  supplyId: { value: [], argValue: FilterArgEnum.IN_LIST },
  responsables: { value: [], argValue: FilterArgEnum.IN_LIST },
  billCreatorList: { value: [], argValue: FilterArgEnum.IN_LIST },
  billEditorList: { value: [], argValue: FilterArgEnum.IN_LIST },
};

export const defaultValueDateInput: IDateInput = {
  billDate: { value: [], argValue: FilterArgEnum.EQUAL },
};

export const pageData = {
  page: 1,
};
const defaultValue: ISingleInput & IMultiInput & IDateInput & IPageData = {
  ...defaultValueSingleInputs,
  ...defaultValueMultiInput,
  ...defaultValueDateInput,
  ...pageData,
};

export const useSupplyBillBrowseFilter = createStore<ISupplyBillBrowseStore>(
  (set, get) => ({
    ...defaultValue,

    setDefaultValue: () =>
      set(() => ({
        ...defaultValue,
      })),

    setSingleValue: (key) => (value) => {
      set((state) => ({
        [key]: {
          ...state[key],
          value:
            get()[key].argValue === FilterArgEnum.INSTALLED ? value : transformToLowerCase(value),
        },
      }));
    },

    setSingleValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.INSTALLED || get()[key].argValue === FilterArgEnum.INSTALLED) {
        set(() => ({
          [key]: {
            value: '',
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },

    setDateValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setDateValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.RANGE || get()[key].argValue === FilterArgEnum.RANGE) {
        set(() => ({
          [key]: {
            value: [],
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },

    setMultiValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setMultiValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.INSTALLED || get()[key].argValue === FilterArgEnum.INSTALLED) {
        set((state) => ({
          [key]: {
            value: [],
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },
    setPage: (page) => set(() => ({ page })),
  }),
  'Supply bill browse'
);
