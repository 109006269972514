import { DateObject } from 'react-multi-date-picker';

export const monthsDataRange = [
  ['Январь', 'Янв.'],
  ['Февраль', 'Февр.'],
  ['Март', 'Март'],
  ['Апрель', 'Апр.'],
  ['Май', 'Май'],
  ['Июнь', 'Июнь'],
  ['Июль', 'Июль'],
  ['Август', 'Авг.'],
  ['Сентябрь', 'Сент.'],
  ['Октябрь', 'Окт.'],
  ['Ноябрь', 'Нояб.'],
  ['Декабрь', 'Дек.'],
];
export const weekDaysDataRange = [
  ['Воскресение', 'Вс'],
  ['Понедельник', 'Пн'],
  ['Вторник', 'Вт'],
  ['Среда', 'Ср'],
  ['Четверг', 'Чт'],
  ['Пятница', 'Пт'],
  ['Суббота', 'Сб'],
];

export const convertDateObjectToDate = (dateObject: DateObject): Date => {
  return new Date(dateObject.valueOf());
};

export const convertDateToDateObject = (date: Date): DateObject => {
  return new DateObject(date.valueOf());
};
