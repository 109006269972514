export const EMBEDDED_STATISTICS_BAR_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  width: 100,
  height: 500,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: false,
      stacked: true,
    },
    y: {
      stacked: true,
      display: false,
    },
  },
};

export const EMBEDDED_STATISTICS_PIE_OPTIONS = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      display: false,
    },
  },
};
