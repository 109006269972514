import { FormProvider, useForm } from 'react-hook-form';
import { AccessPopup } from '../../../../popups/AccessPopup/AccessPopup';
import { Grid, TextField, Typography } from '@mui/material';
import { useNetworkStore } from './store';
import { useEffect } from 'react';

interface EditConsumableEquipmentprops {
  isOpen: boolean;
  onClose: () => void;
  networkId: string;
}

const EditConsumableEquipment = ({ isOpen, onClose, networkId }: EditConsumableEquipmentprops) => {
  const methods = useForm();
  const { register, handleSubmit, reset } = methods;
  const { consumableEquipment, updateConsumableEquipment } = useNetworkStore((store) => ({
    consumableEquipment: store.consumableEquipment,
    updateConsumableEquipment: store.updateConsumableEquipment,
  }));

  const handleSuccessEdite = (data: Record<string, any>) => {
    const dataToSend = Object.entries(data).map(([key, val]) => ({
      id: key,
      quantity: val !== undefined && val !== '' ? parseFloat(val) : 0.0,
    }));
    updateConsumableEquipment(networkId, dataToSend);
    onClose();
    reset();
  };

  useEffect(() => {
    reset();
  }, [consumableEquipment[networkId]]);
  return (
    <AccessPopup
      isOpen={isOpen}
      onClose={onClose}
      btnVariant='text'
      onSuccess={handleSubmit(handleSuccessEdite)}
      titleText={'Внесите изменения'}
      closeText={'Отмена'}
      successText={'Сохранить'}
    >
      <FormProvider {...methods}>
        <Grid
          container
          spacing={1}
          xs={12}
        >
          {consumableEquipment[networkId]?.length &&
            consumableEquipment[networkId].map((el) => (
              <Grid
                item
                xs={3}
                alignSelf={'end'}
                key={el.id}
              >
                <Typography variant='body2'>{el.description}</Typography>
                <TextField
                  variant='standard'
                  sx={{ width: '50%' }}
                  {...register(el.id, { value: el.quantity })}
                />
                {el.unit}
              </Grid>
            ))}
        </Grid>
      </FormProvider>
    </AccessPopup>
  );
};

export default EditConsumableEquipment;
