export const DEFAULT_RANGE = [
  new Date(Date.now() - 86400000 * 14), // 14 дней назад
  new Date(),
];

export const downLoadExcel = (response: any, reportName: string) => {
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `${reportName}.xlsx`;

  a.click();

  window.URL.revokeObjectURL(url);
};
