import { styled } from '@mui/material';
import { color } from '@/styles/mixins';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

export const SearchInput = styled('input')({
  width: '100%',
  border: `1px solid ${color('searchGray')}`,
  borderRadius: 20,
  height: 67,
  paddingRight: 40,
  paddingLeft: 83,
  color: color('searchGray'),
});

export const SearchIcon = styled(SearchRoundedIcon)({
  color: color('searchGray'),
  position: 'absolute',
  left: 41,
  top: 22,
});

export const SearchWrapper = styled('div')({
  position: 'relative',
  width: 'calc(100vw - 30px)',
  maxWidth: 1170,
  margin: '34px auto 0',
});
