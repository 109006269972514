import { yodaRefreshRoles } from '@/services/YodaRestService';
import axios, { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { RolesEnum } from '../app/auth/roles';
import { useLogOut } from './useLogOut';

interface IProps {
  children: React.ReactNode;
}

const RoleContext = React.createContext<{
  role: RolesEnum[] | null;
}>({
  role: null,
});

const INVALID_TOKEN_MESSAGE = 'invalid_token';
export const yodaRoles = 'yodaRoles';

const getRolesLocal = (): null | RolesEnum[] => {
  const roles = localStorage.getItem(yodaRoles);
  if (roles) {
    return JSON.parse(roles);
  } else {
    return null;
  }
};

export const saveRolesLocal = (userRoles: string[]) => {
  localStorage.setItem(yodaRoles, JSON.stringify(userRoles));
};

export const RoleProvider: React.FC<IProps> = ({ children }) => {
  const logOut = useLogOut();
  const [role, setRole] = useState<RolesEnum[] | null>(null);

  useEffect(() => {
    const userRoles = getRolesLocal();
    if (userRoles) {
      setRole(userRoles);
    } else {
      yodaRefreshRoles()
        .then((data) => setRole(data))
        .catch((err: AxiosError<{ error: string }>) => {
          if (axios.isAxiosError(err) && err.response?.data.error === INVALID_TOKEN_MESSAGE) {
            logOut();
          }
        });
    }
  }, []);

  return (
    <RoleContext.Provider
      value={{
        role,
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => useContext(RoleContext);
