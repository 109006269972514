import { AxiosResponse } from 'axios';
import { HOST } from '@/services/YodaRestService';
import { axiosGet } from '@/services/helpers';
import { SimChanges } from './Dto';

export async function getGsmByTaskId(taskId: string): Promise<AxiosResponse<SimChanges[], any>> {
  const url = HOST + `/rest/task/${taskId}/sim-change`;
  return axiosGet(url);
}

export async function getGsmByMeterChangeId(id: string): Promise<AxiosResponse<SimChanges[], any>> {
  const url = HOST + `/rest/simChange/meter-change/${id}`;
  return axiosGet(url);
}
// export async function getGsmByMeterChangeId(id: string): Promise<AxiosResponse<SimChanges[], any>> {
//   const url = HOST + `/rest/simChange/meter-change/${id}`;
//   return axiosGet(url);
// }
// export async function getGsmByMeterChangeId(id: string): Promise<AxiosResponse<SimChanges[], any>> {
//   const url = HOST + `/rest/simChange/meter-change/${id}`;
//   return axiosGet(url);
// }
