import { BoxShadow } from '@/components/ui/BoxShadow';
import { LabelCheckbox } from '@/components/inputs/labelCheckbox';
import { ILabelCheckbox } from '@/components/inputs/labelCheckbox/types';
import { styled, Grid } from '@mui/material';

export const MainFilterCheckbox = ({ ...props }: ILabelCheckbox) => (
  <LabelCheckbox
    {...props}
    style={{ paddingBottom: 0, paddingTop: 0 }}
  />
);

export const MainFilterSingleSelectWrapper = styled('div')({
  padding: '0.1rem 0.5rem',
  marginRight: '10px',
});

export const MainFilterWrapper = styled(BoxShadow)({
  position: 'relative',
});

export const MainFilterButtonsWrapper = styled(Grid)({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'flex-end',
  marginLeft: 'auto',
});

export const MainFilterLocker = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(200,200,200,0.2)',
  borderRadius: '10px',
});

export const MainFilterTKOTooltipTitle = styled('span')({
  whiteSpace: 'pre-line',
  textAlign: 'center',
  display: 'block',
});
