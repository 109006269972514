import { styled } from '@mui/material/styles';

export const MapAlert = styled('div')({
  position: 'absolute',
  top: 10,
  left: 10,
  right: 50,
  zIndex: 3,
  display: 'flex',
  gap: 5,
});
export const MapAlertPillar = styled('div')({
  position: 'absolute',
  top: 90,
  left: 10,
  right: 50,
  zIndex: 3,
});
