import { Button, Grid } from '@mui/material';
import React from 'react';
import styles from '../Popup.module.scss';
import { IPopup, Popup } from '../Popup';
import Typography from '@mui/material/Typography';
import { AccessPopupTextWrapper, AccessPopupHeader } from './AccessPopup.styled';
import LoadingButton from '@mui/lab/LoadingButton';

interface IAccessPopup extends IPopup {
  headerText?: string;
  titleText?: string;
  successText?: string;
  closeText?: string;
  children?: any;
  disabledSuccess?: boolean;
  isLoading?: boolean;
  btnVariant?: 'contained' | 'text' | 'outlined';
}

export const AccessPopup: React.FC<IAccessPopup> = ({
  isOpen,
  onClose,
  onSuccess,
  headerText,
  titleText,
  successText,
  closeText,
  children,
  disabledSuccess,
  isLoading,
  btnVariant = 'contained',
}) => {
  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={onSuccess}
    >
      <Grid
        container
        spacing={2}
        justifyContent={'center'}
      >
        <AccessPopupTextWrapper>
          {headerText && <AccessPopupHeader variant='h4'>{headerText}</AccessPopupHeader>}
          {titleText && <Typography variant='h6'>{titleText}</Typography>}
        </AccessPopupTextWrapper>

        <Grid
          item
          xs={12}
        >
          {children}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <div className={styles['modal-buttons']}>
            <LoadingButton
              disabled={disabledSuccess}
              onClick={onSuccess}
              variant={btnVariant}
              loading={!!isLoading}
            >
              {successText ? successText : 'OK'}
            </LoadingButton>
            <Button
              onClick={onClose}
              variant={btnVariant}
            >
              {closeText ? closeText : 'Отменить'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Popup>
  );
};
