import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TaskResultType, TechInspectionResponse } from '@/dto/taskmap/task/TaskResultDto';
import { TaskResponse } from '@/dto/taskmap/task/TasksDto';
import { useActionLog } from '@/hooks/ActionLogHook';
import { findAllResultByTaskIdAndType } from '@/services/TaskService';
import { useGalleryData } from '@/components/Gallery/store';
import { History } from '@/components/features/taskEditor/item/History';
import Chronology from '@/components/features/taskEditor/item/chronology';
import { getAdpuByTaskId } from '@/services/AdpuService';
import { AdpuResponse } from '@/dto/AdpuDto';
import { Item } from './Item';
import { TechInspectionItem } from '../item/TechInspectionItem';
import { AddTKO } from '../item/tko/AddTKO';
import { MailNotification } from '@/components/features/taskEditor/adpu/mailNotifications/MailNotifications';
import { NewItem } from './NewItem';

interface PanelProps {
  taskResponse: TaskResponse;
  reloadParent?: () => void;
  reloadParentFlag?: boolean;
}

export const Panel = (props: PanelProps) => {
  const { taskResponse, reloadParentFlag } = props;
  const [tabPane, setTabPane] = useState('1');
  const { catchError } = useActionLog();
  const [reloadFlag, setReloadFlag] = useState(false);

  const [loadingAdpu, setLoadingAdpu] = useState(false);
  const [adpuResponses, setAdpuResponses] = useState<AdpuResponse[]>([]);

  const clearImageGroups = useGalleryData(({ clearImageGroups }) => clearImageGroups);
  const setTaskType = useGalleryData(({ setTaskType }) => setTaskType);

  const [techInspectionResponses, setTechInspectionRespons] = useState<TechInspectionResponse[]>(
    []
  );
  const [loadingTechInspection, setLoadingTechInspection] = useState(false);
  const loadTechInspection = () => {
    const taskId = taskResponse?.id;
    if (taskId) {
      setLoadingTechInspection(true);
      findAllResultByTaskIdAndType(taskId, TaskResultType.TKO)
        .then((res) => setTechInspectionRespons(res.data))
        .catch((err) => catchError('Ошибка при загрузке информации о ТКО', err))
        .finally(() => setLoadingTechInspection(false));
    }
  };

  const inverseReloadFlag = () => {
    setReloadFlag(!reloadFlag);
  };

  // const load1 = () => {
  //   if (taskResponse) {
  //     // taskResponse.adpuOrderId && getAdpuById(taskResponse.adpuOrderId)
  //     taskResponse.adpuOrderId &&
  //       getAdpuOrder(taskResponse.adpuOrderId).then(({ data }) => {
  //         console.log(data);
  //       });
  //   }
  // };

  const load = () => {
    const taskId = taskResponse?.id;
    if (taskId) {
      setLoadingAdpu(true);
      getAdpuByTaskId(taskId)
        .then((res) => {
          setAdpuResponses(res.data);
          inverseReloadFlag();
        })
        .catch((err) => catchError('Ошибка при загрузке информации', err))
        .finally(() => setLoadingAdpu(false));
    }
  };

  useEffect(() => {
    // load1();
    load();
    loadTechInspection();
  }, [taskResponse, reloadParentFlag]);

  const handleChangeTabPane = (event: React.SyntheticEvent, newTab: string) => {
    setTabPane(newTab);
  };

  useEffect(() => {
    taskResponse?.type && setTaskType(taskResponse?.type);
  }, [reloadFlag]);

  const handleClick = (e: any) => {
    e.stopPropagation();
    clearImageGroups();
  };

  const updateAdpu = (newAdpu: AdpuResponse) => {
    let tmp = adpuResponses.map((adpu) => (adpu.id === newAdpu.id ? newAdpu : adpu));
    tmp = !tmp.find((elem) => elem.id === newAdpu.id) ? [...tmp, newAdpu] : tmp;
    setAdpuResponses(tmp);
  };

  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        paddingBottom: 2,
      }}
    >
      <TabContext value={tabPane}>
        <Box>
          <TabList
            onChange={handleChangeTabPane}
            centered
            aria-label='result odpu tabs'
          >
            <Tab
              label='Результаты работы'
              value='1'
              onClick={handleClick}
            />
            <Tab
              label='История'
              value='2'
              onClick={handleClick}
            />
            <Tab
              label='Хронология событий'
              value='3'
              onClick={handleClick}
            />
          </TabList>
        </Box>
        <TabPanel
          value='1'
          sx={{ padding: '17px 0 0 0' }}
        >
          <Grid
            container
            spacing={2}
          >
            {adpuResponses.length > 0 ? (
              adpuResponses.map((elem) => (
                <Item
                  {...elem}
                  updateFunction={updateAdpu}
                  key={elem.id}
                />
              ))
            ) : (
              <NewItem updateFunction={load} />
            )}
            <MailNotification />
            {techInspectionResponses &&
              [...techInspectionResponses]
                .sort(
                  (a: TechInspectionResponse, b: TechInspectionResponse) =>
                    (b.eventOn as unknown as number) - (a.eventOn as unknown as number)
                )
                .map((t) => (
                  <Grid
                    key={t.id}
                    item
                    xs={8}
                  >
                    <TechInspectionItem
                      response={t}
                      update={loadTechInspection}
                      contractId={taskResponse?.contractResponse?.id}
                    />
                  </Grid>
                ))}
          </Grid>
          <AddTKO
            contractId={taskResponse?.contractResponse?.id}
            afterAdd={loadTechInspection}
            taskId={taskResponse?.id}
          />
        </TabPanel>
        <TabPanel value='2'>
          <History />
        </TabPanel>
        <TabPanel value='3'>
          <Chronology />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
