import { Button, CircularProgress, Grid, TextField, Autocomplete } from '@mui/material';
import React, { useState } from 'react';
import styles from './Popup.module.scss';
import { IPopup, Popup } from './Popup';
import Typography from '@mui/material/Typography';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { PillarRequest } from '@/dto/taskmap/task/TasksDto';
import { createPillar } from '@/services/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';

interface IAccessPopup extends IPopup {
  headerText?: string;
  titleText?: string;
  successText?: string;
  closeText?: string;
}
const textFieldStyles = {
  '& .MuiSelect-select:focus': {
    backgroundColor: 'inherit',
  },
};

const parseCoordinates = (coordinates: string) => {
  if (!coordinates) {
    return { latitude: null, longitude: null };
  }
  const coordsArray = coordinates.split(',');
  if (coordsArray.length !== 2) {
    return { latitude: null, longitude: null };
  }
  const [latitude, longitude] = coordsArray.map((coord) => (coord ? +coord : null));
  return { latitude, longitude };
};

const formatAbonentsList = (contactPhone: string, fio: string) => {
  return [{ contactPhone, fio }];
};

const defaultForm = {
  taskType: '',
  customerId: '',
  registrationNum: '',
  originalAddress: '',
  coordinats: '',
  contactPhone: '',
  fio: '',
  techCondition7: '',
  techCondition10: '',
  techCondition11: '',
};

export const CreateColumnPopup: React.FC<IAccessPopup> = React.memo(function CreateColumnPopup({
  isOpen,
  onClose,
  onSuccess,
  headerText,
  successText,
  closeText,
}: IAccessPopup) {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { addActionLog, catchError } = useActionLog();

  const [form, setForm] = useState(defaultForm);
  const [isLoading, setIsLoading] = useState(false);

  const disableButton = !form.taskType || (!form.customerId && !form.registrationNum) || isLoading;

  const handleOnChange = (e: React.ChangeEvent<{ name: string; value: string }>) => {
    const allowedCharacters = /^[0-9.,\s-]*$/;
    const key = e.target.name;
    const value = e.target.value;
    if (key === 'coordinats' && !allowedCharacters.test(value)) {
      return;
    }
    setForm({ ...form, [key]: value });
  };

  const handleCencel = () => {
    onClose();
    setForm(defaultForm);
  };

  const sendData = () => {
    const validDataToSend: PillarRequest = {
      type: form.taskType,
      abonents: formatAbonentsList(form.contactPhone, form.fio),
      taskInfoRequest: {
        customerId: form.customerId,
        ...parseCoordinates(form.coordinats),
        registrationNum: form.registrationNum,
        originalAddress: form.originalAddress,
        pillarTaskInfoRequest: {
          techCondition7: form.techCondition7,
          techCondition10: form.techCondition10,
          techCondition11: form.techCondition11,
        },
      },
    };
    setIsLoading(true);
    createPillar(validDataToSend)
      .then(({ data }) => {
        addActionLog(ActionLogType.SUCCESS, `Заявка с id ${data} создана`);
        setForm(defaultForm);
        onClose();
      })
      .catch(({ response }) => {
        catchError(`Ошибка при создании заявки ${response.data}`, response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={onSuccess}
      customStyle={{ width: '80vw' }}
    >
      <Typography
        variant='h4'
        style={{ marginBottom: 24, textAlign: 'center', fontWeight: 'bold' }}
      >
        {headerText}
      </Typography>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={3}
        >
          <Autocomplete
            style={{ width: '100%' }}
            componentName='taskType'
            options={taskMapToArray(getCatalogMapWithErr('fzOrTechConnectionTaskType'))}
            value={{
              value: getCatalogMapWithErr('fzOrTechConnectionTaskType').get(form.taskType) ?? '',
              label: getCatalogMapWithErr('fzOrTechConnectionTaskType').get(form.taskType) ?? '',
            }}
            onChange={(event: any, newValue: any) => {
              setForm({ ...form, taskType: newValue?.value ?? '' });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Тип заявки'}
                variant='standard'
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <TextField
            required
            label='СТДО / Договор Тех.Прис'
            variant='standard'
            fullWidth
            name='customerId'
            value={form.customerId}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <TextField
            label='Рег. № SAP'
            required
            variant='standard'
            fullWidth
            name='registrationNum'
            value={form.registrationNum}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            label='Фактический адрес'
            variant='standard'
            fullWidth
            name='originalAddress'
            value={form.originalAddress}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <TextField
            label='Координаты'
            variant='standard'
            fullWidth
            name='coordinats'
            value={form.coordinats}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            label='Фио контактного лица'
            variant='standard'
            fullWidth
            name='fio'
            value={form.fio}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <TextField
            label='Номер телефона'
            placeholder='Номер телефона'
            variant='standard'
            fullWidth
            name='contactPhone'
            value={form.contactPhone}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            label='Течническое Условия пункт 7.1.1'
            variant='standard'
            fullWidth
            multiline={true}
            maxRows={3}
            name='techCondition7'
            value={form.techCondition7}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            label='Течническое Условия пункт 10.1.1'
            variant='standard'
            fullWidth
            multiline={true}
            maxRows={3}
            name='techCondition10'
            value={form.techCondition10}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            label='Течническое Условия пункт 11.1.1'
            variant='standard'
            fullWidth
            multiline={true}
            maxRows={3}
            name='techCondition11'
            value={form.techCondition11}
            onChange={handleOnChange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent={'center'}
      >
        <Grid
          item
          xs={12}
        >
          <div className={styles['modal-buttons']}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  onClick={sendData}
                  type='submit'
                  variant='text'
                  disabled={disableButton}
                >
                  {successText ? successText : 'OK'}
                </Button>
                <Button
                  onClick={handleCencel}
                  variant='text'
                >
                  {closeText ? closeText : 'Отменить'}
                </Button>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </Popup>
  );
});
