import TableCell from '@mui/material/TableCell';
import { FC } from 'react';
import { IGetRegistryEnforseTable } from '@/dto/RegistryDto';

export interface HeadCell {
  id: keyof IGetRegistryEnforseTable;
  label: string;
}

interface ITableCellsMain {
  headCells: HeadCell[];
}

export const TableCells: FC<ITableCellsMain> = ({ headCells }) => {
  return (
    <>
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          padding={'normal'}
          sortDirection={'asc'}
        >
          {headCell.label}
        </TableCell>
      ))}
    </>
  );
};
