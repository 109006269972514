import { SupplyNetworkElementScanField } from '@/dto/SupplyBillServiceDto';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { SupplyPuDataTypography } from '../PURemoveScanner/PURemoveScanner.styled';

export const generateOsRemoveColumns = (removeScanFields?: SupplyNetworkElementScanField) => {
  return [
    {
      field: 'rowNum',
      headerName: '№',
      sortable: false,
      width: 100,
      renderCell: (params) => <SupplyPuDataTypography>{params.value}</SupplyPuDataTypography>,
    },
    {
      field: 'typeName',
      headerName: 'Тип прибора',
      sortable: false,
      width: 180,
      renderCell: (params) => <SupplyPuDataTypography>{params.value}</SupplyPuDataTypography>,
    },

    ...(removeScanFields === SupplyNetworkElementScanField.MAC ||
    removeScanFields === SupplyNetworkElementScanField.MAC_AND_IMEI
      ? [
          {
            field: 'mac',
            headerName: 'MAC',
            sortable: false,
            width: 230,
            editable: true,
            renderCell: (params: GridRenderCellParams<string>) => {
              let resValue = '';
              if (params.value && params.value.length > 0) {
                resValue = params.value;
              } else if (params.row.mac && params.row.mac.length > 0) {
                resValue = params.row.mac;
              }
              return <SupplyPuDataTypography>{resValue}</SupplyPuDataTypography>;
            },
          },
        ]
      : []),
    ...(removeScanFields === SupplyNetworkElementScanField.IMEI ||
    removeScanFields === SupplyNetworkElementScanField.MAC_AND_IMEI
      ? [
          {
            field: 'imei',
            headerName: 'IMEI',
            sortable: false,
            width: 230,
            editable: true,
            renderCell: (params: GridRenderCellParams<string>) => {
              let resValue = '';
              if (params.value && params.value.length > 0) {
                resValue = params.value;
              } else if (params.row.imei && params.row.imei.length > 0) {
                resValue = params.row.imei;
              }
              return <SupplyPuDataTypography>{resValue}</SupplyPuDataTypography>;
            },
          },
        ]
      : []),
  ] as GridColDef[];
};
