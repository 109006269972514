import { color } from '@/styles/mixins';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { activeTabValues } from './CallingMapPopup';
import { InstallationCallLogType } from '../types/types';
import { checkName, renderBorderColor } from '../utils/CallingMapUtils';

const circleSize = '1.5rem';

const renderCallTime = (status: string) => {
  if (status === 'AGREEMENT') return 'Дата и время монтажа:';
  if (status === 'CALLBACK') return 'Дата и время обратного звонка:';
  return 'Дата и время звонка:';
};

interface CallLogItemProps {
  call: InstallationCallLogType;
}
const CallLogItem = ({ call }: CallLogItemProps) => {
  return (
    <Stack
      gap={1}
      style={{
        marginTop: 5,
        borderBottom: `1px solid grey`,
        borderTopRightRadius: 0,
      }}
    >
      <Stack
        paddingX={1}
        style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
      >
        <Stack
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <Stack
            style={{
              width: circleSize,
              height: circleSize,
              backgroundColor: renderBorderColor(call.status),
              borderRadius: '50%',
            }}
          ></Stack>
          <Stack>
            <span style={{ fontWeight: 'bold', color: color('typographyColorMap1') }}>
              {call.status === 'ADD_NUMBER' ? 'Добавлен номер' : activeTabValues[call.status]}
            </span>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant='overline'>
            {call.callAt && moment(call.callAt).format('HH:mm')}{' '}
            {call.callAt && moment(call.callAt).format('DD.MM.YYYY')}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        paddingX={1}
        gap={1}
      >
        {call.scheduledOn && (
          <Typography
            color={color('gray')}
            variant='body1'
          >
            <span style={{ color: color('typographyColorMap2') }}>
              {renderCallTime(call.status)}
            </span>{' '}
            <span style={{ fontWeight: 'bold', color: color('typographyColorMap1') }}>
              {moment(call.scheduledOn).format('DD.MM.YYYY')}
              {call.scheduledPeriod && ','} {call.scheduledPeriod?.toLowerCase()}
            </span>
          </Typography>
        )}
        {call.executor && (
          <Typography
            color={color('gray')}
            variant='body1'
          >
            <span style={{ color: color('typographyColorMap2') }}>Мастер: </span>
            <span style={{ fontWeight: 'bold', color: color('typographyColorMap1') }}>
              {checkName(call.executor)}
            </span>
          </Typography>
        )}
        {call.note && (
          <Typography
            color={color('gray')}
            variant='body1'
          >
            <span style={{ color: color('typographyColorMap2') }}> Комментарий: </span>

            <span style={{ fontWeight: 'bold', color: color('typographyColorMap1') }}>
              {call.note}
            </span>
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
export default CallLogItem;
