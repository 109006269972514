import { styled } from '@mui/material/styles';
import { color } from '@/styles/mixins';
import { Colors } from '@/styles/colors';

export interface IChronologyRowDiv {
  borderColor?: Colors;
  borderType?:
    | 'none'
    | 'hidden'
    | 'dotted'
    | 'dashed'
    | 'solid'
    | 'double'
    | 'groove'
    | 'ridge'
    | 'inset'
    | 'outset';
}

export const ChronologyRowDiv = styled('div')<IChronologyRowDiv>(
  ({ borderColor, borderType = 'dashed' }) => ({
    padding: borderColor ? '9px 13px 13px 13px' : '10px 14px 14px 14px',
    border: borderColor ? `1px ${borderType} ${color(borderColor)}` : 'none',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 10,
    alignItems: 'center',
    margin: 12,
  })
);

export const ChronologyRowOtherDiv = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  borderRadius: 10,
  padding: '4px 10px 7px',
  border: `1px dashed ${color('chronologyCreate')}`,
  marginBottom: 12,
});

export const ChronologyLine = styled('div')({
  width: 'calc(100% - 200px)',
  margin: '12px 160px 12px 40px',
  height: 1,
  background: color('chronologyLine'),
});

export const ChronologyItemDiv = styled('div')<{ width: string | number }>(({ width }) => ({
  marginBottom: 8,
  width: width,
}));

export const ChronologyLabel = styled('div')({
  fontSize: '10px',
  fontWeight: '600',
  lineHeight: '16px',
  letterSpacing: '1.25px',
  color: color('grayLabel'),
});
export const ChronologyValue = styled('div')({
  paddingRight: '8px',
  wordBreak: 'break-all',
  marginTop: '4px',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '16px',
  letterSpacing: '1.25px',
  color: color('trueBlack'),
});
export const ChronologyValueWarning = styled(ChronologyValue)({
  color: color('muiError'),
});
