import React, { FC } from 'react';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]?: number | string | undefined | File | File[] | boolean },
  b: { [key in Key]?: number | string | undefined | File | File[] | boolean }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface IItem {
  children: React.ReactNode;
}

export const Item: FC<IItem> = ({ children }) => (
  <div
    style={{
      width: '33%',
      display: 'flex',
      justifyContent: 'center',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }}
  >
    {children}
  </div>
);
