import React, { FC } from 'react';
import {
  ChronologyItemDiv,
  ChronologyLabel,
  ChronologyValue,
  ChronologyValueWarning,
} from './Chronology.styled';

interface IChronologyItem {
  label?: string;
  hideLabel?: boolean;
  value?: string;
  hideValue?: boolean;
  isWarning?: boolean;
  width?: string | number;
}

const ChronologyItem: FC<IChronologyItem> = ({
  label = '',
  value = '-',
  hideValue = false,
  hideLabel = false,
  isWarning = false,
  width = '25%',
}) => {
  return (
    <ChronologyItemDiv width={width}>
      {!hideLabel && <ChronologyLabel>{label}</ChronologyLabel>}
      {!hideValue && isWarning ? (
        <ChronologyValueWarning>{value}</ChronologyValueWarning>
      ) : (
        <ChronologyValue>{value}</ChronologyValue>
      )}
    </ChronologyItemDiv>
  );
};

export default ChronologyItem;
