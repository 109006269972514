import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { FC } from 'react';
import { color } from '@/styles/mixins';

export enum NetworkStatus {
  NEW = 'NEW',
  MOBILE_SEND = 'MOBILE_SEND',
  NOT_FOUND = 'NOT_FOUND',
  INSTALLED = 'INSTALLED',
  NOT_VALID = 'NOT_VALID',
  IS_USED = 'IS_USED',
  EX_EQUIPMENT_NOT_FOUND = 'EX_EQUIPMENT_NOT_FOUND',
  NOT_RELEVANT = 'NOT_RELEVANT',
}

interface INetworkStateIcon {
  status?: string;
}

export const getColorByStatusForNewWork = (
  status: any
): { border: string; borderWidth: string } => {
  switch (status) {
    case NetworkStatus.NOT_RELEVANT:
      return { border: color('statusCancel', 0.5), borderWidth: '3' };
    case NetworkStatus.INSTALLED:
      return { border: color('statusSuccess', 0.5), borderWidth: '3' };
  }
  return { border: color('statusError', 0.5), borderWidth: '3' };
};

export const NetworkStateIcon: FC<INetworkStateIcon> = ({ status }) => {
  switch (status) {
    case NetworkStatus.NOT_RELEVANT:
      return (
        <HighlightOffRoundedIcon
          sx={{ mr: 1 }}
          htmlColor={color('statusCancel')}
        />
      );
    case NetworkStatus.INSTALLED:
      return (
        <CheckCircleOutlineRoundedIcon
          sx={{ mr: 1 }}
          htmlColor={color('statusSuccess')}
        />
      );
  }
  return (
    <RemoveCircleOutlineRoundedIcon
      sx={{ mr: 1 }}
      htmlColor={color('statusError')}
    />
  );
};
