import { color } from '@/styles/mixins';
import { styled } from '@mui/material';
import { TableCell } from '@mui/material';

export const CELL_DEFAULT_STYLES = {
  zIndex: 0,
  borderLeft: `1px solid ${color('trueGray')}`,
  borderRight: 'none',
  borderTop: 'none',
  borderBottom: 'none',
};

export const CallCenterStatisticsTableCell = styled(TableCell)({
  ...CELL_DEFAULT_STYLES,
});
