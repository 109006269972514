import React, { FC } from 'react';
import { IconButton } from '@mui/material';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import WestRoundedIcon from '@mui/icons-material/WestRounded';

interface IArrowButton {
  onClick: () => void;
  direction: 'left' | 'right';
}

export const ArrowButton: FC<IArrowButton> = ({ onClick, direction }) => {
  return (
    <IconButton
      onClick={onClick}
      size='large'
    >
      {direction === 'left' && <WestRoundedIcon />}
      {direction === 'right' && <EastRoundedIcon />}
    </IconButton>
  );
};
