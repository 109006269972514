import React, { FC } from 'react';
import { ChronologyResponse } from '@/dto/taskmap/task/TaskResultDto';
import { Colors } from '@/styles/colors';
import moment from 'moment';
import { ChronologyRowDiv } from './Chronology.styled';
import ChronologyItem from './ChronologyItem';

const ChronologyRow: FC<ChronologyResponse & { borderColor?: Colors }> = ({
  date,
  time,
  comment,
  title,
  borderColor,
}) => {
  return (
    <>
      <ChronologyRowDiv borderColor={borderColor}>
        <ChronologyItem
          label={'Дата'}
          value={date ? moment(date).format('DD.MM.YYYY') : '-'}
        />
        <ChronologyItem
          label={'Время'}
          value={time}
        />
        <ChronologyItem
          label={'Сервис'}
          value={title}
          hideLabel={!!borderColor}
        />
        <ChronologyItem
          label={'Результат'}
          value={comment}
        />
      </ChronologyRowDiv>
    </>
  );
};

export default ChronologyRow;
