import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, TextField } from '@mui/material';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';
import { TaskStatus } from '@/dto/taskmap/Dto';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

interface CallTypeModalTypeTwoProps {
  save: (
    taskId: string,
    requestId: string,
    resultJobLog: string,
    planDate?: string,
    executor?: string,
    comment?: string
  ) => any;
  executors: SelectFilterOptType[];
  taskStatus: string;
  taskId: string;
  requestId: string;
}

export default function CallTypeModalTypeTwo(props: CallTypeModalTypeTwoProps) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [planDate, setPlanDate] = React.useState('');
  const [comment, setComment] = React.useState('');

  const navigate = useNavigate();

  const [selectedExecutor, setSelectedExecutor] = React.useState<SelectFilterOptType | null>(null);

  const clearFields = () => {
    setPlanDate('');
    setComment('');
    setSelectedExecutor(null);
  };

  function getKeyByValue(value: string) {
    const indexOfS = Object.values(TaskStatus).indexOf(value as unknown as TaskStatus);

    const key = Object.keys(TaskStatus)[indexOfS];

    return key;
  }

  const save = () => {
    if (planDate && selectedExecutor) {
      props.save(
        props.taskId,
        props.requestId,
        'принял плановую дату: ' +
          planDate +
          ' и назначил ответственного: ' +
          selectedExecutor.label +
          '',
        planDate,
        selectedExecutor!.value,
        comment
      );
      setOpen(false);
    }
  };

  const description =
    props.taskStatus !== getKeyByValue(TaskStatus.assigned) &&
    props.taskStatus !== getKeyByValue(TaskStatus.in_work) &&
    props.taskStatus !== getKeyByValue(TaskStatus.returned) ? (
      <Typography
        id='modal-modal-title'
        variant='h6'
        component='h2'
      >
        Текущая заявка в статусе "{TaskStatus[props.taskStatus as keyof typeof TaskStatus]}".
        Возможно мастер уже вызжал туда. Вы уверены, что хотите назначить исполнителя на эту заявку?
      </Typography>
    ) : (
      <Typography
        id='modal-modal-title'
        variant='h6'
        component='h2'
      >
        Выберите исполнителя и плановую дату работ
      </Typography>
    );

  const secondButton =
    props.taskStatus !== getKeyByValue(TaskStatus.assigned) &&
    props.taskStatus !== getKeyByValue(TaskStatus.in_work) &&
    props.taskStatus !== getKeyByValue(TaskStatus.returned) ? (
      <Button
        onClick={(e) => {
          navigate(`/task/edit/${props.taskId}`);
          setOpen(false);
        }}
      >
        Открыть заявку
      </Button>
    ) : (
      <Button
        onClick={(e) => {
          clearFields();
          setOpen(false);
        }}
      >
        Отмена
      </Button>
    );

  return (
    <div>
      <Button onClick={handleOpen}>Принять</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          {description}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              marginTop: 35,
              marginBottom: 15,
            }}
          >
            <TextField
              sx={{ minWidth: 200 }}
              id='plan-date-field'
              type='date'
              label='Выбрать планируемую дату работ'
              InputLabelProps={{ shrink: true }}
              value={planDate}
              error={!planDate}
              variant='outlined'
              onChange={(e) => setPlanDate(e.target.value)}
            />
            <div style={{ marginLeft: 25 }}>
              <Autocomplete
                disablePortal
                sx={{ minWidth: 300 }}
                id='executor-select'
                value={selectedExecutor}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={props.executors}
                onChange={(e, val) => {
                  setSelectedExecutor(val);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!selectedExecutor}
                    label='Исполнитель'
                  />
                )}
              />
            </div>
          </div>
          <TextField
            fullWidth
            multiline
            rows={4}
            id='comment-field'
            label='Комментарий'
            value={comment}
            variant='outlined'
            onChange={(e) => setComment(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'end',
              marginTop: 10,
            }}
          >
            {secondButton}
            <Button
              onClick={(e) => {
                save();
              }}
            >
              Сохранить
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
