import { createStore } from '@/utils/createStore';

export interface IInput {
  id: string;
  number: string;
}

interface ILockerPopup {
  isLockerPopupOpen: boolean;
  inputs: IInput[];
  lockerData: any;

  setIsLockerPopupOpen: (isOpen: boolean) => void;
  setInputs: (inputs: IInput[]) => void;
  setLockerData: (data: any) => void;
}

export const useLockerPopup = createStore<ILockerPopup>(
  (set, get) => ({
    isLockerPopupOpen: false,
    inputs: [],
    lockerData: {},

    setLockerData: (data) =>
      set(() => ({
        lockerData: data,
      })),

    setInputs: (inputs) =>
      set(() => ({
        inputs: inputs,
      })),

    setIsLockerPopupOpen: (isOpen) =>
      isOpen
        ? set(() => ({
            isLockerPopupOpen: isOpen,
          }))
        : set(() => ({
            isLockerPopupOpen: isOpen,
            lockerKey: '',
            inputs: [],
          })),
  }),
  'Locker popup'
);
