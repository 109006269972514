import { useAccess } from '@/hooks/useAccess';
import { HouseEntranceEdit } from '@/components/features/pnr/HouseEntranceEdit/HouseEntranceEdit';
import { AccessEnum } from '@/app/auth/roles';
import React from 'react';

const PnrEntranceEditPage = () => {
  useAccess({
    access: [AccessEnum.Map, AccessEnum.MapUspd],
  });

  return <HouseEntranceEdit />;
};

export default PnrEntranceEditPage;
