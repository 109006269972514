import { Paper, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import { HouseDataDto, HouseTaskEditDto } from '@/dto/taskmap/Dto';
import {
  activeOsOrderColumns,
  activeOsTaskColumns,
  activePuOrderColumns,
  tkosColumns,
} from './constants';

interface ActiveOrdersPUPnrProps {
  houseData?: HouseDataDto;
}

export const ActiveOrdersPUPnrComponent = (props: ActiveOrdersPUPnrProps) => {
  const [selectedTableIndicator, setSelectedTableIndicator] = React.useState<number>(0);
  const [tkos, setTkos] = React.useState<HouseTaskEditDto[]>([]);
  const [activePuOrder, setActivePuOrder] = React.useState<HouseTaskEditDto[]>([]);
  const [activeOsOrder, setActiveOsOrder] = React.useState<HouseTaskEditDto[]>([]);
  const [activeOsTask, setActiveOsTask] = React.useState<HouseTaskEditDto[]>([]);

  const { houseData } = props;

  React.useEffect(() => {
    if (houseData?.tkos) {
      setTkos(houseData?.tkos);
    }
    if (houseData?.activePuOrder) {
      setActivePuOrder(houseData?.activePuOrder);
    }
    if (houseData?.activeOsOrder) {
      setActiveOsOrder(houseData?.activeOsOrder);
    }
    if (houseData?.activeOsTaskList) {
      setActiveOsTask(houseData?.activeOsTaskList);
    }
  }, []);

  const onLeftArrowTablesIndicatorClick = () => {
    if (selectedTableIndicator > 0) {
      setSelectedTableIndicator(selectedTableIndicator - 1);
    }
  };

  const onRightArrowTablesIndicatorClick = () => {
    if (selectedTableIndicator < 1) {
      setSelectedTableIndicator(selectedTableIndicator + 1);
    }
  };

  const montagePercent =
    houseData!.smrTasks && houseData?.flatCount
      ? Math.round((houseData!.smrTasks?.length / houseData?.flatCount) * 100)
      : 0;

  const getCountInstalledOs = (houseData: HouseDataDto | undefined) => {
    if (!houseData) {
      return 0;
    }
    return houseData!.osTasks
      ? houseData!.osTasks
          .flatMap((tasks) => tasks.networkElements)
          .filter((networkElement) => networkElement && networkElement.status == 'INSTALLED').length
      : 0;
  };

  return (
    <div style={{ width: '100%' }}>
      <Paper style={{ width: '87.5vw', marginLeft: '5.5vw' }}>
        <div
          style={{
            marginBottom: '15px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: '15px',
          }}
        >
          <TextField
            label='Кол-во квартир'
            value={houseData!.flatCount}
            sx={{ width: 150, marginRight: '10px' }}
            variant='standard'
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label='Процент монтажа ПУ'
            value={montagePercent}
            sx={{ width: 150, marginRight: '10px' }}
            variant='standard'
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label='Кол-во смонтированных ПУ'
            value={houseData!.smrTasks ? houseData!.smrTasks.length : 0}
            sx={{ width: 170, marginRight: '10px' }}
            variant='standard'
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label='Кол-во ПУ по заявкам'
            value={houseData!.countPuTasks ? houseData!.countPuTasks : 0}
            sx={{ width: 150, marginRight: '10px' }}
            variant='standard'
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label='Кол-во нарядов на ПУ'
            value={houseData!.activePuOrder ? houseData!.activePuOrder.length : 0}
            sx={{ width: 150, marginRight: '10px' }}
            variant='standard'
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label='Кол-во смонтированных ОС'
            value={getCountInstalledOs(houseData)}
            sx={{ width: 170, marginRight: '10px' }}
            variant='standard'
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label='Кол-во нарядов ОС'
            value={houseData!.activeOsOrder ? houseData!.activeOsOrder.length : 0}
            sx={{ width: 150, marginRight: '10px' }}
            variant='standard'
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label='Кол-во ОС по заявкам'
            value={houseData!.countOsTasks ? houseData!.countOsTasks : 0}
            sx={{ width: 150 }}
            variant='standard'
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </Paper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '37px',
          alignItems: 'center',
          flexBasis: 'max-content',
          flex: 'auto',
        }}
      >
        <Button
          onClick={() => onLeftArrowTablesIndicatorClick()}
          style={{
            borderWidth: 1,
            borderColor: 'rgba(0,0,0,0.2)',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '600',
            color: 'black',
            backgroundColor: '#f1f1f1',
            borderRadius: 100,
          }}
        >
          {'<'}
        </Button>
        <Carousel
          showArrows={false}
          width={'90vw'}
          dynamicHeight
          showThumbs={false}
          showIndicators={false}
          selectedItem={selectedTableIndicator}
        >
          <div
            style={{
              display: 'flex',
              flex: 'auto',
              padding: '5px',
              flexBasis: 'max-content',
              flexDirection: 'row',
              marginLeft: '15px',
            }}
          >
            <Paper
              style={{
                marginLeft: '10px',
                marginBottom: '10px',
                height: '25vw',
                flex: 'auto',
                padding: '10px',
              }}
            >
              <div className='active-order-table-caption'>{'Список ПУ с ТКО'}</div>
              <DataGrid
                rows={tkos}
                sx={{ height: '21vw', overflowY: 'auto' }}
                columns={tkosColumns}
                getRowId={(row) => row.taskId}
                pageSize={20}
                rowsPerPageOptions={[20]}
                showColumnRightBorder
                showCellRightBorder
                isRowSelectable={() => false}
                disableColumnFilter={true}
                sortingOrder={['asc', 'desc']}
              />
            </Paper>
            <Paper
              style={{
                marginLeft: '15px',
                marginRight: '17px',
                height: '25vw',
                marginBottom: '10px',
                flex: 'auto',
                padding: '10px',
              }}
            >
              <div className='active-order-table-caption'>{'Список активных нарядов на ПУ'}</div>
              <DataGrid
                rows={activePuOrder}
                columns={activePuOrderColumns}
                getRowId={(row) => row.taskId}
                pageSize={20}
                rowsPerPageOptions={[20]}
                showColumnRightBorder
                showCellRightBorder
                isRowSelectable={() => false}
                disableColumnFilter={true}
                sx={{ height: '21vw', overflowY: 'auto' }}
                sortingOrder={['asc', 'desc']}
              />
            </Paper>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 'auto',
              padding: '5px',
              flexBasis: 'max-content',
              flexDirection: 'row',
              marginLeft: '15px',
            }}
          >
            <Paper
              style={{
                marginLeft: '10px',
                marginBottom: '10px',
                height: '25vw',
                flex: 'auto',
                padding: '10px',
              }}
            >
              <div className='active-order-table-caption'>{'Список активных нарядов на ОС'}</div>
              <DataGrid
                rows={activeOsOrder}
                columns={activeOsOrderColumns}
                getRowId={(row) => row.taskId}
                pageSize={20}
                rowsPerPageOptions={[20]}
                showColumnRightBorder
                showCellRightBorder
                isRowSelectable={() => false}
                disableColumnFilter={true}
                sx={{ height: '21vw', overflowY: 'auto' }}
                sortingOrder={['asc', 'desc']}
              />
            </Paper>
            <Paper
              style={{
                marginLeft: '15px',
                marginRight: '17px',
                height: '25vw',
                marginBottom: '10px',
                flex: 'auto',
                padding: '10px',
              }}
            >
              <div className='active-order-table-caption'>{'Список активных заявок на ОС'}</div>
              <DataGrid
                rows={activeOsTask}
                columns={activeOsTaskColumns}
                getRowId={(row) => row.taskId}
                pageSize={20}
                rowsPerPageOptions={[20]}
                showColumnRightBorder
                showCellRightBorder
                isRowSelectable={() => false}
                disableColumnFilter={true}
                sx={{ height: '21vw', overflowY: 'auto' }}
                sortingOrder={['asc', 'desc']}
              />
            </Paper>
          </div>
        </Carousel>
        <Button
          onClick={() => onRightArrowTablesIndicatorClick()}
          variant='outlined'
          style={{
            borderWidth: 1,
            borderColor: 'rgba(0,0,0,0.2)',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '600',
            color: 'black',
            backgroundColor: '#f1f1f1',
            borderRadius: 100,
          }}
        >
          {'>'}
        </Button>
      </div>
    </div>
  );
};
