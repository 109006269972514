import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { IssuedStamp } from '../IssuedStamp/IssuedStamp';
import { SupplyBillBrowse } from '../SupplyBillBrowse/SupplyBillBrowse';
import useSupplyStore from '../SupplyStore';
import { StoreHouseComponentWrapper } from './StoreHouseComponent.styled';
import StoreHouseTabPanel from './StoreHouseTabPanel';
import { useDisabledStatisticButton, useStorehouseWorkerAccess } from '../SupplyPUComponent/utils';
import SupplyStatistic from '../SupplyStatistic/SupplyStatistic';
import shallow from 'zustand/shallow';
import { getBillType } from './utils';

export default function StoreHouseComponent() {
  const [value, setValue] = React.useState(0);
  const workerAccess = useStorehouseWorkerAccess();
  const disableByRole = useDisabledStatisticButton();

  const { setBillType } = useSupplyStore(
    (state) => ({
      setBillType: state.setBillType,
    }),
    shallow
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setBillType(getBillType(newValue));
  };

  return (
    <StoreHouseComponentWrapper>
      <Tabs
        value={value}
        onChange={handleChange}
      >
        <Tab label='Архив' />

        <Tab
          label='Выдача'
          disabled={!workerAccess}
        />

        <Tab label='Принять возврат' />

        <Tab
          label='Приём товара'
          disabled={!workerAccess}
        />

        <Tab
          label='Внутреннее перемещение: выдача'
          disabled={!workerAccess}
        />

        <Tab
          label='Внутреннее перемещение: приём'
          disabled={!workerAccess}
        />

        <Tab
          label='Отправка на завод'
          disabled={!workerAccess}
        />

        <Tab
          label='Статистика'
          disabled={disableByRole}
        />
      </Tabs>

      <StoreHouseTabPanel
        value={value}
        index={1}
        unmount
      >
        <IssuedStamp tabType='deliver' />
      </StoreHouseTabPanel>

      <StoreHouseTabPanel
        value={value}
        index={2}
        unmount
      >
        <IssuedStamp tabType='return' />
      </StoreHouseTabPanel>

      <StoreHouseTabPanel
        value={value}
        index={3}
        unmount
      >
        <IssuedStamp tabType='factory_get' />
      </StoreHouseTabPanel>

      <StoreHouseTabPanel
        value={value}
        index={4}
        unmount
      >
        <IssuedStamp tabType='relocation_post' />
      </StoreHouseTabPanel>

      <StoreHouseTabPanel
        value={value}
        index={5}
        unmount
      >
        <IssuedStamp tabType='relocation_get' />
      </StoreHouseTabPanel>

      <StoreHouseTabPanel
        value={value}
        index={6}
        unmount
      >
        <IssuedStamp tabType='factory_post' />
      </StoreHouseTabPanel>

      <StoreHouseTabPanel
        value={value}
        index={0}
        unmount
      >
        <SupplyBillBrowse />
      </StoreHouseTabPanel>

      <StoreHouseTabPanel
        value={value}
        index={7}
        unmount
      >
        <SupplyStatistic />
      </StoreHouseTabPanel>
    </StoreHouseComponentWrapper>
  );
}
