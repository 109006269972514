import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const TaskControlPanelStack = styled(Stack)({
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

export const TaskControlPanelButtonWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  flexDirection: 'row-reverse',
});
