import { HOST } from './YodaRestService';
import { axiosDelete, axiosGet, axiosPatch, axiosPost, axiosPut } from './helpers';

import { AxiosResponse } from 'axios';
import { SubscriberParam, SubscriberResponse } from '@/dto/SubscribersDto';
import { TaskCCAbonent } from '@/dto/taskmap/Dto';

export async function editSub(
  id: string = '',
  data: SubscriberParam | TaskCCAbonent[]
): Promise<AxiosResponse<SubscriberParam, any>> {
  const url = HOST + `/rest/abonent${!!id ? `/${id}` : ''}`;
  return axiosPut(url, data);
}

export async function addSub(
  data: SubscriberParam
): Promise<AxiosResponse<SubscriberResponse[], any>> {
  const url = HOST + `/rest/abonent`;
  return axiosPost(url, data);
}

export async function getSub(id: string): Promise<AxiosResponse<SubscriberResponse, any>> {
  const url = HOST + `/rest/abonent/${id}`;
  return axiosGet(url);
}

export async function getSubList(
  taskId: string
): Promise<AxiosResponse<SubscriberResponse[], any>> {
  const url = HOST + `/rest/abonent/task/${taskId}`;
  return axiosGet(url);
}

export async function deleteSub(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/abonent/${id}`;
  return axiosDelete(url);
}
export async function changeAbonentActiveStatus(body: any): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/abonent/active`;
  return axiosPatch(url, body);
}
