export enum FilterArgEnum {
	IN_LIST,
	NOT_IN_LIST,
	EQUAL,
	NOT_EQUAL,
	CONTAIN,
	MORE,
	LESS,
	INSTALLED,
}

export type PhotPath = {
	path: string;
	title: string;
};
export type ArgumentSelectType = {
	registrName: string;
	argList?: FilterArgEnum[];
	onArgChange: any;
	defaultValue?: FilterArgEnum;
};

