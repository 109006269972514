import { Placemark } from 'react-yandex-maps';
import { GeoTaskInBounds } from '@/dto/taskmap/Dto';
import { TransStationPlacemark } from './trans-station';

interface PpoPlacemarkProps {
  geoTaskInBounds: GeoTaskInBounds;
}

export const PpoPlacemark = (props: PpoPlacemarkProps) => {
  const { geoTaskInBounds } = props;

  if (geoTaskInBounds.geoTaskList.length < 1000) {
    return (
      <>
        {geoTaskInBounds.geoTaskList.map((t) => {
          return (
            <TransStationPlacemark
              key={t.taskId}
              geoTask={t}
              // geoPoint={new PpoGeoPoint(t)}
            />
          );
        })}
      </>
    );
  }
  const centroid = geoTaskInBounds.geoCentroid;
  return (
    <Placemark
      geometry={[centroid.x, centroid.y]}
      options={{
        preset: 'islands#redStretchyIcon',
        iconColor: 'red',
      }}
      properties={{
        iconContent: geoTaskInBounds.geoTaskList.length,
      }}
    />
  );
};
