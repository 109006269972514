import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ReplacementResponse } from '@/dto/taskmap/task/TaskResultDto';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { toCancelReplacement } from '@/services/ReplacementService';

interface ReplacementCancelDialogProps {
  replacementId: string;
  openDialog: boolean;
  onCLose(): void;
  onReplacementChange(replacementResponse: ReplacementResponse): void;
}

export const ReplacementCancelDialog = (props: ReplacementCancelDialogProps) => {
  const { replacementId, openDialog, onCLose, onReplacementChange } = props;
  const [open, setOpen] = useState(false);
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const [reason, setReason] = useState<string | null>(null);
  const { setIsInProgress } = useScreenHoldHook();
  const { addActionLog, catchError } = useActionLog();

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = () => {
    setOpen(false);
    onCLose();
  };

  const toCancel = () => {
    setIsInProgress(true);
    toCancelReplacement(replacementId, reason!)
      .then((res) => {
        onReplacementChange(res.data);
        addActionLog(ActionLogType.SUCCESS, 'Замена ПУ отменена');
      })
      .catch((err) => catchError('Ошибка при отмене ПУ', err))
      .finally(() => {
        setIsInProgress(false);
      });
    onCLose();
  };

  const handleReasonSelect = (event: any) => {
    setReason(event?.target?.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='cancel-dialog-title'
      aria-describedby='cancel-dialog-description'
    >
      <DialogTitle id='replacement-reason-dialog-title'>Отменить замену ПУ</DialogTitle>
      <DialogContent>
        <FormControl
          variant='standard'
          sx={{ m: 1, minWidth: 450 }}
        >
          <InputLabel id='replacement-reason-dialog-label'>Причина</InputLabel>
          <Select
            labelId='replacement-reason-dialog-label'
            id='replacement-reason-dialog'
            defaultValue={''}
            onChange={handleReasonSelect}
            label='Age'
          >
            <MenuItem value={''}>
              <em>(пусто)</em>
            </MenuItem>
            {[...getCatalogMapWithErr('replacementReason').keys()].map((t) => (
              <MenuItem
                key={t}
                value={t}
              >
                {getCatalogMapWithErr('replacementReason').get(t)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        <Button
          onClick={toCancel}
          autoFocus
          disabled={reason === null || reason?.length === 0}
        >
          Оk
        </Button>
      </DialogActions>
    </Dialog>
  );
};
