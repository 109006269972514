import React, { FC } from 'react';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import { color } from '@/styles/mixins';

interface ToggleArrowProps {
  open: boolean;
}

const ToggleArrow: FC<ToggleArrowProps> = ({ open }) => {
  return (
    <span>
      {open ? (
        <KeyboardDoubleArrowRightRoundedIcon htmlColor={color('muiBlue')} />
      ) : (
        <KeyboardDoubleArrowLeftRoundedIcon htmlColor={color('muiBlue')} />
      )}
    </span>
  );
};

export default ToggleArrow;
