import React, { FC } from 'react';
import { default as ReactSelect } from 'react-select';
import {
  FormControl,
  InputLabel,
  InputLabelProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import './styles.scss';
import { ArgumentSelect } from '../inputs/ArgumentSelect';

export enum FilterArgEnum {
  IN_LIST,
  NOT_IN_LIST,
  EQUAL,
  NOT_EQUAL,
  CONTAIN,
  MORE,
  LESS,
  INSTALLED,
}

export type SelectFilterOptType = {
  label: string;
  value: any;
};

export type SelectFilterType = {
  options: SelectFilterOptType[];
  value: SelectFilterOptType[];
  minWidth?: number;
  label: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
  registArgName: string;
};

export type FilterArgument = {
  value: FilterArgEnum;
  label: string;
  flagPath: any;
};

export type SingleFilterParams = {
  type?: string;
};

export type SingleLineFilterType = {
  minWidth?: number;
  label: string;
  registerName: string;
  registerNameArg?: string;
  params?: SingleFilterParams;
  inputLabelProps?: InputLabelProps;
  argList?: FilterArgEnum[];
  defaultArg?: FilterArgEnum;
  textInputTransform?: (value?: string) => void;
};

const styles = {
  menu: (provided: any, state: any) => ({
    ...provided,
    width: 'max-content',
  }),
};
const defaultList = [
  FilterArgEnum.EQUAL,
  FilterArgEnum.NOT_EQUAL,
  FilterArgEnum.CONTAIN,
  FilterArgEnum.MORE,
  FilterArgEnum.LESS,
  FilterArgEnum.INSTALLED,
];

export function getValue(value: any): string[] {
  if (Array.isArray(value)) return value.map((o: any) => o.value);
  else return [(value as any).value];
}

/**
 *
 * @param props SingleLineFilterType if registryNameArg will be null will use registyName + 'Arg'
 * @returns
 */
export const SingleLineFilter: FC<SingleLineFilterType> = (props) => {
  const { register, resetField, setValue } = useFormContext();
  const {
    registerNameArg,
    registerName,
    argList = defaultList,
    defaultArg = FilterArgEnum.EQUAL,
  } = props;
  //we need it for clear up selected after argument isInstalled
  const [prevArgument, setPrevArgument] = React.useState<FilterArgEnum>();
  const [isboolAnswer, setIsboolAnswer] = React.useState<boolean>(false);
  const argName = registerNameArg ? registerNameArg : registerName + 'Arg';
  const onArgChange = (selected: SelectChangeEvent<FilterArgEnum>) => {
    const selEnum: FilterArgEnum = selected.target.value as FilterArgEnum;
    if (selEnum === FilterArgEnum.INSTALLED) {
      setIsboolAnswer(true);
      resetField(`${props.registerName}`);
    } else {
      setIsboolAnswer(false);
    }
    if (
      selected.target.value === FilterArgEnum.INSTALLED ||
      prevArgument === FilterArgEnum.INSTALLED
    )
      resetField(`${props.registerName}`);
    if (selEnum) setPrevArgument(selEnum);
  };
  return (
    <div>
      {isboolAnswer ? (
        <InputLabel sx={{ m: 0.2, minWidth: 10, paddingLeft: 1, fontSize: '0.8rem' }}>
          {props.label}
        </InputLabel>
      ) : (
        <div style={{ height: 10 }}>{}</div>
      )}
      <div className='parent'>
        <ArgumentSelect
          onArgChange={onArgChange}
          registrName={argName}
          defaultValue={defaultArg}
          argList={argList}
        />
        <FormControl
          className='child child_input'
          variant='standard'
          sx={{ m: 1, minWidth: props.minWidth }}
          style={{ margin: 0 }}
        >
          {isboolAnswer ? (
            <Select
              {...register(`${props.registerName}`)}
              style={{ height: 40, minWidth: 170 }}
            >
              <MenuItem value={'yes'}>Да</MenuItem>
              <MenuItem value={'no'}>Нет</MenuItem>
            </Select>
          ) : (
            <TextField
              {...register(`${props.registerName}`, {
                onChange: (e) => {
                  if (props.textInputTransform) {
                    setValue(props.registerName, props.textInputTransform(e.target.value));
                  }
                },
              })}
              label={props.label}
              InputLabelProps={props.inputLabelProps ? props.inputLabelProps : {}}
              {...props.params}
              variant='standard'
            />
          )}
        </FormControl>
      </div>
    </div>
  );
};

export const SingleSelectFilter: FC<SelectFilterType> = (props) => {
  React.useEffect(() => {
    setOptionList([...props.options]);
  }, [props.options]);

  const [optionList, setOptionList] = React.useState<any[]>([...props.options]);
  return (
    <div>
      <InputLabel sx={{ m: 0.2, minWidth: 10, paddingLeft: 1, fontSize: '0.8rem' }}>
        {props.label}
      </InputLabel>
      <div className='parent'>
        <FormControl
          className='child'
          variant='standard'
          sx={{ m: 1, minWidth: props.minWidth }}
          style={{ margin: 0 }}
        >
          <ReactSelect
            {...props}
            options={optionList}
            isMulti={false}
            styles={styles}
            closeMenuOnSelect={true}
            placeholder={props.label}
            onChange={(selected: any) => {
              if (selected !== null && selected.length > 0) {
                return props.onChange(props.options);
              }
              return props.onChange(selected);
            }}
          />
        </FormControl>
      </div>
    </div>
  );
};
