import { styled } from '@mui/material';

interface HouseEntranceFlatCountMatchedWrapperProps {
  border?: string;
}

export const HouseEntranceFlatCountMatchedWrapper = styled(
  'div'
)<HouseEntranceFlatCountMatchedWrapperProps>(({ border }) => ({
  borderRadius: 15,
  padding: 8,
  ...(border && {
    border: border,
  }),
}));
