import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import { useUspdMapStore } from './store';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';

type PolygonInfoBlockProps = {
  handleStopDrawing: () => void;
  downloadExcelFile: () => void;
  isDrawing: boolean;
  startDrawing: () => void;
  clearPolygon: () => void;
};

const PolygonInfoBlock = ({
  handleStopDrawing,
  downloadExcelFile,
  isDrawing,
  startDrawing,
  clearPolygon,
}: PolygonInfoBlockProps) => {
  const { polygonMod } = useUspdMapStore((state) => ({
    polygonMod: state.polygonMod,
  }));

  return (
    <Stack
      spacing={1}
      mb={2}
      mt={2}
      borderRadius={2}
      flexDirection={'row'}
      justifyContent={'flex-end'}
      alignItems={'flex-end'}
      gap={1}
      p={1}
    >
      <Box>
        <Typography variant='h6'>полигон</Typography>
      </Box>

      <Tooltip title={'выгрузить отчет'}>
        <IconButton
          aria-label='download'
          color='primary'
          onClick={downloadExcelFile}
        >
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>

      {polygonMod === 'edit' && (
        <Tooltip title={'редактирование'}>
          <IconButton
            aria-label='edit'
            // disabled
            onClick={startDrawing}
            color='primary'
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {polygonMod === 'stop' && (
        <Tooltip title={'режим редактирования завершен'}>
          <IconButton
            aria-label='stop'
            onClick={handleStopDrawing}
            color='primary'
          >
            <BlockIcon />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={'закрыть полигон'}>
        <IconButton
          aria-label='stop'
          onClick={clearPolygon}
          color='warning'
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default PolygonInfoBlock;
