export class SupplyConstant {
  //Выдача
  static readonly ISSUED_TYPE = 1;
  //Принять возврат
  static readonly RETURNED_TYPE = 101;
  //Черновик выдачи
  static readonly DRAFT_TYPE_ISSUE = 201;
  //Черновик возврата
  static readonly DRAFT_TYPE_RETURN = 301;
  //Приём товара
  static readonly FACTORY_TYPE_GET = 401;
  //Внутреннее перемещение: в пути
  static readonly RELOCATION_TYPE_POST = 501;
  //Внутреннее перемещение: черновик выдачи
  static readonly DRAFT_TYPE_RELOCATION_POST = 601;
  //Внутреннее перемещение: приём
  static readonly RELOCATION_TYPE_GET = 701;
  //Отправка на завод
  static readonly FACTORY_TYPE_POST = 801;
  //Отправка на завод: черновик
  static readonly DRAFT_TYPE_FACTORY_TYPE_POST = 901;
  //Внутреннее перемещение: принято
  static readonly RELOCATION_TYPE_ACCEPTED = 1001;
  //Возврат. Ожидание согласования Руководителем проекта
  static readonly RETURNED_TYPE_WAITING_APPROVAL_PROJECT = 1101;
  //Возврат. Согласована Руководителем проекта
  static readonly RETURNED_TYPE_AGREED_PROJECT = 1201;
  //Черновик приема
  static readonly DRAFT_FACTORY_TYPE_GET = 1301;

  static readonly stampLimit = 10000;
  static readonly puListLimit = 10000;
  static readonly PU_ITEM_NOT_FULL_COMPLEETNESS = 2;
  static readonly PU_ITEM_FULL_COMPLEETNESS = 1;
}

export type StorehouseBillType =
  | typeof SupplyConstant.ISSUED_TYPE
  | typeof SupplyConstant.RETURNED_TYPE
  | typeof SupplyConstant.DRAFT_TYPE_ISSUE
  | typeof SupplyConstant.DRAFT_TYPE_RETURN
  | typeof SupplyConstant.FACTORY_TYPE_GET
  | typeof SupplyConstant.RELOCATION_TYPE_POST
  | typeof SupplyConstant.DRAFT_TYPE_RELOCATION_POST
  | typeof SupplyConstant.RELOCATION_TYPE_GET
  | typeof SupplyConstant.FACTORY_TYPE_POST
  | typeof SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST
  | typeof SupplyConstant.RELOCATION_TYPE_ACCEPTED
  | typeof SupplyConstant.RETURNED_TYPE_WAITING_APPROVAL_PROJECT
  | typeof SupplyConstant.RETURNED_TYPE_AGREED_PROJECT
  | typeof SupplyConstant.DRAFT_FACTORY_TYPE_GET;
