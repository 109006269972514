import React from 'react';
import { Tooltip } from '@mui/material';
import { PnrPlanningModalFirstStepRowProps } from './PnrPlanningModalFirstStepRow';
import {
  PnrPlanningModalInfoField,
  PnrPlanningModalFirstStepRowBackground,
  PnrPlanningModalFirstStepRowWrapper,
  PnrPlanningsRowLoading,
} from './PnrPlanningModalSteps.styled';
import { FLOOR_OPTION_VALUE } from './util';
import { NetworkPlanningTypes } from '../PnrElementsInfo/utils';

const PnrPlanningModalSecondStepRow = ({
  data,
  index,
  style,
  isScrolling,
}: PnrPlanningModalFirstStepRowProps) => {
  const row = data?.currentTypePlannedInstalations[index];

  if (row.deleted || row.type === NetworkPlanningTypes.PREPLAN) return null;

  return (
    <div style={style}>
      {isScrolling ? (
        <PnrPlanningModalFirstStepRowWrapper>
          <PnrPlanningsRowLoading
            loading
            secondStep
          />
        </PnrPlanningModalFirstStepRowWrapper>
      ) : (
        <PnrPlanningModalFirstStepRowWrapper>
          <PnrPlanningModalFirstStepRowBackground isEdited>
            <PnrPlanningModalInfoField
              label='Подъезд'
              value={row?.entrance?.toString()}
            />

            <PnrPlanningModalInfoField
              label='Тип оборудования'
              value={row.equipmentTypeFilter?.label}
            />

            {row.montagePlace?.value === FLOOR_OPTION_VALUE ? (
              <PnrPlanningModalInfoField
                label='Этаж'
                value={row?.houseLevel?.floorNumber?.toString() || '0'}
              />
            ) : (
              <PnrPlanningModalInfoField
                label='Расположение'
                value={row?.montagePlace?.label}
              />
            )}

            <PnrPlanningModalInfoField
              label='Признак'
              value={row.planningTypeFilter?.label}
            />

            {row?.comment ? (
              <Tooltip
                title={row?.comment}
                placement='top-start'
              >
                <div>
                  <PnrPlanningModalInfoField
                    label='Комментарий'
                    value={row?.comment}
                  />
                </div>
              </Tooltip>
            ) : (
              <PnrPlanningModalInfoField label='' />
            )}
          </PnrPlanningModalFirstStepRowBackground>
        </PnrPlanningModalFirstStepRowWrapper>
      )}
    </div>
  );
};

export default PnrPlanningModalSecondStepRow;
