import { styled } from '@mui/material';

export const ComplaintResultTaskBlockWrapper = styled('div')({
  disaply: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: 15,
  backgroundColor: 'white',

  borderRadius: 15,
});
