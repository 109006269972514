import React, { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { color } from '@/styles/mixins';
import PpoHousePopup from './PpoPopupHouse';
import PpoHouseText from './PpoHouseText';

export const PpoHouseInput: FC<any> = (props) => {
  const { setValue, handleSubmit } = useFormContext();
  const { id } = props.houseResponse || {};
  setValue('houseRequest.id', id);
  const { onSave, houseInfo } = props;

  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const handleEdit = () => {
    handleSubmit(onSave)();
    setIsOpenPopup(false);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          container
          spacing={2}
          alignItems={'center'}
        >
          <Grid
            xs={12}
            item
          >
            <Grid
              container
              gap={1}
              alignItems={'center'}
            >
              {houseInfo ? (
                <IconButton
                  size='small'
                  aria-label='edit'
                  onClick={() => setIsOpenPopup(true)}
                >
                  <EditIcon style={{ color: color('aBlue') }} />
                </IconButton>
              ) : (
                <IconButton
                  size='small'
                  aria-label='edit'
                  onClick={() => setIsOpenPopup(true)}
                >
                  <AddIcon style={{ color: color('aBlue') }} />
                </IconButton>
              )}

              <Typography variant='h5'>{'Характеристики дома:'} </Typography>
              <PpoHouseText values={houseInfo || {}} />
            </Grid>
          </Grid>
          <PpoHousePopup
            values={houseInfo}
            handleEdit={handleEdit}
            isOpenPopup={isOpenPopup}
            onClose={() => setIsOpenPopup(false)}
          />
        </Grid>
      </Grid>
    </>
  );
};
