import { Popup } from '@/components/popups';
import { Grid, Typography, Button } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';

interface PpoHousePopupProps {
  onClose: () => void;
  handleEdit: () => void;
  isOpenPopup: boolean;
  values: Record<string, string | null>;
}

const PpoHousePopup = ({ onClose, isOpenPopup, values, handleEdit }: PpoHousePopupProps) => {
  const { cipher, flatCount, floorCount, entranceCount, elevatorCount, hasElectricStove, note } =
    values || {};

  const { register } = useFormContext();
  const yesNoMap = new Map([
    ['true', 'Да'],
    ['false', 'Нет'],
  ]);

  return (
    <Popup
      isOpen={isOpenPopup}
      onClose={onClose}
      backdropStyles={{ background: 'rgba(0, 0, 0, 0.29)' }}
    >
      <Typography
        textAlign={'center'}
        variant='h5'
      >
        {'Изменить характеристики дома'}
      </Typography>
      <Grid
        container
        spacing={2}
      >
        <Grid
          xs={6}
          item
        >
          <TextField
            id='house-elevator'
            label='Шифр'
            variant='standard'
            {...register(`houseRequest.cipher`, { value: cipher })}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            id='house-flat'
            label='Кол-во квартир'
            variant='standard'
            {...register(`houseRequest.flatCount`, { value: flatCount })}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            id='house-floor'
            label='Кол-во этажей'
            variant='standard'
            {...register(`houseRequest.floorCount`, { value: floorCount })}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            id='house-entrance'
            label='Кол-во подьездов'
            variant='standard'
            {...register(`houseRequest.entranceCount`, { value: entranceCount })}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            id='house-elevator'
            label='Кол-во лифтов'
            variant='standard'
            {...register(`houseRequest.elevatorCount`, { value: elevatorCount })}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <AutocompleteValue
            title='Электрические плиты'
            defaultValue={hasElectricStove ? `${hasElectricStove.toString()}` : undefined}
            fieldName={`houseRequest.hasElectricStove`}
            values={yesNoMap}
          />
        </Grid>
        <Grid
          xs={12}
          item
        >
          <TextField
            id='comment'
            label='Комментарий'
            {...register(`houseRequest.note`, { value: note })}
            sx={{ width: '100%' }}
            multiline
            maxRows={2}
            rows={2}
          />
        </Grid>
        <Grid
          xs={12}
          item
          display={'flex'}
          justifyContent={'center'}
          gap={3}
        >
          <Button onClick={handleEdit}>применить</Button>
          <Button onClick={onClose}> отмена</Button>
        </Grid>
      </Grid>
    </Popup>
  );
};

export default PpoHousePopup;
