import {
  Autocomplete,
  CircularProgress,
  TextField,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import { Popper } from '@/components/fixUI/Popper';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import React, { useEffect, useState } from 'react';
import {
  SupplyPUCommentModalButtonsWrapper,
  SupplyPUCommentModalWrapper,
  SupplyPUCommentTextField,
  SupplyPUTableCell,
  SupplyPUTableCellLoading,
  SupplyPUTableCommentCell,
} from '../SupplyPUComponent/SupplyPUComponent.styled';
import {
  NOT_FULL_PU_COMPLETENESS_VALUE,
  useGeneratedYears,
  MAX_INT_VALUE,
  PU_TASK_NUMBER_OPTIONS,
  PU_TASK_NUMBER_INSTALLED_OPTION,
  PU_TASK_NUMBER_NOT_INSTALLED_OPTION,
  ActiveCommentModalState,
  CommentModalState,
  ActiveCommentModal,
  useStorehouseWorkerAccess,
  useTaskNumberCheckState,
  EquipmentState,
} from '../SupplyPUComponent/utils';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import {
  useAllPuCompleteness,
  useAllPuOwner,
  useAllEquipmentState,
  useAllPuDefectCause,
  useAllOsDefectCause,
} from '@/hooks/useQuery/useAllSupplyCatalogs';
import { SupplyNetworkElementItem, SupplyPUItem } from '@/dto/taskmap/Dto';
import { SupplyBillApiCheckTaskNumber } from '@/services/SupplyBillService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { EquipmentTypes, getNumberFromString } from '../utils';
import { KEYS } from '@/hooks/useQuery/Keys';
import { useQuery } from 'react-query';
import { FetchCathError } from '@/types/api';
import useSupplyStore from '../SupplyStore';
import { SupplyConstant } from '../SupplyConstant';
import { Popup } from '@/components/popups';
import { SelectedPu } from '../SupplyPUComponent/SupplyPUComponent';
import { SelectedOs } from '../SupplyOsComponent/SupplyOSComponent';
import { EMPTY_RETURN_NEW_ITEM_CELLS } from './utils';

export interface SavePuItem extends Omit<SupplyPUItem, 'taskNumber'> {
  taskNumber?: TaskNumberState | null | string | number;
}

export interface SaveOsItem extends Omit<SupplyNetworkElementItem, 'taskNumber'> {
  taskNumber?: TaskNumberState | null | string | number;
}

export interface SupplyReturnBillExtraColumnsProps {
  row: SupplyPUItem | SupplyNetworkElementItem;
  handleSave: (puItem: SavePuItem | SaveOsItem) => void;
  showMessage: (text: string, alert?: boolean) => void;
  disabled?: boolean;
  type: EquipmentTypes;
  selectedEquipment?: SelectedPu | SelectedOs;
}

export interface TaskNumberState {
  installedValue: null | SelectFilterOptType;
  taskNumberValue?: string | null;
}

const SupplyReturnBillExtraColumns = ({
  row,
  handleSave,
  disabled,
  showMessage,
  type,
  selectedEquipment,
}: SupplyReturnBillExtraColumnsProps) => {
  const workerAccess = useStorehouseWorkerAccess();

  const checkedTaskNumbers = useSupplyStore((state) => state.checkedTaskNumbers);
  const returnNewItemsMode = useSupplyStore((state) => state.returnNewItemsMode);
  const forcedTaskNumberInfo = useSupplyStore((state) => state.forcedTaskNumberInfo);
  const forcedTaskInfoForRow =
    row.number && forcedTaskNumberInfo.get(`${selectedEquipment?.parentTypeName}-${row.number}`);

  useEffect(() => {
    if (forcedTaskInfoForRow && typeof forcedTaskInfoForRow === 'string') {
      return;
    }

    if (
      forcedTaskInfoForRow &&
      typeof forcedTaskInfoForRow === 'object' &&
      forcedTaskInfoForRow.originalAddress &&
      forcedTaskInfoForRow.taskNumber
    ) {
      setTaskNumberState({
        installedValue: PU_TASK_NUMBER_INSTALLED_OPTION,
        taskNumberValue: forcedTaskInfoForRow.taskNumber.toString(),
      });
      setAddressComment({
        prevValue: forcedTaskInfoForRow.originalAddress,
        text: forcedTaskInfoForRow.originalAddress,
      });
      setDisableFromYodaTask(true);

      checkedTaskNumbers.set(forcedTaskInfoForRow.taskNumber.toString(), 'success');
    }
  }, [forcedTaskInfoForRow, row.number]);

  const { puCompletenessOptions } = useAllPuCompleteness();
  const { equipmentStateOptions } = useAllEquipmentState();
  const { puDefectOptions } = useAllPuDefectCause();
  const { osDefectOptions } = useAllOsDefectCause();
  const { puOwnerOptions } = useAllPuOwner();

  const defectOptions = type === EquipmentTypes.PU ? puDefectOptions : osDefectOptions;

  const yearsOptions = useGeneratedYears();
  const { fetchCatch } = useActionLog();

  const [completeness, setCompleteness] = useState<SelectFilterOptType | null>(() => {
    const neededCompleteness = puCompletenessOptions.find(
      (option) => option.value === row?.completeness?.id
    );
    return row.completeness
      ? { label: neededCompleteness?.label, value: neededCompleteness?.value }
      : null;
  });
  const [owner, setOwner] = useState<SelectFilterOptType | null>(() => {
    const neededId = puOwnerOptions.find((option) => option.value === row?.ownerId);
    return row.ownerId ? { label: neededId?.label, value: neededId?.value } : null;
  });
  const [equipmentState, setEquipmentState] = useState<SelectFilterOptType | null>(() => {
    const neededState = equipmentStateOptions.find((option) => option.value === row?.state?.id);
    return row.state ? { label: neededState?.label, value: neededState?.value } : null;
  });
  const [defectCause, setDefectCause] = useState<SelectFilterOptType | null>(() => {
    const neededState = defectOptions.find((option) => option.value === row?.defectCause?.id);
    return row.defectCause ? { label: neededState?.label, value: neededState?.value } : null;
  });
  const [puYear, setPuYear] = useState<SelectFilterOptType | null>(() =>
    row.manufactureYear
      ? { value: row.manufactureYear, label: row.manufactureYear.toString() }
      : null
  );
  const [taskNumberState, setTaskNumberState] = useState<TaskNumberState>(() => {
    const getInstallValue = () => {
      if (!row?.taskNumber) {
        return PU_TASK_NUMBER_NOT_INSTALLED_OPTION;
      }
      return PU_TASK_NUMBER_INSTALLED_OPTION;
    };
    return {
      installedValue: getInstallValue(),
      taskNumberValue: row?.taskNumber?.toString() || null,
    };
  });

  const { checkingTaskNumber, errorTaskNumber, successTaskNumber } = useTaskNumberCheckState(
    taskNumberState?.taskNumberValue
  );

  const [completenessComment, setCompletenessComment] = useState(() => ({
    text: row?.completenessComment || '',
    prevValue: '',
  }));

  const [addressComment, setAddressComment] = useState(() => ({
    text: row?.address || '',
    prevValue: '',
  }));

  const [generalComment, setGeneralComment] = useState(() => ({
    text: row?.generalComment || '',
    prevValue: '',
  }));
  const [supplyComment, setSupplyComment] = useState(() => ({
    text: row?.supplyComment || '',
    prevValue: '',
  }));

  const [activeCommentModal, setActiveCommentModal] = useState<ActiveCommentModalState>(null);
  const [disableFromYodaTask, setDisableFromYodaTask] = useState(false);

  const { refetch, isFetching } = useQuery({
    queryKey: [...KEYS.checkTaskNumber, row.tmpId, taskNumberState.taskNumberValue],
    refetchOnWindowFocus: false,
    queryFn: () => SupplyBillApiCheckTaskNumber(taskNumberState.taskNumberValue),
    enabled: false,

    onSettled(response, error: FetchCathError | null) {
      if (!taskNumberState.taskNumberValue) return;
      const errorTask = () => {
        if (taskNumberState.taskNumberValue) {
          checkedTaskNumbers.set(taskNumberState.taskNumberValue, 'error');
        }
      };
      if (response?.data) {
        checkedTaskNumbers.set(taskNumberState.taskNumberValue, 'success');
        setAddressComment({
          prevValue: response.data?.address || '',
          text: response.data?.address || '',
        });
      } else {
        showMessage(`Заявка с номером ${taskNumberState.taskNumberValue} отсутствует`);
        errorTask();
      }
      if (error) {
        fetchCatch(error);
        errorTask();
      }
    },
  });

  useEffect(() => {
    saveCurrentState();
  }, [
    taskNumberState,
    completenessComment,
    puYear,
    owner,
    equipmentState,
    defectCause,
    completeness,
    taskNumberState.installedValue,
    addressComment,
    generalComment,
    supplyComment,
  ]);

  const saveCurrentState = () =>
    handleSave({
      ...row,
      taskNumber: taskNumberState,
      address: addressComment.text || null,
      generalComment: generalComment.text || null,
      supplyComment: supplyComment.text || null,
      manufactureYear: puYear?.value || null,
      ownerId: owner?.value || null,
      state: equipmentState ? { id: equipmentState.value, name: equipmentState?.label } : null,
      defectCause: defectCause ? { id: defectCause.value, name: defectCause?.label } : null,
      completeness: completeness ? { id: completeness.value, name: completeness?.label } : null,
      completenessComment: completenessComment.text || null,
    });

  const checkTaskNumberExists = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    saveCurrentState();
    if (!value) return;
    if (taskNumberState.taskNumberValue) {
      checkedTaskNumbers.set(taskNumberState.taskNumberValue, 'checking');
    }

    refetch();
  };

  const handleTaskNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value) {
      const numberValue = getNumberFromString(value);
      if (numberValue <= MAX_INT_VALUE) {
        setTaskNumberState((prev) => ({
          ...prev,
          taskNumberValue: getNumberFromString(value).toString(),
        }));
      }
    } else
      setTaskNumberState((prev) => ({
        ...prev,
        taskNumberValue: '',
      }));
  };

  const commentDisable = completeness?.value !== NOT_FULL_PU_COMPLETENESS_VALUE;
  const commentError = !completenessComment.text && !commentDisable;
  const showTaskNumberIcon = !isFetching && taskNumberState.taskNumberValue && !checkingTaskNumber;
  const handleCompletenes = (value: SelectFilterOptType | null) => {
    setCompleteness(value);
    if (!value || value.value === SupplyConstant.PU_ITEM_FULL_COMPLEETNESS) {
      setCompletenessComment({ text: '', prevValue: '' });
    }
  };

  const resetTaskNumber = () => {
    setTaskNumberState({
      installedValue: PU_TASK_NUMBER_NOT_INSTALLED_OPTION,
      taskNumberValue: null,
    });

    setAddressComment({ prevValue: '', text: '' });
  };

  const handleCommentModalClose = () => {
    switch (activeCommentModal) {
      case CommentModalState.completeness:
        setCompletenessComment((prev) => ({ ...prev, text: prev.prevValue }));
        break;

      case CommentModalState.adress:
        setAddressComment((prev) => ({ ...prev, text: prev.prevValue }));
        break;

      case CommentModalState.general:
        setGeneralComment((prev) => ({ ...prev, text: prev.prevValue }));
        break;

      case CommentModalState.storehouse:
        setSupplyComment((prev) => ({ ...prev, text: prev.prevValue }));
        break;

      default:
        break;
    }
    handleCommentSave();
  };

  const handleCommentSave = () => setActiveCommentModal(null);

  const disableActiveTaskCommentModal =
    taskNumberState.installedValue?.value === PU_TASK_NUMBER_INSTALLED_OPTION.value;

  const getActiveCommentModal = () => {
    const activeState: ActiveCommentModal = {
      value: undefined,
      onChange: undefined,
      error: undefined,
      disableChange: undefined,
    };

    if (activeCommentModal) {
      switch (activeCommentModal) {
        case CommentModalState.completeness:
          activeState.value = completenessComment.text;
          activeState.error = commentError;
          activeState.onChange = (event) => {
            setCompletenessComment((prev) => ({ ...prev, text: event.target.value }));
          };

          break;

        case CommentModalState.adress:
          activeState.value = addressComment.text;
          activeState.error = !addressComment.text.length && !disableActiveTaskCommentModal;
          activeState.onChange = (event) => {
            setAddressComment((prev) => ({ ...prev, text: event.target.value }));
          };
          activeState.disableChange = row?.taskNumber !== null || disableFromYodaTask;

          break;

        case CommentModalState.general:
          activeState.value = generalComment.text;
          activeState.error = undefined;
          activeState.onChange = (event) => {
            setGeneralComment((prev) => ({ ...prev, text: event.target.value }));
          };

          break;

        case CommentModalState.storehouse:
          activeState.value = supplyComment.text;
          activeState.error = !supplyComment.text && row.returnStorehouseAdded;
          activeState.onChange = (event) => {
            setSupplyComment((prev) => ({ ...prev, text: event.target.value }));
          };

          break;

        default:
          break;
      }
    }

    return activeState;
  };

  const handleEnterComment = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleCommentSave();
  };

  const activeCommentModalState = getActiveCommentModal();
  const addressCommentError =
    !addressComment.text.length &&
    taskNumberState.installedValue?.label === PU_TASK_NUMBER_NOT_INSTALLED_OPTION.label;

  const disableSaveCommentButton =
    activeCommentModal === CommentModalState.storehouse && !workerAccess;

  const returnNewItemsModeDisabled = disabled || returnNewItemsMode;

  if (returnNewItemsMode && row.rowNum !== 1)
    return (
      <>
        {EMPTY_RETURN_NEW_ITEM_CELLS?.map((cell, index) => (
          <SupplyPUTableCell key={index} />
        ))}
      </>
    );

  const disabledDefect =
    disabled ||
    !equipmentState ||
    equipmentState?.value === EquipmentState.VANDALISM ||
    equipmentState?.value === EquipmentState.OLD ||
    equipmentState?.value === EquipmentState.NEW;

  return (
    <>
      <SupplyPUTableCell>
        <Autocomplete
          fullWidth
          disabled={disabled}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          PopperComponent={Popper}
          options={yearsOptions}
          value={puYear}
          onChange={(_event: any, newValue: any) => setPuYear(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!puYear}
              variant='standard'
            />
          )}
        />
      </SupplyPUTableCell>
      <SupplyPUTableCell>
        <Autocomplete
          fullWidth
          disabled={disabled}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          PopperComponent={Popper}
          options={puCompletenessOptions}
          value={completeness}
          onChange={(_event: any, newValue: any) => {
            handleCompletenes(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!completeness}
              variant='standard'
            />
          )}
        />
      </SupplyPUTableCell>
      <SupplyPUTableCommentCell
        onClick={() => {
          setCompletenessComment((prev) => ({ ...prev, prevValue: prev.text }));
          setActiveCommentModal(CommentModalState.completeness);
        }}
        disabled={commentDisable}
      >
        <SupplyPUCommentTextField
          variant='standard'
          value={completenessComment.text}
          error={commentError}
          disabled={commentDisable}
        />
      </SupplyPUTableCommentCell>
      <SupplyPUTableCell>
        <Autocomplete
          fullWidth
          disabled={disabled}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          PopperComponent={Popper}
          options={puOwnerOptions}
          value={owner}
          onChange={(_event: any, newValue: any) => setOwner(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!owner}
              variant='standard'
            />
          )}
        />
      </SupplyPUTableCell>
      <SupplyPUTableCell>
        <Autocomplete
          fullWidth
          disabled={disabled}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          options={equipmentStateOptions}
          PopperComponent={Popper}
          value={equipmentState}
          getOptionDisabled={(option) =>
            returnNewItemsMode &&
            (option as SelectFilterOptType).value !== EquipmentState.NEW &&
            (option as SelectFilterOptType).value !== EquipmentState.REPAIRED &&
            (option as SelectFilterOptType).value !== EquipmentState.DEFECT
          }
          onChange={(_event: any, newValue: any) => setEquipmentState(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!equipmentState}
              variant='standard'
            />
          )}
        />
      </SupplyPUTableCell>
      <SupplyPUTableCell>
        <Autocomplete
          fullWidth
          disabled={disabledDefect}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          options={defectOptions}
          PopperComponent={Popper}
          value={defectCause}
          onChange={(_event: any, newValue: any) => setDefectCause(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!disabledDefect && !defectCause}
              variant='standard'
            />
          )}
        />
      </SupplyPUTableCell>

      <SupplyPUTableCell>
        {taskNumberState.installedValue?.value === PU_TASK_NUMBER_INSTALLED_OPTION.value ? (
          <>
            <TextField
              onBlur={checkTaskNumberExists}
              variant='standard'
              disabled={disabled || disableFromYodaTask}
              value={taskNumberState.taskNumberValue}
              error={
                !isFetching &&
                !disabled &&
                (taskNumberState.taskNumberValue?.length ? errorTaskNumber : true)
              }
              title={taskNumberState.taskNumberValue || undefined}
              onChange={handleTaskNumberChange}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={resetTaskNumber}
                    disabled={disabled || disableFromYodaTask}
                  >
                    <CloseIcon fontSize={'small'} />
                  </IconButton>
                ),
              }}
              {...(showTaskNumberIcon && successTaskNumber && { color: 'success', focused: true })}
            />

            <SupplyPUTableCellLoading>
              {isFetching && <CircularProgress size={10} />}
              {showTaskNumberIcon && !successTaskNumber && <CloseIcon color='error' />}
              {showTaskNumberIcon && successTaskNumber && <CheckIcon color='success' />}
            </SupplyPUTableCellLoading>
          </>
        ) : (
          <Autocomplete
            fullWidth
            disabled={returnNewItemsModeDisabled || disableFromYodaTask}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            PopperComponent={Popper}
            options={PU_TASK_NUMBER_OPTIONS}
            value={taskNumberState.installedValue || undefined}
            disableClearable
            onChange={(_event: any, newValue: SelectFilterOptType | null) => {
              setTaskNumberState((prev) => ({ ...prev, installedValue: newValue }));
              if (newValue?.value === PU_TASK_NUMBER_INSTALLED_OPTION.value) {
                setTaskNumberState({
                  installedValue: PU_TASK_NUMBER_INSTALLED_OPTION,
                  taskNumberValue: '',
                });
              }
              setAddressComment({ prevValue: '', text: '' });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!taskNumberState.installedValue}
                variant='standard'
              />
            )}
          />
        )}
      </SupplyPUTableCell>
      <SupplyPUTableCommentCell
        onClick={() => {
          setAddressComment((prev) => ({ ...prev, prevValue: prev.text }));
          setActiveCommentModal(CommentModalState.adress);
        }}
        disabled={disabled}
      >
        <SupplyPUCommentTextField
          variant='standard'
          value={addressComment.text}
          error={addressCommentError}
        />
      </SupplyPUTableCommentCell>
      <SupplyPUTableCommentCell
        onClick={() => {
          setGeneralComment((prev) => ({ ...prev, prevValue: prev.text }));
          setActiveCommentModal(CommentModalState.general);
        }}
        disabled={disabled}
      >
        <SupplyPUCommentTextField
          variant='standard'
          value={generalComment.text}
        />
      </SupplyPUTableCommentCell>
      <SupplyPUTableCommentCell
        onClick={() => {
          setSupplyComment((prev) => ({ ...prev, prevValue: prev.text }));
          setActiveCommentModal(CommentModalState.storehouse);
        }}
        disabled={returnNewItemsMode}
      >
        <SupplyPUCommentTextField
          variant='standard'
          value={supplyComment.text}
        />
      </SupplyPUTableCommentCell>

      <Popup
        isOpen={!!activeCommentModal}
        onClose={handleCommentModalClose}
      >
        <SupplyPUCommentModalWrapper>
          <Typography variant='h6'>{activeCommentModal}</Typography>

          <TextField
            variant='standard'
            value={activeCommentModalState.value}
            sx={{ width: '75%' }}
            multiline
            autoFocus
            maxRows={6}
            onChange={activeCommentModalState.onChange}
            error={!!activeCommentModalState.error}
            disabled={disableSaveCommentButton || disabled || activeCommentModalState.disableChange}
            onKeyDown={handleEnterComment}
          />

          <SupplyPUCommentModalButtonsWrapper>
            <Button
              onClick={handleCommentSave}
              variant='contained'
              disabled={
                disableSaveCommentButton || disabled || activeCommentModalState.disableChange
              }
            >
              Сохранить
            </Button>

            <Button
              onClick={handleCommentModalClose}
              variant='contained'
            >
              Отменить
            </Button>
          </SupplyPUCommentModalButtonsWrapper>
        </SupplyPUCommentModalWrapper>
      </Popup>
    </>
  );
};

export default React.memo(SupplyReturnBillExtraColumns);
