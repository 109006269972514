import { color } from '@/styles/mixins';
import { Grid, Tooltip, Typography } from '@mui/material';

import { useCatalog } from '@/hooks/CatalogHook';
import shallow from 'zustand/shallow';
import CopyButton from '@/components/button/CopyButton';
import { IInstallationCall } from '../types/types';

interface CommonTaskInfoProps {
  taskId: string | null;
  singleGeoPoint: IInstallationCall | null;
  showAllContent?: boolean;
}

const CommonTaskInfo = ({ taskId, singleGeoPoint, showAllContent = true }: CommonTaskInfoProps) => {
  const { getCatalogMapWithErr, taskGroupMap } = useCatalog(
    (state) => ({
      getCatalogMapWithErr: state.getCatalogMapWithErr,
      taskGroupMap: state.taskGroupMap,
    }),
    shallow
  );

  return (
    <>
      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={3}
        >
          <Tooltip title={taskId!}>
            <Typography
              color={color('typographyColorMap2')}
              variant='body1'
            >
              ID заявки
            </Typography>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <CopyButton textToCopy={taskId!} />
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={3}
        >
          <Typography
            color={color('typographyColorMap2')}
            variant='body1'
          >
            Статус заявки
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <Typography color={color('typographyColorMap1')}>
            {getCatalogMapWithErr('taskStatus').get(singleGeoPoint?.taskStatus ?? '')}
          </Typography>
        </Grid>
      </Grid>
      {showAllContent && (
        <Grid
          container
          xs={12}
        >
          <Grid
            item
            xs={3}
          >
            <Typography
              color={color('typographyColorMap2')}
              variant='body1'
            >
              Тип обращения
            </Typography>
          </Grid>
          <Grid
            item
            xs={9}
          >
            <Typography color={color('typographyColorMap1')}>
              {singleGeoPoint?.requestCallType}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={3}
        >
          <Typography
            color={color('typographyColorMap2')}
            variant='body1'
          >
            Тип заявки
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <Typography color={color('typographyColorMap1')}>
            {getCatalogMapWithErr('taskType').get(singleGeoPoint?.taskType ?? '') ?? 'неизвестен'}
          </Typography>
        </Grid>
      </Grid>
      {showAllContent && (
        <>
          <Grid
            container
            xs={12}
          >
            <Grid
              item
              xs={3}
            >
              <Typography
                color={color('typographyColorMap2')}
                variant='body1'
              >
                Адрес
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
            >
              <Typography color={color('typographyColorMap1')}>
                {singleGeoPoint?.address}{' '}
                {singleGeoPoint?.apartmentNumber ? `кв. ${singleGeoPoint?.apartmentNumber}` : null}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
          >
            <Grid
              item
              xs={3}
            >
              <Typography
                color={color('typographyColorMap2')}
                variant='body1'
              >
                Л/с
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
            >
              <Typography color={color('typographyColorMap1')}>
                {singleGeoPoint?.accountingNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
          >
            <Grid
              item
              xs={3}
            >
              <Typography
                color={color('typographyColorMap2')}
                variant='body1'
              >
                Исполнитель
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
            >
              <Typography color={color('typographyColorMap1')}>
                {taskGroupMap.get(singleGeoPoint?.executorId || '')}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={3}
        >
          <Typography
            color={color('typographyColorMap2')}
            variant='body1'
          >
            Тип ПУ
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <Typography color={color('typographyColorMap1')}>
            {singleGeoPoint?.exMeterType}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={3}
        >
          <Typography
            color={color('typographyColorMap2')}
            variant='body1'
          >
            Номер ПУ
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <Typography color={color('typographyColorMap1')}>
            {singleGeoPoint?.exMeterNumber}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={3}
        >
          <Typography
            color={color('typographyColorMap2')}
            variant='body1'
          >
            Тариф
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <Typography color={color('typographyColorMap1')}>{singleGeoPoint?.tariff}</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={3}
        >
          <Typography
            color={color('typographyColorMap2')}
            variant='body1'
          >
            Место уст-ки
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <Typography color={color('typographyColorMap1')}>
            {singleGeoPoint?.montagePlaceType}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={3}
        >
          <Typography
            color={color('typographyColorMap2')}
            variant='body1'
          >
            Фазность
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <Typography color={color('typographyColorMap1')}>
            {getCatalogMapWithErr('phase').get(singleGeoPoint?.phase ?? '') ?? 'неизвестена'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CommonTaskInfo;
