export type CatalogResponse<CatalogNameEnumValues> = {
  title: string;
  name: CatalogNameEnumValues;
}[];

export enum ComplaintStage {
  MODERATED = 'MODERATED', //Модерация
  CALL_CENTERED = 'CALL_CENTERED', //Колл-центр
  RETURNED = 'RETURNED', //Возврат жалобы
  MODERATED_AFTER_CC = 'MODERATED_AFTER_CC', //Модерация после КЦ
  IN_PROGRESS = 'IN_PROGRESS', //В работе
  DUPLICATED = 'DUPLICATED', //Дублирована
  TRANSFERRED_TO_RESPONSIBLE = 'TRANSFERRED_TO_RESPONSIBLE', //Передано ответственному
  MODERATED_AFTER_RESPONSIBLE = 'MODERATED_AFTER_RESPONSIBLE', //Модерация после ответственного
  COMPLETED = 'COMPLETED', //Завершена
  NOT_SEC = 'NOT_SEC', //Не относиться к СЭК
}

export enum ComplaintEnergyClassification {
  PU_NOT_WORK = 'PU_NOT_WORK', //ПУ не исправен
  ISSUE_DD = 'ISSUE_DD', //Выдать ДД
  INCORRECT_BINDING = 'INCORRECT_BINDING', //Некорректная привязка
  TRANSFER_CONSUMER_INTO_ACCOUNT = 'TRANSFER_CONSUMER_INTO_ACCOUNT', //Перевести потребителя в расчет
  MKD = 'MKD', //МКД
  CORRECTION_SWITCHING_CIRCUIT = 'CORRECTION_SWITCHING_CIRCUIT', //Исправление схемы включения
  COORECTION_SMR = 'COORECTION_SMR', //Исправление СМР
}

export interface SetNotSecStateRequestDto {
  response?: string;
  workType?: string | null;
}

export interface ComplaintGetReportRequestDto {
  startDate: string;
  endDate: string;
}
