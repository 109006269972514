import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '@/hooks/useAuth';
import { YodaTokenContextType } from './types';

const RequireAuth = () => {
  const auth: YodaTokenContextType = useAuth() as YodaTokenContextType;
  const location = useLocation();

  return auth?.getYodaToken() ? (
    <Outlet />
  ) : (
    <Navigate
      to='/login'
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
