import { BaseUI } from '@/types/ui';
import { CommonModalWrapper, CommonModalCloseButton, CommonModalInner } from './CommonModal.styled';
import CloseIcon from '@mui/icons-material/Close';

export interface CommonModalProps extends BaseUI {
  open: boolean;
  onClose: () => void;
}

const CommonModal = ({ children, open, onClose, className, ...rest }: CommonModalProps) => {
  return (
    <CommonModalWrapper
      open={open}
      onClose={onClose}
      className={className}
      {...rest}
    >
      <CommonModalInner className={'CommonModalInner'}>
        {children}
        <CommonModalCloseButton onClick={onClose}>
          <CloseIcon />
        </CommonModalCloseButton>
      </CommonModalInner>
    </CommonModalWrapper>
  );
};

export default CommonModal;
