/* eslint-disable */ //TODO: fix error  'handleSave' is missing in props validation   react/prop-types
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { FixedSizeList, FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { SupplyPUItem } from '@/dto/taskmap/Dto';
import SupplyPURow from './SupplyPURow';
import {
  SupplyPUTableHeaderColumn,
  SupplyPUComponentTableHeader,
  SupplyPUComponentTableWrapper,
  SupplyPUReturnScannerWrapper,
} from './SupplyPUComponent.styled';
import {
  focusOnCell,
  osDupErrorMap,
  osNoYodaTaskErrorMap,
  PUExistErrorMap,
  useStorehouseWorkerAccess,
} from './utils';
import { SelectedPu } from './SupplyPUComponent';
import { Button, TextField } from '@mui/material';
import { SupplyReturnBillExtraColumnsProps } from '../SupplyReturnBillExtraColumns/SupplyReturnBillExtraColumns';
import useSupplyStore from '../SupplyStore';
import { SupplyBillApiCheckTaskNumberList } from '@/services/SupplyBillService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { SupplyTabType } from '../StampComponent/StampComponent';
import useSupplyActiveRow from '../SupplyActiveRow/store';
import shallow from 'zustand/shallow';

export interface SupplyPUModalProps {
  puListItems: SupplyPUItem[];
  disabledOrDraftCheck?: boolean;
  selectedPU?: SelectedPu;
  osDupFieldError: osDupErrorMap;
  osExistsFieldError: PUExistErrorMap;
  noYodaTaskFieldError: osNoYodaTaskErrorMap;
  returnBill: boolean;
  relocationGetBill: boolean;
  billWithConfirm: boolean;
  accessProject?: boolean;
}

export interface SupplyPUVirtualizedTableProps
  extends SupplyPUModalProps,
    Pick<SupplyReturnBillExtraColumnsProps, 'handleSave' | 'showMessage'>,
    SupplyTabType {
  removeScanRow: (rowId: number) => void;
  onEditStart: (record?: SupplyPUItem) => void;
  confirmScanRow: (rowId: number) => void;
  confirmScanedNumber: (scanedNumber: string) => number | null | undefined;
  viewMode?: boolean;
  setPUListItems: (value: React.SetStateAction<SupplyPUItem[]>) => void;
}

const SupplyPUVirtualizedTable = ({
  puListItems,
  removeScanRow,
  handleSave,
  onEditStart,
  disabledOrDraftCheck,
  selectedPU,
  osDupFieldError,
  noYodaTaskFieldError,
  osExistsFieldError,
  returnBill,
  showMessage,
  confirmScanRow,
  confirmScanedNumber,
  viewMode,
  billWithConfirm,
  relocationGetBill,
  tabType,
  setPUListItems,
  accessProject,
}: SupplyPUVirtualizedTableProps) => {
  const [itemSize, setItemSize] = useState(50);
  const { setActiveRowIndex } = useSupplyActiveRow(
    (state) => ({
      setActiveRowIndex: state.setActiveRowIndex,
    }),
    shallow
  );

  useEffect(() => {
    setActiveRowIndex(null);
  }, [selectedPU]);

  const workerAccess = useStorehouseWorkerAccess();
  const checkedTaskNumbers = useSupplyStore((state) => state.checkedTaskNumbers);
  const { fetchCatch } = useActionLog();

  useEffect(() => {
    if (returnBill && !viewMode) {
      const taskNumbersToCheck: string[] = [];
      puListItems.forEach((pu) => {
        if (pu.taskNumber) {
          const stringifiedTaskNumber = pu.taskNumber.toString();
          if (
            !checkedTaskNumbers.has(stringifiedTaskNumber) ||
            checkedTaskNumbers.get(stringifiedTaskNumber) === 'checking'
          )
            taskNumbersToCheck.push(stringifiedTaskNumber);
        }
      });
      if (taskNumbersToCheck.length) {
        taskNumbersToCheck.forEach((number) => {
          checkedTaskNumbers.set(number, 'checking');
        });
        SupplyBillApiCheckTaskNumberList(taskNumbersToCheck)
          ?.then(({ data }) => {
            data?.forEach((task) => {
              const stringifiedTaskNumber = task?.number?.toString() || '-1';

              if (taskNumbersToCheck.includes(stringifiedTaskNumber)) {
                checkedTaskNumbers.set(stringifiedTaskNumber, 'success');
              } else {
                checkedTaskNumbers.set(stringifiedTaskNumber, 'error');
              }
            });
          })
          .catch((err) => fetchCatch(err));
      }
    }
  }, []);

  const listRef = useRef() as MutableRefObject<FixedSizeList>;
  const [doubleNumberColumns, _setDoubleNumberColumns] = useState(() => {
    if (selectedPU?.isRim) {
      return true;
    }
    return false;
  });

  // Скролл и фокус на последней строке
  useEffect(() => {
    if (disabledOrDraftCheck) return;
    let innerTimeout: NodeJS.Timeout;
    const upperTimeout = setTimeout(() => {
      const lastEditableCellId = puListItems[puListItems?.length - 1]?.tmpId;
      listRef?.current?.scrollToItem(lastEditableCellId, 'center');
      innerTimeout = setTimeout(() => {
        focusOnCell({ cellRowId: lastEditableCellId });
      }, 10);
    }, 10);
    return () => {
      upperTimeout && clearTimeout(upperTimeout);
      innerTimeout && clearTimeout(innerTimeout);
    };
  }, [itemSize]);

  // Автоподстройка высоты строки
  useEffect(() => {
    if (selectedPU) {
      const height = (document.querySelector('.SupplyVirtualizedTable_Row') as HTMLElement)
        ?.offsetHeight;

      if (height) {
        setItemSize(height);
      }
    }
  }, [listRef?.current]);

  const handleScan = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && (e.target as HTMLInputElement)?.value?.length) {
      const scrollToItem = confirmScanedNumber((e.target as HTMLInputElement).value);
      setActiveRowIndex(scrollToItem ?? null);
      setTimeout(() => {
        scrollToItem && listRef?.current?.scrollToItem(scrollToItem, 'center');
      }, 50);
      (e.target as HTMLInputElement).value = '';
    }
  };

  const addEmptyPU = () => {
    const lastEditableCellId = puListItems[puListItems?.length - 1]?.tmpId;
    listRef?.current?.scrollToItem(lastEditableCellId, 'center');
    onEditStart();
  };

  const returnNewItemsMode = useSupplyStore((state) => state.returnNewItemsMode);
  const firstRow = puListItems[0];

  useEffect(() => {
    if (!returnNewItemsMode) return;

    const returnNewItemsPus = puListItems.map((pu) => ({
      ...pu,
      completeness: firstRow?.completeness,
      state: firstRow?.state,
      defectCause: firstRow.defectCause,
      taskNumber: null,
      completenessComment: firstRow?.completenessComment,
      address: firstRow?.address,
      generalComment: firstRow?.generalComment,
      supplyComment: '-',
      manufactureYear: firstRow?.manufactureYear,
      ownerId: firstRow?.ownerId,
    }));

    setPUListItems(returnNewItemsPus);
  }, [
    returnNewItemsMode,
    firstRow?.manufactureYear,
    firstRow?.ownerId,
    firstRow?.address,
    firstRow?.generalComment,
    firstRow?.taskNumber,
    firstRow?.completenessComment,
    firstRow?.defectCause,
  ]);

  return (
    <>
      {billWithConfirm && workerAccess && !viewMode && (
        <SupplyPUReturnScannerWrapper>
          <TextField
            name='mac/number'
            onKeyDown={handleScan}
            autoComplete='off'
            label='Введите номер'
            InputLabelProps={{ shrink: true }}
            autoFocus
          />

          {returnBill && (
            <Button
              variant='contained'
              onClick={addEmptyPU}
            >
              Добавить ПУ без номера
            </Button>
          )}
        </SupplyPUReturnScannerWrapper>
      )}
      <SupplyPUComponentTableWrapper>
        <SupplyPUComponentTableHeader>
          <SupplyPUTableHeaderColumn
            withBorder
            narrow
          >
            № п/п
          </SupplyPUTableHeaderColumn>
          <SupplyPUTableHeaderColumn withBorder>Тип прибора</SupplyPUTableHeaderColumn>
          <SupplyPUTableHeaderColumn withBorder>
            {doubleNumberColumns ? 'Номер 1' : 'Номер/MAC'}
          </SupplyPUTableHeaderColumn>
          {doubleNumberColumns && (
            <SupplyPUTableHeaderColumn withBorder>Номер 2</SupplyPUTableHeaderColumn>
          )}
          {returnBill && (
            <>
              <SupplyPUTableHeaderColumn withBorder>Год выпуска</SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>Комплектность</SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                Комментарий при неполной комплектности
              </SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>Принадлежность</SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>Состояние</SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>Вид брака</SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>№ Заявки</SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                Адрес демонтированного ПУ
              </SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>Общий комментарий</SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                Складской комментарий
              </SupplyPUTableHeaderColumn>
            </>
          )}
          {!viewMode && (
            <>
              {tabType !== 'relocation_get' && (
                <SupplyPUTableHeaderColumn withBorder>Удалить</SupplyPUTableHeaderColumn>
              )}

              {billWithConfirm && workerAccess && (
                <SupplyPUTableHeaderColumn>Подтвердить</SupplyPUTableHeaderColumn>
              )}
            </>
          )}
        </SupplyPUComponentTableHeader>

        <div
          style={{ height: '90%' }}
          key={`${returnNewItemsMode}`}
        >
          <AutoSizer>
            {({ height, width }: { width: number; height: number }) => (
              <List
                ref={listRef}
                height={height}
                itemCount={puListItems.length}
                itemSize={itemSize}
                width={width}
                itemData={{
                  puListItems,
                  removeScanRow,
                  handleSave,
                  onEditStart,
                  disabledOrDraftCheck,
                  selectedPU,
                  osDupFieldError,
                  noYodaTaskFieldError,
                  osExistsFieldError,
                  returnBill,
                  showMessage,
                  confirmScanRow,
                  viewMode,
                  billWithConfirm,
                  relocationGetBill,
                  tabType,
                  accessProject,
                }}
                itemKey={(index) => puListItems[index].tmpId}
                className='SupplyVirtualizedTable_List'
              >
                {SupplyPURow}
              </List>
            )}
          </AutoSizer>
        </div>
      </SupplyPUComponentTableWrapper>
    </>
  );
};

export default SupplyPUVirtualizedTable;
