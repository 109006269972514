import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import DashBoard from '@/components/statistics/DashBoard';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const DashBoardPage = () => {
  useAccess({
    access: [AccessEnum.Statistic, AccessEnum.TeamManagerVoormanAndExecutor],
  });
  useMetaTitle(`Статистика`);

  return <DashBoard />;
};

export default DashBoardPage;
