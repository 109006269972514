import React, { FC } from 'react';
import { color } from '@/styles/mixins';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { useDeliveryPoints } from '@/components/maps/ppoMap/placemark/delivery-point/store';
import { FormProvider, useForm } from 'react-hook-form';
import { DeliveryPointItem } from '@/components/features/taskEditor/deliveryPoint/DeliveryPointItem';

export const DeliveryPointCard: FC = () => {
  const methods = useForm();
  const { tmpDeliveryPoint } = useDeliveryPoints();

  return tmpDeliveryPoint ? (
    <BoxShadow
      background={color('lightWhite')}
      sx={{ mb: 2 }}
    >
      <FormProvider {...methods}>
        <DeliveryPointItem
          id={tmpDeliveryPoint.id}
          data={tmpDeliveryPoint}
        />
      </FormProvider>
    </BoxShadow>
  ) : (
    <></>
  );
};
