export const TASK_STATUS = {
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
  DISPUT: 'DISPUT',
  RETURNED: 'RETURNED',
  ASSIGNED: 'ASSIGNED',
  CHECK: 'CHECK',
  NOT_RELEVANT: 'NOT_RELEVANT',
  IN_WORK: 'IN_WORK',
};
