import { RouterType } from '@/components/maps/ppoMap/dto';

export const RouterPointBallon = (geoObject: RouterType) => {
  return `
    <div class="map__router-point-balloon">
        <a href="#" class="router-point-balloon-link"
                id="router-point-balloon_id_${geoObject.taskId}">
          редактировать сетевое оборудование ${geoObject.taskNumber}
        </a>
    </div>
  `;
};
