import { Autocomplete, TextField, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SxProps } from '@mui/system';

interface AutocompleteValueProps {
  defaultValue?: string[];
  title?: string;
  values: Map<string, string> | Array<string>;
  fieldName: string;
  disabled?: boolean;
  disableClearable?: boolean;
  optionOnChangeValue?: (list: string[]) => void;
  sx?: SxProps<Theme>;
  clearFlag?: boolean;
  required?: boolean;
}

export const AutocompleteValueMulti = (props: AutocompleteValueProps) => {
  const { disabled, disableClearable, sx, required } = props;
  const [stateValue, setStateValue] = useState<string[]>([]);
  const { setValue } = useFormContext();

  const defaultProps = {
    options: Array.from(props.values instanceof Map ? props?.values?.keys() : props.values).sort(),
    getOptionLabel: (option: any) =>
      props.values instanceof Map ? props?.values.get(option) || option : option,
  };

  useEffect(() => {
    setStateValue(props?.defaultValue || []);
    if (props.optionOnChangeValue) {
      props.optionOnChangeValue(props?.defaultValue || []);
    }
    setValue(props.fieldName, props?.defaultValue);
  }, [props.defaultValue, props.clearFlag]);

  return (
    <Autocomplete
      multiple
      sx={sx}
      disableClearable={disableClearable ? disableClearable : false}
      {...defaultProps}
      id={`${props.fieldName}-autocomplete-value`}
      value={stateValue}
      onChange={(event: any, newValue: any) => {
        setStateValue(newValue);
        setValue(props.fieldName, newValue);
        if (props.optionOnChangeValue) {
          props.optionOnChangeValue(newValue);
        }
      }}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props?.title}
          variant='standard'
          required={required}
        />
      )}
    />
  );
};
