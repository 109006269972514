import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useComplaintStore } from '../store';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import {
  compliteComplaintOdpu,
  duplicatedComplaintOdpu,
  inProgressComplaintOdpu,
  returnComplaintOdpu,
  returnComplaintOdpuBackToModerator,
} from '@/services/ComplaintService/ComplaintService';
import shallow from 'zustand/shallow';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { ComplaintStage } from '@/services/ComplaintService/ComplaintServiceDto';
import { useState } from 'react';
import { Popup } from '@/components/popups';
import { useComplaintsEditAccess } from '../roles';
import { RolesEnum } from '@/app/auth/roles';
import { useAllowedAccessByRole } from '@/hooks/useAccess';

const ButtonsOdpu = ({
  responsibleId,
  executor,
  setError,
}: {
  responsibleId: string | null;
  executor: string | null;
  setError: (flag: boolean) => void;
}) => {
  const { selectedCompliant, fetchSingleComplaint, setSelectedCompliant } = useComplaintStore(
    (state) => ({
      selectedCompliant: state.selectedCompliant,
      fetchSingleComplaint: state.fetchSingleComplaint,
      setSelectedCompliant: state.setSelectedCompliant,
    }),
    shallow
  );
  const selectedCompliantId = selectedCompliant?.complaint?.base?.id;

  const { setIsInProgress } = useScreenHoldHook();
  const { addActionLog, fetchWithUserMessageFlagCatch, catchError } = useActionLog();

  const [isRedyToworkPopup, setIsRedyToworkPopup] = useState(false);

  const handleReturnComplaint = () => {
    if (selectedCompliant?.complaint.stage.name === ComplaintStage.RETURNED) {
      setIsInProgress(true);
      returnComplaintOdpuBackToModerator(selectedCompliantId, {})
        .then((data) => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
        })
        .catch((error) => fetchWithUserMessageFlagCatch(error))
        .finally(() => setIsInProgress(false));
    } else {
      setIsInProgress(true);
      returnComplaintOdpu(selectedCompliantId, {})
        .then((data) => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
        })
        .catch((error) => fetchWithUserMessageFlagCatch(error))
        .finally(() => setIsInProgress(false));
    }
  };

  const handleInProgressComplaint = () => {
    setIsInProgress(true);
    const executorId = executor ? executor : undefined;
    inProgressComplaintOdpu(selectedCompliantId, { responsibleId, executorId })
      .then((data) => {
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
        setIsRedyToworkPopup(false);
        setError(false);
        // setSelectedCompliant(null);
      })
      .catch((error) => {
        fetchWithUserMessageFlagCatch(error);
        setError(true);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  const handleDuplicatedComplaint = () => {
    setIsInProgress(true);
    duplicatedComplaintOdpu(selectedCompliantId, {})
      .then((data) => {
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
      })
      .catch((error) => fetchWithUserMessageFlagCatch(error))
      .finally(() => setIsInProgress(false));
  };

  const handleCompliteComplaint = () => {
    setIsInProgress(true);
    compliteComplaintOdpu(selectedCompliantId, {})
      .then((data) => {
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
      })
      .catch((error) => fetchWithUserMessageFlagCatch(error))

      .finally(() => {
        setIsInProgress(false);
      });
  };

  const buttonText =
    selectedCompliant?.complaint.stage.name === ComplaintStage.RETURNED
      ? 'вернуть модератору'
      : 'вернуть жалобу';

  const hasAccessToEdit = useComplaintsEditAccess();
  const allowAccess = useAllowedAccessByRole({
    allowRoles: [RolesEnum.ComplaintManager],
  });

  return (
    <Stack>
      <Popup
        customStyle={{ minWidth: 'auto' }}
        isOpen={isRedyToworkPopup}
        onClose={() => setIsRedyToworkPopup(false)}
      >
        <>
          <Typography variant='h6'>Вы уверены что хотите передать жалобу в работу ?</Typography>
          <Stack
            flexDirection={'row'}
            justifyContent={'center'}
            mt={3}
          >
            <Button onClick={handleInProgressComplaint}>да</Button>
            <Button onClick={() => setIsRedyToworkPopup(false)}>нет</Button>
          </Stack>
        </>
      </Popup>
      <Grid
        container
        xs={12}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          justifyContent={'center'}
        >
          <Button
            disabled={
              !hasAccessToEdit ||
              (selectedCompliant?.complaint.stage.name === ComplaintStage.MODERATED && !allowAccess)
            }
            onClick={handleReturnComplaint}
          >
            {buttonText}
          </Button>
        </Grid>
        <Grid
          item
          // xs={6}
          xs={12}
          md={6}
          display={'flex'}
          justifyContent={'center'}
        >
          <Button
            onClick={() => setIsRedyToworkPopup(true)}
            disabled={
              selectedCompliant?.complaint.stage.name === ComplaintStage.RETURNED ||
              selectedCompliant?.complaint.stage.name === ComplaintStage.IN_PROGRESS ||
              !allowAccess
            }
          >
            передать в работу
          </Button>
        </Grid>
        <Grid
          item
          // xs={6}
          xs={12}
          md={6}
          display={'flex'}
          justifyContent={'center'}
        >
          <Button
            disabled={!hasAccessToEdit || !allowAccess}
            onClick={handleDuplicatedComplaint}
          >
            дубль
          </Button>
        </Grid>

        <Grid
          item
          // xs={6}
          xs={12}
          md={6}
          display={'flex'}
          justifyContent={'center'}
        >
          <Button
            disabled={!hasAccessToEdit || !allowAccess}
            onClick={handleCompliteComplaint}
          >
            Жалоба отработана
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ButtonsOdpu;
