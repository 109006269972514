import { color } from '@/styles/mixins';
import { Grid, Typography } from '@mui/material';
interface PpoHouseText {
  values: Record<string, string | null>;
}

const PpoHouseText = ({ values }: PpoHouseText) => {
  if (Object.keys(values)?.length) {
    const { cipher, flatCount, floorCount, entranceCount, elevatorCount, hasElectricStove, note } =
      values || {};
    const hasElectricStoveValue = () => {
      if (hasElectricStove) return 'да';
      if (typeof hasElectricStove === 'boolean' && !hasElectricStove) return 'нет';
      return 'пусто';
    };

    return (
      <>
        <Grid
          item
          display={'flex'}
          alignSelf={'flex-end'}
          gap={1}
        >
          <Typography variant='subtitle2'>{'Шифр:'} </Typography>
          <Typography
            color={color('gray')}
            variant='subtitle2'
          >
            {cipher || 'пусто'}
          </Typography>
        </Grid>
        <Grid
          item
          display={'flex'}
          alignSelf={'flex-end'}
          gap={1}
        >
          <Typography variant='subtitle2'>{'Кол-во квартир:'} </Typography>
          <Typography
            color={color('gray')}
            variant='subtitle2'
          >
            {flatCount || 'пусто'}
          </Typography>
        </Grid>
        <Grid
          item
          display={'flex'}
          alignSelf={'flex-end'}
          gap={1}
        >
          <Typography variant='subtitle2'>{'Кол-во этажей:'} </Typography>
          <Typography
            color={color('gray')}
            variant='subtitle2'
          >
            {floorCount || 'пусто'}
          </Typography>
        </Grid>
        <Grid
          item
          display={'flex'}
          alignSelf={'flex-end'}
          gap={1}
        >
          <Typography variant='subtitle2'>{'Кол-во подьездов:'} </Typography>
          <Typography
            color={color('gray')}
            variant='subtitle2'
          >
            {entranceCount || 'пусто'}
          </Typography>
        </Grid>
        <Grid
          item
          display={'flex'}
          alignSelf={'flex-end'}
          gap={1}
        >
          <Typography variant='subtitle2'>{'Кол-во лифтов:'} </Typography>
          <Typography
            color={color('gray')}
            variant='subtitle2'
          >
            {elevatorCount || 'пусто'}
          </Typography>
        </Grid>
        <Grid
          item
          display={'flex'}
          alignSelf={'flex-end'}
          gap={1}
        >
          <Typography variant='subtitle2'>{'Электрические плиты:'} </Typography>
          <Typography
            color={color('gray')}
            variant='subtitle2'
          >
            {hasElectricStoveValue()}
          </Typography>
        </Grid>
        <Grid
          item
          display={'flex'}
          alignSelf={'flex-end'}
          gap={1}
        >
          <Typography variant='subtitle2'>{'Комментарий:'} </Typography>
          <Typography
            color={color('gray')}
            variant='subtitle2'
          >
            {note || 'пусто'}
          </Typography>
        </Grid>
      </>
    );
  }
  return <></>;
};

export default PpoHouseText;
