import axios, { AxiosResponse } from 'axios';
import {
  ApiFindUnAttachmentByFilter,
  GetFileUrlByFileId,
  HOST,
  yodaGetAccessToken,
} from './YodaRestService';
import { UnAttachmentDto } from '@/dto/YodaRestDto';
import { axiosDelete, axiosPost } from './helpers';
import { BindResult } from '@/dto/taskmap/Dto';
import qs from 'qs';

const TAG = 'FileService';

export function GetUnAttachmentByFilter(
  filter: string
): Promise<AxiosResponse<UnAttachmentDto[], any>> {
  return ApiFindUnAttachmentByFilter(filter);
}

export function GetBase64File(id: string): Promise<string> {
  return GetFileUrlByFileId(id);
}

export async function uploadFile(
  objId: string,
  typeIdList: string[],
  stageId: string,
  fileList: FileList
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/file/entity/${objId}`;
  const token = await yodaGetAccessToken();

  const formData = new FormData();
  Array.from(fileList).forEach((t: any) => formData.append('fileList', t));

  return axios.post(url, formData, {
    params: {
      typeIdList: typeIdList,
      stageId,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    headers: {
      Authorization: `Bearer  ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function bindVectorDiagram(
  contractId: string,
  fileList: FileList
): Promise<AxiosResponse<BindResult[], any>> {
  const url = HOST + `/rest/file/diagram/bind?contractId=${contractId}`;
  const formData = new FormData();
  Array.from(fileList).forEach((t: any) => formData.append('fileList', t));
  return axiosPost(url, formData, false, true);
}

export async function deleteFile(fileId: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/file/${fileId}`;
  return axiosDelete(url);
}

export async function deleteFileEntity(entityId: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/file/entity/${entityId}`;
  return axiosDelete(url);
}
