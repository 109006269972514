import { HeadCell } from './TableCells';

export const statusArray: string[] = ['прогружен', 'не прогружен'];

export const headCells: HeadCell[] = [
  {
    id: 'processingAt',
    label: 'Дата сбора',
  },
  {
    id: 'taskCount',
    label: 'Количество заявок с адресом энфорс',
  },
  {
    id: 'taskWithoutAddressCount',
    label: 'Количество заявок без адреса энфорс',
  },
  {
    id: 'loadAt',
    label: 'Статус',
  },
  {
    id: 'contracts',
    label: 'Контракты',
  },
  {
    id: 'manual',
    label: 'Сформирован',
  },
];
