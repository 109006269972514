import { color } from '@/styles/mixins';
import { IconButton, Stack, styled } from '@mui/material';

export const CablePopupTitle = styled('h2')({
  textAlign: 'center',
  fontSize: '48px',
  margin: '0 auto',
});

export const CablePopupIconButton = styled(IconButton)({
  padding: '4px',
  marginTop: 'auto',
});

interface CablePopupFormWrapperProps {
  disabled?: boolean;
}
export const CablePopupFormWrapper = styled('div')<CablePopupFormWrapperProps>(({ disabled }) => ({
  background: disabled ? color('gray', 0.2) : '',
  pointerEvents: disabled ? 'none' : 'auto',
  opacity: disabled ? 0.6 : 1,
  borderRadius: '10px',
  padding: '5px',
  transition: '0.4s',
}));

export const CablePopupFormInputWrapper = styled('div')({
  width: '100%',
});

export const CablePopupColumn = styled(Stack)({
  gap: '16px',
});

export const CablePopupFormRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  padding: '10px 0',
});

export const CablePopupFormButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '30px',
  gap: '20px',
});
