export const BREAKPOINTS = {
  xs: 375,
  sm: 600,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1440,
  fhd: 1920,
  qhd: 2560,
  uhd: 3840,
};

export type Breakpoints = keyof typeof BREAKPOINTS;

export default BREAKPOINTS;
