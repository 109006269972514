import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './ErrorFallback';

interface LowLevelErrorBoundaryProps {
  children: React.ReactNode;
  Fallback?: React.ComponentType;
}

const LowLevelErrorBoundary = ({
  children,
  Fallback = ErrorFallback,
}: LowLevelErrorBoundaryProps) => {
  return (
    <ErrorBoundary
      FallbackComponent={() => <Fallback />}
      onError={(error, errorInfo) => {
        //TODO: Логирование ошибки или отправка данных на сервер, подключать к Sentry
        console.error('Error caught by LowLevelErrorBoundary:', error, errorInfo);
      }}
    >
      <>{children}</>
    </ErrorBoundary>
  );
};

export default LowLevelErrorBoundary;
