import { color } from '@/styles/mixins';
import { styled } from '@mui/material';

export const CableDescentBox = styled('div')({
  marginTop: 40,
  marginBottom: 40,
  border: `1px solid ${color('gray2')}`,
  padding: '16px 35px 35px 35px',
  borderRadius: 10,
  position: 'relative',
});
export const SaveIconWrapper = styled('div')({
  position: 'absolute',
  right: 11,
  bottom: 11,
});
