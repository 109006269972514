import { Button, IconButton, styled, TextField } from '@mui/material';
import { color } from '@/styles/mixins';

export const IconButtonStyled = styled(IconButton)({
  width: '60px',
  height: '30px',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#1975d2',
  color: '#ffffff',
  borderRadius: '4px',
  transition: '0.3s',
  boxShadow:
    '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',

  '&:hover': {
    color: '#1975d2',
  },
});

export const ButtonStyled = styled(Button)({
  marginLeft: '30px',
  fontSize: '10px',
  paddingLeft: '5px',
  paddingRight: '5px',
});

interface WrapperProps {
  unSavedItem?: boolean;
  errorPus?: boolean;
  allConfirmed?: boolean;
}
export const Wrapper = styled('div')<WrapperProps>(({ unSavedItem, errorPus, allConfirmed }) => ({
  border: '1px solid #ccc',
  padding: '10px',
  borderRadius: '5px',
  display: 'inline-block',
  marginBottom: '10px',
  marginTop: '10px',
  marginLeft: '0px',
  marginRight: '10px',
  boxShadow: '2px 2px 10px 2px #ccc',
  backgroundColor: unSavedItem ? color('gray', 0.05) : '',

  ...(allConfirmed && {
    backgroundColor: color('successGreen', 0.2),
  }),

  ...(errorPus && {
    backgroundColor: color('trueRed', 0.1),
  }),
}));

export const SupplyButtonsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 16,
  paddingTop: 16,
});

export const SupplyPUComponentTableWrapper = styled('div')({
  height: '70%',
  border: `1px solid ${color('gray', 0.1)}`,
  borderBottom: 'none',
  borderRadius: 4,
  color: color('trueBlack', 0.87),
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
  fontSize: 14,

  '.SupplyVirtualizedTable_List': {
    scrollbarWidth: 'auto',
  },
});

interface SupplyPUComponentTableRowWrapperProps extends WrapperProps {
  error?: boolean;
  active?: boolean;
}
export const SupplyPUComponentTableRowWrapper = styled(
  'div'
)<SupplyPUComponentTableRowWrapperProps>(({ error, unSavedItem, active }) => ({
  display: 'flex',
  padding: '0 16px',
  paddingLeft: 0,
  alignItems: 'center',
  border: '2px solid transparent',
  borderBottom: `2px solid ${color('gray', 0.1)}`,
  backgroundColor: unSavedItem ? color('gray', 0.05) : '',
  transition: 'background-color 0.4s',

  '&:hover': {
    backgroundColor: error ? color('trueRed', 0.3) : color('gray', 0.1),
  },

  ...(error && {
    backgroundColor: color('trueRed', 0.1),
    input: { color: color('trueRed') },
  }),

  ...(active && {
    border: `2px solid ${color('green')}`,
    borderBottom: `2px solid ${color('green')}`,
  }),
}));

export const SupplyPUComponentTableHeader = styled('div')({
  display: 'flex',
  fontWeight: 500,
  padding: 16,
  paddingLeft: 0,
  borderBottom: `1px solid ${color('gray', 0.1)}`,
});

export const SupplyPUTableHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  gap: 20,
  flexWrap: 'wrap',
});

export const SupplyPUTableHeaderItem = styled('div')({
  display: 'flex',
  gap: 24,
});

export const SupplyPUConfirmationDelete = styled('span')({
  color: color('trueRed'),
  fontWeight: 500,
});

interface SupplyPUComponentTableColumnProps {
  withBorder?: boolean;
  withoutPadding?: boolean;
  narrow?: boolean;
  disabled?: boolean;
  confirmed?: boolean;
}
export const SupplyPUTableHeaderColumn = styled('div')<SupplyPUComponentTableColumnProps>(
  ({ withBorder, narrow }) => ({
    width: narrow ? 70 : 140,
    borderRight: withBorder ? `2px solid ${color('gray', 0.2)}` : '',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 4,
  })
);

export const SupplyPUTableCell = styled('div')<SupplyPUComponentTableColumnProps>(
  ({ withoutPadding, narrow }) => ({
    display: 'flex',
    width: narrow ? 70 : 140,
    padding: '8px 4px',
    paddingLeft: withoutPadding ? 6 : 16,
    gap: 5,
    alignItems: 'center',
  })
);

export const SupplyPUTableCommentCell = styled(SupplyPUTableCell)(({ disabled }) => ({
  cursor: 'pointer',
  pointerEvents: disabled ? 'none' : 'auto',
}));

export const SupplyPUTableCellLoading = styled('div')({
  width: 15,
  marginRight: 15,
});

export const SupplyPUTableInputCell = styled(SupplyPUTableCell)(({ confirmed }) => ({
  paddingLeft: 0,
  padding: 1,
  alignSelf: 'stretch',
  transition: '0.4s',

  '&:focus-within': {
    border: `1px solid ${color('muiBlue')}`,
  },

  ...(confirmed && {
    backgroundColor: color('successGreen', 0.3),

    '&:hover': {
      backgroundColor: color('successGreen', 0.2),
    },
  }),
}));

export const SupplyPUTableInput = styled('input')({
  height: '100%',
  width: '100%',
  border: 'none',
  outline: 'none',
  cursor: 'default',
  backgroundColor: 'inherit',

  '&:focus': {
    cursor: 'auto',
    backgroundColor: color('trueWhite'),
  },
});

export const SupplyPUCommentModalButtonsWrapper = styled('div')({
  display: 'flex',
  gap: 16,
});

export const SupplyPUCommentModalWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 40,
});

export const SupplyPUCommentTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pointerEvents: 'none',
});

interface SupplyPURowButtonProps {
  errorBorder?: boolean;
}

export const SupplyPURowButton = styled(Button)<SupplyPURowButtonProps>(({ errorBorder }) => ({
  fontSize: 10,
  margin: 'auto',
  transition: '0.4s',
  border: `1px solid ${errorBorder ? color('muiError') : 'transparent'}`,
}));

export const SupplyPUReturnScannerWrapper = styled('div')({
  padding: '6px 0',
  display: 'inline-flex',
  alignItems: 'center',
  gap: 12,
});
