import { ImageList, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { ImageViewItem } from '../../item/ImageItem/ImageItem';

import { IImageGroup } from '@/components/Gallery/store';
import ImageGroupViewControls from './ImageGroupViewControls';

export interface ImageGroupViewProps {
  imageBox: IImageGroup;
}

export const ImageGroupView = (props: ImageGroupViewProps) => {
  const [imageBox, setImageBox] = useState<IImageGroup>();

  useEffect(() => {
    setImageBox(props.imageBox);
  }, [props.imageBox]);

  return (
    <>
      {imageBox && (
        <Stack spacing={1}>
          <ImageGroupViewControls imageBox={imageBox} />
          <ImageList>
            {imageBox?.imageList?.map((imageItem, i) => {
              return (
                <ImageViewItem
                  obj={imageBox?.obj ? imageBox?.obj : ''}
                  key={`result-view-item-${imageItem.id}`}
                  imageId={imageItem.id}
                  index={i}
                  titles={imageItem?.typeList || []}
                  createdOn={imageItem.createdOn}
                  withDelete
                  ids={imageBox?.imageList}
                  url={imageItem.url}
                />
              );
            })}
          </ImageList>
        </Stack>
      )}
    </>
  );
};
