import { styled } from '@mui/material';

export const HouseOdpuReplacementWrapper = styled('div')({
  padding: 16,
  width: '100%',
  height: '100%',

  '.MuiTablePagination-root': {
    p: { margin: 0 },
  },
});
