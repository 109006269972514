import { useQueryClient } from 'react-query';
import { KEYS } from '@/hooks/useQuery/Keys';

export const useInvalidateStorehouseTypes = () => {
  const queryClient = useQueryClient();

  const invalidatePuTypes = () => {
    queryClient.invalidateQueries({ queryKey: KEYS.allPuTypes });
    queryClient.invalidateQueries({ queryKey: [...KEYS.allPuTypes, 'parents'] });
  };

  const invalidateOsTypes = () => {
    queryClient.invalidateQueries({ queryKey: KEYS.allNetworkElementTypes });
  };

  return { invalidatePuTypes, invalidateOsTypes };
};
