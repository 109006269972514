import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { FC } from 'react';
import { ImageZoom } from './ImageZoom';
import { useImageDrawerStore } from './store';

export const PersistentDrawerRight: FC = () => {
  const isOpen = useImageDrawerStore((state) => state.isOpen);

  return (
    <Drawer
      sx={{
        width: '26%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '26%',
        },
      }}
      variant='persistent'
      anchor='right'
      open={isOpen}
    >
      <div
        onMouseOut={() => {
          document.body.style.overflow = 'unset';
        }}
        onMouseOver={() => {
          document.body.style.overflow = 'hidden';
        }}
        style={{ flex: '1 1 auto', overflow: 'hidden', display: 'flex' }}
      >
        <ImageZoom />
      </div>
    </Drawer>
  );
};
