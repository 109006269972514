export const isChangeInput = new Map<string, string>([
  ['true', 'да'],
  ['false', 'нет'],
]);

// 1- есть smrTaskId нет meterChangeIdList и нет tkoIdList - не проведено
// 2- нет smrTaskId - отсутствует
// 3- smrTaskId и есть tkoIdList - ТКО
// 4- smrTaskId и есть meterChangeIdList - проведено

export const getSMRText = (
  smrTaskId: string | undefined | null,
  meterChangeIdList: string[] | undefined | null,
  tkoIdList: string[] | undefined | null,
  isChangeNeeded: boolean | null
): string => {
  if (!isChangeNeeded) {
    return 'монтаж не планируется';
  }
  if (smrTaskId == null) {
    return 'отсутствует';
  }
  if (tkoIdList && tkoIdList.length > 0) {
    return 'ТКО';
  }
  if (meterChangeIdList && meterChangeIdList.length > 0) {
    return 'проведено';
  }
  return 'не проведено';
};

export const converStringBoolenToBoolen = (str: string | null) => {
  if (!str || str === 'false') return false;
  return true;
};
