import { BaseUI } from '@/types/ui';
import React, { useMemo } from 'react';
import { ComplaintItemWrapper } from './ComplaintItem.styled';
import { getBorderColor } from './utils';
import { useComplaintStore } from '../store';
import shallow from 'zustand/shallow';
import { IComplaintItem } from '../types';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

export interface ComplaintItemProps extends BaseUI {
  complaintDTO?: IComplaintItem;
}

const ComplaintItem = ({ children, className, complaintDTO }: ComplaintItemProps) => {
  const { setSelectedCompliant, selectedCompliant } = useComplaintStore(
    (state) => ({
      setSelectedCompliant: state.setSelectedCompliant,
      selectedCompliant: state.selectedCompliant,
    }),
    shallow
  );

  const selectedCompliantId = selectedCompliant?.complaint?.base?.id;
  const complaintId = complaintDTO?.complaint?.base?.id;
  const complaintStage = complaintDTO?.complaint?.stage?.name;
  const stage = complaintDTO?.complaint?.stage?.title;
  const borderColor = useMemo(() => getBorderColor(complaintStage), [complaintStage]);
  const handleClick = () => setSelectedCompliant(complaintDTO ?? null);
  const isMobile = useMobileSize();

  return (
    <ComplaintItemWrapper
      className={className}
      stageColor={borderColor}
      stage={stage}
      isMobile={isMobile}
      selected={selectedCompliantId === complaintId}
      onClick={handleClick}
    >
      {children}
    </ComplaintItemWrapper>
  );
};

export default ComplaintItem;
