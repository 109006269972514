import { AxiosResponse } from 'axios';
import { HOST } from '@/services/YodaRestService';
import { axiosPost } from '@/services/helpers';

export async function CreatePu(data: any): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/meter/`;
  return axiosPost(url, data);
}
export async function DuplicatePu(mcId: string, data: any): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${mcId}/create-duplicate-meter`;
  return axiosPost(url, data);
}
