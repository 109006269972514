import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';
import { StatisticsResponsebleDto } from './types';

const COLUMNS_STANDART_OPTIONS: Pick<
  GridColDef,
  'disableColumnMenu' | 'sortable' | 'headerAlign' | 'align'
> = {
  disableColumnMenu: true,
  sortable: false,
  headerAlign: 'center',
  align: 'right',
};

export const STATISTICS_RESPONSEBLE_COLUMNS: GridColDef[] = [
  {
    field: 'contractorName',
    flex: 3,
    headerName: 'Ответственны',
    disableColumnMenu: true,
    sortable: false,
    headerAlign: 'center',
  },
  {
    field: 'incomingCallsCount',
    headerName: 'вход',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'fromSiteCount',
    headerName: 'сайт',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'outgoingCallsCount',
    flex: 1,
    headerName: 'исх готовы',
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'callsTotalCount',
    flex: 1,
    headerName: 'итого',
    ...COLUMNS_STANDART_OPTIONS,
    cellClassName: () => 'super--cell',
  },
  {
    field: 'installedFromIncoming',
    flex: 1,
    headerName: 'вход',
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'installedFromSite',
    headerName: 'сайт',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
  },

  {
    field: 'installedFromOutgoing',
    headerName: 'исх готовы',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'installedTotalCount',
    headerName: 'итого',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
    cellClassName: () => 'super--cell',
  },
  {
    field: 'tasksWithTkoAfterInCallCount',
    flex: 1,
    headerName: 'вход',
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'tasksWithTkoAfterSiteCallCount',
    headerName: 'сайт',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
  },

  {
    field: 'tasksWithTkoAfterOutCallCount',
    headerName: 'исх готовы',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'tasksWithTkoAfterCallsTotalCount',
    headerName: 'итого',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
    cellClassName: () => 'super--cell',
  },

  {
    field: 'incomingBalanceCount',
    flex: 1,
    headerName: 'вход',
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'siteBalanceCount',
    headerName: 'сайт',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'outgoingBalanceCount',
    headerName: 'исх готовы',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'totalBalanceCount',
    headerName: 'итого',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
    cellClassName: () => 'super--cell',
  },
];

export const STATISTICS_RESPONSEBLE_EXTRA_COLUMNS: GridColDef[] = [
  {
    field: 'contractorName',
    flex: 2,
    headerName: 'имя',
    disableColumnMenu: true,
    sortable: false,
    headerAlign: 'center',
  },
  {
    field: 'stairs_1f',
    headerName: 'Лестинца 1ф',
    width: 150,
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'hall_1f',
    headerName: 'Хол 1ф',
    width: 150,
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'apartment_1f',
    width: 150,
    headerName: 'Квартира 1ф',
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'stairs_3f',
    flex: 1,
    headerName: 'Лестница 3ф',
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'hall_3f',
    flex: 1,
    headerName: 'Хол 3ф',
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: 'apartment_3f',
    headerName: 'Квартира 3ф',
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
  },
];

export const STATISTICS_RESPONSEBLE_COLUMN_GROUPING_MODEL: GridColumnGroupingModel = [
  {
    groupId: ' ',
    headerAlign: 'center',
    children: [{ field: 'contractorName' }],
  },
  {
    groupId: 'Всего получено заявок',
    headerAlign: 'center',
    children: [
      { field: 'incomingCallsCount', headerName: 'вход' },
      { field: 'fromSiteCount', headerName: 'сайт' },
      { field: 'outgoingCallsCount', headerName: 'исх готовы' },
      { field: 'callsTotalCount', headerName: 'итого' },
    ],
  },
  {
    groupId: 'Установлено',
    headerAlign: 'center',
    children: [
      { field: 'installedFromIncoming', headerName: 'вход' },
      { field: 'installedFromSite', headerName: 'сайт' },
      { field: 'installedFromOutgoing', headerName: 'исх готовы' },
      { field: 'installedTotalCount', headerName: 'итого' },
    ],
  },
  {
    groupId: 'ТКО после обращения',
    headerAlign: 'center',
    children: [
      { field: 'tasksWithTkoAfterInCallCount', headerName: 'вход' },
      {
        field: 'tasksWithTkoAfterSiteCallCount',
        headerName: 'сайт',
      },
      { field: 'tasksWithTkoAfterOutCallCount', headerName: 'исх готовы' },
      { field: 'tasksWithTkoAfterCallsTotalCount', headerName: 'итого' },
    ],
  },
  {
    groupId: 'Остаток',
    headerAlign: 'center',
    children: [
      { field: 'incomingBalanceCount', headerName: 'вход' },
      {
        field: 'siteBalanceCount',
        headerName: 'сайт',
      },
      { field: 'outgoingBalanceCount', headerName: 'исх готовы' },

      { field: 'totalBalanceCount', headerName: 'итого' },
    ],
  },
];

export const STATISTICS_RESPONSEBLE_CHART_OPTIONS: any = {
  plugins: {
    title: {
      display: true,
      text: '',
    },
    tooltip: {
      displayColors: false,
      backgroundColor: (color: any) => color?.tooltip?.labelColors[0]?.backgroundColor || '',
      callbacks: {},
    },
  },
  legend: {
    display: true,
    usePointStyle: false,
    labels: {
      boxWidth: 70,
      boxHeight: 30,
      color: '#000000',
      fullWidth: false,
    },
  },
  responsive: true,

  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 30,
        padding: 5,
        labelOffset: 15,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
    },

    percentageYAxis: {
      // Новая ось Y для процента
      type: 'linear',
      position: 'right',
      min: 0,
      max: 100, // Проценты от 0 до 100
      title: {
        display: true,
        text: 'Процент монтажа',
      },
      grid: {
        display: false,
      },
      ticks: {
        callback: (value: any) => value + ' %', // Добавляем символ процента к числам
      },
    },
  },
};

export const getDataBar = (labels: string[], barDataFiltered: StatisticsResponsebleDto[]) => {
  return {
    labels,
    datasets: [
      {
        type: 'line' as const,
        label: 'Процент монтажа',
        backgroundColor: '#2b1aa9',
        borderColor: '#2b1aa9',
        borderWidth: 2,
        fill: false,
        yAxisID: 'percentageYAxis', // Указываем идентификатор для оси Y процента
        data: barDataFiltered.map((c) => {
          // Вычисляем процент монтажа (установлено) относительно суммы (Z)
          const total =
            c.installedTotalCount + c.tasksWithTkoAfterCallsTotalCount + c.totalBalanceCount;
          if (total === 0) return 0; // Защита от деления на ноль
          return Math.round((c.installedTotalCount / total) * 100);
        }),
        tooltip: {
          // Настройка всплывающей подсказки для этой линии
          callbacks: {
            label: (context: any) => {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              label += context.parsed.y + ' %'; // Показываем значение с символом процента
              return label;
            },
          },
        },
      },
      {
        type: 'bar' as const,
        label: 'Установлено',
        data: barDataFiltered.map((c) => c.installedTotalCount),
        backgroundColor: 'green',
        minBarLength: 3,
        tooltip: {
          callbacks: {
            label: (context: any) => {
              if (context.dataIndex >= 0 && context.dataIndex < barDataFiltered.length) {
                const tooltipContent = [
                  `вход: ${barDataFiltered[context.dataIndex].installedFromIncoming},`,
                  `сайт: ${barDataFiltered[context.dataIndex].installedFromSite},`,
                  `исх готовы: ${barDataFiltered[context.dataIndex].installedFromOutgoing},`,
                  `итого: ${context.parsed.y}.`,
                ];
                return tooltipContent;
              }
            },
          },
        },
      },
      {
        type: 'bar' as const,
        label: 'ТКО после обращения',
        data: barDataFiltered.map((c) => c.tasksWithTkoAfterCallsTotalCount),
        backgroundColor: 'orange',
        minBarLength: 4,
        tooltip: {
          callbacks: {
            label: (context: any) => {
              if (context.dataIndex >= 0 && context.dataIndex < barDataFiltered.length) {
                const tooltipContent = [
                  `вход: ${barDataFiltered[context.dataIndex].tasksWithTkoAfterInCallCount},`,
                  `сайт: ${barDataFiltered[context.dataIndex].tasksWithTkoAfterSiteCallCount},`,
                  `исх готовы: ${
                    barDataFiltered[context.dataIndex].tasksWithTkoAfterOutCallCount
                  },`,
                  `итого: ${context.parsed.y}.`,
                ];

                return tooltipContent;
              }
            },
          },
        },
      },

      {
        type: 'bar' as const,
        label: 'Остаток',
        data: barDataFiltered.map((c) => c.totalBalanceCount),
        backgroundColor: 'gray',
        minBarLength: 5,
        tooltip: {
          callbacks: {
            label: (context: any) => {
              if (context.dataIndex >= 0 && context.dataIndex < barDataFiltered.length) {
                const tooltipContent = [
                  `вход: ${barDataFiltered[context.dataIndex].incomingBalanceCount},`,
                  `сайт: ${barDataFiltered[context.dataIndex].siteBalanceCount},`,
                  `исх готовы: ${barDataFiltered[context.dataIndex].outgoingBalanceCount},`,
                  `итого: ${context.parsed.y}.`,
                ];

                return tooltipContent;
              }
            },
          },
        },
      },
    ],
  };
};
