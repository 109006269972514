import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Tooltip } from '@mui/material';
import { Arrow, NavLinkMainWrapper } from '@/components/widgets/Navbar/NavBar.styled';
import { color } from '@/styles/mixins';

interface INavDropdownItem {
  parentTitle: JSX.Element | string;
  children: JSX.Element[];
  type?: 'icon' | 'text';
  tooltipText?: string;
}

export const NavDropdownItem: React.FC<INavDropdownItem> = ({
  parentTitle,
  children,
  type = 'text',
  tooltipText,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {type === 'text' && (
        <NavLinkMainWrapper
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          {parentTitle}
          <Arrow />
        </NavLinkMainWrapper>
      )}
      {type === 'icon' &&
        (tooltipText ? (
          <Tooltip title={tooltipText}>
            <IconButton
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{ '&:hover *': { color: color('aBlue') } }}
            >
              {parentTitle}
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            {parentTitle}
          </IconButton>
        ))}
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children.map((elem, index) => (
          <MenuItem
            onClick={handleClose}
            key={index}
            style={{ padding: 0 }}
          >
            {elem}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
