import { LoadingButton } from '@mui/lab';
import { FormControl, InputLabel, NativeSelect, Stack, Tooltip, Typography } from '@mui/material';
import { PnrTagPeriodSelector } from './PnrTagPeriodSelect';
import CachedSharpIcon from '@mui/icons-material/CachedSharp';
import { useEffect, useState } from 'react';
import {
  ApiFindAllPnrInfoByTaskIdList,
  ApiFindAllTaskGeoByTaskIdList,
} from '@/services/YodaRestService';
import { CopiedButton } from '@/components/maps/pnrMap/component/CopiedButton';
import { GeoTask, PnrGeoRequest, PnrTag } from '@/dto/taskmap/Dto';
import { useGeoQuery } from '@/components/features/pnr/hooks/GeoQueryHook';
import { usePnr } from '@/components/maps/pnrMap/hooks/PnrHook';
import { useMap } from '@/components/features/pnr/hooks/MapHook';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { GeoPoint, TaskGeoPoint } from '@/components/maps/GeoPoint';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { geoResponseToGeoTaskList } from '../utils';

export const PnrTagComponent = (props: any) => {
  const [loading, setLoading] = useState(false);
  const { currentGeoBounds, setSelectedGeoPointList, selectedGeoPointList } = useMap();
  const { setIsInProgress } = useScreenHoldHook();
  const { currentTaskFilterParam } = useGeoQuery();
  const [toShowCoppied, setToShowCoppied] = useState(false);
  const [showPnrTagBt, setShowPnrTagBt] = useState(false);
  const {
    addPnrTag,
    getCurrentPnrTag,
    setCurrentPnrTag,
    isPnrTagAvailable,
    getTaskIdList,
    loadTaskIdListByPnrTag,
    getPnrTagItem,
  } = usePnr();
  const [geoTask, setGeoTask] = useState<GeoTask>();
  const [resultMsg, setResultMsg] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [pnrTaggedGeoPoint, setPnrTaggedGeoPoint] = useState<GeoPoint[]>([]);
  const [pnrTagPeriod, setPnrTagPeriod] = useState(30);

  useEffect(() => {
    addPnrTag(props.geoTask);
    setGeoTask(props.geoTask);
    const item = getPnrTagItem(props.geoTask);
    if (item) {
      setResultMsg(item.resultMessage);
      setErrorMsg(item.errorMessage);
      setToShowCoppied(item.resultMessage != undefined && item.errorMessage === undefined);
    } else {
      setResultMsg(undefined);
      setErrorMsg(undefined);
    }
  }, [props.geoTask]);

  const handleSelectPnrTag = (event: any) => {
    const pnrTag = event.target.value;
    if (pnrTag) {
      setCurrentPnrTag(geoTask, pnrTag);
      setShowPnrTagBt(true);
    } else {
      setShowPnrTagBt(false);
    }
  };

  const onRefreshClick = () => {
    setResultMsg(undefined);
    setErrorMsg(undefined);
    setPnrTaggedGeoPoint([]);
    setToShowCoppied(false);
    const currentPnrTag = getCurrentPnrTag(geoTask);
    if (currentPnrTag) {
      const pnrGeoRequest: PnrGeoRequest = {
        geoBounds: currentGeoBounds,
        taskFilterParam: currentTaskFilterParam,
        additionalParam: undefined,
        pnrTag: currentPnrTag,
        period: pnrTagPeriod,
      };
      setLoading(true);
      loadTaskIdListByPnrTag(geoTask, pnrGeoRequest)
        .then((res) => {
          setResultMsg(res);
          setToShowCoppied(true);
        })
        .catch((err) => {
          setErrorMsg(err);
        })
        .finally(() => setLoading(false));
    }
  };

  const getValueForClipboard = async (pnrInfoType: string): Promise<string[]> => {
    const taskIdList = getTaskIdList(geoTask);
    if (!taskIdList) {
      return Promise.reject('');
    }
    return await ApiFindAllPnrInfoByTaskIdList(pnrInfoType, taskIdList)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch(() => {
        return Promise.reject('');
      });
  };

  const showPnrTagResultOnMap = () => {
    const taskIdList = getTaskIdList(geoTask);
    if (taskIdList && pnrTaggedGeoPoint.length == 0) {
      setIsInProgress(true);
      ApiFindAllTaskGeoByTaskIdList(taskIdList)
        .then((res) => {
          const geoTaskList = geoResponseToGeoTaskList(res.data);
          const geoPointList = geoTaskList.map((t) => new TaskGeoPoint(t));
          setSelectedGeoPointList(geoPointList);
          setPnrTaggedGeoPoint(geoPointList);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsInProgress(false));
    } else {
      setSelectedGeoPointList(pnrTaggedGeoPoint);
    }
  };

  const hidePnrTagResultOnMap = () => {
    setSelectedGeoPointList([]);
  };

  const getMacInfo = () => {
    return getValueForClipboard('MAC');
  };

  const getNumberPuInfo = () => {
    return getValueForClipboard('NUMBER_PU');
  };

  const getMacAndNumberPuInfo = () => {
    return getValueForClipboard('MAC_NUMBER_PU');
  };

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='end'
        alignItems='end'
        spacing={0}
      >
        {showPnrTagBt && (
          <LoadingButton
            color='secondary'
            loading={loading}
            loadingPosition='start'
            sx={{ mx: -2 }}
            startIcon={
              <CachedSharpIcon
                sx={{ fontSize: 22, cursor: 'pointer' }}
                color='primary'
                onClick={onRefreshClick}
              />
            }
          ></LoadingButton>
        )}
        <FormControl fullWidth>
          <InputLabel
            variant='standard'
            htmlFor='uncontrolled-native'
          >
            Тип &quot;Признака&quot;
          </InputLabel>
          <NativeSelect
            inputProps={{
              name: 'pnrTag',
              id: 'uncontrolled-native',
            }}
            // sx={{maxWidth:120}}
            onChange={handleSelectPnrTag}
            value={getCurrentPnrTag(geoTask)}
          >
            <option></option>
            {Object.keys(PnrTag).map((t) => (
              <option
                key={t}
                value={t}
                disabled={isPnrTagAvailable(geoTask, t)}
              >
                {PnrTag[t]}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
        {getCurrentPnrTag(geoTask) === 'COORDINATOR' && (
          <PnrTagPeriodSelector onChange={setPnrTagPeriod} />
        )}
      </Stack>
      <Stack
        direction='row'
        justifyContent='end'
        alignItems='center'
        spacing={0}
      >
        <>
          {(resultMsg && (
            <Typography
              sx={{ mt: 1, color: 'green' }}
              variant='caption'
              display='block'
              gutterBottom
            >
              {resultMsg}
            </Typography>
          )) || (
            <Typography
              sx={{ mt: 1, color: 'red' }}
              variant='caption'
              display='block'
              gutterBottom
            >
              {errorMsg}
            </Typography>
          )}
        </>
        {toShowCoppied && (
          <>
            {selectedGeoPointList.length == 0 ? (
              <Tooltip
                title='Показать на карте'
                placement='top'
              >
                <PinDropIcon
                  sx={{ width: 20, ml: 1, cursor: 'pointer' }}
                  onClick={showPnrTagResultOnMap}
                ></PinDropIcon>
              </Tooltip>
            ) : (
              <Tooltip
                title='Показать все объекты'
                placement='top'
              >
                <CancelPresentationIcon
                  sx={{ color: 'gray', fontSize: 22, cursor: 'pointer' }}
                  onClick={hidePnrTagResultOnMap}
                ></CancelPresentationIcon>
              </Tooltip>
            )}
            <CopiedButton
              title='MAC'
              getValue={getMacInfo}
            />
            <CopiedButton
              title='Номер ПУ'
              getValue={getNumberPuInfo}
            />
            <CopiedButton
              title='MAC и Номер ПУ'
              getValue={getMacAndNumberPuInfo}
            />
          </>
        )}
      </Stack>
    </div>
  );
};
