import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { HistoryResponse } from '@/dto/taskmap/task/TaskResultDto';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import moment from 'moment';

interface IHistoryGroup {
  group: HistoryResponse[];
}

export const HistoryGroup: FC<IHistoryGroup> = ({ group }) => {
  return (
    <Grid
      item
      xs={12}
    >
      <BoxShadow>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={6}
          >
            {moment(group[0].eventAt).format('DD.MM.yyyy HH:mm:ss')}
          </Grid>
          <Grid
            item
            xs={6}
          >
            {group[0].fullName}
          </Grid>
          {group.map((elem) => (
            <React.Fragment key={`${elem.value}${elem.oldValue}`}>
              <Grid
                item
                xs={3}
              >
                {elem.attributeName}:
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'space-evenly', overflow: 'hidden' }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {elem.oldValue}
                </span>
              </Grid>
              <Grid
                item
                xs={1}
              >
                <EastRoundedIcon sx={{ margin: '0 12px' }} />
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'space-evenly', overflow: 'hidden' }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {elem.value}
                </span>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </BoxShadow>
    </Grid>
  );
};
