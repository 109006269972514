import { ECallCenterResult } from '@/components/features/complaints/CallCenterModal/utils';
import axios from 'axios';

import { removeTokens, yodaJwt } from '@/app/auth/AuthProvider';
import { ApiResponse } from '@/types/api';
import { GetJWT } from '../YodaRestService';
import {
  CatalogResponse,
  ComplaintStage,
  ComplaintEnergyClassification,
  SetNotSecStateRequestDto,
  ComplaintGetReportRequestDto,
} from './ComplaintServiceDto';
import { IComplaintItem } from '@/components/features/complaints/types';
import { FixedValues } from '@/components/features/complaints/store';

function getHost() {
  const HOST = import.meta.env.VITE_COMPLAINT_SERVICE_BASE_URL;
  if (typeof HOST == 'undefined') {
    alert('Адрес сервера для запросов к COMPLAINT SERVICE API не задан');
  } else return HOST as string;
}
export const getYodaJWTLocal = () => localStorage.getItem(yodaJwt);
const saveYodaJWTLocal = (token: string) => localStorage.setItem(yodaJwt, token);
const HOST = getHost();
const UNAUTHORIZED_CODE = 401;
export const SAVE_COMPLAINT_JWT_TOKEN = 'SAVE_COMPLAINT_JWT_TOKEN';

export const ComplaintClient = axios.create({
  baseURL: HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});
const MULTIPART_HEADER = { 'Content-Type': 'multipart/form-data' };
let refreshTokenPromise: ApiResponse<string> | null = null;

ComplaintClient.interceptors.request.use(
  async function (config) {
    config.headers = config.headers ?? {};
    const jwt = getYodaJWTLocal();
    if (jwt) {
      config.headers['Authorization'] = `Bearer ${jwt}`;
    } else {
      refreshTokenPromise ??= GetJWT();
      const { data } = await refreshTokenPromise;
      refreshTokenPromise = null;
      saveYodaJWTLocal(data);
      config.headers['Authorization'] = `Bearer ${data}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

ComplaintClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === UNAUTHORIZED_CODE) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        refreshTokenPromise ??= GetJWT();
        const { data } = await refreshTokenPromise;
        refreshTokenPromise = null;
        saveYodaJWTLocal(data);
        return ComplaintClient(originalRequest);
      } else {
        removeTokens();
      }
    }
    return Promise.reject(error);
  }
);

export async function getComplaintsByFilter(filter: FixedValues): ApiResponse<any> {
  const { size, page, ...restFilter } = filter;
  const url = HOST + `/complaint?size=${size}&page=${page}`;
  return ComplaintClient.post(url, restFilter);
}
export async function createComplaintEnergy(data: any): ApiResponse<any> {
  const url = HOST + `/complaint/energy`;
  return ComplaintClient.post(url, data, { headers: MULTIPART_HEADER });
}
export async function createComplaintOdpu(data: any): ApiResponse<any> {
  const url = HOST + `/complaint/odpu`;
  return ComplaintClient.post(url, data, { headers: MULTIPART_HEADER });
}
export async function getEnergeTypeList(): ApiResponse<any> {
  const url = HOST + `/complaint/energy/type`;
  return ComplaintClient.get(url);
}

export async function getCallCenterResultType(): ApiResponse<CatalogResponse<ECallCenterResult>> {
  const url = HOST + `/complaint/energy/call-center-result-type`;
  return ComplaintClient.get(url);
}
export async function getComaplaintStageTypeList(): ApiResponse<CatalogResponse<ComplaintStage>> {
  const url = HOST + `/complaint/stage`;
  return ComplaintClient.get(url);
}
export async function getEnergeClassificationList(): ApiResponse<
  CatalogResponse<ComplaintEnergyClassification>
> {
  const url = HOST + `/complaint/energy/classification`;
  return ComplaintClient.get(url);
}
export async function getComplaintTypeList(): ApiResponse<
  CatalogResponse<ComplaintEnergyClassification>
> {
  const url = HOST + `/complaint/type`;
  return ComplaintClient.get(url);
}

export async function editComplaintOdpu(data: any, id: number): ApiResponse<any> {
  const url = HOST + `/complaint/odpu/${id}?id=${id}`;
  return ComplaintClient.put(url, data);
}
export async function editComplaintEnergy(data: any, id: number): ApiResponse<any> {
  const url = HOST + `/complaint/energy/${id}`;
  return ComplaintClient.put(url, data);
}
export async function createComment(
  data: any,
  complaintId: number | null | undefined
): ApiResponse<any> {
  const url = HOST + `/complaint/${complaintId}/comment`;
  return ComplaintClient.post(url, data);
}
export async function getAllCommentsByComplaintId(
  complaintId: number | null | undefined
): ApiResponse<any> {
  const url = HOST + `/complaint/${complaintId}/comment`;
  return ComplaintClient.get(url);
}
export async function moveToCallCenter(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/energy/${complaintId}/state/call_centered`;
  return ComplaintClient.post(url, data);
}
export async function returnComplaint(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/energy/${complaintId}/state/returned`;
  return ComplaintClient.post(url, data);
}

export async function setNotSecState(
  complaintId: number | null | undefined,
  data: SetNotSecStateRequestDto
): ApiResponse<any> {
  const url = HOST + `/complaint/energy/${complaintId}/state/not_sec`;
  return ComplaintClient.post(url, data);
}

export async function returnToModeration(
  complaintId: number | null | undefined
): ApiResponse<string> {
  const url = HOST + `/complaint/energy/${complaintId}/state/moderated`;
  return ComplaintClient.post(url, {});
}

export async function transferToResponsibleEnergy(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/energy/${complaintId}/state/transferred_to_responsible`;
  return ComplaintClient.post(url, data);
}
export async function returnComplaintOdpu(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/odpu/${complaintId}/state/returned`;
  return ComplaintClient.post(url, data);
}
export async function returnComplaintOdpuBackToModerator(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/odpu/${complaintId}/state/moderated`;
  return ComplaintClient.post(url, data);
}
export async function duplicatedComplaint(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/energy/${complaintId}/state/duplicated`;
  return ComplaintClient.post(url, data);
}
export async function duplicatedComplaintOdpu(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/odpu/${complaintId}/state/duplicated`;
  return ComplaintClient.post(url, data);
}
export async function compliteComplaintOdpu(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/odpu/${complaintId}/state/completed`;
  return ComplaintClient.post(url, data);
}
export async function compliteComplaintEnergy(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/energy/${complaintId}/state/completed`;
  return ComplaintClient.post(url, data);
}

export async function moveAfterResponsibleComplaintEnergy(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/energy/${complaintId}/state/moderated_after_responsible`;
  return ComplaintClient.post(url, data);
}
export async function inProgressComplaint(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/energy/${complaintId}/state/in_progress`;
  return ComplaintClient.post(url, data);
}
export async function inProgressComplaintOdpu(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/odpu/${complaintId}/state/in_progress`;
  return ComplaintClient.post(url, data);
}
export async function deleteFileFromComplaint(
  complaintId: number | null | undefined,
  complaintType: string | undefined,
  fileId: string
): ApiResponse<any> {
  const url = HOST + `/complaint/${complaintType}/${complaintId}/files/${fileId}`;
  return ComplaintClient.delete(url);
}
export async function updateFilesInComplaint(
  complaintId: number | null | undefined,
  data: any,
  complaintType: string
): ApiResponse<any> {
  const url = HOST + `/complaint/${complaintType}/${complaintId}/files`;
  return ComplaintClient.post(url, data, { headers: MULTIPART_HEADER });
}

export async function energyAfterCallCenter(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = HOST + `/complaint/energy/${complaintId}/state/moderated_after_cc`;
  return ComplaintClient.post(url, data);
}
export async function getEnergyComplaint(complaintId: number | null | undefined): ApiResponse<any> {
  const url = HOST + `/complaint/energy/${complaintId}`;
  return ComplaintClient.get(url);
}
export async function getOdpuComplaint(complaintId: number | null | undefined): ApiResponse<any> {
  const url = HOST + `/complaint/odpu/${complaintId}`;
  return ComplaintClient.get(url);
}
export async function downloadComplaintImage(fileId: number | null | string): ApiResponse<any> {
  const url = HOST + `/complaint/energy/files/${fileId}`;
  return ComplaintClient.get(url, { responseType: 'blob' });
}
export async function getCompalintWorkTypeList(): ApiResponse<any> {
  const url = HOST + `/complaint/energy/work-type`;
  return ComplaintClient.get(url);
}

export async function getReportCommonDay(data: ComplaintGetReportRequestDto): ApiResponse<any> {
  const url = HOST + `/report/common-daily`;
  return ComplaintClient.post(url, data, {
    responseType: 'blob',
  });
}
export async function getReportWeeklyDay(): ApiResponse<any> {
  const url = HOST + `/report/meeting-weekly`;
  return ComplaintClient.post(
    url,
    {},
    {
      responseType: 'blob',
    }
  );
}

export async function complaintMissedCall(
  complaintId: number | null | undefined
): ApiResponse<number> {
  const url = HOST + `/complaint/energy/${complaintId}/missed-call`;
  return ComplaintClient.post(url);
}
export async function changeResponsibleApi(
  complaintId: number | null | undefined,
  responsibleId: string | null | undefined
): ApiResponse<IComplaintItem> {
  const url = HOST + `/complaint/energy/${complaintId}/responsible-id/${responsibleId}`;
  return ComplaintClient.put(url);
}
export async function getComaplaintSorceList(): ApiResponse<CatalogResponse<ComplaintStage>> {
  const url = HOST + `/complaint/energy/source`;
  return ComplaintClient.get(url);
}
export async function dowmloadCompalintsByFilterParams(
  params: FixedValues
): ApiResponse<CatalogResponse<ComplaintStage>> {
  const url = HOST + `/report/complaints`;
  return ComplaintClient.post(url, params, {
    responseType: 'blob',
  });
}
