import { Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { PnrTagComponent } from '../component/PnrTagComponent';
import { GeoTaskRadar } from '../component/GeoTaskRadar';
import { useGeoCard } from '@/components/maps/pnrMap/hooks/GeoCardHook';
import { GeoPoint, GeoPointType } from '@/components/maps/GeoPoint';
import { GeoTask, TaskStatus } from '@/dto/taskmap/Dto';
import { useMap, GeoCircle } from '@/components/features/pnr/hooks/MapHook';
import { usePnr } from '@/components/maps/pnrMap/hooks/PnrHook';

export const GeoCard = (props: any) => {
  const [status, setStatus] = useState<any>();
  const [geoPoint, setGeoPoint] = useState<GeoPoint>();
  const { removeFromGeoCard, geoCardIndexOf } = useGeoCard();
  const { removePnrTagByGeoTask } = usePnr();
  const { addGeoCircle, removeGeoCircle, getGeoCirctlByGeoTask } = useMap();

  const removeItem = (event: any) => {
    if (geoPoint) {
      removeGeoCircle(geoPoint);
      removeFromGeoCard(geoPoint);
      removePnrTagByGeoTask(geoPoint.getObject() as GeoTask);
    }
  };

  useEffect(() => {
    const currentGeoPoint: GeoPoint = props.geoPoint;
    if (currentGeoPoint) {
      setGeoPoint(currentGeoPoint);
      // addGeoCircle(props.geoTask, 0);

      Object.keys(TaskStatus).forEach((t, i) => {
        if (t === currentGeoPoint.getObject()?.status) {
          setStatus(Object.values(TaskStatus)[i]);
        }
      });
    }
  }, [props.geoPoint]);

  const changeLatitude = (event: any) => {
    const geoCircle: GeoCircle | undefined = getGeoCirctlByGeoTask(props.geoTask);
    if (geoCircle) {
      addGeoCircle(geoCircle.target, [event.target.value, geoCircle.coords[0]], geoCircle.radius);
    }
  };

  const changeLongitude = (event: any) => {
    const geoCircle: GeoCircle | undefined = getGeoCirctlByGeoTask(props.geoTask);
    if (geoCircle) {
      addGeoCircle(geoCircle.target, [geoCircle.coords[1], event.target.value], geoCircle.radius);
    }
  };

  const getContent = (geoPoint: GeoPoint) => {
    const geoTask = geoPoint?.getObject();
    const prefix = `${geoCardIndexOf(geoPoint) + 1}.`;
    if (geoTask) {
      return `${prefix} Заявка ${
        geoTask?.type?.toUpperCase() === 'NETWORKELEMENTS' ? 'ОО' : 'ПУ'
      } № ${geoTask?.number} (${status})`;
    } else {
      return `${prefix} Гео точка без заявки`;
    }
  };

  return (
    <Card
      sx={{ minWidth: 275, mb: 1 }}
      variant='outlined'
    >
      <CardContent>
        <Stack
          direction='row'
          justifyContent='space-between'
        >
          {geoPoint && (
            <Typography
              sx={{ fontSize: 14, m: 0 }}
              color='text.secondary'
              variant='h5'
              gutterBottom
            >
              {getContent(geoPoint)}
            </Typography>
          )}
          <DeleteIcon
            onClick={removeItem}
            sx={{ color: 'gray', fontSize: 22, cursor: 'pointer' }}
          />
        </Stack>
        <Stack
          direction='row'
          sx={{ mb: 1.5 }}
        >
          {geoPoint && (
            <>
              <TextField
                label='широта'
                variant='standard'
                sx={{ px: 1, py: -1 }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                type='number'
                value={geoPoint.getCoords()[0]}
                // defaultValue={geoPoint.getCoords()[0]}
                InputProps={{ readOnly: true }}
                onChange={changeLatitude}
              ></TextField>
              <TextField
                label='долгота'
                variant='standard'
                sx={{ px: 1, py: -1 }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                type='number'
                value={geoPoint.getCoords()[1]}
                // defaultValue={geoPoint.getCoords()[1]}
                InputProps={{ readOnly: true }}
                onChange={changeLongitude}
              ></TextField>
            </>
          )}
        </Stack>
        {geoPoint && geoPoint.getType().valueOf() != GeoPointType.FREE_POINT && (
          <PnrTagComponent
            id={'pnt-tag:' + geoPoint?.getObject()?.taskId}
            geoTask={geoPoint?.getObject()}
          ></PnrTagComponent>
        )}
        <GeoTaskRadar geoPoint={geoPoint}></GeoTaskRadar>
      </CardContent>
    </Card>
  );
};
