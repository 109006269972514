import { createStore } from '@/utils/createStore';

interface NavbarStore {
  openErrorsDialog: boolean;
  isOpenMobileNavbar: boolean;
  setOpenErrorsDialog: (state: boolean) => void;
  toggleDrawer: (state: boolean) => void;
}
export const useNavbar = createStore<NavbarStore>(
  (set) => ({
    openErrorsDialog: false,
    isOpenMobileNavbar: false,

    setOpenErrorsDialog: (state) =>
      set(() => ({
        openErrorsDialog: state,
      })),
    toggleDrawer: (state) => {
      set(() => ({
        isOpenMobileNavbar: state,
      }));
    },
  }),
  'useNavbar'
);
