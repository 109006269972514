import { BoxShadow } from '@/components/ui/BoxShadow';
import { bounce } from '@/components/features/pnr/HouseEntranceHeader/HouseEntranceHeader.styled';
import { mediaBreakpointDown } from '@/styles/mixins';
import { IconButton, styled } from '@mui/material';

interface ComplaintCommentContainerProps {
  open?: boolean;
}

export const ComplaintCommentWrapper = styled('div')<ComplaintCommentContainerProps>(
  ({ open }) => ({
    position: 'absolute',
    left: -10,
    top: 0,
    transform: 'translate(-100%,0)',
    display: 'flex',
    transition: 'gap .3s',
    gap: open ? 10 : 0,
    [mediaBreakpointDown('lg')]: {
      position: 'fixed',
      top: -70,
      left: '42%',
      transform: 'translateX(-50%)',
      width: '100%',
      maxWidth: 600,
      padding: 10,
      zIndex: 1000,
    },
    [mediaBreakpointDown('md')]: {
      position: 'fixed',
      top: -70,
      left: '47%',
      transform: 'translateX(-50%)',
      width: '100%',
      maxWidth: 400,
      padding: 10,
      zIndex: 1000,
    },
  })
);

export const ComplaintCommentButton = styled(BoxShadow)({
  padding: 10,
  cursor: 'pointer',
  transition: 'background .3s',
  width: 'fit-content',
  height: 'fit-content',
  backgroundColor: '#F5F9FF',

  '&:hover': { svg: { animation: `${bounce} 1s 1 both` } },
});

export const ComplaintCommentsWrapper = styled(BoxShadow)<ComplaintCommentContainerProps>(
  ({ open }) => ({
    transition: 'width .3s',

    padding: open ? 8 : 0,
    width: open ? 500 : 0,
    overflow: open ? 'auto' : 'hidden',
    height: open ? 'auto' : 0,
    backgroundColor: '#F5F9FF',
  })
);

export const ComplaintCommentIconButton = styled(IconButton)({
  svg: {
    transition: '0.5s ease',
  },
});
