import { Box, Button, Grid, TextField } from '@mui/material';
import React, { useMemo, memo } from 'react';
import { SupplyBillBrowseDto, SupplyStamp } from '@/dto/taskmap/Dto';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useSupplyStore, { initialStampList } from '../SupplyStore';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import { SupplyConstant } from '../SupplyConstant';
import {
  convertValue,
  checkLetter,
  checkLettersEquals,
  getLetterFromString,
  getNumberFromString,
  checkStampRegexp,
  checkValueFromTo,
} from '../utils';
import {
  IconButtonStyled,
  SupplyButtonsWrapper,
  Wrapper,
} from '../SupplyPUComponent/SupplyPUComponent.styled';
import {
  calcWholeCount,
  getStampsNumbers,
  generateStampItems,
  isNumberExist,
  getStampsFullNumbers,
  generateNextStampId,
  StampList,
  catchLsStampInitError,
} from './utils';
import {
  getSavePuListId,
  LS_PU_TYPE,
  useStorehouseWorkerAccess,
  useStorehouseLS,
} from '../SupplyPUComponent/utils';
import SupplyClear from '../SupplyClear/SupplyClear';
import shallow from 'zustand/shallow';

export interface SupplyBillComponentProps extends SupplyTabType {
  supplyBillDto?: SupplyBillBrowseDto;
  nonEditableBill?: boolean;
  accessProject?: boolean;
}

export interface SupplyTabType {
  tabType: LS_PU_TYPE;
  withoutLsSave?: boolean;
}

type StampComponentProps = SupplyBillComponentProps;

const StampComponent = (props: StampComponentProps) => {
  const {
    resetStampList,
    stampList,
    setStampList,
    wholeCount,
    declaredCount,
    setWholeCount,
    setDeclaredCount,
    billType,
  } = useSupplyStore(
    (state) => ({
      resetStampList: state.resetStampList,
      stampList: state.stampList,
      setStampList: state.setStampList,
      wholeCount: state.wholeCount,
      declaredCount: state.declaredCount,
      setWholeCount: state.setWholeCount,
      setDeclaredCount: state.setDeclaredCount,
      billType: state.billType,
    }),
    shallow
  );
  const { getValue, setValue } = useStorehouseLS();

  const editAccess = useStorehouseWorkerAccess();
  const lsKey = getSavePuListId(props.tabType, props?.supplyBillDto);

  const allBackendStampNumbers = useMemo(
    () => props?.supplyBillDto?.stampList?.map((stamp) => stamp.stampItems).flat(),
    [props?.supplyBillDto]
  );

  const [lastRowId, setLastRowId] = React.useState<number>(1);
  const [stampFieldError, setStampFieldError] = React.useState<Map<number, boolean>>(new Map());

  React.useEffect(() => {
    if (props?.supplyBillDto?.stampList?.length) {
      initStamps();
      const count = calcWholeCount(props.supplyBillDto.stampList);
      setDeclaredCount(count > 0 ? count + '' : '');
    } else {
      if (
        props.supplyBillDto &&
        billType !== SupplyConstant.DRAFT_TYPE_ISSUE &&
        billType !== SupplyConstant.DRAFT_TYPE_RETURN &&
        billType !== SupplyConstant.DRAFT_TYPE_RELOCATION_POST &&
        billType !== SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST &&
        billType !== SupplyConstant.DRAFT_FACTORY_TYPE_GET
      ) {
        return;
      }
      initStampsFromLs();
    }
  }, [props?.supplyBillDto]);

  React.useEffect(() => {
    if (
      props.supplyBillDto &&
      billType !== SupplyConstant.DRAFT_TYPE_ISSUE &&
      billType !== SupplyConstant.DRAFT_TYPE_RETURN &&
      billType !== SupplyConstant.DRAFT_TYPE_RELOCATION_POST &&
      billType !== SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST &&
      billType !== SupplyConstant.DRAFT_FACTORY_TYPE_GET
    ) {
      return;
    }
    updateLocalStorage();
  }, [stampList]);

  const initStampsFromLs = () => {
    const stampsArr = getValue()?.[lsKey]?.stamps ?? [...initialStampList];
    try {
      const mappedStamps = stampsArr?.map((elem, index) => {
        const stampFullNumbers = getStampsFullNumbers(elem);
        return {
          ...elem,
          id: index + 1,
          stampFullNumbers: stampFullNumbers,
          stampNumbers: getStampsNumbers(elem.from!, elem.to!),
          stampLetter: getLetterFromString(elem.from!),
          stampItems: stampFullNumbers.map((fullNumber) => ({ stampNumber: fullNumber, id: null })),
        };
      });
      mappedStamps?.forEach((elem) => {
        setCountValue(mappedStamps, elem);
      });
      setLastRowId(mappedStamps?.length);
      setStampList(mappedStamps);
    } catch (error) {
      catchLsStampInitError(error, stampsArr);
    }
  };

  const initStamps = () => {
    const supplyBill = { ...props.supplyBillDto } as SupplyBillBrowseDto | undefined;

    const stampsArr = supplyBill?.stampList?.map((elem, index) => {
      return {
        ...elem,
        id: index + 1,
        stampFullNumbers: getStampsFullNumbers(elem),
        stampNumbers: getStampsNumbers(elem.from!, elem.to!),
        stampLetter: getLetterFromString(elem.from!),
        backendItem: true,
      };
    }) as StampList[];

    const tmpFromLS =
      (getValue()?.[lsKey]?.stamps?.map((el, index) => ({
        ...el,
        id: generateNextStampId(stampsArr) + index,
      })) as StampList[]) ?? [];

    const mergedStamps = stampsArr?.concat(tmpFromLS);

    mergedStamps?.forEach((elem) => {
      setCountValue(mergedStamps, elem);
    });
    setLastRowId(mergedStamps!.length);
    setStampList(mergedStamps!);
  };

  const setFromValue = (data: SupplyStamp, val: any) => {
    const newStamps = stampList.map((obj) => {
      if (obj.id === data.id) {
        return { ...obj, from: val.toUpperCase(), stampLetter: getLetterFromString(val) };
      }
      return obj;
    });
    setCountValue(newStamps, data);
  };

  const setToValue = (data: SupplyStamp, val: any) => {
    const newStamps = stampList.map((obj) => {
      if (obj.id === data.id) {
        return { ...obj, to: val.toUpperCase() };
      }
      return obj;
    });
    setCountValue(newStamps, data);
  };

  const setCountValue = (stampList: SupplyStamp[], data: SupplyStamp) => {
    const newStamps = stampList.map((obj) => {
      if (obj.id === data.id) {
        if (
          obj.to &&
          obj.from &&
          !isNaN(getNumberFromString(obj.to)) &&
          !isNaN(getNumberFromString(obj.from))
        ) {
          const result = getNumberFromString(obj.to) - getNumberFromString(obj.from) + 1;
          const generatedStampFullNumbers = getStampsFullNumbers(obj);
          const generatedStampItems = generateStampItems(
            allBackendStampNumbers,
            generatedStampFullNumbers
          );
          return {
            ...obj,
            count: result,
            stampNumbers: getStampsNumbers(obj.from, obj.to),
            stampFullNumbers: generatedStampFullNumbers,
            stampItems: generatedStampItems,
          };
        }
        if (!obj.to || !obj.from) {
          return { ...obj, count: 0 };
        }
        return obj;
      }

      return obj;
    });

    updateStampList(newStamps);
    checkDuplicate(newStamps);
    setWholeCountValue(newStamps);
  };

  const updateStampList = (list: SupplyStamp[]) => {
    setStampList(list);
  };

  const checkDuplicate = (stampList: SupplyStamp[]) => {
    stampList.forEach((stamp) => {
      const numberExist = isNumberExist(stamp, stampList);
      setStampFieldError(new Map(stampFieldError.set(stamp.id, numberExist)));
    });
  };

  const setWholeCountValue = (stampList: SupplyStamp[]) => {
    const result = calcWholeCount(stampList);
    setWholeCount(result == 0 ? '' : result + '');
  };

  const generateId = () => {
    const newId = lastRowId + 1;
    setLastRowId(newId);
    return newId;
  };

  const removeRow = (rowId: number) => {
    const index = stampList.indexOf(stampList.find((it) => it.id === rowId)!);
    if (index !== -1) {
      stampList.splice(index, 1);
      updateLocalStorage();

      setLastRowId(lastRowId - 1);
      setWholeCountValue(stampList);
    }
  };

  const confirmRow = (rowId: number) => {
    const index = stampList.indexOf(stampList.find((it) => it.id === rowId)!);
    if (index !== -1) {
      stampList[index].confirmed = true;
    }

    updateStampList([...stampList]);
  };

  const confirmAllRows = () => {
    const arr = stampList.map((stamp) => ({
      ...stamp,
      confirmed: true,
    }));
    updateStampList(arr);
  };

  const updateLocalStorage = () => {
    if (props.withoutLsSave) return;

    const unSavedStampList = stampList.filter((stamp) => !stamp?.backendItem);
    const mappedUnSavedStampList = unSavedStampList.map((stamp) => ({
      ...stamp,
      stampNumbers: [],
      stampFullNumbers: [],
      stampItems: [],
    }));

    setValue(
      {
        stamps: [...mappedUnSavedStampList],
      },
      lsKey
    );
  };

  const disableNonDraft =
    props.supplyBillDto &&
    billType !== SupplyConstant.DRAFT_TYPE_ISSUE &&
    billType !== SupplyConstant.DRAFT_TYPE_RELOCATION_POST &&
    billType !== SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST &&
    billType !== SupplyConstant.DRAFT_TYPE_RETURN &&
    billType !== SupplyConstant.DRAFT_FACTORY_TYPE_GET &&
    !editAccess;

  const showIconConfirmStamp =
    props.tabType === 'relocation_get' ||
    billType === SupplyConstant.DRAFT_TYPE_RETURN ||
    billType === SupplyConstant.RETURNED_TYPE;

  const clearStampList = () => {
    const allUnsavedData = { ...getValue() };
    delete allUnsavedData[lsKey].stamps;
    setValue(allUnsavedData, lsKey);

    setStampFieldError(new Map());
    resetStampList();
    setWholeCount('');
    setDeclaredCount('');
  };

  return (
    <Box style={{ width: '100%', padding: '20px', marginBottom: '20px' }}>
      <form>
        <Grid
          container
          item
          direction='row'
          justifyContent='flex-start'
          width={'100%'}
          alignItems='baseline'
          wrap='nowrap'
          marginBottom={'40px'}
        >
          <TextField
            label='Заявленное количество'
            disabled={disableNonDraft}
            value={declaredCount}
            variant='standard'
            onChange={(value: any) => setDeclaredCount(value.target.value)}
          />
          <TextField
            label='Уже добавлено'
            disabled={disableNonDraft}
            value={wholeCount}
            style={{ marginLeft: '50px' }}
            variant='standard'
            error={getNumberFromString(wholeCount) != getNumberFromString(declaredCount)}
            helperText={
              getNumberFromString(wholeCount) != getNumberFromString(declaredCount)
                ? 'Не соответствует заявленному'
                : ' '
            }
          />
          {(billType === SupplyConstant.RETURNED_TYPE ||
            billType === SupplyConstant.DRAFT_TYPE_RETURN) && (
            <Button
              variant='contained'
              disabled={disableNonDraft}
              onClick={confirmAllRows}
              style={{ marginLeft: '50px' }}
            >
              Подтвердить все
            </Button>
          )}
        </Grid>
        {stampList &&
          stampList.map((val) => {
            return (
              <Wrapper
                key={val.id}
                unSavedItem={!val.backendItem}
                allConfirmed={val.confirmed}
              >
                <Grid
                  container
                  item
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'
                  wrap='nowrap'
                  marginBottom={'10px'}
                >
                  <TextField
                    label='От'
                    disabled={disableNonDraft}
                    autoComplete='false'
                    value={convertValue(val.from)}
                    variant='standard'
                    error={
                      stampFieldError.get(val.id) ||
                      !checkLetter(convertValue(val.from)) ||
                      !checkLettersEquals(val) ||
                      !checkStampRegexp(val.from) ||
                      !checkValueFromTo(val)
                    }
                    helperText={
                      stampFieldError.get(val.id)
                        ? 'Номер пломбы уже добавлен'
                        : !checkLetter(convertValue(val.from))
                        ? 'В номере некорректная буква'
                        : !checkStampRegexp(val.from)
                        ? 'Некорректный формат'
                        : !checkLettersEquals(val)
                        ? 'Буквы не совпадают'
                        : !checkValueFromTo(val)
                        ? 'Значение "От" больше "До"'
                        : ' '
                    }
                    onChange={(value: any) => setFromValue(val, value.target.value)}
                  />
                  <TextField
                    label='До'
                    disabled={disableNonDraft || !val.from}
                    value={convertValue(val.to)}
                    variant='standard'
                    autoComplete='false'
                    style={{ marginLeft: '30px' }}
                    error={
                      stampFieldError.get(val.id) ||
                      !checkLetter(convertValue(val.to)) ||
                      !checkLettersEquals(val) ||
                      !checkStampRegexp(val.to) ||
                      !checkValueFromTo(val)
                    }
                    helperText={
                      stampFieldError.get(val.id)
                        ? 'Номер пломбы уже добавлен'
                        : !checkLetter(convertValue(val.to))
                        ? 'В номере некорректная буква'
                        : !checkStampRegexp(val.to)
                        ? 'Некорректный формат'
                        : !checkLettersEquals(val)
                        ? 'Буквы не совпадают'
                        : !checkValueFromTo(val)
                        ? 'Значение "От" больше "До"'
                        : ' '
                    }
                    onChange={(value: any) => setToValue(val, value.target.value)}
                  />
                  <TextField
                    label='Количество'
                    disabled={disableNonDraft}
                    value={val.count}
                    style={{ width: '150px', marginLeft: '20px' }}
                    variant='standard'
                    error={
                      val.count ? val.count > SupplyConstant.stampLimit || val.count < 0 : false
                    }
                    helperText={
                      (val.count ? val.count > SupplyConstant.stampLimit : false)
                        ? 'Превышен лимит ' + SupplyConstant.stampLimit
                        : (val.count ? val.count < 0 : false)
                        ? 'Отрицательное значение'
                        : ' '
                    }
                  />
                  <IconButton
                    color='error'
                    disabled={disableNonDraft}
                    onClick={() => removeRow(val.id)}
                    style={{ margin: '0 10px' }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>

                  {showIconConfirmStamp && (
                    <Button
                      variant='contained'
                      onClick={() => confirmRow(val.id)}
                    >
                      Подтвердить
                    </Button>
                  )}
                </Grid>
              </Wrapper>
            );
          })}
        <SupplyButtonsWrapper>
          <IconButtonStyled
            disabled={disableNonDraft}
            onClick={() =>
              updateStampList(stampList.concat({ id: generateId(), from: '', to: '', count: 0 }))
            }
          >
            <AddIcon />
          </IconButtonStyled>
          <SupplyClear
            buttonText='Очистить все пломбы из накладной'
            modalText='Вы подтвержаете очистку всех пломб в данной накладной?'
            onSuccess={clearStampList}
            disabled={disableNonDraft || !stampList.length}
          />
        </SupplyButtonsWrapper>
      </form>
    </Box>
  );
};

export default memo(StampComponent);
