import { GridColDef } from '@mui/x-data-grid';
import { StatisticsContractorDto } from '../types';
import CloseIcon from '@mui/icons-material/Close';
import { SmallExtraTableSubHeader, SmallExtraTableWrapper } from './SmallExtraTable.styled';
import { StatisticsResponsebleDataGrid } from '../StatisticsResponseble.styled';

interface SmallExtraTableProps {
  setShowExtraTable: React.Dispatch<React.SetStateAction<boolean>>;
  subTileText: string | null | undefined;
  contractorTableData: StatisticsContractorDto[];
  extraColumns: GridColDef[];
  loading?: boolean;
}
export default function SmallExtraTable({
  setShowExtraTable,
  subTileText,
  contractorTableData,
  extraColumns,
  loading,
}: SmallExtraTableProps) {
  return (
    <div style={{ marginTop: '12px' }}>
      <SmallExtraTableSubHeader>{subTileText}</SmallExtraTableSubHeader>
      <SmallExtraTableWrapper>
        <CloseIcon
          fontSize='large'
          style={{ cursor: 'pointer', marginTop: '-20px' }}
          onClick={() => setShowExtraTable(false)}
        />
        <StatisticsResponsebleDataGrid
          className={'rootTableContainer'}
          rows={contractorTableData}
          getRowId={(row) =>
            row?.apartment_1f + row?.callDates + row?.apartment_3f + row?.contractorName
          }
          columns={extraColumns}
          showColumnRightBorder
          showCellRightBorder
          isRowSelectable={() => false}
          disableColumnFilter={true}
          localeText={{ noRowsLabel: 'Нет данных для отображения' }}
          hideFooter
          loading={loading}
        />
      </SmallExtraTableWrapper>
    </div>
  );
}
