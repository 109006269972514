import axios, { AxiosResponse } from 'axios';
import { HOST, yodaGetAccessToken } from '@/services/YodaRestService';
import { axiosGet } from '@/services/helpers';
import {
  MailNotificationsFileResponse,
  MailNotificationsFolderResponse,
} from '@/dto/MailNotificationsDto';

export async function GetMailNotificationsFolders(): Promise<
  AxiosResponse<MailNotificationsFolderResponse[], any>
> {
  const url = HOST + `/rest/file/postal-departure`;
  return axiosGet(url);
}

export async function GetMailNotificationsFiles(
  fileId: string
): Promise<AxiosResponse<MailNotificationsFileResponse[], any>> {
  const url = HOST + `/rest/file/postal-departure/${fileId}/file`;
  return axiosGet(url);
}

export async function UploadZipFile(file: File): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/file/postal-departure`;
  const token = await yodaGetAccessToken();
  const formData = new FormData();
  formData.append('zip', file);
  return axios.post(url, formData, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
  });
}
export async function UploadZipFileById(id: string, file: File): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/file/postal-departure/${id}`;
  const token = await yodaGetAccessToken();
  const formData = new FormData();
  formData.append('zip', file);
  return axios.post(url, formData, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
  });
}
