import { GeoTask } from '@/dto/taskmap/Dto';

export const PpoPlacemarkBalloon = (geoTask: GeoTask) => {
  return `
    <div class="map__placemark-balloon">
      <div class="placemark-balloon__buttons">
      <div>№ Заявки: ${geoTask.number}</div>
        <a href="#" class="placemark-balloon__profile-btn"
                id="placemark-balloon_task_id_${geoTask.taskId}">
          Показать информацию о ТП
        </a>
      </div>
    </div>
  `;
};
