import { color, mediaBreakpointDown } from '@/styles/mixins';
import { styled } from '@mui/material';

export interface ComplaintItemWrapperProps {
  stageColor: string;
  selected?: boolean;
  stage?: string;
  isMobile: boolean;
}

export const ComplaintItemWrapper = styled('div')<ComplaintItemWrapperProps>(
  ({ stageColor, selected, stage, isMobile }) => ({
    cursor: 'pointer',
    position: 'relative',
    border: `1px solid ${color('gray')}`,
    borderRadius: 16,
    padding: '8px 16px',
    margin: isMobile ? '20px 8px' : 8,
    borderLeft: isMobile ? `1px solid ${color('gray')}` : `15px solid ${stageColor}`,
    transition: 'box-shadow .3s, transform .2s',
    willChange: 'box-shadow, transform',
    transform: selected ? 'translateX(10px)' : 'none',
    '&:hover': {
      boxShadow: `0 0 11px ${color('gray')}`,
    },
    [mediaBreakpointDown('md')]: {
      transform: 'none',
    },

    '&::before': {
      content: isMobile ? `"этп.: ${stage}"` : '""',
      position: 'absolute',
      top: isMobile ? -22 : 'auto',
      right: isMobile ? 12 : 'auto',
      backgroundColor: isMobile ? stageColor : 'transparent',
      color: isMobile ? 'white' : 'transparent',
      fontSize: 12,
      padding: isMobile ? '2px 8px' : '0',
      borderRadius: 8,
      borderBottomLeftRadius: isMobile ? 0 : 'unset',
      borderBottomRightRadius: isMobile ? 0 : 'unset',
      zIndex: 1,
    },
  })
);
