import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { FC } from 'react';
import { color } from '@/styles/mixins';
import { WarantyStatus } from '@/dto/taskmap/Dto';

interface IWarrantyStateIcon {
  status?: WarantyStatus;
}

export const WarrantyStateIcon: FC<IWarrantyStateIcon> = ({ status }) => {
  switch (status) {
    case WarantyStatus.CANCELED:
      return (
        <HighlightOffRoundedIcon
          sx={{ mr: 1 }}
          htmlColor={color('statusCancel')}
        />
      );
    case WarantyStatus.NOT_VALID:
      return (
        <RemoveCircleOutlineRoundedIcon
          sx={{ mr: 1 }}
          htmlColor={color('statusError')}
        />
      );
    case WarantyStatus.VALID:
      return (
        <CheckCircleOutlineRoundedIcon
          sx={{ mr: 1 }}
          htmlColor={color('statusSuccess')}
        />
      );
  }
  return null;
};
