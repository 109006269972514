import * as React from 'react';
import { getComparator, stableSort } from '../constants';
import { IRegistryEditItem, useRegistryEditStore } from './store';
import { TableHeader } from './TableHeader';
import { TableToolbar } from './TableToolbar';
import { FC } from 'react';
import { useCatalog } from '@/hooks/CatalogHook';
import { addRegistryItems } from '@/services/RegistryService';
import {
  Button,
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  LinearProgress,
} from '@mui/material';
import moment from 'moment';
import { useRegistryResultStore } from '../ResultTable/store';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { OnRequestSort } from '../TableCells';

interface IEditTable {
  id?: string;
  loading?: boolean;
}

export const EditTable: FC<IEditTable> = ({ id, loading }) => {
  const { addActionLog, catchError } = useActionLog();
  const {
    registryEditTable_,
    selected,
    orderBy,
    order,
    setSelected,
    setOrderBy,
    setOrder,
    oldIndex,
    setOldIndex,
  } = useRegistryEditStore((state) => ({
    registryEditTable_: state.registryResultTable,
    order: state.order,
    orderBy: state.orderBy,
    selected: state.selected,
    setOrder: state.setOrder,
    setOrderBy: state.setOrderBy,
    setSelected: state.setSelected,
    oldIndex: state.oldIndex,
    setOldIndex: state.setOldIndex,
  }));
  const { taskGroupMap, getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    taskGroupMap: state.taskGroupMap,
  }));
  const { updateResTable, registryResultTable } = useRegistryResultStore((state) => ({
    updateResTable: state.updateTable,
    registryResultTable: state.registryResultTable,
  }));
  const registryEditTable = registryEditTable_.filter(
    (el) => registryResultTable.findIndex((e) => e.taskId === el.id) < 0
  );
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IRegistryEditItem
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const allRows = stableSort<IRegistryEditItem>(registryEditTable, getComparator(order, orderBy));

  const tmp = registryEditTable
    .filter((elem) => selected.indexOf(elem.id) >= 0)
    .filter((elem) => elem.data && elem.executor);

  const handleClick = (event: React.MouseEvent<unknown>, name: string, index: number) => {
    event.preventDefault();
    let newSelected: string[] = [];
    const selectedIndex = selected.indexOf(name);

    if (event.shiftKey) {
      window?.getSelection()?.removeAllRanges();
      const tmp = allRows.filter(
        (el, ind) => (ind > index && ind <= oldIndex) || (ind >= oldIndex && ind < index)
      );
      if (selectedIndex === -1) {
        newSelected = [
          ...selected,
          name,
          ...tmp.filter((elem) => selected.indexOf(elem.id)).map((el) => el.id),
        ];
      } else {
        newSelected = [
          ...selected.filter((el) => !(tmp.find((elem) => elem.id === el) || el === name)),
        ];
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [...selected, name];
      } else {
        newSelected = selected.filter((el, ind) => ind !== selectedIndex);
      }
    }

    setOldIndex(index);
    setSelected(newSelected);
  };

  const handleSaveClick = () => {
    if (selected.length === 0 || tmp.length !== selected.length) {
      catchError(
        'Строки не добавлены в реестр',
        'Необходимо заполнить дату и исполнителя во всех выбранных строках'
      );
    } else {
      const tmpIds = registryEditTable.filter((elem) => selected.find((el) => el == elem.id));
      if (id) {
        addRegistryItems(
          id,
          tmpIds.map((elem) => ({
            taskId: elem.id,
            executorId: elem?.executor ? elem.executor : '',
            scheduleOn: elem?.data
              ? moment(Number(elem.data) - new Date().getTimezoneOffset() * 60 * 1000).valueOf()
              : 0,
          }))
        )
          .then(() => {
            updateResTable(id);
            setSelected([]);
            addActionLog(ActionLogType.SUCCESS, 'Строки успешно добавлены в реестр');
          })
          .catch((res) =>
            catchError(
              selected.length > 1 ? 'Строки не добавлены в реестр' : 'Строка не добавлена в реестр',
              res
            )
          );
      }
    }
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <>
      <Paper>
        <TableToolbar numSelected={selected.length} />
        <TableContainer sx={{ maxHeight: '600px', overflow: 'scroll' }}>
          <Table
            aria-labelledby='tableTitle'
            size={'small'}
          >
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort as OnRequestSort}
            />
            <TableBody>
              {!registryEditTable.length && !loading && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align={'center'}
                  >
                    Пусто
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    style={{ padding: 0 }}
                    colSpan={7}
                    align={'center'}
                  >
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}

              {allRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id, index)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{getCatalogMapWithErr('taskType').get(row.type)}</TableCell>
                    <TableCell>{row.adress}</TableCell>
                    <TableCell>{row?.data && moment(row.data).format('DD.MM.yyyy')}</TableCell>
                    <TableCell>{row?.executor ? taskGroupMap.get(row.executor) : ''}</TableCell>
                    <TableCell>
                      {row?.managementCompany &&
                        getCatalogMapWithErr('odpuManagementCompany').get(
                          `${row.managementCompany}`
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Button onClick={handleSaveClick}>Добавить в реестр</Button>
    </>
  );
};
