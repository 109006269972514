import React, { FC, MouseEventHandler, useMemo } from 'react';
import { Border, Circle, Count } from '@/components/Point/point.styled';
import { color } from '@/styles/mixins';
import { Subscriber } from '@/components/maps/ppoMap/dto';
import { Colors } from '@/styles/colors';

interface IPoint {
  count: number | string;
  type: Subscriber;
  onClick?: MouseEventHandler | undefined;
}

const Point: FC<IPoint> = ({ count, type, onClick }) => {
  const pointColor = useMemo(
    (): Colors =>
      type === Subscriber.NO
        ? 'pointYellow'
        : type === Subscriber.FROM_DB
        ? 'pointGreen'
        : type === Subscriber.CREATED_BY_USER
        ? 'pointPurple'
        : 'trueGray',
    [type]
  );
  return (
    <Border onClick={onClick}>
      <Circle sx={{ backgroundColor: color(pointColor) }} />
      <Count>{count}</Count>
    </Border>
  );
};

export default Point;
