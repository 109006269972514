import React, { useMemo, useState } from 'react';
import { TextField, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { PlannedInstalation, usePnrPlanningStore } from '@/components/sections/PnrPlanning/store';
import {
  PnrPlanningModalFirstStepRowBackground,
  PnrPlanningModalFirstStepRowWrapper,
  PnrPlanningModalIconButton,
  PnrPlanningModalTextField,
  PnrPlanningModalCommentTextField,
  PnrPlanningModalConflictIcon,
  PnrPlanningModalInfoField,
  PnrPlanningModalAutocomplete,
  PnrPlanningModalFirstStepDeleteIconButton,
  PnrPlanningModalFirstStepCommentWrapper,
  PnrPlanningModalInfoIcon,
  PnrPlanningsRowLoading,
} from './PnrPlanningModalSteps.styled';
import { REQUIRED } from './util';
import { replaceNonNums } from '@/components/storehouse/SupplyPUComponent/utils';
import shallow from 'zustand/shallow';
import { HouseLevelFloorType, NetworkPlanningTypes } from '../PnrElementsInfo/utils';
import { useHouseLevelFloorType } from '@/components/features/pnr/HouseEntranceEdit/utils';

export interface PnrPlanningModalFirstStepRowProps {
  index: number;
  style: React.CSSProperties;
  data: {
    currentTypePlannedInstalations: PlannedInstalation[];
  };
  isScrolling?: boolean;
}

const PnrPlanningModalFirstStepRow = ({
  data,
  index,
  style,
  isScrolling,
}: PnrPlanningModalFirstStepRowProps) => {
  const {
    networkEquipmentOptions,
    networkElementPlanningOptions,
    flipDeleteStatus,
    setRowEntrance,
    setRowFloor,
    resetRowFloor,
    setRowMontagePlace,
    setRowEquipmentType,
    setRowPlanningType,
    openComentModal,
    openModalType,
  } = usePnrPlanningStore(
    (state) => ({
      networkEquipmentOptions: state.networkEquipmentOptions,
      flipDeleteStatus: state.flipDeleteStatus,
      setRowEntrance: state.setRowEntrance,
      setRowFloor: state.setRowFloor,
      resetRowFloor: state.resetRowFloor,
      setRowMontagePlace: state.setRowMontagePlace,
      setRowEquipmentType: state.setRowEquipmentType,
      openComentModal: state.openComentModal,
      networkElementPlanningOptions: state.networkElementPlanningOptions,
      setRowPlanningType: state.setRowPlanningType,
      openModalType: state.openModalType,
    }),
    shallow
  );
  const { floorTypesOptions } = useHouseLevelFloorType();

  const row = data?.currentTypePlannedInstalations[index];

  const [isTouched, setIsTouched] = useState({ entrance: false, floor: false });

  const [backendState, setBackendState] = useState<PlannedInstalation>(() => {
    return { ...row };
  });

  const isErrorEntrance =
    !row.deleted && !row?.houseLevel?.entranceNumber?.toString()?.length && isTouched.entrance;
  const isErrorFloor =
    !row.deleted &&
    row.montagePlace?.value === HouseLevelFloorType.FLOOR &&
    !row.houseLevel?.floorNumber?.toString().length &&
    isTouched.floor;

  const isEdited = JSON.stringify(row) !== JSON.stringify(backendState);

  return (
    <div style={style}>
      {isScrolling ? (
        <PnrPlanningModalFirstStepRowWrapper>
          <PnrPlanningsRowLoading loading />
        </PnrPlanningModalFirstStepRowWrapper>
      ) : (
        <PnrPlanningModalFirstStepRowWrapper>
          <div style={{ width: '64px', display: 'flex', gap: 16, flexShrink: 0 }}>
            {!row.deleted && (
              <>
                {row.hasConflict && (
                  <Tooltip title={row.conflictComment || ''}>
                    <PnrPlanningModalConflictIcon
                      color='error'
                      fontSize='medium'
                    />
                  </Tooltip>
                )}
                {row.type === NetworkPlanningTypes.PREPLAN && (
                  <PnrPlanningModalInfoIcon fontSize='medium' />
                )}
              </>
            )}
          </div>

          <PnrPlanningModalFirstStepRowBackground
            isEdited={!row.deleted && (row.userEdited || isEdited)}
          >
            <PnrPlanningModalTextField
              label='Подъезд'
              error={isErrorEntrance}
              helperText={isErrorEntrance && REQUIRED}
              onChange={(e) => {
                const numsValue = replaceNonNums(e.target.value);
                setRowEntrance(row.tmpId, numsValue || '');
              }}
              onBlur={() => setIsTouched((prev) => ({ ...prev, entrance: true }))}
              value={row.houseLevel.entranceNumber}
              disabled={row.deleted}
              variant='standard'
              InputLabelProps={{ shrink: true }}
            />

            {row?.deleted ? (
              <PnrPlanningModalInfoField label='Тип оборудования' />
            ) : (
              <>
                <PnrPlanningModalAutocomplete
                  disableClearable
                  isOptionEqualToValue={(option, value) =>
                    (option as SelectFilterOptType).value === (value as SelectFilterOptType).value
                  }
                  options={networkEquipmentOptions}
                  value={row.equipmentTypeFilter}
                  onChange={(_event: any, newValue: unknown) => {
                    const value = newValue as SelectFilterOptType;
                    setRowEquipmentType(row.tmpId, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Тип оборудования'
                      variant='standard'
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </>
            )}

            {row.deleted ? (
              <PnrPlanningModalInfoField label='Расположение/Этаж' />
            ) : (
              <>
                {row?.houseLevel?.floorType === HouseLevelFloorType.FLOOR ? (
                  <PnrPlanningModalTextField
                    variant='standard'
                    label='Этаж'
                    value={row.houseLevel.floorNumber}
                    error={isErrorFloor}
                    helperText={isErrorFloor && REQUIRED}
                    onChange={(e) => {
                      const numsValue = replaceNonNums(e.target.value);
                      setRowFloor(row.tmpId, numsValue || '');
                    }}
                    onBlur={() => setIsTouched((prev) => ({ ...prev, floor: true }))}
                    InputProps={{
                      endAdornment: (
                        <PnrPlanningModalFirstStepDeleteIconButton
                          onClick={() => resetRowFloor(row.tmpId)}
                        >
                          <CloseIcon fontSize={'small'} />
                        </PnrPlanningModalFirstStepDeleteIconButton>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                ) : (
                  <PnrPlanningModalAutocomplete
                    disableClearable
                    isOptionEqualToValue={(option, value) =>
                      (option as SelectFilterOptType).value === (value as SelectFilterOptType).value
                    }
                    options={floorTypesOptions}
                    value={row.montagePlace}
                    onChange={(_event, newValue) => {
                      const value = newValue as SelectFilterOptType;
                      setRowMontagePlace(row.tmpId, value);
                    }}
                    getOptionDisabled={(option) =>
                      (option as SelectFilterOptType).value === HouseLevelFloorType.UNDEFINED
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='standard'
                        InputLabelProps={{ shrink: true }}
                        label='Расположение/Этаж'
                      />
                    )}
                  />
                )}
              </>
            )}

            {row?.deleted ? (
              <PnrPlanningModalInfoField label='Признак' />
            ) : (
              <>
                <PnrPlanningModalAutocomplete
                  disableClearable
                  isOptionEqualToValue={(option, value) =>
                    (option as SelectFilterOptType).value === (value as SelectFilterOptType).value
                  }
                  options={networkElementPlanningOptions}
                  value={row.planningTypeFilter}
                  onChange={(_event: any, newValue: unknown) => {
                    const value = newValue as SelectFilterOptType;
                    setRowPlanningType(row.tmpId, value);
                  }}
                  getOptionDisabled={(option) =>
                    (option as SelectFilterOptType).value !== NetworkPlanningTypes.SECONDLY
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Признак'
                      variant='standard'
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  disabled={openModalType !== NetworkPlanningTypes.PREPLAN}
                />
              </>
            )}

            <PnrPlanningModalFirstStepCommentWrapper
              onClick={() => openComentModal(row.tmpId)}
              disabled={row.deleted}
            >
              <PnrPlanningModalCommentTextField
                label='Комментарий'
                value={row?.deleted ? '' : row?.comment}
                disabled={row.deleted}
                variant='standard'
                InputLabelProps={{ shrink: true }}
              />
            </PnrPlanningModalFirstStepCommentWrapper>
          </PnrPlanningModalFirstStepRowBackground>

          <PnrPlanningModalIconButton
            onClick={() => flipDeleteStatus(row.tmpId)}
            deleted={row.deleted}
          >
            {row.deleted ? <RefreshIcon /> : <DeleteForeverOutlinedIcon />}
          </PnrPlanningModalIconButton>
        </PnrPlanningModalFirstStepRowWrapper>
      )}
    </div>
  );
};

export default PnrPlanningModalFirstStepRow;
