import { Button, Grid, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { useGalleryData } from '@/components/Gallery/store';
import { ItemTransform } from './ItemTransform';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { TextField } from '@/components/fixUI';
import { useFormContext } from 'react-hook-form';
import { ItemInput } from './ItemInput';
import { TransStationResponse } from '@/dto/taskmap/task/TaskResultDto';
import { SaveButton } from '@/components/ui/SaveButton';
import { getTransTPImageGroup } from '../panel/utils';
import { useCatalog } from '@/hooks/CatalogHook';
import { useStatus } from '../panel/store';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { createTask } from '@/services/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { PillarDeliveryPointToSmrButton } from '../item/PillarDeliveryPointToSmrButton';
import { SendPpoToIsurButton } from '../item/SendPpoToIsurButton';
import { FormUspd } from './FormUspd';

interface ItemTPProps {
  transStation: TransStationResponse;
  keyStr: string;
  onSave: (data: any) => void;
  contractId?: string;
  contractorId: string;
}
const ID = 'd8ab083f-b087-179a-5300-78e704a01453';

export const ItemTP: FC<ItemTPProps> = ({
  transStation,
  keyStr,
  onSave,
  contractId,
  contractorId,
}) => {
  const { register, handleSubmit, setValue, watch } = useFormContext();

  useEffect(() => {
    setValue(`${keyStr}.id`, transStation.id);
  }, [transStation]);

  const disabled = !(watch(`${keyStr}.tpTypeEnum.id`) && watch(`${keyStr}.dispatcherName`));

  const [newInputs, setNewInputs] = useState<any[]>([]);
  const [newFeeders, setNewFeeders] = useState<any[]>([]);
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);
  const { fetchCatch, addActionLog } = useActionLog();

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const handleClick = (e: any) => {
    e.stopPropagation();
    setImageGroups(getTransTPImageGroup(transStation));
  };
  const { isDisabledAll } = useStatus((state) => ({
    isDisabledAll: state.isDisabledAll,
  }));

  const [isOpenReturn, setIsOpenReturn] = useState<boolean>(false);
  const returnPPOtoSMR = () => {
    createTask(transStation.id, {
      contractId: transStation.contractId,
      contractorId,
      regionalElectricNetworkId: transStation.resId,
      transStationName: transStation.dispatcherName,
      latitude: transStation.latitudeY,
      longitude: transStation.longitudeX,
      type: '3dc4f179-870c-24b6-9af4-50837bb31459',
    })
      .then(() => {
        addActionLog(ActionLogType.SUCCESS, 'Заявка успешно создана');
      })
      .catch((res) => {
        fetchCatch(res);
      });
    setIsOpenReturn(false);
  };

  return (
    <BoxShadow onDoubleClick={handleClick}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <PillarDeliveryPointToSmrButton transStationId={transStation.id} />
        {contractId === ID && <SendPpoToIsurButton id={transStation.id} />}
      </div>
      <Grid
        container
        sx={{ mt: 1 }}
        spacing={2}
        item
        direction={'column'}
      >
        <Grid item>
          <Typography variant='h5'>Информация о ТП</Typography>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <AutocompleteValue
              title='Тип ТП'
              sx={{ flexGrow: '1', mr: 2 }}
              fieldName={`${keyStr}.tpTypeEnum.id`}
              defaultValue={transStation?.tpTypeEnumId}
              values={getCatalogMapWithErr('tpType')}
              disabled={isDisabledAll()}
            />
            <TextField
              id='trns-number'
              label='№ ТП'
              variant='standard'
              sx={{ flexGrow: '1', mr: 2 }}
              {...register(`${keyStr}.dispatcherName`, {
                value: transStation.dispatcherName,
                required: true,
              })}
              disabled={isDisabledAll()}
            />
            <TextField
              id='trns-y'
              label='Широта'
              variant='standard'
              sx={{ flexGrow: '1', mr: 2 }}
              {...register(`${keyStr}.latitudeY`, {
                value: transStation.latitudeY,
              })}
              disabled={isDisabledAll()}
            />
            <TextField
              id='trns-x'
              label='Долгота'
              variant='standard'
              sx={{ flexGrow: '1', mr: 2 }}
              {...register(`${keyStr}.longitudeX`, {
                value: transStation.longitudeX,
              })}
              disabled={isDisabledAll()}
            />
            <div style={{ flexGrow: '1' }}>
              <Button
                onClick={() =>
                  setNewInputs([
                    ...newInputs,
                    { id: `newI-${newInputs.length + 1}`, tpFeeder: { id: `newIF-1` } },
                  ])
                }
              >
                добавить силовой трансформатор
              </Button>
              <Button
                disabled={isDisabledAll()}
                onClick={() =>
                  setNewFeeders([...newFeeders, { id: `newF-${newFeeders.length + 1}` }])
                }
              >
                добавить фидер 0,4 КВ
              </Button>
            </div>
          </div>
        </Grid>
        {transStation?.inputs &&
          [...transStation.inputs, ...newInputs].map((t, index) => (
            <Grid
              key={t.id}
              item
              container
            >
              <ItemTransform
                transInput={t}
                keyStr={`${keyStr}.inputs.${t.id}`}
              />
            </Grid>
          ))}
        {transStation?.tpFeeders &&
          [...transStation.tpFeeders, ...newFeeders].map((t, index) => (
            <Grid
              item
              key={t.id}
            >
              <ItemInput
                transFeeder={t}
                keyStr={`${keyStr}.tpFeeders.${t.id}`}
              />
            </Grid>
          ))}

        <FormUspd
          keyStr={`${keyStr}.uspdInfo`}
          uspdInfo={transStation?.uspdInfo}
        />
        <Grid
          item
          xs={1}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Button onClick={() => setIsOpenReturn(true)}>
            Создать заявку на монтаж сетевого оборудования
          </Button>
          <SaveButton
            onSave={handleSubmit(onSave)}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <AccessPopup
        isOpen={isOpenReturn}
        onClose={() => setIsOpenReturn(false)}
        onSuccess={returnPPOtoSMR}
        headerText={'Создать заявку на монтаж сетевого оборудования?'}
      />
    </BoxShadow>
  );
};
