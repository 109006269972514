import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { createStore } from '../../../utils/createStore';

export interface IRegistryBrowse {
  id: string;
  year: number;
  month: number;
  completed: boolean | null;
  fileCount: number;
  fileAttachedCount: number;
  sendable: boolean;
}

interface IRegistryStore {
  registryBrowse: IRegistryBrowse[];
  registryItem: IRegistryBrowse;
}

interface IRegistryStoreFunc {
  setRegistryBrowse: (registryBrowse: IRegistryBrowse[]) => void;
  setRegistryItem: (registryItem: IRegistryBrowse) => void;
}

export const useRegistryBrowseStore = createStore<IRegistryStore & IRegistryStoreFunc>(
  (set) => ({
    registryBrowse: [],
    registryItem: {} as IRegistryBrowse,

    setRegistryBrowse: (registryBrowse) => set(() => ({ registryBrowse })),
    setRegistryItem: (registryItem) => set(() => ({ registryItem })),
  }),
  'Registry browse'
);
