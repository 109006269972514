import React, { useEffect, useState } from 'react';
import { RegistryBrowseItem } from './RegistryBrowseItem';
import { Grid } from '@mui/material';
import { RegistryBrowseAdd } from './RegistryBrowseAdd';
import { CreateRegistryPopup } from '@/components/popups/CreateRegistryPopup';
import { useRegistryBrowseStore } from './store';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { TextField } from '@/components/fixUI';

import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '@/components/ui/Loading';
import { findAllRegistryByYear } from '@/services/RegistryService';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';

export const RegistryBrowse = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { addActionLog } = useActionLog();
  const methods = useForm<{ year: number }>();
  const { register } = methods;
  const { registryBrowse, setRegistryBrowse } = useRegistryBrowseStore((state) => ({
    registryBrowse: state.registryBrowse,
    setRegistryBrowse: state.setRegistryBrowse,
  }));

  const getAllRegistryByYear = ({ year }: { year: number | null }) => {
    const now = new Date();
    const tmpYear = year ? year : now.getFullYear();
    setLoading(true);
    findAllRegistryByYear(tmpYear)
      .then(({ data }) => {
        setRegistryBrowse(data);
      })
      .catch(() => {
        addActionLog(ActionLogType.ERROR, 'Ошибка поиска реестра');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllRegistryByYear({ year: null });
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(getAllRegistryByYear)}>
          <Grid
            container
            spacing={2}
            p={4}
          >
            <Grid
              xs={12}
              item
              container
              spacing={2}
            >
              <FormProvider {...methods}>
                <Grid
                  item
                  xs={3}
                >
                  <TextField
                    fullWidth
                    label='Год'
                    variant='standard'
                    {...register(`year`)}
                  />
                </Grid>
                <Grid item>
                  <FilterButtons onSearchClick={() => methods.handleSubmit(getAllRegistryByYear)} />
                </Grid>
              </FormProvider>
            </Grid>
            <Loading loading={loading}>
              {registryBrowse.map((elem) => (
                <RegistryBrowseItem
                  key={elem.id}
                  {...elem}
                />
              ))}
            </Loading>
            <RegistryBrowseAdd onClick={() => setIsOpen(true)} />
          </Grid>
          <CreateRegistryPopup
            onSuccess={() => setIsOpen(false)}
            onClose={() => setIsOpen(false)}
            isOpen={isOpen}
          />
        </form>
      </FormProvider>
    </>
  );
};
