import { useQuery } from 'react-query';
import { KEYS } from './Keys';
import { FetchCathError } from '@/types/api';
import { useActionLog } from '@/hooks/ActionLogHook';
import { findKidsAndParents } from '@/services/TaskService';

export function useKidsAndParents(taskId?: string, enabled = false) {
  const { catchError } = useActionLog();

  const {
    data: axiosResponse,
    isFetching,
    error,
  } = useQuery({
    queryKey: [...KEYS.taskSiblings, taskId],
    enabled: enabled && !!taskId,
    queryFn: () => findKidsAndParents(taskId),
    staleTime: Infinity,
    onError: (error: FetchCathError) => {
      const errorMessage = error?.response?.data?.message || 'Непредвиденная ошибка';
      catchError(errorMessage, {});
    },
  });

  const siblings = axiosResponse?.data;

  return {
    data: siblings,
    isFetching,
    error,
  };
}
