import { Box, Grid, Popper } from '@mui/material';
import React, { useEffect } from 'react';
import { Filter } from '@/components/features/pnr/TaskMap/TaskMapFilter/Filter';
import { useGeoQuery } from '../hooks/GeoQueryHook';
import { useMap } from '@/components/features/pnr/hooks/MapHook';
import { useResArea } from '@/components/maps/pnrMap/hooks/ResAreaHook';
import { useMapPopper } from '@/components/features/pnr/hooks/ScreenPopperHook';
import TaskMapInner from './TaskMapInner';
import MapManager from '@/components/maps/pnrMap/manager/MapManager';

export const TaskMap = () => {
  const { currentGeoResponse, currentTaskFilterParam } = useGeoQuery();
  const { isOpenMapPopper, anchorEl, mapPopperTitle } = useMapPopper();
  const { initAllReaArea, updateResAreaList } = useResArea();
  const { currentGeoBounds } = useMap();

  useEffect(() => {
    if (currentGeoResponse) {
      initAllReaArea(currentGeoResponse.geoTaskInBoundsList);
    }
  }, [currentTaskFilterParam]);

  useEffect(() => {
    if (currentGeoResponse) {
      updateResAreaList(currentGeoResponse.geoTaskInBoundsList);
    }
  }, [currentGeoBounds]);

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{ p: 2 }}
      >
        <Grid
          item
          xs={12}
        >
          <Filter />
        </Grid>
        <Grid
          container
          item
          spacing={1}
          xs={12}
        >
          <Grid
            item
            xs={9}
          >
            <TaskMapInner />
          </Grid>
          <Grid
            item
            xs={3}
            justifyContent='space-between'
            alignItems='center'
            width='100%'
          >
            <MapManager />
          </Grid>
        </Grid>
      </Grid>
      <Popper
        id='task-map-page-popper'
        open={isOpenMapPopper}
        anchorEl={anchorEl}
      >
        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>{mapPopperTitle}</Box>
      </Popper>
    </>
  );
};
