import React from 'react';
import {
  GetCallCenterStatisticsTableByFilter,
  GetSmsStatusStatistics,
  GetStatisticCallingMap,
  GetStatisticMoreThanOneApealByFilter,
  GetStatisticsResponsebles,
} from '@/services/StatisticsService';
import {
  SmsStatusStatisticsItem,
  StatisticsResponsebleFilter,
  CallCenterStatisticsFilter,
} from './types';
import { useActionLog } from '@/hooks/ActionLogHook';
import SmsStatusStatistics from './SmsStatusStatistics/SmsStatusStatistics';
import { CallCenterStatistics } from './CallCenterStatistics/CallCenterStatistics';

import { StatisticsResponseble } from './StatisticsResponseble/StatisticsResponseble';
import {
  Filter,
  StatisticMoreThanOneApeal,
} from './StatisticMoreThanOneApeal/StatisticMoreThanOneApeal';

import { sortByAlthabit } from '@/utils/heplers';
import { FilterSmsStatisitc } from '@/dto/taskmap/Dto';
import MiniatureSelect from '@/components/blocks/MiniatureSelect/MiniatureSelect';
import { CallingMapStatistics } from '../CallingMapStatistics/CallingMapStatistics';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { CallCenterStatisticsDto } from './CallCenterStatistics/types';
import { StatisticMoreThanOneApealDto } from './StatisticMoreThanOneApeal/types';

const CustomerInteractionStatistics = () => {
  const { catchError } = useActionLog();
  const [loadSmsStatusStatistics, selLoadSmsStatusStatistics] = React.useState(false);
  const [statisticCallingMapData, setStatisticCallingMapData] = React.useState<any>([]);
  const [loadCallCenterStatistics, setLoadCallCenterStatistics] = React.useState(false);
  const [loadStatisticsResponseble, setLoadStatisticsResponseble] = React.useState(false);
  const [callCenterStatisticsData, setCallCenterStatisticsData] = React.useState<
    CallCenterStatisticsDto[]
  >([]);
  const [loadstatisticMoreThanOneApealData, setLoadstatisticMoreThanOneApealData] =
    React.useState(false);

  const [statisticMoreThanOneApealData, setstatisticMoreThanOneApealData] = React.useState<
    StatisticMoreThanOneApealDto[]
  >([]);

  const [loadStatisticCallingMapData, setloadStatisticCallingMapData] = React.useState(false);

  const updateCallCenterStatisticsData = (filter: CallCenterStatisticsFilter) => {
    setLoadCallCenterStatistics(true);
    GetCallCenterStatisticsTableByFilter(filter)
      .then((data) => setCallCenterStatisticsData(data.data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', {}))
      .finally(() => setLoadCallCenterStatistics(false));
  };

  const updateStatisticMoreThanOneApeal = (filter: Filter) => {
    setLoadstatisticMoreThanOneApealData(true);
    GetStatisticMoreThanOneApealByFilter(filter)
      .then(({ data }) => setstatisticMoreThanOneApealData(data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', {}))
      .finally(() => setLoadstatisticMoreThanOneApealData(false));
  };

  const updateCallingMap = (filrer: any) => {
    setloadStatisticCallingMapData(true);
    GetStatisticCallingMap(filrer)
      .then(({ data }) => setStatisticCallingMapData(data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', error))
      .finally(() => setloadStatisticCallingMapData(false));
  };

  const [smsStatusStatisticsData, setSmsStatusStatisticsData] = React.useState<
    SmsStatusStatisticsItem[]
  >([]);
  const [statisticsResponseblesData, setStatisticsResponseblesData] = React.useState<any>([]);

  const updateSmsStatusStatistics = (filter: FilterSmsStatisitc) => {
    selLoadSmsStatusStatistics(true);
    GetSmsStatusStatistics(filter)
      .then((value) => setSmsStatusStatisticsData(value.data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', {}))
      .finally(() => selLoadSmsStatusStatistics(false));
  };
  const updateStatisticsResponseble = (contractIdList: StatisticsResponsebleFilter) => {
    setLoadStatisticsResponseble(true);
    GetStatisticsResponsebles(contractIdList)
      .then(({ data }) => {
        const sortedData = sortByAlthabit(data, 'Итого');
        setStatisticsResponseblesData(sortedData);
      })
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', {}))
      .finally(() => setLoadStatisticsResponseble(false));
  };

  const statistics = [
    {
      hasAccess: useAccess({
        access: [AccessEnum.Statistic, AccessEnum.TeamManagerVoormanAndExecutor],
        stopRedirect: true,
      }),
      title: 'Статистика Колл-центра',
      component: (
        <CallCenterStatistics
          header={'Статистика Колл-центра'}
          load={loadCallCenterStatistics}
          data={callCenterStatisticsData}
          updateFunction={updateCallCenterStatisticsData}
        />
      ),
    },
    {
      hasAccess: useAccess({
        access: [
          AccessEnum.Statistic,
          AccessEnum.TeamManagerVoormanAndExecutor,
          AccessEnum.SupplyInfoStatistic,
        ],
        stopRedirect: true,
      }),
      title: 'Статистика обращений без монтажа',
      component: (
        <StatisticsResponseble
          header={'Статистика обращений без монтажа'}
          load={loadStatisticsResponseble}
          data={statisticsResponseblesData}
          updateFunction={updateStatisticsResponseble}
        />
      ),
    },
    {
      hasAccess: useAccess({ access: [AccessEnum.Statistic], stopRedirect: true }),
      title: 'Заявки где более одного обращения',
      component: (
        <StatisticMoreThanOneApeal
          header={'Заявки где более одного обращения'}
          load={loadstatisticMoreThanOneApealData}
          data={statisticMoreThanOneApealData}
          updateFunction={updateStatisticMoreThanOneApeal}
        />
      ),
    },
    {
      hasAccess: useAccess({ access: [AccessEnum.Statistic], stopRedirect: true }),
      title: 'Статистика СМС рассылок',
      component: (
        <SmsStatusStatistics
          header={'Статистика СМС рассылок'}
          load={loadSmsStatusStatistics}
          data={smsStatusStatisticsData}
          updateFunction={updateSmsStatusStatistics}
        />
      ),
    },

    {
      hasAccess: useAccess({
        access: [AccessEnum.Statistic, AccessEnum.CallingMapOperator],
        stopRedirect: true,
      }),
      title: 'Статистика карты обзвона',
      component: (
        <CallingMapStatistics
          header={'Статистика карты обзвона'}
          load={loadStatisticCallingMapData}
          data={statisticCallingMapData}
          updateFunction={updateCallingMap}
        />
      ),
    },
  ];

  const hasAccessStatistics = statistics.filter((s) => s.hasAccess);

  return <MiniatureSelect components={hasAccessStatistics}></MiniatureSelect>;
};
export default CustomerInteractionStatistics;
