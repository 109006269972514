import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import { Tooltip } from '@mui/material';
import { useMapPopper } from '@/components/features/pnr/hooks/ScreenPopperHook';

interface CopiedButtonProps {
  title?: string;
  getValue(): Promise<any>;
}

export const CopiedButton = (props: CopiedButtonProps) => {
  const { title, getValue } = props;
  const { openMapPopper, closeMapPopper } = useMapPopper();

  const onClick = (event: any) => {
    openMapPopper('Скопировано', event.currentTarget);
    getValue()
      .then((data: string) => {
        navigator.clipboard.writeText(data);
      })
      .catch((err: any) => console.log(err))
      .finally(() => closeMapPopper());
  };

  return (
    <>
      <Tooltip
        title={title || ''}
        placement='top'
      >
        <ContentCopySharpIcon
          sx={{ width: 16, cursor: 'pointer', ml: '4px', mr: '4px' }}
          onClick={onClick}
          data-tip
          data-for='registerTip'
        />
      </Tooltip>
    </>
  );
};
