import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

interface SaveButtonSaveWrapperProps {
  empty?: boolean;
}
export const SaveButtonSaveWrapper = styled('div')<SaveButtonSaveWrapperProps>(({ empty }) => ({
  marginRight: empty ? 0 : 16,
  transition: '0.4s ease',
}));

export const SaveButtonDeleteWrapper = styled(IconButton)({
  marginLeft: 'auto',
});
