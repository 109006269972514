import { FilterArgEnum } from '../../../components/inputs/ArgumentSelect/types';
import { createStore } from '../../../utils/createStore';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';

interface SingleArg {
  value: string;
  argValue: FilterArgEnum;
}
interface DateArg {
  value: number[];
  argValue: FilterArgEnum;
}

interface MultiArg {
  value: SelectFilterOptType[];
  argValue: FilterArgEnum;
}
type ILabelCheckbox = {
  onlyBashkortostan: boolean;
};
export type IDateInput = {
  nextCallDateOn: DateArg;
};

export type ISingleInput = {
  address: SingleArg;
  accountingNum: SingleArg;
  taskId: SingleArg;
  taskNum: SingleArg;
  territorialBranch: SingleArg;
  managingOrganization: SingleArg;
};
export type IMultiInput = {
  contractorIds: MultiArg;
  executorIds: MultiArg;
  taskPriority: MultiArg;
  taskStatus: MultiArg;
  taskType: MultiArg;
  contractIds: MultiArg;
  consumer: MultiArg;
  operatorIdList: MultiArg;
  statusList: MultiArg;
};

type ObjectKey<Obj> = keyof Obj;
export type SingleInputKeys = ObjectKey<ISingleInput>;
export type MultiInputKeys = ObjectKey<IMultiInput>;
export type DateInputKeys = ObjectKey<IDateInput>;
export type LabelCheckboxKeys = ObjectKey<ILabelCheckbox>;

type ICallCenterBrowseFunks = {
  setSingleValue: (key: SingleInputKeys) => (value: string) => void;
  setSingleValueArg: (key: SingleInputKeys) => (argValue: FilterArgEnum) => void;
  setMultiValue: (key: MultiInputKeys) => (value: string[]) => void;
  setMultiValueArg: (key: MultiInputKeys) => (argValue: FilterArgEnum) => void;
  setDateValue: (key: DateInputKeys) => (value: number[]) => void;
  setDateValueArg: (key: DateInputKeys) => (argValue: FilterArgEnum) => void;
  setBooleanValue: (key: LabelCheckboxKeys) => (value: boolean) => void;
  setDefaultValue: () => void;
  setDefaultHelpValue: () => void;
};

export type ICallCenterBrowseStore = ICallCenterBrowseFunks &
  ISingleInput &
  IMultiInput &
  ILabelCheckbox &
  IDateInput;

const defaultValue: ISingleInput & IMultiInput & ILabelCheckbox & IDateInput = {
  address: { value: '', argValue: FilterArgEnum.CONTAIN },
  accountingNum: { value: '', argValue: FilterArgEnum.EQUAL },
  contractIds: { value: [], argValue: FilterArgEnum.IN_LIST },
  contractorIds: { value: [], argValue: FilterArgEnum.IN_LIST },
  executorIds: { value: [], argValue: FilterArgEnum.IN_LIST },
  taskNum: { value: '', argValue: FilterArgEnum.EQUAL },
  taskType: { value: [], argValue: FilterArgEnum.IN_LIST },
  taskPriority: { value: [], argValue: FilterArgEnum.IN_LIST },
  taskStatus: { value: [], argValue: FilterArgEnum.IN_LIST },
  consumer: { value: [], argValue: FilterArgEnum.IN_LIST },
  taskId: { value: '', argValue: FilterArgEnum.EQUAL },
  territorialBranch: { value: '', argValue: FilterArgEnum.EQUAL },
  managingOrganization: { value: '', argValue: FilterArgEnum.EQUAL },
  statusList: { value: [], argValue: FilterArgEnum.IN_LIST },
  operatorIdList: { value: [], argValue: FilterArgEnum.IN_LIST },
  nextCallDateOn: { value: [], argValue: FilterArgEnum.CONTAIN },
  onlyBashkortostan: false,
};

export const useCallCenterBrowseFilter = createStore<ICallCenterBrowseStore>(
  (set, get) => ({
    ...defaultValue,

    setDefaultValue: () =>
      set(() => ({
        ...defaultValue,
      })),

    setDefaultHelpValue: () =>
      set(() => ({
        taskNum: defaultValue.taskNum,
        taskId: defaultValue.taskId,
      })),

    setBooleanValue: (key) => (value) =>
      set(() => ({
        [key]: value,
      })),

    setSingleValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setSingleValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.INSTALLED || get()[key].argValue === FilterArgEnum.INSTALLED) {
        set(() => ({
          [key]: {
            value: '',
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },

    setDateValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setDateValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.CONTAIN || get()[key].argValue === FilterArgEnum.CONTAIN) {
        set(() => ({
          [key]: {
            value: [],
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },

    setMultiValue: (key) => (value) =>
      set((state) => ({
        [key]: {
          ...state[key],
          value,
        },
      })),

    setMultiValueArg: (key) => (argValue) => {
      if (argValue === FilterArgEnum.INSTALLED || get()[key].argValue === FilterArgEnum.INSTALLED) {
        set((state) => ({
          [key]: {
            value: [],
            argValue,
          },
        }));
      } else {
        set((state) => ({
          [key]: {
            ...state[key],
            argValue,
          },
        }));
      }
    },
  }),
  'Call center browse'
);
