import {
  DesktopDatePicker as DesktopDatePicker_,
  DesktopDatePickerProps,
} from '@mui/x-date-pickers';
import { FC } from 'react';

const datePickerLabelStyle = {
  width: '100%',
  paddingRight: '20px',
  overflow: 'hidden',
  display: 'inline-block',
  textOverflow: 'ellipsis',
};

export const DesktopDatePicker: FC<DesktopDatePickerProps<any, any>> = (props) => (
  <DesktopDatePicker_
    {...props}
    label={<span style={datePickerLabelStyle}>{props.label}</span>}
  />
);
