import { FetchCathError } from '@/types/api';
import produce from 'immer';
import { createStore } from '../utils/createStore';
import { v4 as uuid } from 'uuid';

const _MAX_ELEMENT_COUNT = 20;
export enum ActionLogType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}

export class ActionLog {
  id: string = uuid();
  eventAt: Date = new Date();
  type!: ActionLogType;
  message!: string;
  errorBody?: string;
  errorTrace?: string;
  viewed = false;
  constructor(type: ActionLogType, message: string, errorBody?: string, errorTrace?: string) {
    this.type = type;
    this.message = message;
    this.errorBody = errorBody;
    this.errorTrace = errorTrace;
  }
}

export type CatchErrorFunction = (message: string, error: any) => void;
export type FetchCatchErrorFunction = (error: FetchCathError, message?: string) => void;
export type FetchWithUserMessageFlagCatchErrorFunction = (error: any) => void;

export type AddActionLogFunction = (
  type: ActionLogType,
  message: string,
  errorBody?: string,
  errorTrace?: string
) => void;

export interface ActionLogStore {
  actionLogList: ActionLog[];
  addActionLog: AddActionLogFunction;
  catchError: CatchErrorFunction;
  setActionLogViewed: (id: string) => void;
  fetchCatch: FetchCatchErrorFunction;
  fetchWithUserMessageFlagCatch: FetchWithUserMessageFlagCatchErrorFunction;
}

export const useActionLog = createStore<ActionLogStore>(
  (set: any, get: any) => ({
    actionLogList: [],

    addActionLog: (
      type: ActionLogType,
      message: string,
      errorBody?: string,
      errorTrace?: string
    ) => {
      set(
        produce((items: any) => {
          if (items.actionLogList.length >= _MAX_ELEMENT_COUNT) {
            items.actionLogList.shift();
          }
          items.actionLogList.push(new ActionLog(type, message, errorBody, errorTrace));
        })
      );
    },

    catchError: (message: string, error: any) => {
      get().addActionLog(ActionLogType.ERROR, message, error?.response?.data, error);
    },

    fetchCatch: (error, message) => {
      get().catchError(error?.response?.data?.message ?? message ?? 'Непредвиденная ошибка', error);
    },

    fetchWithUserMessageFlagCatch: (error) => {
      const messageToUser = error?.response?.data?.userMessage
        ? error?.response?.data?.messages?.join('; ')
        : error?.response?.data?.messages?.join('; '); // нужно решить выводить все или нет если юзер не виноват! в противно случае вернуть назад "Непредвиденная ошибка"

      get().catchError(messageToUser ?? 'Непредвиденная ошибка', error);
    },

    setActionLogViewed: (id: string) => {
      set(
        produce((items: any) => {
          const item = items.actionLogList.find((t: ActionLog) => t.id === id);
          item.viewed = true;
        })
      );
    },
  }),
  'Action logs',
  'action-logs'
);

export const catchError = (message: string, error: any) =>
  useActionLog.getState().addActionLog(ActionLogType.ERROR, message, error?.response?.data, error);

export const syncErrorCatch = (message: string, error: any) =>
  useActionLog.getState().addActionLog(ActionLogType.ERROR, message, error, error);

export const addSuccessActionLog = (message: string) =>
  useActionLog.getState().addActionLog(ActionLogType.SUCCESS, message);
