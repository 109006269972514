import React from 'react';
import { Grid, Typography } from '@mui/material';

import { MetalEnum, PpoCable } from '../ppoCable/PpoCable';
import { PpoEnterInputProps } from '../PpoEnterInput/PpoEnterInput';
import { useCablePopup } from '@/components/popups/cablePopup/store';
import { PpoCableListWrapper } from './PpoCableList.styled';
import { PpoCableResponse } from '@/dto/taskmap/task/TaskResultDto';

type PpoCableListProps = Pick<PpoEnterInputProps, 'enter'>;

const PpoCableList = ({ enter }: PpoCableListProps) => {
  const { setIsOpen, setCableList } = useCablePopup((state) => ({
    setIsOpen: state.setIsCablePopupOpen,
    setCableList: state.setCableList,
  }));

  const handleClick = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    setIsOpen(true);
    setCableList(enter.ppoCableResponseList ?? [], enter.id);
  };

  const renderBlocks = () => {
    const lookUp: Record<string, PpoCableResponse[]> = {};
    const defaultValue = 'default';
    enter.ppoCableResponseList?.forEach((item) => {
      const cableCompositionId = `${item?.cableCompositionId || defaultValue}_${
        item?.linesNumber || defaultValue
      }_${item?.sectionId || defaultValue}`;
      lookUp[cableCompositionId] = lookUp[cableCompositionId]
        ? [...lookUp[cableCompositionId], item]
        : [item];
    });

    const items = Object.keys(lookUp).map((item) => {
      return (
        <Grid
          key={item}
          display={'flex'}
          flexDirection={'column'}
        >
          <PpoCable
            sectionId={lookUp[item][0]?.sectionId}
            linesNumber={lookUp[item][0]?.linesNumber}
            metal={lookUp[item][0]?.cableCompositionId as MetalEnum}
          />
          <Typography
            style={{ fontSize: 10, fontWeight: 'bolder' }}
            textAlign={'center'}
          >
            {lookUp[item]?.length} шт
          </Typography>
        </Grid>
      );
    });

    if (!items?.length) return <PpoCable add />;
    return items;
  };

  return (
    <PpoCableListWrapper onDoubleClick={(e) => handleClick(e)}>
      {renderBlocks()}
    </PpoCableListWrapper>
  );
};

export default PpoCableList;
