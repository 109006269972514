import { Box, Button, Grid, Typography, styled } from '@mui/material';
import React, { FC, useState } from 'react';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { FormProvider, useForm } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { TextField } from '@/components/fixUI';
import { updatePpoState } from '@/services/TaskService';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { AccessController } from '@/components/common/AccessController';
import { TaskResponse } from '@/dto/taskmap/task/TasksDto';
import CopyButton from '@/components/button/CopyButton';

interface IVruStatusPopup {
  vruTaskId: string;
  vruInputId: string;
  status?: string;
  loadAll: () => void;
  comment?: string;
  task: TaskResponse;
}

interface IDisabledFields {
  executorId: boolean;
  techDecisionId: boolean;
  comment: boolean;
}
interface IIsDisabled {
  RETURN_FOR_REVISION: IDisabledFields;
  ACCEPTED_POSTPONED: IDisabledFields;
  ACCEPTED: IDisabledFields;
  EXCLUDED: IDisabledFields;
  MPI_NOT_EXPIRED: IDisabledFields;
}
const IS_NOT_DISABLED: IIsDisabled = {
  RETURN_FOR_REVISION: { executorId: true, techDecisionId: false, comment: true }, //Возврат на доработку
  ACCEPTED_POSTPONED: { executorId: false, techDecisionId: true, comment: true }, //Принято отложено
  ACCEPTED: { executorId: false, techDecisionId: true, comment: true }, //Принято
  EXCLUDED: { executorId: false, techDecisionId: false, comment: true }, //Исключено
  MPI_NOT_EXPIRED: { executorId: false, techDecisionId: true, comment: true }, //МПИ не истек
};

interface IForm {
  comment?: string;
  ppoState?: string;
  techDecisionId?: string;
  executorId?: string;
  ppoVruTaskId: string;
  vruInputId: string;
}

export const VruStatusPopup: FC<IVruStatusPopup> = ({
  vruInputId,
  vruTaskId,
  status,
  loadAll,
  comment,
  task,
}) => {
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [openByCastomButton, setOpenByCastomButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  const isEdited = useAccess({
    access: [AccessEnum.ShowPPOStageChange],
    stopRedirect: true,
  });

  const { getCatalogMapWithErr, taskGroupMap } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    taskGroupMap: state.taskGroupMap,
  }));
  const { catchError, addActionLog } = useActionLog();
  const methods = useForm<IForm>();
  const onSubmit = (data: any) => {
    setIsLoading(true);
    updatePpoState(vruTaskId, {
      ...data,
      vruTaskId: vruInputId,
    })
      .then((res) => {
        loadAll();
        addActionLog(ActionLogType.SUCCESS, 'Заявка сохранена успешно');
      })
      .catch((err) =>
        catchError(`Ошибка изменения статуса проверки. ${err?.response?.data?.message ?? ''}`, err)
      )
      .finally(() => {
        setIsLoading(false);
        setIsOpenPopup(false);
        setOpenByCastomButton(false);
      });
  };
  const clearFields = () => {
    methods.setValue('executorId', task?.executorId);
    methods.setValue('techDecisionId', undefined);
    methods.setValue('comment', undefined);
    setIsClear(!isClear);
  };

  const checCommetLength = comment && comment.length > 30 ? 7 : 2;
  const renderComment = () => {
    if (comment && comment?.length > 50)
      return (
        <LightTooltip
          title={
            <React.Fragment>
              <Typography variant='subtitle1'>{comment}</Typography>
            </React.Fragment>
          }
        >
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {comment}
          </Typography>
        </LightTooltip>
      );
    return (
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {comment}
      </Typography>
    );
  };

  methods.watch('ppoState');

  return (
    <Grid
      item
      xs={12}
    >
      <Grid
        container
        xs={12}
        alignItems={'center'}
      >
        <Grid
          item
          mr={2}
        >
          <Button
            disabled={!isEdited}
            onClick={() => {
              if (isEdited) {
                setIsOpenPopup(true);
                methods.setValue('executorId', task?.executorId);
              }
            }}
          >
            {status ? getCatalogMapWithErr('ppoState').get(status) : 'Статус'}
          </Button>
        </Grid>
        {status === 'RETURN_FOR_REVISION' && (
          <Grid
            item
            mr={2}
          >
            <Button
              onClick={() => {
                if (isEdited) {
                  setIsOpenPopup(true);
                  setOpenByCastomButton(true);
                  methods.setValue('executorId', task?.executorId);
                }
              }}
            >
              Передано исполнителю
            </Button>
          </Grid>
        )}
        <Grid xs={checCommetLength}>{renderComment()}</Grid>
        <CopyButton textToCopy={comment} />
      </Grid>
      <AccessController access={[AccessEnum.ShowPPOStageChange]}>
        <AccessPopup
          onSuccess={methods.handleSubmit(onSubmit)}
          isOpen={isOpenPopup}
          onClose={() => {
            setIsOpenPopup(false);
            setOpenByCastomButton(false);
          }}
          isLoading={isLoading}
        >
          <FormProvider {...methods}>
            <AutocompleteValue
              optionOnChangeValue={clearFields}
              title='Статус проверки'
              fieldName={`ppoState`}
              defaultValue={openByCastomButton ? 'TRANSFER_TO_EXECUTOR' : status}
              values={getCatalogMapWithErr('ppoState')}
              disabled={openByCastomButton}
            />
            <AutocompleteValue
              clearFlag={isClear}
              title='Исполнитель'
              disabled={
                openByCastomButton ||
                !IS_NOT_DISABLED[methods.getValues('ppoState') as keyof IIsDisabled]?.executorId
              }
              fieldName={`executorId`}
              values={taskGroupMap}
              defaultValue={methods.getValues('executorId')}
            />
            <AutocompleteValue
              clearFlag={isClear}
              title='Тех. решение'
              disabled={
                openByCastomButton ||
                !IS_NOT_DISABLED[methods.getValues('ppoState') as keyof IIsDisabled]?.techDecisionId
              }
              fieldName={`techDecisionId`}
              values={getCatalogMapWithErr('ppoOdpuTechDecision')}
              defaultValue={methods.getValues('techDecisionId')}
            />
            <TextField
              id='comment'
              label='Примечание'
              variant='outlined'
              multiline
              rows={4}
              sx={{ width: '100%', mt: 3 }}
              disabled={
                openByCastomButton ||
                !IS_NOT_DISABLED[methods.getValues('ppoState') as keyof IIsDisabled]?.comment
              }
              {...methods.register(`comment`)}
              defaultValue={comment}
            />
          </FormProvider>
        </AccessPopup>
      </AccessController>
    </Grid>
  );
};
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[3],
    fontSize: 11,
  },
}));
