import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TransformerVoltageRequestDto, TransformerVoltageResponseDto } from '@/dto/TransformerDto';
import {
  getTransformerVoltage,
  postTransformerVoltage,
  putTransformerVoltage,
} from '@/services/TransformerService';
import { useParams } from 'react-router-dom';
import { TransformerBlock } from './TransformerBlock';
import { Grid, Stack, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { BoxShadow } from '../../ui/BoxShadow';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { ExpandButton } from '@/components/button/ExpandButton';
import { useCatalog } from '@/hooks/CatalogHook';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';

const TransformerVoltage: FC = () => {
  const onlyShow = useTaskEditorOnlyShow();
  const methods = useForm<TransformerVoltageRequestDto>({
    defaultValues: {
      transformerVoltageRequestList: [],
    },
  });
  const [open, setOpen] = useState<boolean>(false);
  const [isExist, setIsExist] = useState<boolean>(false);

  const { catchError, addActionLog } = useActionLog();
  const { handleSubmit } = methods;

  const { taskId } = useParams();

  const [data, setData] = useState<TransformerVoltageResponseDto>();

  useEffect(() => {
    taskId &&
      getTransformerVoltage(taskId)
        .then((res) => {
          setData(res.data);
          setIsExist((res.data?.transformerVoltageResponseList?.length ?? 0) > 0);
        })
        .catch(({ response }) => {
          // catchError(response?.data?.message, response?.data);
          setIsExist(false);
        });
  }, []);
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const onSave = (e: TransformerVoltageRequestDto) => {
    // '03a37464-f880-11ec-911f-005056bd1435'
    const tmp = {
      ...e,
      transformerVoltageRequestList: e.transformerVoltageRequestList.map((elem) => ({
        ...elem,
        ktnId: e.ktnId,
        checkOn: e.checkOn,
        nextCheckOn: e.nextCheckOn,
      })),
    };
    !onlyShow &&
      taskId &&
      putTransformerVoltage(taskId, tmp)
        .then(() => addActionLog(ActionLogType.SUCCESS, 'Успешно сохранено'))
        .catch((err) => catchError(err?.response?.data?.message, err));
  };
  return isExist ? (
    <FormProvider {...methods}>
      <BoxShadow sx={{ marginTop: 2 }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography>
            <span style={{ fontWeight: 'bold' }}>Трансформаторы Напряжения: </span>
            {!open && data?.ktnId && getCatalogMapWithErr('ktnType').get(data.ktnId)}
          </Typography>
          {!open && (
            <ExpandButton
              onAction={() => setOpen(!open)}
              expand={open}
            />
          )}
        </Stack>
        {open && (
          <Grid
            mt={2}
            container
            spacing={2}
          >
            {data && <TransformerBlock data={data} />}
            <Grid sx={{ ml: 'auto' }}>
              <SaveIcon
                onClick={handleSubmit(onSave)}
                sx={{
                  color: 'gray',
                  fontSize: 22,
                  cursor: onlyShow ? '' : 'pointer',
                  ml: 2,
                }}
              />
              <ExpandButton
                onAction={() => setOpen(!open)}
                expand={open}
              />
            </Grid>
          </Grid>
        )}
      </BoxShadow>
    </FormProvider>
  ) : null;
};

export default TransformerVoltage;
