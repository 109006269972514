import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { createStore } from '@/utils/createStore';

export enum FilterComboErrorTypes {
  NO_INFO = 'Нет данных для сохранения набора',
  DUPLICATE = 'Набор с данным названием уже существует',
}

export interface IFilter {
  value: string | SelectFilterOptType[] | number[];
  argValue: number;
  label?: string;
}

export interface IFilterCombo {
  [key: string]: IFilter | boolean | SelectFilterOptType[];
}

interface Filters {
  tmpFilter: {
    [filterType: string]: IFilterCombo;
  };
  allFilters: {
    [filterType: string]: {
      filterName: string;
      filters: IFilterCombo;
    }[];
  };

  addFilters: (filterType: string, newFilterName: string, newFilters?: IFilterCombo) => void;
  addTmpFilters: (filterType: string, newFilters?: IFilterCombo) => void;
  removeFilter: (filterType: string, filterName: string) => void;
}
export const useStoredFilters = createStore<Filters>(
  (set, get) => ({
    allFilters: {},
    tmpFilter: {},

    addTmpFilters: (filterType, newFilters) => {
      if (!newFilters) {
        throw new Error(FilterComboErrorTypes.NO_INFO);
      }
      set(() => ({
        tmpFilter: {
          ...get().tmpFilter,
          [filterType]: newFilters,
        },
      }));
    },

    addFilters: (filterType, newFilterName, newFilters) => {
      const newAllFilters = { ...get().allFilters };
      const filterTypeExist = newAllFilters[filterType];
      if (!newFilters) {
        throw new Error(FilterComboErrorTypes.NO_INFO);
      }
      if (filterTypeExist) {
        const duplicateName = filterTypeExist?.find(
          (filter) => filter.filterName === newFilterName
        );
        if (duplicateName) {
          throw new Error(FilterComboErrorTypes.DUPLICATE);
        } else {
          filterTypeExist.push({ filterName: newFilterName, filters: newFilters });
          set(() => ({
            allFilters: newAllFilters,
          }));
        }
      } else {
        newAllFilters[filterType] = [{ filterName: newFilterName, filters: newFilters }];

        set(() => ({
          allFilters: newAllFilters,
        }));
      }
    },

    removeFilter: (filterType: string, removeFilterName: string) => {
      const newAllFilters = { ...get().allFilters };
      const filterTypeExist = newAllFilters[filterType];
      if (filterTypeExist) {
        const test = newAllFilters[filterType]?.filter((filter) => {
          return filter.filterName !== removeFilterName;
        });

        newAllFilters[filterType] = test;

        set(() => ({
          allFilters: newAllFilters,
        }));
      } else {
        return;
      }
    },
  }),
  'taskBrowseFilters',
  'taskBrowseFilters'
);
