import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, Stack } from '@mui/material';
import { PersistentDrawerRight } from '@/components/ImageDrawer';
import { useGalleryData } from '@/components/Gallery/store';
import { findDeliveryPointById } from '@/services/TaskService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { DeliveryPointResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useParams } from 'react-router-dom';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { DeliveryPointItem } from '@/components/features/taskEditor/deliveryPoint/DeliveryPointItem';
import { ImagePanel } from '@/components/features/taskEditor/panel/ImagePannel/ImagePanel';
import { getDeliveryPointImageGroup } from '@/components/features/taskEditor/panel/utils';

export const DeliveryPoint = () => {
  const { deliveryPointId } = useParams();
  const [data, setData] = useState<DeliveryPointResponse>();
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);
  const methods = useForm();

  const { catchError } = useActionLog();

  const load = () => {
    deliveryPointId &&
      findDeliveryPointById(deliveryPointId)
        .then((res) => {
          setData(res.data);
          setImageGroups(getDeliveryPointImageGroup(res.data));
        })
        .catch((err) => catchError('Ошибка загрузки', err));
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <BoxShadow sx={{ m: 2, width: 'auto' }}>
      {data && deliveryPointId && (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <FormProvider {...methods}>
              <DeliveryPointItem
                id={deliveryPointId}
                data={data}
                disabledAll
              />
            </FormProvider>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Stack
              spacing={2}
              height={'100%'}
            >
              <ImagePanel />
              <PersistentDrawerRight />
            </Stack>
          </Grid>
        </Grid>
      )}
    </BoxShadow>
  );
};
