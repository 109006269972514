import { useAccess } from '@/hooks/useAccess';
import { ComplaintStage } from '@/services/ComplaintService/ComplaintServiceDto';
import { AccessEnum } from '@/app/auth/roles';
import { useComplaintStore } from './store';
import { EComplaintType } from './types';

export const useComplaintsEditAccess = (): boolean | undefined => {
  const selectedCompliant = useComplaintStore((state) => state.selectedCompliant);
  const type = selectedCompliant?.complaint?.type;
  const stage = selectedCompliant?.complaint.stage?.name;

  switch (type) {
    case EComplaintType.ENERGY_SERVICE:
      return useAccess({
        access: [
          AccessEnum.ComplaintsEditAll,
          ...(stage === ComplaintStage.CALL_CENTERED ? [AccessEnum.ComplaintsEditCallCenter] : []),
          ...(stage === ComplaintStage.RETURNED ? [AccessEnum.ComplaintsEditReturned] : []),
          ...(stage === ComplaintStage.IN_PROGRESS ? [AccessEnum.ComplaintsEditInProgress] : []),
          ...(stage === ComplaintStage.TRANSFERRED_TO_RESPONSIBLE
            ? [AccessEnum.ComplaintsEditTransferedToResponsible]
            : []),
          ...(stage === ComplaintStage.MODERATED_AFTER_RESPONSIBLE
            ? [AccessEnum.ComplaintsEditModeratedAfterResponsible]
            : []),
        ],
        stopRedirect: true,
      });

    case EComplaintType.ODPU:
      return useAccess({
        access: [
          AccessEnum.ComplaintsEditAll,
          ...(stage === ComplaintStage.MODERATED ? [AccessEnum.ComplaintsEditOdpuModerated] : []),
          ...(stage === ComplaintStage.RETURNED ? [AccessEnum.ComplaintsEditOdpuReturned] : []),
        ],
        stopRedirect: true,
      });

    default:
      return;
  }
};
