import { Box, Button, Grid, Modal, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  ApiRefreshAddressDetail,
  ApiPnrFindAddressDetails,
  ApiPnrChangeAddressDetail,
} from '@/services/YodaRestService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { PnrAddressDetail } from '@/dto/taskmap/Dto';
import CachedIcon from '@mui/icons-material/Cached';
import shallow from 'zustand/shallow';
import { usePnrBrowseStore } from '../../HousePnrBrowse/store';
import {
  HousePnrBrowseEditAddressModalWrapper,
  HousePnrBrowseModalButtonsWrapper,
  HousePnrBrowseModalHeader,
  HousePnrBrowseModalRow,
  HousePnrBrowseModalErrorText,
  HousePnrBrowseModalAnimatedButton,
  HousePnrBrowseModalInputLoading,
  HousePnrBrowseModalInputLoadingBig,
} from './HousePnrBrowseEditAddressModal.styled';
import { HOUSE_PNR_BROWSE_EDIT_ADDRESS_MODAL_VALIDATION } from './utils';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQuery } from 'react-query';
import { FetchCathError } from '@/types/api';

const QUERY_KEY = 'house_address_info';

export interface HousePnrBrowseModalProps {
  refetchBrowse: () => void;
}
export default function HousePnrBrowseEditAddressModal({
  refetchBrowse,
}: HousePnrBrowseModalProps) {
  const { editAddressModalOpen, closeEditAddressModal, selectedRowsData, resetSelection } =
    usePnrBrowseStore(
      (state) => ({
        editAddressModalOpen: state.editAddressModalOpen,
        closeEditAddressModal: state.closeEditAddressModal,
        selectedRowsData: state.selectedRowsData,
        resetSelection: state.resetSelection,
      }),
      shallow
    );

  const { catchError, addActionLog, fetchCatch } = useActionLog();
  const { handleSubmit, getValues, setValue, register, formState } = useForm<PnrAddressDetail>({
    mode: 'onTouched',
  });

  const [addressDetail, setAddressDetail] = useState<PnrAddressDetail>();

  useEffect(() => {
    setValue('regionName', addressDetail?.regionName);
    setValue('areaName', addressDetail?.areaName);
    setValue('localityNameMob', addressDetail?.localityNameMob);
    setValue('streetNameMob', addressDetail?.streetNameMob);
    setValue('house', addressDetail?.house);
    setValue('korpus', addressDetail?.korpus);
    setValue('stroenie', addressDetail?.stroenie);
    setValue('districtName', addressDetail?.districtName);
    setValue('fias', addressDetail?.fias);
    setValue('latitudeY', addressDetail?.latitudeY);
    setValue('longitudeX', addressDetail?.longitudeX);
    setValue('readOnlyAddress', addressDetail?.readOnlyAddress);
  }, [addressDetail]);

  const onSubmit: SubmitHandler<PnrAddressDetail> = (data) => {
    saveAddressDetail(data);
  };

  const handleUpdateAddress = () => mutRefreshAddressDetail.mutate();

  const mutRefreshAddressDetail = useMutation<void, any>(() => {
    const data = { ...getValues(), id: addressDetail?.addressTableId ?? '' };

    return ApiRefreshAddressDetail(data)
      .then(({ data }) => {
        const formValues = getValues();
        const dataWithPnrAddresDetails: PnrAddressDetail = {
          ...data,
          addressTableId: addressDetail?.addressTableId ?? '',
          houseAddressInfoId: addressDetail?.houseAddressInfoId ?? '',
          fias: formValues?.fias ?? '',
          longitudeX: formValues?.longitudeX ?? '',
          latitudeY: formValues?.latitudeY ?? '',
        };

        setAddressDetail(dataWithPnrAddresDetails);
      })
      .catch((err) => catchError('Ошибка при обновлении адреса', err));
  });

  const mutSaveAddressDetail = useMutation<void, any, PnrAddressDetail>((data) => {
    data.houseAddressInfoId = addressDetail?.houseAddressInfoId ?? '';

    return ApiPnrChangeAddressDetail(selectedRowsData[0]?.houseId, data)
      .then((value) => {
        refetchBrowse();
        resetSelection();
        addActionLog(ActionLogType.SUCCESS, 'Адрес обновлен');
        closeEditAddressModal();
      })
      .catch((err) => catchError('Ошибка при обновлении адреса', err));
  });

  const { isFetching: isLoadingAddressDetails, refetch } = useQuery({
    queryKey: [QUERY_KEY, selectedRowsData[0]?.houseId],
    staleTime: Infinity,
    retry: 0,
    refetchOnWindowFocus: false,
    queryFn: () => ApiPnrFindAddressDetails(selectedRowsData[0]?.houseId),
    enabled: editAddressModalOpen,
    onError: (error: FetchCathError) => {
      fetchCatch(error, 'Ошибка загрузки адресной информации');
      closeEditAddressModal();
    },
    onSuccess: ({ data }) => {
      if (data) {
        setAddressDetail(data);
      }
    },
  });

  const saveAddressDetail = (data: PnrAddressDetail) => {
    if (addressDetail) {
      mutSaveAddressDetail.mutate(data);
    }
  };

  useEffect(() => {
    if (editAddressModalOpen) {
      refetch();
    }
  }, [editAddressModalOpen]);

  return (
    <Modal
      open={editAddressModalOpen}
      onClose={closeEditAddressModal}
    >
      <HousePnrBrowseEditAddressModalWrapper>
        <HousePnrBrowseModalHeader variant='h5'>
          Внесите изменения по адресу
        </HousePnrBrowseModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={3}
            >
              <Grid
                item
                xs={4}
              >
                <HousePnrBrowseModalInputLoading loading={isLoadingAddressDetails}>
                  <TextField
                    {...register('regionName')}
                    variant='standard'
                    label='Регион'
                    fullWidth
                  />
                </HousePnrBrowseModalInputLoading>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <HousePnrBrowseModalInputLoading loading={isLoadingAddressDetails}>
                  <TextField
                    {...register('areaName')}
                    variant='standard'
                    label='Район'
                    fullWidth
                  />
                </HousePnrBrowseModalInputLoading>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <HousePnrBrowseModalInputLoading loading={isLoadingAddressDetails}>
                  <TextField
                    {...register('districtName')}
                    variant='standard'
                    fullWidth
                    label='Район города'
                  />
                </HousePnrBrowseModalInputLoading>
              </Grid>

              <Grid
                item
                xs={4}
              >
                <HousePnrBrowseModalInputLoading loading={isLoadingAddressDetails}>
                  <TextField
                    {...register('localityNameMob')}
                    variant='standard'
                    label='Населенный пункт'
                    fullWidth
                  />
                </HousePnrBrowseModalInputLoading>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <HousePnrBrowseModalInputLoading loading={isLoadingAddressDetails}>
                  <TextField
                    {...register('streetNameMob')}
                    variant='standard'
                    label='Улица'
                    fullWidth
                  />
                </HousePnrBrowseModalInputLoading>
              </Grid>
              <Grid
                item
                xs={1}
              >
                <HousePnrBrowseModalInputLoading loading={isLoadingAddressDetails}>
                  <TextField
                    {...register('house')}
                    variant='standard'
                    label='Дом'
                    fullWidth
                  />
                </HousePnrBrowseModalInputLoading>
              </Grid>
              <Grid
                item
                xs={1.5}
              >
                <HousePnrBrowseModalInputLoading loading={isLoadingAddressDetails}>
                  <TextField
                    {...register('korpus')}
                    variant='standard'
                    label='Корпус'
                    fullWidth
                  />
                </HousePnrBrowseModalInputLoading>
              </Grid>
              <Grid
                item
                xs={1.5}
              >
                <HousePnrBrowseModalInputLoading loading={isLoadingAddressDetails}>
                  <TextField
                    {...register('stroenie')}
                    variant='standard'
                    label='Строение'
                    fullWidth
                  />
                </HousePnrBrowseModalInputLoading>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <HousePnrBrowseModalRow>
                  <HousePnrBrowseModalInputLoadingBig
                    loading={isLoadingAddressDetails || mutRefreshAddressDetail?.isLoading}
                  >
                    <TextField
                      variant='filled'
                      disabled
                      fullWidth
                      value={addressDetail?.readOnlyAddress}
                    />
                  </HousePnrBrowseModalInputLoadingBig>

                  <HousePnrBrowseModalAnimatedButton
                    onClick={handleUpdateAddress}
                    disabled={
                      mutSaveAddressDetail?.isLoading ||
                      isLoadingAddressDetails ||
                      mutRefreshAddressDetail?.isLoading
                    }
                  >
                    <CachedIcon color='primary' />
                  </HousePnrBrowseModalAnimatedButton>
                </HousePnrBrowseModalRow>
              </Grid>

              <Grid
                item
                xs={6}
              >
                <HousePnrBrowseModalInputLoading loading={isLoadingAddressDetails}>
                  <TextField
                    variant='standard'
                    {...register('fias', {
                      ...HOUSE_PNR_BROWSE_EDIT_ADDRESS_MODAL_VALIDATION.fias,
                    })}
                    fullWidth
                    label='ФИАС'
                  />
                  {formState?.errors?.fias?.message && (
                    <HousePnrBrowseModalErrorText>
                      {formState?.errors?.fias?.message}
                    </HousePnrBrowseModalErrorText>
                  )}
                </HousePnrBrowseModalInputLoading>
              </Grid>
              <Grid
                item
                xs={3}
              >
                <HousePnrBrowseModalInputLoading loading={isLoadingAddressDetails}>
                  <TextField
                    variant='standard'
                    {...register('latitudeY', {
                      ...HOUSE_PNR_BROWSE_EDIT_ADDRESS_MODAL_VALIDATION.coords,
                    })}
                    fullWidth
                    label='Широта'
                  />
                  {formState?.errors?.latitudeY?.message && (
                    <HousePnrBrowseModalErrorText>
                      {formState?.errors?.latitudeY?.message}
                    </HousePnrBrowseModalErrorText>
                  )}
                </HousePnrBrowseModalInputLoading>
              </Grid>
              <Grid
                item
                xs={3}
              >
                <HousePnrBrowseModalInputLoading loading={isLoadingAddressDetails}>
                  <TextField
                    variant='standard'
                    {...register('longitudeX', {
                      ...HOUSE_PNR_BROWSE_EDIT_ADDRESS_MODAL_VALIDATION.coords,
                    })}
                    fullWidth
                    label='Долгота'
                  />
                  {formState?.errors?.longitudeX?.message && (
                    <HousePnrBrowseModalErrorText>
                      {formState?.errors?.longitudeX?.message}
                    </HousePnrBrowseModalErrorText>
                  )}
                </HousePnrBrowseModalInputLoading>
              </Grid>
            </Grid>
          </Box>
          <HousePnrBrowseModalButtonsWrapper>
            <LoadingButton
              type='submit'
              variant='contained'
              disabled={
                !!formState?.errors?.fias ||
                !!formState?.errors?.latitudeY ||
                !!formState?.errors?.longitudeX
              }
              loading={mutSaveAddressDetail?.isLoading || isLoadingAddressDetails}
            >
              Сохранить
            </LoadingButton>
            <Button onClick={closeEditAddressModal}>Отменить</Button>
          </HousePnrBrowseModalButtonsWrapper>
        </form>
      </HousePnrBrowseEditAddressModalWrapper>
    </Modal>
  );
}
