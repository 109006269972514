import React, { useEffect } from 'react';
import { color } from '@/styles/mixins';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import LocationOffRoundedIcon from '@mui/icons-material/LocationOffRounded';
import EditLocationRoundedIcon from '@mui/icons-material/EditLocationRounded';
import { useCoords } from '@/components/maps/ppoMap/coordsStore';
import { usePillar } from '@/components/maps/ppoMap/resCard/pillarStore';
import { SaveButton } from '@/components/ui/SaveButton';
import { savePillarById } from '@/services/TaskService';
import { addSuccessActionLog, catchError } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { usePpoMap } from '@/components/maps/ppoMap/store';
import { findAllDeliveryPoint } from '@/components/maps/ppoMap/service';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';

const getUrl = (imgIds: string[]) => {
  let result = '/gallery?title=Опора';
  imgIds.forEach((elem) => (result += `&ids=${elem}`));
  return result;
};

export const PillarItem = () => {
  const { infoPillar } = usePillar();
  const { isEditMode, changeMode, latitude, longitude } = useCoords();
  const { register, handleSubmit, setValue, reset, control } = useFormContext();
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const catalog = taskMapToArray(getCatalogMapWithErr('pillarType'));
  const { setGeoTransStation, tmpTaskId } = usePpoMap();

  useEffect(() => {
    if (!isEditMode && latitude && longitude) {
      setValue(`${infoPillar?.id}.latitudeY`, latitude);
      setValue(`${infoPillar?.id}.longitudeX`, longitude);
    }
  }, [isEditMode]);

  const onSave = (data: any) => {
    if (infoPillar) {
      const type = {
        id: data.typeId,
      };
      savePillarById(infoPillar?.id, { ...data[infoPillar?.id], type })
        .then((res) => {
          addSuccessActionLog('Сохранено');
          tmpTaskId &&
            findAllDeliveryPoint(tmpTaskId)
              .then(({ data }) => setGeoTransStation(data))
              .catch((err) => catchError('Ошибка при загрузке точек поставки', err));
        })
        .catch((err) => catchError('Не сохранено', err));
    }
  };

  const openGallery = () => {
    infoPillar?.imageList && window.open(getUrl(infoPillar.imageList.map((elem) => elem.id)));
  };

  useEffect(() => {
    reset();
    setValue(`typeId`, infoPillar?.typeId);
  }, [infoPillar]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography
            variant={'h6'}
            sx={{ flex: '1 1 0' }}
          >
            Опора
          </Typography>
          <IconButton
            sx={{ mr: 2 }}
            disabled={!infoPillar?.imageList || infoPillar?.imageList.length === 0}
            onClick={openGallery}
          >
            <ImageSearchRoundedIcon htmlColor={color('muiBlue')} />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <TextField
            id='trns-number'
            label='Номер'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${infoPillar?.id}.number`, {
              value: infoPillar?.number,
            })}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Controller
            name={`typeId`}
            defaultValue={infoPillar?.typeId ? `${infoPillar.typeId}` : undefined}
            control={control}
            render={({ field }) => (
              <Autocomplete
                componentsProps={{
                  paper: {
                    sx: {
                      width: 190,
                    },
                  },
                }}
                options={catalog}
                value={{
                  value: field?.value ?? '',
                  label: catalog.find((el) => el.value === field?.value)?.label || '',
                }}
                onChange={(event: any, newValue: any) => field?.onChange(newValue?.value)}
                renderInput={(params) => (
                  <TextField
                    name={`typeId`}
                    {...params}
                    label={'Тип'}
                    variant='standard'
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={5}
        >
          <TextField
            id='latitudeY'
            label='Широта'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${infoPillar?.id}.latitudeY`, {
              value: infoPillar?.latitude,
            })}
          />
        </Grid>
        <Grid
          item
          xs={5}
        >
          <TextField
            id='longitudeX'
            label='Долгота'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${infoPillar?.id}.longitudeX`, {
              value: infoPillar?.longitude,
            })}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <IconButton onClick={() => changeMode()}>
            {isEditMode ? (
              <LocationOffRoundedIcon htmlColor={color('muiBlue')} />
            ) : (
              <EditLocationRoundedIcon htmlColor={color('muiBlue')} />
            )}
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
        >
          <SaveButton onSave={handleSubmit(onSave)} />
        </Grid>
      </Grid>
    </Box>
  );
};
