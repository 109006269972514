import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';

export const generateDetailedInfo = (...data: [SelectFilterOptType[] | string, string][]) => {
  return data.map((filter) => {
    const value = filter[0];
    const title = filter[1];
    return { items: Array.isArray(value) ? getDataFromFilter(value, 'label') : value, title };
  });
};
