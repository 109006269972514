import React from 'react';
import { Siblings } from '@/dto/taskmap/Dto';
import { Button, CircularProgress, Typography } from '@mui/material';
import { IdPopup } from '../IdPopup';
import {
  TaskHeaderPanelSiblingsTitle,
  TaskHeaderPanelChildSiblingsWrapper,
  TaskHeaderPanelChildSiblingsTitle,
} from './TaskHeaderPanelSiblings.styled';
import { useCatalog } from '@/hooks/CatalogHook';
import IconArrowsWithExclamation from '@/images/icons/components/IconArrowsWithExclamation';

interface TaskHeaderPanelSiblingsProps {
  siblings?: Siblings;
  isParent?: boolean;
  isFetching: boolean;
}

const TaskHeaderPanelSiblings = ({
  siblings,
  isParent,
  isFetching,
}: TaskHeaderPanelSiblingsProps) => {
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);

  const getType = (type?: string) =>
    getCatalogMapWithErr('taskType').get(type ?? '') ?? 'ТИП НЕ ЗАДАН';
  const handleClick = (taskId?: string) => window.open(`/task/edit/${taskId}`);
  return (
    <>
      <IdPopup
        title={
          <sup>
            <IconArrowsWithExclamation />
          </sup>
        }
        sx={{ color: 'gray' }}
      >
        {isFetching ? (
          <CircularProgress size={20} />
        ) : (
          siblings?.parentTask && (
            <div>
              <TaskHeaderPanelSiblingsTitle variant='subtitle2'>
                Родительская заявка:
              </TaskHeaderPanelSiblingsTitle>
              <Typography variant='subtitle2'>
                {isParent ? (
                  <span>
                    {getType(siblings?.parentTask?.taskType)} №{siblings?.parentTask?.number}
                  </span>
                ) : (
                  <>
                    <span>{getType(siblings?.parentTask?.taskType)}</span>
                    <Button
                      variant='text'
                      size='small'
                      onClick={() => handleClick(siblings?.parentTask?.id)}
                    >
                      <span>№ {siblings?.parentTask?.number}</span>
                    </Button>
                  </>
                )}
              </Typography>
            </div>
          )
        )}
        {siblings?.childTaskList && (
          <div>
            <TaskHeaderPanelChildSiblingsTitle variant='subtitle2'>
              Связанные заявки:
            </TaskHeaderPanelChildSiblingsTitle>
            <Typography variant='subtitle2'>
              {siblings?.childTaskList?.length ? (
                <TaskHeaderPanelChildSiblingsWrapper>
                  {siblings?.childTaskList.map((sibling, index) => (
                    <span key={index}>
                      {getType(sibling?.taskType)}
                      <Button
                        variant='text'
                        size='small'
                        onClick={() => handleClick(sibling.id)}
                      >
                        № {sibling.number}
                      </Button>
                    </span>
                  ))}
                </TaskHeaderPanelChildSiblingsWrapper>
              ) : (
                <>нет связанных</>
              )}
            </Typography>
          </div>
        )}
      </IdPopup>
    </>
  );
};

export default TaskHeaderPanelSiblings;
