import React from 'react';
import { Stack, Typography } from '@mui/material';
import { PpoEnterInputProps } from '../PpoEnterInput/PpoEnterInput';
import { color } from '@/styles/mixins';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { useExpendableToolsPopup } from '@/components/popups/expendableToolsPopup/store';

type ExpendableListProps = Pick<PpoEnterInputProps, 'enter'>;

const PpoInputExpendableList = ({ enter }: ExpendableListProps) => {
  const { setIsOpen, setInputExpendableList } = useExpendableToolsPopup((state) => ({
    setIsOpen: state.setIsExpendableToolsPopupOpen,
    setInputExpendableList: state.setInputExpendableList,
  }));

  const handleClick = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    setIsOpen(true);
    setInputExpendableList(enter.ppoInputExpendableList ?? [], enter.id);
  };

  return (
    <Stack onDoubleClick={(e) => handleClick(e)}>
      <BoxShadow
        sx={{ backgroundColor: color('trueWhite'), paddingY: 1, cursor: 'pointer' }}
        onDoubleClick={handleClick}
      >
        <Typography
          style={{ fontSize: 10 }}
          textAlign={'center'}
        >
          Доп.
        </Typography>
        <Typography
          style={{ fontSize: 10 }}
          textAlign={'center'}
        >
          оборудование
        </Typography>
        <Typography
          style={{ fontSize: 16, color: color('blue') }}
          textAlign={'center'}
        >
          {enter?.ppoInputExpendableList?.length < 1 && '+'}
        </Typography>
      </BoxShadow>
      <Typography
        style={{ fontSize: 10, fontWeight: 'bolder' }}
        textAlign={'center'}
      >
        {!!enter.ppoInputExpendableList?.length && `${enter?.ppoInputExpendableList?.length} шт`}
      </Typography>
    </Stack>
  );
};

export default PpoInputExpendableList;
