import { AxiosResponse } from 'axios';
import { HOST } from '@/services/YodaRestService';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '@/services/helpers';
import {
  TransformerAmperage,
  TransformerAmperageRequestDto,
  TransformerAmperageResponseDto,
  TransformerVoltageRequestDto,
  TransformerVoltageResponseDto,
} from '@/dto/TransformerDto';

export async function getTransformerVoltage(
  id: string
): Promise<AxiosResponse<TransformerVoltageResponseDto, any>> {
  const url = HOST + `/rest/task/${id}/transformer-voltage`;
  return axiosGet(url);
}

export async function deleteTransformerVoltage(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/task/${id}/transformer-voltage`;
  return axiosDelete(url);
}

export async function putTransformerVoltage(
  id: string,
  data: TransformerVoltageRequestDto
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/task/${id}/transformer-voltage`;
  return axiosPut(url, data);
}

export async function postTransformerVoltage(
  id: string,
  data: TransformerVoltageRequestDto
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/task/${id}/transformer-voltage`;
  return axiosPost(url, data);
}

export async function getTransformerAmperage(
  taskId: string,
  type: 'task' | 'vru-input'
): Promise<AxiosResponse<TransformerAmperage, any>> {
  const url = HOST + `/rest/${type}/${taskId}/task-transformer`;
  return axiosGet(url);
}

export async function deleteTransformerAmperage(
  taskId: string,
  type: 'task' | 'vru-input'
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/${type}/${taskId}/task-transformer`;
  return axiosDelete(url);
}

export async function putTransformerAmperage(
  taskId: string,
  type: 'task' | 'vru-input',
  data: TransformerAmperageRequestDto
): Promise<AxiosResponse<any, any>> {
  const url =
    HOST +
    (type == 'task'
      ? `/rest/${type}/${taskId}/transformer-amperage`
      : `/rest/task/${taskId}/transformer-amperage/vru`);
  return axiosPut(url, data);
}

export async function postTransformerAmperage(
  taskId: string,
  type: 'task' | 'vru-input',
  data: TransformerAmperageRequestDto
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/${type}/${taskId}/task-transformer`;
  return axiosPost(url, data);
}
