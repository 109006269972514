import { BubbleDataPoint, ChartData, ScatterDataPoint } from 'chart.js';

type CheckStatisticsChartData = ChartData<
  'pie',
  (number | ScatterDataPoint | BubbleDataPoint | null)[]
>;

export const getLabels = (data: CheckStatisticsChartData) => {
  return Object.assign(
    [],
    data.labels?.map((label) => String(label))
  );
};

export const getData = (data: CheckStatisticsChartData) => {
  return Object.assign([], data?.datasets[0]?.data);
};

export const getColors = (data: CheckStatisticsChartData) => {
  return Object.assign([], data.datasets[0]?.backgroundColor);
};
