import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ReplacementResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useActionLog } from '@/hooks/ActionLogHook';
import { ReplacementViewItem } from './ReplacementViewItem';
import { TextField } from '@/components/fixUI';
import { fidnReplacementById } from '@/services/ReplacementService';
import { getSingleTask } from '@/services/TaskService';
import { TaskResponse } from '@/dto/taskmap/task/TasksDto';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
export interface CombinedDto extends ReplacementResponse, TaskResponse {}
interface ReplacementViewDialogProps {
  handleOk(id?: string): void;
  swapByTaskId(taskId?: string): void;
  closeDialog(): void;
  openDialog: boolean;
}

export const ReplacementViewDialog: FC<ReplacementViewDialogProps> = ({
  handleOk,
  closeDialog,
  openDialog,
  swapByTaskId,
}) => {
  const { catchError } = useActionLog();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [serchByTaskId, setSerchByTaskId] = useState<boolean>(false);
  const [filterId, setFilterId] = useState<string>('');
  const [replacementRespons, setReplacementRespons] = useState<CombinedDto | null>(null);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  useEffect(() => {
    setFilterId('');
    setReplacementRespons(null);
  }, [serchByTaskId]);

  const onClickHandler = () => {
    setLoading(true);
    if (serchByTaskId) {
      getSingleTask(filterId)
        .then(({ data }) => {
          setReplacementRespons(data);
        })
        .catch((err) => {
          catchError('Замена не найдена', err);
          setReplacementRespons(null);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      fidnReplacementById(filterId)
        .then(({ data }) => {
          setReplacementRespons(data);
        })
        .catch((err) => {
          catchError('Замена не найдена', err);
          setReplacementRespons(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    closeDialog();
  };

  const handleClick = () => {
    setFilterId('');
    setReplacementRespons(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={'lg'}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => 1300 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>

      <Stack
        spacing={3}
        padding={4}
      >
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item>
            <Typography
              textAlign={'center'}
              variant='h5'
            >
              {`Введите id ${serchByTaskId ? 'заявки' : 'замены'}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setSerchByTaskId(!serchByTaskId)}
              variant='outlined'
            >
              Нет замены
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid
            item
            xs={9}
          >
            <TextField
              id='search-id'
              label={`id ${serchByTaskId ? 'заявки' : 'замены'}`}
              value={filterId}
              onChange={(e) => setFilterId(e.target.value)}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid
            item
            container
            xs={2}
          >
            <FilterButtons
              onCloseClick={handleClick}
              onSearchClick={onClickHandler}
            />
          </Grid>
        </Grid>
        <Grid>
          {replacementRespons && typeof replacementRespons === 'object' && (
            <Box
              key={`replacement-item-${replacementRespons.id}`}
              sx={{ p: 2, border: '1px solid black', marginY: 2 }}
            >
              <ReplacementViewItem
                replacementResponse={replacementRespons}
                handleOk={handleOk}
                serchByTaskId={serchByTaskId}
                taskId={filterId}
                swapByTaskId={swapByTaskId}
              />
            </Box>
          )}
          {replacementRespons !== null && !replacementRespons && (
            <Typography
              textAlign={'center'}
              variant='h5'
              paddingTop={3}
            >
              Ничего не найдено
            </Typography>
          )}
        </Grid>
      </Stack>
    </Dialog>
  );
};
