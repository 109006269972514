import { Paper, styled } from '@mui/material';

export const CallingMapStatiscDouhnutWrapper = styled('div')({
  width: '25%',
});
export const CallingMapStatiscLineWrapper = styled('div')({
  width: '85%',
  margin: 'auto',
  marginBottom: 30,
});
export const CallingMapStatiscDashBordWrapper = styled('div')({
  width: '90%',
  overflowX: 'auto',
  margin: 'auto',
  display: 'flex',
  gap: 5,
});
export const CallingMapStatiscControleWrapper = styled('div')({
  width: '85%',
  margin: 'auto',
  marginBottom: 25,
});
