import { IImage } from '@/dto/taskmap/task/TaskResultDto';
import { GetBase64File } from '../../services/FileService';
import { createStore } from '../../utils/createStore';

interface IImageDrawerStore {
  isOpen: boolean;
  url: string;
  ids: IImage[];
  index: number;

  onOpen: (ids: IImage[], index: number) => void;
  onClose: () => void;
  nextIndex: () => void;
  prevIndex: () => void;
}

export const useImageDrawerStore = createStore<IImageDrawerStore>(
  (set, get) => ({
    isOpen: false,
    url: '',
    ids: [],
    index: 0,

    onOpen: async (ids, index) => {
      const url = ids[index].url ?? (await GetBase64File(ids[index]?.id));
      set(() => ({
        isOpen: true,
        url: url,
        ids: [...ids],
        index: index,
      }));
    },
    onClose: () =>
      set(() => ({
        isOpen: false,
        url: '',
        ids: [],
        index: 0,
      })),

    nextIndex: async () => {
      const nextIndex = get().index === get().ids.length - 1 ? 0 : get().index + 1;
      const url = await GetBase64File(get().ids[nextIndex]?.id);
      set(() => ({
        url: url,
        index: nextIndex,
      }));
    },

    prevIndex: async () => {
      const prevIndex = get().index === 0 ? get().ids.length - 1 : get().index - 1;
      const url = await GetBase64File(get().ids[prevIndex]?.id);
      set(() => ({
        url: url,
        index: prevIndex,
      }));
    },
  }),
  'Image drawer'
);
