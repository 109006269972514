import React, { FC } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { style, Wrapper } from './Alert.styled';

interface Props {
  alertText: string;
  handleClose: () => void;
  open: boolean;
}

const AlertError: FC<Props> = ({ alertText, handleClose, open }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography
          id='modal-modal-title'
          variant='h6'
          component='h2'
        >
          {alertText}
        </Typography>
        <Wrapper>
          <Button
            variant='contained'
            onClick={() => {
              handleClose();
            }}
          >
            OK
          </Button>
        </Wrapper>
      </Box>
    </Modal>
  );
};

export default AlertError;
