import { BoxShadow } from '@/components/ui/BoxShadow';
import { styled, Popper, IconButton } from '@mui/material';

export const HelpFilterWrapper = styled(BoxShadow)({
  paddingRight: '5px',
  position: 'absolute',
  // width: 'calc(100% - 30px)',
  left: 0,
  top: 0,
  zIndex: 2,
});

export const HelpFilterRow = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
});

export const HelpFilterOpenButton = styled(BoxShadow)({
  padding: '2px',
  cursor: 'pointer',
  position: 'absolute',
  width: 30,
  right: 0,
  top: 0,
  zIndex: 1,
});

export const SearchIconWrapper = styled(IconButton)({
  alignSelf: 'flex-end',
  marginLeft: 'auto',
});

export const HelpFilterOpenButtonDiv = styled('div')({
  padding: '2px',
  cursor: 'pointer',
  position: 'absolute',
  width: 30,
  right: 0,
  top: 0,
  zIndex: 1,
});
