import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import CatalogPuEditor from '@/components/sections/CatalogsEditor/PuEditor';
import CatalogPuItem from '@/components/sections/CatalogsEditor/PuEditor/CatalogPuItem';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const CatalogPuEditorPage = () => {
  useAccess({
    access: [AccessEnum.Catalogs],
  });
  useMetaTitle('Приборы учета - редактирование');
  return <CatalogPuItem />;
};
export default CatalogPuEditorPage;
