import { ComplaintStage } from '@/services/ComplaintService/ComplaintServiceDto';
import { color } from '@/styles/mixins';

export const getBorderColor = (stageName?: ComplaintStage) => {
  switch (stageName) {
    case ComplaintStage.IN_PROGRESS:
      return color('muiBlue');
    case ComplaintStage.DUPLICATED:
    case ComplaintStage.COMPLETED:
      return color('pointGreen');
    default:
      return color('red1');
  }
};
