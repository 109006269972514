export enum FilterArgEnum {
  IN_LIST,
  NOT_IN_LIST,
  EQUAL,
  NOT_EQUAL,
  CONTAIN,
  MORE,
  LESS,
  INSTALLED,
  RANGE,
}

export interface PhotPath {
  path: string;
  title: string;
}
export interface ArgumentSelectType {
  argValue?: FilterArgEnum;
  argList?: FilterArgEnum[];
  onArgChange?: any;
  isBooleanHandler?: (isBoolean: boolean) => void;
}
