import { FC, useEffect } from 'react';
import { Grid, Stack, Typography, Box } from '@mui/material';
import CopyButton from '@/components/button/CopyButton';
import { TextField } from '@/components/fixUI';
import { useFormContext } from 'react-hook-form';

import { useGalleryData } from '@/components/Gallery/store';
import { IdPopup } from '../../panel/IdPopup';
import DateField from '@/components/DateField';
import { LetterResponse } from '@/dto/AdpuDto';
import { useCatalog } from '@/hooks/CatalogHook';

export const Letter: FC<LetterResponse> = ({
  id,
  letterType,
  imageList = [],
  dateDeparture,
  postalId,
  postalNumber,
}) => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { register } = useFormContext();
  const { setImageGroups } = useGalleryData();

  const handleDoubleClick = (e: any) => {
    e.stopPropagation();
    setImageGroups([{ title: `Письмо №${postalNumber}`, imageList: imageList }]);
  };

  return (
    <Grid
      item
      xs={12}
    >
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid #EFEFEF',
          background:
            'linear-gradient(0deg, #EFEFEF, #EFEFEF), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
          width: '100%',
          p: 2,
        }}
        onDoubleClick={handleDoubleClick}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Stack
              direction={'row'}
              sx={{ alignItems: 'center' }}
            >
              <Typography fontWeight={'bold'}>
                {getCatalogMapWithErr('letterType')?.get(letterType) ?? 'Неизвесный тип'}
              </Typography>
              <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                <IdPopup sx={{ color: 'gray' }}>
                  {id && (
                    <div>
                      <CopyButton textToCopy={id} />
                      id письма {id}
                    </div>
                  )}
                </IdPopup>
              </div>
            </Stack>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <TextField
              id='montageName'
              label='ШПИ'
              variant='standard'
              {...register(`${id}.postalId`, {
                value: postalId,
              })}
              disabled
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <TextField
              id='montageName'
              label='Номер письма'
              variant='standard'
              {...register(`${id}.postalNumber`, {
                value: postalNumber,
              })}
              disabled
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <DateField
              label='Дата отправления'
              defaultValue={dateDeparture}
              hookFormString={`${id}.dateDeparture`}
              disabled
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
