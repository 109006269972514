import React, { FC } from 'react';
import {
  FileImg,
  FileName,
  FileNameWrapper,
  FileWrapper,
} from '@/components/features/drive/file/File.styled';
import { IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DownloadFileById, DownloadFileByOtherIds, GetFiles } from '@/services/DriveService';
import FileSaver from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import { convertStr, getImgByType, getType } from '../utils';
import { useActionLog } from '@/hooks/ActionLogHook';
import { DriveItem } from '@/components/features/drive/utils';

interface IFile {
  item: DriveItem;
}

export const File: FC<IFile> = ({ item }) => {
  const { groupId, folderId } = useParams();
  const { catchError } = useActionLog();

  const download = (groupId: any, folderId: any) => {
    if (item?.id) {
      return DownloadFileById(item.id);
    } else {
      return GetFiles(groupId.split('_')[1], convertStr(folderId)).then((res) => {
        const ids = Object.keys(res.data).filter((elem) => elem != '');
        return DownloadFileByOtherIds(ids, convertStr(folderId));
      });
    }
  };

  const clickHandler = () => {
    if (groupId && folderId) {
      document.body.style.cursor = 'wait';
      download(groupId, folderId)
        .then((r) => {
          const type = r.headers['content-type'];
          return new Blob([r.data], { type: type });
        })
        .then((file) => FileSaver.saveAs(file, item.name))
        .catch((err) => {
          catchError('Ошибка скачивания', err);
        })
        .finally(() => {
          document.body.style.cursor = 'default';
        });
    }
  };
  return (
    <FileWrapper>
      <FileNameWrapper>
        <FileImg src={getImgByType(getType(item.name))} />
        <FileName>{item.name}</FileName>
      </FileNameWrapper>
      <IconButton
        onClick={clickHandler}
        title={'Скачать'}
        size='large'
      >
        <DownloadIcon color={'primary'} />
      </IconButton>
    </FileWrapper>
  );
};

export default File;
