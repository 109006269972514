import { color } from '@/styles/mixins';
import { IconButton, Stack, styled } from '@mui/material';

export const ExpendableToolsPopupTitle = styled('h2')({
  textAlign: 'center',
  fontSize: '32px',
  margin: '0 auto',
});

export const ExpendableToolsPopupIconButton = styled(IconButton)({
  padding: '4px',
  marginTop: 'auto',
});

interface ExpendableToolsPopupFormWrapperProps {
  disabled?: boolean;
}
interface ExpendableToolsPopupFormInputWrapperProps {
  width?: string;
}
export const ExpendableToolsPopupFormWrapper = styled('div')<ExpendableToolsPopupFormWrapperProps>(
  ({ disabled }) => ({
    background: disabled ? color('gray', 0.2) : '',
    pointerEvents: disabled ? 'none' : 'auto',
    opacity: disabled ? 0.6 : 1,
    borderRadius: '10px',
    padding: '5px',
    transition: '0.4s',
  })
);

export const ExpendableToolsPopupFormInputWrapper = styled(
  'div'
)<ExpendableToolsPopupFormInputWrapperProps>(({ width = '100%' }) => ({
  width,
}));

export const ExpendableToolsPopupColumn = styled(Stack)({
  gap: '16px',
});

export const ExpendableToolsPopupFormRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  padding: '10px 0',
});

export const ExpendableToolsPopupFormButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '30px',
  gap: '20px',
});
