import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import CustomerInteractionStatistics from '@/components/statistics/CustomerInteractionStatistics/CustomerInteractionStatistics';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const CustomerInteractionStatisticsPage = () => {
  useAccess({
    access: [
      AccessEnum.Statistic,
      AccessEnum.CallingMapOperator,
      AccessEnum.TeamManagerAndVoorman,
      AccessEnum.TeamManagerVoormanAndExecutor,
    ],
  });
  useMetaTitle(`Статистика взаимодействия с абонентом `);
  return <CustomerInteractionStatistics />;
};

export default CustomerInteractionStatisticsPage;
