import { usePnrPlanningStore } from '@/components/sections/PnrPlanning/store';
import { Grid } from '@mui/material';
import React from 'react';
import {
  PnrPlanningsCountMainTextWrapper,
  PnrPlanningsCountLoading,
  PnrPlanningsCountMainText,
  PnrPlanningsCountSecondaryText,
  PnrPlanningsCountWrapper,
  PnrPlanningsCountRightGrid,
} from './PnrPlanningsCount.styled';
import { useIsFetchingPlannings, usePlanedOrdersByType, useIsFetchingOrders } from './utils';
import { usePNRStore } from '@/components/features/pnr/store';

const PnrPlanningsCount = () => {
  const isFetchingPlannings = useIsFetchingPlannings();
  const isFetchingOrders = useIsFetchingOrders();

  const plannedCount = usePnrPlanningStore((state) => state.plannedCount);
  const { gatewayOrders, routerOrders } = usePlanedOrdersByType();

  const networkElementsWithoutEntranceCount = usePNRStore(
    (state) => state.networkElementsWithoutEntranceCount
  );

  return (
    <PnrPlanningsCountWrapper>
      <Grid
        container
        rowGap={0.5}
        columnGap={1}
        justifyContent='center'
      >
        <PnrPlanningsCountRightGrid
          item
          xs={5}
        >
          <PnrPlanningsCountMainText bigFontSize>Шлюз</PnrPlanningsCountMainText>
        </PnrPlanningsCountRightGrid>
        <PnrPlanningsCountRightGrid
          item
          xs={5}
        >
          <PnrPlanningsCountMainText bigFontSize>Роутер</PnrPlanningsCountMainText>
        </PnrPlanningsCountRightGrid>
        <PnrPlanningsCountMainTextWrapper
          item
          xs={12}
        >
          <PnrPlanningsCountSecondaryText>Подъезд не указан:</PnrPlanningsCountSecondaryText>
        </PnrPlanningsCountMainTextWrapper>
        <PnrPlanningsCountRightGrid
          item
          xs={5}
        >
          <PnrPlanningsCountMainText>
            {networkElementsWithoutEntranceCount.gateway}
          </PnrPlanningsCountMainText>
        </PnrPlanningsCountRightGrid>

        <PnrPlanningsCountRightGrid
          item
          xs={5}
        >
          <PnrPlanningsCountMainText>
            {networkElementsWithoutEntranceCount.router}
          </PnrPlanningsCountMainText>
        </PnrPlanningsCountRightGrid>

        <PnrPlanningsCountMainTextWrapper
          item
          xs={12}
          withTopBorder
        >
          <PnrPlanningsCountSecondaryText>План:</PnrPlanningsCountSecondaryText>
        </PnrPlanningsCountMainTextWrapper>

        <PnrPlanningsCountRightGrid
          item
          xs={5}
        >
          <PnrPlanningsCountLoading loading={isFetchingPlannings}>
            <PnrPlanningsCountMainText>{plannedCount?.gateway ?? 0}</PnrPlanningsCountMainText>
          </PnrPlanningsCountLoading>
        </PnrPlanningsCountRightGrid>

        <PnrPlanningsCountRightGrid
          item
          xs={5}
        >
          <PnrPlanningsCountLoading loading={isFetchingPlannings}>
            <PnrPlanningsCountMainText>{plannedCount?.router ?? 0}</PnrPlanningsCountMainText>
          </PnrPlanningsCountLoading>
        </PnrPlanningsCountRightGrid>

        <PnrPlanningsCountMainTextWrapper
          item
          xs={12}
          withTopBorder
        >
          <PnrPlanningsCountSecondaryText>Назначены заявки:</PnrPlanningsCountSecondaryText>
        </PnrPlanningsCountMainTextWrapper>

        <PnrPlanningsCountRightGrid
          item
          xs={5}
        >
          <PnrPlanningsCountLoading loading={!!isFetchingOrders}>
            <PnrPlanningsCountMainText>{gatewayOrders?.length ?? 0}</PnrPlanningsCountMainText>
          </PnrPlanningsCountLoading>
        </PnrPlanningsCountRightGrid>

        <PnrPlanningsCountRightGrid
          item
          xs={5}
        >
          <PnrPlanningsCountLoading loading={!!isFetchingOrders}>
            <PnrPlanningsCountMainText>{routerOrders?.length ?? 0}</PnrPlanningsCountMainText>
          </PnrPlanningsCountLoading>
        </PnrPlanningsCountRightGrid>
      </Grid>
    </PnrPlanningsCountWrapper>
  );
};

export default PnrPlanningsCount;
