import { IssuedStamp } from '../IssuedStamp/IssuedStamp';
import useSupplyStore from '../SupplyStore';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import shallow from 'zustand/shallow';
import { StoreHouseBillItemWrapper } from './StorehouseBillItem.styled';

export const StoreHouseBillItem = () => {
  const { billNumber } = useSupplyStore(
    (state) => ({
      billNumber: state.billNumber,
    }),
    shallow
  );

  useMetaTitle(`Накладная ${billNumber}`);
  return (
    <StoreHouseBillItemWrapper>
      <IssuedStamp tabType='archive' />
    </StoreHouseBillItemWrapper>
  );
};
