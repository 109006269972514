import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useComplaintStore } from '../store';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import {
  compliteComplaintEnergy,
  compliteComplaintOdpu,
  duplicatedComplaint,
  duplicatedComplaintOdpu,
  inProgressComplaint,
  inProgressComplaintOdpu,
  moveAfterResponsibleComplaintEnergy,
  moveToCallCenter,
  returnComplaint,
  returnToModeration,
  transferToResponsibleEnergy,
  setNotSecState,
} from '@/services/ComplaintService/ComplaintService';
import shallow from 'zustand/shallow';
import { Button, Grid, Stack } from '@mui/material';
import { ComplaintStage } from '@/services/ComplaintService/ComplaintServiceDto';
import { useComplaintsEditAccess } from '../roles';
import { isTypeEnergyService } from '../utils';

const ButtonsEnergy = ({
  responsibleId,
  response,
  executorId,
  plannedDate,
  consumerReadyToMeet,
  workType,
  setError,
}: {
  responsibleId?: string | null;
  response?: string;
  executorId?: string;
  plannedDate?: string;
  consumerReadyToMeet?: string;
  workType?: string | null;
  setError?: (flag: boolean) => void;
}) => {
  const hasAccessToEdit = useComplaintsEditAccess();

  const { selectedCompliant, fetchSingleComplaint } = useComplaintStore(
    (state) => ({
      selectedCompliant: state.selectedCompliant,
      fetchSingleComplaint: state.fetchSingleComplaint,
    }),
    shallow
  );
  const selectedCompliantId = selectedCompliant?.complaint?.base?.id;

  const { setIsInProgress } = useScreenHoldHook();
  const { addActionLog, fetchWithUserMessageFlagCatch } = useActionLog();

  const handleCallCenter = () => {
    setIsInProgress(true);
    moveToCallCenter(selectedCompliantId, { workType })
      .then((data) => {
        fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);

        addActionLog(ActionLogType.SUCCESS, 'Успешно');
      })
      .catch((error) => fetchWithUserMessageFlagCatch(error))
      .finally(() => setIsInProgress(false));
  };

  const handleReturnComplaint = () => {
    setIsInProgress(true);
    returnComplaint(selectedCompliantId, {})
      .then((data) => {
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
      })
      .catch((error) => fetchWithUserMessageFlagCatch(error))
      .finally(() => setIsInProgress(false));
  };

  const handleTransferToResponsible = () => {
    setIsInProgress(true);
    transferToResponsibleEnergy(selectedCompliantId, { responsibleId, workType })
      .then((data) => {
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
        setError && setError(false);
      })
      .catch((error) => {
        fetchWithUserMessageFlagCatch(error);
        setError && setError(true);
      })
      .finally(() => setIsInProgress(false));
  };
  const handleMoveAfterResponsible = () => {
    setIsInProgress(true);
    moveAfterResponsibleComplaintEnergy(selectedCompliantId, { consumerReadyToMeet })
      .then((data) => {
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
        setError && setError(false);
      })
      .catch((error) => {
        setError && setError(true);
        fetchWithUserMessageFlagCatch(error);
      })
      .finally(() => setIsInProgress(false));
  };

  const handleInProgressComplaint = () => {
    setIsInProgress(true);
    if (isTypeEnergyService(selectedCompliant?.complaint.type)) {
      inProgressComplaint(selectedCompliantId, {
        executorId,
        plannedDate,
      })
        .then((data) => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
          setError && setError(false);
        })
        .catch((error) => {
          setError && setError(true);
          fetchWithUserMessageFlagCatch(error);
        })
        .finally(() => setIsInProgress(false));
    } else {
      setIsInProgress(true);
      inProgressComplaintOdpu(selectedCompliantId, { responsibleId })
        .then((data) => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
          setError && setError(false);
        })
        .catch((error) => {
          setError && setError(true);

          fetchWithUserMessageFlagCatch(error);
        })
        .finally(() => setIsInProgress(false));
    }
  };

  const handleDuplicatedComplaint = () => {
    setIsInProgress(true);
    if (isTypeEnergyService(selectedCompliant?.complaint.type)) {
      duplicatedComplaint(selectedCompliantId, { response, workType })
        .then((data) => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
        })
        .catch((error) => fetchWithUserMessageFlagCatch(error))
        .finally(() => setIsInProgress(false));
    } else {
      duplicatedComplaintOdpu(selectedCompliantId, {})
        .then((data) => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
        })
        .catch((error) => {
          fetchWithUserMessageFlagCatch(error);
        })
        .finally(() => setIsInProgress(false));
    }
  };

  const handleCompliteComplaint = () => {
    setIsInProgress(true);
    if (isTypeEnergyService(selectedCompliant?.complaint.type)) {
      compliteComplaintEnergy(selectedCompliantId, { response })
        .then((data) => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
        })
        .catch((error) => {
          fetchWithUserMessageFlagCatch(error);
        })
        .finally(() => setIsInProgress(false));
    } else {
      setIsInProgress(true);
      compliteComplaintOdpu(selectedCompliantId, {})
        .then((data) => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
        })
        .catch((error) => {
          fetchWithUserMessageFlagCatch(error);
        })
        .finally(() => setIsInProgress(false));
    }
  };

  const handleNotSec = () => {
    setIsInProgress(true);
    setNotSecState(selectedCompliantId, { response, workType })
      .then(() => {
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
      })
      .catch((error) => fetchWithUserMessageFlagCatch(error))
      .finally(() => setIsInProgress(false));
  };

  const handleReturnToModeration = () => {
    setIsInProgress(true);
    returnToModeration(selectedCompliantId)
      .then(() => {
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
      })
      .catch((error) => fetchWithUserMessageFlagCatch(error))
      .finally(() => setIsInProgress(false));
  };

  const stage = selectedCompliant?.complaint.stage.name;
  return (
    <Stack>
      <Grid
        container
        xs={12}
        spacing={2}
        mt={3}
      >
        {(stage === ComplaintStage.MODERATED ||
          stage === ComplaintStage.MODERATED_AFTER_CC ||
          stage === ComplaintStage.MODERATED_AFTER_RESPONSIBLE) && (
          <Grid
            item
            xs={12}
            md={6}
          >
            <Button
              disabled={!hasAccessToEdit}
              size='small'
              variant='outlined'
              onClick={handleTransferToResponsible}
              fullWidth
            >
              Передать ответственному
            </Button>
          </Grid>
        )}
        {(stage === ComplaintStage.MODERATED || stage === ComplaintStage.MODERATED_AFTER_CC) && (
          <Grid
            item
            xs={12}
            md={6}
          >
            <Button
              disabled={!hasAccessToEdit}
              size='small'
              variant='outlined'
              fullWidth
              onClick={handleReturnComplaint}
            >
              вернуть жалобу
            </Button>
          </Grid>
        )}

        {stage === ComplaintStage.MODERATED && (
          <Grid
            item
            xs={12}
            md={6}
          >
            <Button
              disabled={!hasAccessToEdit}
              size='small'
              variant='outlined'
              onClick={handleCallCenter}
              fullWidth
            >
              передать в колл-центр
            </Button>
          </Grid>
        )}

        {stage === ComplaintStage.MODERATED && (
          <Grid
            item
            xs={12}
            md={6}
          >
            <Button
              disabled={!hasAccessToEdit}
              size='small'
              variant='outlined'
              fullWidth
              onClick={handleNotSec}
            >
              не относится к СЭК
            </Button>
          </Grid>
        )}

        {(stage === ComplaintStage.MODERATED || stage === ComplaintStage.MODERATED_AFTER_CC) && (
          <Grid
            item
            xs={12}
            md={6}
          >
            <Button
              disabled={!hasAccessToEdit}
              size='small'
              variant='outlined'
              fullWidth
              onClick={handleDuplicatedComplaint}
            >
              дубль
            </Button>
          </Grid>
        )}

        {(stage === ComplaintStage.MODERATED_AFTER_CC ||
          stage === ComplaintStage.MODERATED_AFTER_RESPONSIBLE) && (
          <Grid
            item
            xs={12}
            md={6}
          >
            <Button
              disabled={!hasAccessToEdit}
              size='small'
              variant='outlined'
              fullWidth
              onClick={handleCompliteComplaint}
            >
              Жалоба отработана
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        xs={12}
        spacing={2}
        mt={3}
      >
        {stage === ComplaintStage.TRANSFERRED_TO_RESPONSIBLE && (
          <Grid
            item
            xs={12}
            md={6}
          >
            <Button
              disabled={!hasAccessToEdit}
              size='small'
              variant='outlined'
              onClick={handleInProgressComplaint}
              fullWidth
            >
              Передать исполнителю
            </Button>
          </Grid>
        )}
        {stage === ComplaintStage.TRANSFERRED_TO_RESPONSIBLE && (
          <Grid
            item
            xs={12}
            md={6}
          >
            <Button
              disabled={!hasAccessToEdit}
              size='small'
              variant='outlined'
              onClick={handleMoveAfterResponsible}
              fullWidth
            >
              вернуть модератору
            </Button>
          </Grid>
        )}
        {stage === ComplaintStage.RETURNED && (
          <Grid
            item
            xs={12}
            md={6}
          >
            <Button
              disabled={!hasAccessToEdit}
              size='small'
              variant='outlined'
              fullWidth
              onClick={handleReturnToModeration}
            >
              вернуть модератору
            </Button>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

export default ButtonsEnergy;
