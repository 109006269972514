import { color } from '@/styles/mixins';
import { IconButton, Stack, styled } from '@mui/material';

export const ExternalConnectionPopupTitle = styled('h2')({
  textAlign: 'center',
  fontSize: '32px',
  margin: '0 auto',
});

export const ExternalConnectionPopupIconButton = styled(IconButton)({
  padding: '4px',
  marginTop: 'auto',
});
export const ExternalConnectionPopupIconButtonFix = styled(IconButton)({
  position: 'absolute',
  top: 8,
  right: 8,
});

interface ExternalConnectionPopupFormWrapperProps {
  disabled?: boolean;
}
interface ExternalConnectionPopupFormInputWrapperProps {
  width?: string;
}
export const ExternalConnectionPopupFormWrapper = styled(
  'div'
)<ExternalConnectionPopupFormWrapperProps>(({ disabled }) => ({
  background: disabled ? color('gray', 0.2) : '',
  pointerEvents: disabled ? 'none' : 'auto',
  opacity: disabled ? 0.6 : 1,
  borderRadius: '10px',
  padding: '5px',
  transition: '0.4s',
}));

export const ExternalConnectionPopupFormBorderWrapper = styled(ExternalConnectionPopupFormWrapper)(
  () => ({
    border: '1px solid #A9A9A9',
    padding: '20px',
    paddingTop: '32px',
    marginBottom: '16px',
    position: 'relative',
  })
);

export const ExternalConnectionPopupFormInputWrapper = styled(
  'div'
)<ExternalConnectionPopupFormInputWrapperProps>(({ width = '100%' }) => ({
  width,
}));

export const ExternalConnectionPopupColumn = styled(Stack)({
  gap: '16px',
});

export const ExternalConnectionPopupFormRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  padding: '10px 0',
});

export const ExternalConnectionPopupFormButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '30px',
  gap: '20px',
});
