import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createStore } from '@/utils/createStore';
export const REMOVE_KEY = `//${'div'}[text()="${'MUI X: Missing license key'}"]`;
export const xpath = REMOVE_KEY;

const useBlockKey = createStore(
  (set: any, get: any) => ({
    isBlocking: false,
    setIsBlocking: (block: boolean) => {
      set({ isBlocking: block });
    },
  }),
  'hackedDataGridPro'
);
function getElementByXpath(xp: string) {
  return document.evaluate(xp, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)
    .singleNodeValue;
}
export const useHackedDataGridPro = () => {
  const { pathname } = useLocation();
  const { setIsBlocking, isBlocking } = useBlockKey();
  useEffect(() => {
    const element = getElementByXpath(xpath);
    if (element) {
      element?.parentNode?.removeChild(element);
    }
    return () => setIsBlocking(!isBlocking);
  }, [pathname]);

  return useEffect(() => {
    const element = getElementByXpath(xpath);
    if (element) {
      element?.parentNode?.removeChild(element);
    }
  });
};
