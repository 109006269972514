import React, { FC, useEffect, useState } from 'react';
import { TextField } from '@/components/fixUI';
import { Grid, IconButton, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { PpoVruInputResponse } from '@/dto/taskmap/task/TaskResultDto';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@/components/fixUI';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useGalleryData } from '@/components/Gallery/store';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import PpoCableList from '../ppoCableList/PpoCableList';
import { PpoEnterInputHeader } from './PpoEnterInput.styled';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { deletePPOODPUInput } from '@/services/TaskService';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import PpoInputExpendableList from '../ppoInputExpendableList';
import PpoExternalConnection from '../ppoExternalConnection';

export interface PpoEnterInputProps {
  enter: PpoVruInputResponse;
  vruId: string;
  index: number;
  loadAll: any;
}

const formatData = (d: number | null | undefined) => (d ? new Date(d).getTime() : null);

export const PpoEnterInput: FC<PpoEnterInputProps> = (props) => {
  const { register, setValue } = useFormContext();
  const { catchError, addActionLog } = useActionLog();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { enter, vruId, index, loadAll } = props;
  setValue(`${vruId}.inputRequestList.${index}.id`, enter?.id);
  // setValue(`${vruId}.inputRequestList.${index}.inputNumber`, enter?.inputNumber);

  const [date, setDate] = useState(enter?.revisionOn);

  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);

  useEffect(() => {
    setValue(`${vruId}.inputRequestList.${index}.revisionOn`, formatData(date));
  }, [date]);

  const [isOpen, setIsOpenPopup] = useState<boolean>(false);
  const handleDelete = () => {
    setIsOpenPopup(false);
    deletePPOODPUInput(enter.id)
      .then((res) => {
        loadAll();
        addActionLog(ActionLogType.SUCCESS, 'Ввод успешно удален');
      })
      .catch((err) =>
        catchError(`Ошибка удаления Ввода. ${err?.response?.data?.message ?? ''}`, err)
      );
  };

  return (
    <>
      <PpoEnterInputHeader
        item
        xs={12}
      >
        <Typography variant='h5'>
          Ввод:{' '}
          <TextField
            id='tf-enter'
            variant='standard'
            {...register(`${vruId}.inputRequestList.${index}.inputNumber`, {
              value: enter?.inputNumber,
            })}
            sx={{ width: '100%', display: 'inline' }}
          />
          <IconButton onClick={() => setIsOpenPopup(true)}>
            <DeleteOutlineRoundedIcon />
          </IconButton>
        </Typography>
        <Grid
          display={'flex'}
          alignItems={'center'}
        >
          <PpoExternalConnection enter={enter} />
          <PpoInputExpendableList enter={enter} />
          <PpoCableList enter={enter} />
        </Grid>
      </PpoEnterInputHeader>
      <Grid
        item
        xs={2}
      >
        <AutocompleteValue
          title='Тип ввода'
          defaultValue={enter?.inputTypeId ? `${enter.inputTypeId}` : undefined}
          fieldName={`${vruId}.inputRequestList.${index}.inputTypeId`}
          values={getCatalogMapWithErr('ppoInputType')}
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        <TextField
          id='tf-revision'
          label='Исполнитель'
          variant='standard'
          {...register(`${vruId}.inputRequestList.${index}.revisionName`, {
            value: enter?.revisionName,
          })}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label='Дата'
            inputFormat='dd.MM.yyyy'
            value={date}
            onChange={setDate}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid
        item
        xs={2}
      >
        <AutocompleteValue
          title='Тех.решение'
          defaultValue={enter?.ppoTechDecisionId ? `${enter.ppoTechDecisionId}` : undefined}
          fieldName={`${vruId}.inputRequestList.${index}.ppoTechDecisionId`}
          values={getCatalogMapWithErr('ppoOdpuTechDecision')}
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        <AutocompleteValue
          title='Тип АЗ'
          defaultValue={enter?.protectionTypeId ? `${enter.protectionTypeId}` : undefined}
          fieldName={`${vruId}.inputRequestList.${index}.protectionTypeId`}
          values={getCatalogMapWithErr('protectionType')}
        />
      </Grid>

      <Grid
        item
        xs={2}
      />
      <Grid
        item
        xs={2}
      >
        <TextField
          id='tf-nominalTT'
          label='Проектируемый номинал ТТ'
          variant='standard'
          {...register(`${vruId}.inputRequestList.${index}.nominalTT`, {
            value: enter?.nominalTT,
          })}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        <AutocompleteValue
          title='Напряжение на фазе А'
          defaultValue={enter?.voltageAId ? `${enter.voltageAId}` : undefined}
          fieldName={`${vruId}.inputRequestList.${index}.voltageAId`}
          values={getCatalogMapWithErr('ppoVoltageState')}
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        <AutocompleteValue
          title='Напряжение на фазе B'
          defaultValue={enter?.voltageBId ? `${enter.voltageBId}` : undefined}
          fieldName={`${vruId}.inputRequestList.${index}.voltageBId`}
          values={getCatalogMapWithErr('ppoVoltageState')}
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        <AutocompleteValue
          title='Напряжение на фазе C'
          defaultValue={enter?.voltageCId ? `${enter.voltageCId}` : undefined}
          fieldName={`${vruId}.inputRequestList.${index}.voltageCId`}
          values={getCatalogMapWithErr('ppoVoltageState')}
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        <AutocompleteValue
          title='Номинал АЗ'
          defaultValue={enter?.protectionRateId ? `${enter.protectionRateId}` : undefined}
          fieldName={`${vruId}.inputRequestList.${index}.protectionRateId`}
          values={getCatalogMapWithErr('protectionDeviceRating')}
        />
      </Grid>
      <AccessPopup
        headerText={'После удаления восстановить данные будет невозможно'}
        titleText={'Продолжить?'}
        successText={'Да'}
        closeText={'Отменить'}
        onSuccess={handleDelete}
        onClose={() => setIsOpenPopup(false)}
        isOpen={isOpen}
      />
    </>
  );
};
