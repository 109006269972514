import { Checkbox, Stack } from '@mui/material';
import { ImageGroupView } from '../ImageGroupView/ImageGroupView';
import { useGalleryData } from '@/components/Gallery/store';
import { ImagePanelDownloadAllButton, ImagePanelText } from './ImagePanel.styled';
import { GetFileUrlByFileId } from '@/services/YodaRestService';
import { LabelCheckbox } from '@/components/inputs/labelCheckbox';
import React from 'react';
import { useImageNewScreen } from './useImageNewScreen';

export const ImagePanel = () => {
  const imageGroups = useGalleryData(({ imageGroups }) => {
    return imageGroups;
  });
  const { isNewScreen, setIsNewScreen } = useImageNewScreen();

  const allFilesPromise = imageGroups
    ?.map((group) =>
      group?.imageList?.map((image, index) => ({
        url: image.url ?? GetFileUrlByFileId(image.id),
        folder: group.title,
        fileName: `${index + 1}_${group.title}_${image.id}`,
      }))
    )
    .flat();

  return (
    <>
      {imageGroups?.length ? (
        <>
          <ImagePanelDownloadAllButton
            disabled={!allFilesPromise.length}
            buttonText='Скачать все фото'
            text='Скачать все фото'
            zipName='Архив_фотографий'
            downloadLinksPromises={allFilesPromise}
          />
          <LabelCheckbox
            value={isNewScreen}
            onChange={() => setIsNewScreen(!isNewScreen)}
            label={'Открывать фото в новой вкладке'}
            style={{ paddingBottom: 0, paddingTop: 0 }}
          />
          {imageGroups
            .sort((c, p) => (c.title > p.title ? 1 : -1))
            .map((t, i) => (
              <Stack
                key={`image-group-${i + t.title}`}
                spacing={1}
              >
                <ImageGroupView imageBox={t} />
              </Stack>
            ))}
        </>
      ) : (
        <ImagePanelText variant={'h4'}>
          Для отображения фото воспользуйтесь двойным кликом мышки, нажав на ту часть результата
          работ, фото которой вы хотите получить
        </ImagePanelText>
      )}
    </>
  );
};
