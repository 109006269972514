import './style.css';
import { Button } from '@mui/material';
import { useErrorBoundary } from 'react-error-boundary';
import {
  ErrorText,
  ErrorButtonsWrapper,
  ErrorFallbackFrontendVersion,
} from './ErrorFallback.styled';
import RefreshIcon from '@mui/icons-material/Refresh';
import TelegramIcon from '@mui/icons-material/Telegram';
import { ReactComponent as BabyYoda } from '@/images/icons/babyYoda.svg';

export function ErrorFallback() {
  const { resetBoundary } = useErrorBoundary();

  const handleTechSupport = () => {
    window.open('https://t.me/dvorsemen');
  };

  return (
    <div className='containerError'>
      <div className='innerError'>
        <BabyYoda className='img' />
        <ErrorText
          variant='h5'
          className='errorText'
        >
          Не так пошло что-то
        </ErrorText>
        <ErrorText
          variant='h5'
          className='errorText'
        >
          Повторить попытку или к поддержке обратиться вы должны
        </ErrorText>

        <ErrorFallbackFrontendVersion withSessionId />

        <ErrorButtonsWrapper>
          <Button
            startIcon={<RefreshIcon />}
            onClick={resetBoundary}
          >
            Повторить
          </Button>
          <Button
            startIcon={<TelegramIcon />}
            onClick={handleTechSupport}
          >
            Поддержка
          </Button>
        </ErrorButtonsWrapper>
      </div>
    </div>
  );
}
