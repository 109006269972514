import React from 'react';
import { Typography, Portal } from '@mui/material';
import { toFormateDate } from '@/utils/heplers';

export interface DetailedInfoItem {
  title: string;
  items: string | string[] | number | number[];
}

interface DetailedInfoItemProps extends DetailedInfoItem {
  index: number;
}

const COLORS = [
  'rgba(121, 85, 72, 0.25)',
  'rgba(247, 68, 55, 0.25)',
  'rgba(155, 39, 176, 0.25)',
  'rgba(64, 81, 181, 0.25)',
  'rgba(4, 169, 243, 0.25)',
  'rgba(0, 150, 136, 0.25)',
  'rgba(76, 176, 81, 0.25)',
  'rgba(255, 235, 60, 0.25)',
];

const TEXT_MAX_LENGTH = 45;
const BLUE_TITLE = '#0a58ca';

export const DetailedInfoItem = ({ title, items, index }: DetailedInfoItemProps) => {
  const color = COLORS[(index + 1) % COLORS.length];
  const detailedInfoItemTextStyles = {
    borderRadius: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    border: '1px solid gray',
  };

  const textConvert = (text: string | number) => {
    //Случай с timestamp
    if (typeof text === 'number') {
      return toFormateDate(new Date(text));
    }

    return text;
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: 15,
        flexWrap: 'wrap',
        padding: 5,
        justifyContent: 'flex-start',
      }}
    >
      {Array.isArray(items) ? (
        <>
          {!!items.length && (
            <>
              {title && (
                <Typography
                  variant='subtitle2'
                  sx={{ fontWeight: 500, color: BLUE_TITLE }}
                >
                  {title}:
                </Typography>
              )}
              {items?.map((itemText, index) => (
                <Typography
                  variant='subtitle2'
                  key={index}
                  sx={detailedInfoItemTextStyles}
                >
                  {textConvert(itemText)}
                </Typography>
              ))}
            </>
          )}
        </>
      ) : (
        <>
          {items && (
            <>
              {title && (
                <Typography
                  variant='subtitle2'
                  sx={{ fontWeight: 500, color: BLUE_TITLE }}
                >
                  {title}:
                </Typography>
              )}
              <Typography sx={detailedInfoItemTextStyles}>{textConvert(items)}</Typography>
            </>
          )}
        </>
      )}
    </div>
  );
};
