import { styled, Button } from '@mui/material';

export const MeterChangeForRevisionFiltersWrapper = styled('div')({
  margin: '5px 0',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  gap: 10,
});

export const MeterChangeForRevisionButton = styled(Button)({
  minWidth: 211,
});
