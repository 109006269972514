import { NetworkMapItem } from './NetworkMapItem';
import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { YMaps, Map as MMap, Polygon, RulerControl, FullscreenControl } from 'react-yandex-maps';
import { useUspdMapStore } from './store';

const MapNetwork = ({
  handleMapRef,
  networkResponse,
  networkHiddenList,
  isEditMode,
  handleDragEnd,
  setSelectedPoint,
  selectedPoint,
  setIsEditMode,
  polygonRef,
}: any) => {
  const { isDrawing, polygonCoords } = useUspdMapStore((state) => ({
    isDrawing: state.isDrawing,
    polygonCoords: state.polygonCoords,
  }));

  useEffect(() => {
    if (polygonRef.current && isDrawing) {
      polygonRef?.current?.editor?.startDrawing();
    }
  }, [polygonRef.current, isDrawing]);

  return (
    <Grid
      item
      height={'85vh'}
      xs={9}
    >
      <YMaps
        preload={true}
        query={{
          ns: 'use-load-option',
          load: 'Map,Placemark,control.ZoomControl,geoObject.addon.balloon,geoObject.addon.hint,',
          coordorder: 'longlat',
        }}
      >
        <MMap
          instanceRef={(ref) => ref && handleMapRef(ref)}
          modules={[
            'geoQuery',
            'layout.ImageWithContent',
            'templateLayoutFactory',
            'geoObject.addon.editor',
          ]}
          defaultState={{
            center: [37.57, 55.75],
            zoom: 5,
            controls: ['zoomControl'],
          }}
          width={'100%'}
          height={'100%'}
          options={{ autoFitToViewport: 'always', minZoom: 2 }}
          defaultOptions={{ minZoom: 2 }}
        >
          <RulerControl />
          <FullscreenControl />

          {networkResponse &&
            [...networkResponse]
              .filter((t) => !networkHiddenList.includes(t.type))
              .map((t, index) => (
                <NetworkMapItem
                  key={`map-item-${Math.random()}`}
                  item={t}
                  index={index}
                  type={t.type}
                  isEditMode={isEditMode}
                  handleDragEnd={handleDragEnd}
                  setSelectedPoint={setSelectedPoint}
                  selectedPoint={selectedPoint}
                  setIsEditMode={setIsEditMode}
                />
              ))}
          <Polygon
            instanceRef={(ref: any) => {
              if (ref) {
                polygonRef.current = ref;
              }
            }}
            options={{
              editorDrawingCursor: 'crosshair',
              fillColor: '#0000FF',
              fillOpacity: 0.1,
              strokeColor: '#0000FF',
              strokeWidth: 2,
              draggable: false,
              outline: true,
              autoPanInDrawing: true,
            }}
            //@ts-ignore
            geometry={polygonCoords || []}
          />
        </MMap>
      </YMaps>

      {networkResponse?.length && (
        <Typography>всего найдено заявок: {networkResponse?.length}</Typography>
      )}
    </Grid>
  );
};

export default MapNetwork;
