import { CardContent, styled, Box } from '@mui/material';

export const StatisticCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
});

export const StatisticCardMediaWrapper = styled(Box)({
  width: '217px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  marginRight: '10px',
});
