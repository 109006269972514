import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import {
  CatalogsBrowse,
  ICatalogsBrowse,
} from '@/components/sections/CatalogsBrowse/CatalogBrowse';
import { CatalogTypes } from '@/components/sections/CatalogsBrowse/constants';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import { makeTitle } from './utils';

type CatalogsPageProps = ICatalogsBrowse;

const CatalogsPage = (props: CatalogsPageProps) => {
  let hasAccess = false;
  if (props.type === CatalogTypes.PU) {
    hasAccess = useAccess({
      access: [AccessEnum.CatalogsMeter],
    });
  } else {
    hasAccess = useAccess({
      access: [AccessEnum.Catalogs],
    });
  }
  useMetaTitle(makeTitle(props.type));
  return <>{hasAccess && <CatalogsBrowse {...props} />}</>;
};

export default CatalogsPage;
