import { Loading } from '@/components/ui/Loading';
import styled from '@emotion/styled';

export const CatalogEditorTitleLoading = styled(Loading)({
  width: '520px !important',
});

export const CatalogEditorButtonsLoading = styled(Loading)({
  width: '310px !important',
});
