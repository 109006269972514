import { StatisticsContractorDto, StatisticsResponsebleDto } from './types';
import React, { useRef } from 'react';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { MultiSelect } from '../../../inputs/MultiSelectNew';
import { Tab, Tabs, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectFilterOptType } from '../../../filter/MultySelectFilter';
import { ApiFindSingleContractor } from '@/services/YodaRestService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { getElementAtEvent, Chart } from 'react-chartjs-2';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import TableComponentResponsebleStatistic from './TableComponentResponsebleStatistic';
import SmallExtraTable from './SmallExtraTable/SmallExtraTable';
import { getDataFromFilter } from '../../../inputs/MultiSelectNew/utils';
import { generateDetailedInfo } from '../../../button/DetailedInfo/utils';
import { useSaveFilterButton } from '../../../filter/FilterButtons/utils';
import { useAllContractQuery } from '@/hooks/useQuery/useAllContractQuery';
import {
  STATISTICS_RESPONSEBLE_COLUMNS,
  STATISTICS_RESPONSEBLE_EXTRA_COLUMNS,
  STATISTICS_RESPONSEBLE_COLUMN_GROUPING_MODEL,
  STATISTICS_RESPONSEBLE_CHART_OPTIONS,
  getDataBar,
} from './utils';
import {
  StatisticsResponsebleFiltersWrapper,
  StatisticsResponsebleWrapper,
} from './StatisticsResponseble.styled';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Filter {
  contractIds?: string[];
}
interface ContractorFilter {
  contractIds?: string[];
  contractorIds?: string[] | null;
  path?: string;
}
interface StatisticsResponsebleProps {
  header: string;
  load: boolean;
  data: StatisticsResponsebleDto[];
  updateFunction: (filter: Filter) => void;
}

const SAVE_FILTER_KEY = 'StatisticsResponseble';

export const StatisticsResponseble: React.FC<StatisticsResponsebleProps> = ({
  header,
  load,
  data,
  updateFunction,
}) => {
  const { getFilters } = useSaveFilterButton(SAVE_FILTER_KEY);

  const barDataFiltered = data.filter((d) => d.contractorName !== 'Итого');
  const labels = barDataFiltered.map((c) => c.contractorName);
  const dataBar = getDataBar(labels, barDataFiltered);

  const chartRef: any = useRef();
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);
    setShowExtraTable(false);
  };

  const onClickBar = (event: any) => {
    try {
      const elem = getElementAtEvent(chartRef?.current, event);
      if (!elem[0]?.datasetIndex) return; // 0 индекс для Liner ничего не делать
      fetchContractorTable(data[elem[0].index], formatPath(elem[0]?.datasetIndex.toString()));
      setShowExtraTable(true);
    } catch (error) {
      setShowExtraTable(false);
    }
  };

  const methods = useForm();

  const { handleSubmit } = methods;
  const contractListResponse = useAllContractQuery();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [contractorTableData, setContractorTableData] = React.useState<StatisticsContractorDto[]>(
    []
  );
  const [showExtraTable, setShowExtraTable] = React.useState<boolean>(false);
  const [isloadinTable, setIsLodingTable] = React.useState<boolean>(false);
  const subTileText = useRef<null | string>(null);
  const { catchError } = useActionLog();

  const onSubmitSearch = () => {
    const filter: Filter = {};
    if (selectedContractList) {
      filter.contractIds = getDataFromFilter(selectedContractList, 'value');
    }
    updateFunction(filter);
  };

  const fetchContractorTable = (contractor: StatisticsResponsebleDto, path: string) => {
    const contractorFilter: ContractorFilter = {};
    if (contractor?.contractorName === 'Итого') {
      contractorFilter.contractorIds = data
        .filter((contractor) => contractor.contractorId)
        .map((c) => c.contractorId);
    }
    if (contractor.contractorName !== 'Итого' && !contractor.contractorId) {
      contractorFilter.contractorIds = null;
    }
    if (contractor.contractorName !== 'Итого' && contractor.contractorId) {
      contractorFilter.contractorIds = [contractor.contractorId];
    }
    if (selectedContractList.length) {
      contractorFilter.contractIds = getDataFromFilter(selectedContractList, 'value');
    }

    if (path) {
      contractorFilter.path = path;
    }
    setIsLodingTable(true);
    ApiFindSingleContractor(contractorFilter)
      .then(({ data }) => setContractorTableData(() => [data]))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', {}))
      .finally(() => setIsLodingTable(false));
  };

  const formatPath = (str: string) => {
    if (str === 'Всего получено заявок') {
      subTileText.current = 'Всего получено заявок';
      return 'all';
    }
    if (str === 'Установлено' || str === '1') {
      subTileText.current = 'Установлено';
      return 'installed';
    }
    if (str === 'ТКО после обращения' || str === '2') {
      subTileText.current = 'ТКО после обращения';
      return 'tkos';
    }
    if (str === 'Остаток' || str === '3') {
      subTileText.current = 'Остаток';
      return 'balance';
    }
    subTileText.current = '';
    return '';
  };

  function onCellClick(param: any) {
    if (param.colDef.groupPath[0].trim()) {
      const title: string = param?.colDef?.groupPath[0];
      const contractor: StatisticsResponsebleDto = param?.row;
      fetchContractorTable(contractor, formatPath(title));
      setShowExtraTable(true);
    }
  }

  const onResetClick = () => setSelectedContractList([]);

  const detailedInfo = generateDetailedInfo([selectedContractList, 'Контракт']);

  const onSelectSavedFilter = (filterKey: string) => {
    const savedFilters = getFilters<SelectFilterOptType[]>(filterKey);
    savedFilters.selectedContractList && setSelectedContractList(savedFilters.selectedContractList);
  };

  return (
    <StatisticsResponsebleWrapper elevation={6}>
      <p className={'headerPageStatistics'}>{header}</p>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
      >
        <Tab label='Таблица' />
        <Tab
          label='Графики'
          disabled={!data.length}
        />
      </Tabs>
      {
        // Содержимое для вкладки "Таблица"
        activeTab === 0 && (
          <>
            <Grid>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmitSearch)}>
                  <StatisticsResponsebleFiltersWrapper>
                    <MultiSelect
                      label={'Контракт'}
                      value={selectedContractList}
                      onlyInListArgument
                      options={contractListResponse}
                      onChange={(sel: SelectFilterOptType[]) => setSelectedContractList(sel)}
                    />

                    <FilterButtons
                      info={detailedInfo}
                      onSearchClick={() => handleSubmit(onSubmitSearch)}
                      onCloseClick={onResetClick}
                      disable={load}
                      saveFilter={{
                        filterType: SAVE_FILTER_KEY,
                        infoToSave: { selectedContractList },
                        selectCallback: onSelectSavedFilter,
                      }}
                    />
                  </StatisticsResponsebleFiltersWrapper>
                </form>
              </FormProvider>
              {load ? (
                <LoadSpinner />
              ) : (
                <TableComponentResponsebleStatistic
                  data={data}
                  columns={STATISTICS_RESPONSEBLE_COLUMNS}
                  setShowExtraTable={setShowExtraTable}
                  showExtraTable={showExtraTable}
                  onCellClick={onCellClick}
                  isloadinTable={isloadinTable}
                  subTileText={subTileText}
                  columnGroupingModel={STATISTICS_RESPONSEBLE_COLUMN_GROUPING_MODEL}
                  extraColumns={STATISTICS_RESPONSEBLE_EXTRA_COLUMNS}
                  contractorTableData={contractorTableData}
                />
              )}
            </Grid>
          </>
        )
        // Содержимое для вкладки "Графики"
      }
      {activeTab === 1 && (
        <>
          {showExtraTable && (
            <SmallExtraTable
              subTileText={subTileText?.current}
              setShowExtraTable={setShowExtraTable}
              contractorTableData={contractorTableData}
              extraColumns={STATISTICS_RESPONSEBLE_EXTRA_COLUMNS}
              loading={isloadinTable}
            />
          )}
          {!!data.length && (
            <div style={{ width: '95%', overflowX: 'auto', margin: 'auto' }}>
              <div style={{ width: labels.length > 25 ? '95%' : '75%', margin: 'auto' }}>
                <Chart
                  options={STATISTICS_RESPONSEBLE_CHART_OPTIONS}
                  type='bar'
                  data={dataBar}
                  onClick={onClickBar}
                  ref={chartRef}
                />
              </div>
            </div>
          )}
        </>
      )}
    </StatisticsResponsebleWrapper>
  );
};
