import { FC } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { color } from '@/styles/mixins';

interface ISaveButton {
  disabled?: boolean;
  onSave: () => void;
  fontSize?: any;
}

export const SaveButton: FC<ISaveButton> = ({ onSave, disabled, fontSize }) => {
  return (
    <>
      {disabled ? (
        <SaveIcon sx={{ color: 'gray', fontSize: fontSize || 22, opacity: '50%' }} />
      ) : (
        <SaveIcon
          onClick={onSave}
          sx={{ color: color('muiBlue'), fontSize: fontSize || 22, cursor: 'pointer' }}
        />
      )}
    </>
  );
};
