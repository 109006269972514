import { AxiosResponse } from 'axios';
import { HOST } from '@/services/YodaRestService';
import { axiosGet, axiosGetExlByPost, axiosGetPdfByPost, axiosGetZip } from '@/services/helpers';

export async function GetTechPris(): Promise<AxiosResponse<Map<string, string>, any>> {
  const url = HOST + '/rest/electric-networks-enterprise/tech-pris';
  return axiosGet(url);
}

export async function GetRes(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/electric-networks-enterprise/${id}/res`;
  return axiosGet(url);
}

export async function GetSmrDate(
  id: string,
  offset: number,
  limit: number
): Promise<AxiosResponse<any, any>> {
  const url =
    HOST + `/rest/electric-networks-enterprise/${id}/smr-dates?offset=${offset}&limit=${limit}`;
  return axiosGet(url);
}

export async function GetFiles(id: string, sqlDate: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/electric-networks-enterprise/${id}/all-files/${sqlDate}`;
  return axiosGet(url);
}

export async function DownloadFiles(
  energyCompanyId: string,
  sqlDate: string,
  meterChangeIds: string[]
): Promise<any> {
  const url =
    HOST +
    `/rest/report/zip?energyCompanyId=${energyCompanyId}&date=${sqlDate}&meterChangeIds=${meterChangeIds.join(
      ';'
    )}`;
  return axiosGetZip(url);
}

export async function DownloadFileByOtherIds(ids: string[], sqlDate: string): Promise<any> {
  const url = HOST + `/rest/report/stolby/reestr-adpu/${sqlDate}`;
  return axiosGetExlByPost(url, ids);
}

export async function DownloadFileById(objectId: string): Promise<any> {
  const url = HOST + `/rest/report/adpu`;
  return axiosGetPdfByPost(url, {
    objectId,
    reportTemplate: 'Исполнительная документация столбы',
  });
}
