import { DeliveryPointGeo } from '@/components/maps/ppoMap/dto';

export const DeliveryPointBalloon = (geoObject: DeliveryPointGeo, smrTaskId?: string) => {
  return smrTaskId
    ? `
    <div class="map__delivery-point-balloon">
        <a href="#" class="delivery-point-balloon-link"
                id="delivery-point-balloon_id_${geoObject.id}">
          редактировать ППО
        </a>
        <br/>
        <a href='/task/edit/${smrTaskId}' target="_blank" class="delivery-point-balloon-link"
                id="delivery-point-balloon_id_edit_${geoObject.id}">
          редактировать СМР
        </a>
    </div>
  `
    : `<div class="map__delivery-point-balloon">
        <a href="#" class="delivery-point-balloon-link"
           id="delivery-point-balloon_id_${geoObject.id}">
          редактировать ППО
        </a>
      </div>
    `;
};
