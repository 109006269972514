import { replaceNonNums } from '@/components/storehouse/SupplyPUComponent/utils';
import { BaseUI } from '@/types/ui';
import { Button, TextField } from '@mui/material';
import React from 'react';
import { PnrDndEditModalInputLabel } from './PnrDndEditModal.styled';

import { PnrDndEditModalFormType } from './utils';

export interface PnrDndEditModalFormValues {
  floorCount: number;
  flatByEntranceRange: number;
  flatSinceWith: number;
}

export interface PnrDndEditModalFormProps extends BaseUI {
  type?: PnrDndEditModalFormType;
  onSubmit: (formValues: PnrDndEditModalFormValues) => void;
  onReset: () => void;
  forcedFloorCount?: number;
}

const PnrDndEditModalForm = ({
  type,
  className,
  onSubmit,
  forcedFloorCount,
  onReset,
}: PnrDndEditModalFormProps) => {
  const [floorCount, setFloorCount] = React.useState<string>(forcedFloorCount?.toString() ?? '');
  const [flatByEntranceRange, setFlatByEntranceRange] = React.useState<string>('');
  const [flatSinceWith, setFlatSinceWith] = React.useState<string>('');

  const handleIntString = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    return replaceNonNums(e.target.value) ?? '';
  };
  const handleSubmit = () => {
    onSubmit({
      floorCount: parseInt(floorCount),
      flatByEntranceRange: parseInt(flatByEntranceRange),
      flatSinceWith: parseInt(flatSinceWith),
    });
  };

  return (
    <div className={className}>
      <PnrDndEditModalInputLabel>Кол-во этажей</PnrDndEditModalInputLabel>
      <TextField
        value={floorCount}
        variant='standard'
        onChange={(e) => setFloorCount(handleIntString(e))}
        fullWidth
        autoFocus={type === PnrDndEditModalFormType.ENTRANCE}
        autoComplete='off'
      />

      <PnrDndEditModalInputLabel>Кол-во квартир на этаже</PnrDndEditModalInputLabel>
      <TextField
        value={flatByEntranceRange}
        variant='standard'
        onChange={(e) => setFlatByEntranceRange(handleIntString(e))}
        fullWidth
        autoComplete='off'
      />
      <PnrDndEditModalInputLabel>Номера начинаются с</PnrDndEditModalInputLabel>
      <TextField
        value={flatSinceWith}
        variant='standard'
        onChange={(e) => setFlatSinceWith(handleIntString(e))}
        fullWidth
        autoComplete='off'
      />
      <Button
        variant='contained'
        disabled={!floorCount.length || !flatByEntranceRange.length || !flatSinceWith.length}
        onClick={handleSubmit}
        sx={{ flexShrink: '0' }}
      >
        Распределить
      </Button>
      <Button
        variant='contained'
        onClick={onReset}
        sx={{ flexShrink: '0' }}
      >
        Сбросить
      </Button>
    </div>
  );
};

export default PnrDndEditModalForm;
