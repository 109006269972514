import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { RadioItem } from '@/components/RadioGroupForm/types';
import {
  PlannedInstalation,
  PlannedInstalationsMap,
} from '@/components/sections/PnrPlanning/store';
import { PlannedInstalationsDto } from '@/dto/taskmap/Dto';
import { useCatalog } from '@/hooks/CatalogHook';
import { createFilterOptions } from '@mui/material';
import { useMemo } from 'react';
import { HouseLevelFloorType, NetworkPlanningTypes } from '../PnrElementsInfo/utils';

export const REQUIRED = 'Обязательное поле';
export const FLOOR_OPTION_VALUE = 'этаж';

export type MONTAGE_PLACE_VALUES =
  | HouseLevelFloorType.ATTIC
  | HouseLevelFloorType.FLOOR
  | HouseLevelFloorType.BASEMENT
  | null;

export const SINGLE_PLANNING_RADIO_ITEM = {
  value: 'singleOrder',
  label: 'Создать 1 общую заявку',
};

export const MULTIPLE_PLANNING_ORDER_ITEM = {
  value: 'multipleOrders',
  label: 'Создать отдельные заявки на каждую позицию',
};

export const PLANNING_RADIO_ITEMS: RadioItem[] = [
  SINGLE_PLANNING_RADIO_ITEM,
  MULTIPLE_PLANNING_ORDER_ITEM,
];

export const checkIsValidPlannings = (plannings: PlannedInstalation[]) => {
  const nonDeletedPlanings = plannings.filter((planning) => !planning.deleted);
  if (!nonDeletedPlanings.length) return false;
  const validPlannings = nonDeletedPlanings.filter((planning) => {
    if (
      !planning?.houseLevel?.entranceNumber?.toString().length ||
      !planning.equipmentTypeFilter?.value ||
      !planning.montagePlace?.value ||
      !planning.planningTypeFilter?.value ||
      planning.montagePlace?.value === HouseLevelFloorType.UNDEFINED
    )
      return false;
    else {
      if (
        planning.montagePlace?.value === FLOOR_OPTION_VALUE &&
        !planning.houseLevel.floorNumber?.toString().length
      ) {
        return false;
      }
      return true;
    }
  });

  return nonDeletedPlanings.length === validPlannings.length;
};

export const checkAllMontagePlaces = (plannings?: PlannedInstalation[]) => {
  const nonDeletedPlanings = plannings?.filter((planning) => !planning.deleted);
  if (!nonDeletedPlanings?.length) return false;
  const validPlannings = nonDeletedPlanings?.filter((planning) => {
    if (!planning.montagePlace?.value) return false;
    else {
      return true;
    }
  });

  return nonDeletedPlanings.length === validPlannings.length;
};

export const convertPlanningsToPlanningsDto = (
  plannings: PlannedInstalationsMap,
  neededPlaningType?: NetworkPlanningTypes | null
) => {
  let neededPlanings: PlannedInstalation[] = [];
  if (neededPlaningType) {
    neededPlanings = plannings.get(neededPlaningType) || [];
  } else {
    neededPlanings = Array.from(plannings.values()).flat();
  }
  return neededPlanings
    ?.filter((planning) => !planning.deleted)
    .map((planning) => ({
      ...planning,
      comment: planning?.comment || null,
      equipmentTypeId: planning.equipmentTypeFilter?.value || null,
      type: planning.planningTypeFilter?.value || null,

      entranceNumber: planning?.houseLevel?.entranceNumber,
      floorNumber: planning?.houseLevel?.floorNumber,
      floorType: planning?.houseLevel?.floorType,
    }));
};

export const FILTER_OPTIONS = createFilterOptions<SelectFilterOptType>({
  matchFrom: 'any',
  limit: 50,
});

export const usePlanningsTaskTypes = () => {
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const allTaskTypes = getCatalogMapWithErr('taskType');
  const filteredTaskTypes = useMemo(() => {
    if (
      allTaskTypes.get(PlanningsTaskTypes.NETWORKELEMENTS) &&
      allTaskTypes.get(PlanningsTaskTypes.NETWORK_ELEMENT_ODPU)
    ) {
      return [
        {
          label: allTaskTypes.get(PlanningsTaskTypes.NETWORKELEMENTS) as string,
          value: PlanningsTaskTypes.NETWORKELEMENTS,
        },
        {
          label: allTaskTypes.get(PlanningsTaskTypes.NETWORK_ELEMENT_ODPU) as string,
          value: PlanningsTaskTypes.NETWORK_ELEMENT_ODPU,
        },
      ];
    } else {
      return [];
    }
  }, [allTaskTypes]);

  return filteredTaskTypes;
};

enum PlanningsTaskTypes {
  NETWORKELEMENTS = 'NETWORKELEMENTS',
  NETWORK_ELEMENT_ODPU = 'NETWORK_ELEMENT_ODPU',
}
