import { Box, Grid, IconButton, InputBase, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { VruInputResponse } from '@/dto/taskmap/task/TaskResultDto';
import { OdpuItem } from './OdppuItem';
import { getSMRInputListImageGroup } from '../panel/utils';
import { useGalleryData } from '@/components/Gallery/store';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { deleteODPUInput, getWarranty, updateODPUInput } from '@/services/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { TextField } from '@/components/fixUI';
import moment from 'moment';
import { useStatus } from '../panel/store';
import { FormProvider, useFormContext } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import { WarrantyWorkType } from '@/dto/taskmap/Dto';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import CopyButton from '@/components/button/CopyButton';
import { IdPopup } from '../panel/IdPopup';
import TransformerAmperage from '@/components/Transformer/TransformerAmperage';
import WarrantyPanel from '../panel/WarrantyPanel';

interface VruInputItemProps {
  response: VruInputResponse;
  updateRes: any;
  odpuScheduledDateOn?: any;
  ids?: {
    executorId?: string;
    id?: string;
    contractorId?: string;
    vruId?: string;
    taskId?: string;
    contractId?: string;
  };
  updateReplacementResponse?: () => void;
  optionsAutomaticBreaker?: Map<string, string>;
}

export const VruInputItem = (props: VruInputItemProps) => {
  const onlyShow = useTaskEditorOnlyShow();
  const { catchError, addActionLog } = useActionLog();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { updateReplacementResponse, ids, optionsAutomaticBreaker } = props;
  const vruInputResponse = props.response;
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);
  const methods = useFormContext();
  const dataToSend = vruInputResponse?.id;
  const [warrantyResponses, setWarranty] = useState<WarrantyWorkType[]>([]);

  const updateWarranty = () => {
    props.ids?.taskId &&
      vruInputResponse?.id &&
      getWarranty(props.ids.taskId, vruInputResponse.id)
        .then((res) => {
          setWarranty(res.data);
        })
        .catch((err) => {
          catchError(`Ошибка при обновлении гарантии. ${err?.response?.data?.message ?? ''}`, err);
        });
  };

  useEffect(() => {
    updateWarranty();
  }, []);

  const { isDisabledAll } = useStatus((state) => ({
    isDisabledAll: state.isDisabledAll,
  }));
  const handleClick = (e: any) => {
    e.stopPropagation();
    setImageGroups(getSMRInputListImageGroup(vruInputResponse?.replacementResponse));
  };
  const handleDelete = () => {
    setIsOpen(false);
    deleteODPUInput(vruInputResponse?.id)
      .then((res) => {
        props.updateRes(res.data);
        addActionLog(ActionLogType.SUCCESS, 'Ввод успешно удален');
      })
      .catch((err) => {
        catchError(`Ошибка при удалении ввода. ${err?.response?.data?.message ?? ''}`, err);
      });
  };

  const onSave = (data: any) => {
    if (vruInputResponse) {
      updateODPUInput(vruInputResponse?.id, data[dataToSend])
        .then((data) => {
          addActionLog(ActionLogType.SUCCESS, 'Примечание изменено');
        })
        .catch((err) => {
          catchError(`Ошибка изменения примечания. ${err?.response?.data?.message ?? ''}`, err);
        });
    }
  };

  return (
    <Box
      onDoubleClick={handleClick}
      sx={{
        borderRadius: '10px',
        border: '1px solid #EFEFEF',
        background:
          'linear-gradient(0deg, #EFEFEF, #EFEFEF), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        width: '100%',
        p: 2,
      }}
    >
      <AccessPopup
        headerText={'После удаления восстановить данные будет невозможно'}
        titleText={'Продолжить?'}
        successText={'Да'}
        closeText={'Отменить'}
        onSuccess={handleDelete}
        disabledSuccess={onlyShow}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
      />
      <Grid
        container
        spacing={1}
        item
        direction={'column'}
      >
        <Grid
          item
          container
          direction={'row'}
          spacing={2}
          style={{ alignItems: 'flex-end' }}
        >
          <Grid
            item
            xs={12}
            paddingTop={5}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant='body1'>
              {vruInputResponse?.title}
              <IconButton onClick={() => setIsOpen(true)}>
                <DeleteOutlineRoundedIcon />
              </IconButton>
            </Typography>
            <IdPopup title={'id'}>
              <>
                <CopyButton textToCopy={vruInputResponse?.id} />
                {vruInputResponse?.id}
              </>
            </IdPopup>
          </Grid>

          <Grid
            item
            xs={2}
          >
            <TextField
              id='tf-montage-place'
              label='Плановая дата'
              variant='standard'
              value={
                props.odpuScheduledDateOn
                  ? moment(new Date(props.odpuScheduledDateOn)).format('DD.MM.YYYY')
                  : ''
              }
              sx={{ width: '100%' }}
              disabled={isDisabledAll()}
            />
          </Grid>
          <Grid
            item
            xs={2}
          >
            <TextField
              id='tf-montage-place'
              label='Дата передачи в ЦР ИСУ'
              variant='standard'
              value={
                vruInputResponse.transferredInDevelopmentCenterIsuOn
                  ? moment(new Date(vruInputResponse.transferredInDevelopmentCenterIsuOn)).format(
                      'DD.MM.YYYY'
                    )
                  : ''
              }
              sx={{ width: '100%' }}
              disabled={isDisabledAll()}
            />
          </Grid>
          <Grid
            item
            xs={2}
          >
            <TextField
              id='tf-montage-place'
              label='Дата КС'
              variant='standard'
              value={
                vruInputResponse.ksAssignedOn
                  ? moment(new Date(vruInputResponse.ksAssignedOn)).format('DD.MM.YYYY')
                  : ''
              }
              sx={{ width: '100%' }}
              disabled={isDisabledAll()}
            />
          </Grid>
          <Grid
            item
            xs={2}
          >
            <TextField
              id='tf-nominalTT'
              label='Проектируемый номинал ТТ'
              variant='standard'
              value={vruInputResponse?.nominalTT}
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
        </Grid>

        {vruInputResponse?.replacementResponse &&
          vruInputResponse.replacementResponse.map((t) => (
            <Grid
              item
              key={`grid-odpu-result-${t.id}`}
            >
              <OdpuItem
                vruInput={vruInputResponse}
                odpu={t}
                ids={props.ids}
                updateReplacementResponse={updateReplacementResponse}
              />
            </Grid>
          ))}
        {warrantyResponses &&
          [...warrantyResponses]
            .sort(
              (a: WarrantyWorkType, b: WarrantyWorkType) =>
                (b.workDoneAt as unknown as number) - (a.workDoneAt as unknown as number)
            )
            .map((warranty: WarrantyWorkType) => (
              <Grid
                key={warranty.id}
                item
                xs={12}
              >
                <WarrantyPanel
                  ids={props.ids}
                  warrantyResponse={warranty}
                  updateWarranty={updateWarranty}
                  optionsAB={optionsAutomaticBreaker}
                />
              </Grid>
            ))}
        <>
          <Box sx={{ m: 1, p: 1, border: '1px solid silver', borderRadius: '10px' }}>
            <InputBase
              {...methods.register(`${dataToSend}.claimNote`, {
                value: vruInputResponse?.claimNote,
              })}
              sx={{ mb: 0.3, width: '100%' }}
              multiline
              minRows={1}
              placeholder={'Примечания'}
            />

            <Grid
              container
              item
              direction='row'
              justifyContent='end'
            >
              <Grid>
                {!onlyShow && (
                  <SaveIcon
                    onClick={methods?.handleSubmit(onSave)}
                    sx={{
                      color: 'gray',
                      fontSize: 22,
                      cursor: 'pointer',
                      ml: 2,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      </Grid>
      <TransformerAmperage
        id={vruInputResponse.id}
        type={'vru-input'}
      />
    </Box>
  );
};
