import React from 'react';
import { useMutation } from 'react-query';
import {
  Box,
  TextField,
  createTheme,
  Grid,
  ThemeProvider,
  useTheme,
  Paper,
  Autocomplete,
  CircularProgress,
} from '@mui/material';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as locales from '@mui/material/locale';
import { HouseDataBrowseDto, HouseDataFilterParam } from '@/dto/taskmap/Dto';
import {
  ApiCountOfHouseBrowseData,
  ApiFindHouseBrowseData,
  ApiFindSuggestionsAddressForPNR,
} from '@/services/YodaRestService';
import { FilterArgEnum, SingleLineFilter } from '@/components/filter/MultySelectFilter';
import { useActionLog } from '@/hooks/ActionLogHook';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { HousePnrBrowseInputsWrapper, HousePnrBrowseWrapper } from './HousePnrBrowse.styled';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import HousePnrBrowseEditAddressModal from '../modals/HousePnrBrowseEditAddressModal/HousePnrBrowseEditAddressModal';
import HousePnrBrowseMergeAddressModal from '../modals/HousePnrBrowseMergeAddressModal/HousePnrBrowseMergeAddressModal';
import HousePnrBrowseDataGrid from './HousePnrBrowseDataGrid';

export const HousePnrBrowse = () => {
  const methods = useForm();
  const { register, handleSubmit, setValue, watch, getValues } = methods;
  const { catchError } = useActionLog();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [loadingSuggestions, setLoadingSuggestions] = React.useState<boolean>(false);
  const [houseData, setHouseData] = React.useState<HouseDataBrowseDto[]>([]);
  const [houseDataCount, setHouseDataCount] = React.useState<number>(0);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly string[]>([]);
  const [selectedValue, setSelectedValue] = React.useState<string | null>(null);

  const [filterParam, setFilterParam] = React.useState<HouseDataFilterParam>({});
  const refetchBrowse = () => {
    const currentData = getValues();
    onSubmitSearch(currentData);
  };

  const theme = useTheme();

  const themeWithLocale = createTheme(theme, locales['ruRU']);

  const onResetClick = () => {
    setValue('address', '');
    setValue('fias', '');
    setSelectedValue(null);
  };
  const onSubmitSearch = (data: any) => {
    const filtParam: HouseDataFilterParam = data;
    if (filtParam.address && filtParam.address.length > 0) {
      filtParam.addressArg = FilterArgEnum.CONTAIN;
    } else {
      filtParam.addressArg = undefined;
    }
    setFilterParam(filtParam);
    setPage(0);
    mutFindHouseData.mutate(filtParam);
  };

  React.useEffect(() => {
    mutFindHouseData.mutate(filterParam);
  }, [page, pageSize]);

  React.useEffect(() => {
    if (inputValue === '') {
      setOptions(selectedValue ? [selectedValue] : []);
      return undefined;
    }
    const timeOutId = setTimeout(() => findSuggestionsAddress(inputValue), 1000);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [inputValue]);

  const findSuggestionsAddress = (address: string) => {
    setLoadingSuggestions(true);
    ApiFindSuggestionsAddressForPNR(address)
      .then(function (value) {
        let newOptions: readonly string[] = [];
        if (value) {
          newOptions = value.data;
        }

        setOptions(newOptions);
      })
      .catch((err) => catchError('Ошибка во время поиска адресов', err))
      .finally(() => setLoadingSuggestions(false));
  };

  const mutFindHouseData = useMutation<void, any, HouseDataFilterParam>((filter) => {
    const offset = page * pageSize;
    filter.offset = offset;
    filter.limit = pageSize;
    ApiCountOfHouseBrowseData(filter)
      .then((res) => setHouseDataCount(res.data))
      .catch((error) => catchError('Ошибка во время получения количества данных', error));
    return ApiFindHouseBrowseData(filter)
      .then((res) => setHouseData(res.data))
      .catch((error) => catchError('Ошибка во время получения данных', error));
  });

  const detailedInfo = generateDetailedInfo(
    [selectedValue ?? '', 'Адрес'],
    [watch('fias'), 'ФИАС']
  );
  return (
    <Box sx={{ padding: '20px' }}>
      <ThemeProvider theme={themeWithLocale}>
        <HousePnrBrowseWrapper>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitSearch)}>
              <Paper
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  padding: '15px',
                  position: 'relative',
                }}
              >
                <HousePnrBrowseInputsWrapper>
                  <Controller
                    name='address'
                    render={({ field }) => (
                      <Autocomplete
                        id='address-auto-complete'
                        sx={{ width: '50%' }}
                        getOptionLabel={(option) => option}
                        filterOptions={(x) => x}
                        options={options}
                        loading={loadingSuggestions}
                        loadingText={<CircularProgress size={15} />}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        value={selectedValue}
                        onChange={(event: any, newValue: string | null) => {
                          setOptions(newValue ? [newValue, ...options] : options);
                          setSelectedValue(newValue);
                          field.onChange(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...register('address')}
                            variant='standard'
                            label='Адрес'
                          />
                        )}
                        renderOption={(props, option) => {
                          const parts = option;

                          return (
                            <li {...props}>
                              <Grid
                                container
                                alignItems='center'
                              >
                                <Grid item>
                                  <Box sx={{ color: 'text.secondary', mr: 2 }} />
                                </Grid>
                                <Grid
                                  item
                                  xs
                                >
                                  <span>{parts}</span>
                                </Grid>
                              </Grid>
                            </li>
                          );
                        }}
                      />
                    )}
                  />

                  <SingleLineFilter
                    registerName='fias'
                    defaultArg={FilterArgEnum.EQUAL}
                    label='Фиас'
                    params={{ type: 'text' }}
                    argList={[FilterArgEnum.EQUAL, FilterArgEnum.INSTALLED]}
                  />
                </HousePnrBrowseInputsWrapper>
                <div>
                  <FilterButtons
                    onSearchClick={() => handleSubmit(onSubmitSearch)}
                    onCloseClick={onResetClick}
                    info={detailedInfo}
                  />
                </div>
              </Paper>
            </form>
          </FormProvider>
          <HousePnrBrowseDataGrid
            houseData={houseData}
            houseDataCount={houseDataCount}
            pageSize={pageSize}
            page={page}
            setPage={setPage}
            setPageSize={setPageSize}
            loading={mutFindHouseData.isLoading}
          />

          <HousePnrBrowseEditAddressModal refetchBrowse={refetchBrowse} />
          <HousePnrBrowseMergeAddressModal refetchBrowse={refetchBrowse} />
        </HousePnrBrowseWrapper>
      </ThemeProvider>
    </Box>
  );
};
