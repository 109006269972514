import React from 'react';
import { DeliveryPoint } from '@/components/DeliveryPoint';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';

const DeliveryPointPage = () => {
  useAccess({
    access: [AccessEnum.DeliveryPointScreen],
  });
  return <DeliveryPoint />;
};
export default DeliveryPointPage;
