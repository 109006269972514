import NewGallery from '@/components/features/NewGallery/NewGallery';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import React from 'react';

const GalleryPage = () => {
  useMetaTitle(`Галерея`);
  return <NewGallery />;
};

export default GalleryPage;
