import React, { useEffect, useState } from 'react';
import { LinearProgress, Pagination } from '@mui/material';
import { SupplyBillBrowseDto } from '@/dto/taskmap/Dto';
import { useActionLog } from '@/hooks/ActionLogHook';
import SupplyBillBrowseItem from '../SupplyBillBrowseItem/SupplyBillBrowseItem';
import {
  SupplyBillApiGetSupplyBills,
  SupplyBillApiGetSupplyBillsCount,
} from '@/services/SupplyBillService';
import {
  SupplyBillBrowseItemWrapper,
  SupplyBillBrowseLinearProgressWrapper,
  SupplyBillBrowsePaginationWrapper,
} from './SupplyBillBrowse.styled';
import { useMutation } from 'react-query';
import { SupplyBillFilterParam, SupplyBillSearchFilter } from '@/dto/SupplyBillServiceDto';
import { PAGE_SIZE, useStoreHouseFilter } from './utils';

import SupplyBillBrowseForm from './SupplyBillBrowseForm';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useSupplyBillBrowseFilter } from './store';

export const SupplyBillBrowse = () => {
  const { catchError } = useActionLog();
  const navigate = useNavigate();

  const { getFilterValues } = useStoreHouseFilter();

  const [result, setResult] = useState<SupplyBillBrowseDto[]>([]);
  const [count, setCount] = useState<number>(0);

  const { page, setPage } = useSupplyBillBrowseFilter(
    (state) => ({
      page: state.page,
      setPage: state.setPage,
    }),
    shallow
  );

  const updateData = (filt?: SupplyBillFilterParam) => {
    const tmpFilter = filt ? filt : getFilterValues();
    const pageNumber = page - 1;

    mutGetSupplyBillsCount.mutate(tmpFilter);
    mutGetSupplyBills.mutate({ filter: tmpFilter, page: pageNumber, size: PAGE_SIZE });
  };

  const mutGetSupplyBillsCount = useMutation<void, any, SupplyBillFilterParam>((filter) => {
    return SupplyBillApiGetSupplyBillsCount(filter)
      .then(({ data }) => setCount(data))
      .catch((error) => catchError('Возникла ошибка при загрузке количества накладных', error));
  });

  const mutGetSupplyBills = useMutation<void, any, SupplyBillSearchFilter>((filter) => {
    return SupplyBillApiGetSupplyBills(filter)
      .then(({ data }) => setResult(data))
      .catch((error) => catchError('Возникла ошибка при загрузке накладных', error));
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => setPage(value);

  useEffect(() => {
    updateData();
  }, [page]);

  const handleDoubleClick = (billId: any) => {
    navigate(`/storehouse/${billId}`);
  };

  return (
    <>
      <SupplyBillBrowseForm
        updateData={updateData}
        isFetching={mutGetSupplyBills.isLoading}
      />
      <SupplyBillBrowseItemWrapper disabled={mutGetSupplyBills.isLoading}>
        <SupplyBillBrowseLinearProgressWrapper>
          {mutGetSupplyBills.isLoading && <LinearProgress />}
        </SupplyBillBrowseLinearProgressWrapper>
        {result?.map((elem) => (
          <SupplyBillBrowseItem
            key={elem.id}
            onDoubleClick={() => handleDoubleClick(elem.id)}
            updateData={updateData}
            {...elem}
          />
        ))}
      </SupplyBillBrowseItemWrapper>
      <SupplyBillBrowsePaginationWrapper>
        <Pagination
          count={Math.ceil(count / PAGE_SIZE)}
          shape='rounded'
          color='primary'
          sx={{ margin: '15px' }}
          page={page}
          onChange={handleChange}
          disabled={mutGetSupplyBills.isLoading}
        />
      </SupplyBillBrowsePaginationWrapper>
    </>
  );
};
