import { styled } from '@mui/material';
import { color } from '@/styles/mixins';

export const ZoomUIButtonContainer = styled('div')(({ theme }) => ({
  background: color('trueWhite'),
  zIndex: theme.zIndex.drawer + 1,
  borderRadius: 5,
  width: 'fit-content',
  position: 'relative',
}));

interface ZoomUIScaleContainerProps {
  scale: number;
  rotate: number;
}

export const ZoomUIScaleContainer = styled('div')<ZoomUIScaleContainerProps>(
  ({ scale, rotate, theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transform: `scale(${scale}) rotate(${rotate}deg)`,
    width: 'fit-content',
    cursor: 'move',
  })
);
