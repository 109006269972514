import { styled } from '@mui/material/styles';
import DownloadAllButton from '@/components/button/DownloadAllButton/DownloadAllButton';
import { Typography } from '@mui/material';
import { color } from '@/styles/mixins';

export const ImagePanelDownloadAllButton = styled(DownloadAllButton)({
  marginLeft: 'auto',
});

export const ImagePanelText = styled(Typography)({
  textAlign: 'center',
  padding: '40px',
  color: color('gray'),
  lineHeight: '54px',
  fontWeight: 500,
});
