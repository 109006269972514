import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { color } from '@/styles/mixins';

export const SMRBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 15,

  padding: '8px 14px',
  border: `0.5px solid ${color('chronologyLine')}`,
  borderRadius: 5,
  backgroundColor: color('trueWhite'),
});
