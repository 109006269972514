import React, { FC, useEffect, useState } from 'react';
import { useCatalog } from '@/hooks/CatalogHook';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { Button, Grid } from '@mui/material';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { getNextPpoState, nextPpoState } from '@/services/TaskService';
import { useFormContext } from 'react-hook-form';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { VruStatusPopup } from '@/components/popups/vruStatusPopup';
import { AccessController } from '@/components/common/AccessController';

interface OdpuNextStageButtonProps {
  taskId: string;
}
export const OdpuNextStageButton: FC<OdpuNextStageButtonProps> = ({ taskId }) => {
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [nextStage, setNextStage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { catchError, addActionLog } = useActionLog();
  const { setValue } = useFormContext();

  const getNextState = () =>
    getNextPpoState(taskId)
      .then(({ data }) => setNextStage(data))
      .catch((err) =>
        catchError(`Ошибка получения Этапа. ${err?.response?.data?.message ?? ''}`, err)
      );

  useEffect(() => {
    getNextState();
  }, []);

  const getStageTitle = () => getCatalogMapWithErr('odpuStage').get(nextStage) ?? '';

  const onSubmit = () => {
    setIsLoading(true);
    nextPpoState(taskId)
      .then(({ data }) => {
        addActionLog(
          ActionLogType.SUCCESS,
          `Переведено ${getCatalogMapWithErr('odpuStage').get(data)}`
        );
        setValue('odpuInfoRequest.odpuStage', data);
        getNextState();
      })
      .catch((err) =>
        catchError(
          `Ошибка перевода в ${getStageTitle()}. ${err?.response?.data?.message ?? ''}`,
          err
        )
      )
      .finally(() => {
        setIsOpenPopup(false);
        setIsLoading(false);
      });
  };

  return (
    <Grid
      item
      xs={12}
    >
      <AccessController access={[AccessEnum.ShowPPOStageChange]}>
        <Button onClick={() => setIsOpenPopup(true)}>{`Перевести в ${getStageTitle()}`}</Button>
      </AccessController>
      <AccessPopup
        onSuccess={onSubmit}
        isOpen={isOpenPopup}
        onClose={() => setIsOpenPopup(false)}
        successText={'Перевести'}
        headerText={`Перевести в ${getCatalogMapWithErr('odpuStage').get(nextStage) || ''}`}
        titleText={'Действие невозможно отменить. Продолжить?'}
        isLoading={isLoading}
      />
    </Grid>
  );
};
