import { ApiResponse } from '@/types/api';
import axios from 'axios';
import { YungaItemResponse } from './dto/response';

const YUNGA_SENDER_HOST = 'https://yunga-sender.sec2.ru/yunga';

export async function findAllByParam(
  id?: string,
  page?: number,
  limit?: number
): ApiResponse<YungaItemResponse[]> {
  const params = {
    id: id,
    page: page,
    limit: limit,
  };
  return axios.get(YUNGA_SENDER_HOST, {
    params: params,
  });
}

export async function count(id?: string): ApiResponse<number> {
  const params = { id: id };
  const url = `${YUNGA_SENDER_HOST}/count`;
  return axios.get(url, {
    params: params,
  });
}

export async function findCountByStatus(status: string): ApiResponse<number> {
  const url = `${YUNGA_SENDER_HOST}/${status}/count`;
  return axios.get(url);
}
