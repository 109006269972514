import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import {
  SupplyStatisticCellAdditionalInfoRow,
  SupplyStatisticCellAdditionalInfoWrapper,
  SupplyStatisticExpandIcon,
  SupplyStatisticCellPopover,
  SupplyStatisticCellCount,
} from './SupplyStatistic.styled';
import { useAnchorElement } from '@/hooks/useAnchorElement';

export interface SupplyStatisticCellProps {
  cellParams: GridRenderCellParams<string>;
}

const SupplyStatisticCell = ({ cellParams }: SupplyStatisticCellProps) => {
  const { anchorEl, handleOpen, handleClose, open } = useAnchorElement();

  const cellCount = cellParams.row?.detailData[cellParams.field]?.count;
  const cellData = cellParams.row?.detailData[cellParams.field]?.data;
  const cellDataEntries: [string, number][] = cellData ? Object.entries(cellData) : [];
  const withAdditionalInfo = !!cellDataEntries.length;

  return (
    <>
      <SupplyStatisticCellCount
        onClick={handleOpen}
        withAdditionalInfo={withAdditionalInfo}
      >
        <span>{cellCount ?? 0}</span>
        {withAdditionalInfo && (
          <SupplyStatisticExpandIcon
            expanded={open}
            color='info'
          />
        )}
      </SupplyStatisticCellCount>
      <SupplyStatisticCellPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SupplyStatisticCellAdditionalInfoWrapper>
          {cellDataEntries?.map(([key, value]) => (
            <SupplyStatisticCellAdditionalInfoRow key={key}>
              <span>{key}</span>
              <span>{value}</span>
            </SupplyStatisticCellAdditionalInfoRow>
          ))}
        </SupplyStatisticCellAdditionalInfoWrapper>
      </SupplyStatisticCellPopover>
    </>
  );
};

export default React.memo(SupplyStatisticCell);
