import { Grid, Stack, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NetworkMapType, NetworkResponse } from './UspdDto';
import CopyButton from '@/components/button/CopyButton';
import { GetUSPDXlsx } from '@/services/YodaRestService';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useActionLog } from '@/hooks/ActionLogHook';
import { downLoadExcel } from '@/components/features/complaints/ComplaintReportButton/utils';

type SelectedPointProps = {
  selectedPoint: NetworkResponse;
  onCloseSelectedPoint: () => void;
};

const SelectedPoint = ({ onCloseSelectedPoint, selectedPoint }: SelectedPointProps) => {
  const { setIsInProgress, isInProgress } = useScreenHoldHook();
  const { fetchCatch } = useActionLog();

  const downloadReportBySinglePoint = () => {
    setIsInProgress(true);
    GetUSPDXlsx({ networkPollingUspdId: selectedPoint.id })
      .then((res: any) => {
        downLoadExcel(res, `report-USPD-single-point-${selectedPoint.id ?? ''}`);
      })
      .catch((error) => {
        fetchCatch(error);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };
  return (
    <Stack
      spacing={2}
      mb={2}
    >
      <Grid container>
        <Grid
          item
          xs={11}
        >
          <Typography
            textAlign={'center'}
            variant='h6'
          >
            информация о точке:
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
        >
          <CloseIcon
            onClick={onCloseSelectedPoint}
            style={{ cursor: 'pointer' }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Typography variant='subtitle2'>EMEI: {selectedPoint?.imei}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display={'flex'}
          gap={1}
          alignItems={'center'}
        >
          <Typography variant='subtitle2'>ID: {selectedPoint?.networkElementId}</Typography>
          <CopyButton
            onlyIcon={true}
            textToCopy={selectedPoint?.networkElementId}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography variant='subtitle2'>Тип: {NetworkMapType[selectedPoint?.type]}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography variant='subtitle2'>
            Количество подключеных ПУ: {selectedPoint?.connectedCount ?? 0}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            disabled={isInProgress}
            onClick={downloadReportBySinglePoint}
          >
            выгрузить отчет о точке
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SelectedPoint;
