import { ExtraConnectionResponse, PpoCableResponse } from '@/dto/taskmap/task/TaskResultDto';
import { createStore } from '@/utils/createStore';

interface IExternalConnectionPopup {
  isExternalConnectionPopupOpen: boolean;
  setIsExternalConnectionPopupOpen: (isOpen: boolean) => void;

  tmpExternalConnection: ExtraConnectionResponse;
  externalConnection: ExtraConnectionResponse[];
  ppoVruInputId: string;
  reloadCounter: number;
  setInputExternalConnectionList: (
    externalConnection: ExtraConnectionResponse[],
    ppoVruInputId: string
  ) => void;

  addExtraConnection: (externalConnection: ExtraConnectionResponse) => void;
  updateExtraConnection: (externalConnection: ExtraConnectionResponse) => void;
  deleteExtraConnection: (externalConnectionId: string) => void;
  forceReload: () => void;

  addExtraConnectionCabel: (externalConnectionId: string, cabel: PpoCableResponse) => void;
  deleteExtraConnectionCabel: (externalConnectionId: string, cabelId: string) => void;
  deleteAllExtraConnectionCabel: (externalConnectionId: string) => void;

  editeTmpExternalConnection: (tmpExternalConnection: ExtraConnectionResponse) => void;
  clearTmpExternalConnection: () => void;
}

const defaultTmpExternalConnection: ExtraConnectionResponse = {
  id: 'new',
};

const defaultValue: IExternalConnectionPopup = {
  tmpExternalConnection: defaultTmpExternalConnection,
  ppoVruInputId: '',
  isExternalConnectionPopupOpen: false,
  externalConnection: [],
  reloadCounter: 0,
  setIsExternalConnectionPopupOpen: () => null,
  setInputExternalConnectionList: () => null,
  addExtraConnection: () => null,
  updateExtraConnection: () => null,
  deleteExtraConnection: () => null,
  addExtraConnectionCabel: () => null,
  deleteExtraConnectionCabel: () => null,
  deleteAllExtraConnectionCabel: () => null,

  forceReload: () => null,
  editeTmpExternalConnection: () => null,
  clearTmpExternalConnection: () => null,
};

export const useExternalConnectionPopup = createStore<IExternalConnectionPopup>(
  (set, get) => ({
    ...defaultValue,

    setIsExternalConnectionPopupOpen: (isOpen) => set({ isExternalConnectionPopupOpen: isOpen }),
    setInputExternalConnectionList: (externalConnection, ppoVruInputId) =>
      set({
        externalConnection: [{ id: 'new' }, ...externalConnection],
        ppoVruInputId: ppoVruInputId,
      }),

    addExtraConnection: (externalConnection: ExtraConnectionResponse) =>
      set({ externalConnection: [...get().externalConnection, externalConnection] }),

    updateExtraConnection: (externalConnection: ExtraConnectionResponse) =>
      set({
        externalConnection: [
          ...get().externalConnection.map((connection) =>
            connection.id === externalConnection.id ? externalConnection : connection
          ),
        ],
      }),

    deleteExtraConnection: (externalConnectionId: string) => {
      set({
        externalConnection: [
          ...get().externalConnection.filter(
            (exConnection) => exConnection.id !== externalConnectionId
          ),
        ],
      });
    },

    addExtraConnectionCabel: (externalConnectionId: string, cabel: PpoCableResponse) =>
      set({
        externalConnection: get().externalConnection.map((elem) => {
          if (elem.id === externalConnectionId) {
            return {
              ...elem,
              ppoCableResponseList: [
                ...(elem?.ppoCableResponseList ? elem.ppoCableResponseList : []),
                cabel,
              ],
            };
          } else {
            return elem;
          }
        }),
      }),

    deleteExtraConnectionCabel: (externalConnectionId: string, cabelId: string) =>
      set({
        externalConnection: get().externalConnection.map((elem) => {
          if (elem.id === externalConnectionId && elem?.ppoCableResponseList) {
            return {
              ...elem,
              ppoCableResponseList: elem.ppoCableResponseList.filter(
                (cabel) => cabel.id !== cabelId
              ),
            };
          } else {
            return elem;
          }
        }),
      }),

    deleteAllExtraConnectionCabel: (externalConnectionId: string) =>
      set({
        externalConnection: get().externalConnection.map((elem) => {
          if (elem.id === externalConnectionId && elem?.ppoCableResponseList) {
            return {
              ...elem,
              ppoCableResponseList: [],
            };
          } else {
            return elem;
          }
        }),
      }),

    forceReload: () => set(() => ({ reloadCounter: get().reloadCounter + 1 })),
  }),
  'ExternalConnection popup'
);
