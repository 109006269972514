import React, { FC, useEffect } from 'react';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { Grid, Typography } from '@mui/material';
import { DeliveryPointResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { isChangeInput } from './utils';
import { useFormContext } from 'react-hook-form';

interface IFormPUNew {
  keyStr: string;
  meter?: DeliveryPointResponse;
  disabledAll?: boolean;
}

export const FormPUNew: FC<IFormPUNew> = ({ meter, keyStr, disabledAll = false }) => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(`${keyStr}.newPuType`, `${meter?.newPuType}`);
    setValue(`${keyStr}.puConnectType`, `${meter?.puConnectType}`);
    setValue(`${keyStr}.isLockerNeeded`, `${meter?.isLockerNeeded}`);
  }, [meter]);

  return (
    <Grid
      item
      xs={12}
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant={'body1'}>Устанавливаемый ПУ</Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Тип устанавливаемого ПУ'
          sx={{ width: '100%' }}
          defaultValue={meter?.newPuType}
          fieldName={`${keyStr}.newPuType`}
          values={getCatalogMapWithErr('newPuType')}
          disabled={disabledAll}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Тип передачи данных'
          sx={{ width: '100%' }}
          defaultValue={meter?.puConnectType}
          fieldName={`${keyStr}.puConnectType`}
          values={getCatalogMapWithErr('puConnectType')}
          disabled={disabledAll}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Шкаф необходим'
          sx={{ width: '100%' }}
          fieldName={`${keyStr}.isLockerNeeded`}
          defaultValue={meter?.isLockerNeeded == null ? undefined : `${meter?.isLockerNeeded}`}
          values={isChangeInput}
          disabled={disabledAll}
        />
      </Grid>
    </Grid>
  );
};
