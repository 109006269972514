import React, { FC } from 'react';

interface ICableBackground {
  color: string;
}

export const CableBackground: FC<ICableBackground> = ({ color }) => {
  return (
    <svg
      viewBox='0 0 30 48'
      style={{ width: '30px', height: '48px' }}
    >
      <path
        d='M0.234375 0.25L14.6719 1.98438L29.9062 0.25V39.8594L14.6719 42.3906L0.234375 39.8594V0.25Z'
        fill={color}
      />
    </svg>
  );
};
