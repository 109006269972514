import { HouseEntranceShemeEntranceCube } from '@/components/sections/HouseEntranceSheme/HouseEntranceSheme.styled';
import { IconButton, Tooltip, Popover } from '@mui/material';
import React from 'react';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useAnchorElement } from '@/hooks/useAnchorElement';
import {
  PnrDndEditEntranceModalForm,
  PnrDndEditModalEntranceHeaderInner,
} from './PnrDndEditModal.styled';
import { PnrDndEditModalFormValues } from './PnrDndEditModalForm';
import {
  ExistedFlat,
  existedFlatsByNumber,
  generateExistedFlatsErrorMessage,
  PnrDndEditModalFormType,
} from './utils';
import { usePNRStore } from '../../store';
import shallow from 'zustand/shallow';
import { HouseFloor } from '@/dto/taskmap/Dto';

export interface PnrDndEditModalEntranceHeaderProps {
  entranceNumber: number;
  floorCount: number;
}

const PnrDndEditModalEntranceHeader = ({
  entranceNumber,
  floorCount,
}: PnrDndEditModalEntranceHeaderProps) => {
  const { layoutData, setLayoutData } = usePNRStore(
    (state) => ({
      layoutData: state.layoutData,
      setLayoutData: state.setLayoutData,
    }),
    shallow
  );
  const { anchorEl, handleOpen, handleClose, open } = useAnchorElement();

  const handleEntranceEditSubmit = ({
    flatByEntranceRange,
    flatSinceWith,
    floorCount,
  }: PnrDndEditModalFormValues) => {
    if (!layoutData) {
      return;
    }
    const allEntrances = { ...layoutData };
    const neededEntrance = allEntrances?.entrances?.find(
      (entrance) => entrance.number === entranceNumber
    );

    if (!neededEntrance) return;

    neededEntrance.floors = neededEntrance.floors?.map((floor) => ({
      ...floor,
      flats: [],
    })) as HouseFloor[];
    setLayoutData(allEntrances);

    const alreadyExistedFlats: ExistedFlat[] = [];

    let flatNumber = flatSinceWith;
    const newFloors: HouseFloor[] = [];
    for (let i = 1; i <= floorCount; i++) {
      const floorNumber = i;
      const flats = [];
      const isNonResidential = neededEntrance?.floors?.find(
        (floor) => floor.number === floorNumber
      )?.isNonResidential;

      if (!isNonResidential) {
        while (flats.length < flatByEntranceRange) {
          const currentFlatNumber = String(flatNumber);
          flatNumber++;
          const existedFlats = existedFlatsByNumber(currentFlatNumber);

          if (existedFlats.length) {
            alreadyExistedFlats.push(...existedFlats);
          } else {
            flats.push({ number: currentFlatNumber });
          }
        }
      }

      newFloors.push({
        number: floorNumber,
        flats,
        isNonResidential: !!isNonResidential,
      });
    }
    neededEntrance.floors = newFloors.sort((a, b) => b.number - a.number);

    generateExistedFlatsErrorMessage(alreadyExistedFlats);
    setLayoutData(allEntrances);
    handleClose();
  };

  const handleReset = () => {
    if (!layoutData) {
      return;
    }
    const allEntrances = { ...layoutData };
    const neededEntrance = allEntrances?.entrances.find(
      (entrance) => entrance.number === entranceNumber
    );

    if (!neededEntrance) return;

    neededEntrance.floors = neededEntrance.floors?.map((floor) => ({
      ...floor,
      flats: [],
      isNonResidential: false,
    })) as HouseFloor[];
    setLayoutData(allEntrances);
    handleClose();
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: 63 }} />
      <PnrDndEditModalEntranceHeaderInner>
        <HouseEntranceShemeEntranceCube>{entranceNumber}</HouseEntranceShemeEntranceCube>
        <Tooltip title={'Редактировать подъезд'}>
          <IconButton
            style={{ fontSize: 20 }}
            color='primary'
            onClick={handleOpen}
          >
            <EditRoundedIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <PnrDndEditEntranceModalForm
            type={PnrDndEditModalFormType.ENTRANCE}
            onSubmit={handleEntranceEditSubmit}
            forcedFloorCount={floorCount}
            onReset={handleReset}
          />
        </Popover>
      </PnrDndEditModalEntranceHeaderInner>
      <div style={{ width: 36 }} />
    </div>
  );
};

export default PnrDndEditModalEntranceHeader;
