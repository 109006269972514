import React from 'react';
import { useNavbar } from '../store';
import { NavbarLinks } from '../NavbarLinks/NavbarLinks';
import { Drawer } from '@mui/material';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export const MobileNavbar = () => {
  const { toggleDrawer, isOpenMobileNavbar } = useNavbar();
  return (
    <>
      <IconButton
        onClick={() => toggleDrawer(true)}
        sx={{ ml: 'auto' }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        open={isOpenMobileNavbar}
        onClose={() => toggleDrawer(false)}
      >
        <NavbarLinks />
      </Drawer>
    </>
  );
};
