import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { TransformerItem } from './TransformerItem';
import { useFormContext } from 'react-hook-form';
import { TransformerVoltageResponseDto, TransformerVoltageRequestDto } from '@/dto/TransformerDto';
import shallow from 'zustand/shallow';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import moment from 'moment';
import DateField from '@/components/DateField';
import { useStatus } from '@/components/features/taskEditor/panel/store';

interface ITransformerBlock {
  data: TransformerVoltageResponseDto;
}
export const TransformerBlock: FC<ITransformerBlock> = ({ data }) => {
  const { register, setValue } = useFormContext<TransformerVoltageRequestDto>();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { isDisabledAll } = useStatus(
    (state) => ({
      isDisabledAll: state.isDisabledAll,
    }),
    shallow
  );
  const [checkOn, setCheckOn] = useState<Date | undefined>(data?.checkOn);
  const onHandleCheckOn = (newValue: Date | null) => {
    if (newValue) {
      setCheckOn(newValue);
      setValue(`checkOn`, moment(newValue).valueOf());
    }
  };

  return (
    <>
      <Grid
        item
        xs={3}
      >
        <AutocompleteValue
          title='KTН'
          defaultValue={data?.ktnId}
          sx={{ minWidth: '130px' }}
          fieldName={`ktnId`}
          values={getCatalogMapWithErr('ktnType')}
          disabled={isDisabledAll()}
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <DateField
          label={'Дата проверки'}
          defaultValue={data?.checkOn}
          disabled={isDisabledAll()}
          hookFormString={'checkOn'}
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <DateField
          label={'Дата следующей проверки'}
          defaultValue={data?.nextCheckOn}
          disabled={isDisabledAll()}
          hookFormString={'nextCheckOn'}
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <TextField
          id='tf-prev-type'
          label='Тип снятого ТН'
          variant='standard'
          sx={{ width: '100%' }}
          {...register(`prevType`, {
            value: data?.prevType,
          })}
          disabled={isDisabledAll()}
        />
      </Grid>
      <Grid
        item
        container
        justifyContent='center'
        alignItems='center'
      >
        {data?.transformerVoltageResponseList
          ?.sort((c, p) => (c.phaseName > p.phaseName ? 1 : -1))
          .map((t, i) => (
            <TransformerItem
              key={`transformer-item-${t.id}`}
              keyString={`transformerVoltageRequestList.${i}`}
              transformer={t}
            />
          ))}
      </Grid>
    </>
  );
};
